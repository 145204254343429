import { strict as assert } from "assert";

import { AssortmentType } from "../../../commons/types/assortment";
import { BrandWorldPackageInfo } from "../../../commons/types/content";
import { AssortmentPriceSettings, ProductFinderAssortmentFilterSettings } from "../../../commons/types/settings";
import { State } from "../reducers";

import { filterBrandWorldPackages } from "./brand-world";

export const selectSkipBrandWorldAnimationEnabled = (state: State): boolean =>
  Boolean(selectInitializedSettings(state).customization.skipBrandWorldAnimationEnabled);

export const selectBrandWorldPackages = (state: State): BrandWorldPackageInfo[] =>
  filterBrandWorldPackages(state.core.contentPackages);

// Mail / Printing

export const selectHasMailToPrintSettings = (state: State): boolean =>
  Boolean(selectInitializedSettings(state).printing.mailToPrint.email);

// Wawi

export const selectIsWawiConfigured = (state: State): boolean =>
  Boolean(state.wawi.connectionInfo) && Boolean(state.wawi.connectionInfo?.type);

// Smartfit

export const selectIsBodySizingEnabled = (state: State): boolean =>
  Boolean(selectInitializedSettings(state).toolSettings.smartfit.smartfitApiAuth);

export const selectIsBodySizingNotificationsEnabled = (state: State): boolean =>
  Boolean(!selectIsBodySizingEnabled(state)) &&
  Boolean(selectInitializedSettings(state).toolSettings.smartfit.meta.toolVisible);

// Assortment Settings

const fallbackAssortmentFilterSettings: ProductFinderAssortmentFilterSettings = {
  showAllModelYears: false,
  selectAssortmentAutomatically: false,
  hideAutomaticAssortmentFilter: false,
  enableSupplierAvailabilitiesFilterAutomatically: false
};

export const selectAssortmentFilterSettings = (state: State) => {
  const settings = selectInitializedSettings(state);
  const hasAssortmentType = Boolean(settings.assortment.type);

  return hasAssortmentType
    ? (settings.assortment.productFinderFilterSettings ?? fallbackAssortmentFilterSettings)
    : fallbackAssortmentFilterSettings;
};

const fallbackAssortmentPriceSettings: AssortmentPriceSettings = {
  showAssortmentPrices: false,
  showFallbackPrices: false,
  useRrpAsOriginalPrice: false,
  showOriginalPrices: false,
  showSaleBadge: null
};

export const selectAssortmentPriceSettings = (state: State): AssortmentPriceSettings => {
  // eslint-disable-next-line no-restricted-syntax -- This selector can be used during initialization while settings have already been fetched but `isInitialized` is still false.
  const settings = state.settings.settings;
  const hasAutomaticAssortmentType = settings?.assortment.type === AssortmentType.Automatic;

  return hasAutomaticAssortmentType
    ? (settings.assortment.priceSettings ?? fallbackAssortmentPriceSettings)
    : fallbackAssortmentPriceSettings;
};

export const selectInitializedSettings = (state: State) => {
  // Use this selector to get defined settings state after app initialization was done.
  assert(state.settings.settings, "Settings were used before initialization"); // eslint-disable-line no-restricted-syntax
  return state.settings.settings; // eslint-disable-line no-restricted-syntax
};

export const selectInitializedEnv = (state: State) => {
  // Use this selector to get defined core env state after app initialization was done.
  assert(state.core.env, "Env was used before initialization"); // eslint-disable-line no-restricted-syntax
  return state.core.env; // eslint-disable-line no-restricted-syntax
};
