import { Resource } from "i18next";
import { set } from "lodash";

import { Language, Locale, TranslationKey } from "../types/localization";

export type Translation = {
  [key: string]: Translation | string;
} & {
  _description?: string;
};

export interface Translations {
  [key: string]: Translation;
}

export interface Namespaces {
  [key: string]: Translations;
}

export const languages: TranslationKey[] = [...Object.values(Language), ...Object.values(Locale)];

const addTranslation =
  (translationMap: Translations, path: string, languages: TranslationKey[]) =>
  (resources: Resource, translationKey: string): Resource => {
    // Clone object in order to safely delete properties.
    const translation: Translation = translationMap[translationKey];

    languages.forEach(language => {
      if (translation[language]) {
        set(resources, `${language}.${path}.${translationKey}`, translation[language]);
      }

      delete translation[language];
    });

    delete translation._description;

    const nestedPath = `${path}.${translationKey}`;

    // Remaining keys that are neither _description nor a language are considered nested translation keys.
    return Object.keys(translation).reduce(
      addTranslation(translation as Translations, nestedPath, languages),
      resources
    );
  };

export const getResourcesFromTranslations = (namespaces: Namespaces, languages: TranslationKey[]): Resource =>
  Object.entries(namespaces).reduce(
    (resources, [namespaceKey, translationFile]) =>
      Object.keys(translationFile).reduce(addTranslation(translationFile, namespaceKey, languages), resources),
    {}
  );
