import React from "react";

import Headline from "../../components/Headline/Headline";
import Hr from "../../components/Hr/Hr";
import Paragraph from "../../components/Paragraph/Paragraph";
import Text from "../../components/Text/Text";

const DataPrivacyPartial = () => {
  return (
    <Text>
      <Headline kind="l">Datenschutzhinweise BikeCenter Kundenbereich</Headline>

      <Hr />

      <Headline kind="m">Verantwortlichkeit für die Datenverarbeitung</Headline>
      <Paragraph>
        Die DealerCenter Digital GmbH Ludwig-Erhard-Str. 13a 84034 Landshut Deutschland (Im Folgenden: „Wir“) ist als
        Betreiber des BikeCenter Verantwortlicher gem. Art. 4 Nr. 7 der EU-Datenschutz-Grundverordnung (DSGVO). Bei
        Fragen können Sie sich an office@bike.center wenden.
      </Paragraph>

      <Headline kind="m">Betroffenenrechte</Headline>
      <Headline>Ihre Rechte als betroffene Person</Headline>
      <Paragraph>
        Als betroffene Person haben Sie uns gegenüber folgende Rechte hinsichtlich Ihrer personenbezogenen Daten. Sie
        haben:
      </Paragraph>

      <ul>
        <li>
          Ein <strong>Recht auf Auskunft</strong> unter anderem über die Kategorien der verarbeiteten Daten, der
          Verarbeitungszwecke, die Speicherdauer sowie etwaige Empfänger, gemäß Art. 15 DSGVO und § 34 BDSG.
        </li>

        <li>
          Ein <strong>Recht auf Berichtigung oder Löschung</strong> unrichtiger bzw. unvollständiger Daten, gemäß Art.
          16 und 17 DSGVO und § 35 BDSG.
        </li>

        <li>
          Unter den Voraussetzungen des Art. 18 DSGVO oder § 35 Abs. 1 S. 2 BDSG ein{" "}
          <strong>Recht auf Einschränkung der Verarbeitung</strong>.
        </li>

        <li>
          Ein <strong>Recht auf Widerspruch</strong> gegen die Verarbeitung gemäß Art. 21 Abs.1 DSGVO, soweit die
          Datenverarbeitung aufgrund eines berechtigten Interesses erfolgte.
        </li>

        <li>
          Ein <strong>Recht auf Widerruf</strong> einer abgegebenen Einwilligung mit Wirkung für die Zukunft gemäß Art.
          7 Abs. 3 DSGVO.
        </li>

        <li>
          Ein <strong>Recht auf Datenübertragbarkeit</strong> in einem gängigen Format gemäß Art. 20 DSGVO.
        </li>

        <li>
          Sie haben gemäß Art. 22 DSGVO ein{" "}
          <strong>
            Recht darauf, nicht einer ausschließlich auf einer automatisierten Verarbeitung beruhenden Entscheidung
            unterworfen zu werden
          </strong>
          , die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies
          umfasst auch Profiling im Sinne des Art. 4 Nr. 4 DSGVO.
        </li>

        <li>
          Sie haben zudem das Recht, sich gemäß Art. 77 DSGVO bei einer <strong>Datenschutz-Aufsichtsbehörde</strong>{" "}
          über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren, insbesondere in dem Mitgliedstaat
          Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes.
        </li>
      </ul>

      <Headline>Verfahren</Headline>

      <Paragraph>
        Wenn Sie Ihre Rechte nach der DSGVO und dem BDSG uns gegenüber geltend machen, werden wir die von Ihnen dabei an
        uns übermittelten Daten verarbeiten, um Ihren Anspruch zu erfüllen.
      </Paragraph>

      <Paragraph>
        Im Anschluss speichern wir die von Ihnen an uns übermittelten Daten und die von uns an Sie im Gegenzug
        übermittelten Daten zum Zwecke der Dokumentation bis zum Ablauf der ordnungswidrigkeitenrechtlichen
        Verjährungsfrist (3 Jahre).
      </Paragraph>

      <Paragraph>
        Die Rechtsgrundlage für die Verarbeitung und Speicherung der Daten ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO
        (Berechtigtes Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserer
        Verpflichtung Ihrem Anliegen nachzukommen und dem Bedarf, uns in einem möglichen Bußgeldverfahren entlasten zu
        können, indem wir nachweisen, dass wir Ihrem Anliegen ordnungsgemäß nachgekommen sind.
      </Paragraph>

      <Paragraph>
        Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter den
        Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten
        Kontaktdaten. Wir weisen aber darauf hin, dass die Verarbeitung Ihrer Daten zum Nachweis der Einhaltung der
        Betroffenenrechte zwingend im Sinne des Art. 21 Abs. 1 DSGVO ist, da andere Nachweismöglichkeiten nicht bestehen
        bzw. nicht gleichermaßen geeignet sind.
      </Paragraph>

      <Headline>Datenschutzmaßnahmen</Headline>

      <Paragraph>
        Wir sichern unser Tool– und damit auch Ihre Daten – durch technische und organisatorische Maßnahmen gegen
        Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung durch unbefugte Personen ab. Insbesondere werden Ihre
        persönlichen Daten verschlüsselt durch das Internet übertragen. Wir bedienen uns dabei des Codierungssystems TLS
        (Transport Layer Security). Jedoch ist die Übertragung von Informationen über das Internet nie vollständig
        sicher, weshalb wir die Sicherheit der von unserem Tool übermittelten Daten nicht zu 100% garantieren können.
      </Paragraph>

      <Headline>Modalitäten der Datenverarbeitung</Headline>
      <Headline>Quellen und Kategorien von personenbezogenen Daten</Headline>
      <Paragraph>
        Wir verarbeiten Ihre personenbezogenen Daten, soweit sie für die Begründung, inhaltliche Ausgestaltung oder
        Änderung eines Vertragsverhältnisses zwischen uns und Ihnen erforderlich sind (Bestandsdaten).
      </Paragraph>

      <Paragraph>
        Bestandsdaten können insbesondere sein: Name, Anrede, Kontaktdaten (Postadresse, Telefon, E-Mail-Adresse),
        Geburtsdatum usw. Ferner verarbeiten wir Ihre Nutzungsdaten. Nutzungsdaten sind Daten, die durch Ihr Verhalten
        bei der Nutzung unseres Tools und unserer Dienste anfallen, beispielsweise Beginn und Ende der Nutzung des Tools
        und welche Inhalte Sie abgerufen.
      </Paragraph>

      <Paragraph>
        Die genannten Daten erheben wir entweder direkt bei Ihnen (z.B. durch die Nutzung des Tools) oder, soweit dies
        nach den Datenschutzgesetzen zulässig ist, bei Dritten bzw. aus öffentlich zugänglichen Quellen (z.B. Handels-
        und Vereinsregister, Presse, Medien, Internet).
      </Paragraph>

      <Headline>Datenübermittlung an Drittstaaten außerhalb der EU</Headline>
      <Paragraph>
        Alle Informationen, die wir von Ihnen oder über Sie erhalten, werden grundsätzlich auf Servern innerhalb der
        Europäischen Union verarbeitet. Eine Übermittlung Ihrer Daten an oder eine Verarbeitung Ihrer Daten in
        Drittstaaten erfolgt ohne Ihre ausdrückliche Einwilligung lediglich, sofern dies gesetzlich vorgesehen oder
        gestattet ist, in dem Drittstaat ein hierfür angemessenes Datenschutzniveau sichergestellt ist oder vertragliche
        Verpflichtungen durch sogenannte Standarddatenschutzklauseln der EU bestehen.
      </Paragraph>
      <Paragraph>
        Bitte beachten Sie, dass es sich bei den USA um einen sogenannten unsicheren Drittstaat handelt. Es besteht bei
        einer Übermittlung von personenbezogenen Daten in die USA das Risiko, dass US-Sicherheitsbehörden im Rahmen des
        „Cloud-Acts“ auf diese Daten zugreifen können. Gegen diese Maßnahmen stehen EU-Bürgern keine effektiven
        Rechtsschutzmöglichkeiten in den USA oder der EU zu.
      </Paragraph>

      <Headline>Weitergabe von Daten, Auftragsverarbeitung</Headline>
      <Paragraph>
        Wir geben Ihre personenbezogenen Daten niemals unberechtigt an Dritte weiter. Wir können Ihre Daten aber
        insbesondere an Dritte weitergeben, wenn Sie in die Datenweitergabe eingewilligt haben, wenn die Weitergabe zur
        Erfüllung unserer rechtlichen Verpflichtungen notwendig ist oder wenn wir aufgrund gesetzlicher Bestimmungen
        oder behördlicher oder gerichtlicher Anordnungen zu einer Datenweitergabe berechtigt oder verpflichtet sind.
        Dabei kann es sich insbesondere um die Auskunftserteilung für Zwecke der Strafverfolgung, zur Gefahrenabwehr
        oder zur Durchsetzung geistiger Eigentumsrechte handeln.
      </Paragraph>
      <Paragraph>
        Unter Umständen übermitteln wir Ihre Daten außerdem an externe Dienstleister, die Daten in unserem Auftrag und
        nach unserer Weisung verarbeiten (Auftragsverarbeiter), um unsere eigene Datenverarbeitung zu vereinfachen oder
        zu entlasten. Jeder Auftragsverarbeiter wird durch einen Vertrag nach Maßgabe des Art. 28 DSGVO verpflichtet.
        Das heißt insbesondere, dass der Auftragsverarbeiter hinreichend Garantien dafür zu bieten hat, dass durch ihn
        geeignete technische und organisatorische Maßnahmen so durchgeführt werden, dass die Verarbeitung im Einklang
        mit den Anforderungen der DSGVO erfolgt und der Schutz Ihrer Rechte als betroffene Person gewährleistet ist.
        Trotz Beauftragung von Auftragsverarbeitern bleiben wir für die Verarbeitung Ihrer personenbezogenen Daten die
        verantwortliche Stelle im Sinne der Datenschutzgesetze.
      </Paragraph>

      <Headline>Zweck der Datenverarbeitung</Headline>
      <Paragraph>
        Wir nutzen die Daten grundsätzlich nur zu dem Zweck, zu dem die Daten von Ihnen erhoben wurden. Wir können die
        Daten zu einem anderen Zweck weiterverarbeiten, wenn nicht dieser andere Zweck mit dem ursprünglichen Zweck
        unvereinbar ist (Art. 5 Abs. 1 Lit. c) DSGVO).
      </Paragraph>

      <Headline>Speicherdauer</Headline>
      <Paragraph>
        Soweit im Einzelnen nichts anderes angegeben ist, speichern wir von Ihnen erhobene Daten nur so lange, wie es
        für den jeweiligen Zweck erforderlich ist, es sei denn es stehen gesetzliche Aufbewahrungspflichten der Löschung
        entgegen, z.B. aus dem Handelsrecht oder dem Steuerrecht.
      </Paragraph>
      <Headline>Einzelne Verarbeitungstätigkeiten</Headline>
      <Paragraph>
        Wir möchten Ihnen im Folgenden so transparent wie möglich darstellen, welche Daten wir von Ihnen zu welcher
        Gelegenheit, auf welcher Grundlage und zu welchem Zweck verarbeiten.
      </Paragraph>

      <Headline>Analyse-Tools</Headline>
      <Paragraph>
        Wir nutzen bestimmte Dienste, die Daten von BikeCenter Nutzer aufzeichnen und uns zur Analyse zur Verfügung
        stellen. Die Daten werden anonymisiert erhoben und können nicht zum einzelnen User zurückverfolgt werden.
      </Paragraph>
      <Paragraph>
        Diese Daten nutzen wir zur nutzungsorientierten Verbesserung unseres Produkts. Die Rechtsgrundlage für die
        Verarbeitung Ihrer Daten in Bezug auf Analysetools ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes
        Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt sich aus unserem Bedarf an einer
        statistischen Analyse der BikeCenter Nutzung sowie der Optimierung unseres Produkts. Dafür verwenden wir
        folgende Dienst:
      </Paragraph>

      <Headline kind="s">Mixpanel</Headline>
      <Paragraph>
        Wir nutzen zur Produktanalyse die Software Mixpanel der Mixpanel Inc., One Front Street, Floor 28 San Francisco,
        CA 94111.
      </Paragraph>

      <Headline>Dokumente via E-Mail teilen </Headline>
      <Paragraph>
        Das BikeCenter bietet Ihnen die Möglichkeit, Dokumente an Ihre persönliche E-Mail-Adresse zu senden. Bevor das
        Dokument an Ihre E-Mail-Adresse versandt wird, muss dies durch einen Mitarbeiter freigegeben werden. Da wir Sie
        vor Versand des Dokuments um Ihre Einwilligung bitten, ist Rechtsgrundlage für die Datenverarbeitung zum Versand
        des Dokuments Art. 6 Abs. 1 S. 1 Buchstabe a) DSGVO (Einwilligung der betroffenen Person). Diese Einwilligung
        können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Nutzen Sie hierfür die oben genannten Kontaktdaten.
        Wir verwenden für den E-Mail-Versand folgenden externen Dienstleister:
      </Paragraph>

      <Headline kind="s">Sendgrid</Headline>
      <Paragraph>
        Wir nutzen für die Funktion „Dokument teilen“ den Dienst Sendgrid der „SendGrid Inc., 1801 California Street,
        Suite 500, Denver, CO 80202 USA. Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Sendgrid: https://www.twilio.com/legal/privacy
      </Paragraph>
      <Paragraph>
        Wir haben mit dem Anbieter einen sog. Auftragsverarbeitungsvertrag geschlossen, um sicherzustellen, dass dieser
        Ihre Daten nur nach unserer Anweisung verarbeitet. Die Rechtsgrundlage für die Datenweitergabe im Rahmen der
        Nutzung der Software ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes Interesse an der Datenverarbeitung.
        Das berechtigte Interesse ergibt sich aus unserem Bedarf unsere Datenverarbeitung zu vereinfachen und zu
        entlasten. Der Verarbeitung Ihrer Daten auf Grundlage unseres berechtigten Interesses können Sie jederzeit unter
        den Voraussetzungen des Art. 21 DSGVO widersprechen. Nutzen Sie hierfür bitte die im Impressum genannten
        Kontaktdaten.
      </Paragraph>
    </Text>
  );
};

export default DataPrivacyPartial;
