import { isDate } from "lodash";
import moment from "moment";

import { AssortmentItem } from "../types/assortment";
import {
  AutomaticAssortmentAvailabilityState,
  AvailabilityState,
  AvailabilityStates,
  AvailabilityStatus,
  AvailabilityType
} from "../types/availability";
import { InvalidArgumentError } from "../types/invalid-argument-error";
import { AvailabilityPrioritization, AvailabilityPrioritizationOptions } from "../types/settings";
import {
  SupplierAvailabilityCode,
  WawiAvailability,
  WawiAvailabilityCode,
  WawiProductAvailabilityVariant
} from "../types/wawi";

import { isWaWiAssortmentItem } from "./assortment";
import {
  ProductAvailability,
  UpstreamItemStatus,
  ProductAvailability as VeloconnectProductAvailability
} from "./externals/veloconnect-proxy";
import { parseDate } from "./parser";

const FALLBACK_AVAILABILITY_STATE: AvailabilityState = {
  type: AvailabilityType.Unknown,
  status: AvailabilityStatus.NoAvailabilityData,
  itemUnknown: true
};

export function getWawiAvailabilityState(
  wawiAvailability: WawiProductAvailabilityVariant,
  {
    preferSupplier = false
  }: {
    preferSupplier: boolean;
  } = {
    preferSupplier: false
  },
  relativeTo: Date = new Date()
): AvailabilityState {
  if (!wawiAvailability) {
    return FALLBACK_AVAILABILITY_STATE;
  }

  const { inStock, orderIncoming, inStockElsewhere, notInStock, notAvailable, unknown } = calculateAvailabilityStates(
    wawiAvailability,
    undefined,
    relativeTo
  );

  const availabilityPrioritizeSupplier =
    inStock ||
    orderIncoming ||
    inStockElsewhere ||
    notInStock ||
    notAvailable ||
    unknown ||
    FALLBACK_AVAILABILITY_STATE;

  const availabilityPrioritizeInStockElsewhere =
    inStock ||
    inStockElsewhere ||
    orderIncoming ||
    notInStock ||
    notAvailable ||
    unknown ||
    FALLBACK_AVAILABILITY_STATE;

  return preferSupplier ? availabilityPrioritizeSupplier : availabilityPrioritizeInStockElsewhere;
}

export function getWawiLocationAvailabilityState(availabilityLocation: WawiAvailability): AvailabilityState {
  const type = {
    [WawiAvailabilityCode.Available]: AvailabilityType.Full,
    [WawiAvailabilityCode.NotAvailable]: AvailabilityType.No,
    [WawiAvailabilityCode.Unknown]: AvailabilityType.Unknown,
    [WawiAvailabilityCode.Ordered]: AvailabilityType.Partial
  }[availabilityLocation.code];

  return {
    type,
    status: AvailabilityStatus.WarehouseLocation,
    warehouseLocation: availabilityLocation.location
  };
}

function getExpectingDeliveryState(
  wawiAvailability?: WawiProductAvailabilityVariant,
  veloconnectAvailability?: VeloconnectProductAvailability,
  relativeTo: Date = new Date()
): AvailabilityState {
  const veloconnectAvailabilityWithExpectedDeliveryDate = veloconnectAvailability?.availabilities.find(
    availability => availability.expectedDeliveryDate
  );

  const expectedDeliveryDateVeloconnect = parseDate(
    veloconnectAvailabilityWithExpectedDeliveryDate?.expectedDeliveryDate || "",
    "YYYY-MM-DD"
  )?.toDate();
  const expectedDeliveryDateWawi = wawiAvailability?.supplier?.expectedDeliveryDate;
  const expectedDeliveryDate = [expectedDeliveryDateVeloconnect, expectedDeliveryDateWawi]
    .filter(isDate)
    .sort((a, b) => a.getTime() - b.getTime())
    .reverse()
    .pop();

  if (expectedDeliveryDate) {
    const isSince = moment(expectedDeliveryDate).isSameOrBefore(relativeTo);

    return isSince
      ? {
          type: AvailabilityType.Full,
          status: AvailabilityStatus.AvailableSince,
          expectedDeliveryDate
        }
      : {
          type: AvailabilityType.Partial,
          status: AvailabilityStatus.AvailableFrom,
          expectedDeliveryDate
        };
  } else {
    return {
      type: AvailabilityType.Partial,
      status: AvailabilityStatus.FutureAvailable
    };
  }
}

function getExpectingOrderedState(wawiAvailabilities: WawiAvailability[]): AvailabilityState {
  const orderedForDate = wawiAvailabilities
    .map(({ orderedForDate }) => orderedForDate)
    .filter(isDate)
    .sort((a, b) => a.getTime() - b.getTime())
    .reverse()
    .pop();

  if (orderedForDate) {
    return {
      type: AvailabilityType.Partial,
      status: AvailabilityStatus.OrderedTo,
      expectedDeliveryDate: orderedForDate
    };
  } else {
    return {
      type: AvailabilityType.Partial,
      status: AvailabilityStatus.Ordered
    };
  }
}

export function calculateAvailabilityStates(
  wawiAvailability?: WawiProductAvailabilityVariant,
  veloconnectAvailability?: VeloconnectProductAvailability,
  relativeTo: Date = new Date()
): AvailabilityStates {
  if (
    !wawiAvailability?.itemUnknown &&
    wawiAvailability?.availabilities.length === 0 &&
    wawiAvailability?.supplier === undefined
  ) {
    throw new Error("There have to be availabilities for wawiAvailability with a code other than UNKNOWN");
  }

  const wawiCodeIs = (code: SupplierAvailabilityCode): boolean => {
    return wawiAvailability?.supplier?.code === code;
  };

  const someWawiAvailabilityIs = (code: WawiAvailabilityCode): boolean => {
    return !!wawiAvailability?.availabilities.some(availability => availability.code === code);
  };

  const someVeloconnectAvailabilityIs = (status: UpstreamItemStatus): boolean => {
    return !!veloconnectAvailability?.availabilities.some(availability => availability.status === status);
  };

  const getOrderedWawiAvailabilities = (): WawiAvailability[] => {
    return wawiAvailability?.availabilities.filter(av => av.code === WawiAvailabilityCode.Ordered) || [];
  };

  /** In stock on-site. */
  const isInStockOnSite = (): boolean => {
    return !!wawiAvailability?.availabilities.some(
      availability => availability.currentLocation && availability.code === WawiAvailabilityCode.Available
    );
  };

  /** Not in stock on-site. */
  const isNotInStockOnSite = (): boolean => {
    return Boolean(
      !wawiAvailability?.itemUnknown &&
        wawiAvailability?.availabilities.every(
          availability => availability.currentLocation && availability.code === WawiAvailabilityCode.NotAvailable
        )
    );
  };

  /** Ordered on-site or off-site. */
  const isOrderIncoming = (): boolean => {
    return someWawiAvailabilityIs(WawiAvailabilityCode.Ordered);
  };

  /** In stock off-site at another location */
  const isInStockOffSite = (): boolean => {
    return !!wawiAvailability?.availabilities.some(
      availability => !availability.currentLocation && availability.code === WawiAvailabilityCode.Available
    );
  };

  /** Not in stock off-site at another location */
  const isNotInStockOffSite = (): boolean => {
    return Boolean(
      !wawiAvailability?.itemUnknown &&
        wawiAvailability?.availabilities.every(
          availability => !availability.currentLocation && availability.code === WawiAvailabilityCode.NotAvailable
        )
    );
  };

  /** In stock or available at supplier, hence product is orderable */
  const isAvailableAtSupplier = (): boolean => {
    return (
      wawiCodeIs(SupplierAvailabilityCode.Available) || someVeloconnectAvailabilityIs(UpstreamItemStatus.AVAILABLE)
    );
  };

  /** Strange Veloconnect case which means it could be available */
  const isPartiallyAvailableAtSupplier = (): boolean => {
    return someVeloconnectAvailabilityIs(UpstreamItemStatus.PARTIALLY_AVAILABLE);
  };

  /** To-be in stock at supplier in (near) future, hence product is will be orderable */
  const isSoonAvailableAtSupplier = (): boolean => {
    return (
      wawiCodeIs(SupplierAvailabilityCode.Orderable) ||
      someVeloconnectAvailabilityIs(UpstreamItemStatus.EXPECTING_DELIVERY)
    );
  };

  /** Not available or in stock at supplier */
  const isNotAvailableAtSupplier = (): boolean => {
    return (
      wawiCodeIs(SupplierAvailabilityCode.NotAvailable) ||
      someVeloconnectAvailabilityIs(UpstreamItemStatus.NOT_AVAILABLE)
    );
  };

  /** Unknown availability state. */
  const isUnknown = (): boolean => {
    return (
      !!wawiAvailability?.itemUnknown ||
      (veloconnectAvailability && !veloconnectAvailability?.productFound) ||
      someVeloconnectAvailabilityIs(UpstreamItemStatus.NOT_FOUND)
    );
  };

  return {
    availableAtSupplier: // prettier-ignore
      isAvailableAtSupplier() &&
      {
        type: AvailabilityType.Full,
        status: AvailabilityStatus.Available
      },

    partiallyAvailableAtSupplier: // prettier-ignore
      isPartiallyAvailableAtSupplier() &&
      {
        type: AvailabilityType.Partial,
        status: AvailabilityStatus.PartialAvailable
      },

    notAvailableAtSupplier: // prettier-ignore
      isNotAvailableAtSupplier() &&
      {
        type: AvailabilityType.No,
        status: AvailabilityStatus.NotDeliverable
      },

    soonAvailableAtSupplier: // prettier-ignore
      isSoonAvailableAtSupplier() &&
      getExpectingDeliveryState(wawiAvailability, veloconnectAvailability, relativeTo),

    orderIncoming: // prettier-ignore
      isOrderIncoming() &&
      getExpectingOrderedState(getOrderedWawiAvailabilities()),

    inStock: // prettier-ignore
      isInStockOnSite() &&
      {
        type: AvailabilityType.Full,
        status: AvailabilityStatus.InStock
      },

    inStockElsewhere: // prettier-ignore
      isInStockOffSite() &&
      {
        type: AvailabilityType.Partial,
        status: AvailabilityStatus.AvailableOtherWarehouse
      },

    notInStock: // prettier-ignore
      isNotInStockOnSite() &&
      {
        type: AvailabilityType.No,
        status: AvailabilityStatus.NotInStock
      },

    notAvailable: // prettier-ignore
      ((isNotInStockOnSite() ||
      isNotInStockOffSite()) ||
      isNotAvailableAtSupplier()) &&
      {
        type: AvailabilityType.No,
        status: AvailabilityStatus.NotAvailable
      },

    unknown: isUnknown() && FALLBACK_AVAILABILITY_STATE
  };
}

export function getSupplierAvailabilityState(
  wawiAvailability?: WawiProductAvailabilityVariant,
  veloconnectAvailability?: VeloconnectProductAvailability,
  relativeTo: Date = new Date()
): AvailabilityState {
  // Neither wawi nor Veloconnect
  if (!wawiAvailability?.supplier && !veloconnectAvailability) {
    return FALLBACK_AVAILABILITY_STATE;
  }

  const {
    availableAtSupplier,
    soonAvailableAtSupplier,
    partiallyAvailableAtSupplier,
    notAvailableAtSupplier,
    unknown
  } = calculateAvailabilityStates(wawiAvailability, veloconnectAvailability, relativeTo);

  return (
    availableAtSupplier ||
    soonAvailableAtSupplier ||
    partiallyAvailableAtSupplier ||
    notAvailableAtSupplier ||
    unknown ||
    FALLBACK_AVAILABILITY_STATE
  );
}
export function compareAvailabilityStates(current: AvailabilityState, compare: AvailabilityState) {
  const prioritization = Object.values(AvailabilityType);

  if (prioritization.indexOf(current.type) < prioritization.indexOf(compare.type)) {
    return -1;
  }
  if (prioritization.indexOf(current.type) > prioritization.indexOf(compare.type)) {
    return 1;
  }

  return 0;
}

export function getPrioritizedAvailabilityState(
  availabilityStates: AvailabilityStates,
  availabilityPriotizationOptions?: AvailabilityPrioritizationOptions,
  relativeTo: Date = new Date()
): AvailabilityState {
  const {
    inStock,
    orderIncoming,
    availableAtSupplier,
    soonAvailableAtSupplier,
    inStockElsewhere,
    partiallyAvailableAtSupplier,
    notAvailable,
    unknown
  } = availabilityStates;

  const { availabilityPrioritization, downgradeSoonAvailableAtSupplierThresholdInWeeks } =
    availabilityPriotizationOptions ?? {};
  const preferSupplier = availabilityPrioritization === AvailabilityPrioritization.Supplier;

  const shouldInStockElsewhereBePrioritized: boolean =
    preferSupplier &&
    !!soonAvailableAtSupplier &&
    !!downgradeSoonAvailableAtSupplierThresholdInWeeks &&
    moment(soonAvailableAtSupplier.expectedDeliveryDate).diff(relativeTo, "weeks") >
      downgradeSoonAvailableAtSupplierThresholdInWeeks;

  const availabilityPrioritizeInStockElsewhere =
    inStock ||
    inStockElsewhere ||
    orderIncoming ||
    availableAtSupplier ||
    soonAvailableAtSupplier ||
    partiallyAvailableAtSupplier ||
    notAvailable ||
    unknown ||
    FALLBACK_AVAILABILITY_STATE;

  const availabilityPrioritizeSupplier =
    inStock ||
    orderIncoming ||
    availableAtSupplier ||
    (shouldInStockElsewhereBePrioritized
      ? inStockElsewhere || soonAvailableAtSupplier
      : soonAvailableAtSupplier || inStockElsewhere) ||
    partiallyAvailableAtSupplier ||
    notAvailable ||
    unknown ||
    FALLBACK_AVAILABILITY_STATE;

  return preferSupplier ? availabilityPrioritizeSupplier : availabilityPrioritizeInStockElsewhere;
}

export const getAutomaticAssortmentAvailabilityState = (
  assortmentItem: AssortmentItem | undefined
): AutomaticAssortmentAvailabilityState[] | null => {
  if (assortmentItem && isWaWiAssortmentItem(assortmentItem)) {
    const calculatedAvailabilityStates = calculateAvailabilityStates(assortmentItem.wawiProductAvailabilityVariant);

    const {
      unknown,
      inStock,
      inStockElsewhere,
      orderIncoming,
      notAvailable,
      availableAtSupplier,
      soonAvailableAtSupplier,
      partiallyAvailableAtSupplier
    } = calculatedAvailabilityStates;

    const availability: Record<AutomaticAssortmentAvailabilityState, boolean> = {
      [AutomaticAssortmentAvailabilityState.InStock]: !!inStock,
      [AutomaticAssortmentAvailabilityState.InStockElsewhere]: !!inStockElsewhere,
      [AutomaticAssortmentAvailabilityState.Ordered]: !!orderIncoming,
      [AutomaticAssortmentAvailabilityState.NotAvailable]: !!notAvailable,
      [AutomaticAssortmentAvailabilityState.AvailableAtSupplier]:
        !!availableAtSupplier || !!soonAvailableAtSupplier || !!partiallyAvailableAtSupplier,
      [AutomaticAssortmentAvailabilityState.Unknown]: !!unknown
    };

    return Object.values(AutomaticAssortmentAvailabilityState).reduce(
      (acc, state) => [...acc, ...(availability[state] ? [state] : [])],
      [] as AutomaticAssortmentAvailabilityState[]
    );
  } else if (!assortmentItem) {
    return [AutomaticAssortmentAvailabilityState.Unknown];
  } else {
    return null;
  }
};

export function getAvailabilityState(
  wawiAvailability?: WawiProductAvailabilityVariant,
  veloconnectAvailability?: VeloconnectProductAvailability,
  availabilityPriotizationOptions?: AvailabilityPrioritizationOptions,
  relativeTo: Date = new Date()
): AvailabilityState {
  // Neither wawi nor Veloconnect
  if (!wawiAvailability && !veloconnectAvailability) {
    return FALLBACK_AVAILABILITY_STATE;
  }

  const { availabilityPrioritization, downgradeSoonAvailableAtSupplierThresholdInWeeks } =
    availabilityPriotizationOptions ?? {};
  const preferSupplier = availabilityPrioritization === AvailabilityPrioritization.Supplier;

  // Check if downgradeSoonAvailableAtSupplierThresholdInWeeks is a positive number
  if (
    preferSupplier &&
    (!downgradeSoonAvailableAtSupplierThresholdInWeeks || downgradeSoonAvailableAtSupplierThresholdInWeeks < 1)
  ) {
    throw new InvalidArgumentError(
      "If preferSoonAvailableAtSupplier is set than downgradeSoonAvailableAtSupplierThresholdInWeeks must be a positive number greater than 1"
    );
  }

  const availabilityStates = calculateAvailabilityStates(wawiAvailability, veloconnectAvailability, relativeTo);

  return getPrioritizedAvailabilityState(availabilityStates, availabilityPriotizationOptions, relativeTo);
}

export const getVeloconnectAvailabilityState = (
  veloconnectAssortmentItem?: ProductAvailability
): UpstreamItemStatus[] => {
  if (!veloconnectAssortmentItem) {
    return [UpstreamItemStatus.NOT_FOUND];
  } else {
    return veloconnectAssortmentItem.availabilities.map(availability => availability.status);
  }
};
