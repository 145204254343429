import React from "react";
import TextFit from "react-textfit";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  name: string;
  price?: string;
  originalPrice?: string;
  badge?: React.ReactNode;
  year?: string;
  minFontSize?: number;
  maxFontSize?: number;
}

const ProductTitle = ({
  classNames,
  name,
  price = "",
  originalPrice,
  badge,
  year,
  minFontSize = 20,
  maxFontSize = 32
}: Props) => (
  <h1 className={cn("ProductTitle", [], classNames)}>
    <TextFit mode="single" min={minFontSize} max={maxFontSize}>
      <span className="ProductTitle__inner">
        {name}
        {year && (
          <>
            <span className="ProductTitle__icon" />
            <span className="ProductTitle__year">{year}</span>
          </>
        )}
        {!!price && <span className="ProductTitle__price">{price}</span>}
        {!!originalPrice && <span className="ProductTitle__originalPrice">{originalPrice}</span>}
        {badge && <span className="ProductTitle__badge">{badge}</span>}
      </span>
    </TextFit>
  </h1>
);

export default ProductTitle;
