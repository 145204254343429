import { Action } from "../../../commons/types/redux";
import { WawiCredentials, WawiSettings, WawiType } from "../../../commons/types/wawi";

export type FetchConnectionInfoAction = Action<"WAWI.FETCH_CONNECTION_INFO">;
export type ReceivedConnectionInfoAction = Action<"WAWI.RECEIVED_CONNECTION_INFO", Partial<WawiSettings>>;
export type SetConnectionAction = Action<"WAWI.SET_CONNECTION", { type: WawiType; credentials?: WawiCredentials }>;
export type DeleteConnectionAction = Action<"WAWI.DELETE_CONNECTION">;

export type WawiActions =
  | FetchConnectionInfoAction
  | ReceivedConnectionInfoAction
  | SetConnectionAction
  | DeleteConnectionAction;

export const fetchConnectionInfo = (): FetchConnectionInfoAction => ({
  type: "WAWI.FETCH_CONNECTION_INFO"
});

export const receivedConnectionInfo = (connectionInfo: Partial<WawiSettings>): ReceivedConnectionInfoAction => ({
  type: "WAWI.RECEIVED_CONNECTION_INFO",
  payload: connectionInfo
});

export const setConnection = (type: WawiType, credentials?: WawiCredentials): SetConnectionAction => ({
  type: "WAWI.SET_CONNECTION",
  payload: {
    type,
    credentials
  }
});

export const deleteConnection = (): DeleteConnectionAction => ({
  type: "WAWI.DELETE_CONNECTION"
});
