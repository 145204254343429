import React from "react";
import { useTranslation } from "react-i18next";

import { IconLargeFlipDevice } from "../../../../resources/icons";
import Headline from "../../../commons/components/Headline/Headline";
import Icon from "../../../commons/components/Icon/Icon";
import WarningOverlayLayout from "../../../commons/components/WarningOverlayLayout/WarningOverlayLayout";
import LogoPartial from "../../../commons/container/App/LogoPartial";
import useOrientation, { Orientation } from "../../../commons/libs/hooks/use-orientation";

const OrientationWarningPartial = () => {
  const { t } = useTranslation(["mobile"]);
  const orientation = useOrientation();

  return orientation !== Orientation.Landscape ? (
    <WarningOverlayLayout
      logo={<LogoPartial />}
      headline={
        <Headline kind="m"> {t("mobile:orientationWarningPartialApp.incompatibleOrientationHeadline")}</Headline>
      }
      icon={<Icon source={IconLargeFlipDevice} />}
    />
  ) : null;
};

export default OrientationWarningPartial;
