import React from "react";

const useEffectAfterMount: typeof React.useEffect = (effect, deps) => {
  const mountedRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEffectAfterMount;
