import { Language, Locale } from "./localization";

export enum PackageType {
  Generic = "generic",
  Bikes = "bikes",
  Motorcycles = "motorcycles",
  Tool = "tool",
  CustomerWorld = "customer-world",
  BrandWorld = "brand-world"
}

export enum ContentPackageStatus {
  DownloadComplete = "download-complete",
  DownloadFailed = "download-failed",
  DownloadPending = "download-pending",
  Obsolete = "obsolete",
  UpToDate = "up-to-date",
  InstallationPending = "installation-pending",
  UpdateAvailable = "update-available",
  Unknown = "unknown"
}

export interface PackageInfo {
  name: string;
  packageGroup?: string[];
  installed?: boolean;
  type?: PackageType;
  status?: ContentPackageStatus;
}

export interface AssociablePackageInfo {
  associated?: {
    locales?: (Locale | Language)[];
    brands?: string[];
  };
}

export interface PackageUpgrades {
  toBeInstalled: string[];
  toBeRemoved: string[];
  toBeFixed: string[];
  updatesAvailable: boolean;
}

export type BrandWorldPackageInfo = PackageInfo &
  AssociablePackageInfo & {
    associated: { brands: string[] };
  };
