import React from "react";

import CommonPrintModalPartial from "../../../commons/container/App/PrintModalPartial";
import { connect } from "../../../commons/container/utils/loop";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";

type PrintModalPartialProps = React.ComponentProps<typeof CommonPrintModalPartial>;

const mapStateToProps = (state: State) => ({
  isUsbPrinterConnectedOrMailToPrintEnabled: selectors.selectHasMailToPrintSettings(state)
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const PrintModalPartial = (props: PrintModalPartialProps) => (
  <CommonPrintModalPartial {...props} isMobile isOfflineAndMailToPrintIsEnabled={false} />
);

export default connector(PrintModalPartial);
