import React from "react";

import { Component } from "../../../../commons/types/component";
import { IconLargeLoading, IconSmallLoading } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

type Size = "base" | "s";

interface Props extends Component {
  variant?: "primary" | "standout";
  label?: string;
  progress?: string;
  size?: Size;
}

const LoadingIndicator = ({
  classNames = [],
  variant = "primary",
  label = "",
  progress = "",
  size = "base"
}: Props) => (
  <div
    className={cn(
      "LoadingIndicator",
      [
        {
          [`variant-${variant}`]: variant,
          [`size-${size}`]: size,
          label
        }
      ],
      classNames
    )}
  >
    {!!progress && size === "base" && <div className="LoadingIndicator__progress">{progress}</div>}
    <Icon source={size === "s" ? IconSmallLoading : IconLargeLoading} />
    {!!label && <span className="LoadingIndicator__label">{label}</span>}
  </div>
);

export default LoadingIndicator;
