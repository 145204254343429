import { Action } from "../../../commons/types/redux";

export type AuthorizeDealerAction = Action<"AUTH.AUTHORIZE_DEALER", { personalCode: string }>;
export type SetDealerAuthorizedAction = Action<"AUTH.SET_DEALER_AUTHORIZED", { isDealerAuthorized: boolean }>;
export type ResetDealerAuthorizationAction = Action<"AUTH.RESET_DEALER_AUTHORIZATION">;

export type AuthActions = AuthorizeDealerAction | ResetDealerAuthorizationAction | SetDealerAuthorizedAction;

export const authorizeDealer = (personalCode: string): AuthorizeDealerAction => ({
  type: "AUTH.AUTHORIZE_DEALER",
  payload: {
    personalCode
  }
});

export const resetDealerAuthorization = (): ResetDealerAuthorizationAction => ({
  type: "AUTH.RESET_DEALER_AUTHORIZATION"
});

export const setDealerAuthorized = (isDealerAuthorized: boolean = false): SetDealerAuthorizedAction => ({
  type: "AUTH.SET_DEALER_AUTHORIZED",
  payload: {
    isDealerAuthorized
  }
});
