import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type Width = "s" | "base" | "l" | "xl" | "full" | "auto";

interface Props extends Component {
  children: React.ReactNode;
  footer?: React.ReactNode;
  actionButtons?: React.ReactNode;
  backButton?: React.ReactElement<any>;
  closeButton?: React.ReactElement<any>;
  backgroundImage?: string;
  width?: Width;
  layout?: "base" | "noPadding";
  nodeRef?: React.Ref<HTMLElement>;
}

interface State {
  canScrollUp: boolean;
  canScrollDown: boolean;
}

export const ModalContext = React.createContext<{ scroll: boolean }>({
  scroll: false
});

class Modal extends React.Component<Props, State> {
  public state: Readonly<State> = {
    canScrollUp: false,
    canScrollDown: false
  };

  private childrenRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    this.updateScroll();
    window.addEventListener("resize", this.updateScroll);
    this.childrenRef.current?.addEventListener("scroll", this.updateScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateScroll);
    this.childrenRef.current?.removeEventListener("scroll", this.updateScroll);
  }

  public render() {
    const {
      classNames = [],
      children,
      footer = null,
      actionButtons = null,
      backButton = null,
      closeButton = null,
      backgroundImage = "",
      width = "base",
      layout = "base",
      nodeRef = null
    } = this.props;

    const { canScrollUp, canScrollDown } = this.state;
    const scroll = canScrollUp || canScrollDown;

    const style: React.CSSProperties = {};
    if (backgroundImage) {
      style.backgroundImage = `url(${backgroundImage})`;
    }

    const header = !!backButton || !!actionButtons || !!closeButton;

    return (
      <section
        ref={nodeRef}
        className={cn(
          "Modal",
          [
            {
              [`width-${width}`]: width,
              [`layout-${layout}`]: layout,
              header,
              scroll,
              canScrollUp,
              canScrollDown
            }
          ],
          classNames
        )}
        style={style}
      >
        {header && (
          <div className="Modal__header">
            <div className="Modal__back">{backButton}</div>
            <div className="Modal__actions">
              {actionButtons && <div className="Modal__actionButtons">{actionButtons}</div>}
              {closeButton && <div className="Modal__close">{closeButton}</div>}
            </div>
          </div>
        )}
        <div className="Modal__scroll">
          <div className="Modal__children" ref={this.childrenRef}>
            <ModalContext.Provider value={{ scroll }}>{children}</ModalContext.Provider>
          </div>
        </div>

        {footer && <div className="Modal__footer">{footer}</div>}
      </section>
    );
  }

  private updateScroll = () => {
    const node = this.childrenRef.current;

    if (node) {
      const canScrollDown = node.scrollTop + node.clientHeight < node.scrollHeight;
      const canScrollUp = node.scrollTop > 0;

      if (this.state.canScrollDown !== canScrollDown) {
        this.setState({ canScrollDown });
      }
      if (this.state.canScrollUp !== canScrollUp) {
        this.setState({ canScrollUp });
      }
    }
  };
}

export default Modal;
