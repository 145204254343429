import { useCallback } from "react";

import { getAvailableBrands } from "../../../../commons/libs/content-service";
import { Brand } from "../../../../commons/types/brand";
import useQuery, { UseQueryOptions } from "../hooks/use-query";

const queryKey = ["availableBrands"] as const;

type QueryOptions = Omit<Partial<UseQueryOptions<Brand[]>>, "queryKey" | "queryFn">;

const useAvailableBrands = (options: QueryOptions = {}) => {
  const query = useQuery<Brand[], Error>({
    ...options,
    queryKey,
    queryFn: getAvailableBrands,
    // Available Brands do not change during runtime
    staleTime: Infinity
  });

  // `query.data` gets stabilized bei `tanstack-query`.
  // See: https://tanstack.com/query/latest/docs/framework/react/guides/render-optimizations#structural-sharing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableBrands = query.data ?? [];

  const getBrand = useCallback(
    (brandKey: string) => availableBrands.find(brand => brand.key === brandKey),
    [availableBrands]
  );

  return {
    query,
    getBrand
  };
};

export default useAvailableBrands;
