import { Action } from "../../../commons/types/redux";
import { TimeParams, UptimeDay, UptimeParams } from "../../../commons/types/uptime";

export type FetchAction = Action<"STARTUP_TIMES.FETCH">;
export type ResetAction = Action<"STARTUP_TIMES.RESET">;
export type ReceivedAction = Action<"STARTUP_TIMES.RECEIVED", UptimeParams>;
export type SaveDaysAction = Action<"STARTUP_TIMES.SAVE_DAYS", UptimeParams>;
export type SaveDaysSucceededAction = Action<"STARTUP_TIMES.SAVE_DAYS_SUCCEEDED", UptimeParams>;
export type SetDayAction = Action<"STARTUP_TIMES.SET_DAY", UptimeDay>;
export type ChangeDayTimeAction = Action<"STARTUP_TIMES.CHANGE_DAY_TIME", UptimeDay>;
export type DelayAction = Action<"STARTUP_TIMES.DELAY">;
export type DelaySucceededAction = Action<"STARTUP_TIMES.DELAY_SUCCEEDED", TimeParams>;

export type StartupTimesActions =
  | FetchAction
  | ReceivedAction
  | SaveDaysAction
  | SaveDaysSucceededAction
  | ChangeDayTimeAction
  | SetDayAction
  | ResetAction
  | DelayAction
  | DelaySucceededAction;

export const fetchDays = (): FetchAction => ({
  type: "STARTUP_TIMES.FETCH"
});

export const receivedDays = (payload: UptimeParams): ReceivedAction => ({
  type: "STARTUP_TIMES.RECEIVED",
  payload
});

export const saveDays = (payload: UptimeParams): SaveDaysAction => ({
  type: "STARTUP_TIMES.SAVE_DAYS",
  payload
});

export const saveDaysSucceeded = (payload: UptimeParams): SaveDaysSucceededAction => ({
  type: "STARTUP_TIMES.SAVE_DAYS_SUCCEEDED",
  payload
});

export const setDay = (payload: UptimeDay): SetDayAction => ({
  type: "STARTUP_TIMES.SET_DAY",
  payload
});

export const changeDayTime = (payload: UptimeDay): ChangeDayTimeAction => ({
  type: "STARTUP_TIMES.CHANGE_DAY_TIME",
  payload
});

export const reset = (): ResetAction => ({
  type: "STARTUP_TIMES.RESET"
});

export const delay = (): DelayAction => ({
  type: "STARTUP_TIMES.DELAY"
});

export const delaySucceeded = (payload: TimeParams): DelaySucceededAction => ({
  type: "STARTUP_TIMES.DELAY_SUCCEEDED",
  payload
});
