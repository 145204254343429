import { useEffect } from "react";
import { captureException } from "@sentry/electron/renderer";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";

import { IconSmallInputError } from "../../../../resources/icons";
import { ROUTES } from "../../../commons/routes";
import actions from "../../actions";

import useDispatch from "./use-dispatch";
import useVeloconnectEndpoints from "./use-veloconnect-endpoints";

const useVeloconnectWatcher = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["commons"]);

  const { endpointsWithInvalidCredentials } = useVeloconnectEndpoints();

  useEffect(() => {
    const id = "useVeloconnectEndpointsHooks.invalidCredentialsNotification";

    if (endpointsWithInvalidCredentials) {
      if (endpointsWithInvalidCredentials.length > 0) {
        const brandsWithInvalidCredentials = endpointsWithInvalidCredentials.map(({ name }) => name);
        captureException(new Error("Invalid credentials for Veloconnect endpoints"), {
          extra: {
            message: `Invalid configuration for Veloconnect endpoints: ${brandsWithInvalidCredentials.join(", ")}`,
            location: "src/client/commons/libs/hooks/use-veloconnect-watcher.ts"
          }
        });

        dispatch(
          actions.toasts.showToast({
            id,
            icon: IconSmallInputError,
            kind: "negative",
            title: t("commons:useVeloconnectWatcher.invalidCredentialsNotification.title"),
            description: t("commons:useVeloconnectWatcher.invalidCredentialsNotification.description"),
            permanent: true,
            primaryAction: {
              label: t("commons:useVeloconnectWatcher.invalidCredentialsNotification.checkSettingButton"),
              callback: () => push(ROUTES.SETTINGS.VELOCONNECT)
            }
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run when the number of endpoints with invalid credentials changes
  }, [endpointsWithInvalidCredentials?.length]);
};

export default useVeloconnectWatcher;
