import {
  // eslint-disable-next-line no-restricted-imports
  connect as connectRedux,
  InferableComponentEnhancerWithProps,
  MapDispatchToPropsParam,
  MapStateToPropsParam
} from "react-redux";

// Supports up to 5 overrides in T
export type MapResultToPromise<T> = T extends {
  (...args: infer U1): infer R1;
  (...args: infer U2): infer R2;
}
  ? {
      (...args: U1): Promise<R1>;
      (...args: U2): Promise<R2>;
    }
  : T extends {
        (...args: infer U1): infer R1;
        (...args: infer U2): infer R2;
        (...args: infer U3): infer R3;
      }
    ? {
        (...args: U1): Promise<R1>;
        (...args: U2): Promise<R2>;
        (...args: U3): Promise<R3>;
      }
    : T extends {
          (...args: infer U1): infer R1;
          (...args: infer U2): infer R2;
          (...args: infer U3): infer R3;
          (...args: infer U4): infer R4;
        }
      ? {
          (...args: U1): Promise<R1>;
          (...args: U2): Promise<R2>;
          (...args: U3): Promise<R3>;
          (...args: U4): Promise<R4>;
        }
      : T extends {
            (...args: infer U1): infer R1;
            (...args: infer U2): infer R2;
            (...args: infer U3): infer R3;
            (...args: infer U4): infer R4;
            (...args: infer U5): infer R5;
          }
        ? {
            (...args: U1): Promise<R1>;
            (...args: U2): Promise<R2>;
            (...args: U3): Promise<R3>;
            (...args: U4): Promise<R4>;
            (...args: U5): Promise<R5>;
          }
        : T extends (...args: infer U) => infer R
          ? (...args: U) => Promise<R>
          : T;

type ResolveLoops<DispatchProps> = {
  [Property in keyof DispatchProps]: MapResultToPromise<DispatchProps[Property]>;
};

export const connect = <TStateProps, TDispatchProps, TOwnProps, State>(
  mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
  mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
): InferableComponentEnhancerWithProps<TStateProps & ResolveLoops<TDispatchProps>, TOwnProps> =>
  connectRedux(mapStateToProps, mapDispatchToProps);
