import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { prependServiceBaseUrl } from "../../../../commons/libs/client-utils";
import { ModalCloseOptions } from "../../../../commons/types/modal";
import { PdfMetaData } from "../../../../commons/types/pdf";
import { PrintType } from "../../../../commons/types/print-mail";
import * as icons from "../../../../resources/icons";
import actions from "../../actions";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import IconLabel from "../../components/IconLabel/IconLabel";
import PdfViewer from "../../components/PdfViewer/PdfViewer";
import Tooltip from "../../components/Tooltip/Tooltip";
import { connect } from "../../container/utils/loop";
import useFetchData from "../../libs/hooks/use-fetch-data";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import usePrintOrMail from "../../libs/hooks/use-print-or-mail";
import { getPdfMetadataFromUrl } from "../../libs/services/pdf-service/pdf";
import { State } from "../../reducers";

const mapStateToProps = (state: State) => ({
  bodyValues: state.session.bodyValues
});

const mapDispatchToProps = {
  showToast: actions.toasts.showToast,
  openPdfViewerModal: actions.modal.openPdfViewerModal
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OuterProps = {
  url: string;
  isOffline: boolean;
  isOfflineAndMailToPrintIsEnabled: boolean;
  hidePrint: boolean;
  hideSend: boolean;
  onClose: () => void;
};

type Props = ConnectedProps<typeof connector> & OuterProps;

const PdfViewerParial = ({
  url,
  isOffline,
  isOfflineAndMailToPrintIsEnabled,
  showToast,
  openPdfViewerModal,
  hidePrint = false,
  hideSend = false,
  onClose
}: Props) => {
  const { t } = useTranslation(["commons"]);
  const breakpoints = useMediaQuery();

  const { print, mail, isPrintingActive, isMailActive } = usePrintOrMail();

  const pdf = useFetchData<PdfMetaData>(() => getPdfMetadataFromUrl(url), [url]);

  const pages =
    (!pdf?.error && pdf.data && pdf.data.pages.length > 0 && pdf.data.pages.map(page => prependServiceBaseUrl(page))) ||
    [];

  if (pdf.error) {
    showToast({
      title: t("commons:pdfViewerPartialApp.errorWhileDisplayingPdfToast"),
      timeout: 5,
      icon: icons.IconSmallInputError,
      kind: "negative"
    });
    onClose();
  }

  return (
    <PdfViewer
      pages={pages}
      isLoading={pdf.isLoading}
      actionButtons={
        hidePrint && hideSend ? undefined : (
          <>
            {!hidePrint && isPrintingActive && (
              <Tooltip
                shouldCloseAutomatically
                content={
                  <IconLabel kind="negative" icon={<Icon source={icons.IconSmallAttention} />}>
                    {t("commons:pdfViewerPartialApp.offlineAndNoPrinterConnectedLabel")}
                  </IconLabel>
                }
              >
                {({ open }) => (
                  <IconButton
                    label={breakpoints.l ? t("commons:pdfViewerPartialApp.printLabel") : undefined}
                    icon={<Icon title="Drucken" source={icons.IconSmallPrinter} />}
                    disabled={pdf.isLoading || !!pdf.error}
                    onClick={() => {
                      if (isOfflineAndMailToPrintIsEnabled) {
                        open();
                      } else if (pdf.data) {
                        print(
                          {
                            type: PrintType.Default,
                            items: [
                              {
                                url: pdf.data.url,
                                date: new Date(),
                                title: t("commons:pdfViewerPartialApp.pdfPreview")
                              }
                            ]
                          },
                          (options?: ModalCloseOptions) => {
                            if (options?.preventReopenPreviousModal) {
                              openPdfViewerModal({
                                url,
                                isOffline,
                                isOfflineAndMailToPrintIsEnabled,
                                hidePrint,
                                hideSend
                              });
                            }
                          }
                        );
                      }
                    }}
                  />
                )}
              </Tooltip>
            )}

            {!hideSend && isMailActive && (
              <Tooltip
                shouldCloseAutomatically
                content={
                  <IconLabel kind="negative" icon={<Icon source={icons.IconSmallAttention} />}>
                    {t("commons:pdfViewerPartialApp.deviceOfflineLabel")}
                  </IconLabel>
                }
              >
                {({ open }) => (
                  <IconButton
                    label={breakpoints.l ? t("commons:pdfViewerPartialApp.sendButton") : undefined}
                    icon={
                      <Icon title={t("commons:pdfViewerPartialApp.sendButton")} source={icons.IconSmallSendArrow} />
                    }
                    disabled={pdf.isLoading || !!pdf.error}
                    onClick={() => {
                      if (isOffline) {
                        open();
                      } else if (pdf.data) {
                        mail(
                          {
                            type: PrintType.Default,
                            items: [
                              {
                                url: pdf.data.url,
                                date: new Date(),
                                title: t("commons:pdfViewerPartialApp.pdfPreview")
                              }
                            ]
                          },
                          (options?: ModalCloseOptions) => {
                            if (options?.preventReopenPreviousModal) {
                              openPdfViewerModal({
                                url,
                                isOffline,
                                isOfflineAndMailToPrintIsEnabled,
                                hidePrint,
                                hideSend
                              });
                            }
                          }
                        );
                      }
                    }}
                  />
                )}
              </Tooltip>
            )}
          </>
        )
      }
    />
  );
};

export default connector(PdfViewerParial);
