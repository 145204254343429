import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type Size = "default" | "s";

interface Props extends Component {
  title: string;
  phone: string;
  label: string;
  mail?: string;
  size?: Size;
  block?: boolean;
}

const Hotline = ({ classNames = [], title, phone, label, mail = "", size = "default", block = false }: Props) => (
  <div className={cn("Hotline", [{ [`size-${size}`]: size, block }], classNames)}>
    <div className="Hotline__title">{title}</div>
    <div className="Hotline__phone">{phone}</div>
    {!!mail && <div className="Hotline__mail">{mail}</div>}
    <div className="Hotline__label">{label}</div>
  </div>
);

export default Hotline;
