import { Location } from "history";
import qs from "qs";

export const buildPath = (
  pathTemplate: string,
  params: { [key: string]: string },
  query: { [key: string]: string } = {}
): string =>
  pathTemplate
    .split("/")
    .map(pathPart => {
      const matches = /^:(.+)$/.exec(pathPart);
      if (matches) {
        const param = params[matches[1]];
        if (param === undefined) {
          throw new Error(`Missing path parameter ${matches[1]}!`);
        }
        return encodeURIComponent(param);
      }
      return pathPart;
    })
    .join("/") + qs.stringify(query, { addQueryPrefix: true });

export const overwriteQueryInPath = <S = unknown>(
  { pathname, search }: Location<S>,
  query: { [key: string]: string }
) => {
  const nextQuery = {
    ...qs.parse(search, { ignoreQueryPrefix: true }),
    ...query
  };
  return `${pathname}${qs.stringify(nextQuery, { addQueryPrefix: true })}`;
};

export const removeLeadingTrailingSlashesInPath = (path: string) => {
  return path.replace(/^\/|\/$/g, "");
};

export const removeTrailingSlashInPath = (path: string) => {
  return path.replace(/\/$/g, "");
};

export const joinUrl = (baseUrl: string, path: string[]) => {
  return [removeTrailingSlashInPath(baseUrl), ...path.map(p => removeLeadingTrailingSlashesInPath(p))].join("/");
};
