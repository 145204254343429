import React from "react";

import * as icons from "../../../../resources/icons";
import Icon from "../../../commons/components/Icon/Icon";
import IconButton from "../../../commons/components/IconButton/IconButton";
import IconLink from "../../../commons/components/IconLink/IconLink";
import Modal from "../../../commons/components/Modal/Modal";
import DataPrivacyPartial from "../App/DataPrivacyPartial";

interface OuterProps {
  onClose: () => void;
  backButtonLabel?: string;
}

type Props = OuterProps;

const MailPrivacyModalPartial = ({ onClose, backButtonLabel }: Props) => (
  <Modal
    width="xl"
    closeButton={<IconButton onClick={onClose} icon={<Icon source={icons.IconSmallCross} />} />}
    backButton={
      <IconLink
        icon={<Icon source={icons.IconSmallArrowLeft} />}
        onClick={() => {
          onClose();
        }}
      >
        {backButtonLabel || "Zurück"}
      </IconLink>
    }
  >
    <DataPrivacyPartial />
  </Modal>
);

export default MailPrivacyModalPartial;
