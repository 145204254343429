import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  variant?: "primary" | "standout";
}

const InformationPageLayout = ({
  classNames = [],
  children,
  headerLeft = null,
  headerRight = null,
  variant = "primary"
}: Props) => (
  <div className={cn("InformationPageLayout", [{ [`variant-${variant}`]: variant }], classNames)}>
    <header className="InformationPageLayout__header">
      <div className="InformationPageLayout__headerLeft">{headerLeft}</div>
      <div className="InformationPageLayout__headerRight">{headerRight}</div>
    </header>
    <div className="InformationPageLayout__content">{children}</div>
  </div>
);

export default InformationPageLayout;
