import * as sentryUtils from "../../../../commons/libs/sentry-utils";

export interface Sentry {
  initSentry: ReturnType<typeof sentryUtils.initSentry>;
  captureException: ReturnType<typeof sentryUtils.captureException>;
  configureServiceTagAsUsername: ReturnType<typeof sentryUtils.configureServiceTagAsUsername>;
}

const initSentry = (sentry: sentryUtils.Sentry): Sentry => ({
  initSentry: sentryUtils.initSentry(sentry),
  captureException: sentryUtils.captureException(sentry),
  configureServiceTagAsUsername: sentryUtils.configureServiceTagAsUsername(sentry)
});

export default initSentry;
