import React from "react";
import { push } from "connected-react-router";
import { find } from "lodash";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";

import { NavigationConfigId } from "../../../../commons/config/customer-groups";
import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import { getSpecConfig } from "../../../../commons/specs";
import {
  CategoryGroupDefinition,
  CategoryGroupKeyMap,
  CategoryMap,
  ProductSpecKey,
  ProductType
} from "../../../../commons/specs/product";
import { GlobalLocationState } from "../../../../commons/types/location";
import landscapeIllustration from "../../../../resources/images/product/illustration-landscape.png";
import actions from "../../actions";
import CategoryListItem from "../../components/CategoryListItem/CategoryListItem";
import CenteredContent from "../../components/CenteredContent/CenteredContent";
import DistributedContent from "../../components/DistributedContent/DistributedContent";
import Headline from "../../components/Headline/Headline";
import ItemList from "../../components/ItemList/ItemList";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import MultiColumnItemList from "../../components/MultiColumnItemList/MultiColumnItemList";
import { addActiveFilterValue, hasActiveFilters, isActiveFilter, removeActiveFilterValue } from "../../libs/filters";
import useProductFilter from "../../libs/queries/use-product-filter";
import useProducts from "../../libs/queries/use-products";
import {
  selectAssortmentFilterSettings,
  selectAssortmentPriceSettings,
  selectInitializedEnv,
  selectInitializedSettings
} from "../../libs/selectors";
import { State } from "../../reducers";
import { ROUTES } from "../../routes";
import { connect } from "../utils/loop";

import FooterContentPartial from "./FooterContentPartial";

const mapStateToProps = (state: State) => ({
  currency: selectInitializedSettings(state).currency,
  activeFilters: state.session.activeFilters,
  customerGroup: getCustomerGroupConfig(selectInitializedEnv(state).customerGroup),
  assortment: selectInitializedSettings(state).assortment,
  assortmentFilterSettings: selectAssortmentFilterSettings(state),
  assortmentPriceSettings: selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OuterProps) => ({
  setActiveFilters: bindActionCreators(actions.session.setActiveFilters, dispatch),
  onError: bindActionCreators(actions.error.set, dispatch),
  onSubmit: () => {
    dispatch(
      push(ROUTES.PRODUCT_FINDER.INDEX, {
        ...(ownProps.location.state ?? {}),
        // If `goBackToDetails` is set, we know the user visited details content page before
        // We reset that state to prevent the user from being redirected to details page after submitting the searchTerm
        goBackToDetails: undefined
      })
    );
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps extends RouteComponentProps<EmptyObject, EmptyObject, GlobalLocationState> {
  productFilter: ReturnType<typeof useProductFilter>;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const backgroundImageMap: Record<NavigationConfigId, string | undefined> = {
  [NavigationConfigId.Bicycle]: landscapeIllustration,
  [NavigationConfigId.Motorcycle]: undefined
};

const CategoriesContent = ({ onSubmit, currency, assortmentPriceSettings, customerGroup, productFilter }: Props) => {
  const { t } = useTranslation(["commons"]);

  /** TODO: Get productType from Product.
   * @see BCD-6701 Integrate motorcycles into content service
   */
  const productType = customerGroup.productTypes[0];
  const specConfigs = customerGroup.productTypes.map(getSpecConfig);

  const allCategoryGroups = specConfigs.reduce((acc, specConfig) => {
    return [...acc, ...specConfig.categoryGroups];
  }, [] as CategoryGroupDefinition[]);

  const products = useProducts({
    productType,
    currency,
    assortmentPriceSettings,
    activeFilters: productFilter.activeFilters,
    limit: 0
  });

  const renderGroups = allCategoryGroups
    .map(group => {
      const categories = group.categories.filter(category => {
        const categoryFilterValues = productFilter.query.data?.[ProductSpecKey.CategoryKey];
        return find(categoryFilterValues, filterValue => filterValue.value === category);
      });

      if (categories.length === 0) {
        return null;
      }

      return (
        <React.Fragment key={group.key}>
          <Headline>
            {t(
              `commons:specs.${group.productType}.categoryGroup.${group.key}` as keyof {
                [T in ProductType as `commons:specs.${T}.categoryGroup.${CategoryGroupKeyMap[T]}`]: null;
              }
            )}
          </Headline>
          <ItemList>
            {categories.map(category => {
              const active = isActiveFilter(productFilter.activeFilters, ProductSpecKey.CategoryKey, category);

              return (
                <CategoryListItem
                  key={category}
                  label={t(
                    `commons:specs.${group.productType}.categories.${category}.default` as keyof {
                      [T in ProductType as `commons:specs.${T}.categories.${CategoryMap[T]}.default`]: null;
                    }
                  )}
                  active={active}
                  onClick={() =>
                    productFilter.setActiveFilters(
                      active
                        ? removeActiveFilterValue(productFilter.activeFilters, ProductSpecKey.CategoryKey, category)
                        : addActiveFilterValue(productFilter.activeFilters, ProductSpecKey.CategoryKey, category)
                    )
                  }
                />
              );
            })}
          </ItemList>
        </React.Fragment>
      );
    })
    .filter(group => !!group);

  return (
    <DistributedContent
      imageSrc={backgroundImageMap[customerGroup.navigation]}
      footer={
        <FooterContentPartial
          isLoading={products.query.isFetching}
          activeFilters={productFilter.activeFilters}
          resultCount={products.query.data?.totalFiltered ?? 0}
          onFilterChange={productFilter.setActiveFilters}
          onFilterReset={() => {
            productFilter.clearAllFilters();
          }}
          onSubmit={onSubmit}
          isFilterResetButtonDisabled={!hasActiveFilters(productFilter.activeFilters)}
        />
      }
    >
      <CenteredContent
        childrenWidth={productFilter.query.isFetching ? "auto" : "full"}
        classNames={["u-padding-top-xxl", "u-space-xxl-media-s-to-l"]}
      >
        {productFilter.query.isFetching ? (
          <LoadingIndicator classNames={["u-padding-top-xxxxl"]} key="loading-indicator" />
        ) : (
          <MultiColumnItemList gap={renderGroups.length < 4 ? "xxl" : "base"}>{renderGroups}</MultiColumnItemList>
        )}
      </CenteredContent>
    </DistributedContent>
  );
};

export default connector(CategoriesContent);
