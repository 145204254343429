import { Product, ProductId } from "../specs/product";

import { ProductDetailsAdvertiser } from "./advertisers";
import { Currency } from "./currency";
import { AssortmentPriceSettings } from "./settings";

export enum PrintType {
  Default = "default",
  ProductDataSheet = "printProductDataSheet"
}

export interface ProductPrintDataItem {
  productId: ProductId;
  product: Product;
  advertisement?: ProductPrintAdvertisement;
}

export interface UrlPrintDataItem {
  url: string;
  title: string;
  date: Date;
}

export interface ProductPrintData {
  type: PrintType.ProductDataSheet;
  subject?: string;
  bikeCenterName: string;
  showPrices: boolean;
  currency: Currency;
  items: ProductPrintDataItem[];
  showAssortmentPrices?: boolean;
  showFallbackPrices?: boolean;
  showOriginalPrices?: boolean;
  useRrpAsOriginalPrice?: boolean;
  showSaleBadge?: AssortmentPriceSettings["showSaleBadge"];
  initiator: string;
}

export interface UrlPrintData {
  type: PrintType.Default;
  items: UrlPrintDataItem[];
}

export type PrintMailData = ProductPrintData | UrlPrintData;

export interface ProductPrintAdvertisement {
  advertiser: ProductDetailsAdvertiser;
}

export interface AdacProductPrintAdvertisement extends ProductPrintAdvertisement {
  advertiser: ProductDetailsAdvertiser.Adac;
  distributorNumber: string;
}

export interface AlteosProductPrintAdvertisement extends ProductPrintAdvertisement {
  advertiser: ProductDetailsAdvertiser.Alteos;
  shopUrl: string;
}

export interface ZegProductPrintAdvertisement extends ProductPrintAdvertisement {
  advertiser: ProductDetailsAdvertiser.ZegPlusGarantie;
  marketCode: string;
}

export enum ProductPrintTableCellType {
  Specs = "SPECS",
  Ad = "AD"
}

export interface ProductPrintTableCell {
  key: string;
  label: string;
  type: ProductPrintTableCellType;
}

export interface ProductPrintTableCellSpec {
  label: string;
  value: string;
}

export interface ProductPrintTableCellSpecs extends ProductPrintTableCell {
  type: ProductPrintTableCellType.Specs;
  specs: ProductPrintTableCellSpec[];
}

export interface ProductPrintTableCellAdvertisement extends ProductPrintTableCell {
  type: ProductPrintTableCellType.Ad;
  htmlBody: string;
}
