import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  aside?: React.ReactNode;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

const ProductFinderPageLayout = ({
  classNames = [],
  children,
  headerLeft = null,
  headerRight = null,
  aside = null,
  scrollRef
}: Props) => (
  <section className={cn("ProductFinderPageLayout", [{ aside }], classNames)}>
    <header className="ProductFinderPageLayout__header">
      <div className="ProductFinderPageLayout__headerLeft">{headerLeft}</div>
      <div className="ProductFinderPageLayout__headerRight">{headerRight}</div>
    </header>
    <div className="ProductFinderPageLayout__scrollCanvas" ref={scrollRef}>
      <div className="ProductFinderPageLayout__content">{children}</div>
    </div>
    {aside && <aside className="ProductFinderPageLayout__aside">{aside}</aside>}
  </section>
);

export default ProductFinderPageLayout;
