import React from "react";
import { push, replace } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";

import { Product, ProductId, ProductKey } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import { GlobalLocationState } from "../../../../commons/types/location";
import { ToolKey } from "../../../../commons/types/tool";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Icon from "../../../commons/components/Icon/Icon";
import IconLink from "../../../commons/components/IconLink/IconLink";
import TabBarOverlay from "../../../commons/components/TabBarOverlay/TabBarOverlay";
import { connect } from "../../../commons/container/utils/loop";
import { useTrackingContext } from "../../../commons/container/utils/tracking-context";
import { buildPath } from "../../../commons/libs/path";
import { ROUTES } from "../../../commons/routes";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import useOnMount from "../../libs/hooks/use-on-mount";
import useAvailableBrands from "../../libs/queries/use-available-brands";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import ProductTitlePartial from "../partials/ProductTitlePartial";
import { useVeloconnectEndpointContext } from "../utils/veloconnect-endpoint-context";

import VariantsContentPartial from "./VariantsContentPartial";

const mapStateToProps = (state: State) => ({
  isWawiConfigured: selectors.selectIsWawiConfigured(state)
});

const mapDispatchToProps = {
  onReplace: replace,
  onPush: push,
  openAuthModal: actions.modal.openAuthModal
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  variantId?: ProductId;
  product: Product;
  brand: Brand;
  hasInternetConnectivity: boolean;
  modal?: React.ReactNode;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const AvailabilityOverlay = ({
  variantId,
  product,
  brand,
  onReplace,
  isWawiConfigured,
  hasInternetConnectivity,
  modal,
  onPush
}: Props) => {
  const { t } = useTranslation(["commons"]);
  const veloconnectEndpoint = useVeloconnectEndpointContext();

  const breakpoints = useMediaQuery();
  const location = useLocation<GlobalLocationState>();
  const { mixpanel } = useTrackingContext();

  const { getAvailableBrand } = useAvailableBrands();

  const isMobile = !breakpoints.l;

  const isAvailabilityActive = getAvailableBrand(brand.key)?.availabilityPossible ?? false;

  const isVeloconnectActive = !veloconnectEndpoint.isLoading && !!veloconnectEndpoint?.endpoint?.isConfigured;
  const shouldShowVeloconnectNotification =
    !veloconnectEndpoint.isLoading && veloconnectEndpoint.shouldShowNotification;
  const isWawiActive = isAvailabilityActive && isWawiConfigured;

  useOnMount(() => {
    mixpanel?.trackProductAvailabilityViewed(product, isWawiActive, isVeloconnectActive);
  });

  const renderIconLinkLabel = () => {
    const renderLabel = () => {
      const shouldRenderAvailabilityHeadline =
        (isAvailabilityActive && shouldShowVeloconnectNotification) || isVeloconnectActive || isWawiActive;

      const mainHeadline = shouldRenderAvailabilityHeadline
        ? t("commons:availabilityOverlayProductDetails.variantsAvailabilityHeadline")
        : t("commons:availabilityOverlayProductDetails.variantsHeadline");

      return t("commons:availabilityOverlayProductDetails.closeHeadlineButton", { headline: mainHeadline });
    };

    if (isMobile) {
      return t("commons:availabilityOverlayProductDetails.closeButton");
      // We have to wait until the loading state of the Veloconnect Proxy is finished, to prevent a premature rendering of the text.
    } else if (veloconnectEndpoint.isLoading) {
      return undefined;
    } else {
      return renderLabel();
    }
  };

  return (
    <TabBarOverlay
      noContentPadding
      headerLeft={
        <IconLink
          size={isMobile ? "s" : "base"}
          href={buildPath(ROUTES.PRODUCT_DETAILS.INDEX, { id: product[ProductKey.ProductId] })}
          icon={<Icon source={icons.IconSmallAngleDown} />}
          onClick={(_event: React.MouseEvent<HTMLElement>, href: string) => {
            onReplace(href, location.state);
          }}
          classNames={["u-extended-click-area"]}
        >
          {renderIconLinkLabel()}
        </IconLink>
      }
      headerCenter={
        <ProductTitlePartial
          product={product}
          minFontSize={isMobile ? 16 : undefined}
          maxFontSize={isMobile ? 20 : undefined}
        />
      }
    >
      {modal}
      <VariantsContentPartial
        variantId={variantId}
        product={product}
        hasInternetConnectivity={hasInternetConnectivity}
        openFrameSizeCalculatorTool={() => {
          const frameSizeCalculatorHref = buildPath(ROUTES.TOOL.INDEX, { key: ToolKey.BikeFrameSizeCalculator });
          onPush(frameSizeCalculatorHref);
        }}
      />
    </TabBarOverlay>
  );
};

export default connector(AvailabilityOverlay);
