import { i18n } from "i18next";

import { UpstreamItemStatus } from "../libs/externals/veloconnect-proxy";
import { formatPrice } from "../libs/formatters";
import { getSingleSpecValue } from "../libs/specs";
import {
  AutomaticAssortmentAvailabilityState,
  AvailabilityState,
  AvailabilityStates,
  ManualAssortmentAvailabilityState
} from "../types/availability";
import { Currency } from "../types/currency";
import { PriceSource } from "../types/price";

// Has to be import type to avoid circular dependencies.
import type { BicycleCategory, BicycleCategoryGroupKeys, BicycleSpec, BicycleSpecGroupKey } from "./bicycle";
import type { AssortmentFilterKeys, FilterConfig, FilterGroups } from "./filters";
import type {
  MotorcycleCategory,
  MotorcycleCategoryGroupKeys,
  MotorcycleSpec,
  MotorcycleSpecGroupKey
} from "./motorcycle";

// List all available product types here.
// Keep in sync with file names in this folder.
export enum ProductType {
  Bicycle = "bicycle",
  Motorcycle = "motorcycle"
}

export type SpecMap = {
  [ProductType.Bicycle]: BicycleSpec;
  [ProductType.Motorcycle]: MotorcycleSpec;
};

export type SpecGroupKeyMap = {
  [ProductType.Bicycle]: BicycleSpecGroupKey;
  [ProductType.Motorcycle]: MotorcycleSpecGroupKey;
};

export type Category = string;
export type CategoryGroupKey = string;
export type CategoryMap = {
  [ProductType.Bicycle]: BicycleCategory;
  [ProductType.Motorcycle]: MotorcycleCategory;
};

export type CategoryGroupKeyMap = {
  [ProductType.Bicycle]: BicycleCategoryGroupKeys;
  [ProductType.Motorcycle]: MotorcycleCategoryGroupKeys;
};

export enum ProductSpecKey {
  ModelName = "modelName", // TODO: rename to name
  ModelYear = "modelYear",
  BrandId = "brandId",
  BrandName = "brandName",
  BrandKey = "brandKey",
  ArticleNumber = "articleNumber",
  Gtin = "GTIN",
  Upc = "UPC",
  Price = "price",
  PriceSource = "priceSource",
  OriginalPrice = "originalPrice",
  OriginalPriceSource = "originalPriceSource",
  DiscountPercentage = "discountPercentage",
  Rrp = "rrp",
  RrpFrom = "rrpFrom",
  Currency = "currency",
  RrpChf = "rrpCHF",
  RrpDkk = "rrpDKK",
  RrpGbp = "rrpGBP",
  RrpSource = "rrpSource",
  ImageUrl = "imageUrl",
  ImageFile = "imageFile",
  MainImageFile = "mainImageFile",
  MainImageUrl = "mainImageUrl",
  ConfiguratorUrl = "configuratorUrl",
  ProductHighlightsToolKey = "productHighlightsToolKey",
  ManualAssortment = "manualAssortment",
  AutomaticAssortment = "automaticAssortment",
  WawiAvailability = "wawiAvailability",
  VeloconnectAssortment = "veloconnectAssortment",
  VeloconnectAvailability = "veloconnectAvailability",
  Availability = "availability",
  CategoryKey = "categoryKey",
  CategoryName = "categoryName"
}

// TODO: Use undefined instead of null, let the content service cleanup the data accordingly.
export interface ProductSpec {
  [ProductSpecKey.BrandId]: number;
  [ProductSpecKey.BrandName]: string;
  [ProductSpecKey.BrandKey]: string;
  [ProductSpecKey.ModelName]: string;
  [ProductSpecKey.ModelYear]?: number;
  [ProductSpecKey.ArticleNumber]?: string | null;
  [ProductSpecKey.Gtin]?: string | null;
  [ProductSpecKey.Upc]?: string | null;
  [ProductSpecKey.Rrp]?: number | null;
  [ProductSpecKey.RrpFrom]?: boolean;
  [ProductSpecKey.Currency]: Currency;
  [ProductSpecKey.RrpChf]?: number | null;
  [ProductSpecKey.RrpDkk]?: number | null;
  [ProductSpecKey.RrpGbp]?: number | null;
  [ProductSpecKey.Price]?: number | null;
  [ProductSpecKey.PriceSource]: PriceSource | null;
  [ProductSpecKey.OriginalPrice]?: number | null;
  [ProductSpecKey.OriginalPriceSource]?: PriceSource | null;
  [ProductSpecKey.DiscountPercentage]?: number | null;
  [ProductSpecKey.RrpSource]: PriceSource | null;
  [ProductSpecKey.ImageUrl]?: string | null;
  [ProductSpecKey.ImageFile]?: string | null;
  [ProductSpecKey.MainImageUrl]?: string | null; // Used to override imageUrl.
  [ProductSpecKey.MainImageFile]?: string | null; // Used to override imageFile.
  [ProductSpecKey.AutomaticAssortment]?: AutomaticAssortmentAvailabilityState[] | null;
  [ProductSpecKey.ManualAssortment]?: ManualAssortmentAvailabilityState;
  [ProductSpecKey.WawiAvailability]?: AvailabilityStates;
  [ProductSpecKey.Availability]?: AvailabilityState;
  [ProductSpecKey.VeloconnectAssortment]?: UpstreamItemStatus[] | null;
  [ProductSpecKey.VeloconnectAvailability]?: AvailabilityStates;
  [ProductSpecKey.ConfiguratorUrl]?: string | null;
  [ProductSpecKey.ProductHighlightsToolKey]?: string | null;
  [ProductSpecKey.CategoryKey]: Category;
  [ProductSpecKey.CategoryName]: string;
}

export type ProductId = string;

export enum ProductKey {
  ProductId = "productId",
  ProductType = "productType",
  VariantId = "variantId",
  Variants = "variants"
}

export type ProductVariant<Type extends ProductType = ProductType> = Partial<SpecMap[Type]> & {
  [ProductKey.VariantId]: ProductId;
};

export type Product<Type extends ProductType = ProductType> = Partial<SpecMap[Type]> & {
  [ProductKey.ProductId]: ProductId;
  [ProductKey.ProductType]: Type;
  [ProductKey.VariantId]?: ProductId; // Set in root if product has only one variant.
  [ProductKey.Variants]: ProductVariant<Type>[]; // Empty array if product has only one variant.
};

export type StandaloneProductVariant<Type extends ProductType = ProductType> = SpecMap[Type] & {
  [ProductKey.ProductId]: ProductId;
  [ProductKey.ProductType]: Type;
  [ProductKey.VariantId]: ProductId; // Set in root if product has only one variant.
};

export const productTypeToPostfix = {
  [ProductType.Bicycle]: "bikes",
  [ProductType.Motorcycle]: "motorcycles"
};

type Concat<T extends (string | number | symbol)[]> = T extends [infer F, ...infer R]
  ? F extends string
    ? R extends string[]
      ? `${F}${Concat<R>}`
      : never
    : never
  : "";

export type SpecDefinition<Type extends ProductType, K extends keyof SpecMap[Type]> = {
  visibleValue?: (value: NonNullable<SpecMap[Type][K]>) => boolean;
  // Only used in conjunction with formatValues.
  visibleValues?: (values: NonNullable<SpecMap[Type][K]>[]) => NonNullable<SpecMap[Type][K]>[];
  formatValue?: (
    value: NonNullable<SpecMap[Type][K]>,
    variant: StandaloneProductVariant<Type>,
    localization: {
      i18n: i18n;
      i18nSpecKey: Concat<["commons:specs.", Type, ".specDefinitions.", K]>;
    }
  ) => string | undefined;
  formatValues?: (
    values: NonNullable<SpecMap[Type][K]>[],
    product: Product<Type>,
    localization: {
      i18n: i18n;
      i18nSpecKey: Concat<["commons:specs.", Type, ".specDefinitions.", K]>;
    }
  ) => string[] | string | undefined;
  // Default is false if not set.
  enable?: {
    variantInformationBox?: boolean;
    variantsTable?: boolean;
    variantFilters?: boolean;
  };
};

export type SpecDefinitions<Type extends ProductType> = {
  [K in keyof SpecMap[Type]]?: SpecDefinition<Type, K>;
};

export type SpecDefinitionUseCase = keyof Required<SpecDefinition<ProductType, keyof ProductSpec>>["enable"];

export type SpecCompareDefinition<Type extends ProductType> = (keyof SpecMap[Type])[];

export type SpecGroup<Type extends ProductType> = {
  key: SpecGroupKeyMap[Type];
  specKeys: (keyof SpecMap[Type])[];
};

export type CategoryGroupDefinition = {
  key: CategoryGroupKey;
  productType: ProductType;
  categories: Category[];
};

export type SpecConfig<Type extends ProductType = ProductType> = {
  productType: Type;
  specDefinitions: SpecDefinitions<Type>;
  specGroups: SpecGroup<Type>[];
  specCompareDefinition: SpecCompareDefinition<Type>;
  variantsTableFallbackSpecKeys: (keyof SpecMap[Type])[];
  variantsTableSpecKeysPriorityList: (keyof SpecMap[Type])[];
  variantInformationBoxSpecKeyPriorityList: (keyof SpecMap[Type])[];
  filterConfig: FilterConfig<Type>;
  filterGroups: FilterGroups<Type>;
  assortmentFilterKeys: AssortmentFilterKeys<Type>;
  categoryGroups: CategoryGroupDefinition[];
  categories: Category[];
  categoryGroupKeys: CategoryGroupKey[];
};

export type TypeOfProduct<T> = T extends Product<infer Type> ? Type : never;

// This is a whitelist.
// Only keys which are listed here will be shown.
export const specDefinitions: SpecDefinitions<ProductType> = {
  [ProductSpecKey.BrandId]: {},
  [ProductSpecKey.BrandName]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [ProductSpecKey.BrandKey]: {},
  [ProductSpecKey.ModelName]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [ProductSpecKey.ModelYear]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [ProductSpecKey.ArticleNumber]: {
    enable: {
      variantInformationBox: true
    }
  },
  [ProductSpecKey.Gtin]: {
    enable: {
      variantInformationBox: true
    }
  },
  [ProductSpecKey.Upc]: {
    enable: {
      variantInformationBox: true
    }
  },
  [ProductSpecKey.Price]: {
    enable: {
      variantInformationBox: true
    },
    visibleValue: value => value > 0,
    formatValue: (value, variant, { i18n, i18nSpecKey }) => {
      const price = formatPrice(value, variant[ProductSpecKey.Currency], i18n.language);

      if (variant[ProductSpecKey.RrpFrom]) {
        return i18n.t(`${i18nSpecKey}.fromPriceValue`, { price });
      } else {
        return price;
      }
    },
    formatValues: (values, product, { i18n, i18nSpecKey }) => {
      if (values.length === 0) {
        return undefined;
      } else {
        const currency = getSingleSpecValue(ProductSpecKey.Currency, product);
        const priceFrom = getSingleSpecValue(ProductSpecKey.RrpFrom, product) || values.length > 1;
        const price = formatPrice(Math.min(...values), currency, i18n.language);

        if (priceFrom) {
          return i18n.t(`${i18nSpecKey}.fromPriceValue`, { price });
        } else {
          return price;
        }
      }
    }
  },
  [ProductSpecKey.OriginalPrice]: {
    visibleValue: value => value > 0,
    formatValue: (value, variant, { i18n }) => {
      return formatPrice(value, variant[ProductSpecKey.Currency], i18n.language);
    },
    formatValues: (values, product, { i18n }) => {
      if (values.length === 0) {
        return undefined;
      } else {
        const currency = getSingleSpecValue(ProductSpecKey.Currency, product);
        return formatPrice(Math.min(...values), currency, i18n.language);
      }
    }
  },
  [ProductSpecKey.DiscountPercentage]: {
    visibleValue: value => value > 0,
    formatValue: value => {
      return `${Math.floor(value * 100)} %`;
    },
    formatValues: (values, product, { i18n, i18nSpecKey }) => {
      if (values.length === 0) {
        return undefined;
      } else {
        return i18n.t(`${i18nSpecKey}.toDiscountValue`, {
          discount: `${Math.floor(Math.max(...values) * 100)} %`
        });
      }
    }
  },
  [ProductSpecKey.Rrp]: {
    visibleValue: value => value > 0,
    formatValue: (value, variant, { i18n, i18nSpecKey }) => {
      const price = formatPrice(value, variant[ProductSpecKey.Currency], i18n.language);

      if (variant[ProductSpecKey.RrpFrom]) {
        return i18n.t(`${i18nSpecKey}.fromPriceValue`, { price });
      } else {
        return price;
      }
    },
    formatValues: (values, product, { i18n, i18nSpecKey }) => {
      if (values.length === 0) {
        return undefined;
      } else {
        const currency = getSingleSpecValue(ProductSpecKey.Currency, product);
        const rrpFrom = getSingleSpecValue(ProductSpecKey.RrpFrom, product) || values.length > 1;
        const price = formatPrice(Math.min(...values), currency, i18n.language);

        if (rrpFrom) {
          return i18n.t(`${i18nSpecKey}.fromPriceValue`, { price });
        } else {
          return price;
        }
      }
    }
  },
  [ProductSpecKey.RrpFrom]: {},
  [ProductSpecKey.ImageUrl]: {},
  [ProductSpecKey.ImageFile]: {},
  [ProductSpecKey.MainImageUrl]: {},
  [ProductSpecKey.MainImageFile]: {},
  [ProductSpecKey.ConfiguratorUrl]: {},
  [ProductSpecKey.ProductHighlightsToolKey]: {},
  [ProductSpecKey.CategoryKey]: {},
  [ProductSpecKey.CategoryName]: {}
};
