import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Toast from "../Toast/Toast";

// If you want to change this value you also have to adapt the CSS code to support it!
const MAX_TOASTS_TO_SHOW = 3;

export type ToastAlignment = "sidebar-left" | "center" | "sidebar-right";

interface Props extends Component {
  children: React.ReactNode;
  toasts: Array<React.ReactElement<React.ComponentProps<typeof Toast>>>;
  toastAlignment?: ToastAlignment;
}

const ToastLayout = ({ classNames = [], children, toasts, toastAlignment = "center" }: Props) => {
  const visibleToasts = toasts.slice(-1 * MAX_TOASTS_TO_SHOW);

  return (
    <div className={cn("ToastLayout", [{ [`align-${toastAlignment}`]: !!toastAlignment }], classNames)}>
      <ul className="ToastLayout__toasts">
        <AnimatePresence>
          {visibleToasts.map((toast: React.ReactElement<React.ComponentProps<typeof Toast>>, index) => (
            <motion.li
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              key={toast.key}
              className="ToastLayout__toast"
              data-index={index}
              data-rev-index={visibleToasts.length - index - 1}
            >
              <span className="ToastLayout__toastInner">{toast}</span>
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
      <div className="ToastLayout__content">{children}</div>
    </div>
  );
};

export default ToastLayout;
