import { CurrencyCode } from "../libs/externals/wawi-proxy";
import { ProductSpecKey } from "../specs/product";

import { Region } from "./localization";

export enum Currency {
  Eur = "EUR",
  Chf = "CHF",
  Dkk = "DKK",
  Gbp = "GBP"
}

export enum CurrencySymbol {
  Eur = "€",
  Chf = "CHF",
  Dkk = "Kr.",
  Gbp = "£"
}

type RrpSpecKeys = ProductSpecKey.Rrp | ProductSpecKey.RrpChf | ProductSpecKey.RrpDkk | ProductSpecKey.RrpGbp;

export const CURRENCY_RRP_MAP: Record<Currency, RrpSpecKeys> = {
  [Currency.Chf]: ProductSpecKey.RrpChf,
  [Currency.Dkk]: ProductSpecKey.RrpDkk,
  [Currency.Eur]: ProductSpecKey.Rrp,
  [Currency.Gbp]: ProductSpecKey.RrpGbp
};

export const REGION_CURRENCY_MAP: Record<Region, Currency> = {
  [Region.De]: Currency.Eur,
  [Region.At]: Currency.Eur,
  [Region.Dk]: Currency.Dkk,
  [Region.Es]: Currency.Eur,
  [Region.Gb]: Currency.Gbp,
  [Region.Nl]: Currency.Eur
};

export const CURRENCY_SYMBOL_MAP: Record<Currency, CurrencySymbol> = {
  [Currency.Eur]: CurrencySymbol.Eur,
  [Currency.Chf]: CurrencySymbol.Chf,
  [Currency.Dkk]: CurrencySymbol.Dkk,
  [Currency.Gbp]: CurrencySymbol.Gbp
};

export const CURRENCY_CODE_CURRENCY_MAP: Record<CurrencyCode, Currency> = {
  [CurrencyCode.EUR]: Currency.Eur,
  [CurrencyCode.CHF]: Currency.Chf,
  [CurrencyCode.DKK]: Currency.Dkk
};
