import { getFromService } from "../../../../../commons/libs/client-utils";
import { ServiceAvailabilityResponse } from "../../../../../commons/types/service-availability";

export const getServiceAvailability = async (): Promise<ServiceAvailabilityResponse> => {
  const response: ServiceAvailabilityResponse = await getFromService("service-availability", "/");
  return {
    ...response,
    startupDate: new Date(response.startupDate)
  };
};
