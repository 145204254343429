import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Headline from "../Headline/Headline";

interface Props extends Component {
  label: string;
  children?: string;
  placeholder?: string;
}

const SpecsItem = ({ classNames = [], label, children = "", placeholder = "" }: Props) => {
  return (
    <div className={cn("SpecsItem", [{ empty: !children }], classNames)}>
      <Headline kind="xs" tag="strong">
        {label}
      </Headline>
      <div className="SpecsItem__text">{children ? children : placeholder}</div>
    </div>
  );
};

export default SpecsItem;
