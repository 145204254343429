import React from "react";
import { noop } from "lodash";

interface UseIntersectOptions {
  root?: Element;
  rootMargin?: string;
  threshold?: number | number[];
  once?: boolean;
}

export default ({ root, rootMargin, threshold = 0, once = false }: UseIntersectOptions = {}) => {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry | null>(null);
  const [node, setNode] = React.useState<Element | null>();

  const observer = React.useRef<IntersectionObserver | null>(null);
  const entryRef = React.useRef<IntersectionObserverEntry | null>(entry);

  React.useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    if (once && entryRef.current?.isIntersecting) {
      return noop;
    }

    observer.current = new IntersectionObserver(
      ([e]) => {
        setEntry(e);

        if (once && e.isIntersecting) {
          observer.current?.disconnect();
        }
      },
      {
        root,
        rootMargin,
        threshold
      }
    );

    if (node) {
      observer.current.observe(node);
    }

    return () => observer.current?.disconnect();
  }, [node, root, rootMargin, threshold, entryRef, once]);

  return [setNode, entry] as const;
};
