import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  href?: string;
  onClick?: (event: React.MouseEvent, href?: string) => void;
}

const Link = ({ classNames = [], children, href = "#", onClick = noop }: Props) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onClick(event, href);
  };

  return (
    <a className={cn("Link", [], classNames)} href={href} onClick={handleClick}>
      {children}
    </a>
  );
};

export default Link;
