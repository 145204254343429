import { strict as assert } from "assert";
import { difference, flatten, groupBy, isEmpty, isNil, uniq } from "lodash";

import {
  CategoryMap,
  Product,
  ProductId,
  ProductKey,
  ProductSpec,
  ProductSpecKey,
  ProductType,
  ProductVariant,
  SpecDefinition,
  SpecDefinitions,
  SpecDefinitionUseCase,
  SpecGroup,
  SpecMap,
  StandaloneProductVariant
} from "../specs/product";

import shallowOmit from "./shallow-omit";

export type SpecItem<Spec extends ProductSpec> = {
  [K in keyof Spec]: {
    key: K;
    values: Array<NonNullable<Spec[K]>>;
  };
}[keyof Spec];

export interface GroupedSpecItems<Spec extends ProductSpec> {
  key: string;
  specs: SpecItem<Spec>[];
}

export interface VariantFilterValues<Type extends ProductType, K extends keyof SpecMap[Type]> {
  variant: StandaloneProductVariant<Type>;
  value: StandaloneProductVariant<Type>[K];
}

const isValueNotNilAndNotEmpty = <V>(value: V): value is NonNullable<V> => !isNil(value) && value !== "";

export const isStandaloneProductVariant = <Type extends ProductType>(
  product: Product<Type> | StandaloneProductVariant<Type>
): product is StandaloneProductVariant<Type> => !(ProductKey.Variants in product);

export const isProduct = <Type extends ProductType>(
  product: Product<Type> | StandaloneProductVariant<Type>
): product is Product<Type> => ProductKey.Variants in product;

/**
 * Returns all unique, non-empty and non-nil values for a given spec key of all variants in a product.
 */
export const getAllSpecValues = <Type extends ProductType, K extends keyof SpecMap[Type]>(
  specKey: K,
  product: Product<Type>
): Array<NonNullable<SpecMap[Type][K]>> => {
  const values: Array<Product<Type>[K] | ProductVariant<Type>[K]> =
    specKey in product ? [product[specKey]] : product[ProductKey.Variants].map(value => value[specKey]);

  return uniq(values as Partial<SpecMap[Type]>[K][]).filter(isValueNotNilAndNotEmpty);
};

/**
 * Returns the first value for a given spec key in a product.
 * Use this instead of getAllSpecValues if you are only interested in a single value of a spec key
 * e.g. like rendering the modelName of a product.
 */
export const getSingleSpecValue = <Type extends ProductType, K extends keyof SpecMap[Type]>(
  specKey: K,
  product: Product<Type> | StandaloneProductVariant<Type>
): SpecMap[Type][K] => {
  const variant = isProduct(product) ? getStandaloneProductVariant(product, product.variants[0]) : product;
  return variant[specKey];
};

export const isSpecValueVisible = <Type extends ProductType, K extends keyof SpecMap[Type]>(
  value: SpecMap[Type][K],
  specDefinition?: SpecDefinition<Type, K>
): value is NonNullable<SpecMap[Type][K]> => {
  assert(specDefinition, new Error("Spec value cannot be visible without spec definition."));
  return (
    isValueNotNilAndNotEmpty(value) && (specDefinition.visibleValue?.(value as NonNullable<SpecMap[Type][K]>) ?? true)
  );
};

export const filterVisibleSpecValues = <Type extends ProductType, K extends keyof SpecMap[Type]>(
  values: SpecMap[Type][K][],
  specDefinition?: SpecDefinition<Type, K>
): NonNullable<SpecMap[Type][K]>[] => {
  assert(specDefinition, new Error("Spec values cannot be filtered without spec definition."));

  if (specDefinition.visibleValues) {
    return specDefinition.visibleValues(values.filter(isValueNotNilAndNotEmpty));
  } else {
    return values.filter((value): value is NonNullable<SpecMap[Type][K]> => isSpecValueVisible(value, specDefinition));
  }
};

export const getStandaloneProductVariant = <Type extends ProductType>(
  product: Product<Type>,
  variant?: ProductVariant<Type>
): StandaloneProductVariant<Type> => {
  const productSpec = shallowOmit(Object.values(ProductKey), product);

  if (variant) {
    return Object.assign(productSpec, variant, {
      [ProductKey.ProductId]: product[ProductKey.ProductId],
      [ProductKey.ProductType]: product[ProductKey.ProductType],
      [ProductKey.VariantId]: variant[ProductKey.VariantId]
    }) as StandaloneProductVariant<Type>;
  } else {
    return Object.assign(productSpec as unknown as SpecMap[Type], {
      [ProductKey.ProductId]: product[ProductKey.ProductId],
      [ProductKey.ProductType]: product[ProductKey.ProductType],
      [ProductKey.VariantId]: product[ProductKey.VariantId] ?? product[ProductKey.ProductId]
    });
  }
};

export const getStandaloneProductVariants = <Type extends ProductType>(
  products: Product<Type> | Product<Type>[]
): StandaloneProductVariant<Type>[] => {
  const productsArr = Array.isArray(products) ? products : [products];
  return productsArr.flatMap(product =>
    product.variants.length > 0
      ? product.variants.map(variant => getStandaloneProductVariant(product, variant))
      : [getStandaloneProductVariant(product)]
  );
};

export const findCommonValues = <T = StandaloneProductVariant>(list: T[]): Partial<T> => {
  if (list.length === 0) {
    return {};
  }

  const commonValues = { ...list[0] };
  list.forEach(item => {
    for (const key in commonValues) {
      const value = item[key];
      if (commonValues[key] !== value) {
        delete commonValues[key];
      }
    }
  });

  return commonValues;
};

export const groupStandaloneProductVariants = <Type extends ProductType>(
  variants: StandaloneProductVariant<Type>[]
): Product<Type>[] => {
  const groupedVariants = groupBy(variants, variant => `${variant[ProductKey.ProductId]}`);

  return Object.values(groupedVariants).map(group => {
    const commonValues = findCommonValues(group);
    const commonKeys = Object.keys(commonValues) as (keyof typeof commonValues)[];

    const variants = group
      .map(variant => {
        /*
         * ATTENTION:
         * This is an performance optimization to avoid unnecessary object creation.
         */
        const variantValues: any = shallowOmit([...commonKeys, ProductKey.Variants], variant);
        variantValues[ProductKey.VariantId] = variant[ProductKey.VariantId];
        return variantValues as ProductVariant<Type>;
      })
      .filter(variant => !isEmpty(variant));

    /*
     * ATTENTION:
     * This is an performance optimization to avoid unnecessary object creation.
     */
    (commonValues as any)[ProductKey.ProductId] = group[0][ProductKey.ProductId];
    (commonValues as any)[ProductKey.ProductType] = group[0][ProductKey.ProductType];
    (commonValues as any)[ProductKey.Variants] = variants;

    return commonValues as Product<Type>;
  });
};

export const getFirstVariantId = <Type extends ProductType>(product: Product<Type>): ProductId => {
  if (product.variants.length > 0) {
    return product.variants[0][ProductKey.VariantId];
  }

  return product[ProductKey.VariantId] ?? product[ProductKey.ProductId];
};

export const getProductVariants = <Type extends ProductType>(product: Product<Type>): ProductVariant<Type>[] => {
  if (product[ProductKey.Variants].length > 0) {
    return product[ProductKey.Variants];
  }

  return [
    {
      ...shallowOmit(Object.values(ProductKey), product),
      [ProductKey.VariantId]: getFirstVariantId(product)
    } as unknown as ProductVariant<Type>
  ];
};

export const getMinPrice = <Type extends ProductType>(product: Product<Type>): number | undefined => {
  const prices = getAllSpecValues(ProductSpecKey.Price, product);
  return prices.length === 0 ? undefined : Math.min(...prices);
};

export const getMinOriginalPrice = <Type extends ProductType>(product: Product<Type>): number | undefined => {
  const originalPrices = getAllSpecValues(ProductSpecKey.OriginalPrice, product);
  return originalPrices.length === 0 ? undefined : Math.min(...originalPrices);
};

export const getMaxDiscountPercentage = <Type extends ProductType>(product: Product<Type>): number | undefined => {
  const originalPrices = getAllSpecValues(ProductSpecKey.DiscountPercentage, product);
  return originalPrices.length === 0 ? undefined : Math.max(...originalPrices);
};

export const filterSpecGroups = <Type extends ProductType>(
  specGroups: SpecGroup<Type>[],
  showPrices = true
): SpecGroup<Type>[] =>
  specGroups.reduce<SpecGroup<Type>[]>((acc, specGroup) => {
    const nextSpecGroup = {
      ...specGroup,
      specKeys: specGroup.specKeys.filter(specKey => specKey !== ProductSpecKey.Price || showPrices)
    };

    acc.push(nextSpecGroup);

    return acc;
  }, []);

export const calcRelevantVariantSpecKeys = <Type extends ProductType>(
  variants: ProductVariant<Type>[],
  specDefinitions: SpecDefinitions<Type>,
  useCase: SpecDefinitionUseCase,
  priorityList: (keyof SpecMap[Type])[] = []
): (keyof SpecMap[Type])[] => {
  const variantSpecKeys = uniq(flatten(variants.map(variant => Object.keys(variant) as (keyof SpecMap[Type])[])))
    // Check whether key is enabled in spec definition for given use case.
    .filter(key => specDefinitions[key]?.enable?.[useCase]);

  const prioritizedVariantSpecKeys = priorityList.filter(key => variantSpecKeys.includes(key));
  const remainingVariantSpecKeys = difference(variantSpecKeys, priorityList);

  return [...prioritizedVariantSpecKeys, ...remainingVariantSpecKeys];
};

export const calcVariantSpecKeysForMinimalTableWithUniqueRows = <Type extends ProductType>(
  variants: ProductVariant<Type>[],
  specDefinitions: SpecDefinitions<Type>,
  useCase: SpecDefinitionUseCase,
  priorityList: (keyof SpecMap[Type])[] = []
): (keyof SpecMap[Type])[] => {
  const relevantVariantSpecKeys = calcRelevantVariantSpecKeys(variants, specDefinitions, useCase, priorityList);

  // Setup a temporary array where each index represents a table-row
  const tempVariantsTable: string[] = Array(variants.length).fill("");

  // Concat the values for each variant spec to a single, comparable "row-value" and add it to the temporary variants table
  const updateTempVariantsTable = (key: keyof SpecMap[Type]): void => {
    variants.forEach((variant, index) => (tempVariantsTable[index] += variant[key]));
  };

  // If the 'uniqued' array of row values has the same length as the raw values array, we know that each "row-value" is unique
  const isEachTableRowUnique = (): boolean => {
    const rowValues = Object.values(tempVariantsTable);
    return uniq(rowValues).length === rowValues.length;
  };

  const variantSpecKeys: (keyof SpecMap[Type])[] = [];

  for (const specKey of relevantVariantSpecKeys) {
    if (!isEachTableRowUnique()) {
      updateTempVariantsTable(specKey);
      variantSpecKeys.push(specKey);
    } else {
      // Avoid further unnecessary computation
      break;
    }
  }

  return variantSpecKeys;
};

export const filterEmptyAndEnsureMinimumVariantSpecKeys = <Type extends ProductType>(
  product: Product<Type>,
  specKeys: (keyof SpecMap[Type])[],
  specDefinitions: SpecDefinitions<Type>,
  fallbackSpecKeys: (keyof SpecMap[Type])[] = [],
  minimumSpecKeys = 2
): (keyof SpecMap[Type])[] => {
  const filteredFallbackKeys = fallbackSpecKeys.filter(specKey => {
    const values = filterVisibleSpecValues(getAllSpecValues(specKey, product), specDefinitions[specKey]);
    return values.length > 0;
  });

  const filteredSpecKeys = specKeys.filter(specKey => {
    const values = filterVisibleSpecValues(getAllSpecValues(specKey, product), specDefinitions[specKey]);
    return values.length > 0;
  });

  return [
    ...filteredSpecKeys,
    ...filteredFallbackKeys.slice(0, Math.max(0, minimumSpecKeys - filteredSpecKeys.length))
  ];
};

export const getFilterValuesForVariantsBySpecKey = <Type extends ProductType, K extends keyof SpecMap[Type]>(
  variants: StandaloneProductVariant<Type>[],
  specKey: K
): VariantFilterValues<Type, K>[] =>
  variants
    .reduce<VariantFilterValues<Type, K>[]>((acc, variant) => {
      const value = variant[specKey];

      if (acc.find(({ value: v }) => v === value)) {
        return acc;
      } else {
        return [...acc, { value, variant }];
      }
    }, [])
    .filter(({ value }) => !isNil(value));

export const getCategoryKeyFromProduct = <Type extends ProductType>(product: Product<Type>) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- CategoryKey should always be defined
  return product[ProductSpecKey.CategoryKey]! as CategoryMap[Type];
};
