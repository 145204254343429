import React from "react";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { prependServiceBaseUrl } from "../../../../commons/libs/client-utils";
import { ModalProps, PrintMailModalContentProps } from "../../../../commons/types/modal";
import { PrintType } from "../../../../commons/types/print-mail";
import * as icons from "../../../../resources/icons";
import mailImage from "../../../../resources/images/email.png";
import printerImage from "../../../../resources/images/printer.png";
import useFetchData from "../../../commons/libs/hooks/use-fetch-data";
import { getPdfMetadataFromUrl } from "../../../commons/libs/services/pdf-service/pdf";
import { ROUTES } from "../../../commons/routes";
import actions from "../../actions";
import Button from "../../components/Button/Button";
import FlexFooterLayout from "../../components/FlexFooterLayout/FlexFooterLayout";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import IconLabel from "../../components/IconLabel/IconLabel";
import Image from "../../components/Image/Image";
import Modal from "../../components/Modal/Modal";
import Paragraph from "../../components/Paragraph/Paragraph";
import SplitContentLayout from "../../components/SplitContentLayout/SplitContentLayout";
import { connect } from "../../container/utils/loop";
import { createPreviewUrl } from "../../libs/print";
import { State } from "../../reducers";

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  onPush: push,
  openMailModal: actions.modal.openMailModal,
  openPrintModal: actions.modal.openPrintModal
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps extends ModalProps<PrintMailModalContentProps> {
  nodeRef?: React.Ref<HTMLElement>;
  isOffline: boolean;
  isUsbPrinterConnectedOrMailToPrintEnabled: boolean;
  isOfflineAndMailToPrintIsEnabled: boolean;
  isMobile?: boolean;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const PrintMailSwitchModalPartial = ({
  close,
  openMailModal,
  openPrintModal,
  data,
  isOffline,
  isUsbPrinterConnectedOrMailToPrintEnabled,
  isOfflineAndMailToPrintIsEnabled,
  isMobile = false,
  nodeRef,
  onPush
}: Props) => {
  const { t } = useTranslation(["commons"]);

  const printTypeTitle = {
    [PrintType.ProductDataSheet as string]: t("commons:printMailSwitchModalPartialApp.shareDataSheetTitle"),
    [PrintType.Default as string]: t("commons:printMailSwitchModalPartialApp.shareTitle")
  };

  const isPrintTypeProductDataSheet = data.type === PrintType.ProductDataSheet;

  const isPrintingImpossible = !isUsbPrinterConnectedOrMailToPrintEnabled || isOfflineAndMailToPrintIsEnabled;
  const shouldErrorMessageBeDisplayed = isOffline || isPrintingImpossible;

  const previewUrl = React.useMemo(() => createPreviewUrl(data), [data]);
  const previewPdf = useFetchData(() => getPdfMetadataFromUrl(previewUrl));
  const pdfUrl = previewPdf?.data?.url ? prependServiceBaseUrl(previewPdf.data.url) : "";

  const renderNoPrinterEnabledContent = () => (
    <Button
      variant="accent"
      kind="solid"
      icon={<Icon source={icons.IconSmallArrowRight} />}
      iconRight
      onClick={() => {
        close();
        onPush(ROUTES.SETTINGS.PRINTER_INDEX);
      }}
    >
      {t("commons:printMailSwitchModalPartialApp.setupPrinterButton")}
    </Button>
  );

  const renderPrintButtonContent = () => (
    <Button
      variant="accent"
      kind="solid"
      icon={<Icon source={icons.IconSmallArrowRight} />}
      iconRight
      onClick={() => openPrintModal({ data })}
      disabled={isOfflineAndMailToPrintIsEnabled}
    >
      {t("commons:printMailSwitchModalPartialApp.toPrintPreviewButton")}
    </Button>
  );

  const renderOpenPrintInNewTabContent = () => (
    <Button
      classNames={["u-space-top-xs"]}
      icon={<Icon source={icons.IconSmallArrowRight} />}
      disabled={previewPdf.isLoading}
      iconRight
      onClick={() => window.open(pdfUrl)}
    >
      {t("commons:printMailSwitchModalPartialApp.openInNewTabButton")}
    </Button>
  );

  const renderPrintDesktopContent = () =>
    !isUsbPrinterConnectedOrMailToPrintEnabled ? renderNoPrinterEnabledContent() : renderPrintButtonContent();

  const renderPrintMobileContent = () => (
    <>
      {isUsbPrinterConnectedOrMailToPrintEnabled && renderPrintButtonContent()}
      {renderOpenPrintInNewTabContent()}
    </>
  );

  return (
    <Modal
      nodeRef={nodeRef}
      width="xl"
      closeButton={<IconButton onClick={() => close()} icon={<Icon source={icons.IconSmallCross} />} />}
    >
      <Headline kind="m">{printTypeTitle[data.type]}</Headline>
      <SplitContentLayout size="m">
        <FlexFooterLayout footer={isMobile ? renderPrintMobileContent() : renderPrintDesktopContent()}>
          <Image position="center" classNames={["u-space-xl"]} src={printerImage} />
          <Headline kind="sub">{t("commons:printMailSwitchModalPartialApp.analogHeadline")}</Headline>
          <Headline classNames={["u-space-base"]}>
            {t("commons:printMailSwitchModalPartialApp.viaPrinterHeadline")}
          </Headline>
          <Paragraph kind="support">
            {isPrintTypeProductDataSheet
              ? t("commons:printMailSwitchModalPartialApp.printDataSheetInShop")
              : t("commons:printMailSwitchModalPartialApp.printInShop")}
          </Paragraph>
        </FlexFooterLayout>

        <FlexFooterLayout
          footer={
            <Button
              variant="accent"
              kind="solid"
              icon={<Icon source={icons.IconSmallArrowRight} />}
              iconRight
              onClick={() => openMailModal({ data })}
              disabled={isOffline}
            >
              {t("commons:printMailSwitchModalPartialApp.enterEmailAddressButton")}
            </Button>
          }
        >
          <Image position="center" classNames={["u-space-xl"]} src={mailImage} />
          <Headline kind="sub">Digital</Headline>
          <Headline classNames={["u-space-base"]}>Via E-Mail</Headline>
          <Paragraph kind="support">
            {isPrintTypeProductDataSheet
              ? t("commons:printMailSwitchModalPartialApp.sendDataSheetToEmail")
              : t("commons:printMailSwitchModalPartialApp.sendToEmail")}
          </Paragraph>
        </FlexFooterLayout>
      </SplitContentLayout>

      {shouldErrorMessageBeDisplayed && (
        <SplitContentLayout size="m" separator={false}>
          {isPrintingImpossible && (
            <IconLabel kind="negative" icon={<Icon source={icons.IconSmallAttention} />}>
              {!isUsbPrinterConnectedOrMailToPrintEnabled &&
                t("commons:printMailSwitchModalPartialApp.noPrinterConfigured")}
              {isOfflineAndMailToPrintIsEnabled &&
                t("commons:printMailSwitchModalPartialApp.deviceOfflineNoPrinterConnected")}
            </IconLabel>
          )}

          {isOffline && (
            <IconLabel kind="negative" icon={<Icon source={icons.IconSmallAttention} />}>
              {t("commons:printMailSwitchModalPartialApp.deviceOffline")}
            </IconLabel>
          )}
        </SplitContentLayout>
      )}
    </Modal>
  );
};

export default connector(PrintMailSwitchModalPartial);
