import React, { createContext, ReactNode, useContext } from "react";

import { UseVeloconnectEndpointReturn } from "../../libs/hooks/use-veloconnect-endpoint";

interface VeloconnectEndpointContext {
  endpoint: UseVeloconnectEndpointReturn;
}

interface Props extends VeloconnectEndpointContext {
  children: ReactNode;
}

const VeloconnectEndpointContext = createContext<UseVeloconnectEndpointReturn | null>(null);

/** A provider which receives the Veloconnect Endpoint data and provides it to its children. */
export const VeloconnectEndpointProvider = ({ endpoint, children }: Props) => {
  return <VeloconnectEndpointContext.Provider value={endpoint}>{children}</VeloconnectEndpointContext.Provider>;
};

/** Custom hook to use the context directly */
export const useVeloconnectEndpointContext = (): UseVeloconnectEndpointReturn => {
  const context = useContext(VeloconnectEndpointContext);

  if (context === null) {
    throw new Error("useVeloconnectEndpointContext must be used within a VeloconnectEndpointProvider");
  }

  return context;
};
