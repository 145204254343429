import React from "react";
import { clone } from "lodash";

import { ActiveFilters, FilterValue } from "../../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import { getSelectedBrands } from "../brand";
import { setActiveFilter } from "../filters";

type Params<Type extends ProductType> = {
  activeBrands: Brand<Type>[];
  activeFilters: ActiveFilters<Type>;
  brandKey?: string;
};

export default <Type extends ProductType>({ activeBrands, activeFilters, brandKey }: Params<Type>) => {
  const [selectedBrands, setSelectedBrands] = React.useState<Brand<Type>[]>([]);

  React.useEffect(() => {
    if (activeBrands?.length > 0) {
      const nextActiveFilters: ActiveFilters<Type> = clone(activeFilters ?? {});

      if (brandKey) {
        const filterBrandName = activeBrands.find(brand => brand.key === brandKey)?.displayName as
          | FilterValue<Type, ProductSpecKey.BrandName>
          | undefined;
        setActiveFilter(nextActiveFilters, ProductSpecKey.BrandName, filterBrandName ? [filterBrandName] : []);
      }

      setSelectedBrands(getSelectedBrands(activeBrands, nextActiveFilters));
    }
  }, [activeBrands, activeFilters, brandKey]);

  return {
    selectedBrands
  };
};
