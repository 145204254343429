import React from "react";
import { useTranslation } from "react-i18next";

import { buildStaticFileUrl } from "../../../../commons/libs/client-utils";
import { ModalProps } from "../../../../commons/types/modal";
import * as icons from "../../../../resources/icons";
import Button from "../../components/Button/Button";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import Modal from "../../components/Modal/Modal";
import Paragraph from "../../components/Paragraph/Paragraph";
import Text from "../../components/Text/Text";
import DataPrivacyPartial from "../App/DataPrivacyPartial";

import PdfViewerPartial from "./PdfViewerPartial";

enum TabValue {
  Imprint = "IMPRINT",
  DataPrivacy = "DATA_PRIVACY",
  GeneralBusinessTerms = "GENERAL_BUSINESS_TERMS"
}

interface OuterProps extends ModalProps {
  nodeRef?: React.Ref<HTMLElement>;
}
type Props = OuterProps;

const renderImprint = () => (
  <Text>
    <Headline>Impressum</Headline>
    <Headline kind="s">Angaben gemäß § 5 TMG</Headline>
    <Paragraph>
      DealerCenter Digital GmbH
      <br />
      Ludwig-Erhard-Str. 13a
      <br />
      84034 Landshut
      <br />
      Deutschland
    </Paragraph>
    <Headline kind="s">Geschäftsführer:</Headline>
    <Paragraph>Oliver Ertelt</Paragraph>
    <Paragraph>Simon Kern</Paragraph>
    <Headline kind="s">Prokurist:</Headline>
    <Paragraph>Alexander Vetter</Paragraph>
    <Headline kind="s">Sitz:</Headline>
    <Paragraph>84034 Landshut</Paragraph>
    <Headline kind="s">Amtsgericht:</Headline>
    <Paragraph>Landshut</Paragraph>
    <Headline kind="s">Registernummer:</Headline>
    <Paragraph>HRB 12278</Paragraph>
    <Headline kind="s">Umsatzsteuer-ID:</Headline>
    <Paragraph>
      Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
      <br />
      DE317717564
    </Paragraph>
    <Headline kind="s">Kontakt:</Headline>
    <Paragraph>
      DealerCenter Digital GmbH
      <br />
      Ludwig-Erhard-Str. 13a
      <br />
      84034 Landshut
      <br />
      Deutschland
    </Paragraph>
    <Paragraph>
      Telefon: ‪+49 (0)871 / 206 586 30
      <br />
      E-Mail: office@bike.center
    </Paragraph>
    <Headline>Haftungsausschluss</Headline>
    <Headline kind="s">Haftung für Inhalte</Headline>
    <Paragraph>
      Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
      Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
      für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
      als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
      oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
      oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
      Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </Paragraph>
    <Headline>Haftung für Links</Headline>
    <Paragraph>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
      können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
      der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
      Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
      erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
      umgehend entfernen.
    </Paragraph>
    <Headline kind="s">Urheberrecht</Headline>
    <Paragraph>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
      Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
      dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
      Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
      Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
      wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
      umgehend entfernen.
    </Paragraph>
    <Headline kind="s">Google Analytics</Headline>
    <Paragraph>
      Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (&quot;Google&quot;). Google
      Analytics verwendet sog. &quot;Cookies&quot;, Textdateien, die auf Ihrem Computer gespeichert werden und die eine
      Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre
      Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen
      und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
      Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der
      Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
      Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese
      Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google
      in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser
      Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
      Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit
      der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem
      zuvor benannten Zweck einverstanden.
    </Paragraph>
    <Headline kind="s">Web Fonts von Adobe Typekit</Headline>
    <Paragraph>
      Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Adobe Typekit
      bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um
      Texte und Schriftarten korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
      Servern von Adobe Typekit aufnehmen. Hierdurch erlangt Adobe Typekit Kenntnis darüber, dass über Ihre IP-Adresse
      unsere Website aufgerufen wurde. Die Nutzung von Adobe Typekit Web Fonts erfolgt im Interesse einer einheitlichen
      und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
      Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem
      Computer genutzt.
    </Paragraph>
    <Paragraph>
      Weitere Informationen zu Adobe Typekit Web Fonts finden Sie unter https://typekit.com/ und in der
      Datenschutzerklärung von Adobe Typekit: https://www.adobe.com/de/privacy/policies/typekit.html
    </Paragraph>
    <Headline>Datenschutzerklärung</Headline>
    <Paragraph>
      Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert
      für die Geschäftsleitung der DealerCenter Digital GmbH. Eine Nutzung der Internetseiten der DealerCenter Digital
      GmbH ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere
      Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung
      personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht
      für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen
      Person ein.
    </Paragraph>
    <Paragraph>
      Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder
      Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in
      Übereinstimmung mit den für die DealerCenter Digital GmbH geltenden landesspezifischen Datenschutzbestimmungen.
      Mittels dieser Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von
      uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen
      mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
    </Paragraph>
    <Paragraph>
      Die DealerCenter Digital GmbH hat als für die Verarbeitung Verantwortlicher zahlreiche technische und
      organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite
      verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte Datenübertragungen
      grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem
      Grund steht es jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise
      telefonisch, an uns zu übermitteln.
    </Paragraph>
    <Headline kind="s">1. Begriffsbestimmungen</Headline>
    <Paragraph>
      Die Datenschutzerklärung der DealerCenter Digital GmbH beruht auf den Begrifflichkeiten, die durch den
      Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet
      wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und
      Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten
      Begrifflichkeiten erläutern.
    </Paragraph>
    <Paragraph>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</Paragraph>
    <Paragraph>a)&nbsp;&nbsp;&nbsp; personenbezogene Daten</Paragraph>
    <Paragraph>
      Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche
      Person (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche Person angesehen, die
      direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
      Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
      physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
      dieser natürlichen Person sind, identifiziert werden kann.
    </Paragraph>
    <Paragraph>b)&nbsp;&nbsp;&nbsp; betroffene Person</Paragraph>
    <Paragraph>
      Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten
      von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
    </Paragraph>
    <Paragraph>c)&nbsp;&nbsp;&nbsp; Verarbeitung</Paragraph>
    <Paragraph>
      Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
      Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das
      Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
      Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die
      Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
    </Paragraph>
    <Paragraph>d)&nbsp;&nbsp;&nbsp; Einschränkung der Verarbeitung</Paragraph>
    <Paragraph>
      Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre
      künftige Verarbeitung einzuschränken.
    </Paragraph>
    <Paragraph>e)&nbsp;&nbsp;&nbsp; Profiling</Paragraph>
    <Paragraph>
      Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
      personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person
      beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
      persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
      Person zu analysieren oder vorherzusagen.
    </Paragraph>
    <Paragraph>f)&nbsp;&nbsp;&nbsp;&nbsp; Pseudonymisierung</Paragraph>
    <Paragraph>
      Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen
      Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet
      werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
      organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer
      identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
    </Paragraph>
    <Paragraph>g)&nbsp;&nbsp;&nbsp; Verantwortlicher oder für die Verarbeitung Verantwortlicher</Paragraph>
    <Paragraph>
      Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person, Behörde,
      Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
      von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
      das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten
      Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
    </Paragraph>
    <Paragraph>h)&nbsp;&nbsp;&nbsp; Auftragsverarbeiter</Paragraph>
    <Paragraph>
      Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
      personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
    </Paragraph>
    <Paragraph>i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Empfänger</Paragraph>
    <Paragraph>
      Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der
      personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder
      nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
      Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
    </Paragraph>
    <Paragraph>j)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dritter</Paragraph>
    <Paragraph>
      Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen
      Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung
      des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
    </Paragraph>
    <Paragraph>k)&nbsp;&nbsp;&nbsp; Einwilligung</Paragraph>
    <Paragraph>
      Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und
      unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen
      bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
      betreffenden personenbezogenen Daten einverstanden ist.
    </Paragraph>
    <Headline kind="s">2. Name und Anschrift des für die Verarbeitung Verantwortlichen</Headline>
    <Paragraph>
      Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union
      geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
    </Paragraph>
    <Paragraph>DealerCenter Digital GmbH</Paragraph>
    <Paragraph>Ludwig-Erhard-Str. 13a</Paragraph>
    <Paragraph>84034 Landshut</Paragraph>
    <Paragraph>Deutschland</Paragraph>
    <Paragraph>Tel.: +49 (0)871 / 206 586 30</Paragraph>
    <Paragraph>E-Mail: office@bike.center</Paragraph>
    <Paragraph>Website: www.bike.center</Paragraph>
    <Headline kind="s">3. Cookies</Headline>
    <Paragraph>
      Die Internetseiten der DealerCenter Digital GmbH verwenden Cookies. Cookies sind Textdateien, welche über einen
      Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.
    </Paragraph>
    <Paragraph>
      Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine
      Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten
      und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies
      ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von
      anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über
      die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
    </Paragraph>
    <Paragraph>
      Durch den Einsatz von Cookies kann die DealerCenter Digital GmbH den Nutzern dieser Internetseite
      nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
    </Paragraph>
    <Paragraph>
      Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne des Benutzers
      optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer Internetseite
      wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu
      erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet, muss beispielsweise nicht bei jedem Besuch
      der Internetseite erneut seine Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
      Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist das Cookie eines
      Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die ein Kunde in den virtuellen Warenkorb
      gelegt hat, über ein Cookie.
    </Paragraph>
    <Paragraph>
      Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer
      entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft
      widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere
      Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich. Deaktiviert die betroffene
      Person die Setzung von Cookies in dem genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen
      unserer Internetseite vollumfänglich nutzbar.
    </Paragraph>
    <Headline kind="s">4. Erfassung von allgemeinen Daten und Informationen</Headline>
    <Paragraph>
      Die Internetseite der DealerCenter Digital GmbH erfasst mit jedem Aufruf der Internetseite durch eine betroffene
      Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten
      und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die (1) verwendeten
      Browsertypen und Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die Internetseite, von
      welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten,
      welche über ein zugreifendes System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
      eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der
      Internet-Service-Provider des zugreifenden Systems und (8) sonstige ähnliche Daten und Informationen, die der
      Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.
    </Paragraph>
    <Paragraph>
      Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die DealerCenter Digital GmbH keine Rückschlüsse
      auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um (1) die Inhalte unserer Internetseite
      korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren, (3) die
      dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer Internetseite zu
      gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung
      notwendigen Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch die
      DealerCenter Digital GmbH daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz und
      die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die von uns
      verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von
      allen durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
    </Paragraph>
    <Headline kind="s">5. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</Headline>
    <Paragraph>
      Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der betroffenen Person
      nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den
      Europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften,
      welchen der für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
    </Paragraph>
    <Paragraph>
      Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder einem
      anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig
      und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
    </Paragraph>
    <Headline kind="s">6. Rechte der betroffenen Person</Headline>
    <Paragraph>a)&nbsp;&nbsp;&nbsp; Recht auf Bestätigung</Paragraph>
    <Paragraph>
      Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte Recht, von dem für
      die Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten
      verarbeitet werden. Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie sich
      hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
    </Paragraph>
    <Paragraph>b)&nbsp;&nbsp;&nbsp; Recht auf Auskunft</Paragraph>
    <ul>
      <li>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
        Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft
        über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten.
        Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende
        Informationen zugestanden:
      </li>
      <li>die Verarbeitungszwecke</li>
      <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
      <li>
        die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden
        sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen
        Organisationen
      </li>
      <li>
        falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht
        möglich ist, die Kriterien für die Festlegung dieser Dauer
      </li>
      <li>
        das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf
        Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung
      </li>
      <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
      <li>
        wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen
        über die Herkunft der Daten
      </li>
      <li>
        das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Abs.1 und 4
        DS-GVO und — zumindest in diesen Fällen — aussagekräftige Informationen über die involvierte Logik sowie die
        Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person
        <br />
        <br />
      </li>
    </ul>
    <Paragraph>
      Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder
      an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person
      im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.
    </Paragraph>
    <Paragraph>
      Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen
      Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
    </Paragraph>
    <Paragraph>c)&nbsp;&nbsp;&nbsp; Recht auf Berichtigung</Paragraph>
    <Paragraph>
      Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
      Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener
      Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der
      Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden
      Erklärung — zu verlangen. Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie
      sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
    </Paragraph>
    <Paragraph>d)&nbsp;&nbsp;&nbsp; Recht auf Löschung (Recht auf Vergessen werden)</Paragraph>
    <Paragraph>
      Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
      Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden
      personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die
      Verarbeitung nicht erforderlich ist:
    </Paragraph>
    <ul>
      <li>
        Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie
        nicht mehr notwendig sind.
      </li>
      <li>
        Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a
        DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für
        die Verarbeitung.
      </li>
      <li>
        Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein, und es liegen
        keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21
        Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
      </li>
      <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
      <li>
        Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht
        oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
      </li>
      <li>
        Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8
        Abs. 1 DS-GVO erhoben.
        <br />
        <br />
      </li>
    </ul>
    <Paragraph>
      Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von personenbezogenen
      Daten, die bei der DealerCenter Digital GmbH gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit
      an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der DealerCenter Digital
      GmbH wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.
    </Paragraph>
    <Paragraph>
      Wurden die personenbezogenen Daten von der DealerCenter Digital GmbH öffentlich gemacht und ist unser Unternehmen
      als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten verpflichtet, so trifft
      die DealerCenter Digital GmbH unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten
      angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche die
      veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person
      von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen
      personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, soweit die
      Verarbeitung nicht erforderlich ist. Der Mitarbeiter der DealerCenter Digital GmbH wird im Einzelfall das
      Notwendige veranlassen.
    </Paragraph>
    <Paragraph>e)&nbsp;&nbsp;&nbsp; Recht auf Einschränkung der Verarbeitung</Paragraph>
    <Paragraph>
      Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
      Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn
      eine der folgenden Voraussetzungen gegeben ist:
    </Paragraph>
    <ul>
      <li>
        Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für eine Dauer,
        die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.
      </li>
      <li>
        Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der personenbezogenen Daten ab und
        verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten.
      </li>
      <li>
        Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, die
        betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </li>
      <li>
        Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht
        noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person
        überwiegen.
      </li>
    </ul>
    <Paragraph>
      Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die Einschränkung von
      personenbezogenen Daten, die bei der DealerCenter Digital GmbH gespeichert sind, verlangen möchte, kann sie sich
      hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der
      DealerCenter Digital GmbH wird die Einschränkung der Verarbeitung veranlassen.
    </Paragraph>
    <Paragraph>f)&nbsp;&nbsp;&nbsp;&nbsp; Recht auf Datenübertragbarkeit</Paragraph>
    <Paragraph>
      Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
      Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten, welche durch die betroffene Person
      einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu
      erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den
      Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung
      auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem
      Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
      erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
      Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
    </Paragraph>
    <Paragraph>
      Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1
      DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen
      Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und
      Freiheiten anderer Personen beeinträchtigt werden.
    </Paragraph>
    <Paragraph>
      Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an einen
      Mitarbeiter der DealerCenter Digital GmbH wenden
    </Paragraph>
    <Paragraph>g)&nbsp;&nbsp;&nbsp; Recht auf Widerspruch</Paragraph>
    <Paragraph>
      Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
      Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
      Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO
      erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
    </Paragraph>
    <Paragraph>
      Die DealerCenter Digital GmbH verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei
      denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und
      Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
      Verteidigung von Rechtsansprüchen.
    </Paragraph>
    <Paragraph>
      Verarbeitet die DealerCenter Digital GmbH personenbezogene Daten, um Direktwerbung zu betreiben, so hat die
      betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke
      derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung
      steht. Widerspricht die betroffene Person gegenüber der DealerCenter Digital GmbH der Verarbeitung für Zwecke der
      Direktwerbung, so wird die DealerCenter Digital GmbH die personenbezogenen Daten nicht mehr für diese Zwecke
      verarbeiten.
    </Paragraph>
    <Paragraph>
      Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die
      sie betreffende Verarbeitung personenbezogener Daten, die bei der DealerCenter Digital GmbH zu wissenschaftlichen
      oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen,
      Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
      liegenden Aufgabe erforderlich.
    </Paragraph>
    <Paragraph>
      Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden Mitarbeiter der DealerCenter
      Digital GmbH oder einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner frei, im Zusammenhang
      mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr
      Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet
      werden.
    </Paragraph>
    <Paragraph>h)&nbsp;&nbsp;&nbsp; Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</Paragraph>
    <Paragraph>
      Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
      Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung —
      einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung
      entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung (1) nicht für den
      Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich
      ist, oder (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche
      unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten
      sowie der berechtigten Interessen der betroffenen Person enthalten oder (3) mit ausdrücklicher Einwilligung der
      betroffenen Person erfolgt.
    </Paragraph>
    <Paragraph>
      Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und
      dem Verantwortlichen erforderlich oder (2) erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person,
      trifft die DealerCenter Digital GmbH angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten
      Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person
      seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
    </Paragraph>
    <Paragraph>
      Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen, kann sie sich
      hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
    </Paragraph>
    <Paragraph>i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</Paragraph>
    <Paragraph>
      Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
      Verordnungsgeber gewährte Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu
      widerrufen. Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie sich
      hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
    </Paragraph>
    <Headline kind="s">7. Datenschutz bei Bewerbungen und im Bewerbungsverfahren</Headline>
    <Paragraph>
      Der für die Verarbeitung Verantwortliche erhebt und verarbeitet die personenbezogenen Daten von Bewerbern zum
      Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege erfolgen. Dies
      ist insbesondere dann der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen Wege,
      beispielsweise per E-Mail oder über ein auf der Internetseite befindliches Webformular, an den für die
      Verarbeitung Verantwortlichen übermittelt. Schließt der für die Verarbeitung Verantwortliche einen
      Anstellungsvertrag mit einem Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des
      Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert. Wird von dem für die
      Verarbeitung Verantwortlichen kein Anstellungsvertrag mit dem Bewerber geschlossen, so werden die
      Bewerbungsunterlagen zwei Monate nach Bekanntgabe der Absageentscheidung automatisch gelöscht, sofern einer
      Löschung keine sonstigen berechtigten Interessen des für die Verarbeitung Verantwortlichen entgegenstehen.
      Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in einem Verfahren nach dem
      Allgemeinen Gleichbehandlungsgesetz (AGG).
    </Paragraph>
    <Headline kind="s">8. Rechtsgrundlage der Verarbeitung</Headline>
    <Paragraph>
      Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
      Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur
      Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies beispielsweise
      bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen
      Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt
      für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen
      von Anfragen zur unseren Produkten oder Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung
      durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung
      steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen könnte die
      Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person
      oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem
      Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige
      lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden müssten.
      Dann würde die Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6
      I lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der
      vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses
      unseres Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten
      des Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie
      durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein
      berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene Person ein Kunde des Verantwortlichen ist
      (Erwägungsgrund 47 Satz 2 DS-GVO).
    </Paragraph>
    <Headline kind="s">
      9. Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem Dritten verfolgt werden
    </Headline>
    <Paragraph>
      Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser berechtigtes Interesse
      die Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter und unserer
      Anteilseigner.
    </Paragraph>
    <Headline kind="s">10. Dauer, für die die personenbezogenen Daten gespeichert werden</Headline>
    <Paragraph>
      Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche
      Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht
      mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.
    </Paragraph>
    <Headline kind="s">
      11. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten;
    </Headline>
    <Paragraph>
      Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person, die personenbezogenen Daten
      bereitzustellen; mögliche Folgen der Nichtbereitstellung. Wir klären Sie darüber auf, dass die Bereitstellung
      personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus
      vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem
      Vertragsschluss erforderlich sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt,
      die in der Folge durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns
      personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag abschließt. Eine
      Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht
      geschlossen werden könnte. Vor einer Bereitstellung personenbezogener Daten durch den Betroffenen muss sich der
      Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen einzelfallbezogen darüber
      auf, ob die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den
      Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die personenbezogenen Daten bereitzustellen,
      und welche Folgen die Nichtbereitstellung der personenbezogenen Daten hätte.
    </Paragraph>
    <Headline kind="s">12. Bestehen einer automatisierten Entscheidungsfindung</Headline>
    <Paragraph>
      Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung oder ein
      Profiling.
    </Paragraph>
  </Text>
);

const LegalInformationModalPartial = ({ close, nodeRef }: Props) => {
  const { t } = useTranslation(["commons"]);
  const [currentTab, setCurrentTab] = React.useState<TabValue>(TabValue.Imprint);

  return (
    <Modal
      nodeRef={nodeRef}
      width="l"
      key={currentTab}
      layout={currentTab === TabValue.Imprint || currentTab === TabValue.DataPrivacy ? undefined : "noPadding"}
      closeButton={<IconButton onClick={() => close()} icon={<Icon source={icons.IconSmallCross} />} />}
      footer={
        <FlexLayout gap="l" justifyContent="center">
          <Button
            onClick={() => setCurrentTab(TabValue.Imprint)}
            variant={currentTab === TabValue.Imprint ? "accent" : "primary"}
          >
            {t("commons:legalInformationModalPartialApp.imprintButtonLabel")}
          </Button>
          <Button
            onClick={() => setCurrentTab(TabValue.DataPrivacy)}
            variant={currentTab === TabValue.DataPrivacy ? "accent" : "primary"}
          >
            {t("commons:legalInformationModalPartialApp.privacyPolicyButtonLabel")}
          </Button>
          <Button
            onClick={() => setCurrentTab(TabValue.GeneralBusinessTerms)}
            variant={currentTab === TabValue.GeneralBusinessTerms ? "accent" : "primary"}
          >
            {t("commons:legalInformationModalPartialApp.termsOfServiceButtonLabel")}
          </Button>
        </FlexLayout>
      }
    >
      {currentTab === TabValue.Imprint && renderImprint()}
      {currentTab === TabValue.DataPrivacy && <DataPrivacyPartial />}
      {currentTab === TabValue.GeneralBusinessTerms && (
        <PdfViewerPartial
          url={buildStaticFileUrl(
            t("commons:legalInformationModalPartialApp.termsOfServicePdfStaticFilesUrl")
          ).toString()}
          isOffline={false}
          isOfflineAndMailToPrintIsEnabled={false}
          hidePrint={true}
          hideSend={true}
          onClose={close}
        />
      )}
    </Modal>
  );
};

export default LegalInformationModalPartial;
