import { Product, ProductKey, ProductType } from "../specs/product";

export const isProductOfType = <T1 extends ProductType, T2 extends T1>(
  product: Product<T1>,
  productType: T2
): product is Product<T2> => {
  return product[ProductKey.ProductType] === productType;
};

/** Returns `true` if all products have the same product type.
 *
 * **Caution:** If the array is empty, this function returns `true`.
 * @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every#description
 */
export const allProductsOfSameType = (products: Product[]): boolean => {
  if (products.length === 0) {
    return true;
  } else {
    const firstType = products[0][ProductKey.ProductType];
    return products.every((product: Product) => product[ProductKey.ProductType] === firstType);
  }
};

/** Returns true if all products are of the same product type.
 *
 * **Caution:** If the array is empty, this function returns `true`.
 * @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every#description
 */
export const allProductsOfType = <T1 extends ProductType, T2 extends T1>(
  products: Product<T1>[],
  productType: T2
): products is Product<T2>[] => {
  return products.every((product: Product<T1>) => isProductOfType(product, productType));
};
