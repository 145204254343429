import { Product, ProductId } from "../../../commons/specs/product";
import { Action } from "../../../commons/types/redux";

export type SaveAction = Action<"BOOKMARKS.SAVE", { productIds: ProductId[]; name: string }>;
export type RenameAction = Action<"BOOKMARKS.RENAME", { id: string; name: string }>;
export type ClearAction = Action<"BOOKMARKS.CLEAR", { id: string }>;
export type DeleteAction = Action<"BOOKMARKS.DELETE", { id: string }>;

export type AddProductAction = Action<"BOOKMARKS.ADD_PRODUCT", { id: string; productId: ProductId }>;
export type RemoveProductAction = Action<"BOOKMARKS.REMOVE_PRODUCT", { id: string; productId: ProductId }>;

export type SetupCompareAction = Action<"BOOKMARKS.COMPARE_SETUP", { bookmarkId: string; products: Product[] }>;
export type ClearCompareAction = Action<"BOOKMARKS.COMPARE_CLEAR">;
export type AddProductToCompareAction = Action<"BOOKMARKS.COMPARE_ADD_PRODUCT", { productId: ProductId }>;
export type RemoveProductFromCompareAction = Action<"BOOKMARKS.COMPARE_REMOVE_PRODUCT", { productId: ProductId }>;

export type BookmarkActions =
  | SaveAction
  | RenameAction
  | ClearAction
  | DeleteAction
  | AddProductAction
  | RemoveProductAction
  | SetupCompareAction
  | ClearCompareAction
  | AddProductToCompareAction
  | RemoveProductFromCompareAction;

export const saveBookmark = (productIds: ProductId[], name: string): SaveAction => ({
  type: "BOOKMARKS.SAVE",
  payload: { productIds, name }
});

export const renameBookmark = (id: string, name: string): RenameAction => ({
  type: "BOOKMARKS.RENAME",
  payload: { id, name }
});

export const clearBookmark = (id: string): ClearAction => ({
  type: "BOOKMARKS.CLEAR",
  payload: { id }
});

export const deleteBookmark = (id: string): DeleteAction => ({
  type: "BOOKMARKS.DELETE",
  payload: { id }
});

export const addProduct = (id: string, productId: ProductId): AddProductAction => ({
  type: "BOOKMARKS.ADD_PRODUCT",
  payload: { id, productId }
});

export const removeProduct = (id: string, productId: ProductId): RemoveProductAction => ({
  type: "BOOKMARKS.REMOVE_PRODUCT",
  payload: { id, productId }
});

export const setupCompare = (bookmarkId: string, products: Product[]): SetupCompareAction => ({
  type: "BOOKMARKS.COMPARE_SETUP",
  payload: {
    bookmarkId,
    products
  }
});

export const clearCompare = (): ClearCompareAction => ({
  type: "BOOKMARKS.COMPARE_CLEAR"
});

export const addProductToCompare = (productId: ProductId): AddProductToCompareAction => ({
  type: "BOOKMARKS.COMPARE_ADD_PRODUCT",
  payload: { productId }
});

export const removeProductFromCompare = (productId: ProductId): RemoveProductFromCompareAction => ({
  type: "BOOKMARKS.COMPARE_REMOVE_PRODUCT",
  payload: { productId }
});
