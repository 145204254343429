import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  source: string;
  width: string;
  height: string;
  fill?: boolean;
  onClick?: React.MouseEventHandler | null;
}

const Logo = ({ classNames = [], source, width, height, fill = false, onClick }: Props) => (
  <img
    src={source}
    width={width}
    height={height}
    className={cn("Logo", [{ fill, clickable: !!onClick }], classNames)}
    onClick={onClick ?? undefined}
  />
);

export default Logo;
