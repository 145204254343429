import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  advertisement?: React.ReactNode;
  position?: "bottom-right";
}

const AdvertisementLayout = ({ classNames = [], children, advertisement = null, position = "bottom-right" }: Props) => (
  <div className={cn("AdvertisementLayout", [{ [`position-${position}`]: position }], classNames)}>
    <div className="AdvertisementLayout__content">{children}</div>
    {advertisement && <div className="AdvertisementLayout__advertisement">{advertisement}</div>}
  </div>
);

export default AdvertisementLayout;
