import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Badge from "../Badge/Badge";
import Icon from "../Icon/Icon";

type Size = "default" | "l";

interface Props extends Component {
  label: string;
  icon?: React.ReactElement<React.ComponentProps<typeof Icon>>;
  badge?: React.ReactElement<React.ComponentProps<typeof Badge>>;
  href?: string;
  active?: boolean;
  disabled?: boolean;
  fadeIn?: boolean;
  size?: Size;
  variant?: "primary" | "standout" | "accent";
  onClick?: (event: React.MouseEvent<HTMLElement>, href: string) => void;
}

const TabBarLink = ({
  classNames = [],
  label,
  icon,
  badge,
  href = "#",
  active = false,
  disabled = false,
  fadeIn = false,
  size = "default",
  variant = "standout",
  onClick = noop
}: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick(event, href);
  };

  return (
    <a
      href={href}
      className={cn(
        "TabBarLink",
        [
          {
            active,
            [`variant-${variant}`]: variant,
            [`size-${size}`]: size,
            badge: !!badge,
            disabled,
            fadeIn
          }
        ],
        classNames
      )}
      onClick={handleClick}
    >
      {badge}
      {icon && React.cloneElement(icon, { size: "l" })}
      <div className="TabBarLink__label">{label}</div>
    </a>
  );
};

export default TabBarLink;
