import {
  IsSizingDataAvailableForBicycleProductsResponse,
  SizeBicycleProductResponse,
  SizeBicycleProductTarget,
  SizingBodyAverageValues,
  SizingBodyValues
} from "../../commons/types/sizing";
import { BicycleProduct, BicycleVariant } from "../specs/bicycle";
import { ProductKey, ProductSpecKey } from "../specs/product";

import { postToService } from "./client-utils";
import { getStandaloneProductVariants } from "./specs";

export const getBikeCode = (variant: BicycleVariant): string | null =>
  variant[ProductSpecKey.Gtin] ?? variant[ProductSpecKey.Upc] ?? variant[ProductSpecKey.ArticleNumber] ?? null;

export const getBikeTarget = (bike: BicycleProduct): SizeBicycleProductTarget => ({
  productId: bike[ProductKey.ProductId],
  variants: getStandaloneProductVariants(bike).map(variant => ({
    variantId: variant[ProductKey.VariantId],
    code: getBikeCode(variant)
  }))
});

export const getBodyAverageValues = (bodyHeight: number): Promise<SizingBodyAverageValues> => {
  return postToService("sizing", "bodyAverageValues", {
    bodyHeight
  });
};

export const sizeBicycleProducts = (
  bikes: BicycleProduct[],
  body: SizingBodyValues
): Promise<SizeBicycleProductResponse> => {
  return postToService("sizing", "sizeBicycleProducts", {
    body,
    bikes: bikes.map(getBikeTarget)
  });
};

export const isSizingDataAvailableForBicycleProducts = (
  bikes: BicycleProduct[]
): Promise<IsSizingDataAvailableForBicycleProductsResponse> => {
  return postToService("sizing", "isSizingDataAvailableForBicycleProducts", {
    bikes: bikes.map(getBikeTarget)
  });
};
