import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  label: string;
  active?: boolean;
  outline?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const RadioListItem = ({ classNames = [], label = "", active = false, outline = false, onClick = noop }: Props) => (
  <button type="button" className={cn("RadioListItem", [{ active, outline }], classNames)} onClick={onClick}>
    <span className="RadioListItem__label">{label}</span>
    <div className="RadioListItem__icon" />
  </button>
);

export default RadioListItem;
