import React from "react";
import { noop } from "lodash";
import Slider from "rc-slider";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  value: number[] | number;
  min: number;
  max: number;
  step?: number;
  unit?: string;
  average?: [number, number];
  averageLabel?: string;
  disabled?: boolean;
  onChange?: (value: number[] | number) => void;
  onBlur?: () => void;
}

const RangeField2 = ({
  classNames = [],
  value,
  min,
  max,
  step = 1,
  average,
  averageLabel = "",
  unit = "",
  disabled = false,
  onChange = noop,
  onBlur = noop
}: Props) => {
  const isRange = Array.isArray(value);

  const renderAverageRange = () => {
    if (!average) {
      return null;
    }

    const range = max - min;
    const minAverage = average[0] - min;
    const maxAverage = average[1] - min;

    const averageWidthPercentage = minAverage / range;
    const averageLeftMarginPercentage = maxAverage / range - averageWidthPercentage;

    return (
      <div className="RangeField2__average">
        <div
          className="RangeField2__averageTrack"
          style={{
            left: `${averageWidthPercentage * 100}%`,
            width: `${averageLeftMarginPercentage * 100}%`,
            right: "auto"
          }}
        />
      </div>
    );
  };

  return (
    <div className={cn("RangeField2", [{ range: isRange, disabled }], classNames)}>
      {renderAverageRange()}
      <div className="RangeField2__slider">
        <Slider
          range={isRange}
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={(value: number | number[]) => {
            onChange(value);
          }}
          onBlur={onBlur}
        />
      </div>
      <div className="RangeField2__labels">
        <label className="RangeField2__minLabel">{`${min} ${unit}`}</label>
        {!!average && !!averageLabel && <label className="RangeField2__averageLabel">{averageLabel}</label>}
        <label className="RangeField2__maxLabel">{`${max} ${unit}`}</label>
      </div>
    </div>
  );
};

export default RangeField2;
