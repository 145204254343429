export const setColorScheme = (name: string) => {
  // Remove all colorscheme classes from body
  document.body.className = document.body.className.replace(/\bcolorscheme-\S+/g, "");
  document.body.classList.add(`colorscheme-${name}`);
};

export const setColorMode = (name: string) => {
  // Remove all colormode classes from body
  document.body.className = document.body.className.replace(/\bcolormode-\S+/g, "");
  document.body.classList.add(`colormode-${name}`);
};
