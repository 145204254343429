import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import * as icons from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const SearchListItem = ({ classNames = [], placeholder, value = "", onChange = noop }: Props) => {
  const active = Boolean(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) =>
    onChange(event.currentTarget.value);

  return (
    <div className={cn("SearchListItem", [{ active }], classNames)}>
      <div className="SearchListItem__filter">
        <span className="SearchListItem__icon">
          <Icon source={icons.IconSmallSearch} />
        </span>
        <input
          className="SearchListItem__field"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          // To detect the onscreen keyboard changes we need to listen to onKeyDown in all inputs
          onKeyDown={handleChange}
        />
      </div>
      {active && (
        <button type="button" className="SearchListItem__remove" onClick={() => onChange("")}>
          <Icon source={icons.IconSmallInputClear} />
        </button>
      )}
    </div>
  );
};

export default SearchListItem;
