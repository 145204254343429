import React from "react";
import TextFit from "react-textfit";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  tag?: "h1" | "h2" | "h3" | "strong" | "p" | "span";
  kind?: "base" | "xs" | "s" | "m" | "l" | "minor" | "sub" | "hero";
  fitText?: boolean;
  thin?: boolean;
  variant?: "inherit" | "primary" | "standout";
}

const Headline = ({
  classNames = [],
  children,
  variant = "inherit",
  tag = "h1",
  kind = "base",
  thin = false,
  fitText = false
}: Props) =>
  React.createElement(
    tag,
    {
      className: cn(
        "Headline",
        [
          {
            [`kind-${kind}`]: kind,
            [`variant-${variant}`]: variant,
            thin,
            fitText
          }
        ],
        classNames
      )
    },
    <>
      {fitText ? (
        <TextFit mode="single" min={42} max={62}>
          {children}
        </TextFit>
      ) : (
        children
      )}
      <div className="Headline__decoration" />
    </>
  );

export default Headline;
