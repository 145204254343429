import React from "react";
import { goBack, replace } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";

import { GlobalLocationState } from "../../../../commons/types/location";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Icon from "../../../commons/components/Icon/Icon";
import IconLink from "../../../commons/components/IconLink/IconLink";
import CommonProductFinderPartial from "../../../commons/container/ProductFinder/ProductFinderPartial";
import { connect } from "../../../commons/container/utils/loop";
import { ROUTES } from "../../../commons/routes";
import { State } from "../../reducers";

const mapStateToProps = (state: State) => ({
  hasEnteredThroughDeepLink: state.mobileApp?.hasEnteredThroughDeepLink
});

const mapDispatchToProps = {
  onGoBack: goBack,
  onReplace: replace,
  setHasEnteredThroughDeepLink: actions.mobileApp?.setHasEnteredThroughDeepLink
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ProductFinderPartial = ({
  onReplace,
  onGoBack,
  hasEnteredThroughDeepLink,
  setHasEnteredThroughDeepLink
}: Props) => {
  const { t } = useTranslation(["mobile"]);
  const location = useLocation<GlobalLocationState>();

  const renderBackButton = () => {
    const onClick = () => {
      // To prevent a mobile user from accidentally exiting the app when he enters through a deep link,
      // we redirect in place (don't push), to the main page to start a new history
      if (hasEnteredThroughDeepLink) {
        // We reset the hasEnteredThroughDeepLink-state to prevent a loop
        setHasEnteredThroughDeepLink(false);
        onReplace(ROUTES.NAVIGATION.INDEX);
      } else if (location.state?.goBackToDetails) {
        const locationState: GlobalLocationState = {
          ...{
            ...(location.state || {}),
            // active filters are removed from location state because between the details and the overview the session state is the source of truth
            activeFilters: undefined
          },
          goBackToDetails: true
        };
        onReplace(ROUTES.PRODUCT_FINDER_OVERVIEW.DETAILS, locationState);
      } else {
        onGoBack();
      }
    };

    const label = hasEnteredThroughDeepLink
      ? t("mobile:productFinder.backToMainMenuButton")
      : (location.state?.backLabel ?? t("mobile:productFinder.backButton"));

    return (
      <IconLink onClick={onClick} icon={<Icon source={icons.IconSmallArrowLeft} />} size="l">
        {label}
      </IconLink>
    );
  };

  return <CommonProductFinderPartial backButton={renderBackButton} isOffline={false} />;
};

export default connector(ProductFinderPartial);
