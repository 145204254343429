import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useTouchState from "../../libs/hooks/use-touch-state";

interface Props extends Component {
  children: React.ReactNode;
  label?: string;
  disabled?: boolean;
  elevated?: boolean;
  variant?: "primary" | "standout" | "accent";
  size?: "default" | "s";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTouchStart?: React.TouchEventHandler<HTMLButtonElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLButtonElement>;
}

const CircularButton = React.forwardRef(
  (
    {
      classNames = [],
      children,
      label = "",
      disabled = false,
      elevated = false,
      variant = "primary",
      size = "default",
      onClick = noop,
      onTouchStart: onTouchStartOuter,
      onTouchEnd
    }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { onTouchStart, touched } = useTouchState(onTouchStartOuter);

    return (
      <button
        ref={ref}
        onClick={onClick}
        type="button"
        disabled={disabled}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        className={cn(
          "CircularButton",
          [
            {
              [`variant-${variant}`]: variant,
              [`size-${size}`]: size,
              elevated,
              touched
            }
          ],
          classNames
        )}
      >
        <div className="CircularButton__circle">{children}</div>
        {!!label && <span className="CircularButton__label">{label}</span>}
      </button>
    );
  }
);

CircularButton.displayName = "CircularButton";

export default CircularButton;
