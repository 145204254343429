import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  tabBar: React.ReactNode;
  overlay?: React.ReactNode;
  showOverlay?: boolean;
  onCloseOverlay?: () => void;
}

const TabBarPageLayout = ({
  classNames = [],
  children,
  tabBar,
  overlay = null,
  showOverlay = false,
  onCloseOverlay = noop
}: Props) => {
  const nodeRef = React.useRef<HTMLElement>(null);

  const handleOverlayClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target === event.currentTarget) {
      onCloseOverlay();
    }
  };

  return (
    <section className={cn("TabBarPageLayout", [], classNames)}>
      <div className="TabBarPageLayout__content">{children}</div>
      <AnimatePresence>
        {showOverlay && (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="TabBarPageLayout__overlay"
            onClick={handleOverlayClick}
            ref={nodeRef}
          >
            {overlay}
          </motion.section>
        )}
      </AnimatePresence>
      <nav className="TabBarPageLayout__tabBar">{tabBar}</nav>
    </section>
  );
};

export default TabBarPageLayout;
