import React from "react";
import { ConnectedProps } from "react-redux";
import { matchPath, Route, Switch, useLocation } from "react-router-dom";

import { Modals } from "../../../../commons/types/modal";
import ToastLayout from "../../components/ToastLayout/ToastLayout";
import { changeLanguage } from "../../libs/externals/i18n";
import useVh from "../../libs/hooks/use-vh";
import { selectInitializedEnv } from "../../libs/selectors";
import { State } from "../../reducers";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { connect } from "../utils/loop";

import ModalPartial from "./ModalPartial";
import ToastPartial from "./ToastPartial";

export interface RouteDefinition {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
  allowOnFailedLicense?: boolean;
  hideToast?: boolean;
}

const commonRoutes: RouteDefinition[] = [];

interface OuterProps {
  routes?: RouteDefinition[];
  modals?: Modals;
}

const mapStateToProps = (state: State) => ({
  locale: selectInitializedEnv(state).locale,
  toasts: state.toasts.toasts,
  toastAlignment: state.toasts.toastAlignment
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & OuterProps;

const AppPartial = ({ locale, routes = [], modals = {}, toasts, toastAlignment }: Props) => {
  // Only compute VH css variable once per app.
  useVh();

  const location = useLocation();

  React.useEffect(() => {
    if (locale) {
      changeLanguage(locale);
    }
  }, [locale]);

  // The react-router switch should not be re-rendered during a sub-route change. If this happens, the sub-route transitions (for example, the zooming tile in tools) are not working. To keep the switch persistent during sub-route change, it will get a key, which is determined by the first level of the sub-route
  function extractFirstSubpath(path: string): string {
    if (path === "/") {
      return path;
    } else {
      const subpath = path.split("/");
      return subpath[1];
    }
  }

  const allRoutes = [...commonRoutes, ...routes];
  const renderMainApp = () => (
    <>
      <Switch key={extractFirstSubpath(location.pathname)} location={location}>
        {allRoutes.map(route => (
          <Route exact={route.exact} key={route.path} path={route.path} component={route.component} />
        ))}
        <Route key="notFoundPage">
          <NotFoundPage />
        </Route>
      </Switch>
      <ModalPartial modals={modals} />
    </>
  );

  if (
    routes
      .filter(route => route.hideToast)
      .every(route => !matchPath(location.pathname, { path: route.path, exact: route.exact }))
  ) {
    return (
      <ToastLayout
        toasts={toasts.map(toast => (
          <ToastPartial key={toast.id} toast={toast} />
        ))}
        toastAlignment={toastAlignment}
      >
        {renderMainApp()}
      </ToastLayout>
    );
  }

  return renderMainApp();
};

export default connector(AppPartial);
