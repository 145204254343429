import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const CurrentPageBreadcrumb = ({ classNames = [], children }: Props) => (
  <strong className={cn("CurrentPageBreadcrumb", [], classNames)}>{children}</strong>
);

export default CurrentPageBreadcrumb;
