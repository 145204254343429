import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Button from "../Button/Button";

// Keep this manual in sync with config.scss
const LINE_HEIGHT = 1.5;
const FONT_SIZE = 18;

interface Props extends Component {
  children: React.ReactNode;
  lines: number;
  labelButton: string;
}

interface State {
  isFold: boolean;
}

class FoldoutDescription extends React.Component<Props, State> {
  public state = {
    isFold: true
  };

  private unfold = () => {
    this.setState({ isFold: false });
  };

  private content = React.createRef<HTMLDivElement>();

  public render() {
    const { classNames = [], children, labelButton, lines } = this.props;
    const { isFold } = this.state;

    // One more line behind the shadow
    const maxHeight = LINE_HEIGHT * FONT_SIZE * lines + 1;
    const style = isFold ? { maxHeight } : {};

    return (
      <div className={cn("FoldoutDescription", [{ isFold }], classNames)}>
        <div className="FoldoutDescription__content" style={style} ref={this.content}>
          {children}
        </div>
        {isFold && (
          <div className="FoldoutDescription__buttonWrapper">
            <Button size="s" variant="tertiary" onClick={this.unfold}>
              {labelButton}
            </Button>
          </div>
        )}
      </div>
    );
  }

  public componentDidMount() {
    if (
      this.content.current &&
      this.content.current.getBoundingClientRect().height < LINE_HEIGHT * FONT_SIZE * this.props.lines + 1
    ) {
      this.unfold();
    }
  }
}

export default FoldoutDescription;
