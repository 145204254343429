import { OperatingSystem } from "detect-browser";

import { ProductId } from "../specs/product";

import { PrintMailData } from "./print-mail";

export enum ModalContentType {
  Auth = "AUTH",
  PrintMailSwitch = "PRINT_MAIL_SWITCH",
  Mail = "MAIL",
  Print = "PRINT",
  About = "ABOUT",
  LegalInformation = "LEGAL_INFORMATION",
  Error = "ERROR",
  PowerManagement = "POWER_MANAGEMENT",
  ProductDetails = "PRODUCT_DETAILS",
  PwaInformation = "PWA_INFORMATION",
  BodySizing = "BODY_SIZING",
  PdfViewer = "PDF_VIEWER",
  BrandsModelYearActivation = "BRANDS_MODEL_YEAR_ACTIVATION"
}

export type Modals = Partial<Record<ModalContentType, React.FunctionComponent<any> | React.ComponentClass<any>>>;

export interface ModalCloseOptions {
  preventReopenPreviousModal?: boolean;
}

export interface ModalContentProps {
  onClose?: (options?: ModalCloseOptions) => void;
}

export interface AuthModalContentProps extends ModalContentProps {
  onSuccess?: () => void;
  title?: string;
}

export interface ProductDetailsModalContentProps extends ModalContentProps {
  productId: ProductId;
  brandKey: string;
  initialLayout?: "default" | "full-content-static";
}

export interface PwaInformationModalContentProps extends ModalContentProps {
  operatingSystem: OperatingSystem;
}

export interface BodySizingModalContentProps extends ModalContentProps {
  variant: "productDetail" | "productFinder";
}

export interface PdfViewerModalContentProps extends ModalContentProps {
  url: string;
  hidePrint?: boolean;
  hideSend?: boolean;
  isOffline: boolean;
  isOfflineAndMailToPrintIsEnabled: boolean;
}

export interface PrintMailModalContentProps extends ModalContentProps {
  data: PrintMailData;
}

export interface ErrorModalContentProps extends ModalContentProps {
  errorMessage?: string;
  headline: string;
  paragraph: string;
  type: "mail" | "print" | "default";
}

export interface BrandsModelYearActivationModalContentProps extends ModalContentProps {
  onSaved?: () => void;
}

export type ModalProps<T extends ModalContentProps = ModalContentProps> = T & {
  close: (options?: ModalCloseOptions) => void;
};

export type ModalConfigClosed = null;
export type ModalConfigOpened = {
  contentType: ModalContentType;
  contentProps?:
    | PrintMailModalContentProps
    | PdfViewerModalContentProps
    | BodySizingModalContentProps
    | PwaInformationModalContentProps
    | ProductDetailsModalContentProps
    | AuthModalContentProps
    | ErrorModalContentProps
    | BrandsModelYearActivationModalContentProps;
};
export type ModalConfig = ModalConfigOpened | ModalConfigClosed;
