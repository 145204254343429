import React from "react";

function usePrevious<T>(value: T): T | undefined {
  const valueRef = React.useRef<T>();
  const prevValue = valueRef.current;
  valueRef.current = value;
  return prevValue;
}

export default usePrevious;
