import { PrintMailModalContentProps } from "../../../../commons/types/modal";
import * as modalActions from "../../actions/modal";
import { selectInitializedSettings } from "../selectors";

import useDispatch from "./use-dispatch";
import useSelector from "./use-selector";

type Data = PrintMailModalContentProps["data"];
type OnClose = PrintMailModalContentProps["onClose"];

export type ShareAction = ReturnType<typeof usePrintOrMail>["mail" | "print" | "printOrMail"];

const usePrintOrMail = () => {
  const dispatch = useDispatch();
  const isPrintingActive = useSelector(state => selectInitializedSettings(state).printing.active);
  const isMailActive = useSelector(state => selectInitializedSettings(state).mail.active);

  const print = (data: Data, onClose?: OnClose) => {
    dispatch(modalActions.openPrintModal({ data, onClose }));
  };

  const mail = (data: Data, onClose?: OnClose) => {
    dispatch(modalActions.openMailModal({ data, onClose }));
  };

  const printOrMail = (data: Data, onClose?: OnClose) => {
    if (isPrintingActive && isMailActive) {
      dispatch(modalActions.openPrintMailSwitchModal({ data, onClose }));
    } else if (isPrintingActive) {
      dispatch(modalActions.openPrintModal({ data, onClose }));
    } else if (isMailActive) {
      dispatch(modalActions.openMailModal({ data, onClose }));
    }
  };

  return { print, mail, printOrMail, isPrintingActive, isMailActive };
};

export default usePrintOrMail;
