import { useEffect, useState } from "react";

import { ActiveFilters } from "../../../../commons/specs/filters";
import { ProductType } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import { getSelectedBrands } from "../brand";
import { useActiveBrands } from "../queries/use-active-brands";

type Params<Type extends ProductType> = {
  activeFilters: ActiveFilters<Type>;
  brandKey?: string;
};

export default <Type extends ProductType>({ activeFilters, brandKey }: Params<Type>) => {
  const { data: activeBrands, activeBrandKeys, getActiveBrand } = useActiveBrands();

  const [selectedBrands, setSelectedBrands] = useState<Brand<Type>[]>([]);

  useEffect(() => {
    if (activeBrandKeys.length === 0) {
      return;
    }

    setSelectedBrands(
      brandKey
        ? /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion --
           * At this point we know that the `brandKey` is part of the active brand keys.
           **/
          [getActiveBrand(brandKey)!]
        : getSelectedBrands(activeBrands, activeFilters)
    );
  }, [activeFilters, brandKey, activeBrandKeys.length, activeBrands, getActiveBrand]);

  return selectedBrands;
};
