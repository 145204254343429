import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  value?: React.ReactElement<React.ComponentProps<typeof Icon>> | string;
  error?: boolean;
  focus?: boolean;
}

const PinFieldDigit = ({ classNames = [], value, focus = false, error = false }: Props) => (
  <span className={cn("PinFieldDigit", [{ focus, error }], classNames)}>{value}</span>
);

export default PinFieldDigit;
