import { AssortmentType } from "../../../commons/types/assortment";
import { Currency } from "../../../commons/types/currency";
import { Action } from "../../../commons/types/redux";
import {
  AssortmentPriceSettings,
  AssortmentSettings,
  BikeFinancialCalculatorToolSetting,
  BikeFrameSizeCalculatorSetting,
  BikepriceCalculatorToolSetting,
  CustomizationSettings,
  InitialSetupSettings,
  InitialSetupStep,
  MailSettings,
  MailToPrintSettings,
  ProductFinderAssortmentFilterSettings,
  Settings
} from "../../../commons/types/settings";

export type FetchAllAction = Action<"SETTINGS.FETCH_ALL">;
export type ReceivedAction = Action<"SETTINGS.RECEIVED", Settings>;
export type StoreAction<Value = any, SettingsOfPath = any> = Action<
  "SETTINGS.STORE",
  { path: string[]; value: Value; mergeValue?: (value: Value, prevSettings?: SettingsOfPath) => SettingsOfPath }
>;
export type RemoveAction = Action<"SETTINGS.REMOVE", { path: string[] }>;

export type SettingsActions = FetchAllAction | ReceivedAction | StoreAction | RemoveAction;

const store = <Value, SettingsOfPath = Value>(
  path: string[],
  value: Value,
  mergeValue?: (value: Value, prevSettings?: SettingsOfPath) => SettingsOfPath
): StoreAction<Value> => ({
  type: "SETTINGS.STORE",
  payload: { path, value, mergeValue }
});

export const remove = (path: string[]): RemoveAction => ({
  type: "SETTINGS.REMOVE",
  payload: { path }
});

export const fetchAll = (): FetchAllAction => ({
  type: "SETTINGS.FETCH_ALL"
});

export const received = (payload: Settings): ReceivedAction => ({
  type: "SETTINGS.RECEIVED",
  payload
});

export const setAssortmentType = (type: AssortmentType | null) =>
  store<Partial<AssortmentSettings>>(["assortment"], { type }, (value, prevSettings) => ({
    ...prevSettings,
    priceSettings: type === AssortmentType.Automatic ? prevSettings?.priceSettings : undefined,
    ...value
  }));

export const setCustomerWorldDelay = (delay: number) => store<number>(["customerWorld", "delay"], delay);

export const setFinishedInitialSetupStep = (step: InitialSetupStep) =>
  store<InitialSetupStep, InitialSetupSettings>(["initialSetup"], step, (nextStep, prevInitialSetup) => ({
    firstFinished:
      nextStep === InitialSetupStep.Done && !prevInitialSetup?.firstFinished
        ? new Date().toISOString()
        : prevInitialSetup?.firstFinished,
    finishedSteps:
      prevInitialSetup?.finishedSteps.indexOf(nextStep) === -1
        ? [...(prevInitialSetup?.finishedSteps ?? []), nextStep]
        : (prevInitialSetup?.finishedSteps ?? [])
  }));

export const setPrinterPinRequired = (pinRequired: boolean) => store<boolean>(["printing", "pinRequired"], pinRequired);

export const setPrintingActive = (active: boolean) => store<boolean>(["printing", "active"], active);

export const setMailToPrint = (payload: MailToPrintSettings) =>
  store<MailToPrintSettings>(["printing", "mailToPrint"], payload);

export const clearMailToPrint = (): RemoveAction => remove(["printing", "mailToPrint"]);

export const dismissVeloconnectEndpointModal = (endpointKey: string) =>
  store<boolean>(["veloconnect", endpointKey, "modalDismissed"], true);

export const setBikepriceCalculator = (payload: BikepriceCalculatorToolSetting) =>
  store<BikepriceCalculatorToolSetting>(["toolSettings", "bikepriceCalculator"], payload);

export const resetBikepriceCalculator = () =>
  store<Partial<BikepriceCalculatorToolSetting>>(["toolSettings", "bikepriceCalculator"], {
    meta: { touchedSettings: true, viewedSettings: true }
  });

export const setBikeFinancialCalculator = (payload: BikeFinancialCalculatorToolSetting) =>
  store<BikeFinancialCalculatorToolSetting>(["toolSettings", "bikeFinancialCalculator"], payload);

export const resetBikeFinancialCalculator = () =>
  store<Partial<BikeFinancialCalculatorToolSetting>>(["toolSettings", "bikeFinancialCalculator"], {
    meta: { touchedSettings: true, viewedSettings: true }
  });

export const setBikeFrameSizeCalculator = (payload: BikeFrameSizeCalculatorSetting) =>
  store<BikeFrameSizeCalculatorSetting>(["toolSettings", "bikeFrameSizeCalculator"], payload);

export const resetBikeFrameSizeCalculator = () =>
  store<Partial<BikeFrameSizeCalculatorSetting>>(["toolSettings", "bikeFrameSizeCalculator"], {
    meta: { touchedSettings: true, viewedSettings: true }
  });

export const setLastSuccessfulUpdate = () => store<number>(["updater", "lastSuccessfulUpdate"], new Date().getTime());

export const clearLastSuccessfulUpdate = () => store<number>(["updater", "lastSuccessfulUpdate"], 0);

export const setCustomization = (payload: CustomizationSettings) =>
  store<CustomizationSettings>(["customization"], payload);

export const setCurrency = (payload: Currency) => store<Currency>(["currency"], payload);

export const setMailSettings = (payload: MailSettings) => store<MailSettings>(["mail"], payload);

export const setCustomAssortmentSettings = (payload: {
  productFinderFilterSettings: ProductFinderAssortmentFilterSettings;
  priceSettings: AssortmentPriceSettings;
}) =>
  store<Partial<AssortmentSettings>>(["assortment"], payload, (value, prevSettings) => ({
    ...prevSettings,
    ...value
  }));

export const setAssortmentSettings = (payload: AssortmentSettings) =>
  store<AssortmentSettings>(["assortment"], payload);
