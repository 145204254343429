import { Slides } from "../../../commons/types/customer-world";
import { Action } from "../../../commons/types/redux";

export type EnableTimerAction = Action<"CUSTOMER_WORLD.ENABLE_TIMER">;
export type DisableTimerAction = Action<"CUSTOMER_WORLD.DISABLE_TIMER">;
export type NextSlideAction = Action<"CUSTOMER_WORLD.NEXT_SLIDE">;
export type PreviousSlideAction = Action<"CUSTOMER_WORLD.PREVIOUS_SLIDE">;
export type SlidesReceivedAction = Action<"CUSTOMER_WORLD.SLIDES_RECEIVED", { slides: Slides[] }>;
export type ViaTimeoutAction = Action<"CUSTOMER_WORLD.VIA_TIMEOUT">;

export type CustomerWorldActions =
  | EnableTimerAction
  | DisableTimerAction
  | SlidesReceivedAction
  | PreviousSlideAction
  | NextSlideAction
  | ViaTimeoutAction;

export const enableTimer = (): EnableTimerAction => ({
  type: "CUSTOMER_WORLD.ENABLE_TIMER"
});

export const disableTimer = (): DisableTimerAction => ({
  type: "CUSTOMER_WORLD.DISABLE_TIMER"
});

export const nextSlide = (): NextSlideAction => ({
  type: "CUSTOMER_WORLD.NEXT_SLIDE"
});

export const previousSlide = (): PreviousSlideAction => ({
  type: "CUSTOMER_WORLD.PREVIOUS_SLIDE"
});

export const slidesReceived = (slides: Slides[]): SlidesReceivedAction => ({
  type: "CUSTOMER_WORLD.SLIDES_RECEIVED",
  payload: {
    slides
  }
});

export const viaTimeout = (): ViaTimeoutAction => ({
  type: "CUSTOMER_WORLD.VIA_TIMEOUT"
});
