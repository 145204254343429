import React from "react";
import { FormikProps, FormikValues } from "formik";
import { get } from "lodash";

export interface FieldProps {
  name: string;
  value?: unknown;
  checked?: boolean;
  error?: string;
  onBlur?: (event?: Event) => void;
  onChange?: (value: unknown) => void;
  onClearClick?: () => void;
}

export const isFocusEvent = (event: unknown): event is React.FocusEvent<HTMLInputElement> => {
  return (
    Boolean((event as React.FocusEvent<HTMLInputElement>)?.target?.name) ||
    Boolean((event as React.FocusEvent<HTMLInputElement>)?.target?.id)
  );
};

const Field = <V extends FormikValues>({
  formik,
  children: field,
  convertToFormValue = (value: unknown) => value
}: {
  formik: FormikProps<V>;
  children: React.ReactElement<FieldProps>;
  convertToFormValue?: (value: unknown) => unknown;
}) => {
  const { value, checked, name, onChange } = field.props;

  return React.cloneElement(field, {
    value: value ?? get(formik.values, name),
    checked: checked ?? get(formik.values, name),
    error: get(formik.touched, name) ? (get(formik.errors, name) as string) : undefined,
    onBlur: (event?: Event) => (isFocusEvent(event) ? formik.handleBlur(event) : formik.setFieldTouched(name)),
    onChange: onChange ?? ((value: unknown) => formik.setFieldValue(name, convertToFormValue(value))),
    onClearClick: () => {
      formik.setFieldValue(name, "");
      formik.setFieldTouched(name);
    }
  });
};

export default Field;
