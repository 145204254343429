import { strict as assert } from "assert";

import { CustomerGroupConfig, WallpaperId } from "../../../commons/config/customer-groups";
import wallpapers, { WallpaperConfig } from "../config/wallpapers";

export const getWallpaperConfig = (
  id: WallpaperId | undefined,
  customerGroup: CustomerGroupConfig
): WallpaperConfig => {
  if (id && customerGroup.wallpapers.includes(id)) {
    const wallpaper = wallpapers.find(wallpaper => wallpaper.id === id);
    assert(wallpaper, `Wallpaper with id ${id} not found`);
    return wallpaper;
  } else {
    const defaultWallpaper = wallpapers.find(wallpaper => wallpaper.id === customerGroup.defaultWallpaper);
    assert(defaultWallpaper, `Default wallpaper with id ${customerGroup.defaultWallpaper} not found`);
    return defaultWallpaper;
  }
};
