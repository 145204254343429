import { Action } from "../../../commons/types/redux";

export type SetKeyboardOpenAction = Action<"KEYBOARD.SET_KEYBOARD_OPEN", { open: boolean }>;

export type KeyboardActions = SetKeyboardOpenAction;

export const setKeyboardOpen = (open: boolean): SetKeyboardOpenAction => ({
  type: "KEYBOARD.SET_KEYBOARD_OPEN",
  payload: {
    open
  }
});
