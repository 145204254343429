import { AdvertiserArea, ProductDetailsAdvertiser } from "../../../commons/types/advertisers";
import { Locale, TranslationKey } from "../../../commons/types/localization";
import { MediaQueryDefinitions } from "../../../commons/types/media-queries";
import { InitialSetupStep } from "../../../commons/types/settings";

export interface SetupRoutes {
  [name: string]: string;
}

export enum SupportedBrowser {
  Android = "android",
  Ios = "ios"
}

export interface MixpanelInitConfig {
  token: {
    dev: string;
    prod: string;
  };
  sessionTimeout: number;
}

export interface SharedConfig {
  shared: {
    mediaQueryBreakpoints: MediaQueryDefinitions;
    advertisers: Record<AdvertiserArea, ProductDetailsAdvertiser[]>;
    personalCodeLength: number;
    doubleClickTimeout: number;
    minLoadingTimeout: number;
    printMaxCopies: number;
    checkServiceAvailability: {
      retryCount: number;
      retryDelay: number;
    };
    filter: {
      filterFetchDelay: number;
      sizingLimit: number;
    };
    i18n: {
      languages: TranslationKey[];
      defaultLocale: Locale;
      commonNamespace: string;
    };
    /** Animation durations in seconds used with framer motion */
    transitionsDurations: {
      fast: number;
      default: number;
    };
  };
}

export interface MobileConfig {
  mobile: {
    allowPortraitMode: boolean;
    minWidth: number;
    minHeight: number;
    supportedOperatingSystems: SupportedBrowser[];
    sentry: {
      dsn: string;
    };
    mixpanel: MixpanelInitConfig;
  };
}

export interface KioskConfig {
  kiosk: {
    bicoShopSecretKey: string;
    shutdownButtonCountdown: number;
    keyboardTransitionTimeout: number;
    offlineToastDelay: number;
    updateNoticeTimeout: number;
    minutesBeforeShutdownNotice: number;
    minutesForShutdownDelay: number;
    timeoutInMsBeforeRedirectFromIdleAuthPage: number;
    timeoutInMsBeforeRedirectFromIdleSettings: number;
    license: {
      blocklistUrl: string;
      checkTimeout: number;
    };
    webviewSessionPartitionName: {
      tool: string;
    };
    cuttlyApiKey: string;
    wherebyApiKey: string;
    setup: {
      steps: InitialSetupStep[];
      routes: SetupRoutes;
    };
    customerWorld: {
      defaultSlideDuration: number;
      defaultNewsSlideDuration: number;
      timeoutAfterInteraction: number;
      willResumeDuration: number;
      closeNavigationTimeout: number;
    };
    sentry: {
      dsn: string;
      ignoreErrors?: Array<RegExp | string>;
    };
    mixpanel: MixpanelInitConfig;
    periodicUpdate: {
      /**
       * check update every [x] hours
       */
      interval: number;
    };
  };
}
