import { formatUnit } from "../libs/formatters";
import { SizingBodyValues } from "../types/sizing";

import {
  AssortmentFilterKeys,
  FilterConfig,
  FilterGroups,
  FilterTypes,
  getActiveArrayFilters,
  getActiveFilters,
  getActiveRangeFilters
} from "./filters";
import {
  Category,
  CategoryGroupDefinition,
  CategoryGroupKey,
  Product,
  ProductSpec,
  specDefinitions as productSpecDefinitions,
  ProductSpecKey,
  ProductType,
  ProductVariant,
  SpecCompareDefinition,
  SpecConfig,
  SpecDefinitions,
  SpecGroup,
  StandaloneProductVariant
} from "./product";

export type BicycleTypeKey = "BIKE" | "E_BIKE" | "S_PEDELEC";

export type MaterialKey = "ALUMINUM" | "CARBON" | "STEEL" | "TITANIUM" | "MAGNESIUM";

export type DriveTrainKey = "BELT" | "CHAIN";

export enum BicycleSpecKey {
  /** @deprecated */
  TypeId = "typeId",
  /** @deprecated */
  TypeName = "typeName",
  /** @deprecated */
  ModelVariant = "modelVariant",
  FrameTypeKey = "frameTypeKey",
  FrameSize = "frameSize",
  Frame = "frame",
  Material = "material",
  MaterialKey = "materialKey",
  Fork = "fork",
  ForkSuspensionTravel = "forkSuspensionTravel",
  Damper = "damper",
  DamperSuspensionTravel = "damperSuspensionTravel",
  DriveTrain = "driveTrain",
  DriveTrainKey = "driveTrainKey",
  HasDamper = "hasDamper",
  MarketingColor = "marketingColor",
  PrimaryColor = "primaryColor",
  Color = "color",
  Gear = "gear",
  GearTypeKey = "gearTypeKey",
  HasGearHub = "hasGearHub",
  GearShiftLever = "gearShiftLever",
  GearCount = "gearCount",
  FrontDerailleur = "frontDerailleur",
  HasFrontDerailleur = "hasFrontDerailleur",
  Brakes = "brakes",
  BrakeTypeKey = "brakeTypeKey",
  BrakeLever = "brakeLever",
  HasCoasterBrake = "hasCoasterBrake",
  Saddle = "saddle",
  SeatPost = "seatPost",
  SaddleClamping = "saddleClamping",
  Handlebars = "handlebars",
  Stem = "stem",
  Handles = "handles",
  Headset = "headset",
  Pedals = "pedals",
  Rims = "rims",
  Tyres = "tyres",
  Wheelset = "wheelset",
  HasPlusSizeWheels = "hasPlusSizeWheels",
  HasMixedWheelSizes = "hasMixedWheelSizes",
  WheelSize = "wheelSize",
  Hubs = "hubs",
  Spokes = "spokes",
  Lighting = "lighting",
  HasLighting = "hasLighting",
  RearLight = "rearLight",
  Dynamo = "dynamo",
  Fenders = "fenders",
  ChainGuard = "chainGuard",
  Stand = "stand",
  Carrier = "carrier",
  FrontCarrier = "frontCarrier",
  Lock = "lock",
  BottomBracket = "bottomBracket",
  Crankset = "crankset",
  Sprockets = "sprockets",
  Chain = "chain",
  BikeTypeKey = "bikeTypeKey",
  Engine = "engine",
  EngineBrand = "engineBrand",
  EngineSpeed = "engineSpeed",
  HasEngine = "hasEngine",
  Battery = "battery",
  BatteryCapacity = "batteryCapacity",
  Display = "display",
  Extras = "extras",
  BidexInfoText = "bidexInfoText",
  Bidex = "BIDEX",
  Weight = "weight",
  MaxTotalWeight = "maxTotalWeight"
}

export interface BicycleSpec extends ProductSpec {
  [BicycleSpecKey.TypeId]: number | null;
  [BicycleSpecKey.TypeName]: string | null;
  [BicycleSpecKey.ModelVariant]?: string | null;
  [BicycleSpecKey.FrameSize]?: string | null;
  [BicycleSpecKey.FrameTypeKey]: string | null;
  [BicycleSpecKey.Frame]: string | null;
  [BicycleSpecKey.Material]: string | null;
  [BicycleSpecKey.MaterialKey]: MaterialKey | null;
  [BicycleSpecKey.Fork]: string | null;
  [BicycleSpecKey.ForkSuspensionTravel]: number | null;
  [BicycleSpecKey.Damper]: string | null;
  [BicycleSpecKey.DamperSuspensionTravel]: number | null;
  [BicycleSpecKey.HasDamper]: boolean;
  [BicycleSpecKey.MarketingColor]?: string | null;
  [BicycleSpecKey.Color]?: string[];
  [BicycleSpecKey.PrimaryColor]?: string | null;
  [BicycleSpecKey.Gear]: string | null;
  [BicycleSpecKey.GearTypeKey]: string | null;
  [BicycleSpecKey.HasGearHub]: boolean;
  [BicycleSpecKey.GearShiftLever]: string | null;
  [BicycleSpecKey.GearCount]: number | null;
  [BicycleSpecKey.FrontDerailleur]: string | null;
  [BicycleSpecKey.HasFrontDerailleur]: boolean;
  [BicycleSpecKey.Brakes]: string | null;
  [BicycleSpecKey.BrakeTypeKey]: string | null;
  [BicycleSpecKey.BrakeLever]: string | null;
  [BicycleSpecKey.HasCoasterBrake]?: boolean | null;
  [BicycleSpecKey.Saddle]: string | null;
  [BicycleSpecKey.SeatPost]: string | null;
  [BicycleSpecKey.SaddleClamping]: string | null;
  [BicycleSpecKey.Handlebars]: string | null;
  [BicycleSpecKey.Stem]: string | null;
  [BicycleSpecKey.Handles]: string | null;
  [BicycleSpecKey.Headset]: string | null;
  [BicycleSpecKey.Pedals]: string | null;
  [BicycleSpecKey.Rims]: string | null;
  [BicycleSpecKey.Tyres]: string | null;
  [BicycleSpecKey.Wheelset]: string | null;
  [BicycleSpecKey.HasPlusSizeWheels]: boolean;
  [BicycleSpecKey.HasMixedWheelSizes]: boolean;
  [BicycleSpecKey.WheelSize]: number | null;
  [BicycleSpecKey.Hubs]: string | null;
  [BicycleSpecKey.Spokes]: string | null;
  [BicycleSpecKey.Lighting]: string | null;
  [BicycleSpecKey.HasLighting]: boolean;
  [BicycleSpecKey.RearLight]: string | null;
  [BicycleSpecKey.Dynamo]: string | null;
  [BicycleSpecKey.Fenders]: string | null;
  [BicycleSpecKey.ChainGuard]: string | null;
  [BicycleSpecKey.Stand]: string | null;
  [BicycleSpecKey.Carrier]: string | null;
  [BicycleSpecKey.FrontCarrier]: string | null;
  [BicycleSpecKey.Lock]: string | null;
  [BicycleSpecKey.BottomBracket]: string | null;
  [BicycleSpecKey.Crankset]: string | null;
  [BicycleSpecKey.Sprockets]: string | null;
  [BicycleSpecKey.Chain]: string | null;
  [BicycleSpecKey.DriveTrain]?: string | null;
  [BicycleSpecKey.DriveTrainKey]?: DriveTrainKey | null;
  [BicycleSpecKey.BikeTypeKey]: BicycleTypeKey | null;
  [BicycleSpecKey.Engine]: string | null;
  [BicycleSpecKey.EngineBrand]: string | null;
  [BicycleSpecKey.EngineSpeed]: number | null;
  [BicycleSpecKey.HasEngine]: boolean;
  [BicycleSpecKey.Battery]: string | null;
  [BicycleSpecKey.BatteryCapacity]?: string | null;
  [BicycleSpecKey.Display]: string | null;
  [BicycleSpecKey.Extras]: string | null;
  [BicycleSpecKey.BidexInfoText]: string | null;
  [BicycleSpecKey.Bidex]: string | null;
  [BicycleSpecKey.Weight]?: number | null;
  [BicycleSpecKey.MaxTotalWeight]?: number | null;
}

export type BicycleVariant = ProductVariant<ProductType.Bicycle>;

export type BicycleProduct = Product<ProductType.Bicycle>;

export type StandaloneBicycleVariant = StandaloneProductVariant<ProductType.Bicycle>;

export const specDefinitions: SpecDefinitions<ProductType.Bicycle> = {
  ...(productSpecDefinitions as SpecDefinitions<ProductType.Bicycle>),
  [BicycleSpecKey.TypeId]: {},
  [BicycleSpecKey.TypeName]: {},
  [BicycleSpecKey.FrameSize]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Frame]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Material]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Fork]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.ForkSuspensionTravel]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value} mm`
  },
  [BicycleSpecKey.Damper]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.DamperSuspensionTravel]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value} mm`
  },
  [BicycleSpecKey.HasDamper]: {},
  [BicycleSpecKey.MarketingColor]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Color]: {},
  [BicycleSpecKey.Gear]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.HasGearHub]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    // Only show false values if there is also a true value.
    visibleValues: values => {
      if (values.length === 1) {
        return values.filter(value => value === true);
      } else {
        return values;
      }
    },
    formatValue: (value, variant, { i18n, i18nSpecKey }) => i18n.t(`${i18nSpecKey}.valueTrue`),
    formatValues: (values, product, { i18n, i18nSpecKey }) => {
      if (values.length === 1) {
        return i18n.t(`${i18nSpecKey}.valueTrue`);
      } else {
        return i18n.t(`${i18nSpecKey}.valueMixed`);
      }
    }
  },
  [BicycleSpecKey.GearShiftLever]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.GearCount]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.FrontDerailleur]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.HasFrontDerailleur]: {},
  [BicycleSpecKey.Brakes]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.BrakeLever]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Saddle]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.SeatPost]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.SaddleClamping]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Handlebars]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Stem]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Handles]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Headset]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Pedals]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Rims]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Tyres]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Wheelset]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.HasPlusSizeWheels]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    // Only show false values if there is also a true value.
    visibleValues: values => {
      if (values.length === 1) {
        return values.filter(value => value === true);
      } else {
        return values;
      }
    },
    formatValue: (value, variant, { i18n, i18nSpecKey }) => i18n.t(`${i18nSpecKey}.valueTrue`),
    formatValues: (values, product, { i18n, i18nSpecKey }) => {
      if (values.length === 1) {
        return i18n.t(`${i18nSpecKey}.valueTrue`);
      } else {
        return i18n.t(`${i18nSpecKey}.valueMixed`);
      }
    }
  },
  [BicycleSpecKey.WheelSize]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value}"`
  },
  [BicycleSpecKey.Hubs]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Spokes]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Lighting]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.HasLighting]: {},
  [BicycleSpecKey.RearLight]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Dynamo]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Fenders]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.ChainGuard]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Stand]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Carrier]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.FrontCarrier]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Lock]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.BottomBracket]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Crankset]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Sprockets]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Chain]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Engine]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.EngineSpeed]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: value => `${value} km/h`
  },
  [BicycleSpecKey.HasEngine]: {},
  [BicycleSpecKey.Battery]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Display]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.Extras]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    }
  },
  [BicycleSpecKey.BidexInfoText]: {},
  [BicycleSpecKey.Bidex]: {},
  [BicycleSpecKey.ModelVariant]: {},
  [BicycleSpecKey.Weight]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: (value, _variant, { i18n: { language: locale } }) =>
      `ca. ${formatUnit(value, locale, { unit: "kilogram" })}`,
    formatValues: (values, _variant, { i18n: { language: locale } }) => {
      const minWeight = Math.min(...values);
      const maxWeight = Math.max(...values);

      if (minWeight > 0 && maxWeight > 0) {
        return minWeight === maxWeight
          ? `ca. ${formatUnit(minWeight, locale, { unit: "kilogram" })}`
          : `ca. ${formatUnit(minWeight, locale, { unit: "kilogram" })} — ${formatUnit(maxWeight, locale, {
              unit: "kilogram"
            })}`;
      } else {
        return undefined;
      }
    }
  },
  [BicycleSpecKey.MaxTotalWeight]: {
    enable: {
      variantInformationBox: true,
      variantsTable: true,
      variantFilters: true
    },
    visibleValue: value => value > 0,
    formatValue: (value, _variant, { i18n: { language: locale } }) =>
      `max. ${formatUnit(value, locale, { unit: "kilogram" })}`
  }
};

export const specCompareDefinition: SpecCompareDefinition<ProductType.Bicycle> = [
  ProductSpecKey.BrandName,
  ProductSpecKey.ModelName,
  ProductSpecKey.ModelYear,
  BicycleSpecKey.FrameSize,
  ProductSpecKey.Price,
  BicycleSpecKey.Frame,
  BicycleSpecKey.Material,
  BicycleSpecKey.Fork,
  BicycleSpecKey.ForkSuspensionTravel,
  BicycleSpecKey.Damper,
  BicycleSpecKey.DamperSuspensionTravel,
  BicycleSpecKey.MarketingColor,
  BicycleSpecKey.Gear,
  BicycleSpecKey.HasGearHub,
  BicycleSpecKey.GearShiftLever,
  BicycleSpecKey.GearCount,
  BicycleSpecKey.FrontDerailleur,
  BicycleSpecKey.Brakes,
  BicycleSpecKey.BrakeLever,
  BicycleSpecKey.Saddle,
  BicycleSpecKey.SeatPost,
  BicycleSpecKey.SaddleClamping,
  BicycleSpecKey.Handlebars,
  BicycleSpecKey.Stem,
  BicycleSpecKey.Handles,
  BicycleSpecKey.Headset,
  BicycleSpecKey.Pedals,
  BicycleSpecKey.Rims,
  BicycleSpecKey.Tyres,
  BicycleSpecKey.Wheelset,
  BicycleSpecKey.HasPlusSizeWheels,
  BicycleSpecKey.WheelSize,
  BicycleSpecKey.Hubs,
  BicycleSpecKey.Spokes,
  BicycleSpecKey.Lighting,
  BicycleSpecKey.RearLight,
  BicycleSpecKey.Dynamo,
  BicycleSpecKey.Fenders,
  BicycleSpecKey.ChainGuard,
  BicycleSpecKey.Stand,
  BicycleSpecKey.Carrier,
  BicycleSpecKey.FrontCarrier,
  BicycleSpecKey.Lock,
  BicycleSpecKey.BottomBracket,
  BicycleSpecKey.Crankset,
  BicycleSpecKey.Sprockets,
  BicycleSpecKey.Chain,
  BicycleSpecKey.Engine,
  BicycleSpecKey.EngineSpeed,
  BicycleSpecKey.Battery,
  BicycleSpecKey.Display,
  BicycleSpecKey.Weight,
  BicycleSpecKey.MaxTotalWeight
];

// Sync group keys with translations in specs.json
export type BicycleSpecGroupKey =
  | "FRAME"
  | "DRIVING_GEAR"
  | "POWERTRAIN"
  | "GEAR"
  | "BRAKES"
  | "WHEELS"
  | "STEERING_UNIT"
  | "SEAT_UNIT"
  | "LIGHTNING"
  | "ATTACHMENTS"
  | "MODEL_INFOS";

// Sync group keys with translations in specs.json
export const specGroups: SpecGroup<ProductType.Bicycle>[] = [
  {
    key: "FRAME",
    specKeys: [
      BicycleSpecKey.Frame,
      BicycleSpecKey.Material,
      BicycleSpecKey.WheelSize,
      BicycleSpecKey.ForkSuspensionTravel
    ]
  },
  {
    key: "DRIVING_GEAR",
    specKeys: [BicycleSpecKey.Fork, BicycleSpecKey.Damper]
  },
  {
    key: "POWERTRAIN",
    specKeys: [BicycleSpecKey.Engine, BicycleSpecKey.EngineSpeed, BicycleSpecKey.Display, BicycleSpecKey.Battery]
  },
  {
    key: "GEAR",
    specKeys: [
      BicycleSpecKey.Gear,
      BicycleSpecKey.GearShiftLever,
      BicycleSpecKey.GearCount,
      BicycleSpecKey.FrontDerailleur
    ]
  },
  {
    key: "BRAKES",
    specKeys: [BicycleSpecKey.Brakes, BicycleSpecKey.BrakeLever]
  },
  {
    key: "WHEELS",
    specKeys: [BicycleSpecKey.Tyres, BicycleSpecKey.Rims, BicycleSpecKey.Hubs]
  },
  {
    key: "STEERING_UNIT",
    specKeys: [BicycleSpecKey.Handlebars, BicycleSpecKey.Stem, BicycleSpecKey.Handles, BicycleSpecKey.Headset]
  },
  {
    key: "SEAT_UNIT",
    specKeys: [BicycleSpecKey.Saddle, BicycleSpecKey.SeatPost, BicycleSpecKey.SaddleClamping]
  },
  {
    key: "LIGHTNING",
    specKeys: [BicycleSpecKey.Lighting, BicycleSpecKey.RearLight, BicycleSpecKey.Dynamo]
  },
  {
    key: "ATTACHMENTS",
    specKeys: [
      BicycleSpecKey.Carrier,
      BicycleSpecKey.FrontCarrier,
      BicycleSpecKey.Fenders,
      BicycleSpecKey.Pedals,
      BicycleSpecKey.Lock,
      BicycleSpecKey.Stand
    ]
  },
  {
    key: "MODEL_INFOS",
    specKeys: [
      BicycleSpecKey.FrameSize,
      BicycleSpecKey.Weight,
      BicycleSpecKey.MaxTotalWeight,
      BicycleSpecKey.MarketingColor,
      BicycleSpecKey.Color,
      ProductSpecKey.ModelYear,
      BicycleSpecKey.Extras,
      ProductSpecKey.Price
    ]
  }
];

// Specs which are used to fullfill the minimal column count in variant table
export const variantsTableFallbackSpecKeys: (keyof BicycleSpec)[] = [
  BicycleSpecKey.Frame,
  BicycleSpecKey.FrameSize,
  BicycleSpecKey.MarketingColor,
  ProductSpecKey.ModelName
];

export const variantsTableSpecKeysPriorityList: (keyof BicycleSpec)[] = [
  BicycleSpecKey.Battery,
  BicycleSpecKey.Gear,
  BicycleSpecKey.MarketingColor
];

export const variantInformationBoxSpecKeyPriorityList: (keyof BicycleSpec)[] = [
  ProductSpecKey.ArticleNumber,
  ProductSpecKey.Gtin,
  ProductSpecKey.Upc
];

// Filter config

export enum CustomBicycleFilterKey {
  Carrier = `${BicycleSpecKey.Carrier},${BicycleSpecKey.FrontCarrier}`,
  Sizing = "sizing"
}

export interface CustomBicycleFilterSpec {
  [CustomBicycleFilterKey.Sizing]: SizingBodyValues;
  [CustomBicycleFilterKey.Carrier]: string;
}

export const filterConfig = {
  [ProductSpecKey.ManualAssortment]: {
    type: "default",
    getActiveFilters: getActiveFilters(ProductSpecKey.ManualAssortment, FilterTypes.Eqs)
  },
  [ProductSpecKey.AutomaticAssortment]: {
    type: "default",
    getActiveFilters: getActiveArrayFilters(ProductSpecKey.AutomaticAssortment)
  },
  [ProductSpecKey.VeloconnectAssortment]: {
    type: "default",
    getActiveFilters: getActiveArrayFilters(ProductSpecKey.VeloconnectAssortment)
  },
  [ProductSpecKey.BrandKey]: {
    type: "default",
    getActiveFilters: getActiveFilters(ProductSpecKey.BrandKey)
  },
  [ProductSpecKey.ModelYear]: {
    type: "default",
    getActiveFilters: getActiveFilters(ProductSpecKey.ModelYear)
  },
  [BicycleSpecKey.BikeTypeKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.BikeTypeKey, FilterTypes.Eqs)
  },
  [ProductSpecKey.CategoryKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(ProductSpecKey.CategoryKey)
  },
  [ProductSpecKey.Price]: {
    type: "range",
    getActiveFilters: getActiveRangeFilters(ProductSpecKey.Price)
  },
  [BicycleSpecKey.EngineBrand]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.EngineBrand, FilterTypes.Eqs)
  },
  [BicycleSpecKey.BatteryCapacity]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.BatteryCapacity, FilterTypes.Eqs)
  },
  [BicycleSpecKey.FrameTypeKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.FrameTypeKey, FilterTypes.Eqs)
  },
  [BicycleSpecKey.PrimaryColor]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.PrimaryColor)
  },
  [BicycleSpecKey.HasDamper]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.HasDamper, FilterTypes.Eqs)
  },
  [BicycleSpecKey.MaterialKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.MaterialKey, FilterTypes.Eqs)
  },
  [CustomBicycleFilterKey.Sizing]: {
    type: "custom",
    getActiveFilters: values => {
      const bodySizingValues = values[0];

      return Object.keys(bodySizingValues).map(key => ({
        key: `sizing.${key}`,
        value: bodySizingValues[key as keyof SizingBodyValues]
      }));
    }
  },
  [BicycleSpecKey.ForkSuspensionTravel]: {
    type: "range",
    canDisable: true,
    getActiveFilters: getActiveRangeFilters<ProductType.Bicycle>(BicycleSpecKey.ForkSuspensionTravel)
  },
  [BicycleSpecKey.WheelSize]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.WheelSize, FilterTypes.Eqs)
  },
  [BicycleSpecKey.HasMixedWheelSizes]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.HasMixedWheelSizes, FilterTypes.Eqs)
  },
  [BicycleSpecKey.DriveTrainKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.DriveTrainKey, FilterTypes.Eqs)
  },
  [BicycleSpecKey.GearTypeKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.GearTypeKey, FilterTypes.Eqs)
  },
  [BicycleSpecKey.HasFrontDerailleur]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.HasFrontDerailleur, FilterTypes.Eqs)
  },
  [BicycleSpecKey.GearCount]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.GearCount, FilterTypes.Eqs)
  },
  [BicycleSpecKey.BrakeTypeKey]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.BrakeTypeKey, FilterTypes.Eqs)
  },
  [BicycleSpecKey.HasCoasterBrake]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.HasCoasterBrake, FilterTypes.Eqs)
  },
  [BicycleSpecKey.HasLighting]: {
    type: "default",
    getActiveFilters: getActiveFilters<ProductType.Bicycle>(BicycleSpecKey.HasLighting, FilterTypes.Eqs)
  },
  [CustomBicycleFilterKey.Carrier]: {
    type: "custom",
    getActiveFilters: values =>
      values.map(concatenatedValue => {
        const [key, value] = concatenatedValue.split("-");

        return {
          key,
          value,
          filterType: FilterTypes.Has
        };
      })
  }
} as const satisfies FilterConfig<ProductType.Bicycle>;

export const filterGroups = [
  {
    key: "general",
    filters: [
      ProductSpecKey.ManualAssortment,
      ProductSpecKey.AutomaticAssortment,
      ProductSpecKey.BrandKey,
      ProductSpecKey.ModelYear,
      BicycleSpecKey.BikeTypeKey,
      ProductSpecKey.CategoryKey,
      ProductSpecKey.Price
    ]
  },
  {
    key: "frame",
    filters: [
      BicycleSpecKey.HasDamper,
      BicycleSpecKey.FrameTypeKey,
      BicycleSpecKey.PrimaryColor,
      BicycleSpecKey.ForkSuspensionTravel,
      BicycleSpecKey.MaterialKey,
      CustomBicycleFilterKey.Sizing,
      BicycleSpecKey.WheelSize,
      BicycleSpecKey.HasMixedWheelSizes
    ]
  },
  {
    key: "specifications",
    filters: [
      BicycleSpecKey.EngineBrand,
      BicycleSpecKey.BatteryCapacity,
      BicycleSpecKey.BrakeTypeKey,
      BicycleSpecKey.HasCoasterBrake,
      BicycleSpecKey.HasLighting,
      CustomBicycleFilterKey.Carrier
    ]
  },
  {
    key: "gear",
    filters: [
      BicycleSpecKey.DriveTrainKey,
      BicycleSpecKey.GearTypeKey,
      BicycleSpecKey.HasFrontDerailleur,
      BicycleSpecKey.GearCount
    ]
  }
] as const satisfies FilterGroups<ProductType.Bicycle, typeof filterConfig>;

export const assortmentFilterKeys: AssortmentFilterKeys<ProductType.Bicycle, typeof filterConfig> = [
  ProductSpecKey.ManualAssortment,
  ProductSpecKey.BrandKey,
  BicycleSpecKey.BikeTypeKey,
  ProductSpecKey.CategoryKey,
  ProductSpecKey.Price,
  ProductSpecKey.ModelYear
];

// Category config

export const bicycleCategoryGroupKeys = [
  "offroad",
  "allroadTour",
  "road",
  "cityDaily",
  "childYouthBmx",
  "other"
] as const satisfies CategoryGroupKey[];
export type BicycleCategoryGroupKeys = (typeof bicycleCategoryGroupKeys)[number];

const categories = [
  "DOWNHILL",
  "ENDURO",
  "ALLMTN",
  "ALLMTN_HT",
  "FAT",
  "CROSSCOUNTRY_FS",
  "CROSSCOUNTRY_HT",
  "CROSS",
  "TREKKING",
  "RACE",
  "GRAVEL",
  "FITNESS",
  "URBAN",
  "DUTCH",
  "CITY",
  "FOLDING",
  "TANDEM",
  "CARGO",
  "COMPACT",
  "BMX",
  "YOUTH_KIDS",
  "TRAINER",
  "FRAME",
  "UNKNOWN"
] as const satisfies Category[];
export type BicycleCategory = (typeof categories)[number];
export const bicycleCategories = categories;

export const categoryGroups: CategoryGroupDefinition[] = [
  {
    key: "offroad",
    categories: ["DOWNHILL", "ENDURO", "ALLMTN", "ALLMTN_HT", "FAT", "CROSSCOUNTRY_FS", "CROSSCOUNTRY_HT"]
  },
  {
    key: "allroadTour",
    categories: ["CROSS", "TREKKING"]
  },
  {
    key: "road",
    categories: ["RACE", "GRAVEL", "FITNESS"]
  },
  {
    key: "cityDaily",
    categories: ["URBAN", "DUTCH", "CITY", "FOLDING", "TANDEM", "CARGO", "COMPACT"]
  },
  {
    key: "childYouthBmx",
    categories: ["BMX", "YOUTH_KIDS", "TRAINER"]
  },
  {
    key: "other",
    categories: ["FRAME", "UNKNOWN"]
  }
].map(group => ({ ...group, productType: ProductType.Bicycle }));

const specConfig: SpecConfig<ProductType.Bicycle> = {
  productType: ProductType.Bicycle,
  specDefinitions,
  specCompareDefinition,
  specGroups,
  variantsTableFallbackSpecKeys,
  variantsTableSpecKeysPriorityList,
  variantInformationBoxSpecKeyPriorityList,
  assortmentFilterKeys,
  filterGroups,
  filterConfig,
  categoryGroups,
  categories: bicycleCategories,
  categoryGroupKeys: bicycleCategoryGroupKeys
};

export default specConfig;
