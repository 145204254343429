import { Env } from "../types/env";
import { Locale } from "../types/localization";

import { getFromService, postToService } from "./client-utils";
import envelope from "./externals/envelope";
import { retryPromise } from "./promise";

export const getEnv = (): Promise<Env> => getFromService("env", "/");

export const updateLocale = (locale: Locale): Promise<Env> => postToService("env", "/locale", { value: locale });

export const getServiceTag = async (): Promise<string> =>
  envelope.serviceTag ?? (await retryPromise(getEnv, 6, 250)).serviceTag;
