import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type JustifyContent = "start" | "space-between" | "end" | "center";

type AlignItems = "normal" | "flex-start" | "center" | "flex-end";

type Direction = "row" | "column";

type Gap = "none" | "base" | "xs" | "s" | "sl" | "l" | "xxxl";

interface Props extends Component {
  children: React.ReactNode;
  gap?: Gap;
  justifyContent?: JustifyContent;
  direction?: Direction;
  alignItems?: AlignItems;
  alignLastItemToRight?: boolean;
}

const FlexLayout = ({
  children,
  gap = "none",
  justifyContent = "start",
  direction = "row",
  alignItems = "normal",
  alignLastItemToRight = false,
  classNames = []
}: Props) => (
  <div
    className={cn(
      "FlexLayout",
      [
        {
          [`direction-${direction}`]: direction,
          [`justify-content-${justifyContent}`]: justifyContent,
          [`align-items-${alignItems}`]: alignItems,
          [`gap-${gap}`]: gap,
          alignLastItemToRight
        }
      ],
      classNames
    )}
  >
    {children}
  </div>
);

export default FlexLayout;
