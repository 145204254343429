import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  price: React.ReactNode;
  originalPrice?: React.ReactNode;
  badge?: React.ReactNode;
}

const PriceInfo = ({ classNames = [], price, originalPrice, badge }: Props) => {
  return (
    <div className={cn("PriceInfo", [], classNames)}>
      <div className="PriceInfo__price">{price}</div>
      {originalPrice && <div className="PriceInfo__originalPrice">{originalPrice}</div>}
      {badge && <div className="PriceInfo__badge">{badge}</div>}
    </div>
  );
};

export default PriceInfo;
