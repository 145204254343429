import React from "react";
import { get } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import InputField from "../InputField/InputField";
import LabeledField from "../LabeledField/LabeledField";

type Layout = "default" | "input-auto-width" | "full-width";

interface Props extends Component {
  input:
    | React.ReactElement<React.ComponentProps<typeof LabeledField>>
    | React.ReactElement<React.ComponentProps<typeof InputField>>;
  sideComponents: React.ReactNode;
  layout?: Layout;
  hasLabeledInput?: boolean;
}

const SingleInputFormLayout = ({
  classNames = [],
  input,
  sideComponents,
  layout = "default",
  hasLabeledInput = false
}: Props) => {
  const labeledInput = input.type === LabeledField || hasLabeledInput;
  const isSmallInput =
    input.type === LabeledField
      ? get(input, "props.children.props.size") === "s"
      : input.type === InputField
        ? get(input, "props.size") === "s"
        : false;

  return (
    <div
      className={cn(
        "SingleInputFormLayout",
        [{ labeledInput, isSmallInput, [`layout-${layout}`]: layout }],
        classNames
      )}
    >
      <div className="SingleInputFormLayout__input">{input}</div>
      <div className="SingleInputFormLayout__sideComponents">{sideComponents}</div>
    </div>
  );
};

export default SingleInputFormLayout;
