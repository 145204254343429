import { useEffect } from "react";

import actions from "../../../commons/actions";
import useDispatch from "../../../commons/libs/hooks/use-dispatch";
import useInitCore from "../../../commons/libs/hooks/use-init-core";
import useSelector from "../../../commons/libs/hooks/use-selector";

const useInitializeApp = () => {
  useInitCore();

  const dispatch = useDispatch();
  const isInitCoreDone = useSelector(state => state.core.isInitCoreDone);

  const initApp = async () => {
    await dispatch(actions.wawi.fetchConnectionInfo()); // BCD-6822 Replace `state.wawi` with `useWawi`
    dispatch(actions.core.initAppDone());
  };

  useEffect(() => {
    if (isInitCoreDone) {
      initApp();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * To keep this as straightforward as possible,
     * we only want to run `initApp` once the core is initialized.
     **/
  }, [isInitCoreDone]);
};

export default useInitializeApp;
