import { Action } from "redux";
import { Cmd, loop } from "redux-loop";

import { Actions } from "../actions";
import * as authActions from "../actions/auth";
import * as settingsActions from "../actions/settings";
import { hash } from "../libs/externals/hash";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";

import { State } from "./";

const NAME = "auth";

export interface AuthState {
  [NAME]: {
    isDealerAuthorized: boolean;
    personalCode?: string;
  };
}

export const initialState: AuthState = {
  [NAME]: {
    isDealerAuthorized: false
  }
};

type AuthInnerReducer<A extends Action = Actions> = InnerReducer<State, AuthState[typeof NAME], A>;

const handleSettingsReceived: AuthInnerReducer<settingsActions.ReceivedAction> = (state, action) => ({
  ...state[NAME],
  personalCode: action.payload.personalCode
});

const handleAuthorizeDealer: AuthInnerReducer<authActions.AuthorizeDealerAction> = (state, action) =>
  loop(
    state[NAME],
    Cmd.run(
      async (givenCode: string, storedCode?: string) => {
        const hex = await hash(givenCode);
        return !!storedCode && hex === storedCode;
      },
      {
        successActionCreator: authActions.setDealerAuthorized,
        args: [action.payload.personalCode, state[NAME].personalCode]
      }
    )
  );

const handleResetDealerAuthorization: AuthInnerReducer<authActions.ResetDealerAuthorizationAction> = state => ({
  ...state[NAME],
  isDealerAuthorized: false
});

const handleSetDealerAuthorized: AuthInnerReducer<authActions.SetDealerAuthorizedAction> = (state, action) => ({
  ...state[NAME],
  isDealerAuthorized: action.payload.isDealerAuthorized
});

const reducer: InnerReducer<State, AuthState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "SETTINGS.RECEIVED":
      return handleSettingsReceived(state, action);
    case "AUTH.AUTHORIZE_DEALER":
      return handleAuthorizeDealer(state, action);
    case "AUTH.RESET_DEALER_AUTHORIZATION":
      return handleResetDealerAuthorization(state, action);
    case "AUTH.SET_DEALER_AUTHORIZED":
      return handleSetDealerAuthorized(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
