import { useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { useQuery as originalUseQuery, UseQueryOptions as OriginalUseQueryOptions } from "@tanstack/react-query";

export type UseQueryOptions<TQueryFnData = unknown, TError = Error> = OriginalUseQueryOptions<TQueryFnData, TError> & {
  onSuccess?: (data: TQueryFnData) => void;
  onError?: (error: TError) => void;
};

const useQuery = <Data = unknown, Err = Error>(options: UseQueryOptions<Data, Err>) => {
  const query = originalUseQuery<Data, Err>(options);

  useEffect(() => {
    if (query.isSuccess && query.data) {
      options.onSuccess?.(query.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect when the data changes
  }, [query.data]);

  useEffect(() => {
    if (query.isError && query.error) {
      options.onError?.(query.error as Err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect when the error changes
  }, [query.error]);

  return query;
};

// Use throughout the app instead of original `useQuery` from "@tanstack/react-query" module
export default useQuery;
