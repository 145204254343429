import React from "react";
import { replace } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { useLocation } from "react-router";

import { byDefinedKeys } from "../../../../commons/libs/filter-guards";
import { getSpecFormatter } from "../../../../commons/libs/formatters";
import {
  filterSpecGroups,
  getMaxDiscountPercentage,
  getMinOriginalPrice,
  getMinPrice
} from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { Product, ProductKey, ProductSpecKey, ProductType, SpecGroupKeyMap } from "../../../../commons/specs/product";
import * as icons from "../../../../resources/icons";
import Badge from "../../components/Badge/Badge";
import Icon from "../../components/Icon/Icon";
import IconLink from "../../components/IconLink/IconLink";
import ProductTitle from "../../components/ProductTitle/ProductTitle";
import SpecsItem from "../../components/SpecsList/SpecsItem";
import SpecsList from "../../components/SpecsList/SpecsList";
import SpecsListLayout from "../../components/SpecsListLayout/SpecsListLayout";
import TabBarOverlay from "../../components/TabBarOverlay/TabBarOverlay";
import useDispatch from "../../libs/hooks/use-dispatch";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import useOnMount from "../../libs/hooks/use-on-mount";
import { buildPath } from "../../libs/path";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import { ROUTES } from "../../routes";
import { connect } from "../utils/loop";
import { useTrackingContext } from "../utils/tracking-context";

const mapStateToProps = (state: State) => ({
  customization: selectors.selectInitializedSettings(state).customization,
  assortmentPriceSettings: selectors.selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  product: Product;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const SpecificationsOverlay = ({ product, customization, assortmentPriceSettings }: Props) => {
  const { t, i18n } = useTranslation(["commons"]);
  const dispatch = useDispatch();
  const location = useLocation();
  const breakpoints = useMediaQuery();
  const isMobile = !breakpoints.l;
  const { mixpanel } = useTrackingContext();

  useOnMount(() => {
    mixpanel?.trackProductSpecificationsOpened(product);

    return () => {
      mixpanel?.trackProductSpecificationsClosed(product);
    };
  });

  const handleLinkClick = (_event: React.MouseEvent<HTMLElement>, href: string) => {
    dispatch(replace(href, location?.state));
  };

  const productType = product[ProductKey.ProductType];
  const specConfig = getSpecConfig(productType);

  const specFormatter = getSpecFormatter(productType, specConfig.specDefinitions, i18n);

  const filteredSpecGroups = filterSpecGroups(
    specConfig.specGroups,
    customization?.showPrices || customization?.showPricesFeaturesOnly
  );

  const price = customization?.showPrices ? specFormatter.formatProductValue(ProductSpecKey.Price, product) : undefined;

  return (
    <TabBarOverlay
      headerLeft={
        <IconLink
          size={isMobile ? "s" : "base"}
          href={buildPath(ROUTES.PRODUCT_DETAILS.INDEX, { id: product[ProductKey.ProductId] })}
          icon={<Icon source={icons.IconSmallAngleDown} />}
          onClick={handleLinkClick}
          classNames={["u-extended-click-area"]}
        >
          {isMobile
            ? t("commons:specificationsOverlayProductDetails.closeButton")
            : t("commons:specificationsOverlayProductDetails.closeHeadlineButton", {
                headline: t("commons:specificationsOverlayProductDetails.headline")
              })}
        </IconLink>
      }
      headerCenter={
        <ProductTitle
          minFontSize={isMobile ? 16 : undefined}
          maxFontSize={isMobile ? 20 : undefined}
          name={specFormatter.formatProductValue(ProductSpecKey.ModelName, product) ?? ""}
          price={price}
          originalPrice={
            (getMinPrice(product) ?? 0) < (getMinOriginalPrice(product) ?? 0) &&
            getMaxDiscountPercentage(product) &&
            assortmentPriceSettings.showOriginalPrices
              ? specFormatter.formatProductValue(ProductSpecKey.OriginalPrice, product)
              : undefined
          }
          badge={
            getMaxDiscountPercentage(product) ? (
              assortmentPriceSettings.showSaleBadge === "word" ? (
                <Badge label={t("commons:defaults.sale")} size="inherit" />
              ) : assortmentPriceSettings.showSaleBadge === "percentage" ? (
                <Badge
                  label={specFormatter.formatProductValue(ProductSpecKey.DiscountPercentage, product)}
                  size="inherit"
                />
              ) : undefined
            ) : undefined
          }
        />
      }
    >
      <SpecsListLayout>
        {filteredSpecGroups
          .map(specGroup => {
            // Limitation of TypeScript:
            // https://github.com/microsoft/TypeScript/issues/57388
            const tKey = `commons:specs.${productType}.specGroups.${specGroup.key}` as keyof {
              [T in ProductType as `commons:specs.${T}.specGroups.${SpecGroupKeyMap[T]}`]: null;
            };
            return (
              <SpecsList headline={t(tKey)} key={specGroup.key}>
                {specGroup.specKeys
                  .map(specKey => ({
                    specKey,
                    label: specFormatter.formatLabel(specKey),
                    value: specFormatter.formatProductValue(specKey, product)
                  }))
                  .filter(byDefinedKeys("value", "label"))
                  .map(({ specKey, label, value }) => (
                    <SpecsItem key={String(specKey)} label={label}>
                      {value}
                    </SpecsItem>
                  ))}
              </SpecsList>
            );
          })
          .filter(element => element.props.children.length > 0)}
      </SpecsListLayout>
    </TabBarOverlay>
  );
};

export default connector(SpecificationsOverlay);
