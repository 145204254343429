import React from "react";
import { useTranslation } from "react-i18next";

import { CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import { ActiveFilters, FilterConfigItem, FilterKey, ProductFilterValues } from "../../../../commons/specs/filters";
import { ProductType } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import DetailListItem from "../../components/DetailListItem/DetailListItem";
import Tooltip, { TooltipPosition } from "../../components/Tooltip/Tooltip";
import config from "../../config";
import { FilterItemDisabledType, getFilterItemDisabledSettings, isFilterItemActive } from "../../libs/filters";
import usePrevious from "../../libs/hooks/use-previous";

type DetailListItemArgs = React.ComponentProps<typeof DetailListItem>;

type Props<Type extends ProductType> = DetailListItemArgs & {
  filterKey: FilterKey<Type>;
  filterConfig: FilterConfigItem<Type, FilterKey<Type>>;
  filterValues: ProductFilterValues<Type>;
  selectedBrands: Brand[];
  activeFilters: ActiveFilters<Type>;
  filterKeysWithInsufficientFilterValues: FilterKey<Type>[];
  variantsLength: number;
  isOffline: boolean;
  preventTooltip: boolean;
  warningReason?: string;
  disabledReason?: string;
};

const InnerTooltip = ({
  preventTooltip,
  open,
  ...args
}: DetailListItemArgs & {
  preventTooltip: boolean;
  open: () => void;
}) => {
  const prevPreventTooltip = usePrevious(preventTooltip);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!preventTooltip && args.warning) {
      // Await transition duration before showing Tooltip if tooltip was previously prevented
      timeout = setTimeout(open, prevPreventTooltip ? 300 : 0);
    }

    return () => clearTimeout(timeout);
  }, [preventTooltip, args.warning]); // eslint-disable-line react-hooks/exhaustive-deps

  return <DetailListItem {...args} />;
};

const DetailListItemTooltipPartial = <Type extends ProductType>({
  filterKey,
  filterConfig,
  filterValues,
  selectedBrands,
  activeFilters,
  filterKeysWithInsufficientFilterValues,
  variantsLength,
  isOffline,
  preventTooltip,
  warningReason: outerWarningReason,
  disabledReason: outerDisabledReason,
  ...args
}: Props<Type>) => {
  const { t } = useTranslation(["commons"]);

  const innerDisabledReason = React.useMemo(() => {
    const { filterItemDisabledType, disabledConfigs } = getFilterItemDisabledSettings(
      filterKey,
      filterConfig,
      filterValues,
      selectedBrands,
      activeFilters,
      filterKeysWithInsufficientFilterValues,
      isOffline
    );

    if (filterItemDisabledType === FilterItemDisabledType.InsufficientDataQuality) {
      const brands = disabledConfigs
        .filter(({ years }) => years.length)
        .map(({ brand, years }) => `${brand} ${years.join(", ")}`);

      return t("commons:detailListItemTooltipPartialProductFinderOverview.productDataQualityInsufficientTooltip", {
        brands: brands.join(", ")
      });
    } else if (filterItemDisabledType === FilterItemDisabledType.InsufficientFilterOptions) {
      const brands = disabledConfigs.map(({ brand }) => brand);

      return t("commons:detailListItemTooltipPartialProductFinderOverview.filterOptionNotApplicableTooltip", {
        brands: brands.join(", ")
      });
    } else if (filterItemDisabledType === FilterItemDisabledType.Offline) {
      return t("commons:detailListItemTooltipPartialProductFinderOverview.filterOfflineUnavailableTooltip");
    }
  }, [
    filterKey,
    filterConfig,
    filterValues,
    selectedBrands,
    activeFilters,
    filterKeysWithInsufficientFilterValues,
    t,
    isOffline
  ]);

  const innerWarningReason = React.useMemo(() => {
    if (
      filterKey === CustomBicycleFilterKey.Sizing &&
      isFilterItemActive(filterKey, filterConfig, activeFilters) &&
      variantsLength > config.shared.filter.sizingLimit
    ) {
      return t("commons:detailListItemTooltipPartialProductFinderOverview.sizingWarningTooltip");
    }
  }, [t, filterKey, filterConfig, activeFilters, variantsLength]);

  const disabledReason = outerDisabledReason ?? innerDisabledReason;
  const warningReason = outerWarningReason ?? innerWarningReason;
  const tooltipReason = disabledReason ?? warningReason;
  const isTooltipReasonWarning = !disabledReason && !!warningReason;

  return tooltipReason ? (
    <Tooltip
      key={tooltipReason}
      maxWidth="s"
      preferredPosition={TooltipPosition.Right}
      content={tooltipReason}
      isCloseable={isTooltipReasonWarning}
    >
      {({ open }) => (
        <InnerTooltip
          onDisabledClick={open}
          disabled={!!disabledReason}
          warning={isTooltipReasonWarning}
          preventTooltip={preventTooltip}
          open={open}
          {...args}
        />
      )}
    </Tooltip>
  ) : (
    <DetailListItem {...args} />
  );
};

export default DetailListItemTooltipPartial;
