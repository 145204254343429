import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type TextAlign = "left" | "center" | "right";
type VerticalPadding = "default" | "s" | "none";

interface Props extends Component {
  children: React.ReactNode;
  textAlign?: TextAlign;
  verticalPadding?: VerticalPadding;
  icon?: React.ReactNode | null;
}

const TableCell = ({
  classNames = [],
  children,
  icon = null,
  textAlign = "left",
  verticalPadding = "default"
}: Props) => (
  <td
    className={cn(
      "TableCell",
      [
        {
          [`text-align-${textAlign}`]: textAlign,
          [`vertical-padding-${verticalPadding}`]: verticalPadding
        }
      ],
      classNames
    )}
  >
    {icon && <div className="TableCell__icon">{icon}</div>}
    {children}
  </td>
);

export default TableCell;
