import React from "react";
import TextFit from "react-textfit";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  name: string;
  price?: string | React.ReactElement;
  originalPrice?: string | React.ReactElement;
  badge?: React.ReactNode;
  year?: string;
  align?: "left" | "center";
  minFontSize?: number;
  maxFontSize?: number;
  wrap?: boolean;
}

const ProductTitle = ({
  classNames,
  name,
  price = "",
  originalPrice,
  badge,
  year,
  align = "center",
  minFontSize = 20,
  maxFontSize = 32,
  wrap = false
}: Props) => (
  <h1 className={cn("ProductTitle", [{ [`align-${align}`]: align }], classNames)}>
    <TextFit mode={!wrap ? "single" : "multi"} min={minFontSize} max={maxFontSize}>
      <span className="ProductTitle__inner">
        {name}
        {year && (
          <>
            {name && <span className="ProductTitle__icon" />}
            <span className="ProductTitle__year">{year}</span>
          </>
        )}
      </span>
    </TextFit>
    <TextFit mode={!wrap ? "single" : "multi"} min={minFontSize} max={maxFontSize}>
      <span className="ProductTitle__inner">
        {!!price && <span className="ProductTitle__price">{price}</span>}
        {!!originalPrice && <span className="ProductTitle__originalPrice">{originalPrice}</span>}
        {badge && <span className="ProductTitle__badge">{badge}</span>}
      </span>
    </TextFit>
  </h1>
);

export default ProductTitle;
