function hasProp<T extends object, K extends string | number | symbol>(prop: K, obj: T): obj is T & Record<K, unknown> {
  return prop in obj;
}

export default <T extends object, K extends string | number | symbol>(propsToOmit: K[], obj: T): Omit<T, K> => {
  const shallowCopy = { ...obj };

  for (const k of propsToOmit) {
    if (hasProp(k, shallowCopy)) {
      delete shallowCopy[k];
    }
  }

  return shallowCopy;
};
