import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallCheck } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";
import Image from "../Image/Image";

interface Props extends Component {
  imageUrl: string;
  imageWidth?: "s" | "m" | "l";
  imageAspectRatio?: number;
  active?: boolean;
  outline?: boolean;
  badge?: React.ReactElement;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const CheckboxListItemWithImage = ({
  classNames = [],
  imageUrl,
  imageWidth = "m",
  imageAspectRatio = 16 / 9,
  active = false,
  outline = false,
  badge,
  onClick = noop
}: Props) => (
  <button
    type="button"
    onClick={onClick}
    className={cn(
      "CheckboxListItemWithImage",
      [
        {
          active,
          outline,
          [`image-width-${imageWidth}`]: imageWidth
        }
      ],
      classNames
    )}
  >
    <div className="CheckboxListItemWithImage__image">
      <Image src={imageUrl} ratio={imageAspectRatio} position="center" />
    </div>
    {!!badge && <div className="CheckboxListItemWithImage__badge">{badge}</div>}
    <div className="CheckboxListItemWithImage__checkbox">
      <Icon source={IconSmallCheck} />
    </div>
  </button>
);

export default CheckboxListItemWithImage;
