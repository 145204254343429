import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

import SpecsItem from "./SpecsItem";

interface Props extends Component {
  headline: string;
  children: Array<React.ReactElement<React.ComponentProps<typeof SpecsItem>>>;
}

const SpecsList = ({ classNames = [], children, headline }: Props) => (
  <div className={cn("SpecsList", [], classNames)}>
    <h4 className="SpecsList__headline">{headline}</h4>
    {children}
  </div>
);

export default SpecsList;
