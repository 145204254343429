import { RangeFilterValue } from "../specs/filters";

export enum ForkSuspensionTravel {
  None = "none",
  Small = "s",
  Medium = "m",
  Large = "l"
}

export type ForkSuspensionFilter = {
  label: string;
  range: RangeFilterValue;
  travel?: ForkSuspensionTravel;
};
