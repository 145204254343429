import React from "react";
import { push } from "connected-react-router";
import { ConnectedProps } from "react-redux";

import { ErrorModalContentProps, ModalProps } from "../../../../commons/types/modal";
import { IconSmallBin, IconSmallCross } from "../../../../resources/icons";
import { connect } from "../../../commons/container/utils/loop";
import Button from "../../components/Button/Button";
import ErrorBox from "../../components/ErrorBox/ErrorBox";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import Modal from "../../components/Modal/Modal";
import Paragraph from "../../components/Paragraph/Paragraph";
import { clearPrintQueue } from "../../libs/services/print-service/print";
import { ROUTES } from "../../routes";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onPush: push
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps extends ModalProps<ErrorModalContentProps> {
  nodeRef?: React.Ref<HTMLElement>;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const ErrorModalPartial = ({ close, headline, paragraph, type, errorMessage, onPush, nodeRef }: Props) => (
  <Modal nodeRef={nodeRef} closeButton={<IconButton onClick={() => close()} icon={<Icon source={IconSmallCross} />} />}>
    <Headline kind="m">{headline}</Headline>
    <Paragraph>{paragraph}</Paragraph>
    {errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}

    {type === "print" && (
      <FlexLayout gap="l">
        <Button icon={<Icon source={IconSmallBin} />} onClick={() => clearPrintQueue()}>
          Warteschlange leeren
        </Button>
        <Button
          variant="accent"
          kind="solid"
          onClick={() => {
            close();
            onPush(ROUTES.SETTINGS.PRINTER_INDEX);
          }}
        >
          Einstellungen
        </Button>
      </FlexLayout>
    )}

    {type === "mail" && (
      <FlexLayout gap="l">
        <Button
          onClick={() => {
            close();
            onPush(ROUTES.DEVICE_INFORMATION_AND_SUPPORT.INDEX);
          }}
        >
          Support kontaktieren
        </Button>
        <Button
          variant="accent"
          kind="solid"
          onClick={() => {
            close();
            onPush(ROUTES.SETTINGS.MAIL);
          }}
        >
          Einstellungen
        </Button>
      </FlexLayout>
    )}
  </Modal>
);

export default connector(ErrorModalPartial);
