import { postToService } from "../../../../../commons/libs/client-utils";
import { SendPdfMailData } from "../../../../../commons/types/mail-tool";

export const sendEmail = ({ to, from, subject, text, cc, bcc, replyTo, files }: SendPdfMailData, nonce: string) =>
  postToService(
    "mail",
    "/",
    {
      to,
      from,
      subject,
      text,
      cc,
      bcc,
      replyTo,
      files
    },
    {
      requestInit: {
        headers: {
          "X-Nonce": nonce
        }
      }
    }
  );
