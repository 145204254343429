import { browserName, detectOS } from "detect-browser";

import actions from "../../../commons/actions";
import useDispatch from "../../../commons/libs/hooks/use-dispatch";
import useOnMount from "../../../commons/libs/hooks/use-on-mount";
import { supportedOperatingSystems } from "../../libs/browser";

const usePwaInformationModal = () => {
  const dispatch = useDispatch();

  useOnMount(() => {
    const isFullscreen = window.matchMedia("(display-mode: fullscreen)").matches;
    const browser = browserName(navigator.userAgent);
    const operatingSystem = detectOS(navigator.userAgent);

    // can only be in fullscreen mode if PWA is installed
    // this check must be kept in sync with the manifest display setting
    if (
      operatingSystem &&
      !isFullscreen &&
      ((operatingSystem && supportedOperatingSystems.includes(operatingSystem)) || browser === "safari")
    ) {
      dispatch(actions.modal.openPwaInformationModal({ operatingSystem }));
    }
  });
};

export default usePwaInformationModal;
