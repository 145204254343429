import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  icon: React.ReactElement<React.ComponentProps<typeof Icon>>;
  children?: React.ReactNode;
  iconPosition?: "left" | "right";
  kind?: "default" | "positive" | "negative";
}

const IconLabel = ({ classNames = [], icon, children, iconPosition = "left", kind = "default" }: Props) => (
  <div className={cn("IconLabel", [{ [`kind-${kind}`]: kind }], classNames)}>
    {iconPosition === "left" && icon}
    {children}
    {iconPosition === "right" && icon}
  </div>
);

export default IconLabel;
