import React from "react";

import { Component } from "../../../../commons/types/component";
import { IconSource } from "../../../../commons/types/icon";
import { IconMediumPlaceholder } from "../../../../resources/icons";
import cn from "../../libs/class-name";

type Size = "base" | "l" | "xl";

interface Props extends Component {
  source?: IconSource;
  title?: string;
  block?: boolean;
  size?: Size;
  filter?: boolean;
  spin?: boolean;
}

const Icon = ({
  classNames = [],
  source = IconMediumPlaceholder,
  title = "",
  block = false,
  size = "base",
  filter = false,
  spin = false
}: Props) => (
  <svg
    className={cn("Icon", [{ block, [`size-${size}`]: size, filter, spin }], classNames)}
    role={title ? "img" : "presentation"}
    pointerEvents="none"
  >
    <use xlinkHref={`#${source.id}`} />
    {!!title && <title>{title}</title>}
  </svg>
);

export default Icon;
