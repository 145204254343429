import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallAngleRight, IconSmallInputError } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";
import NumberIndicator from "../NumberIndicator/NumberIndicator";

interface Props extends Component {
  label: string;
  description?: string;
  active?: number;
  disabled?: boolean;
  warning?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  onDisabledClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const DetailListItem = ({
  classNames = [],
  label,
  description = "",
  active = 0,
  disabled = false,
  warning = false,
  onClick = noop,
  onDisabledClick = noop
}: Props) => {
  const isActive = active > 0;

  return (
    <button
      type="button"
      className={cn("DetailListItem", [{ active: isActive, disabled, warning }], classNames)}
      onClick={disabled ? onDisabledClick : onClick}
      disabled={disabled && !onDisabledClick}
    >
      <div className="DetailListItem__text">
        <span className="DetailListItem__label">{label}</span>
        {description && <span className="DetailListItem__description">{description}</span>}
      </div>

      <div className="DetailListItem__indicator">
        {warning ? (
          <Icon source={IconSmallInputError} />
        ) : isActive ? (
          <NumberIndicator num={active} />
        ) : (
          <Icon source={IconSmallAngleRight} />
        )}
      </div>
    </button>
  );
};

export default DetailListItem;
