import * as R from "ramda";

import { getFromService, postToService } from "../../../../../commons/libs/client-utils";
import { getProductPrintPath } from "../../../../../commons/libs/resource-paths";
import {
  removeServiceStatusCallback,
  StatusWatcher,
  watchStatusForService
} from "../../../../../commons/libs/service-status-service";
import { ProductPrintData, UrlPrintData } from "../../../../../commons/types/print-mail";
import { PrinterStatus, PrintJobId, PrintJobStatus } from "../../../../../commons/types/print-tool";
import { MailToPrintSettings } from "../../../../../commons/types/settings";

export type PrinterStatusResponse = PrinterStatus[];
export type PrintQueueResponse = PrintJobStatus[];

/**
 * Retrieve printer and job information
 */
export const getPrinterStatus = (): Promise<PrinterStatusResponse> => getFromService("print", "status");

export const getPrintQueue = async (): Promise<PrintQueueResponse> => {
  const printerStatus = await getPrinterStatus();
  return R.chain(({ jobs }) => jobs, printerStatus);
};

const getPrintJobIdFrom =
  <T>(postPrintRequest: (toPrint: T, numCopies?: number, mailToPrintSettings?: MailToPrintSettings) => Promise<any>) =>
  (toPrint: T, numCopies: number = 1, mailToPrintSettings?: MailToPrintSettings): Promise<PrintJobId> =>
    postPrintRequest(toPrint, numCopies, mailToPrintSettings).then((response: { jobId: PrintJobId }) => response.jobId);

/**
 * Print the document given by file:, http:, or https: URL. Will reject
 * if the file is not accessible or no printer is connected and powered
 */
export const printFromUrl = (
  { items: data }: UrlPrintData,
  numCopies: number = 1,
  mailToPrintSettings?: MailToPrintSettings
) =>
  Promise.all(
    data.map(async ({ url }) =>
      getPrintJobIdFrom(await postToService("print", `file?numCopies=${numCopies}`, { url, mailToPrintSettings }))
    )
  );

/**
 * Print a data sheet for the product given by its productId bikeCenterName and showPrices. Will reject if
 * no printer is connected and powered
 */
export const printProductDataSheet = (
  {
    items: data,
    bikeCenterName,
    showPrices,
    currency,
    showOriginalPrices,
    showSaleBadge,
    showAssortmentPrices,
    showFallbackPrices,
    useRrpAsOriginalPrice,
    modelYearDisplayTerm,
    concealModelYear
  }: ProductPrintData,
  numCopies: number = 1,
  mailToPrintSettings?: MailToPrintSettings
) =>
  Promise.all(
    data.map(async ({ productId, advertisement }) =>
      getPrintJobIdFrom(
        await postToService(
          "print",
          getProductPrintPath(
            productId,
            numCopies,
            bikeCenterName,
            showPrices,
            advertisement,
            currency,
            showAssortmentPrices,
            showFallbackPrices,
            showOriginalPrices,
            useRrpAsOriginalPrice,
            showSaleBadge,
            modelYearDisplayTerm,
            concealModelYear
          ),
          {
            mailToPrintSettings
          }
        )
      )
    )
  );

export const cancelCurrentPrintJob = () => postToService("print", "cancel");
export const cancelPrintJob = (jobId: PrintJobId) => postToService("print", `cancel/${jobId}`);

/**
 * Cancel and remove running and queued print jobs on all devices
 */
export const clearPrintQueue = () => postToService("print", "clearAll");

/**
 * Create a pdf from html string and print it. No sanitizing is done
 * to the input.
 */
export const printHtml = getPrintJobIdFrom((html: string, numCopies: number = 1) =>
  postToService("print", `html?numCopies=${numCopies}`, { html })
);

/**
 * Create a pdf from an html string. No sanitizing is done to the
 * input.
 */
export const previewHtml = (html: string) => postToService("print", "html/preview", { html });

/**
 * Delete known printers and try to activate connected/known ones
 */
export const resetPrinters = () => postToService("print", "resetPrinters");

export const subscribeToStatusUpdates = (onUpdate: StatusWatcher) => watchStatusForService("printer-devices", onUpdate);

export const unsubscribeFromStatusUpdates = (onUpdate: StatusWatcher) => removeServiceStatusCallback(onUpdate);
