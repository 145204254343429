import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { PwaInformationModalContentProps } from "../../../../commons/types/modal";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Button from "../../../commons/components/Button/Button";
import FlexLayout from "../../../commons/components/FlexLayout/FlexLayout";
import Headline from "../../../commons/components/Headline/Headline";
import Icon from "../../../commons/components/Icon/Icon";
import IconButton from "../../../commons/components/IconButton/IconButton";
import IconLabel from "../../../commons/components/IconLabel/IconLabel";
import InstructionCard from "../../../commons/components/InstructionCard/InstructionCard";
import Modal from "../../../commons/components/Modal/Modal";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";
import Text from "../../../commons/components/Text/Text";
import { connect } from "../../../commons/container/utils/loop";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  close: actions.modal.close
};

const connector = connect(mapStateToProps, mapDispatchToProps);

enum ModalStep {
  AdvantageList = "advantageList",
  InstallationInfo = "installationInfo"
}

interface InstallationStep {
  instruction: React.ReactNode;
  footer: React.ReactNode;
}

interface InstallationConfig {
  headline: string;
  steps: InstallationStep[];
}

interface OuterProps extends PwaInformationModalContentProps {
  nodeRef?: React.Ref<HTMLElement>;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const PwaInformationModalPartial = ({ close, operatingSystem, nodeRef = null }: Props) => {
  const { t } = useTranslation(["mobile"]);
  const [modalStep, setModalStep] = React.useState(ModalStep.AdvantageList);

  const getInstallationConfig = (): InstallationConfig => {
    switch (operatingSystem) {
      case "iOS":
      case "Mac OS": // macos, because iPads have the same user agent as a mac
        return {
          headline: t("mobile:pwaInformationModalPartialApp.macOs.installationStepsHeadline"),
          steps: [
            {
              instruction: (
                <Paragraph size="s">
                  <Trans
                    t={t}
                    i18nKey="mobile:pwaInformationModalPartialApp.macOs.selectSymbolInBrowser"
                    components={{ bold: <strong /> }}
                  />
                </Paragraph>
              ),
              footer: <Icon source={icons.IconSmallIosShare} />
            },
            {
              instruction: (
                <Paragraph size="s">
                  <Trans
                    t={t}
                    i18nKey="mobile:pwaInformationModalPartialApp.macOs.addToHomeScreen"
                    components={{ bold: <strong /> }}
                  />
                </Paragraph>
              ),
              footer: <Icon source={icons.IconSmallIosAdd} />
            },
            {
              instruction: (
                <Paragraph size="s">
                  <Trans
                    t={t}
                    i18nKey="mobile:pwaInformationModalPartialApp.macOs.selectAdd"
                    components={{ bold: <strong /> }}
                  />
                </Paragraph>
              ),
              footer: (
                <Paragraph>
                  <strong>{t("mobile:pwaInformationModalPartialApp.macOs.add")}</strong>
                </Paragraph>
              )
            }
          ]
        };
      case "Android OS":
        return {
          headline: t("mobile:pwaInformationModalPartialApp.androidOs.installationStepsHeadline"),
          steps: [
            {
              instruction: (
                <Paragraph size="s">
                  <Trans
                    t={t}
                    i18nKey="mobile:pwaInformationModalPartialApp.androidOs.selectSymbolInBrowser"
                    components={{ bold: <strong /> }}
                  />
                </Paragraph>
              ),
              footer: <Icon source={icons.IconSmallAndroidBrowserMenu} />
            },
            {
              instruction: (
                <Paragraph size="s">
                  <Trans
                    t={t}
                    i18nKey="mobile:pwaInformationModalPartialApp.androidOs.addToHomeScreen"
                    components={{ bold: <strong /> }}
                  />
                </Paragraph>
              ),
              footer: <Icon source={icons.IconSmallPlus} />
            },
            {
              instruction: (
                <Paragraph size="s">
                  <Trans
                    t={t}
                    i18nKey="mobile:pwaInformationModalPartialApp.androidOs.selectAdd"
                    components={{ bold: <strong /> }}
                  />
                </Paragraph>
              ),
              footer: (
                <Paragraph>
                  <strong>{t("mobile:pwaInformationModalPartialApp.androidOs.add")}</strong>
                </Paragraph>
              )
            }
          ]
        };
      default:
        return {
          headline: t("mobile:pwaInformationModalPartialApp.otherOs.installationStepsHeadline"),
          steps: [
            {
              instruction: (
                <Paragraph size="s">
                  {t("mobile:pwaInformationModalPartialApp.otherOs.unknownBrowserAndOrOs")}
                </Paragraph>
              ),
              footer: <Icon source={icons.IconSmallAttention} />
            }
          ]
        };
    }
  };

  const renderModalFooter = () => (
    <FlexLayout gap="l" justifyContent="center">
      <Button
        icon={<Icon source={icons.IconSmallArrowRight} />}
        iconRight
        onClick={() => setModalStep(ModalStep.InstallationInfo)}
      >
        {t("mobile:pwaInformationModalPartialApp.howTheInstallationWorksButton")}
      </Button>
    </FlexLayout>
  );

  const renderAdvantage = (text: string, index: number) => (
    <IconLabel
      key={index}
      kind="positive"
      classNames={["u-space-top-s"]}
      icon={<Icon source={icons.IconSmallCheck}></Icon>}
    >
      {text}
    </IconLabel>
  );

  const renderAdvantageListStep = (): React.JSX.Element => {
    const advantages = [
      t("mobile:pwaInformationModalPartialApp.advantagesLabels.separated"),
      t("mobile:pwaInformationModalPartialApp.advantagesLabels.notRelevantBrowserFunctionsHidden"),
      t("mobile:pwaInformationModalPartialApp.advantagesLabels.fasterLoadingTime")
    ];

    return (
      <Text>
        <Headline>
          <Trans
            t={t}
            i18nKey="mobile:pwaInformationModalPartialApp.installOnHomeScreen"
            components={{ linebreak: <br /> }}
          />
        </Headline>

        <Paragraph classNames={["u-space-top-l"]}>
          <strong>{t("mobile:pwaInformationModalPartialApp.givesFollowingAdvantages")}</strong>
        </Paragraph>

        {advantages.map(renderAdvantage)}
      </Text>
    );
  };

  const renderStep = ({ footer, instruction }: InstallationStep, index: number): React.JSX.Element => (
    <InstructionCard
      key={index}
      headline={t("mobile:pwaInformationModalPartialApp.steps", { count: index + 1 })}
      footer={footer}
    >
      {instruction}
    </InstructionCard>
  );

  const renderInstallationInfoStep = (): React.JSX.Element => {
    const { headline, steps } = getInstallationConfig();

    return (
      <Text>
        <Headline>{headline}</Headline>

        <FlexLayout justifyContent="space-between" gap="base">
          {steps.map(renderStep)}
        </FlexLayout>
      </Text>
    );
  };

  return (
    <Modal
      nodeRef={nodeRef}
      closeButton={<IconButton onClick={close} icon={<Icon source={icons.IconSmallCross} />} />}
      footer={modalStep === ModalStep.AdvantageList && renderModalFooter()}
    >
      {modalStep === ModalStep.AdvantageList ? renderAdvantageListStep() : renderInstallationInfoStep()}
    </Modal>
  );
};

export default connector(PwaInformationModalPartial);
