import React from "react";
import { FormikProps, FormikValues } from "formik";

interface Props<V> {
  formik: FormikProps<V>;
  children: React.ReactNode;
}

const FormPartial = <V extends FormikValues>({ formik, children }: Props<V>) => (
  // eslint-disable-next-line react/forbid-elements
  <form
    onSubmit={event => {
      event.preventDefault();

      if (!formik.isSubmitting) {
        formik.handleSubmit();
      }
    }}
  >
    {children}
  </form>
);

export default FormPartial;
