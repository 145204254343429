import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import { getSpecFormatter } from "../../../../commons/libs/formatters";
import { getSpecConfig } from "../../../../commons/specs";
import { Product, ProductKey, ProductSpecKey } from "../../../../commons/specs/product";
import ProductTitle from "../../components/ProductTitle/ProductTitle";
import useSelector from "../../libs/hooks/use-selector";
import { selectInitializedSettings } from "../../libs/selectors";

import { OriginalPricePartial, PricePartial, SaleBadgePartial } from "./PricePartials";

type ProductTitleProps = ComponentProps<typeof ProductTitle>;

interface Props {
  product?: Product;
  name?: string;
  align?: ProductTitleProps["align"];
  wrap?: ProductTitleProps["wrap"];
  minFontSize?: ProductTitleProps["minFontSize"];
  maxFontSize?: ProductTitleProps["maxFontSize"];
}

const ProductTitlePartial = ({
  product = undefined,
  name: customName,
  align = "center",
  wrap = false,
  minFontSize = 20,
  maxFontSize = 32
}: Props) => {
  const { i18n } = useTranslation(["commons"]);

  const settings = useSelector(selectInitializedSettings);

  if (!product) {
    return null;
  }

  const productType = product[ProductKey.ProductType];
  const specConfig = getSpecConfig(productType);
  const specFormatter = getSpecFormatter(productType, specConfig.specDefinitions, i18n, settings.customization);

  const name = customName ?? specFormatter.formatProductValue(ProductSpecKey.ModelName, product) ?? "";
  const year = specFormatter.formatProductValue(ProductSpecKey.ModelYear, product);

  return (
    <ProductTitle
      name={name}
      year={year}
      price={<PricePartial productOrVariant={product} />}
      originalPrice={<OriginalPricePartial productOrVariant={product} />}
      badge={<SaleBadgePartial productOrVariant={product} />}
      align={align}
      wrap={wrap}
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
    />
  );
};

export default ProductTitlePartial;
