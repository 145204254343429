import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Badge from "../Badge/Badge";
import Headline from "../Headline/Headline";

interface Props extends Component {
  children: React.ReactElement<React.ComponentProps<typeof Headline>>;
  topline?: string | React.ReactElement<React.ComponentProps<typeof Badge>>;
}

const TextHeader = ({ classNames = [], children, topline }: Props) => (
  <div className={cn("TextHeader", [], classNames)}>
    {topline && <div className="TextHeader__topline">{topline}</div>}
    {children}
  </div>
);

export default TextHeader;
