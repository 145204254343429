import React from "react";
import * as Sentry from "@sentry/browser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConnectedRouter, push, replace } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { ensureError } from "../../commons/libs/error";
import envelope from "../../commons/libs/externals/envelope";
import { getClientVersion } from "../../commons/libs/externals/versions";
import { Locale } from "../../commons/types/localization";
import * as translations from "../../resources/translations/mobile-resources.json";
import actions from "../commons/actions";
import config from "../commons/config";
import { TrackingProvider } from "../commons/container/utils/tracking-context";
import { changeLanguage, initI18n } from "../commons/libs/externals/i18n";
import initSentry from "../commons/libs/externals/sentry";

import App from "./container/App/App";
import CredentialsNotice from "./container/CredentialsNotice/CredentialsNotice";
import MaxLicensesReachedNotice from "./container/MaxLicensesReachedNotice/MaxLicensesReachedNotice";
import { setLicenseCookies } from "./libs/cookies";
import {
  AuthenticationCredentials,
  getQueriedAuthenticationCredentials,
  getQueriedRegistrationCredentials,
  getStoredAuthenticationCredentials,
  getStoredRegistrationCredentials,
  hasExistingAuthenticationCredentials,
  hasNewRegistrationCredentials,
  isAuthenticated,
  isTryingToRegister,
  registerLicense,
  RegistrationCredentials,
  reloadApp,
  removeRegistrationCredentials,
  storeAuthenticationCredentials,
  storeRegistrationCredentials
} from "./libs/credentials";
import initMixpanel from "./libs/externals/mixpanel";
import { generateManifest, injectManifest } from "./manifest";
import createRootReducer from "./store";

enum AuthenticationState {
  Authenticated,
  Registering,
  RegistrationFailed,
  InsufficientCredentials
}

const authenticate = async (
  queriedRegistrationCredentials: Partial<RegistrationCredentials>,
  storedRegistrationCredentials: Partial<RegistrationCredentials>,
  queriedAuthenticationCredentials: Partial<AuthenticationCredentials>,
  storedAuthenticationCredentials: Partial<AuthenticationCredentials>
): Promise<AuthenticationState> => {
  if (hasNewRegistrationCredentials(queriedRegistrationCredentials)) {
    storeRegistrationCredentials(queriedRegistrationCredentials);
    reloadApp();

    return AuthenticationState.Registering;
  } else if (isTryingToRegister(storedRegistrationCredentials)) {
    try {
      const license = await registerLicense(storedRegistrationCredentials);

      removeRegistrationCredentials();
      storeAuthenticationCredentials({
        licenseId: String(license.id),
        licenseToken: license.licenseToken,
        serviceTag: storedRegistrationCredentials.serviceTag
      });
      reloadApp();

      return AuthenticationState.Registering;
    } catch (error) {
      const ensuredError = ensureError(error);
      if ("status" in ensuredError && ensuredError.status === 402) {
        return AuthenticationState.RegistrationFailed;
      } else {
        return AuthenticationState.InsufficientCredentials;
      }
    }
  } else if (hasExistingAuthenticationCredentials(queriedAuthenticationCredentials)) {
    storeAuthenticationCredentials(queriedAuthenticationCredentials);
    reloadApp();

    return AuthenticationState.Registering;
  } else if (isAuthenticated(storedAuthenticationCredentials)) {
    return AuthenticationState.Authenticated;
  } else {
    return AuthenticationState.InsufficientCredentials;
  }
};

const renderRoot = async (): Promise<React.JSX.Element | null> => {
  if (envelope.isProductionBuild) {
    const queriedRegistrationCredentials = getQueriedRegistrationCredentials();
    const storedRegistrationCredentials = getStoredRegistrationCredentials();

    const queriedAuthenticationCredentials = getQueriedAuthenticationCredentials();
    const storedAuthenticationCredentials = getStoredAuthenticationCredentials();

    const authenticationState = await authenticate(
      queriedRegistrationCredentials,
      storedRegistrationCredentials,
      queriedAuthenticationCredentials,
      storedAuthenticationCredentials
    );

    if (authenticationState !== AuthenticationState.Authenticated) {
      initI18n(translations, config.shared.i18n);
      changeLanguage(navigator.language as Locale);
      document.documentElement.setAttribute("lang", navigator.language);
    }

    switch (authenticationState) {
      case AuthenticationState.Registering:
        // display a blank page as long as the registration process is going on
        return null;
      case AuthenticationState.InsufficientCredentials:
        return <CredentialsNotice />;
      case AuthenticationState.RegistrationFailed:
        return <MaxLicensesReachedNotice />;
    }

    if (isAuthenticated(storedAuthenticationCredentials)) {
      setLicenseCookies(storedAuthenticationCredentials);
    }

    const { serviceTag, licenseId, licenseToken } = storedAuthenticationCredentials;
    const query = `?serviceTag=${serviceTag}&licenseId=${licenseId}&licenseToken=${licenseToken}`;
    injectManifest(generateManifest(query));
  } else {
    injectManifest(generateManifest());
  }

  const sentry = initSentry(Sentry);
  const mixpanel = initMixpanel({
    appVersion: getClientVersion(),
    sentry,
    config: config.mobile.mixpanel
  });

  // Sentry
  sentry.initSentry({
    release: getClientVersion(),
    environment: envelope.isProductionTracking ? "production" : "development",
    ...config.mobile.sentry
  });
  sentry.configureServiceTagAsUsername();

  // Mixpanel
  mixpanel.init(envelope.isProductionTracking ? config.mobile.mixpanel.token.prod : config.mobile.mixpanel.token.dev);

  initI18n(translations, config.shared.i18n, sentry);

  const history = createBrowserHistory();
  const store = createRootReducer(history, mixpanel.trackingMiddleware);
  const queryClient = new QueryClient();

  if (!envelope.isProductionBuild) {
    // Expose store and action creators to be able to dispatch actions in console
    window.dev = {
      store,
      actions: {
        router: {
          push,
          replace
        },
        ...actions
      }
    };
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <TrackingProvider sentry={sentry} mixpanel={mixpanel}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
          </TrackingProvider>
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>
  );
};

export async function renderApp() {
  const container = document.getElementById("app");
  //eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Root container is defined in index.html
  const root = createRoot(container!);
  root.render(await renderRoot());
}
