import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallInputClear, IconSmallInputError } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";
import NumberIndicator from "../NumberIndicator/NumberIndicator";

interface Props extends Component {
  label: string;
  description?: string;
  active?: number;
  disabled?: boolean;
  warning?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  onDisabledClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  onRemove?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const FilterListItem = React.forwardRef(
  (
    {
      classNames = [],
      label,
      description = "",
      active = 0,
      disabled = false,
      warning = false,
      onClick = noop,
      onDisabledClick = noop,
      onRemove = noop
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const isActive = active > 0;

    return (
      <div className={cn("FilterListItem", [{ active: isActive, disabled, warning }], classNames)} ref={ref}>
        <button
          type="button"
          className="FilterListItem__filter"
          onClick={disabled ? onDisabledClick : onClick}
          disabled={disabled && !onDisabledClick}
        >
          <span className="FilterListItem__number">
            {warning ? <Icon source={IconSmallInputError} /> : isActive ? <NumberIndicator num={active} /> : null}
          </span>

          <div className="FilterListItem__text">
            <span className="FilterListItem__label">{label}</span>
            {description && <span className="FilterListItem__description">{description}</span>}
          </div>
        </button>

        {isActive && (
          <button type="button" className="FilterListItem__remove-button" onClick={onRemove}>
            <Icon source={IconSmallInputClear} />
          </button>
        )}
      </div>
    );
  }
);
FilterListItem.displayName = "FilterListItem";

export default FilterListItem;
