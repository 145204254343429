import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Button from "../Button/Button";
import ItemList from "../ItemList/ItemList";
import LabeledToggleField from "../LabeledToggleField/LabeledToggleField";

interface Props extends Component {
  children?: React.ReactElement<React.ComponentProps<typeof ItemList>>;
  header?: React.ReactElement<React.ComponentProps<typeof LabeledToggleField>>;
  footer?: React.ReactElement<React.ComponentProps<typeof Button>>;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

const ProductFilterList = ({ classNames = [], children, header, footer, scrollRef }: Props) => (
  <div className={cn("ProductFilterList", [], classNames)}>
    {header && <div className="ProductFilterList__header">{header}</div>}
    {children && (
      <div className="ProductFilterList__children" ref={scrollRef}>
        {children}
      </div>
    )}
    {footer && <div className="ProductFilterList__footer">{footer}</div>}
  </div>
);

export default ProductFilterList;
