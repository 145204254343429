import React from "react";
import { goBack, replace } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { Route, RouteChildrenProps, useLocation } from "react-router-dom";

import { ProductId } from "../../../../commons/specs/product";
import { Currency } from "../../../../commons/types/currency";
import { GlobalLocationState } from "../../../../commons/types/location";
import { PrintMailData, PrintType } from "../../../../commons/types/print-mail";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Icon from "../../../commons/components/Icon/Icon";
import IconLink from "../../../commons/components/IconLink/IconLink";
import TabBar from "../../../commons/components/TabBar/TabBar";
import TabBarLink from "../../../commons/components/TabBar/TabBarLink";
import TabBarOverlayTransition from "../../../commons/components/TabBarOverlay/TabBarOverlayTransition";
import ProductDetailsPartial, {
  ProductDetailsPartialRenderProps
} from "../../../commons/container/ProductDetails/ProductDetailsPartial";
import SpecificationsOverlay from "../../../commons/container/ProductDetails/SpecificationsOverlay";
import { connect } from "../../../commons/container/utils/loop";
import getBikeCenterName from "../../../commons/libs/bike-center-name";
import usePrintOrMail from "../../../commons/libs/hooks/use-print-or-mail";
import { buildPath } from "../../../commons/libs/path";
import { decodeIdFromMatch } from "../../../commons/libs/products";
import { ROUTES } from "../../../commons/routes";
import { selectAssortmentPriceSettings, selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";

import AvailabilityOverlay from "./AvailabilityOverlay";

const mapStateToProps = (state: State) => ({
  customization: selectInitializedSettings(state).customization,
  currency: selectInitializedSettings(state).currency,
  hasEnteredThroughDeepLink: state.mobileApp?.hasEnteredThroughDeepLink,
  assortmentPriceSettings: selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = {
  onReplace: replace,
  onGoBack: goBack,
  setHasEnteredThroughDeepLink: actions.mobileApp?.setHasEnteredThroughDeepLink
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ProductDetails = ({
  customization,
  currency,
  onReplace,
  hasEnteredThroughDeepLink,
  setHasEnteredThroughDeepLink,
  onGoBack,
  assortmentPriceSettings
}: Props) => {
  const { t } = useTranslation(["mobile"]);
  const location = useLocation<GlobalLocationState>();

  const { printOrMail, isPrintingActive, isMailActive } = usePrintOrMail();

  const handleLinkClickWithReplace = (
    _event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
    href: string
  ) => {
    onReplace(href, location.state);
  };

  const handlePrintOrMail = ({
    event,
    href,
    data
  }: {
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>;
    href: string;
    data: PrintMailData;
  }): void => {
    handleLinkClickWithReplace(event, href);
    printOrMail(data);
  };

  const buildPathWithProductId = (productId: ProductId, path: string) =>
    buildPath(path, { id: decodeIdFromMatch(productId) });

  const renderBackButton = () => {
    const onClick = () => {
      // To prevent a mobile user from accidentally exiting the app when he enters through a deep link,
      // we redirect in place (don't push), to the main page to start a new history
      if (hasEnteredThroughDeepLink) {
        // We reset the hasEnteredThroughDeepLink-state to prevent a loop
        setHasEnteredThroughDeepLink(false);
        onReplace(ROUTES.NAVIGATION.INDEX);
      } else {
        onGoBack();
      }
    };

    const label = hasEnteredThroughDeepLink
      ? t("mobile:productDetails.backToMainMenuButton")
      : (location.state?.backLabel ?? t("mobile:productDetails.backToProductFinderButton"));

    return (
      <IconLink onClick={onClick} icon={<Icon source={icons.IconSmallArrowLeft} />} size="l">
        {label}
      </IconLink>
    );
  };

  const renderTabBar = ({ productId, product, productInitializingFirstTime }: ProductDetailsPartialRenderProps) => {
    return (
      <TabBar>
        <Route
          exact
          path={buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.SPECIFICATIONS)}
          children={({ match }: RouteChildrenProps) => (
            <TabBarLink
              fadeIn={!productInitializingFirstTime}
              active={!!match}
              variant={match ? "primary" : "standout"}
              href={
                match
                  ? buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.INDEX)
                  : buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.SPECIFICATIONS)
              }
              label={!productInitializingFirstTime ? t("mobile:productDetails.specificationsButton") : ""}
              onClick={handleLinkClickWithReplace}
            />
          )}
        />
        {product && (isPrintingActive || isMailActive) && (
          <TabBarLink
            fadeIn={!productInitializingFirstTime}
            label={
              !productInitializingFirstTime
                ? isPrintingActive && !isMailActive
                  ? t("mobile:productDetails.printButton")
                  : t("mobile:productDetails.shareDataSheetButton")
                : ""
            }
            onClick={event =>
              handlePrintOrMail({
                event,
                href: buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.INDEX),
                data: {
                  type: PrintType.ProductDataSheet,
                  bikeCenterName: getBikeCenterName(customization),
                  showPrices: customization.showPrices,
                  showAssortmentPrices: assortmentPriceSettings.showAssortmentPrices,
                  showFallbackPrices: assortmentPriceSettings.showFallbackPrices,
                  showOriginalPrices: assortmentPriceSettings.showOriginalPrices,
                  useRrpAsOriginalPrice: assortmentPriceSettings.useRrpAsOriginalPrice,
                  showSaleBadge: assortmentPriceSettings.showSaleBadge,
                  currency: currency ?? Currency.Eur,
                  items: [
                    {
                      productId,
                      product
                    }
                  ],
                  initiator: "ProductDetails"
                }
              })
            }
          />
        )}
        <Route
          exact
          path={buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.AVAILABILITY)}
          children={({ match }: RouteChildrenProps) => (
            <TabBarLink
              active={!!match}
              variant={match ? "primary" : "standout"}
              fadeIn={!productInitializingFirstTime}
              href={
                match
                  ? buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.INDEX)
                  : buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.AVAILABILITY)
              }
              label={
                !productInitializingFirstTime
                  ? t("mobile:productDetails.variantsAvailabilityButton")
                  : t("mobile:productDetails.variantsButton")
              }
              onClick={handleLinkClickWithReplace}
            />
          )}
        />
      </TabBar>
    );
  };

  const renderOverlay = ({ productId, variantId, product, brand }: ProductDetailsPartialRenderProps) => {
    if (!product || !brand) {
      return null;
    }

    return (
      <>
        <Route
          exact
          path={buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.SPECIFICATIONS)}
          children={({ match }: RouteChildrenProps) => (
            <TabBarOverlayTransition open={!!match}>
              <SpecificationsOverlay product={product} />
            </TabBarOverlayTransition>
          )}
        />
        <Route
          exact
          path={buildPathWithProductId(productId, ROUTES.PRODUCT_DETAILS.AVAILABILITY)}
          children={({ match }: RouteChildrenProps) => (
            <TabBarOverlayTransition open={!!match}>
              <AvailabilityOverlay product={product} brand={brand} variantId={variantId} />
            </TabBarOverlayTransition>
          )}
        />
      </>
    );
  };

  return <ProductDetailsPartial backButton={renderBackButton} tabBar={renderTabBar} overlay={renderOverlay} />;
};

export default connector(ProductDetails);
