import { compact, flatMap, groupBy, uniq } from "lodash";
import * as R from "ramda";

import { ActiveFilters, FilterKey, FilterValue } from "../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../commons/specs/product";
import { Brand, BrandGroup, BrandsContentContext, BrandsGridConfig } from "../../../commons/types/brand";

import { getActiveFilterValues } from "./filters";

export const groupBrandsByFirstCharacter = <Type extends ProductType = ProductType>(
  givenBrands: Brand<Type>[]
): BrandGroup<Type>[] => {
  const groupedBrands = groupBy(givenBrands, (brand: Brand<Type>) => R.toUpper(brand.displayName.charAt(0)));
  return Object.keys(groupedBrands)
    .sort()
    .reduce((acc: BrandGroup<Type>[], char) => [...acc, { char, brands: groupedBrands[char] }], []);
};

export const getActiveModelYears = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  (brand.modelYears || brand.availableModelYears || []).sort((a, b) => b - a);

export const getLatestActiveModelYearForBrands = <Type extends ProductType = ProductType>(
  brands: Brand<Type>[]
): number[] => uniq(compact(flatMap(brands, brand => hasActiveModelYear(brand) && getLatestActiveModelYear(brand))));

export const hasActiveModelYear = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  getActiveModelYears(brand).length > 0;

export const hasMultipleActiveModelYears = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  getActiveModelYears(brand).length > 1;

export const getLatestActiveModelYear = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  getActiveModelYears(brand)[0];

export const getPreviousActiveModelYear = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  getActiveModelYears(brand)[1];

export const getLatestAvailableModelYear = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  brand.availableModelYears.sort((a, b) => b - a)[0];

export const hasNewAndUnconfiguredModelYear = <Type extends ProductType = ProductType>(brand: Brand<Type>) => {
  const latestAvailableModelYear = getLatestAvailableModelYear(brand);

  const inactiveModelYears = brand.inactiveModelYears || [];
  const activeModelYears = brand.modelYears || [];

  return !inactiveModelYears.includes(latestAvailableModelYear) && !activeModelYears.includes(latestAvailableModelYear);
};

export const isLatestAvailableModelYearActive = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  getLatestActiveModelYear(brand) === getLatestAvailableModelYear(brand);

export const isModelYearConfigurable = <Type extends ProductType = ProductType>(brand: Brand<Type>) =>
  hasNewAndUnconfiguredModelYear(brand) && !isLatestAvailableModelYearActive(brand);

export const areNewAndUnconfiguredModelYearsInBrands = <Type extends ProductType = ProductType>(
  brands: Brand<Type>[] | undefined
): boolean => (brands ?? []).some(isModelYearConfigurable);

export const shouldBeFlippableTile = <Type extends ProductType = ProductType>(
  brand: Brand<Type>,
  showsAllModelYears: boolean
) => hasMultipleActiveModelYears(brand) && !isModelYearConfigurable(brand) && !showsAllModelYears;

export const generateBrandsGridConfig = ({
  brandCount,
  brandsKind = "default",
  shouldReduceColumnCount = false
}: BrandsContentContext): BrandsGridConfig => {
  const columnCount = getBrandsContentGridColumnCount({
    brandCount,
    shouldReduceColumnCount,
    brandsKind
  });
  const withShadow = (brandCount ?? 0) === 0 ? false : brandCount % columnCount === 0;

  return {
    columnCount,
    withShadow
  };
};

export const getBrandsContentGridColumnCount = ({
  brandCount,
  shouldReduceColumnCount,
  brandsKind
}: BrandsContentContext): BrandsGridConfig["columnCount"] => {
  if (brandsKind === "highlighted") {
    switch (brandCount) {
      case 0:
      case 1:
        return 1;

      case 2:
        return shouldReduceColumnCount ? 1 : 2;

      default:
        return shouldReduceColumnCount ? 1 : 3;
    }
  } else if (brandsKind === "nonHighlighted") {
    switch (brandCount) {
      case 0:
      case 1:
        return 1;

      case 2:
        return 2;

      case 3:
        return shouldReduceColumnCount ? 2 : 3;

      default:
        return shouldReduceColumnCount ? 2 : 4;
    }
  } else {
    switch (brandCount) {
      case 0:
      case 1:
        return 1;

      case 2:
        return 2;

      case 3:
        return shouldReduceColumnCount ? 2 : 3;

      case 4:
        return 2;

      case 5:
      case 6:
        return shouldReduceColumnCount ? 2 : 3;

      default:
        return shouldReduceColumnCount ? 2 : 4;
    }
  }
};

export const getFilteredModelYearsForBrand = <Type extends ProductType>(
  brand: Brand<Type>,
  activeFilters: ActiveFilters<Type>
): number[] => {
  const activeModelYears: number[] = getActiveModelYears(brand);
  const modelYearFilter = getActiveFilterValues(activeFilters, ProductSpecKey.ModelYear);

  const filteredModelYears: number[] =
    (modelYearFilter?.length ?? 0) > 0 ? (modelYearFilter as number[]) : [getLatestActiveModelYear(brand)];

  return R.intersection(filteredModelYears, activeModelYears);
};

export const getFilterKeysWithInsufficientFilterValues = <Type extends ProductType>(
  selectedBrands: Brand<Type>[],
  activeFilters: ActiveFilters<Type>
): FilterKey<Type>[] => {
  return uniq(
    flatMap(selectedBrands, (brand: Brand<Type>) => {
      const filteredModelYearsForBrand = getFilteredModelYearsForBrand(brand, activeFilters);

      return flatMap(
        filteredModelYearsForBrand,
        (selectedModelYear: number) => brand.insufficientFilterValues?.[selectedModelYear] ?? []
      );
    })
  );
};

export const getSelectedBrands = <Type extends ProductType>(
  activeBrands: Brand<Type>[],
  activeFilters: ActiveFilters<Type>
): Brand<Type>[] => {
  const activeBrandKeyFilterValues = getActiveFilterValues(activeFilters, ProductSpecKey.BrandKey);

  return activeBrandKeyFilterValues && activeBrandKeyFilterValues.length > 0
    ? activeBrands.filter(brand => {
        const brandKey = brand.key as FilterValue<Type, ProductSpecKey.BrandKey>;
        return activeBrandKeyFilterValues.includes(brandKey);
      })
    : activeBrands;
};

export const isSingleActiveModelYear = <Type extends ProductType>(modelYear: number, brand: Brand<Type>): boolean =>
  brand.availableModelYears.includes(modelYear) &&
  brand?.modelYears?.length === 1 &&
  brand.modelYears.includes(modelYear);
