import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { KeyboardType } from "../../../../commons/types/keyboard";
import {
  IconSmallEyeClosed,
  IconSmallEyeOpen,
  IconSmallInputClear,
  IconSmallInputError
} from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

type Size = "default" | "s";

// Note: This input element has a different interface than reacts native element
type ChangeHandler = (value: string) => void;

interface Props extends Component {
  type?: "text" | "password" | "search";
  value?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  icon?: React.ReactElement<React.ComponentProps<typeof Icon>>;
  inputRef?: React.RefObject<HTMLInputElement>;
  labelShowPassword?: string;
  keyboardType?: KeyboardType;
  maxLength?: number;
  monospace?: boolean;
  presentation?: boolean;
  size?: Size;
  suffix?: string;
  withoutClearButton?: boolean;
  withoutErrorButton?: boolean;
  onClearClick?: () => void;
  onChange?: ChangeHandler;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const InputField = ({
  classNames = [],
  type = "text",
  value = "",
  name = "",
  placeholder = "",
  disabled = false,
  error = false,
  icon,
  inputRef: inputRefProp,
  keyboardType = KeyboardType.Letters,
  labelShowPassword = "",
  maxLength,
  monospace = false,
  presentation = false,
  size = "default",
  suffix = "",
  withoutClearButton = false,
  withoutErrorButton = false,
  onClearClick = () => onChange(""),
  onChange = noop,
  onBlur = noop,
  onFocus = noop
}: Props) => {
  const [passwordVisible, setPasswordVisibility] = React.useState(false);
  const inputRef = inputRefProp ?? React.createRef<HTMLInputElement>();

  const password = type === "password";
  const hasValue = Boolean(value);
  const withButton = hasValue || error || password;
  const isPassword = password && !passwordVisible;

  const handleClearClick = () => {
    onClearClick();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value);
  };

  const handleInteractionStart = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    // Keep focus and prevent blur, but don't focus the input element if it wasn't focused before.
    if (inputRef.current === document.activeElement) {
      if (event.cancelable) {
        event.preventDefault(); // This is required in order to prevent the `onBlur` of the input element.
      }

      inputRef.current?.focus();
    }
  };

  const blur = (event: React.FocusEvent<HTMLInputElement>) => {
    // TODO: as soon as there is time we have to find a special solution
    // to hide the password onBlur without closing the keyboard
    // setPasswordInvisible();
    onBlur(event);
  };

  const setPasswordVisible = () => setPasswordVisibility(true);
  const setPasswordInvisible = () => setPasswordVisibility(false);

  return (
    <div
      className={cn(
        "InputField",
        [
          {
            error,
            password,
            disabled,
            presentation,
            withButton,
            hasValue,
            icon,
            [`size-${size}`]: size,
            withoutErrorButton,
            monospace
          }
        ],
        classNames
      )}
      data-keyboard-input
    >
      <input
        data-keyboard-type={keyboardType}
        name={name}
        value={value}
        onChange={handleChange}
        // To detect the onscreen keyboard changes we need to listen to onKeyDown in all inputs
        onKeyDown={handleChange}
        disabled={disabled || presentation}
        type={isPassword ? "password" : "text"}
        className="InputField__input"
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={blur}
        ref={inputRef}
        maxLength={maxLength}
      />
      {!!suffix && <div className="InputField__suffix">{suffix}</div>}
      {icon && <div className="InputField__icon">{icon}</div>}
      {passwordVisible ? (
        <button
          className="InputField__togglePasswordButton"
          onClick={setPasswordInvisible}
          onMouseDown={handleInteractionStart}
          onTouchStart={handleInteractionStart}
          type="button"
        >
          <Icon source={IconSmallEyeClosed} />
        </button>
      ) : (
        <button
          className="InputField__togglePasswordButton"
          onClick={setPasswordVisible}
          onMouseDown={handleInteractionStart}
          onTouchStart={handleInteractionStart}
          type="button"
        >
          {!!labelShowPassword && <div className="InputField__buttonLabel">{labelShowPassword}</div>}
          <Icon source={IconSmallEyeOpen} />
        </button>
      )}
      {!withoutClearButton && (
        <button
          className="InputField__clearButton"
          onMouseDown={handleInteractionStart}
          onTouchStart={handleInteractionStart}
          onClick={handleClearClick}
          type="button"
        >
          <Icon source={IconSmallInputClear} />
        </button>
      )}
      {!withoutErrorButton && (
        <button
          className="InputField__errorButton"
          onMouseDown={handleInteractionStart}
          onTouchStart={handleInteractionStart}
          onClick={handleClearClick}
          type="button"
        >
          <Icon source={IconSmallInputError} />
        </button>
      )}
    </div>
  );
};

export default InputField;
