import React from "react";
import { go, goBack, replace } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";

import { GlobalLocationState } from "../../../../commons/types/location";
import { ProductFinderOverviewInitialTab } from "../../../../commons/types/settings";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Icon from "../../../commons/components/Icon/Icon";
import IconLink from "../../../commons/components/IconLink/IconLink";
import CategoriesContent from "../../../commons/container/ProductFinderOverview/CategoriesContent";
import DetailsContent from "../../../commons/container/ProductFinderOverview/DetailsContent";
import ProductFinderOverviewPartial, {
  RouteDefinition
} from "../../../commons/container/ProductFinderOverview/ProductFinderOverviewPartial";
import SearchContent from "../../../commons/container/ProductFinderOverview/SearchContent";
import { connect } from "../../../commons/container/utils/loop";
import { ROUTES } from "../../../commons/routes";
import type { State } from "../../reducers";

import BrandsContent from "./BrandsContent";

const mapStateToProps = (state: State) => ({
  hasEnteredThroughDeepLink: state.mobileApp.hasEnteredThroughDeepLink
});

const mapDispatchToProps = {
  onReplace: replace,
  onGoBack: goBack,
  onGo: go,
  setHasEnteredThroughDeepLink: actions.mobileApp.setHasEnteredThroughDeepLink
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ProductFinderOverview = ({
  onGo,
  onGoBack,
  onReplace,
  hasEnteredThroughDeepLink,
  setHasEnteredThroughDeepLink
}: Props) => {
  const { t } = useTranslation(["mobile"]);
  const location = useLocation<GlobalLocationState>();

  const productFinderOverviewRoutes: RouteDefinition[] = [
    {
      path: ROUTES.PRODUCT_FINDER_OVERVIEW.BRANDS,
      component: BrandsContent,
      title: t("mobile:productFinderOverview.brands"),
      value: ProductFinderOverviewInitialTab.Brands
    },
    {
      path: ROUTES.PRODUCT_FINDER_OVERVIEW.CATEGORIES,
      component: CategoriesContent,
      title: t("mobile:productFinderOverview.categories"),
      value: ProductFinderOverviewInitialTab.Categories
    },
    {
      path: ROUTES.PRODUCT_FINDER_OVERVIEW.DETAILS,
      component: DetailsContent,
      title: t("mobile:productFinderOverview.details"),
      value: ProductFinderOverviewInitialTab.Details,
      props: {
        isOnline: true
      }
    },
    {
      path: ROUTES.PRODUCT_FINDER_OVERVIEW.SEARCH,
      component: SearchContent,
      title: t("mobile:productFinderOverview.search"),
      value: ProductFinderOverviewInitialTab.Search
    }
  ];

  const renderBackButton = () => {
    const onClick = () => {
      // To prevent a mobile user from accidentally exiting the app when he enters through a deep link,
      // we redirect in place (don't push), to the main page to start a new history
      if (hasEnteredThroughDeepLink) {
        // We reset the hasEnteredThroughDeepLink-state to prevent a loop
        setHasEnteredThroughDeepLink(false);
        onReplace(ROUTES.NAVIGATION.INDEX);
      } else {
        if (location.state?.goBackToDetails) {
          onGo(-2);
        } else {
          onGoBack();
        }
      }
    };

    const label = hasEnteredThroughDeepLink
      ? t("mobile:productFinderOverview.backToMainMenuButton")
      : t("mobile:productFinderOverview.backButton");

    return (
      <IconLink onClick={onClick} icon={<Icon source={icons.IconSmallArrowLeft} />} size="l">
        {label}
      </IconLink>
    );
  };

  return (
    <ProductFinderOverviewPartial
      isOffline={false}
      backButton={renderBackButton()}
      productFinderOverviewRoutes={productFinderOverviewRoutes}
    />
  );
};

export default connector(ProductFinderOverview);
