import React, { useCallback } from "react";
import { QueryClient, QueryKey, QueryObserverOptions } from "@tanstack/react-query";
import { useLocation } from "react-router";

import { compactPromiseErrors as promiseSequence } from "../../../../commons/libs/promise";
import { getSpecConfig } from "../../../../commons/specs";
import {
  ActiveFilters,
  FilterConfigItem,
  FilterKey,
  getTypedFilterConfigKeys,
  ProductFilterValues
} from "../../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { Currency } from "../../../../commons/types/currency";
import { GlobalLocationState } from "../../../../commons/types/location";
import {
  AssortmentPriceSettings,
  AutomaticAssortmentFilter,
  ProductFinderAssortmentFilterSettings
} from "../../../../commons/types/settings";
import * as sessionActions from "../../actions/session";
import { getPossibleValues as getPossibleValuesLib } from "../content-service";
import {
  clearActiveFilter,
  clearActiveFilters,
  clearAllActiveFilters,
  getEvaluatedDefaultActiveFilters,
  hasActiveFilters,
  isFilterItemActive as isFilterItemActiveLib
} from "../filters";
import useDispatch from "../hooks/use-dispatch";
import useGetFilterKeysWithInsufficientFilterValues from "../hooks/use-get-filter-keys-with-insufficient-filter-values";
import useQuery, { UseQueryOptions } from "../hooks/use-query";
import useSelectedBrands from "../hooks/use-selected-brands";
import useSelector from "../hooks/use-selector";
import { useActiveBrands } from "../queries/use-active-brands";
import { selectInitializedSettings } from "../selectors";

interface Arguments<Type extends ProductType> {
  options?: Omit<UseQueryOptions<ProductFilterValues<Type>>, "queryKey" | "queryFn">;
  productType: Type;
  assortmentFilterSettings: ProductFinderAssortmentFilterSettings;
  assortmentPriceSettings: AssortmentPriceSettings;
  automaticAssortmentFilter?: AutomaticAssortmentFilter;
  brandKey?: string;
  useSessionState?: boolean;
  shouldSetDefaultActiveFilters?: boolean;
}

const useProductFilter = <Type extends ProductType>({
  productType,
  assortmentFilterSettings,
  assortmentPriceSettings,
  automaticAssortmentFilter,
  brandKey = "",
  useSessionState = true,
  shouldSetDefaultActiveFilters = false,
  options = {}
}: Arguments<Type>) => {
  const specConfig = getSpecConfig(productType);
  const filterKeys = getTypedFilterConfigKeys(specConfig);

  const [activeFilters, setActiveFilters] = useSessionOrLocalState<Type>(useSessionState);
  const [wereDefaultActiveFiltersEvaluated, setWereDefaultActiveFiltersEvaluated] = React.useState<boolean>(false);
  const currency = useSelector(state => selectInitializedSettings(state).currency);
  const assortmentSettings = useSelector(state => selectInitializedSettings(state).assortment);
  const { data: activeBrands } = useActiveBrands();
  const { selectedBrands } = useSelectedBrands({ activeBrands, activeFilters, brandKey });

  const query = useQuery({
    ...getDefaultFilterValuesQueryOptions<Type>(),
    ...options,
    ...getDefaultFilterValuesQueryOptions<Type>(),
    queryKey: queryKey({ brandKey, currency, assortmentPriceSettings, filterKeys }),
    queryFn: () => getPossibleValues({ brandKey, currency, filterKeys, assortmentPriceSettings }),
    onSuccess: data => {
      options?.onSuccess?.(data);
      if (!wereDefaultActiveFiltersEvaluated) {
        if (activeFilters !== null && !shouldSetDefaultActiveFilters) {
          setWereDefaultActiveFiltersEvaluated(true);
        } else {
          const defaultActiveFilters = getEvaluatedDefaultActiveFilters<Type>({
            assortmentType: assortmentSettings.type,
            assortmentFilterSettings,
            automaticAssortmentFilter,
            activeFilters,
            productFilterValues: data
          });

          setActiveFilters(defaultActiveFilters);

          setWereDefaultActiveFiltersEvaluated(true);
        }
      }
    }
  });

  const filterKeysWithInsufficientFilterValues = useGetFilterKeysWithInsufficientFilterValues({
    selectedBrands,
    activeFilters
  });

  const clearAllFilters = useCallback(() => {
    setActiveFilters(clearAllActiveFilters(activeFilters, assortmentSettings.productFinderFilterSettings));
  }, [activeFilters, setActiveFilters, assortmentSettings.productFinderFilterSettings]);

  const clearFilters = useCallback(
    (filterKeys: FilterKey<Type>[]) => {
      setActiveFilters(clearActiveFilters(activeFilters, filterKeys));
    },
    [activeFilters, setActiveFilters]
  );

  const clearFilter = useCallback(
    (filterKey: FilterKey<Type>) => {
      setActiveFilters(clearActiveFilter(activeFilters, filterKey));
    },
    [activeFilters, setActiveFilters]
  );

  const isFilterItemActive = useCallback(
    (key: FilterKey<Type>, filterConfigItem: FilterConfigItem<Type, FilterKey<Type>>) =>
      isFilterItemActiveLib(key, filterConfigItem, activeFilters),
    [activeFilters]
  );

  return {
    ...query,
    activeFilters,
    selectedBrands,
    filterKeysWithInsufficientFilterValues,
    wereDefaultActiveFiltersEvaluated,
    hasActiveFilters: hasActiveFilters(activeFilters),
    setActiveFilters,
    clearAllFilters,
    clearFilters,
    clearFilter,
    isFilterItemActive
  };
};

const useSessionOrLocalState = <Type extends ProductType>(useSessionState: boolean) => {
  const location = useLocation<GlobalLocationState<Type>>();
  // Disable syncing the active filters with the session state
  const localState = React.useState<ActiveFilters<Type>>(location.state?.activeFilters ?? null);

  const dispatch = useDispatch();
  const activeFilters = useSelector(state => state.session.activeFilters as ActiveFilters<Type>);
  const setActiveFilters = React.useCallback(
    (activeFilters: ActiveFilters<Type>) => dispatch(sessionActions.setActiveFilters(activeFilters)),
    [dispatch]
  );

  if (!useSessionState) {
    return localState;
  }

  return [activeFilters, setActiveFilters] as const;
};

const queryKey = <Type extends ProductType>({
  currency,
  assortmentPriceSettings,
  filterKeys,
  brandKey
}: {
  currency: Currency;
  assortmentPriceSettings: AssortmentPriceSettings;
  filterKeys: FilterKey<Type>[];
  brandKey?: string;
}): QueryKey => ["product-filter", ...(!!brandKey ? [brandKey] : []), filterKeys, currency, assortmentPriceSettings];

const getDefaultFilterValuesQueryOptions = <Type extends ProductType>(): Omit<
  QueryObserverOptions<ProductFilterValues<Type>>,
  "queryKey"
> => ({
  /* To prevent loosing the cache during longer passive periods like the customer world,
   * we keep the cache during the entire lifetime of the app,
   * while still fetchting updates when possible, since we leave the stale time at 0 (default).
   */
  gcTime: Infinity
});

const getPossibleValues = <Type extends ProductType>({
  currency,
  assortmentPriceSettings,
  filterKeys,
  brandKey
}: {
  currency: Currency;
  assortmentPriceSettings: AssortmentPriceSettings;
  filterKeys: FilterKey<Type>[];
  brandKey?: string;
}): Promise<ProductFilterValues<Type>> => {
  const filters = brandKey ? [{ key: ProductSpecKey.BrandKey, value: brandKey }] : [];
  return getPossibleValuesLib(filterKeys, currency, assortmentPriceSettings, filters);
};

export const prefetchFilterValues = async <Type extends ProductType>({
  queryClient,
  currency,
  assortmentPriceSettings,
  filterKeys,
  brandKey
}: {
  queryClient: QueryClient;
  currency: Currency;
  assortmentPriceSettings: AssortmentPriceSettings;
  filterKeys: FilterKey<Type>[];
  brandKey?: string;
}) => {
  await queryClient.prefetchQuery({
    ...getDefaultFilterValuesQueryOptions<Type>(),
    queryKey: queryKey({ brandKey, currency, assortmentPriceSettings, filterKeys }),
    queryFn: () => getPossibleValues({ brandKey, currency, assortmentPriceSettings, filterKeys })
  });
};

export const prefetchAllFilterValues = async <Type extends ProductType>({
  queryClient,
  currency,
  assortmentPriceSettings,
  filterKeys,
  activeBrandKeys
}: {
  queryClient: QueryClient;
  currency: Currency;
  assortmentPriceSettings: AssortmentPriceSettings;
  filterKeys: FilterKey<Type>[];
  activeBrandKeys: string[];
}) => {
  const sharedArgs = { queryClient, currency, assortmentPriceSettings, filterKeys };

  const generalFilterValues = prefetchFilterValues({
    ...sharedArgs
  });

  const brandFilterValues = activeBrandKeys.map(brandKey =>
    prefetchFilterValues({
      ...sharedArgs,
      brandKey
    })
  );

  await promiseSequence([generalFilterValues, ...brandFilterValues]);
};
export default useProductFilter;
