import moment from "moment";
import * as R from "ramda";

export const parseToNumber = (value: string) => {
  if (R.either(R.isNil, R.isEmpty)(value)) {
    return NaN;
  }

  const str = String(value).trim();
  const firstDot = str.indexOf(".");
  const firstComma = str.indexOf(",");

  const decimalSeparator = firstDot < firstComma ? "," : ".";

  const cleaned = (firstDot < firstComma ? str.replace(/\./g, "") : str.replace(/,/g, "")).replace(
    decimalSeparator,
    "."
  );

  const parsed = Number(cleaned);
  return R.isEmpty(cleaned) || Number.isNaN(parsed) ? NaN : parsed;
};

export const parseDate = (input: string, format: string | string[]): moment.Moment | undefined => {
  const parsed = moment(input, format, true);
  return parsed.isValid() ? parsed : undefined;
};
