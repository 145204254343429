import React from "react";

import * as toastActions from "../../actions/toasts";
import { ActionsToastProperties, ToastProperties } from "../../actions/toasts";
import Button from "../../components/Button/Button";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Icon from "../../components/Icon/Icon";
import Toast from "../../components/Toast/Toast";
import useDispatch from "../../libs/hooks/use-dispatch";
import useOnMount from "../../libs/hooks/use-on-mount";

interface Props {
  toast: ToastProperties;
}

const ToastItemPartial = ({ toast }: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toastActions.hideToast(toast.id));
    toast.onClose?.();
  };

  useOnMount(() => {
    let timeoutRef: NodeJS.Timeout;

    if (toast.timeout) {
      timeoutRef = setTimeout(() => {
        handleClose();
      }, toast.timeout * 1000);
    }

    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  });

  const dispatchActionCallback = (action?: ActionsToastProperties) => {
    handleClose();

    const actionCallback = action?.callback();
    if (actionCallback) {
      dispatch(actionCallback);
    }
  };

  const renderToastActions = (toast: ToastProperties) => {
    if (toast.primaryAction && !toast.secondaryAction) {
      return (
        <Button size="s" onClick={() => dispatchActionCallback(toast.primaryAction)}>
          {toast.primaryAction.label}
        </Button>
      );
    } else if (toast.primaryAction && toast.secondaryAction) {
      return (
        <FlexLayout gap="s">
          <Button size="s" onClick={() => dispatchActionCallback(toast.primaryAction)}>
            {toast.primaryAction.label}
          </Button>
          <Button size="s" onClick={() => dispatchActionCallback(toast.secondaryAction)}>
            {toast.secondaryAction.label}
          </Button>
        </FlexLayout>
      );
    } else {
      return undefined;
    }
  };

  return (
    <Toast
      id={toast.id}
      title={toast.title}
      description={toast.description}
      icon={toast.icon && <Icon source={toast.icon} />}
      kind={toast.kind}
      timeout={toast.timeout}
      permanent={toast.permanent}
      shouldInteractionLeaveKeyboardOpen={toast.shouldInteractionLeaveKeyboardOpen}
      onClose={handleClose}
      action={renderToastActions(toast)}
    />
  );
};

export default ToastItemPartial;
