import { WallpaperId } from "../../../commons/config/customer-groups";
import abstract0Thumb from "../../../resources/images/backgrounds/abstract-0-thumb.jpg";
import abstract0 from "../../../resources/images/backgrounds/abstract-0.jpg";
import abstract2Thumb from "../../../resources/images/backgrounds/abstract-2-thumb.jpg";
import abstract2 from "../../../resources/images/backgrounds/abstract-2.jpg";
import landscape0Thumb from "../../../resources/images/backgrounds/landscape-0-thumb.jpg";
import landscape0 from "../../../resources/images/backgrounds/landscape-0.jpg";
import landscape1Thumb from "../../../resources/images/backgrounds/landscape-1-thumb.jpg";
import landscape1 from "../../../resources/images/backgrounds/landscape-1.jpg";
import lifestyle0Thumb from "../../../resources/images/backgrounds/lifestyle-0-thumb.jpg";
import lifestyle0 from "../../../resources/images/backgrounds/lifestyle-0.jpg";
import lifestyle1Thumb from "../../../resources/images/backgrounds/lifestyle-1-thumb.jpg";
import lifestyle1 from "../../../resources/images/backgrounds/lifestyle-1.jpg";
import lifestyle2Thumb from "../../../resources/images/backgrounds/lifestyle-2-thumb.jpg";
import lifestyle2 from "../../../resources/images/backgrounds/lifestyle-2.jpg";
import lifestyle3Thumb from "../../../resources/images/backgrounds/lifestyle-3-thumb.jpg";
import lifestyle3 from "../../../resources/images/backgrounds/lifestyle-3.jpg";
import lifestyle4Thumb from "../../../resources/images/backgrounds/lifestyle-4-thumb.jpg";
import lifestyle4 from "../../../resources/images/backgrounds/lifestyle-4.jpg";
import lifestyle5Thumb from "../../../resources/images/backgrounds/lifestyle-5-thumb.jpg";
import lifestyle5 from "../../../resources/images/backgrounds/lifestyle-5.jpg";
import lifestyle6Thumb from "../../../resources/images/backgrounds/lifestyle-6-thumb.jpg";
import lifestyle6 from "../../../resources/images/backgrounds/lifestyle-6.jpg";
import lifestyle7Thumb from "../../../resources/images/backgrounds/lifestyle-7-thumb.jpg";
import lifestyle7 from "../../../resources/images/backgrounds/lifestyle-7.jpg";
import mountain0Thumb from "../../../resources/images/backgrounds/mountain-0-thumb.jpg";
import mountain0 from "../../../resources/images/backgrounds/mountain-0.jpg";
import mountain1Thumb from "../../../resources/images/backgrounds/mountain-1-thumb.jpg";
import mountain1 from "../../../resources/images/backgrounds/mountain-1.jpg";
import mountain2Thumb from "../../../resources/images/backgrounds/mountain-2-thumb.jpg";
import mountain2 from "../../../resources/images/backgrounds/mountain-2.jpg";
import mountain3Thumb from "../../../resources/images/backgrounds/mountain-3-thumb.jpg";
import mountain3 from "../../../resources/images/backgrounds/mountain-3.jpg";
import mountain4Thumb from "../../../resources/images/backgrounds/mountain-4-thumb.jpg";
import mountain4 from "../../../resources/images/backgrounds/mountain-4.jpg";
import mountain5Thumb from "../../../resources/images/backgrounds/mountain-5-thumb.jpg";
import mountain5 from "../../../resources/images/backgrounds/mountain-5.jpg";
import mountain6Thumb from "../../../resources/images/backgrounds/mountain-6-thumb.jpg";
import mountain6 from "../../../resources/images/backgrounds/mountain-6.jpg";
import road0Thumb from "../../../resources/images/backgrounds/road-0-thumb.jpg";
import road0 from "../../../resources/images/backgrounds/road-0.jpg";
import road1Thumb from "../../../resources/images/backgrounds/road-1-thumb.jpg";
import road1 from "../../../resources/images/backgrounds/road-1.jpg";
import road2Thumb from "../../../resources/images/backgrounds/road-2-thumb.jpg";
import road2 from "../../../resources/images/backgrounds/road-2.jpg";
import technical0Thumb from "../../../resources/images/backgrounds/technical-0-thumb.jpg";
import technical0 from "../../../resources/images/backgrounds/technical-0.jpg";

export type WallpaperVariant = "primary" | "standout";

export type WallpaperType = "abstract" | "landscapes" | "lifestyle" | "mountains" | "road" | "technical";

export interface WallpaperConfig {
  id: WallpaperId;
  file: string;
  thumbFile: string;
  associatedVariant: WallpaperVariant;
  type: WallpaperType;
  /** @see [TileGrid: Shadow verschiebt die Kacheln vertical : BCD-6603](https://dcd.myjetbrains.com/youtrack/issue/BCD-6603/TileGrid-Shadow-verschiebt-die-Kacheln-vertical) */
  shouldHideTileGridShadow: boolean;
  shouldMainNavigationTilesBeTransparent: boolean;
  shouldHideMainNavigationHeaderBackground: boolean;
}

const wallpapers: WallpaperConfig[] = [
  {
    id: WallpaperId.Abstract0,
    file: abstract0,
    thumbFile: abstract0Thumb,
    associatedVariant: "primary",
    type: "abstract",
    shouldHideTileGridShadow: false,
    shouldMainNavigationTilesBeTransparent: false,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Abstract2,
    file: abstract2,
    thumbFile: abstract2Thumb,
    associatedVariant: "primary",
    type: "abstract",
    shouldHideTileGridShadow: false,
    shouldMainNavigationTilesBeTransparent: false,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Landscape0,
    file: landscape0,
    thumbFile: landscape0Thumb,
    associatedVariant: "standout",
    type: "landscapes",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Landscape1,
    file: landscape1,
    thumbFile: landscape1Thumb,
    associatedVariant: "standout",
    type: "landscapes",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle0,
    file: lifestyle0,
    thumbFile: lifestyle0Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle1,
    file: lifestyle1,
    thumbFile: lifestyle1Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle2,
    file: lifestyle2,
    thumbFile: lifestyle2Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle3,
    file: lifestyle3,
    thumbFile: lifestyle3Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle4,
    file: lifestyle4,
    thumbFile: lifestyle4Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle5,
    file: lifestyle5,
    thumbFile: lifestyle5Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle6,
    file: lifestyle6,
    thumbFile: lifestyle6Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Lifestyle7,
    file: lifestyle7,
    thumbFile: lifestyle7Thumb,
    associatedVariant: "standout",
    type: "lifestyle",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Mountain0,
    file: mountain0,
    thumbFile: mountain0Thumb,
    associatedVariant: "standout",
    type: "mountains",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Mountain1,
    file: mountain1,
    thumbFile: mountain1Thumb,
    associatedVariant: "standout",
    type: "mountains",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Mountain2,
    file: mountain2,
    thumbFile: mountain2Thumb,
    associatedVariant: "standout",
    type: "mountains",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Mountain3,
    file: mountain3,
    thumbFile: mountain3Thumb,
    associatedVariant: "standout",
    type: "mountains",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Mountain4,
    file: mountain4,
    thumbFile: mountain4Thumb,
    associatedVariant: "standout",
    type: "mountains",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Mountain5,
    file: mountain5,
    thumbFile: mountain5Thumb,
    associatedVariant: "standout",
    type: "mountains",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Mountain6,
    file: mountain6,
    thumbFile: mountain6Thumb,
    associatedVariant: "standout",
    type: "mountains",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Road0,
    file: road0,
    thumbFile: road0Thumb,
    associatedVariant: "standout",
    type: "road",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Road1,
    file: road1,
    thumbFile: road1Thumb,
    associatedVariant: "standout",
    type: "road",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Road2,
    file: road2,
    thumbFile: road2Thumb,
    associatedVariant: "standout",
    type: "road",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  },
  {
    id: WallpaperId.Technical0,
    file: technical0,
    thumbFile: technical0Thumb,
    associatedVariant: "standout",
    type: "technical",
    shouldHideTileGridShadow: true,
    shouldMainNavigationTilesBeTransparent: true,
    shouldHideMainNavigationHeaderBackground: true
  }
];

export default wallpapers;
