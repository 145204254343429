import WebSocket from "ws";

export enum StatusCode {
  Ready = "ready",
  Busy = "busy",
  NonExistant = "non-existant",
  Unknown = "unknown",
  Error = "error",
  Success = "success",
  AllContentDownloaded = "all-content-downloaded",
  AllContentInstalled = "all-content-installed",
  PrintingStarted = "printing-started",
  PrintingFinished = "printing-finished", // only from the system's perspective!
  PowerButton = "power-button"
}

export interface StatusProgress {
  status: StatusCode;
  progress: number;
  package: string;
  received?: number;
  total?: number;
  value?: string;
}

export interface Message {
  service?: string;
  status?: StatusCode | StatusProgress;
  text: string;
  time?: string;
}

export interface ErrorMessage extends Partial<Message> {
  error: string;
}

export interface ProgressMessage extends Message {
  status: StatusProgress;
}

export interface HeartbeatWebSocket extends WebSocket {
  isAlive?: boolean;
}

export interface IntercomServer extends WebSocket.Server {
  clients: Set<HeartbeatWebSocket>;
  broadcast(data: string): void;
}

export interface IntercomTool {
  setServer: (server: IntercomServer) => void;

  broadcastStatus: (service: string, message: Message | string) => Message;

  /**
   * Only broadcast the status if no important process blocks the service
   * E.g. when calling a status-reading process while a long-running writer is active
   */
  broadcastStatusWeak: (service: string, message: Message | string) => Message;

  requestStatus: (service: string) => Message;

  registerService: (service: string) => void;
  getKnownServices: () => Map<StatusCode, string[]>;
  port: number;
}
