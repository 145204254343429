import type { URL } from "url";
import type * as NodeFormData from "form-data";
import { merge } from "lodash";
import nodeFetch from "node-fetch";

import envelope from "../../commons/libs/externals/envelope";
import { ErrorMessage } from "../types/intercom";

import { ensureError } from "./error";

type FormData = globalThis.FormData | NodeFormData;

const fetch =
  typeof window !== "undefined" && typeof window.fetch === "function"
    ? window.fetch
    : (nodeFetch as unknown as typeof window.fetch);

export type UrlParams = Array<{ param: string; value: string | number | boolean }>;

export const serviceBaseUrl: string = envelope.serviceUrl;

const replaceDoubleSlash = (str: string) => str.replace(/\/\/+/g, "/");
const trimSlashes = (str: string) => str.replace(/^\//, "").replace(/\/$/, "");

export const pathToEndpoint = (service: string, path: string, baseUrl = serviceBaseUrl) => {
  return [baseUrl, ...[service, path].map(replaceDoubleSlash)].map(trimSlashes).join("/");
};

export const buildServiceUrl = (service: string, path: string, params: UrlParams = [], baseUrl?: string): URL => {
  const url = new global.URL(pathToEndpoint(service, path, baseUrl));
  (params || []).forEach(({ param, value }) => url.searchParams.append(param, String(value)));
  return url;
};

export const buildStaticFileUrl = (path: string) => buildServiceUrl("static", path);

export const buildMobileServiceBaseUrl = (host: string, serviceTag: string) =>
  `${host}/api/${serviceTag.toLowerCase()}`;

export const prependServiceBaseUrl = (path: string) => {
  return [serviceBaseUrl, replaceDoubleSlash(path)].map(trimSlashes).join("/");
};

export interface ServiceClient {
  get: ServiceFetchFunction<UrlParams, any>;
  post: ServiceFetchFunction<any, any>;
  postForm: ServiceFetchFunction<FormData, any>;
  put: ServiceFetchFunction<any, any>;
  patch: ServiceFetchFunction<any, any>;
  delete: ServiceFetchFunction<any, any>;
}

interface BuildServiceClientOptions {
  serviceUrl?: string;
  requestInit: RequestInit;
}

type ServiceFetchFunction<DT = any, RT = any> = (
  service: string,
  path: string,
  data?: DT,
  buildOptions?: BuildServiceClientOptions
) => Promise<RT>;

export class ServiceClientHttpError extends Error {
  constructor(
    public message: string,
    public body: string | ErrorMessage | Error,
    public status?: number,
    public stack?: string
  ) {
    super(message, body && body instanceof Error ? { cause: body } : undefined);

    this.name = "ServiceClientHttpError";
  }

  get isServiceError() {
    return !!this.body && !(this.body instanceof Error) && typeof this.body === "object" && "error" in this.body;
  }

  get error(): string {
    return typeof this.body === "string" ? this.body : this.body instanceof Error ? this.body.message : this.body.error;
  }
}

const rejectNonSuccess =
  <DT, RT>(fn: ServiceFetchFunction<DT, Response>): ServiceFetchFunction<DT, RT | null> =>
  async (service: string, path: string, data?: DT, buildOptions?: BuildServiceClientOptions): Promise<RT | null> => {
    try {
      const response: Response = await fn(service, path, data, buildOptions);
      // consider 2xx status as success
      if (!response.ok) {
        const body: string | any = await response
          .clone()
          .json() // 1) try to parse response as json
          .catch(() => response.clone().text()) // 2) ... if that fails try to return as text
          .catch(() => null); // 3) if that also doesn't work return null

        const { status, statusText } = response;
        const message = `Service request to ${service} -> ${pathToEndpoint(
          service,
          path
        )} returned status ${status} ${statusText}`;

        const serviceClientHttpError = new ServiceClientHttpError(message, body, status);

        return Promise.reject(serviceClientHttpError);
      } else if (response.status === 204) {
        // no content
        return null;
      } else {
        return response.json() as Promise<RT>;
      }
    } catch (error) {
      const message = `Service request to ${service} -> ${pathToEndpoint(service, path)} failed.`;

      const serviceClientHttpError = new ServiceClientHttpError(message, ensureError(error));
      return Promise.reject(serviceClientHttpError);
    }
  };

function fetchRequest(
  service: string,
  path: string,
  params?: UrlParams,
  buildOptions: Partial<BuildServiceClientOptions> = {}
): Promise<Response> {
  const url = buildServiceUrl(service, path, params, buildOptions.serviceUrl).toString();
  return fetch(url, buildOptions.requestInit);
}

function dataRequest(
  service: string,
  path: string,
  method: string,
  data: any,
  buildOptions: Partial<BuildServiceClientOptions> = {}
): Promise<Response> {
  const url = pathToEndpoint(service, path, buildOptions.serviceUrl);
  return fetch(
    url,
    merge(buildOptions.requestInit, {
      method,
      body: JSON.stringify(data),
      headers: { "Content-type": "application/json" }
    })
  );
}

function formRequest(
  service: string,
  path: string,
  method: string,
  data?: FormData,
  buildOptions: Partial<BuildServiceClientOptions> = {}
): Promise<Response> {
  const url = pathToEndpoint(service, path, buildOptions.serviceUrl);
  return fetch(
    url,
    merge(buildOptions.requestInit, {
      method,
      body: data ? data : undefined
    })
  );
}

export const getFromService: ServiceFetchFunction<UrlParams> = rejectNonSuccess(
  (service: string, path: string, params?: UrlParams, buildOptions?: BuildServiceClientOptions) => {
    return fetchRequest(service, path, params, buildOptions);
  }
);

export const postToService: ServiceFetchFunction = rejectNonSuccess(
  (service: string, path: string, data?: any, buildOptions?: BuildServiceClientOptions) =>
    dataRequest(service, path, "post", data, buildOptions)
);

export const postFormToService: ServiceFetchFunction<FormData> = rejectNonSuccess(
  (service: string, path: string, formData?: FormData, buildOptions?: BuildServiceClientOptions) =>
    formRequest(service, path, "post", formData, buildOptions)
);

export const putToService: ServiceFetchFunction = rejectNonSuccess(
  (service: string, path: string, data?: any, buildOptions?: BuildServiceClientOptions) =>
    dataRequest(service, path, "put", data, buildOptions)
);

export const patchAtService: ServiceFetchFunction = rejectNonSuccess(
  (service: string, path: string, data?: any, buildOptions?: BuildServiceClientOptions) =>
    dataRequest(service, path, "patch", data, buildOptions)
);

export const deleteFromService: ServiceFetchFunction = rejectNonSuccess(
  (service: string, path: string, data?: any, buildOptions?: BuildServiceClientOptions) =>
    dataRequest(service, path, "delete", data, buildOptions)
);

export const buildServiceClient = (buildOptions: BuildServiceClientOptions): ServiceClient => ({
  get: (service, path, data) => getFromService(service, path, data, buildOptions),
  post: (service, path, data) => postToService(service, path, data, buildOptions),
  postForm: (service, path, data) => postFormToService(service, path, data, buildOptions),
  put: (service, path, data) => putToService(service, path, data, buildOptions),
  patch: (service, path, data) => patchAtService(service, path, data, buildOptions),
  delete: (service, path, data) => deleteFromService(service, path, data, buildOptions)
});
