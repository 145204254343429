import useColorScheme from "../../../commons/libs/hooks/use-color-scheme";
import useVeloconnectWatcher from "../../../commons/libs/hooks/use-veloconnect-watcher";
import usePwaInformationModal from "../../libs/hooks/use-pwa-information-modal";

const AppLifecycles = (): null => {
  usePwaInformationModal();
  useColorScheme();
  useVeloconnectWatcher();

  return null;
};

export default AppLifecycles;
