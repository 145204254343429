import React from "react";

import { AvailabilityType } from "../../../../commons/types/availability";
import { Component } from "../../../../commons/types/component";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import Paragraph from "../../components/Paragraph/Paragraph";
import cn from "../../libs/class-name";

interface Props extends Component {
  icon: React.ReactElement<React.ComponentProps<typeof Icon>>;
  headline: React.ReactElement<React.ComponentProps<typeof Headline>>;
  paragraph: React.ReactElement<React.ComponentProps<typeof Paragraph>>;
  kind?: AvailabilityType;
}

const VariantAvailabilityError = ({ classNames = [], icon, headline, paragraph, kind = undefined }: Props) => (
  <div className={cn("VariantAvailabilityError", [{ [`kind-${kind}`]: kind }], classNames)}>
    <div className="VariantAvailabilityError__headline">
      <div className="VariantAvailabilityError__icon">{icon}</div>
      {headline}
    </div>
    <div className="VariantAvailabilityError__paragraph">{paragraph}</div>
  </div>
);

export default VariantAvailabilityError;
