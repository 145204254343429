import { env as runtimeEnv } from "process";

import { BuildGroup } from "../../types/build-group";

export const env = { ...process.env, ...runtimeEnv };

export interface Envelope {
  _env: any;
  isKeyboardDisabled: boolean;
  isLocalDevelopment: boolean;
  isProductionBuild: boolean;
  isProductionTracking: boolean;
  isElectronLoggingDisabled: boolean;
  isDevMode: boolean;
  maxLineCountPerLog: number;
  serviceUrl: string;

  /**
   * The service tag is only available via envelope in the Kiosk and Kiosk System Services applications.
   */
  serviceTag: string;

  pwd: string;
  home: string;
  user: string;
  hostname: string;
  puppeteerExecutablePath: string;
  buildGroup: BuildGroup;
  isMobileBuild: boolean;
  mobileHost: string;
  mobileServerUrl: string;
  isMobileServerSecure: boolean;
}

const replaceServiceUrl = (): string => {
  // default SERVICE_URL is defined in /scripts/webpack/webpack.config.js as DEFAULT_BASE_CONFIG_SERVICE_URL
  const serviceUrl = process.env.SERVICE_URL ?? "http://localhost:8001/service/";

  return typeof window !== "undefined"
    ? serviceUrl.replace("%serviceTag%", (localStorage.getItem("serviceTag") ?? "").toLowerCase())
    : serviceUrl;
};

const getMobileServerUrl = (): string => env.MOBILE_SERVER_URL ?? "https://mobile.bike.center";

const checkIsMobileServerSecure = (mobileServerUrl: string): boolean => mobileServerUrl.includes("https://");

// We have to enforce some env variables here because
// units tests are not running in the same environment as the application
// and envelope is not mocked in the tests.
const envelope: Envelope = {
  _env: env,
  isKeyboardDisabled: env.DISABLE_KEYBOARD === "true",
  isLocalDevelopment: env.LOCAL_DEVELOPMENT === "true",
  isProductionBuild: env.NODE_ENV === "production",
  isProductionTracking: env.TRACKING_ENV !== "development",
  isElectronLoggingDisabled: env.DISABLE_ELECTRON_LOGGING === "true",
  isDevMode: env.DEV_MODE === "true",
  maxLineCountPerLog: parseInt(env.MAX_LINE_COUNT_PER_LOG ?? "", 10) || 10,
  serviceUrl: replaceServiceUrl(),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  serviceTag: env.SERVICETAG!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  pwd: env.PWD!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  home: env.HOME!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  user: env.USER!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  hostname: env.HOSTNAME!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  puppeteerExecutablePath: env.PUPPETEER_EXECUTABLE_PATH!,
  buildGroup: env.BUILD_GROUP as BuildGroup,
  isMobileBuild: env.BUILD_GROUP === BuildGroup.Mobile || env.BUILD_GROUP === BuildGroup.MobileServices,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  mobileHost: env.MOBILE_HOST!,
  mobileServerUrl: getMobileServerUrl(),
  isMobileServerSecure: checkIsMobileServerSecure(getMobileServerUrl())
};

export default envelope;
