import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  actions?: React.ReactNode;
  info?: React.ReactNode;
}

const FloatingActionBar = ({ classNames = [], actions, info }: Props) => (
  <div className={cn("FloatingActionBar", [], classNames)}>
    {info && <div className="FloatingActionBar__info">{info}</div>}
    {actions && <div className="FloatingActionBar__actions">{actions}</div>}
  </div>
);

export default FloatingActionBar;
