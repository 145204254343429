import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import * as icons from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  next?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const NextPrevNavigationButton = ({ classNames = [], next = false, onClick = noop }: Props) => (
  <button className={cn("NextPrevNavigationButton", [], classNames)} type="button" onClick={onClick}>
    <Icon source={next ? icons.IconLargeAngleRight : icons.IconLargeAngleLeft} size="xl" />
  </button>
);

export default NextPrevNavigationButton;
