import React from "react";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import * as icons from "../../../../resources/icons";
import Badge from "../../components/Badge/Badge";
import Button from "../../components/Button/Button";
import ErrorPageLayout from "../../components/ErrorPageLayout/ErrorPageLayout";
import Headline from "../../components/Headline/Headline";
import Hotline from "../../components/Hotline/Hotline";
import Icon from "../../components/Icon/Icon";
import MonospaceParagraph from "../../components/MonospaceParagraph/MonospaceParagraph";
import Paragraph from "../../components/Paragraph/Paragraph";
import Text from "../../components/Text/Text";
import TextHeader from "../../components/TextHeader/TextHeader";
import useDispatch from "../../libs/hooks/use-dispatch";
import { ROUTES } from "../../routes";

const NotFoundPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation(["commons"]);

  const navigateToHome = () => {
    dispatch(push(ROUTES.NAVIGATION.INDEX));
  };

  return (
    <ErrorPageLayout classNames={["u-text-center"]}>
      <Text>
        <TextHeader topline={<Badge label="Error 404" variant="negative" />}>
          <Headline kind="hero">{t("commons:notFoundPage.pageNotFoundHeadline")}</Headline>
        </TextHeader>
        <Paragraph>{t("commons:notFoundPage.requestedContentNotFound")}</Paragraph>
        <MonospaceParagraph>{t("commons:notFoundPage.path", { path: location.pathname })}</MonospaceParagraph>
        <Paragraph classNames={["u-space-xxxl"]}>
          <Button
            onClick={navigateToHome}
            variant="accent"
            kind="solid"
            icon={<Icon source={icons.IconSmallArrowLeft} />}
          >
            {t("commons:notFoundPage.homeButton")}
          </Button>
        </Paragraph>
        <Hotline
          title={t("commons:supportConfig.headline")}
          phone={t("commons:supportConfig.phone")}
          label={t("commons:supportConfig.officeHours")}
          mail={t("commons:supportConfig.mail")}
        />
      </Text>
    </ErrorPageLayout>
  );
};

export default NotFoundPage;
