import React from "react";
import { noop } from "lodash";

import { LogoType } from "../../../../commons/types/logo";
import assetBcLogoIconInvert from "../../../../resources/images/bc-logo-icon-invert.svg";
import assetBcLogoIcon from "../../../../resources/images/bc-logo-icon.svg";
import assetLogoClaim from "../../../../resources/images/bc-logo-with-claim.svg";
import assetLogo from "../../../../resources/images/bc-logo.svg";
import Logo from "../../components/Logo/Logo";

interface Props {
  fill?: boolean;
  type?: LogoType;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const logos: { [key: number]: { src: string; width: string; height: string } } = {
  [LogoType.Name]: {
    src: assetLogo,
    width: "634px",
    height: "142px"
  },
  [LogoType.Claim]: {
    src: assetLogoClaim,
    width: "634px",
    height: "142px"
  },
  [LogoType.ClaimSmall]: {
    src: assetLogoClaim,
    width: `${Math.floor((634 / 3) * 2)}px`,
    height: `${Math.floor((142 / 3) * 2)}px`
  },
  [LogoType.BcIcon]: {
    src: assetBcLogoIcon,
    width: "90px",
    height: "90px"
  },
  [LogoType.BcIconInvert]: {
    src: assetBcLogoIconInvert,
    width: "90px",
    height: "90px"
  }
};

const LogoPartial = ({ type = LogoType.BcIcon, fill = false, onClick = noop }: Props) => {
  const { src, width, height } = logos[type] || logos[LogoType.BcIcon];
  return <Logo source={src} width={width} height={height} fill={fill} onClick={onClick} />;
};

export default LogoPartial;
