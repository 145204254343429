import React from "react";

import CommonPrintMailSwitchModalPartial from "../../../commons/container/App/PrintMailSwitchModalPartial";
import { connect } from "../../../commons/container/utils/loop";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";

type PrintMailSwitchModalPartialProps = React.ComponentProps<typeof CommonPrintMailSwitchModalPartial>;

const mapStateToProps = (state: State) => ({
  isUsbPrinterConnectedOrMailToPrintEnabled: selectors.selectHasMailToPrintSettings(state)
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

const PrintMailSwitchModalPartial = (props: PrintMailSwitchModalPartialProps) => (
  <CommonPrintMailSwitchModalPartial {...props} isMobile isOffline={false} isOfflineAndMailToPrintIsEnabled={false} />
);

export default connector(PrintMailSwitchModalPartial);
