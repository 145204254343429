import { ServiceAvailabilityResponse } from "../../../../commons/types/service-availability";
import config from "../../config";
import useQuery, { UseQueryOptions } from "../hooks/use-query";
import { getServiceAvailability } from "../services/service-availability/service-availability";

const queryKey = ["serviceAvailability"] as const;

type Options = Omit<
  Partial<UseQueryOptions<ServiceAvailabilityResponse>>,
  "queryKey" | "queryFn" | "gcTime" | "retryDelay" | "retry"
>;

const useServiceAvailability = (options: Options = {}) => {
  const query = useQuery({
    ...options,
    queryKey,
    queryFn: getServiceAvailability,
    retry: config.shared.checkServiceAvailability.retryCount,
    retryDelay: config.shared.checkServiceAvailability.retryDelay,
    gcTime: 0 // Never use the cache for this query
  });

  return query;
};

export default useServiceAvailability;
