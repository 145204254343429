import React from "react";
import { motion } from "framer-motion";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import * as icons from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Button from "../Button/Button";
import FlexLayout from "../FlexLayout/FlexLayout";
import Icon from "../Icon/Icon";

export type ToastKind = "default" | "negative" | "positive";

interface Props extends Component {
  id?: string;
  title: string;
  description?: string;
  action?:
    | React.ReactElement<React.ComponentProps<typeof Button>>
    | React.ReactElement<React.ComponentProps<typeof FlexLayout>>;
  icon?: React.ReactElement<React.ComponentProps<typeof Icon>>;
  kind?: ToastKind;
  timeout?: number; // In seconds
  permanent?: boolean;
  shouldInteractionLeaveKeyboardOpen?: boolean;
  onClose?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const Toast = React.forwardRef(
  (
    {
      classNames = [],
      id = "",
      title,
      description = "",
      action = undefined,
      icon = undefined,
      kind = "default",
      timeout = undefined,
      permanent = false,
      shouldInteractionLeaveKeyboardOpen = false,
      onClose = noop
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const shouldLeaveKeyboardOpen = shouldInteractionLeaveKeyboardOpen ? { "data-keyboard-leave-open": "" } : {};

    return (
      <div
        id={id}
        className={cn("Toast", [{ [`kind-${kind}`]: kind, permanent }], classNames)}
        ref={ref}
        {...shouldLeaveKeyboardOpen}
      >
        {icon && <div className="Toast__icon">{icon}</div>}
        <div className="Toast__text">
          <strong className="Toast__title">{title}</strong>
          {!!description && <small className="Toast__description">{description}</small>}
        </div>
        {action && <div className="Toast__action">{action}</div>}
        {!permanent && (
          <button type="button" className="Toast__close" onClick={onClose}>
            <Icon source={icons.IconSmallCross} />
          </button>
        )}
        {!!timeout && (
          <motion.div
            className="Toast__timeout"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: timeout, ease: "linear" }}
          />
        )}
      </div>
    );
  }
);

Toast.displayName = "Toast";

export default Toast;
