export function makeCancelablePromise<T>(promise: Promise<T>, silent: boolean = true) {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? !silent && reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? !silent && reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    }
  };
}

export async function retryPromise<A>(
  fn: () => Promise<A>,
  retryCount: number,
  retryDelay: number,
  backoffFactor: number = 1.0
): Promise<A> {
  const retryWithTimeout = (error: any) =>
    new Promise<A>((resolve, reject) => {
      if (retryCount > 0) {
        setTimeout(
          () => resolve(retryPromise(fn, retryCount - 1, retryDelay * backoffFactor, backoffFactor)),
          retryDelay
        );
      } else {
        reject(error);
      }
    });

  try {
    return await fn();
  } catch (error) {
    return retryWithTimeout(error);
  }
}

export function waitPromise(delay: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export function compactPromiseErrors<T>(tasks: Array<Promise<T>>): Promise<T[]> {
  return tasks.reduce<Promise<T[]>>(
    (promiseChain, currentTask) =>
      promiseChain.then(chainResults =>
        currentTask.then(currentResult => [...chainResults, currentResult]).catch((error: any) => chainResults)
      ),
    Promise.resolve([] as T[])
  );
}

export function timeoutPromise<T>(promise: Promise<T>, timeout: number) {
  return timeoutPromiseWithAbortSignal(() => promise, timeout);
}

export async function timeoutPromiseWithAbortSignal<T>(fn: (abortSignal: AbortSignal) => Promise<T>, timeout: number) {
  const controller = new AbortController();

  return new Promise<T>((resolve, reject) => {
    const timer = setTimeout(() => {
      controller.abort();
      reject(new Error("Promised timed out"));
    }, timeout);

    fn(controller.signal).then(
      val => {
        clearTimeout(timer);
        return !controller.signal.aborted && resolve(val);
      },
      error => {
        clearTimeout(timer);
        return !controller.signal.aborted && reject(error);
      }
    );
  });
}
