import { useMemo } from "react";
import { keyBy, mapValues } from "lodash";

import { isProductOfType } from "../../../../commons/libs/products";
import { isSizingDataAvailableForBicycleProducts, sizeBicycleProducts } from "../../../../commons/libs/sizing-service";
import {
  calcVariantSpecKeysForMinimalTableWithUniqueRows,
  filterEmptyAndEnsureMinimumVariantSpecKeys
} from "../../../../commons/libs/specs";
import { BicycleSpecKey, CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import { ActiveFilters, ProductFilter } from "../../../../commons/specs/filters";
import {
  Product,
  ProductId,
  ProductKey,
  ProductSpecKey,
  ProductType,
  SpecConfig,
  SpecMap
} from "../../../../commons/specs/product";
import { SizingResult } from "../../../../commons/types/sizing";
import { getActiveFilterValues } from "../../libs/filters";
import useFetchData from "../../libs/hooks/use-fetch-data";
import useSelector from "../../libs/hooks/use-selector";
import { selectIsBodySizingEnabled } from "../../libs/selectors";

const MINIMUM_NUMBER_OF_VARIANTS_TO_SHOW_ALL_FILTERS = 8;
const MAXIMUM_NUMBER_OF_VARIANT_FILTERS = 3;

export const getVariantsTableSpecKeys = <Type extends ProductType>(
  product: Product<Type>,
  specConfig: SpecConfig<Type>,
  showAvailabilityStatus: boolean
): (keyof SpecMap[Type])[] => {
  const variants = product.variants;

  const variantTableSpecKeysForMinimalTableWithUniqueTableRows = calcVariantSpecKeysForMinimalTableWithUniqueRows(
    variants,
    specConfig.specDefinitions,
    "variantsTable",
    specConfig.variantsTableSpecKeysPriorityList
  );

  const doVariantsHaveTheirOwnPrices = variants.some(variant => variant[ProductSpecKey.Price]);

  const variantsTableSpecKeys = filterEmptyAndEnsureMinimumVariantSpecKeys(
    product,
    [
      ...variantTableSpecKeysForMinimalTableWithUniqueTableRows,

      // If the variants have their own prices, append them to the table w/o influencing the algorithm of calcVariantSpecKeysForMinimalTableWithUniqueRows()
      ...(doVariantsHaveTheirOwnPrices ? [ProductSpecKey.Price as const] : [])
    ],
    specConfig.specDefinitions,
    specConfig.variantsTableFallbackSpecKeys,
    showAvailabilityStatus ? 1 : 2
  );

  return variantsTableSpecKeys;
};

export const getFilterSpecKeys = <Type extends ProductType>(
  product: Product<Type>,
  specConfig: SpecConfig<Type>,
  variantsTableSpecKeys: (keyof SpecMap[Type])[],
  flags: { bodySizingEnabled: boolean; hasInternetConnectivity: boolean; bikeFrameSizeCalculatorEnabled: boolean }
): (keyof SpecMap[Type])[] => {
  const { bodySizingEnabled, hasInternetConnectivity, bikeFrameSizeCalculatorEnabled } = flags;
  const variants = product.variants;
  const hasMultipleVariants = variants.length > 1;
  const shouldShowVariantFilters = variants.length >= MINIMUM_NUMBER_OF_VARIANTS_TO_SHOW_ALL_FILTERS;
  const isBodySizingPossible = bodySizingEnabled && hasInternetConnectivity;

  if (!hasMultipleVariants) {
    return [];
  }

  const variantFilterSpecKeys = variantsTableSpecKeys
    // Filter against blacklist
    .filter(specKey => specConfig.specDefinitions[specKey]?.enable?.variantFilters)
    .filter(specKey => {
      return (
        shouldShowVariantFilters ||
        (specKey === BicycleSpecKey.FrameSize && (bikeFrameSizeCalculatorEnabled || isBodySizingPossible))
      );
    })
    // Set maximum filters
    .slice(0, MAXIMUM_NUMBER_OF_VARIANT_FILTERS);

  return variantFilterSpecKeys;
};

export const getVisibleVariantsTableSpecKeys = <Type extends ProductType>(
  variantsTableSpecKeys: (keyof SpecMap[Type])[],
  variantFilterSpecKeys: (keyof SpecMap[Type])[],
  activeFilters: ProductFilter[]
): (keyof SpecMap[Type])[] => {
  const unfilteredVariantSpecKeys = variantsTableSpecKeys.filter(
    specKey => activeFilters.find(filter => filter.key === specKey) === undefined
  );

  const shouldDynamicallyHideColumns =
    // Don't hide, if for each variant spec key a filter was set
    unfilteredVariantSpecKeys.length !== 0 &&
    // Don't hide, if we only have one filter
    variantFilterSpecKeys.length > 1;

  // Filter variant spec keys against active filters, to display only differing values between each variant.
  return shouldDynamicallyHideColumns
    ? variantsTableSpecKeys.filter(specKey => unfilteredVariantSpecKeys.includes(specKey))
    : variantsTableSpecKeys;
};

export const useBodySizing = <Type extends ProductType>(
  product: Product,
  activeFilters: ActiveFilters<Type>,
  hasInternetConnectivity: boolean
) => {
  const isBodySizingEnabled = useSelector(selectIsBodySizingEnabled);

  const isBicycle = isProductOfType(product, ProductType.Bicycle);
  const isPossible = isBicycle && isBodySizingEnabled && hasInternetConnectivity;

  const [activeBodySizingFilterValues] =
    getActiveFilterValues<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
      activeFilters as ActiveFilters<ProductType.Bicycle>,
      CustomBicycleFilterKey.Sizing
    ) ?? [];

  const sizingDataAvailable = useFetchData(
    async () => (isBicycle ? await isSizingDataAvailableForBicycleProducts([product]) : undefined),
    [product],
    {
      isEnabled: !!isPossible
    }
  );
  const isAvailable = sizingDataAvailable.data?.find(
    sizingStatusBikeResult => sizingStatusBikeResult.bikeId === product[ProductKey.ProductId]
  )?.isSizable;

  const bodySizing = useFetchData(
    () =>
      activeBodySizingFilterValues && isBicycle
        ? sizeBicycleProducts([product], activeBodySizingFilterValues)
        : Promise.resolve(undefined),
    [activeBodySizingFilterValues, product],
    { isEnabled: isPossible && !!activeBodySizingFilterValues }
  );

  // Record of all variants to their sizing result
  const result: Record<ProductId, SizingResult> | null = useMemo(
    () =>
      bodySizing.data
        ? mapValues(
            keyBy(bodySizing.data[0].variants, variant => variant.variantId),
            item => item.result
          )
        : null,
    [bodySizing.data]
  );

  return {
    isPossible,
    isAvailable,
    isLoading: sizingDataAvailable.isLoading || bodySizing.isLoading,
    error: sizingDataAvailable.error ?? bodySizing.error,
    result
  };
};
