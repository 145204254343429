import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallCheck } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  label: string;
  active?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const CategoryListItem = ({ classNames = [], label, active = false, onClick = noop }: Props) => (
  <button type="button" className={cn("CategoryListItem", [{ active }], classNames)} onClick={onClick}>
    <span className="CategoryListItem__label">{label}</span>
    <div className="CategoryListItem__icon">
      <Icon source={IconSmallCheck} />
    </div>
  </button>
);

export default CategoryListItem;
