import React from "react";

import { LogoType } from "../../../../commons/types/logo";
import CenteredPageLayout from "../../components/CenteredPageLayout/CenteredPageLayout";
import Logo from "../App/LogoPartial";

const SplashScreen = () => (
  <CenteredPageLayout>
    <Logo type={LogoType.Claim} />
  </CenteredPageLayout>
);

export default SplashScreen;
