import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  printPreview: React.ReactNode;
}

const PrintModalLayout = ({ classNames = [], children, printPreview }: Props) => (
  <div className={cn("PrintModalLayout", [], classNames)}>
    <div className="PrintModalLayout__preview">{printPreview}</div>
    <div className="PrintModalLayout__content">{children}</div>
  </div>
);

export default PrintModalLayout;
