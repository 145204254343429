import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import { countChildrenWithFragments } from "../../libs/react-utils";

interface Props extends Component {
  children: React.ReactNode;
  defaultValue: React.ReactNode;
}

const FilterModalModelYearValues = ({ classNames = [], children, defaultValue }: Props) => {
  const childrenCount = countChildrenWithFragments(children);

  // A maximum of 3 columns, otherwise the columnCount should be equal to childrenCount
  const columnCount = childrenCount >= 3 ? 3 : childrenCount;

  return (
    <div className={cn("FilterModalModelYearValues", [{ [`columns-${columnCount}`]: columnCount }], classNames)}>
      <div className="FilterModalModelYearValues__default">{defaultValue}</div>
      <div className="FilterModalModelYearValues__children">{children}</div>
    </div>
  );
};

export default FilterModalModelYearValues;
