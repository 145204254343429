import React from "react";
import { useTranslation } from "react-i18next";

import {
  compareAvailabilityStates,
  getPrioritizedAvailabilityState as getPrioritizedAvailabilityStateLib
} from "../../../../commons/libs/availability";
import { getSpecFormatter } from "../../../../commons/libs/formatters";
import { getProductImageUrl } from "../../../../commons/libs/resource-paths";
import {
  getAllSpecValues,
  getMaxDiscountPercentage,
  getMinOriginalPrice,
  getMinPrice
} from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { ActiveFilters } from "../../../../commons/specs/filters";
import { Product, ProductKey, ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { AssortmentType } from "../../../../commons/types/assortment";
import {
  AvailabilityState,
  AvailabilityStatus,
  AvailabilityType,
  ManualAssortmentAvailabilityState
} from "../../../../commons/types/availability";
import { ImageManipulationFormat } from "../../../../commons/types/image-manipulation";
import Availability from "../../components/Availability/Availability";
import Badge from "../../components/Badge/Badge";
import ProductTile from "../../components/ProductTile/ProductTile";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import useSelector from "../../libs/hooks/use-selector";
import * as selectors from "../../libs/selectors";

interface Props<Type extends ProductType> {
  product: Product;
  asCategoryViewMode: boolean;
  onClick: () => void;
  brandKey?: string;
  activeFilters: ActiveFilters<Type>;
}

const ProductTilePartial = <Type extends ProductType>({
  product,
  asCategoryViewMode,
  onClick,
  brandKey,
  activeFilters
}: Props<Type>) => {
  const breakpoints = useMediaQuery();

  const assortment = useSelector(state => selectors.selectInitializedSettings(state).assortment);
  const customization = useSelector(state => selectors.selectInitializedSettings(state).customization);
  const assortmentPriceSettings = useSelector(state => selectors.selectAssortmentPriceSettings(state));

  const { t, i18n } = useTranslation(["commons"]);

  const productType = product[ProductKey.ProductType];
  const specConfig = getSpecConfig(productType);

  const specFormatter = getSpecFormatter(productType, specConfig.specDefinitions, i18n);

  const renderAvailability = (product: Product) => {
    const getAvailabilityState = (): AvailabilityState | null => {
      if (assortment.type === AssortmentType.Manual) {
        return getManualAssortmentAvailability(product);
      }

      if (assortment.type === AssortmentType.Automatic) {
        return getAutomaticAssortmentAvailability(product);
      }

      return null;
    };

    const availabilityState = getAvailabilityState();
    return availabilityState ? <Availability kind={availabilityState.type} {...availabilityState} /> : undefined;
  };

  const getManualAssortmentAvailability = (product: Product): AvailabilityState | null => {
    const availability = product[ProductSpecKey.ManualAssortment];
    if (!availability) {
      return null;
    }

    return availability === ManualAssortmentAvailabilityState.Current
      ? { type: AvailabilityType.Full, status: AvailabilityStatus.InStock }
      : { type: AvailabilityType.No, status: AvailabilityStatus.NotInStock };
  };

  const getAutomaticAssortmentAvailability = (product: Product): AvailabilityState | null => {
    const hasActiveAutomaticAssortmentFilter = (activeFilters?.[ProductSpecKey.AutomaticAssortment] || []).length > 0;
    const hasActiveVeloconnectAssortmentFilter =
      (activeFilters?.[ProductSpecKey.VeloconnectAssortment] || []).length > 0;
    const hasBothActiveAssortmentFilters = hasActiveVeloconnectAssortmentFilter && hasActiveAutomaticAssortmentFilter;

    if (hasBothActiveAssortmentFilters) {
      return getPrioritizedCombinedAvailabilityState(product);
    } else if (hasActiveAutomaticAssortmentFilter) {
      return getPrioritizedAvailabilityState(product, ProductSpecKey.WawiAvailability);
    } else if (hasActiveVeloconnectAssortmentFilter) {
      return getPrioritizedAvailabilityState(product, ProductSpecKey.VeloconnectAvailability);
    } else {
      return null;
    }
  };

  const getPrioritizedCombinedAvailabilityState = (product: Product): AvailabilityState | null => {
    const availabilityStates = getAllSpecValues(ProductSpecKey.Availability, product);
    return availabilityStates.length > 0 ? availabilityStates.sort(compareAvailabilityStates)[0] : null;
  };

  const getPrioritizedAvailabilityState = (
    product: Product,
    availabilityKey: ProductSpecKey.WawiAvailability | ProductSpecKey.VeloconnectAvailability
  ): AvailabilityState | null => {
    const availabilityStates = getAllSpecValues(availabilityKey, product).map(availabilityStates =>
      getPrioritizedAvailabilityStateLib(availabilityStates, customization.availabilityPrioritizationOptions)
    );
    return availabilityStates.length > 0 ? availabilityStates.sort(compareAvailabilityStates)[0] : null;
  };

  return (
    <ProductTile
      size={breakpoints.xl ? "default" : "s"}
      switchLines={asCategoryViewMode}
      brandName={!brandKey && asCategoryViewMode ? product[ProductSpecKey.BrandName] : undefined}
      key={product[ProductKey.ProductId]}
      title={specFormatter.formatProductValue(ProductSpecKey.ModelName, product) ?? ""}
      price={customization.showPrices ? specFormatter.formatProductValue(ProductSpecKey.Price, product) : undefined}
      originalPrice={
        (getMinPrice(product) ?? 0) < (getMinOriginalPrice(product) ?? 0) &&
        getMaxDiscountPercentage(product) &&
        assortmentPriceSettings.showOriginalPrices
          ? specFormatter.formatProductValue(ProductSpecKey.OriginalPrice, product)
          : undefined
      }
      badge={
        getMaxDiscountPercentage(product) ? (
          assortmentPriceSettings.showSaleBadge === "word" ? (
            <Badge label={t("commons:defaults.sale")} size="inherit" />
          ) : assortmentPriceSettings.showSaleBadge === "percentage" ? (
            <Badge
              label={specFormatter.formatProductValue(ProductSpecKey.DiscountPercentage, product)}
              size="inherit"
            />
          ) : undefined
        ) : undefined
      }
      imgSource={getProductImageUrl(product, { format: ImageManipulationFormat.Jpeg, width: 365 })}
      onClick={onClick}
      year={specFormatter.formatProductValue(ProductSpecKey.ModelYear, product)}
      availability={renderAvailability(product)}
    />
  );
};

export default ProductTilePartial;
