import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Headline from "../Headline/Headline";

interface Props extends Component {
  title?: string;
  children: React.ReactNode;
  justifyContent?: "normal" | "start";
  columnCount?: number;
}

const FilterModalValues = ({
  classNames = [],
  title = "",
  children,
  justifyContent = "normal",
  columnCount: outerColumnCount
}: Props) => {
  const childrenCount = React.Children.count(children);

  // A maximum of 3 columns, otherwise the columnCount should be equal to childrenCount
  const columnCount = outerColumnCount ? outerColumnCount : childrenCount >= 3 ? 3 : childrenCount;

  const modifiers = {
    [`justify-content-${justifyContent}`]: justifyContent,
    [`columns-${columnCount}`]: columnCount
  };

  return (
    <div className={cn("FilterModalValues", [modifiers], classNames)}>
      {!!title && (
        <div className="FilterModalValues__title">
          <Headline kind="minor">{title}</Headline>
        </div>
      )}

      <div className="FilterModalValues__content">{children}</div>
    </div>
  );
};

export default FilterModalValues;
