import React from "react";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import envelope from "../../../../commons/libs/externals/envelope";
import { formatUnit } from "../../../../commons/libs/formatters";
import { CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import { ActiveFilters } from "../../../../commons/specs/filters";
import { ProductType } from "../../../../commons/specs/product";
import { BodySizingModalContentProps } from "../../../../commons/types/modal";
import { SizingBodyValues } from "../../../../commons/types/sizing";
import * as icons from "../../../../resources/icons";
import * as modalActions from "../../../commons/actions/modal";
import * as sessionActions from "../../../commons/actions/session";
import { ROUTES } from "../../../commons/routes";
import Button from "../../components/Button/Button";
import DescriptionListCard from "../../components/DescriptionListCard/DescriptionListCard";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import Note from "../../components/Note/Note";
import Paragraph from "../../components/Paragraph/Paragraph";
import { setActiveFilter } from "../../libs/filters";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import { connect } from "../utils/loop";

const mapStateToProps = (state: State) => ({
  customization: selectors.selectInitializedSettings(state).customization,
  bodySizingEnabled: selectors.selectIsBodySizingEnabled(state),
  bodySizingNotificationsEnabled: selectors.selectIsBodySizingNotificationsEnabled(state),
  bodySizingBodyValues: state.session.bodyValues
});

const mapDispatchToProps = {
  openBodySizingModal: modalActions.openBodySizingModal,
  setActiveFilters: sessionActions.setActiveFilters,
  onPush: push
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  onClose: () => void;
  activeFilters: ActiveFilters<ProductType.Bicycle>;
  showSizingWarning: boolean;
  showSetFilterButton: boolean;
  openBodySizingModal: (contentProps: BodySizingModalContentProps) => Promise<modalActions.OpenModalAction>;
  setActiveFilters: (activeFilters: ActiveFilters<ProductType.Bicycle>) => Promise<sessionActions.SetActiveFilters>;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const BodySizingFilterModalPartial = ({
  bodySizingEnabled,
  bodySizingNotificationsEnabled,
  bodySizingBodyValues,
  openBodySizingModal,
  activeFilters,
  setActiveFilters,
  showSetFilterButton,
  showSizingWarning,
  onPush,
  onClose
}: Props) => {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation(["commons"]);

  const renderBodySizingNotification = () => {
    const button = envelope.isMobileBuild ? (
      <Button
        block
        variant="accent"
        kind="solid"
        onClick={() => onPush(ROUTES.DEVICE_INFORMATION_AND_SUPPORT.INDEX)}
        icon={<Icon source={icons.IconSmallArrowRight} />}
        iconRight
      >
        {t("commons:filterModalPartialProductFinder.sizing.notification.contactSupportButton")}
      </Button>
    ) : (
      <Button
        block
        variant="accent"
        kind="solid"
        onClick={() => onPush(ROUTES.SETTINGS.THIRD_PARTY_BODYSIZING)}
        icon={<Icon source={icons.IconSmallArrowRight} />}
        iconRight
      >
        {t("commons:filterModalPartialProductFinder.sizing.notification.activateBikeSizingButton")}
      </Button>
    );

    return (
      <>
        <Headline>{t("commons:filterModalPartialProductFinder.sizing.notification.headline")}</Headline>
        <Paragraph>{t("commons:filterModalPartialProductFinder.sizing.notification.description")}</Paragraph>
        {button}
      </>
    );
  };

  const renderWarning = () => {
    return (
      <Note spacing="s" kind="warning" icon={<Icon source={icons.IconSmallInputError} />}>
        <Paragraph size="s" kind="support">
          {t("commons:filterModalPartialProductFinder.sizing.limitWarning")}
        </Paragraph>
      </Note>
    );
  };

  const renderBodySizingValues = (bodySizingBodyValues: SizingBodyValues) => {
    return (
      <>
        <Headline>{t("commons:filterModalPartialProductFinder.sizing.resultsHeadline")}</Headline>
        <DescriptionListCard noCard classNames={["u-space-base"]}>
          <dt>
            {t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterActivatedTooltip.bodyHeight")}:
          </dt>
          <dd>{formatUnit(bodySizingBodyValues.bodyHeight, locale, { unit: "centimeter" })}</dd>
          <dt>{t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterActivatedTooltip.inseam")}:</dt>
          <dd>{formatUnit(bodySizingBodyValues.inseam, locale, { unit: "centimeter" })}</dd>
          <dt>{t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterActivatedTooltip.armLength")}</dt>
          <dd>{formatUnit(bodySizingBodyValues.armLength, locale, { unit: "centimeter" })}</dd>
        </DescriptionListCard>
        {showSetFilterButton && (
          <Button
            block
            variant="accent"
            kind="solid"
            onClick={() => {
              onClose();
              setActiveFilters(
                setActiveFilter<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
                  activeFilters,
                  CustomBicycleFilterKey.Sizing,
                  [bodySizingBodyValues]
                )
              );
            }}
            icon={<Icon source={icons.IconSmallArrowRight} />}
            iconRight
          >
            {t("commons:filterModalPartialProductFinder.sizing.setFilterCtaButton")}
          </Button>
        )}
        <Button
          block
          variant={showSetFilterButton ? "standout" : "accent"}
          kind="solid"
          onClick={() => {
            onClose();
            openBodySizingModal({ variant: "productFinder" });
          }}
          icon={<Icon source={icons.IconSmallArrowRight} />}
          iconRight
        >
          {t("commons:filterModalPartialProductFinder.sizing.resultsCtaButton")}
        </Button>
      </>
    );
  };

  const renderNoBodySizingValues = () => {
    return (
      <>
        <Headline kind="xs">{t("commons:filterModalPartialProductFinder.sizing.noResultsHeadline")}</Headline>
        <Button
          block
          variant={showSetFilterButton ? "standout" : "accent"}
          kind="solid"
          onClick={() => {
            onClose();
            openBodySizingModal({ variant: "productFinder" });
          }}
          icon={<Icon source={icons.IconSmallArrowRight} />}
          iconRight
        >
          {t("commons:filterModalPartialProductFinder.sizing.noResultsCtaButton")}
        </Button>
      </>
    );
  };

  const renderContent = () => {
    if (!bodySizingEnabled) {
      if (bodySizingNotificationsEnabled) {
        return renderBodySizingNotification();
      } else {
        return null;
      }
    } else {
      if (showSizingWarning) {
        return renderWarning();
      } else if (!bodySizingBodyValues) {
        return renderNoBodySizingValues();
      } else {
        return renderBodySizingValues(bodySizingBodyValues);
      }
    }
  };

  return renderContent();
};

export default connector(BodySizingFilterModalPartial);
