import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { ModalContentType, Modals } from "../../../../commons/types/modal";
import actions from "../../../commons/actions";
import AppPartial, { RouteDefinition } from "../../../commons/container/App/AppPartial";
import BodySizingModalPartial from "../../../commons/container/App/BodySizingModalPartial";
import ErrorModalPartial from "../../../commons/container/App/ErrorModalPartial";
import LegalInformationModalPartial from "../../../commons/container/App/LegalInformationModalPartial";
import MailModalPartial from "../../../commons/container/App/MailModalPartial";
import PdfViewerModalPartial from "../../../commons/container/App/PdfViewerModalPartial";
import SplashScreen from "../../../commons/container/SplashScreen/SplashScreen";
import { connect } from "../../../commons/container/utils/loop";
import { errorBoundary } from "../../../commons/libs/hocs/error-boundary";
import useOnMount from "../../../commons/libs/hooks/use-on-mount";
import useTrackPageView from "../../../commons/libs/hooks/use-track-page-view";
import { State } from "../../../commons/reducers";
import { ROUTES } from "../../../commons/routes";
import useInitializeApp from "../../libs/hooks/use-initialize-app";
import DeviceInformationAndSupportPage from "../DeviceInformationAndSupportPage/DeviceInformationAndSupportPage";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import MainNavigationPage from "../Navigation/MainNavigationPage";
import ProductDetails from "../ProductDetails/ProductDetails";
import ProductFinder from "../ProductFinder/ProductFinder";
import ProductFinderOverview from "../ProductFinderOverview/ProductFinderOverview";

import AboutModalPartial from "./AboutModalPartial";
import AppLifecycles from "./AppLifecycles";
import PrintMailSwitchModalPartial from "./PrintMailSwitchModalPartial";
import PrintModalPartial from "./PrintModalPartial";
import PwaInformationModalPartial from "./PwaInformationModalPartial";
import WarningPartial from "./WarningPartial";

const routes: RouteDefinition[] = [
  {
    path: ROUTES.NAVIGATION.INDEX,
    component: MainNavigationPage,
    exact: true
  },
  {
    path: ROUTES.PRODUCT_FINDER_OVERVIEW.INDEX,
    component: ProductFinderOverview
  },
  {
    path: ROUTES.PRODUCT_FINDER.INDEX,
    component: ProductFinder,
    exact: true
  },
  {
    path: ROUTES.PRODUCT_FINDER.BRAND,
    component: ProductFinder,
    exact: true
  },
  {
    path: ROUTES.DEVICE_INFORMATION_AND_SUPPORT.INDEX,
    component: DeviceInformationAndSupportPage,
    exact: true
  },
  {
    path: ROUTES.PRODUCT_DETAILS.INDEX,
    component: ProductDetails
  }
];

const modals: Modals = {
  [ModalContentType.Error]: ErrorModalPartial,
  [ModalContentType.About]: AboutModalPartial,
  [ModalContentType.LegalInformation]: LegalInformationModalPartial,
  [ModalContentType.PrintMailSwitch]: PrintMailSwitchModalPartial,
  [ModalContentType.Mail]: MailModalPartial,
  [ModalContentType.Print]: PrintModalPartial,
  [ModalContentType.PwaInformation]: PwaInformationModalPartial,
  [ModalContentType.BodySizing]: BodySizingModalPartial,
  [ModalContentType.PdfViewer]: PdfViewerModalPartial
};

const mapStateToProps = (state: State) => ({
  isInitAppDone: state.core.isInitAppDone
});

const mapDispatchToProps = {
  setHasEnteredThroughDeepLink: actions.mobileApp.setHasEnteredThroughDeepLink
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const App = ({ isInitAppDone, setHasEnteredThroughDeepLink }: Props) => {
  const { i18n } = useTranslation(["mobile"]);

  useInitializeApp();
  useTrackPageView();

  const registerI18nLanguageChange = () => {
    i18n.on("languageChanged", language => {
      document.documentElement.setAttribute("lang", language);
    });
  };

  useOnMount(() => {
    registerI18nLanguageChange();

    // On first mount, detect if the user has entered through a deep link and save this information in the store
    setHasEnteredThroughDeepLink(location.pathname !== "/");
  });

  if (!isInitAppDone) {
    return <SplashScreen />;
  }

  // From here on the app has been successfully initialized!
  // You can use `selectInitializedEnv` and `selectInitializedSettings` to access env/settings safely.

  return (
    <>
      <AppLifecycles />
      <AppPartial routes={routes} modals={modals} />
      <WarningPartial />
    </>
  );
};

export default errorBoundary(ErrorScreen)(connector(App));
