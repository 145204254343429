import React from "react";
import { isElement } from "react-is";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useIntersect from "../../libs/hooks/use-intersect";

interface Props extends Component {
  children: React.ReactNode | ((params: { isVisible: boolean }) => React.ReactNode);
  height: number;
}

const LazyContent = ({ classNames = [], children, height }: Props) => {
  const [setNode, observerEntry] = useIntersect({ once: true });
  const isVisible = !!observerEntry?.isIntersecting;

  return (
    <div className={cn("LazyContent", [], classNames)} ref={setNode} style={!isVisible ? { height } : undefined}>
      {typeof children === "function" && !isElement(children) ? children({ isVisible }) : isVisible && children}
    </div>
  );
};

export default LazyContent;
