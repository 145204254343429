import { Action } from "redux";

import { ModalConfig } from "../../../commons/types/modal";
import { Actions } from "../actions";
import * as modalActions from "../actions/modal";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";

import { State } from "./";

const NAME = "modal";

export interface ModalState {
  [NAME]: {
    isOpen: boolean;
    modalConfig: ModalConfig;
  };
}

export const initialState: ModalState = {
  [NAME]: {
    isOpen: false,
    modalConfig: null
  }
};

type ModalInnerReducer<A extends Action = Actions> = InnerReducer<State, ModalState[typeof NAME], A>;

const openModal: ModalInnerReducer<modalActions.OpenModalAction> = (state, action) => ({
  ...state[NAME],
  isOpen: true,
  modalConfig: action.payload
});

const closeModal: ModalInnerReducer = () => ({ ...initialState[NAME] });

const reducer: InnerReducer<State, ModalState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "MODAL.OPEN":
      return openModal(state, action);
    case "MODAL.CLOSE":
      return closeModal(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
