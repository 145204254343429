import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerCenter?: React.ReactNode;
  headerRight?: React.ReactNode;
  annotation?: React.ReactElement<any>;
  onContentClick?: () => void;
  noContentPadding?: boolean;
}

const TabBarOverlay = ({
  classNames = [],
  children,
  headerLeft = null,
  headerCenter = null,
  headerRight = null,
  annotation,
  onContentClick = noop,
  noContentPadding = false
}: Props) => (
  <section className={cn("TabBarOverlay", [{ noContentPadding }], classNames)}>
    <header className="TabBarOverlay__header">
      <div className="TabBarOverlay__headerLeft">{headerLeft}</div>
      <div className="TabBarOverlay__headerCenter">{headerCenter}</div>
      <div className="TabBarOverlay__headerRight">{headerRight}</div>
    </header>
    <div
      className="TabBarOverlay__content"
      onClick={event => {
        if (event.currentTarget === event.target) {
          onContentClick();
        }
      }}
    >
      {children}
    </div>
    {annotation && <div className="TabBarOverlay__annotation">{annotation}</div>}
  </section>
);

export default TabBarOverlay;
