import { useState } from "react";
import { noop } from "lodash";

import {
  ClientConfig as VeloconnectEndpoint,
  Status as VeloconnectStatus
} from "../../../../commons/libs/externals/veloconnect-proxy";
import {
  addVeloconnectEndpointConfiguration,
  getConfiguredVeloconnectEndpoints,
  getVeloconnectEndpoint,
  getVeloconnectEndpoints,
  getVisibleVeloconnectEndpoints,
  isVeloconnectEndpointVisible,
  isVeloconnectImportReady,
  removeVeloconnectEndpointConfiguration,
  VeloconnectError
} from "../../../../commons/libs/veloconnect-service";
import { VeloconnectErrorType } from "../../../../commons/types/veloconnect";

import useFetchData from "./use-fetch-data";

interface UseVeloconnectEndpointsOptions {
  refetchDependencies?: React.DependencyList;
  onFetchSuccess?: (endpoints: VeloconnectEndpoint[]) => void;
  onFetchError?: (error: Error) => void;
}

export interface UseVeloconnectEndpointsReturn {
  areReachable: boolean;
  areLoading: boolean;
  endpoints: VeloconnectEndpoint[] | undefined;
  refresh: () => void;
  error: VeloconnectErrorType | undefined;
  visibleEndpoints: VeloconnectEndpoint[];
  configuredEndpoints: VeloconnectEndpoint[] | undefined;
  areAnyEndpointsConfigured: boolean;
  endpointsWithInvalidCredentials: VeloconnectEndpoint[] | undefined;
  areImportsReady: boolean;
  getEndpoint: (endpointKey: string) => VeloconnectEndpoint | undefined;
  getEndpointByBrandKey: (brandKey: string) => VeloconnectEndpoint | undefined;
  addConfiguration: (endpointKey: string, buyersId: string, password: string) => Promise<VeloconnectEndpoint>;
  removeConfiguration: (endpointKey: string) => Promise<VeloconnectStatus>;
  isVisible: (endpointKey: string) => boolean;
}

/** A hook that fetches the Veloconnect endpoint and provides utility functions to interact with them. */
const useVeloconnectEndpoints = ({
  refetchDependencies = [],
  onFetchSuccess = noop,
  onFetchError = noop
}: UseVeloconnectEndpointsOptions = {}): UseVeloconnectEndpointsReturn => {
  const [areReachable, setAreReachable] = useState<boolean>(false);

  const {
    data: endpoints,
    error,
    refresh,
    isLoading: areLoading
  } = useFetchData(getVeloconnectEndpoints, [...refetchDependencies], {
    onDone: endpoints => {
      setAreReachable(true);
      onFetchSuccess?.(endpoints);
    },
    handleError: error => {
      setAreReachable(false);
      onFetchError?.(error);
      console.error("Error fetching Veloconnect endpoints", error);
    }
  });

  const visibleEndpoints = getVisibleVeloconnectEndpoints(endpoints ?? []);

  const configuredEndpoints = getConfiguredVeloconnectEndpoints(endpoints ?? []);

  const areAnyEndpointsConfigured = configuredEndpoints.length > 0;

  const areImportsReady = areAnyEndpointsConfigured
    ? configuredEndpoints.every(endpoint => isVeloconnectImportReady(endpoint))
    : false;

  const endpointsWithInvalidCredentials = configuredEndpoints.reduce(
    (acc: VeloconnectEndpoint[], endpoint) => [...acc, ...(endpoint.areCredentialsInvalid ? [endpoint] : [])],
    []
  );

  // TODO: BCD-7002 Generic Error Type Argument für `useFetchData`
  const evalError = (error: Error): VeloconnectErrorType => {
    if (error instanceof VeloconnectError) {
      return error.veloconnectError;
    } else {
      return VeloconnectErrorType.Other;
    }
  };

  const veloconnectError = error ? evalError(error) : undefined;

  const getEndpoint = (endpointKey: string): VeloconnectEndpoint | undefined => {
    return getVeloconnectEndpoint(endpoints ?? [], endpointKey);
  };

  const getEndpointByBrandKey = (brandKey: string): VeloconnectEndpoint | undefined => {
    return (endpoints ?? []).find(endpoint => endpoint.brandKeys.includes(brandKey));
  };

  const isVisible = (endpointKey: string): boolean => {
    return isVeloconnectEndpointVisible(endpoints ?? [], endpointKey);
  };

  const addConfiguration = async (
    endpointKey: string,
    buyersId: string,
    password: string
  ): Promise<VeloconnectEndpoint> => {
    const response = await addVeloconnectEndpointConfiguration(endpointKey, buyersId, password);
    refresh(); // Refresh the endpoints after adding a configuration
    return response;
  };

  const removeConfiguration = async (endpointKey: string): Promise<VeloconnectStatus> => {
    const response = await removeVeloconnectEndpointConfiguration(endpointKey);
    refresh(); // Refresh the endpoints after removing a configuration
    return response;
  };

  return {
    areReachable,
    areLoading,
    endpoints,
    refresh,
    error: veloconnectError,
    visibleEndpoints,
    configuredEndpoints,
    areAnyEndpointsConfigured,
    endpointsWithInvalidCredentials,
    areImportsReady,
    getEndpoint,
    getEndpointByBrandKey,
    addConfiguration,
    removeConfiguration,
    isVisible
  };
};

export default useVeloconnectEndpoints;
