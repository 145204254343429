import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  logo: React.ReactNode;
  headline: React.ReactNode;
  icon: React.ReactNode;
  description?: React.ReactNode;
}

const WarningOverlayLayout = ({ classNames = [], logo, headline, icon, description = null }: Props) => (
  <div className={cn("WarningOverlayLayout", [], classNames)}>
    <div className="WarningOverlayLayout__logo">{logo}</div>
    <div className="WarningOverlayLayout__content">
      <div className="WarningOverlayLayout__headline">{headline}</div>
      <div className="WarningOverlayLayout__icon">{icon}</div>
      {description && <div className="WarningOverlayLayout__description">{description}</div>}
    </div>
  </div>
);

export default WarningOverlayLayout;
