import { useEffect } from "react";
import { throttle } from "lodash";

export default () => {
  useEffect(() => {
    const updateViewportHeight = throttle(() => {
      const vh = window.innerHeight * 0.01;
      window.document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, 300);

    updateViewportHeight();

    window.addEventListener("resize", updateViewportHeight, false);
    window.addEventListener("orientationchange", updateViewportHeight, false);

    return () => {
      window.removeEventListener("resize", updateViewportHeight, false);
      window.removeEventListener("orientationchange", updateViewportHeight, false);
    };
  }, []);
};
