import React, { createContext } from "react";

import envelope from "../../../../commons/libs/externals/envelope";

interface KeyboardContext {
  keyboardOpen: boolean;
}

interface Props extends KeyboardContext {
  children: React.ReactNode;
}

const KeyboardContext = createContext<KeyboardContext | null>(null);

/** A provider which receives the keyboard open state and provides it to its children. */
export const KeyboardProvider = ({ children, keyboardOpen = false }: Props) => {
  return <KeyboardContext.Provider value={{ keyboardOpen }}>{children}</KeyboardContext.Provider>;
};

/** Custom hook to use the context directly */
export const useKeyboardContext = (): KeyboardContext => {
  const context = React.useContext(KeyboardContext);
  const isMobile = envelope.isMobileBuild;

  if (isMobile) {
    return {
      keyboardOpen: false
    };
  } else {
    if (context === null) {
      throw new Error("useKeyboardContext must be used within a KeyboardProvider");
    }
  }

  return context;
};
