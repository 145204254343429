import { Language, Locale, Region } from "../types/localization";

import { isEnumValue } from "./fp-utils";

export const isLanguage = (language: string): language is Language => isEnumValue(Language)(language);
export const isRegion = (region: string): region is Region => isEnumValue(Region)(region);
export const isLocale = (locale: string): locale is Locale => isEnumValue(Locale)(locale);

export const getLanguageFromLocale = (locale: Locale): Language | undefined => {
  const language = locale.split("-")[0];
  return isLanguage(language) ? language : undefined;
};

export const getRegionFromLocale = (locale: Locale): Region | undefined => {
  const region = locale.split("-")[1];
  return isRegion(region) ? region : undefined;
};

export const createLocale = (language: Language, region: Region): Locale | undefined => {
  const locale = `${language}-${region}`;
  return isLocale(locale) ? locale : undefined;
};

/**
 * ⚠️ Be cautious when using this function. If the first parameter is undefined,
 * this function will always return true, as that is interpreted as "all locales associated".
 */
export const isLocaleInAssociatedLocales = (
  associatedLocales: Array<Locale | Language> | undefined,
  locale: Locale
): boolean => {
  if (!associatedLocales) {
    return true;
  } else {
    const languageFromLocale = getLanguageFromLocale(locale);

    return (
      associatedLocales.includes(locale) || (!!languageFromLocale && associatedLocales.includes(languageFromLocale))
    );
  }
};
