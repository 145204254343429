import { Action } from "redux";

import { Actions } from "../../commons/actions";
import * as mobileActions from "../../commons/actions/mobile-app";
import { InnerReducer, mapReducerStateWithKey } from "../../commons/libs/reducer";

import { State } from "./";

export const NAME = "mobileApp";

export interface MobileAppState {
  [NAME]: {
    hasEnteredThroughDeepLink: boolean;
  };
}

export const initialState: MobileAppState = {
  [NAME]: {
    hasEnteredThroughDeepLink: false
  }
};

export type MobileAppInnerReducer<A extends Action = Actions> = InnerReducer<State, MobileAppState[typeof NAME], A>;

export const handleHasEnteredThroughDeepLink: MobileAppInnerReducer<mobileActions.HasEnteredThroughDeepLinkAction> = (
  state,
  { payload: { hasEnteredThroughDeepLink } }
) => ({
  ...state[NAME],
  hasEnteredThroughDeepLink
});

const reducer: InnerReducer<State, MobileAppState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "MOBILE_APP.SET_HAS_ENTERED_THROUGH_DEEP_LINK":
      return handleHasEnteredThroughDeepLink(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
