import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type Size = "xs" | "s" | "base";

interface Props extends Component {
  name?: string;
  // The props `labelNo` and `labelYes` should only contain short labels for the translations of "Yes" and "No".
  // These labels aren't clipped or prevented from overflowing.
  // If you need a longer label, please use the `ToggleFormLayout` component to place a label to the left of the ToggleField.
  labelNo?: string;
  labelYes?: string;
  alwaysOn?: boolean;
  checked?: boolean;
  disabled?: boolean;
  size?: Size;
  onBlur?: () => void;
  onChange?: (value: boolean) => void;
}

const ToggleField = ({
  classNames = [],
  name = "",
  labelYes = "",
  labelNo = "",
  alwaysOn = false,
  checked = false,
  disabled = false,
  size = "base",
  onChange = noop,
  onBlur = noop
}: Props) => {
  const handleChange = (value: boolean) => {
    onChange(value);
  };

  return (
    <div className={cn("ToggleField", [{ [`size-${size}`]: !!size, alwaysOn, disabled }], classNames)}>
      <input
        disabled={disabled}
        className="ToggleField__input"
        type="checkbox"
        name={name}
        checked={checked}
        onChange={() => handleChange(!checked)}
        onBlur={onBlur}
      />
      <div className="ToggleField__background" />
      <div className="ToggleField__inner">
        <div className="ToggleField__knob">
          <div className="ToggleField__labelYes">{labelYes}</div>
          <div className="ToggleField__labelNo">{labelNo}</div>
        </div>
      </div>
    </div>
  );
};

export default ToggleField;
