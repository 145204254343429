import React from "react";
import { goBack, replace } from "connected-react-router";
import { Trans, useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { LogoType } from "../../../../commons/types/logo";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Headline from "../../../commons/components/Headline/Headline";
import Hotline from "../../../commons/components/Hotline/Hotline";
import Icon from "../../../commons/components/Icon/Icon";
import IconLink from "../../../commons/components/IconLink/IconLink";
import InformationPageLayout from "../../../commons/components/InformationPageLayout/InformationPageLayout";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";
import SplitContentLayout from "../../../commons/components/SplitContentLayout/SplitContentLayout";
import Text from "../../../commons/components/Text/Text";
import LogoPartial from "../../../commons/container/App/LogoPartial";
import { connect } from "../../../commons/container/utils/loop";
import { ROUTES } from "../../../commons/routes";
import { State } from "../../reducers";
import DeviceInformationPartial from "../App/DeviceInformationPartial";

const mapStateToProps = (state: State) => ({
  hasEnteredThroughDeepLink: state.mobileApp?.hasEnteredThroughDeepLink
});

const mapDispatchToProps = {
  onGoBack: goBack,
  onReplace: replace,
  setHasEnteredThroughDeepLink: actions.mobileApp?.setHasEnteredThroughDeepLink
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const SupportPage = ({ onGoBack, onReplace, hasEnteredThroughDeepLink, setHasEnteredThroughDeepLink }: Props) => {
  const { t } = useTranslation(["mobile"]);

  const renderBackButton = () => {
    const onClick = () => {
      // To prevent a mobile user from accidentally exiting the app when he enters through a deep link,
      // we redirect in place (don't push), to the main page to start a new history
      if (hasEnteredThroughDeepLink) {
        // We reset the hasEnteredThroughDeepLink-state to prevent a loop
        setHasEnteredThroughDeepLink(false);
        onReplace(ROUTES.NAVIGATION.INDEX);
      } else {
        onGoBack();
      }
    };

    const label = hasEnteredThroughDeepLink
      ? t("mobile:deviceInformationAndSupportPage.backToMainMenuButton")
      : t("mobile:deviceInformationAndSupportPage.backButton");

    return (
      <IconLink icon={<Icon source={icons.IconSmallArrowLeft} />} size="l" variant="standout" onClick={onClick}>
        {label}
      </IconLink>
    );
  };

  return (
    <InformationPageLayout
      variant="standout"
      headerLeft={renderBackButton()}
      headerRight={<LogoPartial type={LogoType.BcIconInvert} />}
    >
      <Text>
        <Headline kind="hero">
          <Trans
            t={t}
            i18nKey="mobile:deviceInformationAndSupportPage.contactSupportHeadline"
            components={{
              linebreak: <br />
            }}
          />
        </Headline>
        <Paragraph>{t("mobile:deviceInformationAndSupportPage.contactExplanation")}</Paragraph>
        <br />
        <br />
        <SplitContentLayout size="s">
          <Hotline
            title={t("mobile:supportConfig.headline")}
            phone={t("mobile:supportConfig.phone")}
            label={t("mobile:supportConfig.officeHours")}
            mail={t("mobile:supportConfig.mail")}
          />
          <DeviceInformationPartial informationListVariant="standout" />
        </SplitContentLayout>
      </Text>
    </InformationPageLayout>
  );
};

export default connector(SupportPage);
