import { isEqual } from "lodash";

import {
  filterConfig as bicycleFilterConfig,
  filterGroups as bicycleFilterGroups,
  CustomBicycleFilterSpec
} from "./bicycle";
import {
  CustomMotorcycleFilterSpec,
  filterConfig as motorcycleFilterConfig,
  filterGroups as motorcycleFilterGroups
} from "./motorcycle";
import { ProductKey, ProductType, SpecConfig, SpecMap } from "./product";

/* # Filter values */

export enum FilterTypes {
  // Greater than
  Gt = "gt",
  // Greater than or equals to
  Gte = "gte",
  // Less than
  Lt = "lt",
  // Less than or equals to
  Lte = "lte",
  // Loosely equals (==)
  Eq = "eq",
  // Strict equals (===)
  Eqs = "eqs",
  // Contains after lower
  Con = "con",
  // Checks if prop is or is not defined (true/false)
  Has = "has"
}

export type RangeFilterValue = [number, number];

type FilterConfigBaseKey<Type extends ProductType> = keyof SpecMap[Type];
type FilterConfigCustomKey<Type extends ProductType> = keyof CustomFilterSpecMap[Type];

export type FilterKey<Type extends ProductType> = FilterConfigBaseKey<Type> | FilterConfigCustomKey<Type>;

type FilterValueBase<Type extends ProductType, K extends FilterConfigBaseKey<Type>> = NonNullable<SpecMap[Type][K]>;
type FilterValueCustom<Type extends ProductType, K extends FilterConfigCustomKey<Type>> = CustomFilterSpecMap[Type][K];

export type FilterValue<Type extends ProductType, K extends FilterKey<Type>> =
  K extends FilterConfigBaseKey<Type>
    ? FilterValueBase<Type, K>
    : K extends FilterConfigCustomKey<Type>
      ? FilterValueCustom<Type, K>
      : never;

type ActiveFilterMap<Type extends ProductType> = {
  [K in FilterKey<Type>]?: FilterValue<Type, K>[];
};

export type ActiveFilters<Type extends ProductType> = ActiveFilterMap<Type> | null;

/* # Filter Config */

export type ProductFilter = {
  key: string;
  value: string | number | boolean;
  filterType?: FilterTypes;
};

export type ProductFilterValues<Type extends ProductType, K extends FilterKey<Type> = FilterKey<Type>> = Partial<
  Record<
    K | ProductKey,
    // TODO: BCD-7688 Fix or loose type safety of ProductFilterValues
    Array<{
      value: any;
      label: string;
    }>
  >
>;

type DefaultFilterConfig<Type extends ProductType, K extends FilterConfigBaseKey<Type>> = {
  getActiveFilters: (values: FilterValueBase<Type, K>[]) => ProductFilter[];
  type: "default";
};

type RangeFilterConfig<Type extends ProductType, K extends FilterConfigBaseKey<Type>> = {
  getActiveFilters: (values: FilterValueBase<Type, K>[]) => ProductFilter[];
  type: "range";
  canDisable?: boolean;
};

type CustomFilterConfig<Type extends ProductType, K extends FilterConfigCustomKey<Type>> = {
  getActiveFilters: (values: FilterValueCustom<Type, K>[]) => ProductFilter[];
  type: "custom";
};

type CustomFilterSpecMap = {
  [ProductType.Bicycle]: CustomBicycleFilterSpec;
  [ProductType.Motorcycle]: CustomMotorcycleFilterSpec;
};

export type FilterConfigItem<Type extends ProductType, K extends FilterKey<Type>> =
  K extends FilterConfigBaseKey<Type>
    ? DefaultFilterConfig<Type, K> | RangeFilterConfig<Type, K>
    : K extends FilterConfigCustomKey<Type>
      ? CustomFilterConfig<Type, K>
      : never;

export type FilterConfig<Type extends ProductType> = {
  [K in FilterKey<Type>]?: FilterConfigItem<Type, K>;
};

// Local helper type to infer all filter config keys from filter config
type FilterConfigConstTypeMap = {
  [ProductType.Bicycle]: typeof bicycleFilterConfig;
  [ProductType.Motorcycle]: typeof motorcycleFilterConfig;
};

type FilterConfigConstKey<Type extends ProductType> = keyof FilterConfigConstTypeMap[Type];

// Export filter config keys as string constants for usage in i18n keys
export type FilterConfigConstKeys<Type extends ProductType> =
  FilterConfigConstKey<Type> extends string ? FilterConfigConstKey<Type> : never;

/* # Filter groups */

export type FilterGroups<Type extends ProductType, Config extends FilterConfig<Type> = FilterConfig<Type>> = {
  key: string;
  filters: (keyof Config & FilterKey<Type>)[];
}[];

// Local helper type to infer all filter group keys from filter groups
type FilterGroupsConstTypeMap = {
  [ProductType.Bicycle]: typeof bicycleFilterGroups;
  [ProductType.Motorcycle]: typeof motorcycleFilterGroups;
};

type FilterGroupsConstKey<Type extends ProductType> = FilterGroupsConstTypeMap[Type][number]["key"];

// Export filter group keys as string constants for usage in i18n keys
export type FilterGroupsConstKeys<Type extends ProductType> =
  FilterGroupsConstKey<Type> extends string ? FilterGroupsConstKey<Type> : never;

/* # Assortment filter keys */

export type AssortmentFilterKeys<
  Type extends ProductType,
  Config extends FilterConfig<Type> = FilterConfig<Type>
> = (keyof Config & FilterKey<Type>)[];

/* # Filter helpers */

export const getActiveFilters =
  <Type extends ProductType, K extends FilterKey<Type> = FilterKey<Type>>(key: K, filterType?: FilterTypes) =>
  <V extends FilterValue<Type, K>>(values: V[]): ProductFilter[] =>
    values.map(value => ({
      key: String(key),
      value: String(value),
      filterType
    }));

export const getActiveArrayFilters =
  <Type extends ProductType, K extends FilterKey<Type> = FilterKey<Type>>(key: K) =>
  <V extends FilterValue<Type, K>>(values: V[]): ProductFilter[] => {
    return values.flatMap(filterValue => {
      const listValue = Array.isArray(filterValue) ? filterValue : [filterValue];

      return listValue.map(value => ({
        key: String(key),
        value: String(value),
        filterType: FilterTypes.Con
      }));
    });
  };

export const getActiveRangeFilters =
  <Type extends ProductType, K extends FilterKey<Type> = FilterKey<Type>>(key: K) =>
  (values: number[]): ProductFilter[] => {
    const keyString = key.toString();

    return isEqual(values, [0, 0])
      ? [
          {
            key: keyString,
            filterType: FilterTypes.Has,
            value: "false"
          }
        ]
      : values.length === 2
        ? [
            {
              key: keyString,
              filterType: FilterTypes.Gte,
              value: values[0]
            },
            {
              key: keyString,
              filterType: FilterTypes.Lte,
              value: values[1]
            }
          ]
        : [];
  };

export const getTypedFilterConfigEntries = <Type extends ProductType>(filterConfig: FilterConfig<Type>) => {
  return Object.entries(filterConfig) as [keyof FilterConfig<Type>, FilterConfigItem<Type, keyof FilterConfig<Type>>][];
};

export const getTypedFilterConfigKeys = <Type extends ProductType>(specConfig: SpecConfig<Type>): FilterKey<Type>[] => {
  return Object.keys(specConfig.filterConfig) as FilterKey<Type>[];
};
