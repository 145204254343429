export enum Language {
  De = "de",
  En = "en",
  Fr = "fr",
  It = "it",
  Es = "es",
  Da = "da"
}

export enum Region {
  De = "DE",
  At = "AT",
  Dk = "DK",
  Es = "ES",
  Gb = "GB",
  Nl = "NL"
}

export enum Locale {
  EnUs = "en-US",
  EnDe = "en-DE",
  EnDk = "en-DK",
  EnNl = "en-NL",
  EnGb = "en-GB",
  EsEs = "es-ES",
  EnEs = "en-ES",
  DeDe = "de-DE",
  DeAt = "de-AT",
  DeCh = "de-CH"
}

export type TranslationKey = Language | Locale;
