import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Image, { Position as ImagePosition } from "../Image/Image";

interface Props extends Component {
  src: string;
  position?: ImagePosition;
}

// Has to be in sync with the styles
const IMAGE_WIDTH = 260;
const IMAGE_HEIGHT = 70;

const BrandImage = ({ classNames = [], src, position = "left" }: Props) => (
  <div className={cn("BrandImage", [], classNames)}>
    <Image src={src} position={position} ratio={IMAGE_WIDTH / IMAGE_HEIGHT} />
  </div>
);

export default BrandImage;
