import { datetime } from "../libs/externals/veloconnect-proxy";

export interface VeloconnectSettings {
  apiKey?: string;
}

export enum VeloconnectErrorType {
  InvalidCredentials = "InvalidCredentials",
  EndpointKeyAlreadyConfigured = "EndpointKeyAlreadyConfigured",
  InvalidEndpointKey = "InvalidEndpointKey",
  EndpointKeyNotConfigured = "EndpointKeyNotConfigured",
  AuthorizationFailed = "AuthorizationFailed",
  ClientAlreadyExists = "ClientAlreadyExists",
  Other = "Other"
}

export interface VeloconnectCredentials {
  endpointKey: string;
  username: string;
  password: string;
}

export interface AdapterKeyLastSucessFulImportMap {
  [adapterKey: string]: datetime | undefined;
}
