import * as R from "ramda";

import { ProductId } from "../specs/product";
import { AssortmentItem, ManualAssortmentItem, VeloconnectAssortment, WaWiAssortmentItem } from "../types/assortment";

import { ProductAvailability } from "./externals/veloconnect-proxy";

const uniqAssortmentItems = <T extends AssortmentItem>(...assortmentItemsList: T[][]): T[] => {
  const newAssortment = new Map();

  assortmentItemsList.forEach(assortmentItems =>
    assortmentItems.forEach(assortmentItem =>
      newAssortment.set(`bikeId:${assortmentItem.bikeId}_variantId:${assortmentItem.variantId}`, assortmentItem)
    )
  );

  return Array.from(newAssortment.values());
};

const withoutAssortmentItems = <T extends AssortmentItem>(assortmentItemsToRemove: T[], assortment: T[]): T[] => {
  const newAssortment = new Map();

  assortment.forEach(assortmentItem => {
    newAssortment.set(`bikeId:${assortmentItem.bikeId}_variantId:${assortmentItem.variantId}`, assortmentItem);
  });

  assortmentItemsToRemove.forEach(assortmentItemToRemove => {
    newAssortment.delete(`bikeId:${assortmentItemToRemove.bikeId}_variantId:${assortmentItemToRemove.variantId}`);
  });

  return Array.from(newAssortment.values());
};

export const addBikeIdsToManualAssortment = (
  assortmentItems: AssortmentItem[],
  currentAssortment: AssortmentItem[]
): AssortmentItem[] => {
  return uniqAssortmentItems(assortmentItems, currentAssortment);
};

export const deleteBikeIdsFromManualAssortment = (
  assortmentItems: AssortmentItem[],
  currentAssortment: AssortmentItem[]
): AssortmentItem[] => withoutAssortmentItems(assortmentItems, currentAssortment);

export const addBikeIdsToWawiAssortment = (
  wawiResults: WaWiAssortmentItem[],
  currentAssortment: WaWiAssortmentItem[]
): WaWiAssortmentItem[] => {
  return uniqAssortmentItems(wawiResults, currentAssortment);
};

export const deleteBikeIdsFromWawiAssortment = (
  wawiResults: AssortmentItem[],
  currentAssortment: AssortmentItem[]
): AssortmentItem[] => withoutAssortmentItems(wawiResults, currentAssortment);

export const getCurrentAssortmentFromAssortmentItems = (assortmentItem: AssortmentItem[]): ProductId[] =>
  R.uniq(R.map(R.prop("bikeId"), assortmentItem));

export const getManualAssortmentItemFromBikeIds = (bikeIds: ProductId[]): AssortmentItem[] =>
  bikeIds.map(bikeId => ({ bikeId, variantId: null }));

export const isInAssortment = (
  assortment: AssortmentItem[],
  bikeId: ProductId,
  variantId?: ProductId | null
): boolean =>
  assortment.some(
    assortmentItem =>
      assortmentItem.bikeId === bikeId && (assortmentItem.variantId === variantId || variantId === undefined)
  );

export const getAssortmentItem = (
  assortment: AssortmentItem[],
  bikeId: ProductId,
  variantId?: ProductId | null
): AssortmentItem | undefined =>
  assortment.find(
    assortmentItem =>
      assortmentItem.bikeId === bikeId && (assortmentItem.variantId === variantId || variantId === undefined)
  );

export const isWaWiAssortmentItem = (assortmentItem: AssortmentItem): assortmentItem is WaWiAssortmentItem =>
  (assortmentItem as WaWiAssortmentItem).wawiProductAvailabilityVariant !== undefined;

export const isManualAssortmentItem = (assortmentItem: AssortmentItem): assortmentItem is ManualAssortmentItem =>
  (assortmentItem as WaWiAssortmentItem).wawiProductAvailabilityVariant === undefined;

export const getVeloconnectAssortmentItem = (
  veloconnectAssortment: VeloconnectAssortment,
  bikeId: ProductId,
  variantId: ProductId
): ProductAvailability | undefined => {
  return veloconnectAssortment.assortment.find(item => item.productId === bikeId && item.variantId === variantId);
};
