import React from "react";
import { noop } from "lodash";
import { ConnectedProps } from "react-redux";

import actions from "../../../commons/actions";
import { connect } from "../../../commons/container/utils/loop";
import { State } from "../../../commons/reducers";

const mapStateToProps = (state: State) => ({
  hasError: state.error.hasError
});

const mapDispatchToProps = {
  setError: actions.error.set
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  children?: any;
  errorScreen: React.ComponentType<any>;
  onError: (error: Error) => void;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

interface ErrorBoundaryState {
  hasRenderError: boolean;
}

class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  public state = {
    hasRenderError: false
  };

  public static getDerivedStateFromError(error: Error) {
    return {
      hasRenderError: true
    };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.setError({
      message: !error ? "" : typeof error === "string" ? error : error.message ? error.message : "",
      stack: errorInfo.componentStack ?? ""
    });

    this.props.onError(error);

    this.setState({ hasRenderError: true });
  }

  public render() {
    if (this.state.hasRenderError || this.props.hasError) {
      const ErrorScreen = this.props.errorScreen;

      return (
        <ErrorScreen
          onReset={() => {
            this.setState({ hasRenderError: false });
          }}
        />
      );
    }
    return this.props.children;
  }
}

const EnhancedErrorBoundary = connector(ErrorBoundary);

export const errorBoundary =
  (ErrorScreen: React.ComponentType<any>, onError: (error: Error) => void = noop) =>
  (Component: React.ComponentType<any>) => {
    const Wrapped = (props: any) => (
      <EnhancedErrorBoundary errorScreen={ErrorScreen} onError={onError}>
        <Component {...props} />
      </EnhancedErrorBoundary>
    );

    const name = Component.displayName || Component.name;
    Wrapped.displayName = name ? `WithErrorBoundary(${name})` : "WithErrorBoundary";

    return Wrapped;
  };
