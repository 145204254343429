import React from "react";

export enum ScrollPosition {
  Start = "start",
  Middle = "middle",
  End = "end"
}

type ScrollDirection = "horizontal" | "vertical";

interface UseScrollObserverOptions {
  isScrolledToEndThresholdInPx?: number;
  shouldObserveResizeEvents?: boolean;
  deps?: React.DependencyList;
}

interface UseScrollObserverReturn {
  scrollPosition: Record<ScrollDirection, ScrollPosition>;
  isScrollable: Record<ScrollDirection, boolean>;
}

const evalIsVerticallyScrollable = ({ scrollHeight, clientHeight }: HTMLElement): boolean =>
  scrollHeight > clientHeight;

const evalIsHorizontallyScrollable = ({ scrollWidth, clientWidth }: HTMLElement): boolean => scrollWidth > clientWidth;

const evalVerticalScrollPosition = (
  { scrollHeight, scrollTop, offsetHeight }: HTMLElement,
  isScrolledToEndThresholdInPx: UseScrollObserverOptions["isScrolledToEndThresholdInPx"]
): ScrollPosition => {
  const scrolledHeight = scrollTop + offsetHeight;
  const remainingScrollHeight = Math.abs(scrollHeight - scrolledHeight);

  const isScrolledToStart = scrollTop === 0;
  const isScrolledToEnd = remainingScrollHeight <= (isScrolledToEndThresholdInPx ?? 0);

  return isScrolledToStart ? ScrollPosition.Start : isScrolledToEnd ? ScrollPosition.End : ScrollPosition.Middle;
};

const evalHorizontalScrollPosition = (
  { scrollWidth, scrollLeft, offsetWidth }: HTMLElement,
  isScrolledToEndThresholdInPx: UseScrollObserverOptions["isScrolledToEndThresholdInPx"]
): ScrollPosition => {
  const scrolledWidth = scrollLeft + offsetWidth;
  const remainingScrollWidth = Math.abs(scrollWidth - scrolledWidth);

  const isScrolledToStart = scrollLeft === 0;
  const isScrolledToEnd = remainingScrollWidth <= (isScrolledToEndThresholdInPx ?? 0);

  return isScrolledToStart ? ScrollPosition.Start : isScrolledToEnd ? ScrollPosition.End : ScrollPosition.Middle;
};

export const useIsScrollable = (
  elementRef: React.RefObject<HTMLElement>,
  {
    shouldObserveResizeEvents = false,
    deps = []
  }: Pick<UseScrollObserverOptions, "shouldObserveResizeEvents" | "deps"> = {}
): Record<ScrollDirection, boolean> => {
  const frameID = React.useRef<number>(0);

  const [isScrollable, setIsScrollable] = React.useState<UseScrollObserverReturn["isScrollable"]>({
    horizontal: false,
    vertical: false
  });

  const evalIsScrollable = React.useCallback((element: HTMLElement): void => {
    setIsScrollable({
      vertical: evalIsVerticallyScrollable(element),
      horizontal: evalIsHorizontallyScrollable(element)
    });
  }, []);

  const resizeObserver = React.useMemo(
    () =>
      new ResizeObserver(entries => {
        /* Why cancelAnimationFrame()?
         * Because the resizeObserver fires before the element is actually resized, so we need to wait for the element to be resized before we can call evalIsScrollable().
         * These next lines prevent evalIsScrollable() from being called before the element is actually resized, and improves performance.
         * Modeled after: https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-resize-observer/use-resize-observer.ts
         */

        if (entries[0]) {
          cancelAnimationFrame(frameID.current);

          frameID.current = requestAnimationFrame(() => {
            if (elementRef.current) {
              evalIsScrollable(elementRef.current);
            }
          });
        }
      }),
    [elementRef, evalIsScrollable]
  );

  // After mount, initially evaluate scrollability, and then observe resize events of element
  React.useEffect(() => {
    const element = elementRef?.current;

    if (element && resizeObserver) {
      evalIsScrollable(element);
      if (shouldObserveResizeEvents) {
        resizeObserver.observe(element);
      }
    }

    return () => {
      if (element && shouldObserveResizeEvents) {
        resizeObserver?.unobserve(element);
      }
      if (frameID.current) {
        cancelAnimationFrame(frameID.current);
      }
    };

    // Rule disabled only for spread deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, resizeObserver, shouldObserveResizeEvents, ...deps]);

  return isScrollable;
};

const useScrollPosition = (
  elementRef: React.RefObject<HTMLElement>,
  options: UseScrollObserverOptions,
  isScrollable: Record<ScrollDirection, boolean>
): Record<ScrollDirection, ScrollPosition> => {
  const [scrollPosition, setScrollPosition] = React.useState<UseScrollObserverReturn["scrollPosition"]>({
    horizontal: ScrollPosition.Start,
    vertical: ScrollPosition.Start
  });

  const evalScrollPosition = React.useCallback(
    (
      element: HTMLElement,
      isScrolledToEndThresholdInPx: UseScrollObserverOptions["isScrolledToEndThresholdInPx"]
    ): void => {
      setScrollPosition({
        horizontal: evalHorizontalScrollPosition(element, isScrolledToEndThresholdInPx),
        vertical: evalVerticalScrollPosition(element, isScrolledToEndThresholdInPx)
      });
    },
    []
  );

  // If the element is scrollable, evaluate and listen to the scroll events for re-evaluation
  React.useEffect(() => {
    const element = elementRef?.current;
    const handleScrollEvent = (): void => {
      if (element) {
        evalScrollPosition(element, options.isScrolledToEndThresholdInPx);
      }
    };

    if (element && (isScrollable.horizontal || isScrollable.vertical)) {
      evalScrollPosition(element, options.isScrolledToEndThresholdInPx);
      element.addEventListener("scroll", handleScrollEvent);
    }

    return () => element?.removeEventListener("scroll", handleScrollEvent);
  }, [elementRef, isScrollable, options.isScrolledToEndThresholdInPx, evalScrollPosition]);

  return scrollPosition;
};

export default (
  elementRef: React.RefObject<HTMLElement>,
  { isScrolledToEndThresholdInPx = 30, shouldObserveResizeEvents = false, deps = [] }: UseScrollObserverOptions = {}
): UseScrollObserverReturn => {
  const options: UseScrollObserverOptions = {
    isScrolledToEndThresholdInPx,
    shouldObserveResizeEvents,
    deps
  };

  const isScrollable = useIsScrollable(elementRef, options);
  const scrollPosition = useScrollPosition(elementRef, options, isScrollable);

  return {
    scrollPosition,
    isScrollable
  };
};
