import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

export interface Props extends Component {
  step: number;
  kind?: "default" | "active" | "complete";
  onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
}

const NumberedStep = ({ classNames = [], step, kind = "default", onClick = noop }: Props) => (
  <div
    className={cn(
      "NumberedStep",
      [{ [`kind-${kind}`]: !!kind, pointer: kind === "complete" && onClick !== noop }],
      classNames
    )}
    onClick={kind === "complete" ? onClick : noop}
  >
    {step}
  </div>
);

export default NumberedStep;
