import React from "react";
import { sortBy } from "lodash";

import { BicycleSpecKey } from "../../../../commons/specs/bicycle";
import { ActiveFilters, ProductFilterValues } from "../../../../commons/specs/filters";
import { ProductType } from "../../../../commons/specs/product";
import ColorCheckCircle from "../../components/ColorCheckCircle/ColorCheckCircle";
import FilterModalValues from "../../components/FilterModal/FilterModalValues";
import { addActiveFilterValue, isActiveFilter, removeActiveFilterValue } from "../../libs/filters";

interface Props {
  activeFilters: ActiveFilters<ProductType.Bicycle>;
  filterValues: NonNullable<ProductFilterValues<ProductType.Bicycle>[BicycleSpecKey.PrimaryColor]>;
  onFilterChange: (activeFilters: ActiveFilters<ProductType.Bicycle>) => void;
}

const ColorFilterModalPartial = ({ onFilterChange, filterValues, activeFilters }: Props) => {
  const filterValuesSorted = sortBy(filterValues, ({ value }) => {
    const index = Object.keys(colorConfig).indexOf(value);
    return index === -1 ? Infinity : index;
  });

  return (
    <FilterModalValues justifyContent="start" columnCount={5}>
      {filterValuesSorted.map(({ value: primaryColor }) => {
        const filterKey = BicycleSpecKey.PrimaryColor;
        const isActive = isActiveFilter<ProductType.Bicycle, BicycleSpecKey.PrimaryColor>(
          activeFilters,
          filterKey,
          primaryColor
        );

        const handleClick = () =>
          onFilterChange(
            isActive
              ? removeActiveFilterValue<ProductType.Bicycle, BicycleSpecKey.PrimaryColor>(
                  activeFilters,
                  filterKey,
                  primaryColor
                )
              : addActiveFilterValue<ProductType.Bicycle, BicycleSpecKey.PrimaryColor>(
                  activeFilters,
                  filterKey,
                  primaryColor
                )
          );

        return (
          <ColorCheckCircle
            key={`${filterKey.toString()}-${primaryColor}`}
            onClick={handleClick}
            bg={colorConfig[primaryColor].bg}
            fg={colorConfig[primaryColor].fg}
            checked={isActive}
          />
        );
      })}
    </FilterModalValues>
  );
};

export const colorConfig: {
  [key: string]: { bg: string; fg: string };
} = {
  schwarz: { bg: "#101518", fg: "#ffffff" },
  anthrazit: { bg: "#28383c", fg: "#ffffff" },
  grau: { bg: "#4F6367", fg: "#ffffff" },
  silber: { bg: "#adb1bc", fg: "#ffffff" },
  weiß: { bg: "#F5F5F5", fg: "#000000" },

  braun: { bg: "#754f3f", fg: "#ffffff" },
  weinrot: { bg: "#ac3a48", fg: "#ffffff" },
  rot: { bg: "#f25a5a", fg: "#ffffff" },
  pink: { bg: "#fdd0f2", fg: "#000000" },
  violett: { bg: "#8093F1", fg: "#ffffff" },
  blau: { bg: "#4385d3", fg: "#ffffff" },
  türkis: { bg: "#71c3b5", fg: "#ffffff" },
  grün: { bg: "#53ad59", fg: "#ffffff" },
  lime: { bg: "#9acd32", fg: "#ffffff" },
  gelb: { bg: "#ffcc4b", fg: "#000000" },
  gold: { bg: "#DEB841", fg: "#ffffff" },
  orange: { bg: "#ff9b1a", fg: "#ffffff" }
};

export default ColorFilterModalPartial;
