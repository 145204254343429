import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const CenteredPageLayout = ({ classNames = [], children }: Props) => (
  <section className={cn("CenteredPageLayout", [], classNames)}>
    <div className="CenteredPageLayout__main">{children}</div>
  </section>
);

export default CenteredPageLayout;
