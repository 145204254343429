import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode[];
  verticalAlign?: "top" | "center" | "bottom";
  size?: "xs" | "s" | "m" | "l";
  separator?: boolean;
  dynamicWidth?: boolean;
  overflow?: boolean;
  outerPadding?: "none" | "xxl";
  background?: "none" | "primary-contrast-gradient-support-to-main";
  backgroundGradientDirection?: "left" | "right";
}

const SplitContentLayout = ({
  classNames = [],
  children,
  verticalAlign = "top",
  size = "l",
  separator = true,
  dynamicWidth = false,
  overflow = false,
  background = "none",
  backgroundGradientDirection = "left",
  outerPadding = "none"
}: Props) => (
  <div
    className={cn(
      "SplitContentLayout",
      [
        {
          [`background-${background}`]: background,
          [`backgroundGradientDirection-${backgroundGradientDirection}`]: backgroundGradientDirection,
          [`verticalAlign-${verticalAlign}`]: verticalAlign,
          [`outerPadding-${outerPadding}`]: outerPadding,
          [`size-${size}`]: size,
          separator,
          dynamicWidth,
          overflow
        }
      ],
      classNames
    )}
  >
    <div className="SplitContentLayout__column">{children[0]}</div>
    <div className="SplitContentLayout__column">{children[1]}</div>
  </div>
);

export default SplitContentLayout;
