import React from "react";

import ModalContainerWithState from "../../components/ModalContainer/ModalContainerWithState";
import config from "../../config";
import { useKeyboardContext } from "../utils/keyboard-context";

type Props = {
  animationDuration?: number;
} & Omit<React.ComponentProps<typeof ModalContainerWithState>, "animationDuration" | "keyboardOpen">;

const ModalContainerWithStatePartial = ({
  children,
  animationDuration = config.shared.transitionsDurations.default,
  ...props
}: Props) => {
  const { keyboardOpen } = useKeyboardContext();

  return (
    <ModalContainerWithState animationDuration={animationDuration} keyboardOpen={keyboardOpen} {...props}>
      {children}
    </ModalContainerWithState>
  );
};

export default ModalContainerWithStatePartial;
