import React from "react";

import { ActiveFilters, FilterKey } from "../../../../commons/specs/filters";
import { ProductType } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import { getFilterKeysWithInsufficientFilterValues } from "../brand";

type Params<Type extends ProductType> = {
  selectedBrands: Brand<Type>[];
  activeFilters: ActiveFilters<Type>;
};

export default <Type extends ProductType>({ selectedBrands, activeFilters }: Params<Type>): FilterKey<Type>[] => {
  const [filterKeysWithInsufficientFilterValues, setFilterKeysWithInsufficientFilterValues] = React.useState<
    FilterKey<Type>[]
  >([]);

  React.useEffect(() => {
    if (selectedBrands.length > 0) {
      setFilterKeysWithInsufficientFilterValues(
        getFilterKeysWithInsufficientFilterValues(selectedBrands, activeFilters)
      );
    }
  }, [selectedBrands, activeFilters]);

  return filterKeysWithInsufficientFilterValues;
};
