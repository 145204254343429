import { buildPath } from "./libs/path";

type ChildRoutes<
  RouteDefinitions extends Record<string, string>,
  ChildKeyPrefix extends string & `${Uppercase<string>}`
> = {
  [Key in keyof RouteDefinitions as `${ChildKeyPrefix}_${string & Key}`]: string;
};

/**
 * Builds child routes by combining a prefix with the original route keys and applying a parent path.
 *
 * @template RouteDefinitions - A record type where keys are route names and values are route paths.
 * @template ChildKeyPrefix - A string literal type that must be uppercase.
 *
 * @param {RouteDefinitions} routes - An object containing the original routes.
 * @param {ChildKeyPrefix} childKeyPrefix - A prefix to be added to each route key.
 * @param {string} parentPathName - The parent path to be prepended to each route value.
 *
 * @returns {ChildRoutes<RouteDefinitions, ChildKeyPrefix>} An object with new keys (prefix + original key) and values (parent path + original path).
 *
 * @example
 * const NETWORK = {
 *   INDEX: "/network",
 *   SETTINGS: "/network/settings"
 * };
 * const result = buildChildRoutes(NETWORK, "CHILD", "/parent");
 * // Result:
 * // {
 * //   CHILD_INDEX: "/parent/network",
 * //   CHILD_SETTINGS: "/parent/network/settings"
 * // }
 */
function buildChildRoutes<
  RouteDefinitions extends Record<string, string>,
  ChildKeyPrefix extends string & `${Uppercase<string>}`
>(
  routes: RouteDefinitions,
  childKeyPrefix: ChildKeyPrefix,
  parentPathName: string
): ChildRoutes<RouteDefinitions, ChildKeyPrefix> {
  return Object.fromEntries(
    Object.entries(routes).map(([route_key, route_path]) => [
      `${childKeyPrefix}_${route_key}` as const,
      buildPath(route_path, { parent: parentPathName })
    ])
  ) as ChildRoutes<RouteDefinitions, ChildKeyPrefix>;
}

const NETWORK = {
  INDEX: "/:parent/network",
  LAN: "/:parent/network/lan",
  WIFI: "/:parent/network/wifi",
  WIFI_STATUS: "/:parent/network/wifi/status",
  LAN_SETTINGS: "/:parent/network/lan/settings",
  WIFI_SETTINGS: "/:parent/network/wifi/settings",
  CHOOSE: "/:parent/network/choose"
} as const;

const UPDATER = {
  INDEX: "/updater"
} as const;

const SETUP = {
  INDEX: "/setup",
  WELCOME: "/setup/welcome",
  LOCALE: "/setup/locale",
  CODE: "/setup/code",
  STARTUP_TIMES: "/setup/startup-times",
  DONE: "/setup/done",
  BRAND_ACTIVATION: "/setup/brand-activation",
  UPDATER_INDEX: "/setup/updater",
  ...buildChildRoutes(NETWORK, "NETWORK", "setup")
} as const;

const PRINTER = {
  INDEX: "/:parent/printer",
  SETTINGS: "/:parent/printer/settings",
  MAIL_TO_PRINT: "/:parent/printer/mailtoprint"
} as const;

const WAWI = {
  INDEX: "/:parent/wawi",
  TRIDATA_CONNECTION_SETTINGS: "/:parent/wawi/tridata/settings",
  DEBUG: "/:parent/wawi/debug",
  DEBUG_REVERSE: "/:parent/wawi/debug-reverse"
} as const;

const ASSORTMENT = {
  INDEX: "/:parent/assortment",
  EDIT: "/:parent/assortment/edit"
} as const;

const SETTINGS = {
  INDEX: "/settings",
  LOCALE: "/settings/locale",
  STARTUP_TIMES: "/settings/startup-times",
  BRAND_ACTIVATION: "/settings/brand-activation",
  CUSTOMER_WORLD: "/settings/customer-world",
  CUSTOMIZATION: "/settings/customization",
  SUPPORT: "/settings/support",
  VOLUME: "/settings/volume",
  CODE: "/settings/code",
  SCREEN_BRIGHTNESS: "/settings/screen-brightness",
  MOBILE_LICENSE_MANAGER: "/settings/mobile/license-manager",
  MOBILE_PROMOTION: "/settings/mobile/promotion",
  VELOCONNECT: "/settings/veloconnect",
  THIRD_PARTY_ENRA: "/settings/third-party/enra",
  THIRD_PARTY_ADAC: "/settings/third-party/adac",
  THIRD_PARTY_ALTEOS: "/settings/third-party/alteos",
  THIRD_PARTY_BIKMO: "/settings/third-party/bikmo",
  THIRD_PARTY_BODY_SCANNING: "/settings/third-party/body-scanning",
  THIRD_PARTY_ALTEOS_REGISTER: "/settings/third-party/alteos/register",
  THIRD_PARTY_BIKMO_REGISTER: "/settings/third-party/bikmo/register",
  THIRD_PARTY_JOBRAD: "/settings/third-party/jobrad",
  THIRD_PARTY_WUERTH_BIKE_LEASE: "/settings/third-party/wuerth-bike-lease",
  THIRD_PARTY_BC_REMOTE_SHOP: "/settings/third-party/bcRemoteShop",
  THIRD_PARTY_BICOSHOP: "/settings/third-party/bicoshop",
  THIRD_PARTY_WERTGARANTIE: "/settings/third-party/wertgarantie",
  THIRD_PARTY_WERTGARANTIE_REGISTER: "/settings/third-party/wertgarantie/register",
  THIRD_PARTY_LEASE_A_BIKE: "/settings/third-party/lease-a-bike",
  THIRD_PARTY_BUSINESSBIKE: "/settings/third-party/businessbike",
  THIRD_PARTY_EASY_BIKE_LEASING: "/settings/third-party/easy-bike-leasing",
  THIRD_PARTY_BIKELEASING: "/settings/third-party/bikeleasing",
  THIRD_PARTY_LEASE_MY_BIKE: "/settings/third-party/lease-my-bike",
  THIRD_PARTY_DEUTSCHE_DIENSTRAD: "/settings/third-party/deutsche-dienstrad",
  THIRD_PARTY_KAZEN_MAIER: "/settings/third-party/kazen-maier",
  THIRD_PARTY_MEIN_DIENSTRAD: "/settings/third-party/mein-dienstrad",
  THIRD_PARTY_ZEG_PLUS_GARANTIE: "/settings/third-party/zeg-plus-garantie",
  THIRD_PARTY_FIRMENRADL: "/settings/third-party/firmenradl",
  THIRD_PARTY_EURORAD: "/settings/third-party/eurorad",
  THIRD_PARTY_BODYSIZING: "/settings/third-party/bodysizing",
  THIRD_PARTY_BOSCH_PARTS_ADVISER: "/settings/third-party/bosch-parts-adviser",
  THIRD_PARTY_SCHWALBE_PARTS_ADVISER: "/settings/third-party/schwalbe-parts-adviser",
  THIRD_PARTY_SQLAB_PROFILER: "/settings/third-party/sqlab-profiler",
  THIRD_PARTY_SQLAB_PROFILER_REGISTER: "/settings/third-party/sqlab-profiler/register",
  THIRD_PARTY_KTM_CONFIGURATOR: "/settings/third-party/ktm/configurator",
  THIRD_PARTY_KTM_BOOK_A_TESTRIDE: "/settings/third-party/ktm/book-a-testride",
  THIRD_PARTY_KTM_PARTS: "/settings/third-party/ktm/parts",
  THIRD_PARTY_KTM_WEAR: "/settings/third-party/ktm/wear",
  THIRD_PARTY_HUSQVARNA_CONFIGURATOR: "/settings/third-party/husqvarna/configurator",
  THIRD_PARTY_HUSQVARNA_BOOK_A_TESTRIDE: "/settings/third-party/husqvarna/book-a-testride",
  THIRD_PARTY_HUSQVARNA_PARTS: "/settings/third-party/husqvarna/parts",
  THIRD_PARTY_HUSQVARNA_WEAR: "/settings/third-party/husqvarna/wear",
  THIRD_PARTY_GASGAS_BOOK_A_TESTRIDE: "/settings/third-party/gasgas/book-a-testride",
  THIRD_PARTY_GASGAS_CONFIGURATOR: "/settings/third-party/gasgas/configurator",
  THIRD_PARTY_GASGAS_PARTS: "/settings/third-party/gasgas/parts",
  THIRD_PARTY_GASGAS_WEAR: "/settings/third-party/gasgas/wear",
  USER_OPTIONS: "/settings/user-options",
  VIDEO_CONFERENCE: "/settings/video-conference",
  MAIL: "/settings/mail",
  /*
   * Tool Settings
   * Important: we rely on a correct snakecase convention, to check the existence of a specific tool settings page!
   * So a tool with the key my-special-tool must be be key named: MY_SPECIAL_TOOL
   */
  TOOL_SETTINGS: "/settings/tools/:key",
  // Can be removed, when settings navigation is dynamically created.
  TOOL_BIKEPRICE_CALCULATOR: "/settings/tools/bikeprice-calculator",
  TOOL_BIKE_FINANCIAL_CALCULATOR: "/settings/tools/bike-financial-calculator",
  TOOL_BIKE_FRAME_SIZE_CALCULATOR: "/settings/tools/bike-frame-size-calculator",
  ...buildChildRoutes(NETWORK, "NETWORK", "settings"),
  ...buildChildRoutes(PRINTER, "PRINTER", "settings"),
  ...buildChildRoutes(WAWI, "WAWI", "settings"),
  ...buildChildRoutes(ASSORTMENT, "ASSORTMENT", "settings")
} as const;

const NAVIGATION = {
  INDEX: "/",
  ADVICE: "/advice",
  SERVICE: "/service",
  LEASING: "/leasing",
  CONFIGURATORS: "/configurators",
  TESTRIDES: "/testrides",
  PARTS_AND_WEAR: "/parts-and-wear"
} as const;

const PRODUCT_FINDER_OVERVIEW = {
  INDEX: "/products-overview",
  BRANDS: "/products-overview/brands",
  CATEGORIES: "/products-overview/categories",
  DETAILS: "/products-overview/details",
  SEARCH: "/products-overview/search"
} as const;

const PRODUCT_FINDER = {
  INDEX: "/products",
  BRAND: "/products/:brand"
} as const;

const PRODUCT_DETAILS = {
  INDEX: "/product/:id",
  SPECIFICATIONS: "/product/:id/features",
  BOOKMARK: "/product/:id/bookmark",
  BOOKMARK_COMPARE: "/product/:id/bookmark/compare",
  AVAILABILITY: "/product/:id/availability"
} as const;

const TOOL = {
  INDEX: "/tool/:key"
} as const;

const CUSTOMER_WORLD = {
  INDEX: "/customer-world"
} as const;

const BOOKMARKS = {
  INDEX: "/bookmarks",
  DETAIL: "/bookmarks/:id",
  COMPARE: "/bookmarks/:id/compare"
} as const;

const DEVICE_INFORMATION_AND_SUPPORT = {
  INDEX: "/device-information-and-support"
} as const;

const LICENSE = {
  INDEX: "/license/:reason"
} as const;

const BROWSER = {
  INDEX: "/browser"
} as const;

const WAWI_FTP = {
  INDEX: "/:parent/wawi/ftp",
  CONNECTION_TYPE: "/:parent/wawi/ftp/:wawiType/type",
  CONNECTION_SETTINGS: "/:parent/wawi/ftp/:wawiType/settings",
  CREATE_CONNECTION_SETTINGS: "/:parent/wawi/ftp/:wawiType/create"
} as const;

export const ROUTES = {
  NETWORK,
  UPDATER,
  SETUP,
  PRINTER,
  WAWI,
  ASSORTMENT,
  SETTINGS,
  NAVIGATION,
  PRODUCT_FINDER_OVERVIEW,
  PRODUCT_FINDER,
  PRODUCT_DETAILS,
  TOOL,
  CUSTOMER_WORLD,
  BOOKMARKS,
  DEVICE_INFORMATION_AND_SUPPORT,
  LICENSE,
  BROWSER,
  WAWI_FTP
} as const;
