import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallCheck, IconSmallMinus } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  name?: string;
  value?: string;
  label?: React.ReactNode;
  asContextButton?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onDisabledClick?: React.EventHandler<React.MouseEvent<HTMLLabelElement>>;
  multiline?: boolean;
  partiallyChecked?: boolean;
  onChange?: (checked: boolean) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CheckboxField = ({
  classNames = [],
  name = "",
  value = "",
  label = null,
  asContextButton = false,
  checked = false,
  disabled = false,
  multiline = false,
  partiallyChecked = false,
  onChange = noop,
  onDisabledClick = noop,
  onBlur = noop
}: Props) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <label
      onClick={disabled ? onDisabledClick : undefined}
      className={cn(
        "CheckboxField",
        [{ asContextButton, multiline, partiallyChecked, withLabel: Boolean(label) }],
        classNames
      )}
      aria-checked={checked}
      aria-disabled={disabled}
    >
      <input
        className="CheckboxField__input"
        type="checkbox"
        name={name}
        value={value}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
        onBlur={onBlur}
      />
      <div className="CheckboxField__icon">
        <Icon source={partiallyChecked ? IconSmallMinus : IconSmallCheck} />
      </div>
      {label && <div className="CheckboxField__label">{label}</div>}
    </label>
  );
};

export default CheckboxField;
