export const ensureError = (value: unknown): Error => {
  if (value instanceof Error) {
    return value;
  }

  const fallbackError = new Error(value ? String(value) : "Unknown error");

  if (value && typeof value === "object") {
    try {
      return new Error(JSON.stringify(value, null, 2));
    } catch {
      return fallbackError;
    }
  } else {
    return fallbackError;
  }
};
