import React from "react";
import { motion } from "framer-motion";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import { countChildrenWithFragments } from "../../libs/react-utils";

type Gap = "default" | "l";

export type AcceptedColumnsCount = 1 | 2 | 3 | 4;
type ColumnsFunction = (numOfChildren: number) => AcceptedColumnsCount;

interface Props extends Component {
  children: React.ReactNode;
  header?: React.ReactNode;
  columns?: AcceptedColumnsCount | ColumnsFunction;
  gap?: Gap;
  fill?: boolean;
  shadow?: boolean;
  /** @see [TileGrid: Shadow verschiebt die Kacheln vertical : BCD-6603](https://dcd.myjetbrains.com/youtrack/issue/BCD-6603/TileGrid-Shadow-verschiebt-die-Kacheln-vertical) */
  hideShadow?: boolean;
  stickyHeader?: boolean;
  slideIn?: boolean;
  size?: "default" | "s" | "l";
}

const TileGrid = ({
  classNames = [],
  children,
  header = null,
  columns = 4,
  gap = "default",
  fill = false,
  shadow = false,
  hideShadow = false,
  stickyHeader = false,
  slideIn = false,
  size = "default"
}: Props) => {
  const columnsCount: AcceptedColumnsCount =
    typeof columns === "function" ? columns(countChildrenWithFragments(children)) : columns;

  return (
    <div
      className={cn(
        "TileGrid",
        [
          {
            [`columns-${columnsCount}-${size}`]: columnsCount,
            [`columns-used-${Math.min(React.Children.count(children), columnsCount)}`]: true,
            [`gap-${gap}`]: gap,
            shadow,
            hideShadow,
            fill,
            stickyHeader
          }
        ],
        classNames
      )}
    >
      {header && <div className="TileGrid__header">{header}</div>}
      {slideIn &&
        React.Children.map(
          children,
          (child, index) =>
            child && (
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { duration: 0.5, delay: index * 0.075 } }}
              >
                {child}
              </motion.div>
            )
        )}
      {!slideIn && children}
    </div>
  );
};

export default TileGrid;
