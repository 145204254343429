import { datetime, ProductAvailability } from "../libs/externals/veloconnect-proxy";
import { ProductId } from "../specs/product";

import { Currency } from "./currency";
import { WawiProductAvailabilityVariant, WaWiResult } from "./wawi";

export enum AssortmentType {
  Manual = "manual",
  Automatic = "automatic"
}

export interface AssortmentPrice {
  net: number;
  gross: number;
  tax: number;
  currency: Currency;
}

export interface AssortmentPrices {
  rrp: AssortmentPrice | null;
  sales: AssortmentPrice;
  reduced: AssortmentPrice | null;
}
export interface ManualAssortmentItem {
  bikeId: ProductId;
  variantId: ProductId | null;
}

export interface WaWiAssortmentItem extends WaWiResult {
  /**
   * WaWiResult works with productId so the old bikeId isn't needed.
   * For compatiblity the bikeId mirrors the productId
   * @deprecated
   */
  bikeId: ProductId;
  prices: AssortmentPrices | null;
  wawiProductAvailabilityVariant: WawiProductAvailabilityVariant;
}

export type Assortment = (ManualAssortment | AutomaticAssortment | NoAssortment) & {
  lastUpdated: Date | null;
};

export type VeloconnectAssortment = {
  assortment: ProductAvailability[];
  lastUpdated: datetime | null;
};

export interface NoAssortment {
  type: null;
  assortment: [];
}

export interface ManualAssortment {
  type: "manual";
  assortment: ManualAssortmentItem[];
}

export interface AutomaticAssortment {
  type: "automatic";
  assortment: WaWiAssortmentItem[];
}

export type AssortmentItem = ManualAssortmentItem | WaWiAssortmentItem;
