import { compact, get } from "lodash";

import { Product, ProductKey, ProductSpecKey } from "../specs/product";
import { AssortmentType } from "../types/assortment";
import {
  DebugAvailability,
  DebugConnection,
  FtpWawiCredentials,
  GetWawiImporterStatsResponse,
  GetWawiProductAvailabiltyResponse,
  StartWawiImporterResponse,
  SupplierAvailabilityCode,
  TridataWawiCredentials,
  WawiAvailability,
  WawiAvailabilityCode,
  WawiCredentials,
  WawiErrorType,
  WawiImporterStatus,
  WawiProductAvailabilityVariant,
  WaWiResult,
  WawiSettings,
  WawiType
} from "../types/wawi";

import { deleteFromService, getFromService, postToService, ServiceClientHttpError, UrlParams } from "./client-utils";
import { getCurrentAssortment } from "./content-service";
import {
  ErpAvailabilityStatus,
  ProductAvailability,
  ProductAvailabilityWaWi,
  ProductIdentification,
  Status,
  WaWiConnection
} from "./externals/wawi-proxy";
import { parseDate } from "./parser";

export class WawiError extends ServiceClientHttpError {
  static fromServiceErrorOrRethrow(error: unknown): never {
    if (!!error && error instanceof ServiceClientHttpError && error.isServiceError) {
      throw new WawiError(error);
    } else {
      throw error;
    }
  }

  public wawiError: WawiErrorType;

  constructor(error: ServiceClientHttpError) {
    super(error.message, error, error.status, error.stack);

    this.wawiError = WawiError.mapError(error.error);

    this.name = "WawiError";
  }

  private static mapError = (error: string) => {
    const map: { [error: string]: WawiErrorType } = {
      BIKECENTER_HAS_NO_CONNECTION: WawiErrorType.BikecenterHasNoConnection,
      WAWICREDENTIALS_INVALID: WawiErrorType.InvalidCredentials,
      TYPE_NOT_IMPLEMENTED: WawiErrorType.TypeNotImplemented,
      UNAUTHORIZED: WawiErrorType.Unauthorized,
      WAWICREDENTIALS_IN_WRONG_FORMAT: WawiErrorType.WaWiCredentialsInWrongFormat,
      INVALID_PRODUCT_IDENTIFICATION: WawiErrorType.InvalidProductIdentification,
      FTPHOST_NO_OFFICIAL_INSTANCE: WawiErrorType.FtphostNoOfficialInstance
    };
    return map[error] ?? WawiErrorType.Other;
  };
}

export const WawiTypeLabel: { [key in WawiType]: string } = {
  [WawiType.ADVARICS]: "Advarics",
  [WawiType.APP_ROOM_CYCLE]: "app-room: Cycle",
  [WawiType.ASCEND]: "Ascend",
  [WawiType.BIDEX]: "Bidex",
  [WawiType.BIKEARENA_OLTMANNS]: "Bikearena Oltmanns",
  [WawiType.BIKEDESK]: "Bikedesk",
  [WawiType.CSB]: "CSB",
  [WawiType.DOIT7]: "doit!7",
  [WawiType.DUMMY]: "Dummy",
  [WawiType.E_VENDO]: "e\u2011vendo",
  [WawiType.FAHRRAD_XXL_FRANZ]: "Fahrrad XXL Franz",
  [WawiType.FAMOWA]: "famowa",
  [WawiType.FITSTORE_24]: "Fitstore24",
  [WawiType.HIBIKE]: "HIBIKE",
  [WawiType.HIW]: "HIW",
  [WawiType.INTERSPORT_AUSTRIA_COMBINED]: "Intersport Austria",
  [WawiType.INTERSPORT_SAUER]: "Intersport Sauer",
  [WawiType.JTL]: "JTL",
  [WawiType.RADFAK]: "RADFAK",
  [WawiType.SERVI_BIKES]: "ServiBikes",
  [WawiType.TRIDATA]: "Tridata",
  [WawiType.VELODATA]: "Velodata",
  [WawiType.VELOPORT]: "velo.port",
  [WawiType.ZEGWW3]: "ZEGww3"
};

export function getVariantAvailabilities(variant: WaWiResult): WawiProductAvailabilityVariant {
  const availabilityWaWi = variant.results?.[0]?.availabilityWaWi ?? [];
  const availabilityBranches = variant.results?.[0]?.availabilityBranches ?? [];
  const availabilityErp = variant?.results?.[0]?.availabilityErp;

  const getAvailability =
    ({ currentLocation }: { currentLocation: boolean }) =>
    (cur: ProductAvailabilityWaWi): WawiAvailability => ({
      location: cur.location,
      currentLocation,
      productCount: cur.productCount,
      orderedCount: cur.orderedCount,
      orderedForDate: cur.orderedForDate
        ? (parseDate(cur.orderedForDate, "YYYY-MM-DD")?.toDate() ?? undefined)
        : undefined,
      code:
        cur.productCount > 0
          ? WawiAvailabilityCode.Available
          : (cur.orderedCount ?? 0) > 0
            ? WawiAvailabilityCode.Ordered
            : WawiAvailabilityCode.NotAvailable
    });

  const availabilities: WawiAvailability[] = [
    ...availabilityWaWi.map(getAvailability({ currentLocation: true })),
    ...availabilityBranches.map(getAvailability({ currentLocation: false }))
  ];

  const results: ProductAvailability[] = get(variant, "results", []);

  const availabilityMapping: { [key in ErpAvailabilityStatus]: SupplierAvailabilityCode } = {
    [ErpAvailabilityStatus.YES]: SupplierAvailabilityCode.Available,
    [ErpAvailabilityStatus.NO]: SupplierAvailabilityCode.NotAvailable,
    [ErpAvailabilityStatus.ORDERABLE]: SupplierAvailabilityCode.Orderable
  };

  const supplierCode: SupplierAvailabilityCode = availabilityMapping[availabilityErp?.available];
  const expectedDeliveryDate =
    supplierCode === SupplierAvailabilityCode.Orderable
      ? availabilityErp?.orderableFromDate
        ? parseDate(availabilityErp.orderableFromDate, "YYYY-MM-DD")?.toDate()
        : undefined
      : undefined;

  return {
    variantId: variant.variantId,

    itemUnknown: results.length === 0,
    itemAmbiguous: results.length > 1,

    supplier: supplierCode
      ? {
          code: supplierCode,
          expectedDeliveryDate
        }
      : undefined,

    availabilities
  };
}

export const productIdentificationToLookupKey = (productId: ProductIdentification) =>
  `${productId.gtin ?? null}:${productId.productNumber}:${productId.brandKey}`;

const lookupTableCache: {
  updated: Date;
  cache: Record<string, DebugConnection>;
} = {
  updated: new Date(0),
  cache: {}
};

async function getOrCreateLookupTable() {
  const importerStats = await getWawiImporterStats();
  const assortment = await getCurrentAssortment();
  const lastSuccessfulFinishedImport = importerStats.lastSuccessfulImport?.importFinishedTime
    ? new Date(importerStats.lastSuccessfulImport?.importFinishedTime)
    : new Date();
  const lastAssortmentUpdate = assortment.lastUpdated ? new Date(assortment.lastUpdated) : new Date(0);

  if (assortment.type === AssortmentType.Automatic && lastAssortmentUpdate > lastSuccessfulFinishedImport) {
    return (assortment.assortment ?? []).reduce((lookup: Record<string, DebugConnection>, item) => {
      const connection: DebugConnection = {
        value: item.variant[ProductSpecKey.ModelName] ?? null,
        itemAmbiguous: item.results.length > 1,
        itemUnknown: false
      };
      return {
        ...lookup,
        ...item.results.reduce((itemLookup: Record<string, DebugConnection>, productAvailability) => {
          itemLookup[productIdentificationToLookupKey(productAvailability.productId)] = connection;
          return itemLookup;
        }, {})
      };
    }, {});
  } else if (lookupTableCache.updated > lastSuccessfulFinishedImport) {
    return lookupTableCache.cache;
  } else {
    const output = await getFromService("wawi", "availability/lookup");
    lookupTableCache.updated = new Date();
    lookupTableCache.cache = output;
    return output;
  }
}

function lookup(table: Record<string, DebugConnection>, productIdentification: ProductIdentification) {
  return (
    table[productIdentificationToLookupKey(productIdentification)] ?? {
      value: null,
      itemAmbiguous: false,
      itemUnknown: true
    }
  );
}

export async function getImportedAvailabilities(page: number = 0, pageSize: number = 10): Promise<DebugAvailability[]> {
  try {
    const params: UrlParams = [
      { param: "page", value: page },
      { param: "pageSize", value: pageSize }
    ];

    const productAvailabilities: ProductAvailability[] = await getFromService("wawi", "imported", params);
    const lookupTable = await getOrCreateLookupTable();

    const availabilities: DebugAvailability[] = productAvailabilities.map(
      (availability: ProductAvailability): DebugAvailability => ({
        brandNameOrId: availability.productId?.brandKey ?? "",
        modelName: availability.productId?.name ?? "",
        gtin: availability.productId?.gtin ?? "",
        articleNumber: availability.productId?.productNumber ?? "",
        connectionStatus: lookup(lookupTable, availability.productId)
      })
    );

    return availabilities;
  } catch (error) {
    WawiError.fromServiceErrorOrRethrow(error);
  }
}

export async function getWawiProductAvailabilty(product: Product): Promise<GetWawiProductAvailabiltyResponse> {
  return postToService("wawi", "availability", { product })
    .catch(WawiError.fromServiceErrorOrRethrow)
    .then((wawiAvailabilities: WaWiResult[]) => ({
      productId: product[ProductKey.ProductId],
      variants: wawiAvailabilities.map(getVariantAvailabilities)
    }));
}

export async function getDebugAvailabilities(
  brandKey: string,
  queryFilter: string,
  queryString: string,
  limit: number = 1000
): Promise<DebugAvailability[]> {
  const getConnectedWawiResult = (resultVariant: WaWiResult): DebugConnection => ({
    value:
      resultVariant.results?.[0]?.productId?.name ||
      resultVariant.results?.[0]?.productId?.productNumber ||
      resultVariant.results?.[0]?.productId?.gtin ||
      null,
    itemUnknown: (resultVariant.results?.length ?? 0) === 0,
    itemAmbiguous: (resultVariant.results?.length ?? 0) > 1
  });

  const queryParams: UrlParams = compact([
    brandKey && { param: "f:brandKey:eq", value: brandKey },
    queryFilter && queryString && { param: `f:${queryFilter}:con`, value: queryString },
    limit && { param: "limit", value: limit }
  ]);

  const wawiAvailabilities: WaWiResult[] = await getFromService("wawi", "availability", queryParams).catch(
    WawiError.fromServiceErrorOrRethrow
  );

  const debugAvailabilities: DebugAvailability[] = wawiAvailabilities.map((wawiAvailability: WaWiResult) => {
    return {
      brandNameOrId: wawiAvailability?.variant[ProductSpecKey.BrandName] ?? "",
      modelName: wawiAvailability?.variant[ProductSpecKey.ModelName] ?? "",
      gtin: wawiAvailability?.variant[ProductSpecKey.Gtin] ?? "",
      articleNumber: wawiAvailability?.variant[ProductSpecKey.ArticleNumber] ?? "",
      connectionStatus: getConnectedWawiResult(wawiAvailability)
    };
  });

  return debugAvailabilities;
}

export function testWawiProxy(): Promise<Status> {
  return postToService("wawi", "test").catch(WawiError.fromServiceErrorOrRethrow);
}

export function getWawiConnectionInfo(): Promise<Partial<WawiSettings>> {
  return getFromService("wawi", "connection-info").catch(WawiError.fromServiceErrorOrRethrow);
}

export async function getWawiConnection(): Promise<WaWiConnection> {
  return getFromService("wawi", "connection").catch(WawiError.fromServiceErrorOrRethrow);
}

export async function createWawiConnection(
  wawiType: WawiType,
  wawiCredentials?: WawiCredentials
): Promise<WaWiConnection> {
  return postToService("wawi", "connection", {
    wawiType,
    wawiCredentials
  }).catch(WawiError.fromServiceErrorOrRethrow);
}

export function isFtpWawiCredentials(wawiCredentials: unknown): wawiCredentials is FtpWawiCredentials {
  return (
    !!wawiCredentials &&
    typeof wawiCredentials === "object" &&
    "ftphost" in wawiCredentials &&
    typeof wawiCredentials["ftphost"] === "string" &&
    "username" in wawiCredentials &&
    typeof wawiCredentials["username"] === "string" &&
    "password" in wawiCredentials &&
    typeof wawiCredentials["password"] === "string"
  );
}

export function isTridataCredentials(wawiCredentials: unknown): wawiCredentials is TridataWawiCredentials {
  return (
    !!wawiCredentials &&
    typeof wawiCredentials === "object" &&
    "dealerId" in wawiCredentials &&
    typeof wawiCredentials["dealerId"] === "string"
  );
}

export function deleteWawiConnection(): Promise<Status> {
  return deleteFromService("wawi", "connection").catch(WawiError.fromServiceErrorOrRethrow);
}

export function startWawiImporter(): Promise<StartWawiImporterResponse> {
  return postToService("wawi", "importer")
    .then((response: Status) => ({
      status:
        response.message === "IMPORT_ACCEPTED"
          ? WawiImporterStatus.ImportRunning
          : response.message === "IMPORT_STARTED"
            ? WawiImporterStatus.ImportStarted
            : WawiImporterStatus.ImportFailed,
      details: {
        id: get(response, "details.id")
      }
    }))
    .catch(WawiError.fromServiceErrorOrRethrow);
}

export function getWawiImporterStats(): Promise<GetWawiImporterStatsResponse> {
  return getFromService("wawi", "importer").catch(WawiError.fromServiceErrorOrRethrow);
}

export async function resetWawiConnection(): Promise<void> {
  await deleteFromService("wawi", "/").catch(WawiError.fromServiceErrorOrRethrow);
}
