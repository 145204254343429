import type { BrowserOptions, Scope as BrowserScope, SeverityLevel as BrowserSeverityLevel } from "@sentry/browser";
import type {
  ElectronMainOptions,
  Scope as ElectronScope,
  SeverityLevel as ElectronSeverityLevel
} from "@sentry/electron/main";
import type { NodeOptions, Scope as NodeScope, SeverityLevel as NodeSeverityLevel } from "@sentry/node";

import { getServiceTag } from "./env-service";

const FALLBACK_USERNAME = "UNKNOWN";

export type SentryOptions = NodeOptions | ElectronMainOptions | BrowserOptions;

export interface Sentry {
  init(options?: SentryOptions): void;
  getCurrentScope(): BrowserScope | ElectronScope | NodeScope;
  captureException(exception: any): string;
  captureMessage(message: string, level?: NodeSeverityLevel | ElectronSeverityLevel | BrowserSeverityLevel): string;
}

export const initSentry =
  (Sentry: Sentry) =>
  (options: SentryOptions): void => {
    Sentry.init(options);
  };

const configureScope = (Sentry: Sentry, username: string): void => {
  console.log(`Configure Sentry scope with user ${username}`);
  Sentry.getCurrentScope().setUser({ username });
};

export const captureException =
  (Sentry: Sentry) =>
  (error: Error): string => {
    return Sentry.captureException(error);
  };

const getSentryUsername = async (): Promise<string> => {
  try {
    return await getServiceTag();
  } catch (error) {
    console.error("Couldn't configure serviceTag as Sentry username. Will use fallback instead.", error);
    return FALLBACK_USERNAME;
  }
};

export const configureServiceTagAsUsername = (Sentry: Sentry) => async (): Promise<string> => {
  const username = await getSentryUsername();

  configureScope(Sentry, username);
  return Sentry.captureMessage("Initialized Sentry and set username");
};
