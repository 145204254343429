import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  imageSrc?: string;
  footer?: React.ReactNode;
}

const DistributedContent = ({ classNames = [], children, imageSrc = "", footer = null }: Props) => (
  <div className={cn("DistributedContent", [], classNames)}>
    {!!imageSrc && (
      <div className="DistributedContent__image">
        <img src={imageSrc} />
      </div>
    )}
    <div className="DistributedContent__children">{children}</div>
    {footer && <div className="DistributedContent__footer">{footer}</div>}
  </div>
);

export default DistributedContent;
