import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

type Variant = "primary" | "neutral" | "accent" | "positive" | "negative" | "warning";
type Size = "default" | "s" | "inherit";

interface Props extends Component {
  label: React.ReactNode;
  icon?: React.ReactElement<React.ComponentProps<typeof Icon>>;
  variant?: Variant;
  size?: Size;
  triangle?: boolean;
}

const Badge = ({ classNames = [], label, icon, variant = "neutral", size = "default", triangle = false }: Props) => (
  <div className={cn("Badge", [{ [`size-${size}`]: size, [`variant-${variant}`]: variant, triangle }], classNames)}>
    {icon}
    {label}
  </div>
);

export default Badge;
