import { useEffect, useRef, useState } from "react";
import { noop } from "lodash";

import { makeCancelablePromise } from "../../../../commons/libs/promise";

export interface FetchState<Data = unknown> {
  isLoading: boolean;
  data?: Data;
  error?: Error;
}

interface UseFetchDataOptions<Data = unknown> {
  handleError?: (error: Error) => void;
  onDone?: (data: Data) => void;
  isEnabled?: boolean;
}

export default <Data = unknown>(
  handleFetch: () => Promise<Data>,
  deps: unknown[] = [],
  options: UseFetchDataOptions<Data> = {}
) => {
  const { handleError = noop, onDone = noop, isEnabled = true } = options;
  const currentCancel = useRef<() => void>();
  const [state, setState] = useState<FetchState<Data>>({
    isLoading: isEnabled
  });

  const cancelLastFetch = () => {
    if (currentCancel.current) {
      currentCancel.current();
      currentCancel.current = undefined;
    }
  };

  const fetch = () => {
    if (!isEnabled) {
      return;
    }

    cancelLastFetch();

    const { promise, cancel } = makeCancelablePromise<Data>(handleFetch());
    currentCancel.current = cancel;

    setState(prevState => ({
      ...prevState,
      isLoading: true
    }));

    promise
      .then(data => {
        setState(prevState => {
          // delete error after succesfull retried fetch
          if (prevState.error) {
            delete prevState.error;
          }

          return {
            ...prevState,
            isLoading: false,
            data
          };
        });

        onDone(data);
      })
      .catch(error => {
        handleError(error);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          data: undefined,
          error
        }));
      });
  };

  // Trigger effect only when deps or isEnabled changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetch, [...deps, isEnabled]);
  useEffect(() => () => cancelLastFetch(), []);

  return {
    ...state,
    refresh: fetch
  };
};
