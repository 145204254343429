import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import ToggleField from "../ToggleField/ToggleField";

type Alignment = "default" | "left" | "right";

type LabelSize = "base" | "s";

interface Props extends Component {
  children: React.ReactElement<React.ComponentProps<typeof ToggleField>>;
  label: string;
  checked?: boolean;
  align?: Alignment;
  labelSize?: LabelSize;
}

const SingleLabeledToggleField = ({
  classNames = [],
  children,
  label,
  checked = false,
  align = "default",
  labelSize = "base"
}: Props) => (
  <div
    className={cn(
      "SingleLabeledToggleField",
      [{ checked, [`align-${align}`]: !!align, [`label-size-${labelSize}`]: labelSize }],
      classNames
    )}
  >
    <div className="SingleLabeledToggleField__label">{label}</div>
    <div className="SingleLabeledToggleField__children">{children}</div>
  </div>
);

export default SingleLabeledToggleField;
