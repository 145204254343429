import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Headline from "../Headline/Headline";

interface Props extends Component {
  children: React.ReactNode;
  headline: React.ReactNode;
  badge?: React.ReactNode;
  actions?: React.ReactNode;
  active?: boolean;
}

const Card = ({ classNames = [], children, headline, badge = null, actions = null, active = false }: Props) => (
  <div className={cn("Card", [{ active }], classNames)}>
    <div className="Card__header">
      {badge}
      <div className="Card__headline">
        <Headline>{headline}</Headline>
      </div>
    </div>
    <div className="Card__content">{children}</div>
    {actions && <div className="Card__actions">{actions}</div>}
  </div>
);

export default Card;
