// Keep these types in prioritized order
export enum AvailabilityType {
  Full = "full",
  Partial = "partial",
  No = "no",
  Error = "error",
  Unknown = "unknown"
}

// Keep these states in sync with `variantsContentPartialProductDetails.json#availabilityStatus`
export enum AvailabilityStatus {
  NoAvailabilityData = "noAvailabilityData",
  Available = "available",
  AvailableFrom = "availableFrom",
  AvailableSince = "availableSince",
  FutureAvailable = "futureAvailable",
  OrderedTo = "ordererdTo",
  Ordered = "ordered",
  PartialAvailable = "partiallyAvailable",
  NotDeliverable = "notDeliverable",
  InStock = "inStock",
  AvailableOtherWarehouse = "availableOtherWarehouse",
  NotInStock = "notInStock",
  NotAvailable = "notAvailable",
  WarehouseLocation = "warehouseLocation"
}

export interface AvailabilityState {
  type: AvailabilityType;
  status: AvailabilityStatus;
  itemUnknown?: boolean;
  expectedDeliveryDate?: Date;
  warehouseLocation?: string;
}

export interface AvailabilityStates {
  /** In stock or available at supplier, hence product is orderable. */
  availableAtSupplier: AvailabilityState | false;
  /** To-be in stock at supplier in (near) future, hence product is will be orderable. */
  soonAvailableAtSupplier: AvailabilityState | false;
  /** Strange Veloconnect case which means it could be available. */
  partiallyAvailableAtSupplier: AvailabilityState | false;
  /** Not available or in stock at supplier. */
  notAvailableAtSupplier: AvailabilityState | false;
  /** Ordered on-site or off-site. */
  orderIncoming: AvailabilityState | false;
  /** In stock on-site. */
  inStock: AvailabilityState | false;
  /** In stock off-site at another location. */
  inStockElsewhere: AvailabilityState | false;
  /** Not in stock on-site. */
  notInStock: AvailabilityState | false;
  /** Not in stock neither on-site, off-site, nor at supplier. */
  notAvailable: AvailabilityState | false;
  /** Unknown availability state. */
  unknown: AvailabilityState | false;
}

export enum AutomaticAssortmentAvailabilityState {
  InStock = "inStock",
  InStockElsewhere = "inStockElsewhere",
  Ordered = "ordered",
  NotAvailable = "notAvailable",
  AvailableAtSupplier = "availableAtSupplier",
  Unknown = "unknown"
}

export enum ManualAssortmentAvailabilityState {
  Current = "current",
  Remaining = "remaining"
}

export type AvailabilityFilterLocation = "dealer" | "supplier" | "everywhere";
