import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

type Spacing = "s" | "base" | "l";
type VerticalAlignment = "start" | "center";

interface Props extends Component {
  children: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactElement<typeof Icon>;
  kind?: "default" | "warning" | "negative";
  spacing?: Spacing;
  verticalAlignment?: VerticalAlignment;
  withShadow?: boolean;
  rounding?: "base" | "l";
}

const Note = ({
  children = null,
  icon,
  footer = null,
  classNames = [],
  kind = "default",
  spacing = "base",
  verticalAlignment = "start",
  withShadow = false,
  rounding = "base"
}: Props) => {
  const modifiers = {
    [`kind-${kind}`]: kind,
    [`spacing-${spacing}`]: spacing,
    [`verticalAlignment-${verticalAlignment}`]: verticalAlignment,
    [`rounding-${rounding}`]: rounding,
    withShadow
  };

  return (
    <div className={cn("Note", [modifiers], classNames)}>
      {!!icon && <div className="Note__icon">{icon}</div>}

      <div className="Note__content">
        <div className="Note__children">{children}</div>
        {!!footer && <div className="Note__footer">{footer}</div>}
      </div>
    </div>
  );
};

export default Note;
