import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import BrandTile from "../BrandTile/BrandTile";

interface Props extends Component {
  frontSide: (flip: () => void, isFlipped: boolean) => React.ReactElement<React.ComponentProps<typeof BrandTile>>;
  backSide: (flip: () => void, isFlipped: boolean) => React.ReactElement<React.ComponentProps<typeof BrandTile>>;
  height?: "default" | "l" | "xl";
}

const FlippableTile = ({ classNames = [], frontSide, backSide, height = "default" }: Props) => {
  const [isFlipped, flip] = React.useState(false);
  const toggleFlip = () => flip(value => !value);

  return (
    <div className={cn("FlippableTile", [{ isFlipped, [`height-${height}`]: height }], classNames)}>
      <div className="FlippableTile__frontSide">{frontSide(toggleFlip, isFlipped)}</div>
      <div className="FlippableTile__backSide">{backSide(toggleFlip, isFlipped)}</div>
    </div>
  );
};

export default FlippableTile;
