import envelope from "../../commons/libs/externals/envelope";

const SIZES = ["72x72", "96x96", "128x128", "144x144", "152x152", "192x192", "384x384", "512x512"];

const generateIcon = (size: string) => ({
  src: `${envelope.mobileServerUrl}/static/icons/icon-${size}.png`,
  sizes: size,
  type: "image/png"
});

export const generateManifest = (queryParams?: string): Record<string, unknown> => ({
  name: "BikeCenter Mobile",
  short_name: "BikeCenter",
  background_color: "#ffffff",
  theme_color: "#000000",
  start_url: `${envelope.mobileServerUrl}/${queryParams ?? ""}`,
  dir: "ltr",
  display: "fullscreen",
  orientation: "landscape",
  icons: SIZES.map(generateIcon),
  prefer_related_applications: false
});

export const injectManifest = (manifest: Record<string, unknown>) => {
  const stringifiedManifest = JSON.stringify(manifest);
  const blob = new Blob([stringifiedManifest], {
    type: "application/json"
  });
  const manifestUrl = URL.createObjectURL(blob);
  document.querySelector("#manifest")?.setAttribute("href", manifestUrl);
};
