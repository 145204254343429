import { BadLicenseReason } from "../../../commons/types/license";
import { Action } from "../../../commons/types/redux";
import { ExternalToolStatus } from "../../../commons/types/tool";
import { VideoConferenceSession } from "../libs/externals/video-conference";
import { PrinterStatusResponse } from "../libs/services/print-service/print";

import { Actions as AnyAction } from ".";

export type UpdateAndRestartAction = Action<"KIOSK_APP.UPDATE_AND_RESTART">;
export type SetupDoneAction = Action<"KIOSK_APP.SETUP_DONE">;
export type RestartSoftwareAction = Action<"KIOSK_APP.RESTART_SOFTWARE">;
export type RestartSoftwareAbortAction = Action<"KIOSK_APP.RESTART_SOFTWARE_ABORT">;
export type FinishedUpdateAction = Action<"KIOSK_APP.FINISHED_UPDATE">;

export type InitCheckLicenseAction = Action<"KIOSK_APP.INIT_CHECK_LICENSE">;
export type CheckLicenseAction = Action<"KIOSK_APP.CHECK_LICENSE", { successAction?: AnyAction }>;
export type BadLicenseReasonReceivedAction = Action<"KIOSK_APP.BAD_LICENSE_REASON_RECEIVED", BadLicenseReason>;
export type PrinterStatusReceivedAction = Action<"KIOSK_APP.PRINTER_STATUS_RECEIVED", PrinterStatusResponse>;
export type ExternalToolTestedAction = Action<"KIOSK_APP.EXTERNAL_TOOL_TESTED", ExternalToolStatus>;

export type VideoConferenceStartedAction = Action<"KIOSK_APP.VIDEO_CONFERENCE_STARTED", VideoConferenceSession>;
export type StopVideoConferenceAction = Action<"KIOSK_APP.STOP_VIDEO_CONFERENCE">;

export type SetCursorHighlightAction = Action<"KIOSK_APP.SET_CURSOR_HIGHLIGHT", boolean>;

export type KioskAppActions =
  | UpdateAndRestartAction
  | RestartSoftwareAction
  | FinishedUpdateAction
  | RestartSoftwareAbortAction
  | PrinterStatusReceivedAction
  | CheckLicenseAction
  | InitCheckLicenseAction
  | BadLicenseReasonReceivedAction
  | ExternalToolTestedAction
  | VideoConferenceStartedAction
  | StopVideoConferenceAction
  | SetCursorHighlightAction;

export const updateAndRestart = (): UpdateAndRestartAction => ({
  type: "KIOSK_APP.UPDATE_AND_RESTART"
});

export const restartSoftware = (): RestartSoftwareAction => ({
  type: "KIOSK_APP.RESTART_SOFTWARE"
});

export const restartSoftwareAbort = (): RestartSoftwareAbortAction => ({
  type: "KIOSK_APP.RESTART_SOFTWARE_ABORT"
});

export const finishedUpdate = (): FinishedUpdateAction => ({
  type: "KIOSK_APP.FINISHED_UPDATE"
});

export const printerStatusReceived = (payload: PrinterStatusResponse): PrinterStatusReceivedAction => ({
  type: "KIOSK_APP.PRINTER_STATUS_RECEIVED",
  payload
});

export const initCheckLicense = (): InitCheckLicenseAction => ({
  type: "KIOSK_APP.INIT_CHECK_LICENSE"
});

export const checkLicense = (successAction?: AnyAction): CheckLicenseAction => ({
  type: "KIOSK_APP.CHECK_LICENSE",
  payload: {
    successAction
  }
});

export const badLicenseReasonReceived = (payload: BadLicenseReason): BadLicenseReasonReceivedAction => ({
  type: "KIOSK_APP.BAD_LICENSE_REASON_RECEIVED",
  payload
});

export const externalToolTested = (toolStatus: ExternalToolStatus): ExternalToolTestedAction => ({
  type: "KIOSK_APP.EXTERNAL_TOOL_TESTED",
  payload: toolStatus
});

export const videoConferenceStarted = (session: VideoConferenceSession): VideoConferenceStartedAction => ({
  type: "KIOSK_APP.VIDEO_CONFERENCE_STARTED",
  payload: session
});

export const stopVideoConference = (): StopVideoConferenceAction => ({
  type: "KIOSK_APP.STOP_VIDEO_CONFERENCE"
});

export const setCursorHighlight = (showCursorHighlight: boolean): SetCursorHighlightAction => ({
  type: "KIOSK_APP.SET_CURSOR_HIGHLIGHT",
  payload: showCursorHighlight
});
