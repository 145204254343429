import { ActiveFilters } from "../../../commons/specs/filters";
import { ProductType } from "../../../commons/specs/product";
import { AvailabilityFilterLocation } from "../../../commons/types/availability";
import { Action } from "../../../commons/types/redux";
import { SizingBodyValues } from "../../../commons/types/sizing";

export type Reset = Action<"SESSION.RESET">;

export type SetActiveFilters<Type extends ProductType = ProductType> = Action<
  "SESSION.SET_ACTIVE_FILTERS",
  { activeFilters: ActiveFilters<Type> }
>;
export type ResetActiveFilters = Action<"SESSION.RESET_ACTIVE_FILTERS">;

export type SetBodyValues = Action<"SESSION.SET_BODY_VALUES", SizingBodyValues>;

export type HideProductDetailsZoomNotice = Action<"SESSION.HIDE_PRODUCT_DETAILS_ZOOM_NOTICE">;

export type SetIsSupplierFilterEnabled = Action<"SESSION.SET_IS_SUPPLIER_FILTER_ENABLED", boolean>;
export type SetAvailabilityFilterLocation = Action<
  "SESSION.SET_AVAILABILITY_FILTER_LOCATION",
  AvailabilityFilterLocation
>;

export type SessionActions =
  | Reset
  | SetActiveFilters
  | ResetActiveFilters
  | SetBodyValues
  | HideProductDetailsZoomNotice
  | SetIsSupplierFilterEnabled
  | SetAvailabilityFilterLocation;

export const reset = (): Reset => ({
  type: "SESSION.RESET"
});

export const setActiveFilters = <Type extends ProductType>(
  activeFilters: ActiveFilters<Type>
): SetActiveFilters<Type> => ({
  type: "SESSION.SET_ACTIVE_FILTERS",
  payload: { activeFilters }
});

export const resetActiveFilters = (): ResetActiveFilters => ({
  type: "SESSION.RESET_ACTIVE_FILTERS"
});

export const setBodyValues = (bodyValues: SizingBodyValues): SetBodyValues => ({
  type: "SESSION.SET_BODY_VALUES",
  payload: bodyValues
});

export const hideProductDetailsZoomNotice = (): HideProductDetailsZoomNotice => ({
  type: "SESSION.HIDE_PRODUCT_DETAILS_ZOOM_NOTICE"
});

export const setIsSupplierFilterEnabled = (isEnabled: boolean): SetIsSupplierFilterEnabled => ({
  type: "SESSION.SET_IS_SUPPLIER_FILTER_ENABLED",
  payload: isEnabled
});

export const setAvailabilityFilterLocation = (location: AvailabilityFilterLocation): SetAvailabilityFilterLocation => ({
  type: "SESSION.SET_AVAILABILITY_FILTER_LOCATION",
  payload: location
});
