import { useEffect, useState } from "react";

export const useDebounceArray = <T extends Iterable<unknown>>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // Disabled for spread values only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...value, delay]);
  return debouncedValue;
};

const useDebounce = <T>(value: T, delay: number) => useDebounceArray([value], delay)[0];

export default useDebounce;
