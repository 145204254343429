// eslint-disable-next-line no-restricted-imports
import { useDispatch as useOriginalDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";

import { Actions } from "../../actions";
import { MapResultToPromise } from "../../container/utils/loop";

export type AppDispatch = MapResultToPromise<Dispatch<Actions | AnyAction>>;

// Use throughout the app instead of plain `useDispatch`
export default () => useOriginalDispatch<AppDispatch>();
