import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  gap?: "base" | "xxl";
}

const MultiColumnItemList = ({ classNames = [], children, gap = "base" }: Props) => (
  <div className={cn("MultiColumnItemList", [{ [`gap-${gap}`]: !!gap }], classNames)}>
    {React.Children.map(children, (child, index) => (
      <div className="MultiColumnItemList__column" key={index}>
        {child}
      </div>
    ))}
  </div>
);

export default MultiColumnItemList;
