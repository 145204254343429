import { MobileConfig, SupportedBrowser } from "./config-types";

const mobileConfig: MobileConfig = {
  mobile: {
    allowPortraitMode: false,
    minWidth: 768,
    minHeight: 576,
    supportedOperatingSystems: [SupportedBrowser.Android, SupportedBrowser.Ios],

    // Sentry
    sentry: {
      dsn: "https://1a11060853fc4d3898bdc6b014375812@sentry.bike.center/8"
    },

    // Mixpanel tracking config
    mixpanel: {
      token: {
        dev: "1b49cc405933daf2c8d7d2ceb7a00545",
        prod: "98537099b2a1b5b99b78806bc4c82fe1"
      },
      sessionTimeout: 5 * 60 * 1000
    }
  }
};

export default mobileConfig;
