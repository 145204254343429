import { Action } from "redux";

import { Actions } from "../actions";
import * as toastsActions from "../actions/toasts";
import { ToastProperties } from "../actions/toasts";
import { ToastAlignment } from "../components/ToastLayout/ToastLayout";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";

import { State } from "./";

const NAME = "toasts";

export interface ToastsState {
  [NAME]: {
    toasts: ToastProperties[];
    toastAlignment: ToastAlignment;
  };
}

export const initialState: ToastsState = {
  [NAME]: {
    toasts: [],
    toastAlignment: "center"
  }
};

type ToastsInnerReducer<A extends Action = Actions> = InnerReducer<State, ToastsState[typeof NAME], A>;

const handleShowToast: ToastsInnerReducer<toastsActions.ShowToastAction> = (state, action) => ({
  ...state[NAME],
  toasts: [...state[NAME].toasts.filter(({ id }) => id !== action.payload.id), action.payload]
});

const handleHideToast: ToastsInnerReducer<toastsActions.HideToastAction> = (state, action) => ({
  ...state[NAME],
  toasts: state[NAME].toasts.filter(({ id }) => id !== action.payload.id)
});

const handleSetToastAlignment: ToastsInnerReducer<toastsActions.SetToastAlignmentAction> = (state, action) => ({
  ...state[NAME],
  ...action.payload
});

const reducer: InnerReducer<State, ToastsState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "TOASTS.SHOW_TOAST":
      return handleShowToast(state, action);
    case "TOASTS.HIDE_TOAST":
      return handleHideToast(state, action);
    case "TOASTS.SET_TOAST_ALIGNMENT":
      return handleSetToastAlignment(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
