import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type TextAlign = "left" | "center" | "right";

interface Props extends Component {
  children?: React.ReactNode;
  textAlign?: TextAlign;
  inContent?: boolean;
  noStickyHeader?: boolean;
  // `width`/`fixed` props are used by parent Table component
  width?: number; // eslint-disable-line react-redux/no-unused-prop-types
  fixed?: boolean; // eslint-disable-line react-redux/no-unused-prop-types
  computedWidth?: string; // Injected by parent
}

const TableHeaderCell = ({
  classNames = [],
  children = null,
  textAlign = "left",
  inContent = false,
  noStickyHeader = false,
  computedWidth = ""
}: Props) => (
  <th
    className={cn(
      "TableHeaderCell",
      [
        {
          [`text-align-${textAlign}`]: textAlign,
          inContent,
          noStickyHeader
        }
      ],
      classNames
    )}
    style={{
      width: computedWidth
    }}
  >
    {children}
  </th>
);

export default TableHeaderCell;
