import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallArrowRight } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const SelectCtaButton = ({ classNames = [], children, disabled = false, onClick = noop }: Props) => (
  <button disabled={disabled} className={cn("SelectCtaButton", [], classNames)} onClick={onClick}>
    <div className="SelectCtaButton__children">{children}</div>
    <Icon source={IconSmallArrowRight} />
  </button>
);

export default SelectCtaButton;
