import { languages } from "./libs/i18n";
import { Language, Locale, Region } from "./types/localization";

const i18nConfig = {
  languages,
  defaultLanguage: Language.En,
  defaultRegion: Region.De,
  defaultLocale: Locale.EnDe,
  commonNamespace: "commons",
  systemServiceNamespace: "system-services"
} as const;

export type I18NConfig = typeof i18nConfig;

export default i18nConfig;
