import React from "react";

import { RangeFilterValue } from "../../../../commons/specs/filters";
import RangeField from "../../components/RangeField/RangeField";
import config from "../../config";
import useDebouncedCallback from "../../libs/hooks/use-debounced-callback";

interface Props {
  disabled: boolean;
  min: number;
  max: number;
  values: RangeFilterValue;
  onChange: (values: RangeFilterValue) => void;
}

const RangeFilterModalPartial = ({ disabled, min, max, values, onChange }: Props) => {
  const [rangeSliderValues, setRangeSliderValues] = React.useState<RangeFilterValue>(values);

  const debouncedOnChange = useDebouncedCallback(onChange, config.shared.filter.filterFetchDelay, { trailing: true });

  const handleRangeToChange: React.ComponentProps<typeof RangeField>["onChange"] = values => {
    if (Array.isArray(values)) {
      const [from, to] = values;
      debouncedOnChange([from, to]);
      setRangeSliderValues([from, to]);
    }
  };

  return (
    <RangeField
      disabled={disabled}
      min={min}
      max={max}
      value={rangeSliderValues}
      onChange={handleRangeToChange}
      labelPosition="outside"
    />
  );
};

export default RangeFilterModalPartial;
