import { compact, noop } from "lodash";
import * as R from "ramda";

import { BicycleProduct } from "../specs/bicycle";
import { FilterKey, ProductFilter, ProductFilterValues } from "../specs/filters";
import { Product, ProductId, ProductType } from "../specs/product";
import { Assortment, ManualAssortmentItem, VeloconnectAssortment, WaWiAssortmentItem } from "../types/assortment";
import { Brand } from "../types/brand";
import { PackageInfo, PackageUpgrades } from "../types/content";
import { Currency } from "../types/currency";
import { Message, StatusCode } from "../types/intercom";
import { AssortmentPriceSettings } from "../types/settings";

import { deleteFromService, getFromService, postToService, UrlParams } from "./client-utils";
import { isProgressMessage } from "./intercom";
import { retryPromise, timeoutPromiseWithAbortSignal } from "./promise";
import { timeoutWithStatusKeepalive } from "./service-status-service";

interface Pagination {
  total: number;
  totalFiltered: number;
  offset: number | null;
  limit: number | null;
}

interface Errors {
  [key: string]: string;
  _error: string;
}

export interface ProductsResponse<Type extends ProductType = ProductType> extends Pagination {
  errors?: Errors;
  results: Product<Type>[];
}

export interface BikesResponse extends Pagination {
  errors?: Errors;
  results: BicycleProduct[];
}

export async function checkAvailableUpdates(
  retryOptions: {
    requestTimeout: number;
    retryCount: number;
    retryDelay: number;
    backoffFactor: number;
  } = { requestTimeout: 5 * 60_000, retryCount: 3, retryDelay: 500, backoffFactor: 1.0 }
): Promise<PackageUpgrades> {
  const { requestTimeout, retryCount, retryDelay, backoffFactor } = retryOptions;
  const fn = (signal: AbortSignal) => getFromService("content", "refresh", undefined, { requestInit: { signal } });
  const timedFn = () => timeoutPromiseWithAbortSignal<PackageUpgrades>(fn, requestTimeout);
  return retryPromise(timedFn, retryCount, retryDelay, backoffFactor);
}

function parsePkgNamesFromMessage(msg: Message): string[] {
  const text = (msg && msg.text) || "";
  const match = text.match(/\[(.+)]/) || [];
  const packages = String(match[1] || "").trim();
  return packages.length === 0 ? [] : packages.split(",").map(R.trim);
}

export function downloadAvailableUpdates(packageUpgrades: PackageUpgrades): Promise<string[]> {
  return timeoutWithStatusKeepalive("content", (resolve, reject, resetTimeout) => {
    postToService("content", "download", packageUpgrades)
      .then(resolve) // resolve fast installations as soon as possible
      .catch(noop); // ignore request timeout when triggering long-running installations

    return (msg: Message) => {
      if (msg.status === StatusCode.Error) {
        reject(msg);
      } else if (msg.status === StatusCode.AllContentDownloaded) {
        resolve(parsePkgNamesFromMessage(msg));
      } else if (isProgressMessage(msg) || msg.status === StatusCode.Busy) {
        resetTimeout();
      }
    };
  });
}

export async function upgradeContentPackages(packageUpgrades: PackageUpgrades): Promise<string[]> {
  return timeoutWithStatusKeepalive("content", (resolve, reject, resetTimeout) => {
    postToService("content", "upgradeAll", packageUpgrades)
      .then(resolve) // resolve small downloads as soon as possible
      .catch(noop); // ignore request timeout when triggering big downloads

    return (msg: Message) => {
      if (msg.status === StatusCode.AllContentInstalled) {
        resolve(parsePkgNamesFromMessage(msg));
      } else if (msg.status === StatusCode.Error) {
        reject(msg);
      } else {
        resetTimeout();
      }
    };
  });
}

export function getAvailableBrands(): Promise<Brand[]> {
  return getFromService("content", "brands/available").catch(() => []);
}

export async function getActiveBrands(): Promise<Brand[]> {
  return getFromService("content", "brands").catch(() => []);
}

/**
 * Assure that all `availableModelYears` for a Brand are handled.
 * `availableModelYears` which are not declared as active- or inactive, are put into the `inactiveModelYears` array.
 * @param brand {Brand} The brand to ensure
 * @returns {Brand} The updated brand
 */
export const assureAllAvailableModelYearsHandled = (brand: Brand): Brand => {
  const availableModelYears = brand.availableModelYears ?? [];
  const modelYears = brand.modelYears ?? [];
  const inactiveModelYears = brand.inactiveModelYears ?? [];

  const isUnhandled = (modelYear: number) => !modelYears.includes(modelYear) && !inactiveModelYears.includes(modelYear);

  const unhandledModelYears: number[] = availableModelYears.filter(isUnhandled);

  return {
    ...brand,
    modelYears,
    inactiveModelYears: [...inactiveModelYears, ...unhandledModelYears]
  };
};

export async function addBrand(brand: Brand): Promise<Brand[]> {
  const { key, modelYears, inactiveModelYears } = assureAllAvailableModelYearsHandled(brand);
  const highlighted = brand.highlighted ?? false; // Ensure fallback for `highlighted`

  await postToService("content", "brands", { key, modelYears, inactiveModelYears, highlighted });
  return getActiveBrands();
}

export async function addBrands(brands: Brand[]): Promise<Brand[]> {
  await brands.reduce(async (accPromise: Promise<void>, brand: Brand) => {
    await accPromise;

    await addBrand(brand);
  }, Promise.resolve());

  const activeBrands: Brand[] = await getActiveBrands();

  return activeBrands;
}

export async function removeBrand(key: string): Promise<Brand[]> {
  await deleteFromService("content", `brands/${key}`);
  return getActiveBrands();
}

export async function getProduct<Type extends ProductType = ProductType>(
  productId: ProductId,
  currency: Currency,
  assortmentPriceSettings: AssortmentPriceSettings,
  filter?: ProductFilter[]
): Promise<Product<Type>> {
  const queryParams: UrlParams = compact([
    ...createFilterParams(filter),
    currency && { param: "currency", value: currency },
    assortmentPriceSettings.showAssortmentPrices && {
      param: "showAssortmentPrices",
      value: assortmentPriceSettings.showAssortmentPrices
    },
    assortmentPriceSettings.showFallbackPrices && {
      param: "showFallbackPrices",
      value: assortmentPriceSettings.showFallbackPrices
    },
    assortmentPriceSettings.useRrpAsOriginalPrice && {
      param: "useRrpAsOriginalPrice",
      value: assortmentPriceSettings.useRrpAsOriginalPrice
    }
  ]);

  // TODO: BCD-6701 We need a way to fetch arbitrary products by id on a single route
  return getFromService("content", `bikes/${productId}`, queryParams);
}

export async function getProducts<Type extends ProductType = ProductType>(
  currency: Currency,
  assortmentPriceSettings?: AssortmentPriceSettings,
  filter?: ProductFilter[],
  searchTerm?: string,
  limit?: number,
  offset?: number
): Promise<ProductsResponse<Type>> {
  const queryParams: UrlParams = compact([
    ...createFilterParams(filter),
    searchTerm && { param: "s", value: searchTerm },
    typeof limit === "number" && { param: "limit", value: limit },
    typeof offset === "number" && { param: "offset", value: offset },
    currency && { param: "currency", value: currency },
    assortmentPriceSettings?.showAssortmentPrices && {
      param: "showAssortmentPrices",
      value: assortmentPriceSettings?.showAssortmentPrices
    },
    assortmentPriceSettings?.showFallbackPrices && {
      param: "showFallbackPrices",
      value: assortmentPriceSettings?.showFallbackPrices
    },
    assortmentPriceSettings?.useRrpAsOriginalPrice && {
      param: "useRrpAsOriginalPrice",
      value: assortmentPriceSettings?.useRrpAsOriginalPrice
    }
  ]);

  // TODO: BCD-6701 We need a way to fetch arbitrary products by id on a single route
  return getFromService("content", "bikes", queryParams) as Promise<ProductsResponse<Type>>;
}

export function createFilterParams(filter?: ProductFilter[]): UrlParams {
  return R.compose(
    R.map(({ key, filterType, value }: ProductFilter) => ({
      param: `f:${key}` + (filterType ? `:${filterType}` : ""),
      value
    })),
    R.when(R.isNil, R.always([]))
  )(filter) as UrlParams;
}

export async function getPackages(): Promise<PackageInfo[]> {
  return getFromService("content", "packages");
}

export async function getPossibleValues<Type extends ProductType>(
  specKeys: FilterKey<Type>[],
  currency: Currency,
  assortmentPriceSettings?: AssortmentPriceSettings,
  filter?: ProductFilter[]
): Promise<ProductFilterValues<Type>> {
  const queryParams: UrlParams = compact([
    ...createFilterParams(filter),
    ...specKeys.map(key => ({ param: "key", value: key.toString() })),
    currency && { param: "currency", value: currency },
    assortmentPriceSettings?.showAssortmentPrices && {
      param: "showAssortmentPrices",
      value: assortmentPriceSettings?.showAssortmentPrices
    },
    assortmentPriceSettings?.showFallbackPrices && {
      param: "showFallbackPrices",
      value: assortmentPriceSettings?.showFallbackPrices
    },
    assortmentPriceSettings?.useRrpAsOriginalPrice && {
      param: "useRrpAsOriginalPrice",
      value: assortmentPriceSettings?.useRrpAsOriginalPrice
    }
  ]);

  return getFromService("content", "bikes/labeledValues", queryParams);
}

export async function getCurrentAssortment(): Promise<Assortment> {
  const { currentAssortment }: { currentAssortment: Assortment } = await getFromService("content", "bikes/assortment");
  return currentAssortment;
}

export async function getManualAssortment(): Promise<ManualAssortmentItem[]> {
  const assortment: ManualAssortmentItem[] = await getFromService("content", "bikes/assortment/manual");
  return assortment;
}

export async function deleteFromManualAssortment(
  assortmentItems: ManualAssortmentItem[]
): Promise<ManualAssortmentItem[]> {
  const assortment: ManualAssortmentItem[] = await deleteFromService("content", "bikes/assortment/manual", {
    discontinuedAssortment: assortmentItems
  });
  return assortment;
}

export async function addNewManualAssortment(assortmentItems: ManualAssortmentItem[]): Promise<ManualAssortmentItem[]> {
  const assortment: ManualAssortmentItem[] = await postToService("content", "bikes/assortment/manual", {
    additionalAssortment: assortmentItems
  });
  return assortment;
}

export async function getWawiAssortment(): Promise<WaWiAssortmentItem[]> {
  const assortment: WaWiAssortmentItem[] = await getFromService("content", "bikes/assortment/wawi");
  return assortment;
}

export async function resetWaWiAssortment(): Promise<WaWiAssortmentItem[]> {
  const assortment: WaWiAssortmentItem[] = await deleteFromService("content", "bikes/assortment/wawi");
  return assortment;
}

export async function setWawiAssortment(
  wawiAssortmentItems: WaWiAssortmentItem[],
  transactionId: string,
  commit?: boolean,
  force?: boolean
): Promise<{ transactionId?: string }> {
  const response = await postToService("content", `bikes/assortment/wawi/${transactionId}`, {
    additionalAssortment: wawiAssortmentItems,
    commit,
    force
  });

  return response;
}

export async function resetVeloconnectAssortment(): Promise<VeloconnectAssortment> {
  const veloconnectAssortment: VeloconnectAssortment = await deleteFromService(
    "content",
    "bikes/assortment/veloconnect"
  );

  return veloconnectAssortment;
}

export async function updateVeloconnectAssortment(
  transactionId: string
): Promise<{ currentTransactionId?: string; hasError?: boolean; message?: string }> {
  const response = await postToService("content", `bikes/assortment/veloconnect/${transactionId}`);

  return response;
}

export async function getVeloconnectAssortment(): Promise<VeloconnectAssortment> {
  const response: VeloconnectAssortment = await getFromService("content", `bikes/assortment/veloconnect`);

  return response;
}

export async function addToWawiAssortment(wawiAssortmentItems: WaWiAssortmentItem[]): Promise<WaWiAssortmentItem[]> {
  const assortment: WaWiAssortmentItem[] = await postToService("content", "bikes/assortment/wawi", {
    additionalAssortment: wawiAssortmentItems
  });
  return assortment;
}
