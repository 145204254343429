import React from "react";
import { noop } from "lodash";
import TextFit from "react-textfit";

import { Component } from "../../../../commons/types/component";
import { IconSmallCheck } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import useTouchState from "../../libs/hooks/use-touch-state";
import Availability from "../Availability/Availability";
import Icon from "../Icon/Icon";
import Image from "../Image/Image";
import TileIconButton from "../TileIconButton/TileIconButton";

interface Props extends Component {
  title: string;
  imgSource: string;
  price?: string | React.ReactElement;
  originalPrice?: string | React.ReactElement;
  brandName?: string;
  year?: string;
  availability?: React.ReactElement<React.ComponentProps<typeof Availability>>;
  tileButton?: React.ReactElement<React.ComponentProps<typeof TileIconButton>>;
  badge?: React.ReactNode; // Only if no tile button.
  size?: "default" | "s";
  checked?: boolean;
  switchLines?: boolean;
  background?: "default" | "gradient";
  onClick?: React.MouseEventHandler<HTMLElement>;
  onTouchStart?: React.TouchEventHandler<HTMLElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLElement>;
}

const ProductTile = ({
  classNames = [],
  title,
  imgSource,
  price = "",
  originalPrice,
  brandName = "",
  year,
  availability,
  tileButton,
  badge,
  size = "default",
  checked = false,
  switchLines = false,
  background = "default",
  onClick = noop,
  onTouchStart: onTouchStartOuter,
  onTouchEnd
}: Props) => {
  const breakpoints = useMediaQuery();
  const { touched, onTouchStart } = useTouchState(onTouchStartOuter);

  return (
    <div
      className={cn(
        "ProductTile",
        [
          {
            touched,
            checked,
            switchLines,
            [`background-${background}`]: !!background,
            [`size-${size}`]: !!size
          }
        ],
        classNames
      )}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <a onClick={onClick} className="ProductTile__wrapper">
        {checked && (
          <div className="ProductTile__check">
            <Icon source={IconSmallCheck} />
          </div>
        )}
        <div className="ProductTile__checkedOverlay" />
        <div className="ProductTile__touchedOverlay" />
        <div className="ProductTile__top">
          {availability}
          {year && <div className="ProductTile__year">{year}</div>}
        </div>
        {badge && !tileButton && <div className="ProductTile__badge">{badge}</div>}
        <div className="ProductTile__image">
          <Image src={imgSource} timeout={60_000} position="center" fill lazy />
        </div>

        {!switchLines ? (
          <div className="ProductTile__footer">
            <div className="ProductTile__title">
              <TextFit mode="single" min={12} max={breakpoints.xl ? 18 : 16}>
                {title}
              </TextFit>
            </div>
            {price && (
              <div className="ProductTile__price">
                {price}
                {originalPrice && <span className="ProductTile__originalPrice">{originalPrice}</span>}
              </div>
            )}
          </div>
        ) : (
          <div className="ProductTile__footer">
            {brandName && <div className="ProductTile__brandName">{brandName}</div>}
            {price && price !== "" && (
              <>
                <span className="ProductTile__priceIcon" />
                <div className="ProductTile__price">
                  {price}
                  {originalPrice && <span className="ProductTile__originalPrice">{originalPrice}</span>}
                </div>
              </>
            )}
            <div className="ProductTile__title">
              <TextFit mode="single" min={12} max={14}>
                {title}
              </TextFit>
            </div>
          </div>
        )}
      </a>
      {tileButton && <div className="ProductTile__tileButton">{tileButton}</div>}
    </div>
  );
};

export default ProductTile;
