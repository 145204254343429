import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { ConnectedProps } from "react-redux";

import { SpecFormatter } from "../../../../commons/libs/formatters";
import { getProductImageUrl } from "../../../../commons/libs/resource-paths";
import { getMaxDiscountPercentage, getMinOriginalPrice, getMinPrice } from "../../../../commons/libs/specs";
import { Product, ProductSpecKey, ProductType, StandaloneProductVariant } from "../../../../commons/specs/product";
import { IconSmallZoomIn } from "../../../../resources/icons";
import zoomAnimationData from "../../../../resources/images/lottie/zoom-animation.json";
import actions from "../../actions";
import Badge from "../../components/Badge/Badge";
import CircularButton from "../../components/CircularButton/CircularButton";
import Icon from "../../components/Icon/Icon";
import ProductDetailsImage from "../../components/ProductDetailsImage/ProductDetailsImage";
import ProductTitle from "../../components/ProductTitle/ProductTitle";
import Tooltip from "../../components/Tooltip/Tooltip";
import ZoomImageModal from "../../components/ZoomImageModal/ZoomImageModal";
import { selectAssortmentPriceSettings, selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";
import ModalContainerWithStatePartial from "../Modal/ModalContainerWithStatePartial";
import { connect } from "../utils/loop";
import { useTrackingContext } from "../utils/tracking-context";

const mapStateToProps = (state: State) => ({
  customization: selectInitializedSettings(state).customization,
  assortmentPriceSettings: selectAssortmentPriceSettings(state),
  showProductDetailsZoomNotice: state.session.showProductDetailsZoomNotice
});

const mapDispatchToProps = {
  hideProductDetailsZoomNotice: actions.session.hideProductDetailsZoomNotice
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  product: Product;
  productVariant: StandaloneProductVariant;
  specFormatter: SpecFormatter<ProductType>;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const ProductDetailsImagePartial = ({
  product,
  productVariant,
  specFormatter,
  customization,
  assortmentPriceSettings,
  showProductDetailsZoomNotice,
  hideProductDetailsZoomNotice
}: Props) => {
  const openButtonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(["commons"]);
  const { mixpanel } = useTrackingContext();

  return (
    <ModalContainerWithStatePartial
      onOpenChange={isOpen => {
        if (isOpen) {
          mixpanel?.trackProductDetailsImageZoomOpened(product);
        } else {
          mixpanel?.trackProductDetailsImageZoomClosed(product);
        }
      }}
      modal={(_isOpen, close) => (
        <ZoomImageModal
          openButton={openButtonRef.current}
          imageUrl={getProductImageUrl(productVariant)}
          notice={
            <Tooltip
              variant="accent"
              maxWidth="s"
              content={t("productDetailsImagePartialProductDetails.zoomNotice")}
              openOnMount
              isCloseable
              openOnMountDelay={300}
              shouldCloseAutomatically={false}
              onClose={hideProductDetailsZoomNotice}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: zoomAnimationData
                }}
                height={240}
                width={240}
              />
            </Tooltip>
          }
          onClose={close}
          showNotice={showProductDetailsZoomNotice}
          onHideNotice={hideProductDetailsZoomNotice}
        />
      )}
    >
      {(isOpen, open) => (
        <ProductDetailsImage
          imageUrl={getProductImageUrl(productVariant)}
          onClick={open}
          action={
            <CircularButton ref={openButtonRef} variant={isOpen ? "accent" : "primary"} elevated onClick={open}>
              <Icon source={IconSmallZoomIn} />
            </CircularButton>
          }
          productTitle={
            <ProductTitle
              name={specFormatter.formatProductValue(ProductSpecKey.ModelName, product) ?? ""}
              year={specFormatter.formatProductValue(ProductSpecKey.ModelYear, product) ?? ""}
              price={
                customization?.showPrices ? specFormatter.formatProductValue(ProductSpecKey.Price, product) : undefined
              }
              originalPrice={
                (getMinPrice(product) ?? 0) < (getMinOriginalPrice(product) ?? 0) &&
                getMaxDiscountPercentage(product) &&
                assortmentPriceSettings.showOriginalPrices
                  ? specFormatter.formatProductValue(ProductSpecKey.OriginalPrice, product)
                  : undefined
              }
              badge={
                getMaxDiscountPercentage(product) ? (
                  assortmentPriceSettings.showSaleBadge === "word" ? (
                    <Badge label={t("commons:defaults.sale")} size="s" />
                  ) : assortmentPriceSettings.showSaleBadge === "percentage" ? (
                    <Badge
                      label={specFormatter.formatProductValue(ProductSpecKey.DiscountPercentage, product)}
                      size="s"
                    />
                  ) : undefined
                ) : undefined
              }
            />
          }
        />
      )}
    </ModalContainerWithStatePartial>
  );
};

export default connector(ProductDetailsImagePartial);
