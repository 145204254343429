import { useCallback, useRef, useState } from "react";

import config from "../../config";

import useDebouncedCallback from "./use-debounced-callback";
import useOnUnmount from "./use-on-unmount";

const useDoubleClick = <T = HTMLElement>(
  onDoubleClick?: React.MouseEventHandler<T>,
  onClick?: React.MouseEventHandler<T>,
  onBlur?: React.FocusEventHandler<T>,
  doubleClickTimeout = config.shared.doubleClickTimeout
): {
  waitingForDoubleClick: boolean;
  doubleClicked: boolean;
  onClick: React.MouseEventHandler<T>;
  onBlur: React.FocusEventHandler<T>;
} => {
  const [waitingForDoubleClick, setWaitingForDoubleClick] = useState(false);
  const [doubleClicked, setDoubleClicked] = useState(false);

  // The following refs are necessary to allow those event handlers to change its instance with every render
  // without changing the instances of the returned onClick and onBlur handlers.

  const onDoubleClickRef = useRef(onDoubleClick);
  onDoubleClickRef.current = onDoubleClick;

  const onClickRef = useRef(onClick);
  onClickRef.current = onClick;

  const onBlurRef = useRef(onBlur);
  onBlurRef.current = onBlur;

  const waitingForDoubleClickRef = useRef(waitingForDoubleClick);
  waitingForDoubleClickRef.current = waitingForDoubleClick;

  const onDebouncedClick = useDebouncedCallback((event: React.MouseEvent<T>) => {
    setWaitingForDoubleClick(false);
    setDoubleClicked(false);
    onClick?.(event);
  }, doubleClickTimeout);

  useOnUnmount(() => {
    onDebouncedClick.cancel();
  });

  return {
    waitingForDoubleClick,
    doubleClicked,
    onClick: useCallback(
      (event: React.MouseEvent<T>) => {
        if (onDoubleClickRef.current) {
          if (waitingForDoubleClickRef.current) {
            onDebouncedClick.cancel();
            onDoubleClickRef.current(event);
            setWaitingForDoubleClick(false);
            setDoubleClicked(true);
          } else {
            setWaitingForDoubleClick(true);
            onDebouncedClick(event);
          }
        } else {
          onDebouncedClick.cancel();
          onClickRef.current?.(event);
        }
      },
      [onDebouncedClick]
    ),
    onBlur: useCallback((event: React.FocusEvent<T>) => {
      setWaitingForDoubleClick(false);
      setDoubleClicked(false);
      if (onBlurRef.current) {
        onBlurRef.current(event);
      }
    }, [])
  };
};

export default useDoubleClick;
