import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const ItemList = ({ classNames = [], children }: Props) => (
  <ul className={cn("ItemList", [], classNames)}>
    {React.Children.map(children, (child, index) => (
      <li key={index} className="ItemList__item">
        {child}
      </li>
    ))}
  </ul>
);

export default ItemList;
