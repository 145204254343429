import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  pulse?: boolean;
}

const Dot = ({ classNames = [], pulse = false }: Props) => <div className={cn("Dot", [{ pulse }], classNames)} />;

export default Dot;
