import bicycleSpecConfig from "./bicycle";
import motorcycleSpecConfig from "./motorcycle";
import { ProductType, SpecConfig } from "./product";

// List all available product types here.
// Keep in sync with specs in this folder.
const specConfigs: {
  [P in ProductType]: SpecConfig<P>;
} = {
  [ProductType.Bicycle]: bicycleSpecConfig,
  [ProductType.Motorcycle]: motorcycleSpecConfig
};

// Accessing spec config via this function ensures that generic type is inferred correctly.
export const getSpecConfig = <Type extends ProductType>(productType: Type): SpecConfig<Type> => {
  return specConfigs[productType];
};
