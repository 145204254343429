import { connectRouter } from "connected-react-router";
import { History } from "history";
import { LoopReducer, reduceReducers, WithDefaultActionHandling } from "redux-loop";

import { Actions } from "../../commons/actions";
import { mapReducerWithKey } from "../../commons/libs/reducer";
import {
  reducers as commonReducers,
  State as CommonState,
  initialState as inititalStateCommon
} from "../../commons/reducers";

import mobileApp, { initialState as initialMobileAppState, MobileAppState } from "./mobile-app";

export type State = CommonState & MobileAppState;

const createRootReducer = (history: History) => {
  const router = connectRouter(history);

  const initialState = {
    ...inititalStateCommon,
    ...initialMobileAppState,
    router: router(undefined, {} as Actions)
  };

  // The individual reducers only require the state type they actually depend on
  // therefore we have convert them here to a more generic reducer type.
  // This type conversion still checks whether the types are compatible. So this is safe to do.
  return reduceReducers<State, Actions>(
    (state = initialState) => state,
    ...commonReducers.map(reducer => reducer as unknown as LoopReducer<State, Actions>),
    mobileApp as LoopReducer<State, Actions>,
    mapReducerWithKey<State, "router", WithDefaultActionHandling<Actions>>(router, "router")
  );
};

export default createRootReducer;
