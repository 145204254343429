import * as R from "ramda";

import { BicycleProduct } from "../specs/bicycle";
import { AdapterKeyLastSucessFulImportMap, VeloconnectErrorType } from "../types/veloconnect";

import { deleteFromService, getFromService, postToService, ServiceClientHttpError } from "./client-utils";
import { AdapterInformation, ClientConfig, datetime, ProductAvailability, Status } from "./externals/veloconnect-proxy";
import { byDefined } from "./filter-guards";

export class VeloconnectError extends ServiceClientHttpError {
  static fromServiceErrorOrRethrow(error: unknown): never {
    if (!!error && error instanceof ServiceClientHttpError && error.isServiceError) {
      throw new VeloconnectError(error);
    } else {
      throw error;
    }
  }

  public veloconnectError: VeloconnectErrorType;

  constructor(error: ServiceClientHttpError) {
    super(error.message, error, error.status, error.stack);

    this.veloconnectError = VeloconnectError.mapError(error.error) ?? VeloconnectErrorType.Other;

    this.name = "VeloconnectError";
  }

  private static mapError = (error: string) => {
    const map: { [error: string]: VeloconnectErrorType } = {
      ENDPOINT_KEY_ALREADY_CONFIGURED: VeloconnectErrorType.EndpointKeyAlreadyConfigured,
      INVALID_ENDPOINT_KEY: VeloconnectErrorType.InvalidEndpointKey,
      ENDPOINT_KEY_NOT_CONFIGURED: VeloconnectErrorType.EndpointKeyNotConfigured,
      CREDENTIALS_INVALID: VeloconnectErrorType.InvalidCredentials,
      AUTHORIZATION_FAILED: VeloconnectErrorType.AuthorizationFailed,
      CLIENT_ALREADY_EXISTS: VeloconnectErrorType.ClientAlreadyExists
    };

    return map[error] ?? VeloconnectErrorType.Other;
  };
}

export async function getVeloconnectEndpoints(): Promise<ClientConfig[]> {
  return getFromService("veloconnect", "endpoints").catch(VeloconnectError.fromServiceErrorOrRethrow);
}

export async function getVeloconnectEndpointByBrandKey(brandKey: string): Promise<ClientConfig | undefined> {
  return getFromService("veloconnect", `endpoint/${brandKey}`).catch(VeloconnectError.fromServiceErrorOrRethrow);
}

export async function testVeloconnectProxy(): Promise<Status> {
  return getFromService("veloconnect", "test").catch(VeloconnectError.fromServiceErrorOrRethrow);
}

export async function syncActiveBrandsWithVeloconnect(): Promise<string[]> {
  return postToService("veloconnect", "syncActiveBrands").catch(VeloconnectError.fromServiceErrorOrRethrow);
}

export async function addVeloconnectEndpointConfiguration(
  endpointKey: string,
  buyersId: string,
  password: string,
  doNotValidateCredentials?: boolean
): Promise<ClientConfig> {
  return postToService("veloconnect", "endpoint", {
    endpointKey,
    buyersId,
    password,
    doNotValidateCredentials
  }).catch(VeloconnectError.fromServiceErrorOrRethrow);
}

export async function removeVeloconnectEndpointConfiguration(endpointKey: string): Promise<Status> {
  return deleteFromService("veloconnect", "endpoint", {
    endpointKey
  }).catch(VeloconnectError.fromServiceErrorOrRethrow);
}

export async function getVeloconnectProductDetails(product: BicycleProduct): Promise<ProductAvailability[]> {
  return postToService("veloconnect", "productAvailabilities", {
    product
  }).catch(VeloconnectError.fromServiceErrorOrRethrow);
}

export const getVeloconnectEndpoint = (endpoints: ClientConfig[], endpointKey: string): ClientConfig | undefined => {
  return endpoints.find(endpoint => endpoint.endpointKey === endpointKey);
};

export const isVeloconnectEndpointVisible = (endpoints: ClientConfig[], endpointKey: string): boolean => {
  const endpoint = getVeloconnectEndpoint(endpoints, endpointKey);

  return Boolean(endpoint?.isConfigurable || endpoint?.isRestricted || endpoint?.isConfigured);
};

export const getVisibleVeloconnectEndpoints = (endpoints: ClientConfig[]) => {
  return endpoints.filter(endpoint => isVeloconnectEndpointVisible(endpoints, endpoint.endpointKey));
};

export const getConfiguredVeloconnectEndpoints = (endpoints: ClientConfig[]) => {
  return endpoints.filter(endpoint => endpoint.isConfigured);
};

export const getNotConfiguredVeloconnectEndpoints = (endpoints: ClientConfig[]) => {
  return endpoints.filter(endpoint => !endpoint.isConfigured);
};

export const getVeloconnectEndpointKeys = (endpoints: ClientConfig[]) =>
  endpoints.map(endpoint => endpoint.endpointKey);

export const getConfiguredVeloconnectEndpointKeys = (endpoints: ClientConfig[]) =>
  R.compose(getVeloconnectEndpointKeys, getConfiguredVeloconnectEndpoints)(endpoints);

export const getNotConfiguredVeloconnectEndpointKeys = (endpoints: ClientConfig[]) =>
  R.compose(getVeloconnectEndpointKeys, getNotConfiguredVeloconnectEndpoints)(endpoints);

export const getVeloconnectEndpointBrandKeys = (endpoints: ClientConfig[]) =>
  endpoints.flatMap(endpoint => endpoint.brandKeys);

export const getConfiguredVeloconnectEndpointBrandKeys = (endpoints: ClientConfig[]) =>
  R.compose(getVeloconnectEndpointBrandKeys, getConfiguredVeloconnectEndpoints)(endpoints);

export const isVeloconnectImportReady = (
  endpoint: ClientConfig | undefined,
  { adapterKey = endpoint?.endpointKey } = { adapterKey: endpoint?.endpointKey }
) => {
  const adapter = getAdapterByEndpoint(endpoint, { adapterKey });

  return Boolean(adapter?.lastSuccessfulImport && !adapter?.isImportRunning);
};

export const getAdapterByEndpoint = (
  endpoint: ClientConfig | undefined,
  { adapterKey = endpoint?.endpointKey } = { adapterKey: endpoint?.endpointKey }
): AdapterInformation | undefined => {
  return endpoint?.adapters?.find(adapter => adapter.adapterKey === adapterKey);
};

export const getAdapterKeyLastSucessFulImportMap = (
  configuredEndpoints: ClientConfig[]
): AdapterKeyLastSucessFulImportMap => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- configured endpoint has always adapters
  const adapters = configuredEndpoints.flatMap(endpoint => endpoint.adapters!);

  return adapters.reduce<AdapterKeyLastSucessFulImportMap>((map, adapter) => {
    return adapter.adapterKey ? { ...map, [adapter.adapterKey]: adapter.lastSuccessfulImport } : map;
  }, {});
};

export const getLastSuccessFulImport = (map: AdapterKeyLastSucessFulImportMap): datetime | undefined => {
  const lastSuccessFulImports = Object.values(map).filter(byDefined);

  const lastSucessFulImport = lastSuccessFulImports
    .map(date => new Date(date))
    // sort by date descending
    .sort((a, b) => b.getTime() - a.getTime())[0];

  return lastSucessFulImport instanceof Date ? lastSucessFulImport.toISOString() : undefined;
};
