import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  image: React.ReactElement;
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  inlineRadio?: boolean;
  onClick?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  kind?: "main" | "support";
}

const RadioFieldWithImage = ({
  classNames = [],
  image,
  id = "",
  name = "",
  value = "",
  label = "",
  checked = false,
  disabled = false,
  inlineRadio = false,
  onClick = noop,
  onBlur = noop,
  kind = "main"
}: Props) => {
  return (
    <label
      className={cn(
        "RadioFieldWithImage",
        [
          {
            [`kind-${kind}`]: kind,
            checked,
            inlineRadio
          }
        ],
        classNames
      )}
      aria-checked={checked}
      aria-disabled={disabled}
    >
      <div className="RadioFieldWithImage__image">{image}</div>
      <input
        className="RadioFieldWithImage__input"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={onClick}
        onBlur={onBlur}
      />
      {!!label && <div className="RadioFieldWithImage__label">{label}</div>}
    </label>
  );
};

export default RadioFieldWithImage;
