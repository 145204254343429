import React from "react";
import { flattenDeep } from "lodash";
import { isElement, isFragment } from "react-is";

/**
 * Flattens a given ReactNode. Flattening, in this case, means that undefined and null children will be filtered out.
 * Additionally, Fragments will be flattened by one level. However, a nested fragment within a fragment will not be flattened.
 *
 * @param children - The ReactNode to be flattened.
 * @param iterator - A higher-order function specifying what should be done with each child.
 * @returns A flattened array of ReactNode elements.
 *
 * @example
 * The fragment is removed, and all its children are returned in a React.ReactNode[]
 * mapChildrenWithFragments(<>
 *   <div>1</div>
 *   <div>2</div>
 *   <div>3</div>
 * </>) // => [<div>1</div>, <div>2</div>, <div>3</div>]
 *
 * @example
 * The fragment is removed, and all its children are returned in a React.ReactNode[]. Nested fragments are not flattened.
 * mapChildrenWithFragments(<>
 *   <div>1</div>
 *   <>
 *     <div>2</div>
 *     <div>3</div>
 *   </>
 * </>) // => [<div>1</div>, <> <div>2</div> <div>3</div> </>]
 *
 * @example
 * The fragment is flattened, and all its children are returned in a React.ReactNode[].
 * mapChildrenWithFragments([
 *   <div key="1">1</div>,
 *   <>
 *     <div key="2">2</div>
 *     <div key="3">3</div>
 *   </>
 * ]) // => [<div>1</div>, <div>2</div>, <div>3</div>]
 */
export const mapChildrenWithFragments = (
  children: React.ReactNode,
  iterator: (child: React.ReactElement, index: number) => React.ReactNode = child => child
) =>
  flattenDeep(
    React.Children.map(children, child => {
      if (isFragment(child)) {
        return React.Children.toArray((child as React.ReactElement).props.children);
      }
      return child;
    })
  )
    .filter(child => child)
    .map((child, index) => (isElement(child) ? iterator(child, index) : child));

/**
 * Counts the number of children after flattening a given ReactNode. Flattening, in this case,
 * means that undefined and null children will be filtered out. Additionally, Fragments will be
 * flattened by one level, but nested fragments within a fragment will not be flattened.
 *
 * @param children The ReactNode whose children need to be counted.
 * @returns The total number of children elements after flattening.
 */
export const countChildrenWithFragments = (children: React.ReactNode) => mapChildrenWithFragments(children).length;
