import React from "react";
import { useTranslation } from "react-i18next";

import Badge from "../../../commons/components/Badge/Badge";
import ErrorPageLayout from "../../../commons/components/ErrorPageLayout/ErrorPageLayout";
import Headline from "../../../commons/components/Headline/Headline";
import Hotline from "../../../commons/components/Hotline/Hotline";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";
import Text from "../../../commons/components/Text/Text";
import TextHeader from "../../../commons/components/TextHeader/TextHeader";

const MaxLicensesReachedNotice = () => {
  const { t } = useTranslation(["mobile"]);

  return (
    <ErrorPageLayout>
      <Text>
        <TextHeader
          topline={<Badge label={t("mobile:maxLicensesReachedNotice.licenseOverrunLabel")} variant="negative" />}
        >
          <Headline classNames={["u-space-top-base"]} kind="l">
            {t("mobile:maxLicensesReachedNotice.maxLicensesReachedHeadline")}
          </Headline>
        </TextHeader>

        <Paragraph>{t("mobile:maxLicensesReachedNotice.contactSupport")}</Paragraph>

        <Hotline
          title={t("mobile:supportConfig.headline")}
          phone={t("mobile:supportConfig.phone")}
          label={t("mobile:supportConfig.officeHours")}
          mail={t("mobile:supportConfig.mail")}
        />
      </Text>
    </ErrorPageLayout>
  );
};

export default MaxLicensesReachedNotice;
