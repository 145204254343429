import { LocaleAssociablePackageInfo, PackageInfo } from "./content";
import { Env } from "./env";
import { Language, TranslationKey } from "./localization";
import { GlobalLocationState } from "./location";
import { FeatureFlags, ToolSetting } from "./settings";

export enum ToolKey {
  BikeFrameSizeCalculator = "bike-frame-size-calculator",
  BikeFinancialCalculator = "bike-financial-calculator",
  BikepriceCalculator = "bikeprice-calculator",
  AllAboutCompanyBike = "all-about-company-bike"
}

export enum ExternalToolKey {
  Adac = "adac",
  Alteos = "alteos",
  Bikmo = "bikmo",
  BcRemoteShop = "bcRemoteShop",
  BicoB2bShop = "bicoShop",
  BodyScanningCrm = "bodyScanningCrm",
  BodyScanningAppointmentPrep = "bodyScanningAppointmentPrep",
  Enra = "enra",
  JobRad = "jobRad",
  WuerthBikeLease = "wuerthBikeLease",
  BicoAssessment = "bicoAssessment",
  SqlabProfiler = "sqlabProfiler",
  Wertgarantie = "wertgarantie",
  // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint does not recognize "LeaseABike" as PascalCase
  LeaseABike = "leaseABike",
  BusinessBike = "businessbike",
  EasyBikeLeasing = "easyBikeLeasing",
  BikeLeasing = "bikeleasing",
  LeaseMyBike = "leaseMyBike",
  DeutscheDienstrad = "deutscheDienstrad",
  FirmenRadl = "firmenRadl",
  EuroRad = "euroRad",
  KazenMaier = "kazenMaier",
  MeinDienstrad = "meinDienstrad",
  ZegPlusGarantie = "zegPlusGarantie",
  KtmConfigurator = "ktmConfigurator",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KtmBookATestride = "ktmBookATestride",
  KtmParts = "ktmParts",
  KtmWear = "ktmWear",
  HusqvarnaConfigurator = "husqvarnaConfigurator",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HusqvarnaBookATestride = "husqvarnaBookATestride",
  HusqvarnaParts = "husqvarnaParts",
  HusqvarnaWear = "husqvarnaWear",
  GasGasConfigurator = "gasgasConfigurator",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  GasgasBookATestride = "gasgasBookATestride",
  GasGasParts = "gasgasParts",
  GasGasWear = "gasgasWear",
  Smartfit = "smartfit",
  BoschPartsAdviser = "boschPartsAdviser",
  SchwalbePartsAdviser = "schwalbePartsAdviser"
}

export type ToolLabel = Partial<Record<TranslationKey, string>> & {
  [Language.De]: string;
};

export enum ToolCategory {
  Advice = "advice",
  Service = "service",
  Leasing = "leasing",
  Configurator = "configurator",
  Testride = "testride",
  Integration = "integration",
  PartsAndWear = "partsAndWear"
}

export interface ToolBase extends LocaleAssociablePackageInfo {
  label: ToolLabel;
  category: ToolCategory;
}

export interface ToolPackageInfo extends PackageInfo, ToolBase {
  /**
   * Tools will be displayed in order of their priority.
   * The tool with the smallest number will be displayed first, and so on.
   */
  priority: number;
  /**
   * Internal icon id which is only used for internal tools and is defined in the meta.json of each internal tool.
   * Furthermore it is used for internal icon mapping. e.g. icon "frame" (first character uppercased) is the Suffix of IconLargeFrame.
   */
  icon: string;
}

// Tools and TestUrls can be localized using environment.locale in the Tool/TestUrlFunction
export type ToolUrlFunction<
  CustomToolSettings extends ToolSetting = ToolSetting,
  LocationState extends GlobalLocationState = GlobalLocationState
> = (
  environment: Env,
  customToolSettings?: CustomToolSettings,
  externalToolLocationState?: LocationState
) => string | null;

export type TestUrlFunction<CustomToolSettings extends ToolSetting = ToolSetting> = (
  environment: Env,
  customToolSettings?: CustomToolSettings
) => string | null;

export type IconFunction = (environment: Env) => string;

export interface ExternalToolBase extends ToolBase {
  key: ExternalToolKey;
  initialViewSettingsRequired?: boolean;
  route?: string;
  featureFlag?: keyof FeatureFlags;
}

export interface ExternalIntegrationToolConfig extends ExternalToolBase {
  type: ToolCategory.Integration;
}

export type ExternalWebviewToolConfig<
  CustomToolSettings extends ToolSetting = ToolSetting,
  LocationState extends GlobalLocationState = GlobalLocationState
> = ExternalToolBase & {
  category: Omit<ToolCategory, ToolCategory.Integration>;
  icon: string | IconFunction; // Assignes external icon or returns external icon depending on IconFunction
  persistWebviewSession: boolean; // Persists cookies and storage even if the device gets restarted
  clearAllSessionDataOnClose: boolean; // Clears cookies and storage after each tool close
  disableAutomaticKeyboard?: boolean; // Pevents the keyboard from opening when inputs are focused
  toolUrl: string | ToolUrlFunction<CustomToolSettings, LocationState>;
  testUrl: string | TestUrlFunction<CustomToolSettings>;
  zoomFactor?: number;
  width?: number;
  pinRequired: boolean;
  metadata: string[];
  bgColor?: string;
  printable: boolean;
  registerUrl?: string;
  exitButtonAsFloatingButton?: boolean;
};

export type ExternalToolConfig = ExternalIntegrationToolConfig | ExternalWebviewToolConfig;

export interface ExternalToolStatus {
  [toolKey: string]: boolean;
}
