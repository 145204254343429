import React from "react";
import { ceil, floor, isEqual } from "lodash";

import { RangeFilterValue } from "../../../../commons/specs/filters";
import { Currency, CURRENCY_SYMBOL_MAP } from "../../../../commons/types/currency";
import { KeyboardType } from "../../../../commons/types/keyboard";
import * as icons from "../../../../resources/icons";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import InputField from "../../components/InputField/InputField";
import PriceFilterLayout from "../../components/PriceFilterLayout/PriceFilterLayout";
import RangeField from "../../components/RangeField/RangeField";
import useDebouncedCallback from "../../libs/hooks/use-debounced-callback";

interface Props {
  priceActiveFilters: RangeFilterValue;
  rangeLimitFrom: number;
  rangeLimitTo: number;
  onChange: (priceRange: RangeFilterValue) => void;
  currency: Currency;
}

const DEBOUNCED_DELAY: number = 300;

const PriceFilterModalPartial = ({ priceActiveFilters, rangeLimitFrom, rangeLimitTo, onChange, currency }: Props) => {
  // We store the onChange function in a ref to prevent the debounce function to be recreated on every render
  const debouncedOnChange = useDebouncedCallback(onChange, DEBOUNCED_DELAY, { trailing: true });

  const initialRangeFrom: string = priceActiveFilters[0].toString();
  const initialRangeTo: string = priceActiveFilters[1].toString();

  // the values of the inputs and the range-filed are decoupled form the real price value
  // this is necessary because two controls will controll one filter value
  const [rangeFrom, setRangeFrom] = React.useState<string>(initialRangeFrom);
  const [rangeTo, setRangeTo] = React.useState<string>(initialRangeTo);
  const [priceSliderValues, setPriceSliderValues] = React.useState<RangeFilterValue>(priceActiveFilters);

  const handleResetRangeMinValue = () => {
    const updatedPriceValues: RangeFilterValue = [rangeLimitFrom, priceActiveFilters[1]];

    setRangeFrom(`${rangeLimitFrom}`);
    setPriceSliderValues(updatedPriceValues);

    onChange(updatedPriceValues);
  };

  const handleResetRangeMaxValue = () => {
    const updatedPriceValues: RangeFilterValue = [priceActiveFilters[0], rangeLimitTo];

    setRangeTo(`${rangeLimitTo}`);
    setPriceSliderValues(updatedPriceValues);

    onChange(updatedPriceValues);
  };

  const handleResetRanges = () => {
    const initialRanges: RangeFilterValue = [rangeLimitFrom, rangeLimitTo];

    setRangeFrom(`${rangeLimitFrom}`);
    setRangeTo(`${rangeLimitTo}`);
    setPriceSliderValues(initialRanges);

    onChange(initialRanges);
  };

  // calculate the new priceValues which are applied to the filter via the onChange function
  // also the calculatePriceValues function is to update the RangeField
  const calculatePriceValues = (priceValues: RangeFilterValue): RangeFilterValue => {
    const [from, to] = priceValues;

    let fromRounded: number = from;
    let toRounded: number = to;

    // values above 1000 should be rounded to the hundredth digit
    let fromRoundingDigit: number = -2;
    let toRoundingDigit: number = -2;

    // values under 1000 should be rounded to the tenth digit
    if (from < 1000) {
      fromRoundingDigit = -1;
    }
    if (to < 1000) {
      toRoundingDigit = -1;
    }

    // depending on what the user enters into the input
    // the from value could be higher than the to value
    // therefore the ceiling and flooring of the values switches
    // the order of the from and to value will be sort later
    if (from < to) {
      fromRounded = floor(from, fromRoundingDigit);
      toRounded = ceil(to, toRoundingDigit);
    } else if (from > to) {
      fromRounded = ceil(from, fromRoundingDigit);
      toRounded = floor(to, toRoundingDigit);
    }

    // if the rounded values are out of their limits apply the limit
    if (fromRounded < rangeLimitFrom) {
      fromRounded = rangeLimitFrom;
    } else if (fromRounded > rangeLimitTo) {
      fromRounded = rangeLimitTo;
    }

    if (toRounded < rangeLimitFrom) {
      toRounded = rangeLimitFrom;
    } else if (toRounded > rangeLimitTo) {
      toRounded = rangeLimitTo;
    }

    // sort the form and to value, so that the lower number is the from and the higher the to
    const sortedRoundedRanges: RangeFilterValue = [fromRounded, toRounded].sort((a, b) => a - b) as RangeFilterValue;

    return sortedRoundedRanges;
  };

  const handleRoundedRangeChange = (values: RangeFilterValue) => {
    const updatedPriceValues: RangeFilterValue = calculatePriceValues(values);
    const [from, to] = updatedPriceValues;

    setPriceSliderValues(updatedPriceValues);
    setRangeFrom(from.toString());
    setRangeTo(to.toString());

    debouncedOnChange(updatedPriceValues);
  };

  const handleRangeFromChange = (value: string) => {
    const from: number = parseInt(value) || rangeLimitFrom;
    const to: number = parseInt(rangeTo);
    const updatedPriceValues: RangeFilterValue = calculatePriceValues([from, to]);

    debouncedOnChange(updatedPriceValues);

    setRangeFrom(value);

    if (updatedPriceValues[0] < rangeLimitFrom) {
      setPriceSliderValues([rangeLimitFrom, to]);
    } else {
      setPriceSliderValues(updatedPriceValues);
    }
  };

  const handleRangeToChange = (value: string) => {
    const from: number = parseInt(rangeFrom);
    const to: number = parseInt(value) || rangeLimitTo;
    const updatedPriceValues: RangeFilterValue = calculatePriceValues([from, to]);

    debouncedOnChange(updatedPriceValues);

    setRangeTo(value);

    if (updatedPriceValues[1] > rangeLimitTo) {
      setPriceSliderValues([from, rangeLimitTo]);
    } else {
      setPriceSliderValues(updatedPriceValues);
    }
  };

  const renderRangeClearButton = () => {
    const initialRanges: RangeFilterValue = [rangeLimitFrom, rangeLimitTo];

    return !isEqual(priceActiveFilters, initialRanges) ? (
      <IconButton icon={<Icon source={icons.IconSmallBin} />} onClick={handleResetRanges} />
    ) : undefined;
  };

  // To keep a consistent UI, the lower value will be set into the left input and the higher value into the right input
  const handleRangeInputOnBlur = () => {
    const fromInputValue = parseInt(rangeFrom);
    const toInputValue = parseInt(rangeTo);

    if (fromInputValue > toInputValue) {
      setRangeFrom(`${toInputValue}`);
      setRangeTo(`${fromInputValue}`);
    }

    if (rangeFrom === "") {
      setRangeFrom(`${rangeLimitFrom}`);
    }
    if (rangeTo === "") {
      setRangeTo(`${rangeLimitTo}`);
    }
  };

  return (
    <PriceFilterLayout
      range={
        <RangeField
          minMaxLabels
          labelPosition="outside"
          min={rangeLimitFrom}
          max={rangeLimitTo}
          value={priceSliderValues}
          onChange={value => handleRoundedRangeChange(value as RangeFilterValue)}
          tooltip="auto"
          unit={CURRENCY_SYMBOL_MAP[currency]}
        />
      }
      from={
        <InputField
          value={rangeFrom}
          withoutClearButton={rangeLimitFrom.toString() === rangeFrom}
          onClearClick={handleResetRangeMinValue}
          onChange={handleRangeFromChange}
          onBlur={handleRangeInputOnBlur}
          keyboardType={KeyboardType.Numpad}
        />
      }
      to={
        <InputField
          value={rangeTo}
          withoutClearButton={rangeLimitTo.toString() === rangeTo}
          onClearClick={handleResetRangeMaxValue}
          onChange={handleRangeToChange}
          onBlur={handleRangeInputOnBlur}
          keyboardType={KeyboardType.Numpad}
        />
      }
      clear={renderRangeClearButton()}
    />
  );
};

export default PriceFilterModalPartial;
