import React from "react";
import { throttle } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import FloatingActionBar from "../FloatingActionBar/FloatingActionBar";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import PdfPage from "../PdfPage/PdfPage";

const SCROLL_THROTTLE_MS = 300;

interface Props extends Component {
  pages: string[];
  isLoading?: boolean;
  actionButtons?: React.ReactNode;
}

const isElementInView = (element: Element, top: number, bottom: number, height: number) => {
  const elementRect = element.getBoundingClientRect();
  if (
    (elementRect.bottom > bottom && elementRect.top > top && elementRect.top < bottom - height / 2) ||
    (elementRect.bottom > bottom && elementRect.top < top) ||
    (elementRect.top < top && elementRect.bottom > top + height / 2) ||
    (elementRect.top > top && elementRect.bottom < bottom)
  ) {
    return true;
  }

  return false;
};

const PdfViewer = ({ classNames, pages, actionButtons, isLoading = false }: Props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const pagesRef = React.useRef<HTMLDivElement>(null);
  const viewRef = React.useRef<HTMLDivElement>(null);

  const onScroll = throttle(() => {
    if (pagesRef.current && viewRef.current) {
      const { top, bottom, height } = viewRef.current.getBoundingClientRect();

      const elements = Array(pagesRef.current.children.length)
        .fill(0)
        .map((value, index) => pagesRef.current?.children.item(index));

      const currentPage = elements
        .map(el => el && isElementInView(el, top, bottom, height))
        .findIndex(visible => visible);

      if (currentPage >= 0) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, SCROLL_THROTTLE_MS);

  return (
    <div ref={viewRef} className={cn("PdfViewer", [], classNames)}>
      <div ref={pagesRef} onScroll={onScroll} className="PdfViewer__pages">
        {isLoading && (
          <div className="PdfViewer__loading">
            <LoadingIndicator />
          </div>
        )}
        {!isLoading &&
          pages.map((page, index) => (
            <div key={index} className="PdfViewer__page">
              <PdfPage source={page} />
            </div>
          ))}
      </div>
      <div className="PdfViewer__info">
        <FloatingActionBar
          info={
            isLoading ? (
              <LoadingIndicator size="s" variant="standout" />
            ) : (
              <>
                {currentPage}/{pages.length}
              </>
            )
          }
          actions={actionButtons}
        />
      </div>
    </div>
  );
};

export default PdfViewer;
