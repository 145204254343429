import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useTouchState from "../../libs/hooks/use-touch-state";
import Icon from "../Icon/Icon";

interface Props extends Component {
  icon: React.ReactElement<React.ComponentProps<typeof Icon>>;
  label?: string;
  dot?: React.ReactElement<Component>;
  kind?: "inherit" | "support";
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTouchStart?: React.TouchEventHandler<HTMLButtonElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLButtonElement>;
}

const IconButton = ({
  classNames = [],
  icon,
  kind = "inherit",
  label = "",
  dot,
  disabled = false,
  onClick = noop,
  onTouchStart: onTouchStartOuter,
  onTouchEnd
}: Props) => {
  const { onTouchStart, touched } = useTouchState(onTouchStartOuter);

  return (
    <button
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      disabled={disabled}
      type="button"
      className={cn("IconButton", [{ [`kind-${kind}`]: kind, touched, label: !!label }], classNames)}
      onClick={onClick}
    >
      {icon}
      {!!label && <div className="IconButton__label">{label}</div>}
      {dot && <div className="IconButton__dot">{dot}</div>}
    </button>
  );
};

export default IconButton;
