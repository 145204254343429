import React, { useCallback, useMemo } from "react";
import { intersection } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { ClientConfig } from "../../../../commons/libs/externals/veloconnect-proxy";
import { getBrandLogoUrl } from "../../../../commons/libs/resource-paths";
import { ActiveFilters, FilterKey, ProductFilterValues } from "../../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { AvailabilityFilterLocation } from "../../../../commons/types/availability";
import { IconSmallInputError } from "../../../../resources/icons";
import imageLocationDealer from "../../../../resources/images/availability/availability-location-dealer.svg";
import imageLocationEverywhere from "../../../../resources/images/availability/availability-location-everywhere.svg";
import imageLocationSupplier from "../../../../resources/images/availability/availability-location-supplier.svg";
import actions from "../../actions";
import Badge from "../../components/Badge/Badge";
import CheckboxListItem from "../../components/CheckboxListItem/CheckboxListItem";
import FilterModalValues from "../../components/FilterModal/FilterModalValues";
import FilterModalBrandsGrid from "../../components/FilterModalBrandsGrid/FilterModalBrandsGrid";
import FlexFooterLayout from "../../components/FlexFooterLayout/FlexFooterLayout";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Headline from "../../components/Headline/Headline";
import Hr from "../../components/Hr/Hr";
import Icon from "../../components/Icon/Icon";
import Image from "../../components/Image/Image";
import Note from "../../components/Note/Note";
import Paragraph from "../../components/Paragraph/Paragraph";
import RadioFieldWithImage from "../../components/RadioFieldWithImage/RadioFieldWithImage";
import RadioGroup from "../../components/RadioGroup/RadioGroup";
import ToggleField from "../../components/ToggleField/ToggleField";
import { addActiveFilterValue, isActiveFilter, removeActiveFilterValue, setActiveFilters } from "../../libs/filters";
import useDispatch from "../../libs/hooks/use-dispatch";
import useOnMount from "../../libs/hooks/use-on-mount";
import useSelector from "../../libs/hooks/use-selector";
import { useActiveBrands } from "../../libs/queries/use-active-brands";
import { useVeloconnectEndpointsContext } from "../utils/veloconnect-endpoints-context";

interface Props<Type extends ProductType> {
  activeFilters: ActiveFilters<Type>;
  automaticAssortmentFilterValues: NonNullable<ProductFilterValues<ProductType>[ProductSpecKey.AutomaticAssortment]>;
  veloconnectAssortmentFilterValues: NonNullable<ProductFilterValues<ProductType>[ProductSpecKey.VeloconnectAssortment]>; // prettier-ignore
  onFilterChange: (activeFilters: ActiveFilters<Type>) => void;
  clearFilter?: (filterKey: FilterKey<ProductType>) => void;
}

const ExtendedInterfaceToggle = ({
  isExtendedAvailabilitesFilterEnabled,
  shouldExtendedInterfaceBeDisabled,
  onToggle
}: {
  isExtendedAvailabilitesFilterEnabled: boolean;
  shouldExtendedInterfaceBeDisabled: boolean;
  onToggle: (value: boolean) => void;
}) => {
  const { t } = useTranslation(["commons"]);

  return (
    <>
      <Hr />
      <FlexLayout gap="s" alignItems="center" alignLastItemToRight>
        <Badge size="s" label={t("defaults.new")} />
        {t("commons:filterModalPartialProductFinder.automaticAssortment.showVeloconnectFilter")}
        <ToggleField
          size="s"
          labelYes={t("commons:filterModalPartialProductFinder.automaticAssortment.yesLabel")}
          labelNo={t("commons:filterModalPartialProductFinder.automaticAssortment.noLabel")}
          checked={isExtendedAvailabilitesFilterEnabled}
          disabled={shouldExtendedInterfaceBeDisabled}
          onChange={onToggle}
        />
      </FlexLayout>
    </>
  );
};

const AutomaticAssortmentFilters = <Type extends ProductType>({
  filterValues,
  activeFilters,
  onFilterChange,
  availabilityLocation
}: {
  filterValues: NonNullable<ProductFilterValues<Type>[ProductSpecKey.AutomaticAssortment]>;
  activeFilters: ActiveFilters<Type>;
  onFilterChange: (activeFilters: ActiveFilters<Type>) => void;
  availabilityLocation: AvailabilityFilterLocation;
}) => {
  const { t } = useTranslation(["commons"]);

  return (
    <FlexLayout direction="column" gap="s">
      {availabilityLocation === "everywhere" && (
        <Headline kind="minor">
          {t("commons:filterModalPartialProductFinder.automaticAssortment.dealerFilterHeadline")}
        </Headline>
      )}
      <FilterModalValues justifyContent="start" columnCount={2}>
        {filterValues.map(filterValue => {
          const filterKey = ProductSpecKey.AutomaticAssortment;
          const isActive = isActiveFilter(activeFilters, filterKey, filterValue.value);

          const handleClick = () =>
            onFilterChange(
              isActive
                ? removeActiveFilterValue(activeFilters, filterKey, filterValue.value)
                : addActiveFilterValue(activeFilters, filterKey, filterValue.value)
            );

          return (
            <CheckboxListItem
              key={`${filterKey}-${filterValue.value}`}
              outline
              label={filterValue.label}
              active={isActive}
              onClick={handleClick}
            />
          );
        })}
      </FilterModalValues>
    </FlexLayout>
  );
};

const SupplierFilters = <Type extends ProductType>({
  filterValues,
  activeFilters,
  onFilterChange,
  availabilityLocation
}: {
  filterValues: NonNullable<ProductFilterValues<Type>[ProductSpecKey.VeloconnectAssortment]>;
  activeFilters: ActiveFilters<Type>;
  onFilterChange: (activeFilters: ActiveFilters<Type>) => void;
  availabilityLocation: AvailabilityFilterLocation;
}) => {
  const { t } = useTranslation(["commons"]);

  return (
    <FlexLayout direction="column" gap="s">
      {availabilityLocation === "everywhere" && (
        <Headline kind="minor">
          {t("commons:filterModalPartialProductFinder.automaticAssortment.supplierFilterHeadline")}
        </Headline>
      )}
      <FilterModalValues justifyContent="start" columnCount={2}>
        {filterValues.map(filterValue => {
          const filterKey = ProductSpecKey.VeloconnectAssortment;
          const isActive = isActiveFilter(activeFilters, filterKey, filterValue.value);

          const handleClick = () =>
            onFilterChange(
              isActive
                ? removeActiveFilterValue(activeFilters, filterKey, filterValue.value)
                : addActiveFilterValue(activeFilters, filterKey, filterValue.value)
            );

          return (
            <CheckboxListItem
              key={`${filterKey}-${filterValue.value}`}
              outline
              label={filterValue.label}
              active={isActive}
              onClick={handleClick}
            />
          );
        })}
      </FilterModalValues>
    </FlexLayout>
  );
};

const ConfiguredBrands = ({ configuredVeloconnectEndpoints }: { configuredVeloconnectEndpoints: ClientConfig[] }) => {
  const { t } = useTranslation(["commons"]);
  const { data: activeBrands } = useActiveBrands();
  const configuredBrandKeys = configuredVeloconnectEndpoints.flatMap(endpoint => endpoint.brandKeys);
  const configuredAndActiveBrandKeys = intersection(
    configuredBrandKeys,
    activeBrands.map(brand => brand.key)
  );

  return (
    <FilterModalBrandsGrid
      description={t("commons:availabilityFilterModalPartialProductFinder.brandsWithSupplierAvailabilities")}
      brands={configuredAndActiveBrandKeys.map(brandKey => ({
        key: brandKey,
        logoSrc: getBrandLogoUrl(brandKey)
      }))}
    />
  );
};

type AvailabilityLocationMap = Array<{
  key: AvailabilityFilterLocation;
  label: string;
  image: string;
}>;

// Prefetch images in cache to avoid blocking the UI while the images are loading
const prefetchImages = async (locationMap: AvailabilityLocationMap): Promise<void> => {
  const promises = await Promise.all(
    locationMap.map(async location => {
      return new Promise(resolve => {
        const image = new window.Image();
        image.src = location.image;
        image.onload = resolve;
        image.onerror = resolve;
      });
    })
  );

  await Promise.all(promises);
};

const AvailabilityFilterModalPartial = <Type extends ProductType>({
  activeFilters,
  onFilterChange,
  clearFilter,
  automaticAssortmentFilterValues,
  veloconnectAssortmentFilterValues
}: Props<Type>) => {
  const veloconnectEndpoints = useVeloconnectEndpointsContext();
  const { t } = useTranslation(["commons"]);
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);

  const isExtendedAvailabilitesFilterEnabled = session.availabilityFilter.isSupplierFilterEnabled;
  const availabilityLocation = session.availabilityFilter.availabilityLocation;

  const { brand: brandKey } = useParams<{ brand?: string }>();
  const areMulitpleBrandsDisplayed = brandKey === undefined;

  const areCredentialsInvalid = useMemo(() => {
    if (veloconnectEndpoints) {
      if (brandKey) {
        const endpoint = veloconnectEndpoints.getEndpointByBrandKey(brandKey);
        return endpoint?.areCredentialsInvalid ?? false;
      } else {
        const { endpointsWithInvalidCredentials } = veloconnectEndpoints;
        return (endpointsWithInvalidCredentials ?? []).length > 0;
      }
    } else {
      return false;
    }
  }, [veloconnectEndpoints, brandKey]);

  const areSupplierAvailabilitesConfigurableForBrand = useMemo(() => {
    if (brandKey && veloconnectEndpoints) {
      const endpoint = veloconnectEndpoints.getEndpointByBrandKey(brandKey);
      if (endpoint) {
        return !endpoint.isConfigured && endpoint.isConfigurable;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [brandKey, veloconnectEndpoints]);

  const shouldExtendedInterfaceBeDisabled = useMemo(() => {
    if (!veloconnectEndpoints) {
      return true;
    }

    if (brandKey) {
      const endpoint = veloconnectEndpoints.getEndpointByBrandKey(brandKey);
      if (!endpoint) {
        return true;
      }

      const { isConfigured, areCredentialsInvalid } = endpoint;
      if (!isConfigured) {
        return true;
      } else {
        return !!areCredentialsInvalid;
      }
    } else {
      const { areAnyEndpointsConfigured, endpointsWithInvalidCredentials } = veloconnectEndpoints;

      return !areAnyEndpointsConfigured || (endpointsWithInvalidCredentials ?? [])?.length > 0;
    }
  }, [veloconnectEndpoints, brandKey]);

  const shouldRenderExtendedInterfaceToggle = React.useMemo(() => {
    if (!veloconnectEndpoints) {
      return false;
    }

    if (brandKey) {
      const endpoint = veloconnectEndpoints.getEndpointByBrandKey(brandKey);
      return !!endpoint?.isConfigured;
    } else {
      return veloconnectEndpoints?.areAnyEndpointsConfigured;
    }
  }, [veloconnectEndpoints, brandKey]);

  const shouldRenderExtendedAvailabilitiesFilter =
    isExtendedAvailabilitesFilterEnabled && !shouldExtendedInterfaceBeDisabled;

  const shouldRenderAutomaticAssortmentFilterValues = shouldRenderExtendedAvailabilitiesFilter
    ? availabilityLocation === "dealer" || availabilityLocation === "everywhere"
    : automaticAssortmentFilterValues.length > 0;

  const shouldRenderSupplierFilterValues =
    isExtendedAvailabilitesFilterEnabled &&
    !shouldExtendedInterfaceBeDisabled &&
    veloconnectAssortmentFilterValues.length > 0 &&
    (availabilityLocation === "supplier" || availabilityLocation === "everywhere");

  const locationMap: AvailabilityLocationMap = [
    {
      key: "dealer",
      label: t("commons:filterModalPartialProductFinder.automaticAssortment.locationMap.dealer"),
      image: imageLocationDealer
    },
    {
      key: "supplier",
      label: t("commons:filterModalPartialProductFinder.automaticAssortment.locationMap.supplier"),
      image: imageLocationSupplier
    },
    {
      key: "everywhere",
      label: t("commons:filterModalPartialProductFinder.automaticAssortment.locationMap.everywhere"),
      image: imageLocationEverywhere
    }
  ];

  useOnMount(() => {
    prefetchImages(locationMap);
  });

  /** Handles the availability location change and set default active filter values.
   * This is okay and does not overwrite the initial default active filters for the automatic assortment which are dependend on settings,
   * because `onChange` does not trigger on initial render.
   */
  const handleAvailabilityLocationChange = useCallback(
    (location: AvailabilityFilterLocation) => {
      dispatch(actions.session.setAvailabilityFilterLocation(location));

      switch (location) {
        case "dealer":
          onFilterChange(
            setActiveFilters(activeFilters, {
              [ProductSpecKey.AutomaticAssortment]:
                automaticAssortmentFilterValues.length > 0 ? [automaticAssortmentFilterValues[0].value] : [],
              [ProductSpecKey.VeloconnectAssortment]: []
            })
          );
          break;
        case "supplier":
          onFilterChange(
            setActiveFilters(activeFilters, {
              [ProductSpecKey.VeloconnectAssortment]:
                veloconnectAssortmentFilterValues.length > 0 ? [veloconnectAssortmentFilterValues[0].value] : [],
              [ProductSpecKey.AutomaticAssortment]: []
            })
          );
          break;
        case "everywhere":
          onFilterChange(
            setActiveFilters(activeFilters, {
              [ProductSpecKey.AutomaticAssortment]:
                automaticAssortmentFilterValues.length > 0 ? [automaticAssortmentFilterValues[0].value] : [],
              [ProductSpecKey.VeloconnectAssortment]:
                veloconnectAssortmentFilterValues.length > 0 ? [veloconnectAssortmentFilterValues[0].value] : []
            })
          );
          break;
      }
    },
    [dispatch, onFilterChange, activeFilters, automaticAssortmentFilterValues, veloconnectAssortmentFilterValues]
  );

  return (
    <FlexFooterLayout
      footer={
        <FlexLayout gap="l" direction="column">
          {shouldRenderExtendedInterfaceToggle && (
            <ExtendedInterfaceToggle
              isExtendedAvailabilitesFilterEnabled={isExtendedAvailabilitesFilterEnabled}
              shouldExtendedInterfaceBeDisabled={shouldExtendedInterfaceBeDisabled}
              onToggle={value => {
                dispatch(actions.session.setIsSupplierFilterEnabled(value));

                if (!value) {
                  dispatch(actions.session.setAvailabilityFilterLocation("dealer"));
                  clearFilter?.(ProductSpecKey.VeloconnectAssortment);
                }
              }}
            />
          )}

          {areMulitpleBrandsDisplayed &&
            veloconnectEndpoints?.configuredEndpoints &&
            veloconnectEndpoints.configuredEndpoints.length > 0 && (
              <ConfiguredBrands configuredVeloconnectEndpoints={veloconnectEndpoints.configuredEndpoints} />
            )}

          {areCredentialsInvalid && (
            <Note kind="negative" icon={<Icon source={IconSmallInputError} />}>
              <Trans
                t={t}
                i18nKey="commons:filterModalPartialProductFinder.automaticAssortment.invalidCredentialsNotification"
                components={{ linebreak: <br /> }}
              />
            </Note>
          )}

          {areSupplierAvailabilitesConfigurableForBrand && (
            <Note>
              <FlexLayout direction="column" gap="s" alignItems="flex-start">
                <Badge size="s" label={t("defaults.new")} />
                <Paragraph classNames={["u-width-60ch"]}>
                  <Trans
                    t={t}
                    i18nKey="commons:filterModalPartialProductFinder.automaticAssortment.supplierFilterConfigurableNotification"
                    components={{ linebreak: <br /> }}
                  />
                </Paragraph>
              </FlexLayout>
            </Note>
          )}
        </FlexLayout>
      }
    >
      <FlexLayout direction="column" gap="s">
        {shouldRenderExtendedAvailabilitiesFilter && (
          <RadioGroup<AvailabilityFilterLocation>
            gridCols={3}
            align="center"
            value={availabilityLocation}
            onChange={handleAvailabilityLocationChange}
          >
            {Object.values(locationMap).map(location => (
              <RadioFieldWithImage
                inlineRadio
                kind="support"
                key={location.key}
                image={<Image originalSize src={location.image} />}
                value={location.key}
                label={location.label}
              />
            ))}
          </RadioGroup>
        )}

        {shouldRenderAutomaticAssortmentFilterValues && (
          <AutomaticAssortmentFilters
            filterValues={automaticAssortmentFilterValues}
            activeFilters={activeFilters}
            onFilterChange={onFilterChange}
            availabilityLocation={availabilityLocation}
          />
        )}

        {shouldRenderSupplierFilterValues && (
          <SupplierFilters
            filterValues={veloconnectAssortmentFilterValues}
            activeFilters={activeFilters}
            onFilterChange={onFilterChange}
            availabilityLocation={availabilityLocation}
          />
        )}
      </FlexLayout>
    </FlexFooterLayout>
  );
};

export default AvailabilityFilterModalPartial;
