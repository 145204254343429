import React from "react";
import { capitalize } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import UAParser from "ua-parser-js";

import DeviceInformation from "../../../commons/components/DeviceInformation/DeviceInformation";
import Headline from "../../../commons/components/Headline/Headline";
import InformationList from "../../../commons/components/InformationList/InformationList";
import Text from "../../../commons/components/Text/Text";
import { connect } from "../../../commons/container/utils/loop";
import { getStoredAuthenticationCredentials } from "../../libs/credentials";
import { State } from "../../reducers";

const mapStateToProps = (state: State) => ({
  /* eslint-disable-next-line no-restricted-syntax --
   * Allow direct usage of `state.core.env?` before initialization
   * Device information can be rendered when an error is set before initialization is done
   **/
  serviceTag: state.core.env?.serviceTag
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OuterProps = {
  informationListVariant?: React.ComponentProps<typeof InformationList>["variant"];
  errorLog?: React.ReactNode;
  timestamp?: moment.Moment;
};

type Props = ConnectedProps<typeof connector> & OuterProps;

const DeviceInformationPartial = (props: Props) => {
  const { t } = useTranslation(["mobile"]);

  const storedAuthenticationCredentials = getStoredAuthenticationCredentials();

  const {
    errorLog,
    timestamp,
    serviceTag = storedAuthenticationCredentials.serviceTag,
    informationListVariant = "primary"
  } = props;

  const userAgent = UAParser(navigator.userAgent);

  return (
    <DeviceInformation>
      <Text>
        <Headline>{t("mobile:deviceInformationPartialApp.deviceInformationHeadline")}</Headline>
        <InformationList
          variant={informationListVariant}
          data={{
            ServiceTag: serviceTag ?? "",
            ["Device"]: `${userAgent.device.vendor} ${userAgent.device.model} (${capitalize(userAgent.device.type)})`,
            ["Browser"]: `${userAgent.browser.name} ${userAgent.browser.version}`,
            ["OS"]: `${userAgent.os.name} ${userAgent.os.version}`,
            ["Viewport"]: `${window.innerWidth}x${window.innerHeight}px`,
            ["Timestamp"]: timestamp ? timestamp.toString() : moment().toString(),
            ["License"]: `${
              storedAuthenticationCredentials.licenseId
            } ${storedAuthenticationCredentials.licenseToken?.slice(0, 8)}`
          }}
        />
      </Text>
      {errorLog}
    </DeviceInformation>
  );
};

export default connector(DeviceInformationPartial);
