import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import SpecsList from "../SpecsList/SpecsList";

interface Props extends Component {
  children: Array<React.ReactElement<React.ComponentProps<typeof SpecsList>>>;
  narrow?: boolean;
}

const SpecsListLayout = ({ classNames = [], children, narrow = false }: Props) => (
  <div className={cn("SpecsListLayout", [{ narrow }], classNames)}>{children}</div>
);

export default SpecsListLayout;
