import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import { setColorScheme } from "../externals/theme";
import * as selectors from "../selectors";

import useSelector from "./use-selector";

const useColorScheme = () => {
  const customerGroup = useSelector(state =>
    getCustomerGroupConfig(selectors.selectInitializedEnv(state).customerGroup)
  );
  setColorScheme(customerGroup.colorScheme);
};

export default useColorScheme;
