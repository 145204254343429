import {
  IpSettings,
  NetworkEntity,
  NetworkInterface,
  NetworkSettings,
  NetworkStatus,
  WifiNetworkSettings
} from "../../../commons/types/network-tool";
import { Action } from "../../../commons/types/redux";
import { PollErrorInterface } from "../libs/poll";

export type GetInterfaceStatusAction = Action<
  "NETWORK.GET_INTERFACE_STATUS",
  {
    name: string;
  }
>;
export type ReceivedInterfaceStatusAction = Action<"NETWORK.RECEIVED_INTERFACE_STATUS", NetworkInterface>;

export type ReceivedWifiNetworkListAction = Action<"NETWORK.RECEIVED_WIFI_NETWORK_LIST", NetworkEntity[]>;
export type ConnectInterfaceAction = Action<
  "NETWORK.CONNECT_INTERFACE",
  {
    name: string;
    settings: NetworkSettings | WifiNetworkSettings;
  }
>;
export type DisconnectInterfaceAction = Action<
  "NETWORK.DISCONNECT_INTERFACE",
  {
    name: string;
  }
>;
export type ConnectInterfaceFailedAction = Action<"NETWORK.CONNECT_INTERFACE_FAILED">;
export type ConnectInterfaceSucceededAction = Action<"NETWORK.CONNECT_INTERFACE_SUCCEEDED", NetworkStatus>;

export type ModifyInterfaceAction = Action<
  "NETWORK.MODIFY_INTERFACE",
  {
    name: string;
    settings: IpSettings;
  }
>;

export type EstablishConnectionAction = Action<"NETWORK.ESTABLISH_CONNECTION", { ssid: string }>;
export type ResetConnectionAttemptAction = Action<"NETWORK.RESET_CONNECTION_ATTEMPT">;

export type GetWifiNetworkListAction = Action<
  "NETWORK.GET_WIFI_NETWORK_LIST",
  {
    name: string;
  }
>;

export type StartPollWifiNetworksList = Action<
  "NETWORK.START_POLL_WIFI_NETWORK_LIST",
  {
    name: string;
    countdown?: number;
    isComplete: (status: NetworkEntity[]) => boolean;
  }
>;
export type StopPollWifiNetworksList = Action<"NETWORK.STOP_POLL_WIFI_NETWORK_LIST">;
export type AbortPollWifiNetworksList = Action<"NETWORK.ABORT_POLL_WIFI_NETWORK_LIST", PollErrorInterface>;

export type NetworkActions =
  | GetInterfaceStatusAction
  | ReceivedInterfaceStatusAction
  | ConnectInterfaceAction
  | ConnectInterfaceFailedAction
  | ConnectInterfaceSucceededAction
  | DisconnectInterfaceAction
  | EstablishConnectionAction
  | ResetConnectionAttemptAction
  | GetWifiNetworkListAction
  | ReceivedWifiNetworkListAction
  | StartPollWifiNetworksList
  | StopPollWifiNetworksList
  | AbortPollWifiNetworksList
  | ModifyInterfaceAction;

export const getInterfaceStatus = (name: string): GetInterfaceStatusAction => ({
  type: "NETWORK.GET_INTERFACE_STATUS",
  payload: { name }
});

export const receivedInterfaceStatus = (payload: NetworkInterface): ReceivedInterfaceStatusAction => ({
  type: "NETWORK.RECEIVED_INTERFACE_STATUS",
  payload
});

export const connectInterface = (
  name: string,
  settings: NetworkSettings | WifiNetworkSettings
): ConnectInterfaceAction => ({
  type: "NETWORK.CONNECT_INTERFACE",
  payload: {
    name,
    settings
  }
});

export const connectInterfaceFailed = (): ConnectInterfaceFailedAction => ({
  type: "NETWORK.CONNECT_INTERFACE_FAILED"
});

export const connectInterfaceSucceeded = (payload: NetworkStatus): ConnectInterfaceSucceededAction => ({
  type: "NETWORK.CONNECT_INTERFACE_SUCCEEDED",
  payload
});

export const modifyInterface = (name: string, settings: IpSettings): ModifyInterfaceAction => ({
  type: "NETWORK.MODIFY_INTERFACE",
  payload: {
    name,
    settings
  }
});

export const establishConnection = (ssid: string): EstablishConnectionAction => ({
  type: "NETWORK.ESTABLISH_CONNECTION",
  payload: {
    ssid
  }
});

export const resetConnectionAttempt = (): ResetConnectionAttemptAction => ({
  type: "NETWORK.RESET_CONNECTION_ATTEMPT"
});

export const disconnectInterface = (name: string): DisconnectInterfaceAction => ({
  type: "NETWORK.DISCONNECT_INTERFACE",
  payload: {
    name
  }
});

export const getWifiNetworksList = (name: string): GetWifiNetworkListAction => ({
  type: "NETWORK.GET_WIFI_NETWORK_LIST",
  payload: {
    name
  }
});

export const receivedWifiNetworksList = (payload: NetworkEntity[]): ReceivedWifiNetworkListAction => ({
  type: "NETWORK.RECEIVED_WIFI_NETWORK_LIST",
  payload
});

export const startPollWifiNetworksList = (
  name: string,
  isComplete: (status: NetworkEntity[]) => boolean = networks => Array.isArray(networks),
  countdown?: number
): StartPollWifiNetworksList => ({
  type: "NETWORK.START_POLL_WIFI_NETWORK_LIST",
  payload: {
    name,
    countdown,
    isComplete
  }
});

export const stopPollWifiNetworksList = (): StopPollWifiNetworksList => ({
  type: "NETWORK.STOP_POLL_WIFI_NETWORK_LIST"
});

export const abortPollWifiNetworksList = (payload: PollErrorInterface): AbortPollWifiNetworksList => ({
  type: "NETWORK.ABORT_POLL_WIFI_NETWORK_LIST",
  payload
});
