import {
  AuthModalContentProps,
  BodySizingModalContentProps,
  BrandsModelYearActivationModalContentProps,
  ErrorModalContentProps,
  ModalConfigOpened,
  ModalContentType,
  PdfViewerModalContentProps,
  PrintMailModalContentProps,
  ProductDetailsModalContentProps,
  PwaInformationModalContentProps
} from "../../../commons/types/modal";
import { Action } from "../../../commons/types/redux";

export type OpenModalAction = Action<"MODAL.OPEN", ModalConfigOpened>;
export type CloseModalAction = Action<"MODAL.CLOSE", void>;

export type ModalActions = OpenModalAction | CloseModalAction;

const open = (config: ModalConfigOpened): OpenModalAction => ({
  type: "MODAL.OPEN",
  payload: config
});

export const close = (): CloseModalAction => ({
  type: "MODAL.CLOSE",
  payload: undefined
});

export const openAuthModal = (contentProps: AuthModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.Auth, contentProps });

export const openPrintMailSwitchModal = (contentProps: PrintMailModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.PrintMailSwitch, contentProps });

export const openMailModal = (contentProps: PrintMailModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.Mail, contentProps });

export const openPrintModal = (contentProps: PrintMailModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.Print, contentProps });

export const openAboutModal = (): OpenModalAction =>
  open({ contentType: ModalContentType.About, contentProps: undefined });

export const openLegalInformationModal = (): OpenModalAction =>
  open({ contentType: ModalContentType.LegalInformation, contentProps: undefined });

export const openErrorModal = (contentProps: ErrorModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.Error, contentProps });

export const openPowerManagementModal = (): OpenModalAction =>
  open({ contentType: ModalContentType.PowerManagement, contentProps: undefined });

export const openProductDetailsModal = (contentProps: ProductDetailsModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.ProductDetails, contentProps });

export const openPwaInformationModal = (contentProps: PwaInformationModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.PwaInformation, contentProps });

export const openBodySizingModal = (contentProps: BodySizingModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.BodySizing, contentProps });

export const openPdfViewerModal = (contentProps: PdfViewerModalContentProps): OpenModalAction =>
  open({ contentType: ModalContentType.PdfViewer, contentProps });

export const openBrandsModelYearActivationModal = (
  contentProps: BrandsModelYearActivationModalContentProps
): OpenModalAction => open({ contentType: ModalContentType.BrandsModelYearActivation, contentProps });
