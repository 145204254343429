import { isError, isPlainObject } from "lodash";
import { Action } from "redux";

import { Actions } from "../actions";
import * as errorActions from "../actions/error";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";

import { State } from "./";

const NAME = "error";

export interface ErrorState {
  [NAME]: {
    hasError: boolean;
    errorMessage?: string;
    stack?: string;
  };
}

export const initialState: ErrorState = {
  [NAME]: {
    hasError: false,
    errorMessage: undefined,
    stack: undefined
  }
};

type ErrorInnerReducer<A extends Action = Actions> = InnerReducer<State, ErrorState[typeof NAME], A>;

const handleSet: ErrorInnerReducer<errorActions.SetAction> = (state, action) => {
  // Never overwrite previous error
  if (state[NAME].hasError) {
    return state[NAME];
  }

  const error = action.payload.error;

  const fallbackErrorMessage = "Es ist ein unbekannter Fehler aufgetreten.";

  if (isError(error)) {
    return {
      hasError: true,
      errorMessage: error.message || fallbackErrorMessage,
      stack: error.stack || ""
    };
  } else if (isPlainObject(error)) {
    return {
      hasError: true,
      errorMessage: (error as any).message || fallbackErrorMessage,
      stack: (error as any).stack || JSON.stringify(error, null, 2)
    };
  } else {
    return {
      hasError: true,
      errorMessage: error ? String(error) : fallbackErrorMessage,
      stack: ""
    };
  }
};

const handleReset: ErrorInnerReducer = () => ({
  ...initialState[NAME]
});

const reducer: InnerReducer<State, ErrorState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "ERROR.SET":
      return handleSet(state, action);
    case "ERROR.RESET":
      return handleReset(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
