import { Product, ProductId } from "../../../commons/specs/product";
import { Action } from "../../../commons/types/redux";

export type SetupCompareAction = Action<"BOOKMARKS.COMPARE_SETUP", { bookmarkId: string; products: Product[] }>;
export type ClearCompareAction = Action<"BOOKMARKS.COMPARE_CLEAR">;
export type AddProductToCompareAction = Action<"BOOKMARKS.COMPARE_ADD_PRODUCT", { productId: ProductId }>;
export type RemoveProductFromCompareAction = Action<"BOOKMARKS.COMPARE_REMOVE_PRODUCT", { productId: ProductId }>;

export type BookmarkActions =
  | SetupCompareAction
  | ClearCompareAction
  | AddProductToCompareAction
  | RemoveProductFromCompareAction;

export const setupCompare = (bookmarkId: string, products: Product[]): SetupCompareAction => ({
  type: "BOOKMARKS.COMPARE_SETUP",
  payload: {
    bookmarkId,
    products
  }
});

export const clearCompare = (): ClearCompareAction => ({
  type: "BOOKMARKS.COMPARE_CLEAR"
});

export const addProductToCompare = (productId: ProductId): AddProductToCompareAction => ({
  type: "BOOKMARKS.COMPARE_ADD_PRODUCT",
  payload: { productId }
});

export const removeProductFromCompare = (productId: ProductId): RemoveProductFromCompareAction => ({
  type: "BOOKMARKS.COMPARE_REMOVE_PRODUCT",
  payload: { productId }
});
