import { getProductPdfPreviewPath } from "../../../commons/libs/resource-paths";
import {
  PrintMailData,
  PrintType,
  ProductPrintData,
  ProductPrintDataItem,
  UrlPrintDataItem
} from "../../../commons/types/print-mail";
import { MailToPrintSettings } from "../../../commons/types/settings";
import { printFromUrl, printProductDataSheet } from "../../commons/libs/services/print-service/print";

export const createPdfFromUrlPreview = (data: UrlPrintDataItem): string =>
  `/print/url/preview/?url=${encodeURIComponent(data.url)}`;

export const createProductDataSheetPreview = (productItem: ProductPrintDataItem, data: ProductPrintData): string =>
  `/print/${getProductPdfPreviewPath(
    productItem.productId,
    data.bikeCenterName,
    data.showPrices,
    productItem.advertisement,
    data.currency,
    data.showAssortmentPrices,
    data.showFallbackPrices,
    data.showOriginalPrices,
    data.useRrpAsOriginalPrice,
    data.showSaleBadge
  )}`;

export const createPreviewUrl = (data: PrintMailData): string => {
  switch (data.type) {
    case PrintType.ProductDataSheet:
      return createProductDataSheetPreview(data.items[0], data);
    case PrintType.Default:
      return createPdfFromUrlPreview(data.items[0]);
  }
};

const print = (data: PrintMailData, numCopies: number, mailToPrintSettings?: MailToPrintSettings) => {
  switch (data.type) {
    case PrintType.Default:
      return printFromUrl(data, numCopies, mailToPrintSettings);
    case PrintType.ProductDataSheet:
      return printProductDataSheet(data, numCopies, mailToPrintSettings);
    default:
      throw new Error("PrintType not implemented");
  }
};

export default print;
