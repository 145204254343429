import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const ErrorBox = ({ classNames = [], children }: Props) => <p className={cn("ErrorBox", [], classNames)}>{children}</p>;

export default ErrorBox;
