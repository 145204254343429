import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

export type ClickHandler = (value: any) => void;

interface Props extends Component {
  value: any;
  label: React.ReactNode;
  selected?: boolean;
  onClick?: ClickHandler;
}

const SelectMenuItem = ({ classNames = [], value, label = null, selected = false, onClick = noop }: Props) => {
  const handleClick: React.EventHandler<React.MouseEvent<HTMLButtonElement>> = event => {
    event.preventDefault();
    if (onClick) {
      onClick(value);
    }
  };

  return (
    <button type="button" className={cn("SelectMenuItem", [{ selected }], classNames)} onClick={handleClick}>
      {label}
    </button>
  );
};

export default SelectMenuItem;
