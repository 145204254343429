import React from "react";

import { Component } from "../../../../commons/types/component";
import abstract1 from "../../../../resources/images/backgrounds/abstract-1.jpg";
import cn from "../../libs/class-name";

type Layout = "default" | "unbalanced-small" | "unbalanced-large" | "full" | "aside";

interface Props extends Component {
  children: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  aside?: React.ReactNode;
  backgroundImage?: string | null;
  layout?: Layout;
  contentImageSrc?: string;
  asideBottom?: boolean;
  hideScrollbar?: boolean;
  hideHeaderBackground?: boolean;
  noPaddingBottom?: boolean;
  scrollCanvasRef?: React.RefObject<HTMLDivElement>;
}

const MainContentLayout = React.forwardRef(
  (
    {
      classNames = [],
      children,
      headerLeft = null,
      headerRight = null,
      aside = null,
      backgroundImage = abstract1,
      layout = "default",
      contentImageSrc,
      asideBottom = false,
      hideScrollbar = false,
      hideHeaderBackground = false,
      noPaddingBottom = false,
      scrollCanvasRef
    }: Props,
    ref: React.ForwardedRef<HTMLElement>
  ) => (
    <section
      className={cn(
        "MainContentLayout",
        [
          {
            [`layout-${layout}`]: layout,
            asideBottom,
            hideScrollbar,
            noPaddingBottom,
            hideHeaderBackground
          }
        ],
        classNames
      )}
      style={backgroundImage ? { backgroundImage: `url(${backgroundImage}` } : {}}
      ref={ref}
    >
      <header className="MainContentLayout__header">
        <div className="MainContentLayout__headerLeft">{headerLeft}</div>
        <div className="MainContentLayout__headerRight">{headerRight}</div>
      </header>
      <div className="MainContentLayout__scrollCanvas" ref={scrollCanvasRef}>
        <div className="MainContentLayout__content">
          {children}
          {!!contentImageSrc && <img className="MainContentLayout__contentImage" src={contentImageSrc} />}
        </div>
      </div>
      {aside && <aside className="MainContentLayout__aside">{aside}</aside>}
    </section>
  )
);

MainContentLayout.displayName = "MainContentLayout";

export default MainContentLayout;
