import React from "react";

import { Component } from "../../../../commons/types/component";
import { KeyboardType } from "../../../../commons/types/keyboard";
import { IconSmallAsterisk } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

import PinFieldDigit from "./PinFieldDigit";

// Note: This input element has a different interface than reacts native element
type ChangeHandler = (value: string) => void;

const DEFAULT_LENGTH = 6;

interface Props extends Component {
  value?: string;
  name?: string;
  length?: number;
  autoFocus?: boolean;
  error?: boolean;
  pinVisible?: boolean;
  onChange?: ChangeHandler;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

interface State {
  focus: boolean;
}

export default class PinField extends React.PureComponent<Props, State> {
  private pinFieldInput = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      focus: props.autoFocus ?? false
    };
  }

  public componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        if (this.pinFieldInput.current) {
          this.pinFieldInput.current.focus();
          this.handleFocus();
        }
      }, 500);
    }
  }

  public componentWillUnmount() {
    if (this.props.autoFocus && this.pinFieldInput.current) {
      this.pinFieldInput.current.blur();
      this.handleBlur();
    }
  }

  public handleBlur = (event?: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur && event) {
      this.props.onBlur(event);
    }
    this.setState({ focus: false });
  };
  public handleFocus = () => this.setState({ focus: true });

  public handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
    const { length = DEFAULT_LENGTH } = this.props;
    const nextValue = event.currentTarget.value.substring(0, length);
    this.props.onChange?.(nextValue);
  };

  public render() {
    const {
      classNames = [],
      value = "",
      name = "",
      length = DEFAULT_LENGTH,
      error = false,
      pinVisible = false
    } = this.props;

    return (
      <div className={cn("PinField", [{ pinVisible, error }], classNames)} data-keyboard-input>
        <input
          data-keyboard-type={KeyboardType.Pin}
          ref={this.pinFieldInput}
          type="text"
          className="PinField__input"
          value={value}
          // To detect the onscreen keyboard changes we need to listen to onKeyDown in all inputs
          onKeyDown={this.handleChange}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          maxLength={length}
          name={name}
        />
        {Array.from({ length }, (val, index) => index).map(index => {
          const emptyValue = "";
          const hiddenValue = <Icon source={IconSmallAsterisk} />;
          const indexValue = value[index];

          const digitValue = pinVisible
            ? indexValue
              ? indexValue
              : emptyValue
            : indexValue
              ? hiddenValue
              : emptyValue;
          const focus = this.state.focus && Math.min(value.length, length - 1) === index;

          return <PinFieldDigit key={`${index}${digitValue}`} value={digitValue} focus={focus} error={error} />;
        })}
      </div>
    );
  }
}
