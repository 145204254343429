import { strict as assert } from "assert";
import React from "react";
import { push } from "connected-react-router";
import { Trans, useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import {
  getSupplierAvailabilityState,
  getWawiAvailabilityState,
  getWawiLocationAvailabilityState
} from "../../../../commons/libs/availability";
import envelope from "../../../../commons/libs/externals/envelope";
import { byDefinedKeys } from "../../../../commons/libs/filter-guards";
import { formatDate, getSpecFormatter } from "../../../../commons/libs/formatters";
import { getProductImageUrl } from "../../../../commons/libs/resource-paths";
import {
  calcRelevantVariantSpecKeys,
  getProductVariants,
  getStandaloneProductVariant
} from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { Product, ProductKey, ProductSpecKey, StandaloneProductVariant } from "../../../../commons/specs/product";
import { AvailabilityType } from "../../../../commons/types/availability";
import { AvailabilityPrioritization } from "../../../../commons/types/settings";
import { VeloconnectErrorType } from "../../../../commons/types/veloconnect";
import { GetWawiProductAvailabiltyResponse, WawiErrorType } from "../../../../commons/types/wawi";
import * as icons from "../../../../resources/icons";
import Availability from "../../../commons/components/Availability/Availability";
import AvailabilityList from "../../../commons/components/AvailabilityList/AvailabilityList";
import Button from "../../../commons/components/Button/Button";
import DescriptionList from "../../../commons/components/DescriptionList/DescriptionList";
import FoldoutDescription from "../../../commons/components/FoldoutDescription/FoldoutDescription";
import Headline from "../../../commons/components/Headline/Headline";
import Icon from "../../../commons/components/Icon/Icon";
import LoadingIndicator from "../../../commons/components/LoadingIndicator/LoadingIndicator";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";
import VariantAvailabilityError from "../../../commons/components/VariantAvailabilityError/VariantAvailabilityError";
import VariantAnnotation from "../../../commons/components/VariantInformationBox/VariantAnnotation";
import VariantInformationBox from "../../../commons/components/VariantInformationBox/VariantInformationBox";
import { connect } from "../../../commons/container/utils/loop";
import { FetchState } from "../../../commons/libs/hooks/use-fetch-data";
import { ROUTES } from "../../../commons/routes";
import { UseVeloconnectProductAvailabilitiesReturn } from "../../libs/hooks/use-veloconnect-product-availabilities";
import { selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";
import { useVeloconnectEndpointContext } from "../utils/veloconnect-endpoint-context";

const mapStateToProps = (state: State) => ({
  customization: selectInitializedSettings(state).customization
});

const mapDispatchToProps = {
  onPush: push
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  product: Product;
  productVariant: StandaloneProductVariant;
  hasInternetConnectivity: boolean;
  isVeloconnectActive: boolean;
  veloconnectAvailabilities?: UseVeloconnectProductAvailabilitiesReturn;
  veloconnectError?: VeloconnectErrorType;
  isWawiActive: boolean;
  wawiProductAvailabilities?: FetchState<GetWawiProductAvailabiltyResponse>;
  wawiError?: WawiErrorType;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const VariantInformationBoxPartial = ({
  customization,
  product,
  productVariant,
  hasInternetConnectivity,
  isVeloconnectActive,
  veloconnectAvailabilities,
  veloconnectError,
  isWawiActive,
  wawiProductAvailabilities,
  wawiError,
  onPush
}: Props) => {
  const veloconnectEndpoint = useVeloconnectEndpointContext();

  const { t, i18n } = useTranslation(["commons"]);
  const locale = i18n.language;

  const { isMobileBuild } = envelope;

  const productType = product[ProductKey.ProductType];
  const specConfig = getSpecConfig(productType);

  const specFormatter = getSpecFormatter(productType, specConfig.specDefinitions, i18n, customization);

  const productVariants = getProductVariants(product);
  const variantFromProduct = productVariants.find(
    variant => variant[ProductKey.VariantId] === productVariant[ProductKey.VariantId]
  );
  assert(variantFromProduct, "Variant not found on product");

  const relevantVariantSpecKeys = calcRelevantVariantSpecKeys(
    [productVariants.length > 1 ? variantFromProduct : getStandaloneProductVariant(product, variantFromProduct)],
    specConfig.specDefinitions,
    "variantInformationBox",
    specConfig.variantInformationBoxSpecKeyPriorityList
  );

  const selectedVeloconnectProductAvailabilities = (veloconnectAvailabilities?.availabilities || []).find(
    variant => variant.variantId === productVariant[ProductKey.VariantId]
  );
  const selectedWawiProductAvailabilitiesVariant = (wawiProductAvailabilities?.data?.variants ?? []).find(
    variant => variant.variantId === productVariant[ProductKey.VariantId]
  );

  const firstVeloconnectAvailability = selectedVeloconnectProductAvailabilities?.availabilities[0];

  const wawiAvailabilityState =
    selectedWawiProductAvailabilitiesVariant &&
    getWawiAvailabilityState(selectedWawiProductAvailabilitiesVariant, {
      preferSupplier:
        customization.availabilityPrioritizationOptions?.availabilityPrioritization ===
        AvailabilityPrioritization.Supplier
    });

  const supplierAvailabilityState = getSupplierAvailabilityState(
    selectedWawiProductAvailabilitiesVariant,
    selectedVeloconnectProductAvailabilities
  );

  const handleLinkClick = (_event: React.MouseEvent<HTMLElement>, href: string) => {
    onPush(href);
  };

  const renderSupplierAnnotation = () => {
    const renderAvailability = () => {
      if (wawiProductAvailabilities?.isLoading || veloconnectAvailabilities?.isLoading) {
        return (
          <LoadingIndicator
            size="s"
            label={t("commons:variantInformationBoxPartialProductDetails.supplierAvailability.loadingLabel")}
          />
        );
      }

      if (!veloconnectEndpoint.endpoint) {
        return (
          <Availability
            kind={AvailabilityType.Unknown}
            label={t("commons:variantsContentPartialProductDetails.availabilityStatus.noAvailabilityData")}
          />
        );
      } else {
        if (!veloconnectEndpoint.endpoint?.isConfigured) {
          return (
            <Availability
              kind={AvailabilityType.Unknown}
              label={t("commons:variantsContentPartialProductDetails.availabilityStatus.noAvailabilityData")}
            />
          );
        } else if (veloconnectEndpoint.endpoint?.areCredentialsInvalid) {
          return (
            <Availability
              kind={AvailabilityType.Error}
              label={t(
                "commons:variantInformationBoxPartialProductDetails.supplierAvailability.invalidCredentialsLabel"
              )}
            />
          );
        } else if (!veloconnectEndpoint.isImportReady) {
          return (
            <Availability
              kind={AvailabilityType.Error}
              label={t("commons:variantInformationBoxPartialProductDetails.supplierAvailability.importIsNotReadyYet")}
            />
          );
        } else if (veloconnectEndpoint.isImportOutdated) {
          return (
            <Availability
              kind={AvailabilityType.Error}
              label={t("commons:variantInformationBoxPartialProductDetails.supplierAvailability.lastImportIsOutdated")}
            />
          );
        } else if (supplierAvailabilityState) {
          return (
            <Availability
              kind={supplierAvailabilityState.type}
              label={t(
                `commons:variantsContentPartialProductDetails.availabilityStatus.${supplierAvailabilityState.status}`,
                {
                  expectedDeliveryDate:
                    supplierAvailabilityState.expectedDeliveryDate &&
                    formatDate(supplierAvailabilityState.expectedDeliveryDate, locale),
                  warehouseLocation: supplierAvailabilityState.warehouseLocation
                }
              )}
            />
          );
        }
      }
    };

    const renderTooltipContent = () => {
      if (!veloconnectEndpoint.endpoint) {
        return (
          <VariantAvailabilityError
            kind={AvailabilityType.Unknown}
            icon={<Icon block source={icons.IconSmallAttention} />}
            headline={
              <Headline kind="xs">
                {t(
                  "commons:variantInformationBoxPartialProductDetails.supplierTooltip.availabilityNotPossibleHeadline"
                )}
              </Headline>
            }
            paragraph={
              <>
                <Paragraph kind="support" classNames={["u-space-base"]}>
                  <Trans
                    t={t}
                    i18nKey="commons:variantInformationBoxPartialProductDetails.supplierTooltip.availabilityNotPossible"
                    components={{ linebreak: <br /> }}
                    values={{ brandName: product[ProductSpecKey.BrandName] }}
                  />
                </Paragraph>
              </>
            }
          />
        );
      } else {
        if (!veloconnectEndpoint.endpoint?.isConfigured) {
          return (
            <VariantAvailabilityError
              kind={AvailabilityType.Unknown}
              icon={<Icon block source={icons.IconSmallAttention} />}
              headline={
                <Headline kind="xs">
                  {t(
                    "commons:variantInformationBoxPartialProductDetails.supplierTooltip.availabilityIsNotConfiguredHeadline"
                  )}
                </Headline>
              }
              paragraph={
                <>
                  <Paragraph kind="support" classNames={["u-space-base"]}>
                    <Trans
                      t={t}
                      i18nKey="commons:variantInformationBoxPartialProductDetails.supplierTooltip.availabilityIsNotConfigured"
                      components={{ linebreak: <br /> }}
                      values={{ brandName: product[ProductSpecKey.BrandName] }}
                    />
                  </Paragraph>
                  {!isMobileBuild && (
                    <Button
                      iconRight
                      size="s"
                      icon={<Icon source={icons.IconSmallArrowRight} />}
                      onClick={event => handleLinkClick(event, ROUTES.SETTINGS.VELOCONNECT)}
                    >
                      {t(
                        "commons:variantInformationBoxPartialProductDetails.supplierTooltip.availabilityNotPossibleButton"
                      )}
                    </Button>
                  )}
                </>
              }
            />
          );
        } else if (veloconnectEndpoint.endpoint?.areCredentialsInvalid) {
          return (
            <VariantAvailabilityError
              kind={AvailabilityType.Error}
              icon={<Icon block source={icons.IconSmallAttention} />}
              headline={
                <Headline kind="xs">
                  {t("commons:variantInformationBoxPartialProductDetails.supplierTooltip.invalidCredentialsHeadline")}
                </Headline>
              }
              paragraph={
                <>
                  <Paragraph kind="support" classNames={["u-space-base"]}>
                    <Trans
                      t={t}
                      i18nKey="commons:variantInformationBoxPartialProductDetails.supplierTooltip.credentialsExpired"
                      components={{ linebreak: <br /> }}
                    />
                  </Paragraph>
                  {!isMobileBuild && (
                    <Button
                      iconRight
                      size="s"
                      icon={<Icon source={icons.IconSmallArrowRight} />}
                      onClick={event => handleLinkClick(event, ROUTES.SETTINGS.VELOCONNECT)}
                    >
                      {t("commons:variantInformationBoxPartialProductDetails.supplierTooltip.checkCredentialsButton")}
                    </Button>
                  )}
                </>
              }
            />
          );
        } else if (!veloconnectEndpoint.isImportReady) {
          return (
            <VariantAvailabilityError
              kind={AvailabilityType.Error}
              icon={<Icon block source={icons.IconSmallAttention} />}
              headline={
                <Headline kind="xs">
                  {t("commons:variantInformationBoxPartialProductDetails.supplierTooltip.importIsNotReadyYetHeadline")}
                </Headline>
              }
              paragraph={
                <>
                  <Paragraph kind="support" classNames={["u-space-base"]}>
                    <Trans
                      t={t}
                      i18nKey="commons:variantInformationBoxPartialProductDetails.supplierTooltip.importIsNotReadyYetExplanation"
                      components={{ linebreak: <br /> }}
                    />
                  </Paragraph>
                  {!isMobileBuild && (
                    <Button
                      iconRight
                      size="s"
                      icon={<Icon source={icons.IconSmallArrowRight} />}
                      onClick={event => handleLinkClick(event, ROUTES.SETTINGS.SUPPORT)}
                    >
                      {t(
                        "commons:variantInformationBoxPartialProductDetails.supplierTooltip.importIsNotReadyYetContactSupportButton"
                      )}
                    </Button>
                  )}
                </>
              }
            />
          );
        } else if (veloconnectEndpoint.isImportOutdated) {
          return (
            <VariantAvailabilityError
              kind={AvailabilityType.Error}
              icon={<Icon block source={icons.IconSmallAttention} />}
              headline={
                <Headline kind="xs">
                  {t("commons:variantInformationBoxPartialProductDetails.supplierTooltip.lastImportOutdatedHeadline")}
                </Headline>
              }
              paragraph={
                <>
                  <Paragraph kind="support" classNames={["u-space-base"]}>
                    <Trans
                      t={t}
                      i18nKey="commons:variantInformationBoxPartialProductDetails.supplierTooltip.lastImportOutdatedExplanation"
                      components={{ linebreak: <br /> }}
                    />
                  </Paragraph>
                  {!isMobileBuild && (
                    <Button
                      iconRight
                      size="s"
                      icon={<Icon source={icons.IconSmallArrowRight} />}
                      onClick={event => handleLinkClick(event, ROUTES.SETTINGS.SUPPORT)}
                    >
                      {t(
                        "commons:variantInformationBoxPartialProductDetails.supplierTooltip.lastImportOutdatedContactSupportButton"
                      )}
                    </Button>
                  )}
                </>
              }
            />
          );
        } else if (supplierAvailabilityState?.itemUnknown) {
          return (
            <VariantAvailabilityError
              kind={supplierAvailabilityState?.type}
              icon={<Icon block source={icons.IconSmallAttention} />}
              headline={
                <Headline kind="xs">
                  {t("commons:variantInformationBoxPartialProductDetails.supplierTooltip.productNotFoundHeadline")}
                </Headline>
              }
              paragraph={
                <Paragraph kind="support">
                  <Trans
                    t={t}
                    i18nKey="commons:variantInformationBoxPartialProductDetails.supplierTooltip.productNotFound"
                    components={{ linebreak: <br /> }}
                  />
                </Paragraph>
              }
            />
          );
        }
      }
    };

    const tooltipContent = renderTooltipContent();

    const isConfiguredButHasFaults = !!(
      veloconnectEndpoint.endpoint &&
      veloconnectEndpoint.endpoint.isConfigured &&
      (veloconnectEndpoint.endpoint.areCredentialsInvalid ||
        !veloconnectEndpoint.isImportReady ||
        veloconnectEndpoint.isImportOutdated)
    );

    return (
      <VariantAnnotation
        label={t("commons:variantInformationBoxPartialProductDetails.supplierLabel")}
        availability={renderAvailability()}
        icon={!!tooltipContent ? <Icon source={icons.IconSmallAttention} /> : undefined}
        tooltipContent={tooltipContent}
        error={(hasInternetConnectivity && isConfiguredButHasFaults) || !!veloconnectError}
      />
    );
  };

  const renderWawiAnnotation = () => {
    const renderAvailability = () => {
      if (wawiError === WawiErrorType.Other) {
        return (
          <Availability
            kind={AvailabilityType.Error}
            label={t("commons:variantInformationBoxPartialProductDetails.erpAvailability.serverNotReachableLabel")}
          />
        );
      } else if (wawiError === WawiErrorType.InvalidProductIdentification) {
        return (
          <Availability
            kind={AvailabilityType.Error}
            label={t("commons:variantInformationBoxPartialProductDetails.erpAvailability.insufficientDataQualityLabel")}
          />
        );
      } else if (wawiError === WawiErrorType.InvalidCredentials) {
        return (
          <Availability
            kind={AvailabilityType.Error}
            label={t("commons:variantInformationBoxPartialProductDetails.erpAvailability.invalidCredentialsLabel")}
          />
        );
      } else if (!selectedWawiProductAvailabilitiesVariant) {
        return (
          <Availability
            kind={AvailabilityType.Unknown}
            label={t("commons:variantInformationBoxPartialProductDetails.erpAvailability.noAvailabilityDataLabel")}
          />
        );
      } else if (wawiAvailabilityState) {
        return (
          <Availability
            kind={wawiAvailabilityState.type}
            label={t(
              `commons:variantsContentPartialProductDetails.availabilityStatus.${wawiAvailabilityState.status}`,
              {
                expectedDeliveryDate:
                  wawiAvailabilityState.expectedDeliveryDate &&
                  formatDate(wawiAvailabilityState.expectedDeliveryDate, locale),
                warehouseLocation: wawiAvailabilityState.warehouseLocation
              }
            )}
          />
        );
      } else {
        return (
          <LoadingIndicator
            size="s"
            label={t("commons:variantInformationBoxPartialProductDetails.erpAvailability.loadingLabel")}
          />
        );
      }
    };

    const renderTooltipContent = () => {
      if (wawiError === WawiErrorType.Other) {
        return (
          <VariantAvailabilityError
            kind={AvailabilityType.Error}
            icon={<Icon block source={icons.IconSmallAttention} />}
            headline={
              <Headline kind="xs">
                {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.serverNotReachableHeadline")}
              </Headline>
            }
            paragraph={
              <Paragraph kind="support">
                <Trans
                  t={t}
                  i18nKey="commons:variantInformationBoxPartialProductDetails.erpTooltip.availabilityRequestUnavailable"
                  components={{ linebreak: <br /> }}
                />
              </Paragraph>
            }
          />
        );
      } else if (wawiError === WawiErrorType.InvalidProductIdentification) {
        return (
          <VariantAvailabilityError
            kind={AvailabilityType.Error}
            icon={<Icon block source={icons.IconSmallSmileySad} />}
            headline={
              <Headline kind="xs">
                {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.insufficientDataQualityHeadline")}
              </Headline>
            }
            paragraph={
              <Paragraph kind="support">
                {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.insufficientDataQuality", {
                  brandName: specFormatter.formatVariantValue(ProductSpecKey.BrandName, productVariant)
                })}
              </Paragraph>
            }
          />
        );
      } else if (wawiError === WawiErrorType.InvalidCredentials) {
        return (
          <VariantAvailabilityError
            kind={AvailabilityType.Error}
            icon={<Icon block source={icons.IconSmallAttention} />}
            headline={
              <Headline kind="xs">
                {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.invalidCredentialsHeadline")}
              </Headline>
            }
            paragraph={
              <>
                <Paragraph kind="support" classNames={["u-space-base"]}>
                  <Trans
                    t={t}
                    i18nKey="commons:variantInformationBoxPartialProductDetails.erpTooltip.credentialsExpired"
                    components={{ linebreak: <br /> }}
                  />
                </Paragraph>
                {!isMobileBuild && (
                  <Button
                    iconRight
                    size="s"
                    icon={<Icon source={icons.IconSmallArrowRight} />}
                    onClick={event => handleLinkClick(event, ROUTES.SETTINGS.WAWI_INDEX)}
                  >
                    {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.checkCredentialsButton")}
                  </Button>
                )}
              </>
            }
          />
        );
      } else if (selectedWawiProductAvailabilitiesVariant?.itemAmbiguous) {
        return (
          <VariantAvailabilityError
            kind={wawiAvailabilityState && wawiAvailabilityState.type}
            icon={<Icon block source={icons.IconSmallAttention} />}
            headline={
              <Headline kind="xs">
                {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.foundMultipleHeadline")}
              </Headline>
            }
            paragraph={
              <Paragraph kind="support">
                <Trans
                  t={t}
                  i18nKey="commons:variantInformationBoxPartialProductDetails.erpTooltip.foundProductMultipleTimes"
                  components={{ linebreak: <br /> }}
                />
              </Paragraph>
            }
          />
        );
      } else if (!selectedWawiProductAvailabilitiesVariant || selectedWawiProductAvailabilitiesVariant?.itemUnknown) {
        return (
          <VariantAvailabilityError
            kind={wawiAvailabilityState && wawiAvailabilityState.type}
            icon={<Icon block source={icons.IconSmallAttention} />}
            headline={
              <Headline kind="xs">
                {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.productNotFoundHeadline")}
              </Headline>
            }
            paragraph={
              <Paragraph kind="support">
                <Trans
                  t={t}
                  i18nKey="commons:variantInformationBoxPartialProductDetails.erpTooltip.productNotFound"
                  components={{ linebreak: <br /> }}
                />
              </Paragraph>
            }
          />
        );
      } else if (selectedWawiProductAvailabilitiesVariant) {
        const availableWawiProductVariants = selectedWawiProductAvailabilitiesVariant.availabilities.filter(
          ({ productCount, orderedCount }) => productCount > 0 || (orderedCount && orderedCount > 0)
        );

        if (availableWawiProductVariants.length > 0) {
          return (
            <AvailabilityList>
              {availableWawiProductVariants
                .map(availability => getWawiLocationAvailabilityState(availability))
                .map((transformed, index) => (
                  <Availability
                    key={index}
                    kind={transformed.type}
                    label={t(`commons:variantsContentPartialProductDetails.availabilityStatus.${transformed.status}`, {
                      expectedDeliveryDate:
                        transformed.expectedDeliveryDate && formatDate(transformed.expectedDeliveryDate, locale),
                      warehouseLocation: transformed.warehouseLocation
                    })}
                    block
                  />
                ))}
            </AvailabilityList>
          );
        } else {
          return (
            <VariantAvailabilityError
              kind={wawiAvailabilityState && wawiAvailabilityState.type}
              icon={<Icon block source={icons.IconSmallAttention} />}
              headline={
                <Headline kind="xs">
                  {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.productNotAvailableHeadline")}
                </Headline>
              }
              paragraph={
                <Paragraph kind="support">
                  {t("commons:variantInformationBoxPartialProductDetails.erpTooltip.productNotAvailable")}
                </Paragraph>
              }
            />
          );
        }
      }
    };

    const tooltipContent = renderTooltipContent();

    return (
      <VariantAnnotation
        label={t("commons:variantInformationBoxPartialProductDetails.localLabel")}
        availability={renderAvailability()}
        icon={!!tooltipContent ? <Icon source={icons.IconSmallAttention} /> : undefined}
        tooltipContent={tooltipContent}
      />
    );
  };

  const renderOfflineAnnotation = () => {
    return (
      <VariantAnnotation
        error
        label={t("commons:variantInformationBoxPartialProductDetails.offlineLabel")}
        availability={
          <Availability
            kind={AvailabilityType.Error}
            label={t("commons:variantInformationBoxPartialProductDetails.deviceOfflineLabel")}
          />
        }
        icon={<Icon source={icons.IconSmallAttention} />}
        tooltipContent={
          <VariantAvailabilityError
            kind={AvailabilityType.Error}
            icon={<Icon block source={icons.IconSmallOffline} />}
            headline={
              <Headline kind="xs">
                {t("commons:variantInformationBoxPartialProductDetails.offlineTooltip.offlineHeadline")}
              </Headline>
            }
            paragraph={
              <Paragraph kind="support">
                <Trans
                  t={t}
                  i18nKey="commons:variantInformationBoxPartialProductDetails.offlineTooltip.deviceOfflineRequestNotPossible"
                  components={{ linebreak: <br /> }}
                />
              </Paragraph>
            }
          />
        }
      />
    );
  };

  const renderAuthOrOtherErrorAnnotation = () => {
    return (
      <VariantAnnotation
        error
        label={t("commons:variantInformationBoxPartialProductDetails.supplierLabel")}
        availability={
          <Availability
            kind={AvailabilityType.Error}
            label={t("commons:variantInformationBoxPartialProductDetails.authOrOtherErrorAvailability.label")}
          />
        }
        icon={<Icon source={icons.IconSmallAttention} />}
        tooltipContent={
          <VariantAvailabilityError
            kind={AvailabilityType.Error}
            icon={<Icon block source={icons.IconSmallOffline} />}
            headline={
              <Headline kind="xs">
                {t("commons:variantInformationBoxPartialProductDetails.authOrOtherErrorTooltip.headline")}
              </Headline>
            }
            paragraph={
              <>
                <Paragraph kind="support" classNames={["u-space-base"]}>
                  <Trans
                    t={t}
                    i18nKey="commons:variantInformationBoxPartialProductDetails.authOrOtherErrorTooltip.explanation"
                    components={{ linebreak: <br /> }}
                  />
                </Paragraph>
                {!isMobileBuild && (
                  <Button
                    iconRight
                    size="s"
                    icon={<Icon source={icons.IconSmallArrowRight} />}
                    onClick={event => handleLinkClick(event, ROUTES.SETTINGS.SUPPORT)}
                  >
                    {t("commons:variantInformationBoxPartialProductDetails.authOrOtherErrorTooltip.button")}
                  </Button>
                )}
              </>
            }
          />
        }
      />
    );
  };

  const renderTopAnnotation = () => {
    if (!hasInternetConnectivity) {
      return renderOfflineAnnotation();
    } else if (
      veloconnectError === VeloconnectErrorType.AuthorizationFailed ||
      veloconnectError === VeloconnectErrorType.Other
    ) {
      return renderAuthOrOtherErrorAnnotation();
    } else if (isVeloconnectActive || isWawiActive) {
      return renderSupplierAnnotation();
    } else {
      return null;
    }
  };

  const renderBottomAnnotation = () => {
    if (!hasInternetConnectivity) {
      return null;
    } else if (isWawiActive) {
      return renderWawiAnnotation();
    } else {
      return null;
    }
  };

  return (
    <VariantInformationBox
      imgSource={getProductImageUrl(productVariant, {}, false)}
      fallbackSource={getProductImageUrl(product)}
      topAnnotation={renderTopAnnotation()}
      bottomAnnotation={renderBottomAnnotation()}
    >
      {firstVeloconnectAvailability?.description && (
        <DescriptionList>
          <dt>{t("commons:variantInformationBoxPartialProductDetails.productInformationManufacturer")}</dt>
          <dd>
            <FoldoutDescription
              lines={4}
              labelButton={t("commons:variantInformationBoxPartialProductDetails.moreButton")}
            >
              {firstVeloconnectAvailability.description}
            </FoldoutDescription>
          </dd>
        </DescriptionList>
      )}
      <DescriptionList>
        {relevantVariantSpecKeys
          .map(specKey => ({
            specKey,
            label: specFormatter.formatLabel(specKey),
            value: specFormatter.formatVariantValue(specKey, productVariant)
          }))
          .filter(byDefinedKeys("value", "label"))
          .map(({ specKey, label, value }) => (
            <React.Fragment key={specKey}>
              <dt>{label}</dt>
              <dd>{value}</dd>
            </React.Fragment>
          ))}
      </DescriptionList>
    </VariantInformationBox>
  );
};

export default connector(VariantInformationBoxPartial);
