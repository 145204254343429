import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useScrollObserver, { ScrollPosition } from "../../libs/hooks/use-scroll-observer";
import { countChildrenWithFragments } from "../../libs/react-utils";
import Headline from "../Headline/Headline";

interface Props extends Component {
  filters?: React.ReactNode;
  headline: string;
  children: React.ReactNode;
  selectedVariant: React.ReactNode;
  noSeparationShadow?: boolean;
  noTopSpacing?: boolean;
}

const AvailabilityLayout = ({
  classNames = [],
  filters,
  children,
  headline,
  selectedVariant,
  noSeparationShadow,
  noTopSpacing
}: Props) => {
  const childrenCount = countChildrenWithFragments(children);
  const filtersCount = countChildrenWithFragments(filters);

  const variantsScrollContainerRef = React.useRef<HTMLDivElement>(null);
  const { isScrollable, scrollPosition } = useScrollObserver(variantsScrollContainerRef, {
    shouldObserveResizeEvents: true,
    deps: [childrenCount]
  });

  const modifiers = {
    [`filtersCount-${filtersCount}`]: !!filtersCount,
    isHorizontallyScrollable: isScrollable.horizontal,
    isVerticallyScrollable: isScrollable.vertical,
    isScrolledToHorizontalEnd: !isScrollable.horizontal || scrollPosition.horizontal === ScrollPosition.End,
    isScrolledToVerticalEnd: !isScrollable.vertical || scrollPosition.vertical === ScrollPosition.End,
    noSeparationShadow,
    noTopSpacing
  };

  return (
    <div className={cn("AvailabilityLayout", [modifiers], classNames)}>
      <div className="AvailabilityLayout__content">
        <div className="AvailabilityLayout__headline">
          <Headline>{headline}</Headline>
        </div>
        {filters && <div className="AvailabilityLayout__filters">{filters}</div>}
        <div className="AvailabilityLayout__variants">
          <div className="AvailabilityLayout__variantsScrollContainer" ref={variantsScrollContainerRef}>
            {children}
          </div>
          <div className="AvailabilityLayout__scrollIndicator AvailabilityLayout__scrollIndicator--horizontal" />
          <div className="AvailabilityLayout__scrollIndicator AvailabilityLayout__scrollIndicator--vertical" />
        </div>
      </div>
      <div className="AvailabilityLayout__variantInformation">{selectedVariant}</div>
    </div>
  );
};

export default AvailabilityLayout;
