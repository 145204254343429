import { useMemo } from "react";

import { getSettings } from "../../../../commons/libs/settings-service";
import { AssortmentType } from "../../../../commons/types/assortment";
import { AssortmentPriceSettings, Settings } from "../../../../commons/types/settings";
import actions from "../../actions";
import useDispatch from "../hooks/use-dispatch";
import useQuery, { UseQueryOptions } from "../hooks/use-query";

const queryKey = ["settings"] as const;

type Options = Omit<Partial<UseQueryOptions<Settings>>, "queryKey" | "queryFn">;

const useSettings = (options: Options = {}) => {
  const dispatch = useDispatch();

  const query = useQuery({
    ...options,
    queryKey,
    queryFn: getSettings,
    onSuccess: settings => {
      // TODO: BCD-7672 Replace `state.settings.settings` with `useSettings`
      dispatch(actions.settings.received(settings));
      options.onSuccess?.(settings);
    }
  });

  const settings = query.data;

  const assortmentPriceSettings = useMemo(() => {
    const fallbackAssortmentPriceSettings: AssortmentPriceSettings = {
      showAssortmentPrices: false,
      showFallbackPrices: false,
      useRrpAsOriginalPrice: false,
      showOriginalPrices: false,
      showSaleBadge: null
    };

    if (settings) {
      const hasAutomaticAssortmentType = settings.assortment.type === AssortmentType.Automatic;

      return hasAutomaticAssortmentType
        ? (settings.assortment.priceSettings ?? fallbackAssortmentPriceSettings)
        : fallbackAssortmentPriceSettings;
    } else {
      return undefined;
    }
  }, [settings]);

  return {
    ...query,
    assortmentPriceSettings
  };
};

export default useSettings;
