import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useScrollObserver, { ScrollPosition } from "../../libs/hooks/use-scroll-observer";
import Image from "../Image/Image";

interface Props extends Component {
  imgSource: string;
  fallbackSource?: string;
  children: React.ReactNode;
  button?: React.ReactNode;
  topAnnotation?: React.ReactNode;
  bottomAnnotation?: React.ReactNode;
}

const VariantInformationBox = ({
  classNames = [],
  imgSource,
  fallbackSource,
  children,
  button = null,
  topAnnotation = null,
  bottomAnnotation = null
}: Props) => {
  const contentScrollContainerRef = React.useRef<HTMLDivElement>(null);
  const { isScrollable, scrollPosition } = useScrollObserver(contentScrollContainerRef);

  const hasAnnotations = topAnnotation || bottomAnnotation;

  const modifiers = {
    hasAnnotations,
    isNotScrollable: !isScrollable.vertical,
    isScrolledToTop: scrollPosition.vertical === ScrollPosition.Start,
    isScrolled: scrollPosition.vertical === ScrollPosition.Middle,
    isScrolledToBottom: scrollPosition.vertical === ScrollPosition.End
  };

  return (
    <div className={cn("VariantInformationBox", [modifiers], classNames)}>
      {hasAnnotations && (
        <div className="VariantInformationBox__annotations">
          {topAnnotation && <div className="VariantInformationBox__annotationTop">{topAnnotation}</div>}
          {bottomAnnotation && <div className="VariantInformationBox__annotationBottom">{bottomAnnotation}</div>}
        </div>
      )}

      <div className="VariantInformationBox__image">
        <Image src={imgSource} fallbackSrc={fallbackSource} position="center" ratio={16 / 9} />
      </div>

      {button && <div className="VariantInformationBox__button">{button}</div>}

      <div className="VariantInformationBox__content">
        <div className="VariantInformationBox__contentScrollContainer" ref={contentScrollContainerRef}>
          {children}
        </div>
        <div className="VariantInformationBox__scrollIndicator VariantInformationBox__scrollIndicator--top" />
        <div className="VariantInformationBox__scrollIndicator VariantInformationBox__scrollIndicator--bottom" />
      </div>
    </div>
  );
};

export default VariantInformationBox;
