import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Button from "../Button/Button";
import { ModalContext } from "../Modal/Modal";
import NumberedSteps from "../NumberedSteps/NumberedSteps";

interface Props extends Component {
  children: React.ReactNode;
  actionLeft: React.ReactElement<React.ComponentProps<typeof Button>>;
  actionRight: React.ReactElement<React.ComponentProps<typeof Button>>;
  progress: React.ReactElement<React.ComponentProps<typeof NumberedSteps>>;
  aside?: React.ReactNode;
}

const BodySizingModalLayout = ({
  classNames = [],
  children,
  actionLeft,
  actionRight,
  progress,
  aside = null
}: Props) => (
  <ModalContext.Consumer>
    {({ scroll }) => (
      <div className={cn("BodySizingModalLayout", [{ scroll }], classNames)}>
        <div className="BodySizingModalLayout__main">
          <div className="BodySizingModalLayout__children">{children}</div>
          {aside && <div className="BodySizingModalLayout__aside">{aside}</div>}
        </div>
        <div className="BodySizingModalLayout__footer">
          <div className="BodySizingModalLayout__actionLeft">{actionLeft}</div>
          <div className="BodySizingModalLayout__progress">{progress}</div>
          <div className="BodySizingModalLayout__actionRight">{actionRight}</div>
        </div>
      </div>
    )}
  </ModalContext.Consumer>
);

export default BodySizingModalLayout;
