import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  icon: React.ReactElement<React.ComponentProps<typeof Icon>>;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const TileIconButton = ({ classNames = [], icon, onClick = noop }: Props) => (
  <button type="button" className={cn("TileIconButton", [], classNames)} onClick={onClick}>
    {icon}
  </button>
);

export default TileIconButton;
