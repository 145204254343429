import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Tooltip from "../Tooltip/Tooltip";

interface Props extends Component {
  label: string;
  tooltipContent?: React.ReactNode;
  icon?: React.ReactElement<any>;
  availability?: React.ReactNode;
  error?: boolean;
}

const VariantAnnotation = ({
  classNames = [],
  label,
  availability,
  tooltipContent = null,
  icon,
  error = false
}: Props) => {
  const renderContent = () => (
    <>
      <p className="VariantAnnotation__label">{label}</p>
      <div className="VariantAnnotation__availability">{availability}</div>
      {icon && <div className="VariantAnnotation__icon">{React.cloneElement(icon, { block: true })}</div>}
    </>
  );

  return tooltipContent ? (
    <Tooltip size="l" isCloseable content={tooltipContent} shouldCloseAutomatically>
      {({ toggle }) => (
        <div onClick={toggle} className={cn("VariantAnnotation", [{ error }], classNames)}>
          {renderContent()}
        </div>
      )}
    </Tooltip>
  ) : (
    <div className={cn("VariantAnnotation", [{ error }], classNames)}>{renderContent()}</div>
  );
};

export default VariantAnnotation;
