import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSource } from "../../../../commons/types/icon";
import { IconSmallCheck } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  label: string;
  active?: boolean;
  outline?: boolean;
  icon: IconSource;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const CheckboxListItemWithIcon = ({
  classNames = [],
  label,
  icon,
  active = false,
  outline = false,
  onClick = noop
}: Props) => (
  <button type="button" onClick={onClick} className={cn("CheckboxListItemWithIcon", [{ active, outline }], classNames)}>
    <div className="CheckboxListItemWithIcon__icon">
      <Icon source={icon} filter />
    </div>

    <span className="CheckboxListItemWithIcon__label">{label}</span>

    <div className="CheckboxListItemWithIcon__checkbox">
      <Icon source={IconSmallCheck} />
    </div>
  </button>
);

export default CheckboxListItemWithIcon;
