import { get } from "lodash";
import { Action } from "redux";
import { Cmd, loop } from "redux-loop";

import { deleteSetting, getSettings, storeSetting } from "../../../commons/libs/settings-service";
import { Settings } from "../../../commons/types/settings";
import { Actions } from "../actions";
import * as errorActions from "../actions/error";
import * as settingsActions from "../actions/settings";
import { hash } from "../libs/externals/hash";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";

import { State } from "./";

const NAME = "settings";

export interface SettingsState {
  [NAME]: {
    settings?: Settings;
  };
}

export const initialState: SettingsState = {
  [NAME]: {
    settings: undefined
  }
};

type SettingsInnerReducer<A extends Action = Actions> = InnerReducer<State, SettingsState[typeof NAME], A>;

const handleFetchAll: SettingsInnerReducer = (state: SettingsState) =>
  loop(
    state[NAME],
    Cmd.run(getSettings, {
      successActionCreator: settingsActions.received,
      failActionCreator: errorActions.set
    })
  );

const handleReceived: SettingsInnerReducer<settingsActions.ReceivedAction> = (state, action) => ({
  ...state[NAME],
  settings: action.payload
});

const handleStore: SettingsInnerReducer<settingsActions.StoreAction> = (state, action) =>
  loop(
    state[NAME],
    Cmd.run(storeSetting, {
      successActionCreator: settingsActions.received,
      failActionCreator: errorActions.set,
      args: [
        action.payload.path,
        action.payload.mergeValue
          ? action.payload.mergeValue(action.payload.value, get(state[NAME].settings, action.payload.path))
          : action.payload.value
      ]
    })
  );

const handleRemove: SettingsInnerReducer<settingsActions.RemoveAction> = (state, action) =>
  loop(
    state[NAME],
    Cmd.run(deleteSetting, {
      successActionCreator: settingsActions.received,
      failActionCreator: errorActions.set,
      args: [action.payload.path]
    })
  );

const handleSetPersonalCode: SettingsInnerReducer<settingsActions.SetPersonalCodeAction> = (state, action) =>
  loop(
    state[NAME],
    Cmd.run(hash, {
      successActionCreator: (hashedPersonalCode: string) => settingsActions.storePersonalCode(hashedPersonalCode),
      failActionCreator: errorActions.set,
      args: [action.payload.personalCode]
    })
  );

const reducer: InnerReducer<State, SettingsState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "SETTINGS.FETCH_ALL":
      return handleFetchAll(state, action);
    case "SETTINGS.RECEIVED":
      return handleReceived(state, action);
    case "SETTINGS.STORE":
      return handleStore(state, action);
    case "SETTINGS.REMOVE":
      return handleRemove(state, action);
    case "SETTINGS.SET_PERSONAL_CODE":
      return handleSetPersonalCode(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
