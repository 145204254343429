import { strict as assert } from "assert";
import { groupBy, orderBy } from "lodash";

import { ALL_CATEGORIES } from "../../../commons/libs/categories";
import { getProducts } from "../../../commons/libs/content-service";
import { isProductOfType } from "../../../commons/libs/products";
import { getFirstVariantId, getMinPrice, getSingleSpecValue } from "../../../commons/libs/specs";
import { BicycleSpecKey } from "../../../commons/specs/bicycle";
import { FilterTypes, ProductFilter } from "../../../commons/specs/filters";
import { Category, Product, ProductId, ProductKey, ProductSpecKey, ProductType } from "../../../commons/specs/product";
import { Currency } from "../../../commons/types/currency";
import { ProductListContextEntry } from "../../../commons/types/location";
import { AssortmentPriceSettings, ProductFinderViewMode } from "../../../commons/types/settings";

import { getPossibleValues } from "./content-service";

export interface GroupedProductsByBrand {
  [brandKey: string]: CategorizedProducts;
}

export type CategorizedProductKey = "withEngine" | "withoutEngine";

export type CategorizedProduct = { [engineKey in CategorizedProductKey]: Product[] };

export type CategorizedProducts = {
  [categoryKey in Category]: CategorizedProduct;
};

export const getGroupedProductsByView = (
  products: Product[],
  view: ProductFinderViewMode,
  highlightedBrandKeys: string[] = []
): GroupedProductsByBrand => {
  return view === ProductFinderViewMode.GroupedByCategoryOnly
    ? groupProductsByCategory(products, highlightedBrandKeys)
    : groupProductsByBrandAndCategory(products, highlightedBrandKeys);
};

const hasEngine = (product: Product): boolean =>
  isProductOfType(product, ProductType.Bicycle) && !!product[BicycleSpecKey.HasEngine];

const groupProductsByCategoryEngine = (products: Product[]): CategorizedProducts =>
  products.reduce((acc: CategorizedProducts, product: Product) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We can assume that every category key is part of the Category enum and is defined
    const categoryKey = product[ProductSpecKey.CategoryKey]!;

    if (!acc[categoryKey]) {
      return {
        ...acc,
        [categoryKey]: {
          // This is an exception for Bicycle products. The can be grouped in products with or without engine.
          // We want ebike categories to be displayed before their respective counterparts
          withEngine: hasEngine(product) ? [product] : [],
          withoutEngine: !hasEngine(product) || !isProductOfType(product, ProductType.Bicycle) ? [product] : []
        }
      };
    } else {
      // Non bicycle products are always added to the withoutEngine category
      const engineKey: CategorizedProductKey = hasEngine(product) ? "withEngine" : "withoutEngine";

      // to ensure the order of the bikes
      acc[categoryKey][engineKey].push(product);

      return acc;
    }
  }, {});

const sortByCategory = (product: Product): number =>
  ALL_CATEGORIES.findIndex(category => category === product[ProductSpecKey.CategoryKey]);

const getCategorySortOrder = () =>
  [
    [sortByCategory, "asc"],
    [BicycleSpecKey.HasEngine, "desc"]
  ] as const;

const getBrandSortOrderForGroupedByCategoryOnly = (highlightedBrandKeys: string[]) =>
  [
    [
      (product: Product): boolean => {
        const brandKey = getSingleSpecValue(ProductSpecKey.BrandKey, product);
        return highlightedBrandKeys.includes(brandKey);
      },
      "desc"
    ]
  ] as const;

const getBrandSortOrderForGroupedByBrandAndCategory = (highlightedBrandKeys: string[]) =>
  [
    [
      (product: Product): number => {
        const brandKey = getSingleSpecValue(ProductSpecKey.BrandKey, product);
        const index = highlightedBrandKeys.findIndex(highlightedBrankKey => highlightedBrankKey === brandKey);
        return index === -1 ? Number.MAX_VALUE : index;
      },
      "asc"
    ],
    [ProductSpecKey.BrandKey, "asc"]
  ] as const;

const getProductSortOrder = () =>
  [
    [(product: Product): number | undefined => getMinPrice(product), "desc"],
    [ProductSpecKey.ModelName, "asc"],
    [ProductSpecKey.ModelYear, "asc"]
  ] as const;

export const groupProductsByCategory = (
  products: Product[],
  highlightedBrandKeys: string[] = []
): GroupedProductsByBrand => {
  const sortedProducts = orderBy<Product>(
    products,
    [
      ...getCategorySortOrder().map(([sortFn]) => sortFn),
      ...getBrandSortOrderForGroupedByCategoryOnly(highlightedBrandKeys).map(([sortFn]) => sortFn),
      ...getProductSortOrder().map(([sortFn]) => sortFn)
    ],
    [
      ...getCategorySortOrder().map(([, order]) => order),
      ...getBrandSortOrderForGroupedByCategoryOnly(highlightedBrandKeys).map(([, order]) => order),
      ...getProductSortOrder().map(([, order]) => order)
    ]
  );

  return {
    "all-brands": groupProductsByCategoryEngine(sortedProducts)
  };
};

export const groupProductsByBrandAndCategory = (
  products: Product[],
  highlightedBrandKeys: string[] = []
): GroupedProductsByBrand => {
  const sortedProducts = orderBy<Product>(
    products,
    [
      ...getBrandSortOrderForGroupedByBrandAndCategory(highlightedBrandKeys).map(([sortFn]) => sortFn),
      ...getCategorySortOrder().map(([sortFn]) => sortFn),
      ...getProductSortOrder().map(([sortFn]) => sortFn)
    ],
    [
      ...getBrandSortOrderForGroupedByBrandAndCategory(highlightedBrandKeys).map(([, order]) => order),
      ...getCategorySortOrder().map(([, order]) => order),
      ...getProductSortOrder().map(([, order]) => order)
    ]
  );

  const groupedProductsByBrandKey = groupBy(sortedProducts, product => product[ProductSpecKey.BrandKey]);

  return Object.fromEntries(
    Object.entries(groupedProductsByBrandKey).map(([brandKey, brandProducts]) => [
      brandKey,
      groupProductsByCategoryEngine(brandProducts)
    ])
  );
};

export const getProductListContextFromProducts = (products: Product[]): ProductListContextEntry[] =>
  products.map(product => ({
    productId: product[ProductKey.ProductId],
    variantId: getFirstVariantId(product)
  }));

export const createProductListContextFromProducts = (
  sortedProducts: GroupedProductsByBrand | Product[]
): ProductListContextEntry[] => {
  if (Array.isArray(sortedProducts)) {
    return getProductListContextFromProducts(sortedProducts);
  } else {
    const getEntryFromBicycleProducts = (
      acc: ProductListContextEntry[],
      categorizedBike: CategorizedProduct
    ): ProductListContextEntry[] => [
      ...acc,
      ...[...categorizedBike.withEngine, ...categorizedBike.withoutEngine].map(bike => ({
        productId: bike[ProductKey.ProductId],
        variantId: getFirstVariantId(bike)
      }))
    ];

    return Object.values(sortedProducts).reduce(
      (acc: ProductListContextEntry[], category: CategorizedProducts): ProductListContextEntry[] => [
        ...acc,
        ...Object.values(category).reduce(getEntryFromBicycleProducts, [])
      ],
      []
    );
  }
};

export const getNeighbouringProducts = (
  productListContext: ProductListContextEntry[],
  productId: ProductId
): {
  next: ProductListContextEntry | null;
  prev: ProductListContextEntry | null;
} => {
  const index = productListContext.findIndex(item => item.productId === productId);

  assert(
    productListContext.length === 0 || index >= 0,
    `Product id does not exist in product list context (productId: ${productId}).`
  );

  const next = index < productListContext.length - 1 ? productListContext[index + 1] : null;
  const prev = index > 0 ? productListContext[index - 1] : null;

  return { next, prev };
};

export const decodeIdFromMatch = (id: ProductId) => decodeURIComponent(String(id));

export async function getSortedProductsWithAvailableModelYearsFilter<Type extends ProductType = ProductType>(
  productIds: ProductId[],
  currency: Currency,
  assortmentPriceSettings: AssortmentPriceSettings
) {
  const modelYearObjects = (await getPossibleValues([ProductSpecKey.ModelYear], currency, assortmentPriceSettings))[
    ProductSpecKey.ModelYear
  ];

  return getSortedProducts<Type>(
    productIds,
    currency,
    assortmentPriceSettings,
    (modelYearObjects ?? []).map(modelYear => ({
      key: ProductSpecKey.ModelYear,
      filterType: FilterTypes.Eq,
      value: modelYear.value
    }))
  );
}

export async function getSortedProducts<Type extends ProductType = ProductType>(
  productIds: ProductId[],
  currency: Currency,
  assortmentPriceSettings: AssortmentPriceSettings,
  filter?: ProductFilter[]
) {
  return getProducts<Type>(currency, assortmentPriceSettings, [
    ...productIds.map(productId => ({
      key: ProductKey.ProductId,
      value: productId
    })),
    ...(filter ?? [])
  ]).then(response =>
    response.results.sort((productA, productB) => {
      const productAIndex = productIds.indexOf(productA[ProductKey.ProductId]);
      const productBIndex = productIds.indexOf(productB[ProductKey.ProductId]);
      return productAIndex > productBIndex ? 1 : -1;
    })
  );
}
