import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Image from "../Image/Image";

interface Props extends Component {
  imageUrl: string;
  productTitle: React.ReactNode;
  action?: React.ReactNode;
  onClick?: () => void;
}

const ProductDetailsImage = ({ classNames, imageUrl, productTitle, action, onClick }: Props) => (
  <div className={cn("ProductDetailsImage", [], classNames)}>
    <div className="ProductDetailsImage__image">
      <div className="ProductDetailsImage__action">{action}</div>
      <Image position="center" fill src={imageUrl} timeout={30_000} onClick={onClick} />
    </div>
    <div className="ProductDetailsImage__title">{productTitle}</div>
  </div>
);

export default ProductDetailsImage;
