import React from "react";
import { useTranslation } from "react-i18next";

import { formatEmptyPrice, getSpecFormatter, SpecFormatter } from "../../../../commons/libs/formatters";
import { getMaxDiscountPercentage, isProduct } from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import {
  Product,
  ProductKey,
  ProductSpecKey,
  ProductType,
  StandaloneProductVariant
} from "../../../../commons/specs/product";
import Badge from "../../components/Badge/Badge";
import useSelector from "../../libs/hooks/use-selector";
import { selectAssortmentPriceSettings, selectInitializedSettings } from "../../libs/selectors";

const useSpecFormatter = <Type extends ProductType>(
  productOrVariant?: Product<Type> | StandaloneProductVariant<Type>
): SpecFormatter<Type> | undefined => {
  const { i18n } = useTranslation(["commons"]);
  const settings = useSelector(selectInitializedSettings);

  if (!productOrVariant) {
    return undefined;
  }

  const productType = productOrVariant[ProductKey.ProductType];
  const specConfig = getSpecConfig(productType);

  return getSpecFormatter(productType, specConfig.specDefinitions, i18n, settings.customization);
};

const getDiscountPercentage = <Type extends ProductType>(
  productOrVariant?: Product<Type> | StandaloneProductVariant<Type>
): number | undefined => {
  if (productOrVariant && isProduct(productOrVariant)) {
    return getMaxDiscountPercentage(productOrVariant);
  } else {
    return productOrVariant?.[ProductSpecKey.DiscountPercentage] ?? undefined;
  }
};

export const PricePartial = <Type extends ProductType>({
  productOrVariant,
  inSpecView = false,
  forceShow = false
}: {
  productOrVariant?: Product<Type> | StandaloneProductVariant<Type>;
  inSpecView?: boolean;
  forceShow?: boolean;
}) => {
  const { i18n } = useTranslation(["commons"]);
  const { currency, customization } = useSelector(selectInitializedSettings);
  const specFormatter = useSpecFormatter(productOrVariant);

  if (productOrVariant && specFormatter) {
    const shouldShowPrice = inSpecView
      ? customization.showPrices || customization.showPricesFeaturesOnly
      : customization.showPrices;

    if (forceShow || shouldShowPrice) {
      const price = specFormatter.formatProductOrVariantValue(ProductSpecKey.Price, productOrVariant);
      // In case that there is no price available, we want to explitly show an empty price, instead of showing nothing.
      return price ? price : formatEmptyPrice(currency, i18n.language);
    }
  }
};

export const OriginalPricePartial = <Type extends ProductType>({
  productOrVariant,
  inSpecView = false
}: {
  productOrVariant?: Product<Type> | StandaloneProductVariant<Type>;
  inSpecView?: boolean;
}) => {
  const customization = useSelector(selectInitializedSettings).customization;
  const assortmentPriceSettings = useSelector(selectAssortmentPriceSettings);
  const specFormatter = useSpecFormatter(productOrVariant);

  if (productOrVariant && specFormatter) {
    const shouldShowPrice = inSpecView
      ? customization.showPrices || customization.showPricesFeaturesOnly
      : customization.showPrices;

    if (shouldShowPrice && assortmentPriceSettings.showOriginalPrices && getDiscountPercentage(productOrVariant)) {
      return specFormatter.formatProductOrVariantValue(ProductSpecKey.OriginalPrice, productOrVariant);
    }
  }
};

export const SaleBadgePartial = <Type extends ProductType>({
  productOrVariant,
  size = "s"
}: {
  productOrVariant?: Product<Type> | StandaloneProductVariant<Type>;
  size?: React.ComponentProps<typeof Badge>["size"];
}) => {
  const { t } = useTranslation(["commons"]);

  const customization = useSelector(selectInitializedSettings).customization;
  const assortmentPriceSettings = useSelector(selectAssortmentPriceSettings);
  const specFormatter = useSpecFormatter(productOrVariant);

  if (productOrVariant && specFormatter) {
    if (customization.showPrices && assortmentPriceSettings.showSaleBadge && getDiscountPercentage(productOrVariant)) {
      switch (assortmentPriceSettings.showSaleBadge) {
        case "word":
          return <Badge label={t("commons:defaults.sale")} size={size} />;
        case "percentage":
          return (
            <Badge
              label={specFormatter.formatProductOrVariantValue(ProductSpecKey.DiscountPercentage, productOrVariant)}
              size={size}
            />
          );
        default:
          return undefined;
      }
    }
  }
};
