import React from "react";
import { compact } from "lodash";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import Badge from "../../../commons/components/Badge/Badge";
import Button from "../../../commons/components/Button/Button";
import ButtonGrid from "../../../commons/components/ButtonGrid/ButtonGrid";
import ErrorLog from "../../../commons/components/ErrorLog/ErrorLog";
import ErrorPageLayout from "../../../commons/components/ErrorPageLayout/ErrorPageLayout";
import Headline from "../../../commons/components/Headline/Headline";
import Hotline from "../../../commons/components/Hotline/Hotline";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";
import Text from "../../../commons/components/Text/Text";
import TextHeader from "../../../commons/components/TextHeader/TextHeader";
import { connect } from "../../../commons/container/utils/loop";
import { State } from "../../reducers";
import DeviceInformationPartial from "../App/DeviceInformationPartial";

const mapStateToProps = (state: State) => ({
  message: state.error.errorMessage,
  stack: state.error.stack
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const ErrorScreen = ({ message, stack }: Props) => {
  const { t } = useTranslation(["mobile"]);

  const reloadApp = () => {
    window.location.reload();
  };

  return (
    <ErrorPageLayout
      aside={
        <DeviceInformationPartial
          errorLog={
            <ErrorLog
              headline={<Headline>{t("mobile:errorScreen.developerInformationHeadline")}</Headline>}
              messages={compact([message, stack])}
              expandLabel={t("mobile:errorScreen.showDeveloperInformationButton")}
            />
          }
          timestamp={moment()}
        />
      }
      footer={
        <ButtonGrid>
          <Button size="s" onClick={reloadApp} variant="accent" kind="solid">
            {t("mobile:errorScreen.reloadApplicationButton")}
          </Button>
        </ButtonGrid>
      }
    >
      <Text>
        <TextHeader topline={<Badge label={t("mobile:errorScreen.criticalErrorLabel")} variant="negative" />}>
          <Headline kind="hero">{t("mobile:errorScreen.reloadRequiredHeadline")}</Headline>
        </TextHeader>
        <Paragraph>
          {t("mobile:errorScreen.rareTechnicalErrorOccurred")}
          <br />
          {t("mobile:errorScreen.reloadPageOrApplication")}
        </Paragraph>
        <Paragraph>
          <Trans t={t} i18nKey="mobile:errorScreen.screenReappearsContactSupport" components={{ linebreak: <br /> }} />
        </Paragraph>
        <Hotline
          title={t("mobile:supportConfig.headline")}
          phone={t("mobile:supportConfig.phone")}
          label={t("mobile:supportConfig.officeHours")}
          mail={t("mobile:supportConfig.mail")}
        />
      </Text>
    </ErrorPageLayout>
  );
};

export default connector(ErrorScreen);
