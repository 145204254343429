import { useEffect, useState } from "react";
import { debounce, mapValues } from "lodash";

import { MatchedMediaQueryBreakpoints, MediaQueryDefinitions } from "../../../../commons/types/media-queries";
import config from "../../config";

const getMatchedMediaBreakpoints = (breakpoints: MediaQueryDefinitions): MatchedMediaQueryBreakpoints => {
  return mapValues(breakpoints, value => global.matchMedia(value).matches);
};

export const useMediaQuery = () => {
  const mediaQueryBreakpoints = config.shared.mediaQueryBreakpoints;
  const [matchedBreakpoints, setMatchedBreakpoints] = useState(getMatchedMediaBreakpoints(mediaQueryBreakpoints));

  useEffect(() => {
    const calculateAndSetBreakpoints = () => setMatchedBreakpoints(getMatchedMediaBreakpoints(mediaQueryBreakpoints));

    const onResize = debounce(() => {
      calculateAndSetBreakpoints();
    }, 200);

    window.addEventListener("resize", onResize, false);
    window.addEventListener("orientationchange", onResize, false);

    return () => {
      onResize.cancel();
      window.removeEventListener("resize", onResize, false);
      window.removeEventListener("orientationchange", onResize, false);
    };
  }, [mediaQueryBreakpoints]);

  return matchedBreakpoints;
};
