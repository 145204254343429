import React from "react";

import { Component } from "../../../../commons/types/component";
import * as icons from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";

interface Props extends Component {
  messages: string[];
  headline: React.ReactNode;
  expandLabel: string;
}

const ErrorLog = ({ classNames = [], messages, headline, expandLabel }: Props) => {
  const [compact, setCompact] = React.useState(true);

  return (
    <div className={cn("ErrorLog", [], classNames)}>
      {compact ? (
        <Button
          icon={<Icon source={icons.IconSmallAngleDown} />}
          onClick={() => {
            setCompact(false);
          }}
        >
          {expandLabel}
        </Button>
      ) : (
        <Text>
          {headline}
          {messages.map((message, idx) => (
            <p key={idx} className="ErrorLog__message">
              {message}
            </p>
          ))}
        </Text>
      )}
    </div>
  );
};

export default ErrorLog;
