import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import * as icons from "../../../../resources/icons";
import cn from "../../libs/class-name";
import useDoubleClick from "../../libs/hooks/use-double-click";
import FlexLayout from "../FlexLayout/FlexLayout";
import Icon from "../Icon/Icon";
import IconButton from "../IconButton/IconButton";
import Image from "../Image/Image";
import TileIconButton from "../TileIconButton/TileIconButton";

interface Props extends Component {
  image: React.ReactElement<React.ComponentProps<typeof Image>>;
  year?: string;
  action?: React.ReactNode;
  doubleClickInfo?: React.ReactElement<React.ComponentProps<typeof FlexLayout>>;
  href?: string;
  height?: "default" | "l" | "xl";
  badge?: React.ReactElement;
  isBackside?: boolean;
  isCloseable?: boolean;
  isFlippable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>, href: string) => void;
  onDoubleClick?: (event: React.MouseEvent<HTMLElement>, href: string) => void;
  onClose?: () => void;
  onFlip?: () => void;
}

const BrandTile = ({
  classNames = [],
  href = "#",
  image,
  year = "",
  action = null,
  doubleClickInfo,
  height = "default",
  badge,
  isBackside = false,
  isCloseable = false,
  isFlippable = false,
  onClick = noop,
  onClose = noop,
  onFlip = noop,
  onDoubleClick = noop
}: Props) => {
  const {
    doubleClicked,
    waitingForDoubleClick,
    onBlur: onBlurInner,
    onClick: onClickInner
  } = useDoubleClick(
    event => {
      event.preventDefault();
      onDoubleClick(event, href);
    },
    event => {
      event.preventDefault();
      onClick(event, href);
    }
  );

  return (
    <div
      className={cn(
        "BrandTile",
        [
          {
            touched: (waitingForDoubleClick || doubleClicked) && !doubleClickInfo,
            isBackside,
            doubleClickInfo,
            [`height-${height}`]: height
          }
        ],
        classNames
      )}
    >
      <button className="BrandTile__content" onClick={onClickInner} onBlur={onBlurInner}>
        <div className="BrandTile__image">
          {React.cloneElement(image, { fill: true, position: "center", timeout: 30_000 })}
        </div>
        {!doubleClickInfo && !!year && <div className="BrandTile__year">{year}</div>}
      </button>
      {isCloseable && (
        <div className="BrandTile__closeButton">
          <TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />
        </div>
      )}
      {isFlippable && !doubleClickInfo && (
        <div className="BrandTile__action">
          <IconButton icon={<Icon source={icons.IconSmallSwitch} />} onClick={onFlip} />
        </div>
      )}
      {!!badge && <div className="BrandTile__badge">{badge}</div>}
      {action && !doubleClickInfo && <div className="BrandTile__action">{action}</div>}
      {doubleClickInfo && <div className="BrandTile__doubleClickInfo">{doubleClickInfo}</div>}
    </div>
  );
};

export default BrandTile;
