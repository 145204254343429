import { useCallback, useMemo } from "react";

import { getAvailableBrands } from "../../../../commons/libs/content-service";
import { Brand } from "../../../../commons/types/brand";
import useQuery, { UseQueryOptions } from "../hooks/use-query";

const queryKey = ["availableBrands"] as const;

type QueryOptions = Omit<Partial<UseQueryOptions<Brand[]>>, "queryKey" | "queryFn">;

const useAvailableBrands = (options: QueryOptions = {}) => {
  const query = useQuery<Brand[], Error>({
    ...options,
    queryKey,
    queryFn: getAvailableBrands,
    onSuccess: availableBrands => {
      options.onSuccess?.(availableBrands);
    }
  });

  const data = useMemo(() => query.data ?? [], [query.data]);

  const getAvailableBrand = useCallback((brandKey: string) => data.find(brand => brand.key === brandKey), [data]);

  return {
    ...query,
    data,
    getAvailableBrand
  };
};

export default useAvailableBrands;
