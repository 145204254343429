import { postToService } from "../../../commons/libs/client-utils";
import { CreateLicense, License } from "../../../commons/types/licenses";

import { setRegistrationCookie } from "./cookies";

interface BaseCredentials {
  serviceTag: string;
}

export interface RegistrationCredentials extends BaseCredentials {
  registrationToken: string;
}

export interface AuthenticationCredentials extends BaseCredentials {
  licenseId: string;
  licenseToken: string;
}

const registrationCredentialKeys: Array<keyof RegistrationCredentials> = ["serviceTag", "registrationToken"];

const authenticationCredentialKeys: Array<keyof AuthenticationCredentials> = [
  "serviceTag",
  "licenseId",
  "licenseToken"
];

const getValuesFromLocalStorage = <T>(keys: string[]): T =>
  keys.reduce((acc, key) => ({ ...acc, [key]: localStorage.getItem(key) }), {}) as T;

const removeValuesFromLocalStorage = <T>(keys: string[]): T =>
  keys.reduce((acc, key) => ({ ...acc, [key]: localStorage.removeItem(key) }), {}) as T;

const storeValuesInLocalStorage = <T>(keys: string[], credentials: T): void =>
  keys.forEach(key => localStorage.setItem(key, (credentials as unknown as Record<string, string>)[key]));

const getUrlSearchParams = () => new URLSearchParams(window.location.search);

const getValuesFromSearchParams = <T>(keys: string[]): T => {
  const urlSearchParams = getUrlSearchParams();
  return keys.reduce((acc, key) => ({ ...acc, [key]: urlSearchParams.get(key) }), {}) as T;
};

const allValuesAreTruthy = <T extends Partial<RegistrationCredentials> | Partial<AuthenticationCredentials>>(
  credentials: T
) => Object.values(credentials).every(value => !!value);

export const getQueriedRegistrationCredentials = (): Partial<RegistrationCredentials> =>
  getValuesFromSearchParams(registrationCredentialKeys);

export const getQueriedAuthenticationCredentials = (): Partial<AuthenticationCredentials> =>
  getValuesFromSearchParams(authenticationCredentialKeys);

export const storeRegistrationCredentials = (credentials: RegistrationCredentials) =>
  storeValuesInLocalStorage(registrationCredentialKeys, credentials);

export const storeAuthenticationCredentials = (credentials: AuthenticationCredentials) =>
  storeValuesInLocalStorage(authenticationCredentialKeys, credentials);

export const removeRegistrationCredentials = () => {
  removeValuesFromLocalStorage(registrationCredentialKeys);
};

export const getStoredRegistrationCredentials = (): Partial<RegistrationCredentials> =>
  getValuesFromLocalStorage(registrationCredentialKeys);

export const getStoredAuthenticationCredentials = (): Partial<AuthenticationCredentials> =>
  getValuesFromLocalStorage(authenticationCredentialKeys);

export const reloadApp = () => {
  window.history.replaceState({}, document.title, "/");
  window.location.reload();
};

export const hasNewRegistrationCredentials = (
  queriedRegistrationCredentials: Partial<RegistrationCredentials>
): queriedRegistrationCredentials is RegistrationCredentials => allValuesAreTruthy(queriedRegistrationCredentials);

export const isTryingToRegister = (
  storedRegistrationCredentials: Partial<RegistrationCredentials>
): storedRegistrationCredentials is RegistrationCredentials => allValuesAreTruthy(storedRegistrationCredentials);

export const hasExistingAuthenticationCredentials = (
  queriedAuthenticationCredentials: Partial<AuthenticationCredentials>
): queriedAuthenticationCredentials is AuthenticationCredentials =>
  allValuesAreTruthy(queriedAuthenticationCredentials);

export const isAuthenticated = (
  storedAuthenicationCredentials: Partial<AuthenticationCredentials>
): storedAuthenicationCredentials is AuthenticationCredentials => allValuesAreTruthy(storedAuthenicationCredentials);

export const registerLicense = async ({ registrationToken }: RegistrationCredentials): Promise<License> => {
  setRegistrationCookie(registrationToken);
  const license: CreateLicense = {
    name: `Mobile ${(Math.random() + 1).toString(36).substring(7)}`,
    userAgent: navigator.userAgent
  };

  return await postToService("licenses", "/", license);
};
