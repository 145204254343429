import React from "react";
import { noop, uniqueId } from "lodash";

import { Component } from "../../../../commons/types/component";
import { KeyboardType } from "../../../../commons/types/keyboard";
import cn from "../../libs/class-name";
import { mapChildrenWithFragments } from "../../libs/react-utils";
import InputField from "../InputField/InputField";
import RadioField from "../RadioField/RadioField";
import RadioGroup from "../RadioGroup/RadioGroup";

interface Props extends Component {
  children: Array<React.ReactElement<React.ComponentProps<typeof RadioField>>>;
  name?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  block?: boolean;
  vertical?: boolean;
  keyboardType?: KeyboardType;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
}

const OTHER_VALUE = "__other_value__";

const RadioGroupWithOther = ({
  classNames = [],
  children,
  name = "",
  value = "",
  placeholder = "",
  disabled = false,
  error = false,
  block = false,
  vertical = false,
  keyboardType,
  onBlur = noop,
  onChange = noop
}: Props) => {
  const radioValues: string[] = [];
  const ref = React.useRef<HTMLInputElement>(null);

  const otherId = React.useMemo(() => uniqueId("RadioGroupWithOther-other-"), []);
  const [inputValue, setInputValue] = React.useState("");

  const radioFields = mapChildrenWithFragments(
    children,
    (child: React.ReactElement<React.ComponentProps<typeof RadioField>>) => {
      radioValues.push(child.props.value ?? "");
      return child;
    }
  );

  const isOtherValue = radioValues.indexOf(value) === -1 || inputValue === value;
  const radioGroupValue = !isOtherValue ? value : OTHER_VALUE;

  const onRadioChange = (radioValue: string) => {
    if (radioValue !== OTHER_VALUE) {
      if (inputValue === radioValue) {
        setInputValue("");
      }
      onChange(radioValue);
    } else {
      if (ref.current) {
        ref.current.focus();
      }
      onChange(inputValue);
    }
  };

  const onInputFieldChange = (inputFieldValue: string) => {
    setInputValue(inputFieldValue);
    onChange(inputFieldValue);
  };

  return (
    <div className={cn("RadioGroupWithOther", [{ block, vertical }], classNames)}>
      <RadioGroup
        disabled={disabled}
        direction={vertical ? "vertical" : "horizontal"}
        value={!disabled ? radioGroupValue : undefined}
        onChange={onRadioChange}
      >
        {radioFields}
        <RadioField
          id={otherId}
          label={
            <label htmlFor={otherId} className="RadioGroupWithOther__input">
              <InputField
                keyboardType={keyboardType}
                disabled={disabled}
                onFocus={() => onRadioChange(OTHER_VALUE)}
                error={error}
                inputRef={ref}
                name={name}
                onBlur={onBlur}
                value={isOtherValue && !disabled ? value : inputValue}
                onChange={onInputFieldChange}
                placeholder={placeholder}
              />
            </label>
          }
          value={OTHER_VALUE}
        />
      </RadioGroup>
    </div>
  );
};

export default RadioGroupWithOther;
