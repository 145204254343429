import React from "react";
import { noop, uniqueId } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import { mapChildrenWithFragments } from "../../libs/react-utils";
import RadioField from "../RadioField/RadioField";

type Direction = "horizontal" | "vertical";
type Alignment = "left" | "center";

interface Props<T extends string = string> extends Component {
  children: React.ReactNode;
  name?: string;
  value?: T;
  disabled?: boolean;
  direction?: Direction;
  align?: Alignment;
  gridCols?: 2 | 3 | 4;
  onChange?: (value: T) => void;
  onBlur?: () => void;
}

const RadioGroup = <T extends string = string>({
  classNames = [],
  children,
  name = uniqueId("RadioGroup-"),
  value = "" as T,
  disabled = false,
  direction = "vertical",
  align = "left",
  gridCols,
  onChange = noop as (value: T) => void,
  onBlur = noop
}: Props<T>) => {
  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as T);
  };

  const radioFields = mapChildrenWithFragments(
    children,
    (child: React.ReactElement<React.ComponentProps<typeof RadioField>>, index) => (
      <div className="RadioGroup__item" key={index}>
        {React.cloneElement(child, {
          onClick,
          onBlur,
          name,
          checked: value === child.props.value,
          disabled: child.props.disabled || disabled
        })}
      </div>
    )
  );
  return (
    <div
      className={cn(
        "RadioGroup",
        [{ [`direction-${direction}`]: direction, [`align-${align}`]: align, gridCols }],
        classNames
      )}
      {...(!!gridCols && { style: { gridTemplateColumns: `repeat(${gridCols}, 1fr)` } })}
    >
      {radioFields}
    </div>
  );
};

export default RadioGroup;
