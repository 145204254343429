import { uniqueId } from "lodash";
import { AnyAction } from "redux";

import { IconSource } from "../../../commons/types/icon";
import { Action } from "../../../commons/types/redux";
import type { ToastKind } from "../components/Toast/Toast";
import { ToastAlignment } from "../components/ToastLayout/ToastLayout";

export type ToastActionType = "primary" | "secondary";
export type ShowToastAction = Action<"TOASTS.SHOW_TOAST", ToastProperties>;
export type HideToastAction = Action<"TOASTS.HIDE_TOAST", { id: string }>;
export type SetToastAlignmentAction = Action<"TOASTS.SET_TOAST_ALIGNMENT", { toastAlignment: ToastAlignment }>;

export type ToastsActions = ShowToastAction | HideToastAction | SetToastAlignmentAction;

export interface ActionsToastProperties {
  label: string;
  callback: () => AnyAction | void;
}

export interface CustomizableToastProperties {
  id?: string;
  title: string;
  description?: string;
  kind?: ToastKind;
  timeout?: number;
  primaryAction?: ActionsToastProperties;
  secondaryAction?: ActionsToastProperties;
  onClose?: () => void;
  icon?: IconSource;
  permanent?: boolean;
  shouldInteractionLeaveKeyboardOpen?: boolean;
}

export interface ToastProperties extends CustomizableToastProperties {
  id: string;
}

export const showToast = (props: CustomizableToastProperties): ShowToastAction => ({
  type: "TOASTS.SHOW_TOAST",
  payload: {
    id: uniqueId(),
    ...props
  }
});

export const hideToast = (id: string): HideToastAction => ({
  type: "TOASTS.HIDE_TOAST",
  payload: { id }
});

export const setToastAlignment = (toastAlignment: ToastAlignment): SetToastAlignmentAction => ({
  type: "TOASTS.SET_TOAST_ALIGNMENT",
  payload: { toastAlignment }
});
