import { Action } from "../../../commons/types/redux";

interface ErrorActionParam {
  error: Error | string | { message: string; stack: string };
}

interface ErrorModalActionParam {
  errorMessage: string;
  headline: string;
  paragraph: string;
  type: "print" | "default";
}

export type SetAction = Action<"ERROR.SET", ErrorActionParam>;
export type ResetAction = Action<"ERROR.RESET">;

export type SetModalAction = Action<"ERROR.SET_MODAL", { error: ErrorModalActionParam }>;
export type ResetModalAction = Action<"ERROR.RESET_MODAL">;
export type SetModalIsOpenAction = Action<"ERROR.SET_MODAL_IS_OPEN", { isOpen: boolean }>;

export type ErrorActions = SetAction | ResetAction | SetModalAction | ResetModalAction | SetModalIsOpenAction;

export const set = (error: Error | { message: string; stack: string }): SetAction => ({
  type: "ERROR.SET",
  payload: {
    error
  }
});

export const reset = (): ResetAction => ({
  type: "ERROR.RESET"
});

export const setModal = (error: ErrorModalActionParam): SetModalAction => ({
  type: "ERROR.SET_MODAL",
  payload: {
    error
  }
});

export const resetModal = (): ResetModalAction => ({
  type: "ERROR.RESET_MODAL"
});

export const setModalIsOpen = (isOpen: boolean): SetModalIsOpenAction => ({
  type: "ERROR.SET_MODAL_IS_OPEN",
  payload: {
    isOpen
  }
});
