import { LocationChangeAction, RouterAction } from "connected-react-router";

import * as authActions from "./auth";
import * as bookmarkActions from "./bookmarks";
import * as coreActions from "./core";
import * as customerWorldActions from "./customer-world";
import * as errorActions from "./error";
import * as keyboardActions from "./keyboard";
import * as kioskAppActions from "./kiosk-app";
import * as mobileAppActions from "./mobile-app";
import * as modalActions from "./modal";
import * as networkActions from "./network";
import * as sessionActions from "./session";
import * as settingsActions from "./settings";
import * as startupTimesActions from "./startup-times";
import * as toastsActions from "./toasts";
import * as wawiActions from "./wawi";

export type VendorActions = RouterAction | LocationChangeAction;

export type Actions =
  | authActions.AuthActions
  | bookmarkActions.BookmarkActions
  | coreActions.CoreActions
  | customerWorldActions.CustomerWorldActions
  | errorActions.ErrorActions
  | networkActions.NetworkActions
  | settingsActions.SettingsActions
  | startupTimesActions.StartupTimesActions
  | toastsActions.ToastsActions
  | VendorActions
  | keyboardActions.KeyboardActions
  | wawiActions.WawiActions
  | modalActions.ModalActions
  | kioskAppActions.KioskAppActions
  | mobileAppActions.MobileAppActions
  | sessionActions.SessionActions;

export default {
  auth: authActions,
  bookmarks: bookmarkActions,
  core: coreActions,
  customerWorld: customerWorldActions,
  error: errorActions,
  network: networkActions,
  settings: settingsActions,
  startupTimes: startupTimesActions,
  toasts: toastsActions,
  keyboard: keyboardActions,
  wawi: wawiActions,
  modal: modalActions,
  kioskApp: kioskAppActions,
  mobileApp: mobileAppActions,
  session: sessionActions
};
