import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { noop } from "lodash";

import { ensureError } from "../../../../commons/libs/error";
import { getSpecConfig } from "../../../../commons/specs";
import { getTypedFilterConfigKeys } from "../../../../commons/specs/filters";

import { useActiveBrands } from "./use-active-brands";
import useEnv from "./use-env";
import { prefetchAllFilterValues } from "./use-product-filter";
import useSettings from "./use-settings";

interface Arguments {
  enabled: boolean;
  onError: (error: Error) => void;
}

const usePrefillCache = ({ enabled = false, onError = noop }: Arguments) => {
  const queryClient = useQueryClient();

  const [isSuccess, setIsSuccess] = useState(false);

  const { customerGroupConfig } = useEnv({ enabled });
  const { data: settings, assortmentPriceSettings } = useSettings({ enabled });
  const { activeBrandKeys } = useActiveBrands({ enabled });

  const productType = customerGroupConfig?.productTypes[0];
  const specConfig = productType ? getSpecConfig(productType) : undefined;
  const filterKeys = specConfig ? getTypedFilterConfigKeys(specConfig) : undefined;

  useEffect(() => {
    const prefillCache = async () => {
      if (enabled && !isSuccess && settings && assortmentPriceSettings && filterKeys) {
        try {
          await prefetchAllFilterValues({
            queryClient,
            currency: settings.currency,
            assortmentPriceSettings,
            filterKeys,
            activeBrandKeys
          });

          setIsSuccess(true);
        } catch (err) {
          setIsSuccess(false);
          onError(ensureError(err));
        }
      }
    };

    if (enabled) {
      // Only prefill cache if there are active brands, otherwise skip it
      if (activeBrandKeys.length > 0) {
        prefillCache();
      } else {
        setIsSuccess(true);
      }
    }
  }, [enabled, isSuccess, settings, activeBrandKeys, assortmentPriceSettings, filterKeys, queryClient, onError]);

  return {
    isSuccess
  };
};

export default usePrefillCache;
