import { useCallback, useState } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { isEqual } from "lodash";

import {
  getSettings as getSettingsLib,
  storeSetting as storeSettingLib
} from "../../../../commons/libs/settings-service";
import { AssortmentType } from "../../../../commons/types/assortment";
import { Bookmarks } from "../../../../commons/types/bookmarks";
import { AssortmentPriceSettings, Settings, ToolSetting } from "../../../../commons/types/settings";
import { ExternalToolKey } from "../../../../commons/types/tool";
import actions from "../../actions";
import sharedConfig from "../../config/shared";
import { hash } from "../externals/hash";
import useDispatch from "../hooks/use-dispatch";
import useOnMount from "../hooks/use-on-mount";
import useQuery, { UseQueryOptions } from "../hooks/use-query";
import useSelector from "../hooks/use-selector";
import useThrottle from "../hooks/use-throttle";

const settingsQueryKey = ["settings"] as const;
const setSettingsMutationKey = ["setSettings"] as const;

type SettingsQueryOptions = Omit<Partial<UseQueryOptions<Settings>>, "queryKey" | "queryFn">;

type SetSettingsMutationVariables = {
  path: Parameters<typeof storeSettingLib>[0];
  value: Parameters<typeof storeSettingLib>[1];
};

type SetSettingsMutationOptions = Omit<
  Partial<UseMutationOptions<Settings, Error, SetSettingsMutationVariables>>,
  "mutationKey" | "mutationFn"
>;

export const useSettings = (options: SettingsQueryOptions = {}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-restricted-syntax -- This selector can be used during initialization while settings have already been fetched but `isInitialized` is still false
  const reduxSettings = useSelector(state => state.settings.settings);

  const query = useQuery({
    ...options,
    queryKey: settingsQueryKey,
    queryFn: getSettingsLib,
    staleTime: Infinity, // Keep the cache data, until we mutate the settings via `useSetSettings`.
    onData: settings => {
      options.onData?.(settings);

      /**
       * @todo BCD-7672 Replace `state.settings.settings` with `useSettings`
       *
       * During the migration from the redux settings store to the query settings store,
       * we want to prevent unnecessary dispatching of `settings.received` actions, so
       * we only dispatch it if the settings have changed.
       * This allows us to use the `useSettings` hook in components and containers,
       * without dispatching `settings.received` actions all the time.
       */
      if (!isEqual(reduxSettings, settings)) {
        dispatch(actions.settings.received(settings));
      }
    },
    onError: error => {
      dispatch(actions.error.set(error));
    }
  });

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: settingsQueryKey });
  }, [queryClient]);

  const settings = query.data;

  const assortmentPriceSettings: AssortmentPriceSettings | undefined = settings
    ? settings.assortment.type === AssortmentType.Automatic
      ? (settings.assortment.priceSettings ?? {
          showAssortmentPrices: false,
          showFallbackPrices: false,
          useRrpAsOriginalPrice: false,
          showOriginalPrices: false,
          showSaleBadge: null
        })
      : {
          showAssortmentPrices: false,
          showFallbackPrices: false,
          useRrpAsOriginalPrice: false,
          showOriginalPrices: false,
          showSaleBadge: null
        }
    : undefined;

  return {
    query,
    assortmentPriceSettings,
    invalidateCache
  };
};

export const useSetSettings = (options: SetSettingsMutationOptions = {}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const mutation = useMutation({
    ...options,
    mutationKey: setSettingsMutationKey,
    mutationFn: ({ path, value }: SetSettingsMutationVariables) => storeSettingLib(path, value),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: settingsQueryKey });
      options.onSuccess?.(data, variables, context);
    },
    onError: error => {
      dispatch(actions.error.set(error));
    }
  });

  const mutate = mutation.mutate;

  const setPersonalCode = useCallback(
    (personalCode: string) => {
      return mutate({ path: ["personalCode"], value: hash(personalCode) });
    },
    [mutate]
  );

  const setToolSettings = useCallback(
    <T extends ToolSetting>(toolKey: ExternalToolKey) =>
      (toolSettings: T) => {
        return mutate({ path: ["toolSettings", toolKey], value: toolSettings });
      },
    [mutate]
  );

  const setBookmarks = useCallback(
    (bookmarks: Bookmarks) => {
      return mutate({ path: ["bookmarks"], value: bookmarks });
    },
    [mutate]
  );

  return {
    mutation,
    setPersonalCode,
    setToolSettings,
    setBookmarks
  };
};

interface UseToolSettingsOptions<TS> {
  formik?: {
    initialValues?: (toolSettings: TS) => TS;
    onSubmit?: ({
      values,
      helpers,
      setToolSettings
    }: {
      /** The current formik values. */
      values: TS;
      /** The original formik helpers */
      helpers: FormikHelpers<TS>;
      /** The function to set the tool settings with typed tool settings. */
      setToolSettings: (toolSettings: TS) => void;
    }) => Promise<void> | void;
    validate?: ({
      values,
      errors
    }: {
      /** The current formik values. */
      values: TS;
      /** Initial empty, but typed error object to which the errors need to be appended to. */
      errors: FormikErrors<TS>;
    }) => FormikErrors<TS>;
  };
  query?: {
    settings?: SettingsQueryOptions;
    setSettings?: SetSettingsMutationOptions;
  };
}

/** Time to throttle quickly changing loading states to avoid flickering for elements that depend on the loading states. */
const LOADING_STATE_THROTTLE_TIME = sharedConfig.shared.minLoadingTimeout;

// BCD-7381 Improve Tool Config Type Association
export const useToolSettings = <TS extends ToolSetting = ToolSetting>(
  toolKey: ExternalToolKey,
  options: UseToolSettingsOptions<TS> = {}
) => {
  const settings = useSettings(options.query?.settings);
  const setSettings = useSetSettings(options.query?.setSettings);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const fallbackToolSettings = { meta: {} };
  const currentToolSettings = settings.query.data?.toolSettings[toolKey] as TS;
  const toolSettings = currentToolSettings ?? fallbackToolSettings;
  const setToolSettings = setSettings.setToolSettings<TS>(toolKey);

  const formik = useFormik<TS>({
    enableReinitialize: true,
    initialValues: options.formik?.initialValues?.(toolSettings) ?? toolSettings,
    validate: (values: TS) => {
      if (options.formik?.validate) {
        const errors: FormikErrors<TS> = {};
        return options.formik.validate({ values, errors });
      } else {
        return {};
      }
    },
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);

      if (options.formik?.onSubmit) {
        await options.formik.onSubmit({ values, helpers, setToolSettings });
      } else {
        setToolSettings({
          ...values,
          meta: {
            ...values.meta,
            touchedSettings: true
          }
        });
      }

      helpers.setSubmitting(false);
      setHasSubmitted(true);
    }
  });

  const isFetching = useThrottle(
    settings.query.isFetching || setSettings.mutation.isPending || formik.isSubmitting,
    LOADING_STATE_THROTTLE_TIME
  );

  const isSuccess = useThrottle(
    setSettings.mutation.isSuccess || settings.query.isSuccess || hasSubmitted,
    LOADING_STATE_THROTTLE_TIME
  );

  // Mark the tool as viewed if we visit the page.
  useOnMount(() => {
    if (!toolSettings.meta.viewedSettings) {
      setToolSettings({
        ...toolSettings,
        meta: {
          ...toolSettings.meta,
          viewedSettings: true
        }
      });
    }
  });

  return {
    /** The raw query return object of `useSettings`. */
    settingsQuery: settings,
    /** The raw mutation return object of `useSetSettings`. */
    setSettingsMutation: setSettings,
    /** The current tool settings for the provided tool key. */
    toolSettings,
    /** The mutation function for setting the tool settings. */
    setToolSettings,
    /** The formik object for the tool settings form. */
    formik,
    /** Has the user tried to submit the form? */
    hasSubmitted,
    /** Convience property for checking whether the settings, the mutation is currently fetching or formik is submitting. */
    isFetching,
    /** Convience property for checking whether the settings or the mutation was successful. */
    isSuccess
  };
};
