import React from "react";
import { FormikProps, FormikValues } from "formik";
import { get } from "lodash";

import LabeledFieldComponent from "../../components/LabeledField/LabeledField";

import { FieldProps, isFocusEvent } from "./Field";

const LabeledField = <V extends FormikValues>({
  formik,
  children: field,
  convertToFormValue = (value: unknown) => value,
  ...labelProps
}: {
  formik: FormikProps<V>;
  children: React.ReactElement<FieldProps>;
  convertToFormValue?: (value: unknown) => unknown;
} & Omit<React.ComponentProps<typeof LabeledFieldComponent>, "children">) => {
  const { value, checked, name, onChange } = field.props;

  return (
    <LabeledFieldComponent
      {...labelProps}
      error={get(formik.touched, name) ? (get(formik.errors, name) as string) : undefined}
    >
      {React.cloneElement(field, {
        value: value ?? get(formik.values, name),
        checked: checked ?? get(formik.values, name),
        error: get(formik.touched, name) ? (get(formik.errors, name) as string) : undefined,
        onBlur: (event?: Event) => (isFocusEvent(event) ? formik.handleBlur(event) : formik.setFieldTouched(name)),
        onChange: onChange ?? ((value: unknown) => formik.setFieldValue(name, convertToFormValue(value))),
        onClearClick: () => {
          formik.setFieldValue(name, "");
          formik.setFieldTouched(name);
        }
      })}
    </LabeledFieldComponent>
  );
};

export default LabeledField;
