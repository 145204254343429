import React from "react";
import { push, replace } from "connected-react-router";
import { useLocation } from "react-router";

import { GlobalLocationState } from "../../../../commons/types/location";

import useDispatch from "./use-dispatch";

const useRestoreScrollPosition = (scrollRef: React.RefObject<HTMLElement>, shouldRestore: boolean = true) => {
  const dispatch = useDispatch();
  const location = useLocation<GlobalLocationState>();

  const [didRestoreScroll, setDidRestoreScroll] = React.useState(false);

  const onPushAndStoreScrollPosition = (pushLocation: string, state?: GlobalLocationState) => {
    const scrollElement = scrollRef.current;
    const scrollPosition = scrollElement?.scrollTop ?? 0;

    // Use a replace to push the state on the stack
    dispatch(
      replace([location.pathname, location.search, location.hash].join(""), {
        ...(location.state || {}),
        scrollPosition
      })
    );

    dispatch(push(pushLocation, state));
  };

  React.useEffect(() => {
    if (!didRestoreScroll && shouldRestore) {
      const scrollElement = scrollRef.current;

      if (location.state?.scrollPosition && scrollElement) {
        scrollElement.scrollTop = location.state?.scrollPosition;
        setDidRestoreScroll(true);
      }
    }
  }, [didRestoreScroll, location.state?.scrollPosition, scrollRef, shouldRestore]);

  return onPushAndStoreScrollPosition;
};

export default useRestoreScrollPosition;
