import { getBodyAverageValues } from "../../../../commons/libs/sizing-service";

import useFetchData from "./use-fetch-data";

export default (isEnabled: boolean = true) => {
  const dummyRequest = useFetchData(() => getBodyAverageValues(180), [], { isEnabled });

  return {
    isLoading: dummyRequest.isLoading,
    available: isEnabled && !dummyRequest.isLoading && !dummyRequest.error
  };
};
