import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useOnMount from "../../libs/hooks/use-on-mount";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import TileIconButton from "../TileIconButton/TileIconButton";

interface Props extends Component {
  children: React.ReactNode;
  title: string;
  badge?: React.ReactNode;
  description?: React.ReactNode;
  sub?: string;
  closeButton?: React.ReactElement<React.ComponentProps<typeof TileIconButton>>;
  limitHeight?: boolean;
  alignRight?: boolean;
  loadingIndicator?: React.ReactElement<React.ComponentProps<typeof LoadingIndicator>>;
}

const FilterModal = React.forwardRef(
  (
    {
      classNames = [],
      children,
      title,
      badge,
      description,
      // eslint-disable-next-line @typescript-eslint/no-deprecated -- false-negative: `sub` is not deprecated
      sub = "Filter",
      closeButton,
      limitHeight = false,
      alignRight = false,
      loadingIndicator
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const childrenRef = React.useRef<HTMLDivElement>(null);
    const [asideMaxWidth, setAsideMaxWidth] = React.useState<number>(0);

    // To prevent long titles expanding the width of the `FilterModal`, set the max-width to the current width of the children.
    // Note: This is currently necessary, because the children define the width of the `FilterModal` in combination with the fixed `filter-item-width` from the config.
    // Note: This is not re-evaluated on resize events!
    useOnMount(() => {
      if (childrenRef.current) {
        setAsideMaxWidth(childrenRef.current.offsetWidth);
      }
    });

    return (
      <section className={cn("FilterModal", [{ limitHeight, alignRight }], classNames)} ref={ref}>
        <div className="FilterModal__aside" style={{ maxWidth: asideMaxWidth }}>
          <div className="FilterModal__meta">
            <div className="FilterModal__sub">{sub}</div>
            <div className="FilterModal__titleContainer">
              <h2 className="FilterModal__title">{title}</h2>
              {badge && <div className="FilterModal__badge">{badge}</div>}
              {loadingIndicator && loadingIndicator}
            </div>

            {description && <div className="FilterModal__description">{description}</div>}
          </div>
          <div className="FilterModal__closeButton">{closeButton}</div>
        </div>
        <div ref={childrenRef} className="FilterModal__children">
          {children}
        </div>
      </section>
    );
  }
);

FilterModal.displayName = "FilterModal";

export default FilterModal;
