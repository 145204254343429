import React from "react";
import { push, replace } from "connected-react-router";
import { compact } from "lodash";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { Redirect, RouteChildrenProps, useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";

import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import envelope from "../../../../commons/libs/externals/envelope";
import { getSpecConfig } from "../../../../commons/specs";
import { ActiveFilters, FilterGroups } from "../../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { AssortmentType } from "../../../../commons/types/assortment";
import { Brand } from "../../../../commons/types/brand";
import { GlobalLocationState } from "../../../../commons/types/location";
import { ProductFinderOverviewInitialTab } from "../../../../commons/types/settings";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Button from "../../../commons/components/Button/Button";
import CurrentPageBreadcrumb from "../../../commons/components/CurrentPageBreadcrumb/CurrentPageBreadcrumb";
import Dot from "../../../commons/components/Dot/Dot";
import FlexLayout from "../../../commons/components/FlexLayout/FlexLayout";
import Icon from "../../../commons/components/Icon/Icon";
import IconLink from "../../../commons/components/IconLink/IconLink";
import MainContentLayout from "../../../commons/components/MainContentLayout/MainContentLayout";
import TabContent from "../../../commons/components/TabContent/TabContent";
import TabLink from "../../../commons/components/TabContent/TabLink";
import Tooltip, { HandleProps, TooltipPosition } from "../../../commons/components/Tooltip/Tooltip";
import { connect } from "../../../commons/container/utils/loop";
import { ROUTES } from "../../../commons/routes";
import { areNewAndUnconfiguredModelYearsInBrands } from "../../libs/brand";
import { isActiveFilter } from "../../libs/filters";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import useSizingAvailability from "../../libs/hooks/use-sizing-availability";
import { useActiveBrands } from "../../libs/queries/use-active-brands";
import useProductFilter from "../../libs/queries/use-product-filter";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";

export interface RouteDefinition {
  path: string;
  component: React.ComponentType<any>;
  title: string;
  value: ProductFinderOverviewInitialTab;
  props?: object;
}

const mapStateToProps = (state: State) => ({
  activeFilters: state.session.activeFilters,
  productFinderOverviewInitialTab:
    selectors.selectInitializedSettings(state).customization.productFinderOverviewInitialTab,
  bodySizingEnabled: selectors.selectIsBodySizingEnabled(state),
  customerGroup: getCustomerGroupConfig(selectors.selectInitializedEnv(state).customerGroup),
  customization: selectors.selectInitializedSettings(state).customization,
  assortment: selectors.selectInitializedSettings(state).assortment,
  assortmentFilterSettings: selectors.selectAssortmentFilterSettings(state),
  assortmentPriceSettings: selectors.selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = {
  onReplace: replace,
  onPush: push,
  openAuthModal: actions.modal.openAuthModal,
  openBrandsModelYearActivationModal: actions.modal.openBrandsModelYearActivationModal
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  backButton: React.ReactNode;
  productFinderOverviewRoutes: RouteDefinition[];
  isOffline: boolean;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const tabHasBrandNotification = (route: RouteDefinition): boolean => {
  return route.value === ProductFinderOverviewInitialTab.Brands;
};

const tabHasActiveFilter = <Type extends ProductType>(
  productFilterGroups: FilterGroups<Type>,
  route: RouteDefinition,
  activeFilters: ActiveFilters<Type>
): boolean => {
  switch (route.value) {
    case ProductFinderOverviewInitialTab.Details:
      return productFilterGroups
        .flatMap(group =>
          group.filters.flatMap(filter =>
            Array.isArray(filter)
              ? filter.map(filterKey => isActiveFilter(activeFilters, filterKey))
              : isActiveFilter(activeFilters, filter)
          )
        )
        .some(isActive => isActive);

    case ProductFinderOverviewInitialTab.Categories:
      return !!isActiveFilter(activeFilters, ProductSpecKey.CategoryKey);

    default:
      return false;
  }
};

const ProductFinderOverviewPartial = ({
  backButton,
  onReplace,
  onPush,
  productFinderOverviewInitialTab,
  productFinderOverviewRoutes,
  openAuthModal,
  openBrandsModelYearActivationModal,
  bodySizingEnabled,
  isOffline,
  customization,
  customerGroup,
  assortment,
  assortmentFilterSettings,
  assortmentPriceSettings
}: Props) => {
  const { t } = useTranslation(["commons"]);

  const { data: activeBrands } = useActiveBrands();

  const productType = customerGroup.productTypes[0];
  const specConfig = getSpecConfig(productType);

  const location = useLocation<GlobalLocationState>();
  const pageRef = React.useRef(null);
  const breakpoints = useMediaQuery();
  const currentRoutePath = location.pathname;

  const productFilter = useProductFilter({
    productType,
    assortmentFilterSettings,
    assortmentPriceSettings,
    automaticAssortmentFilter: assortment[AssortmentType.Automatic]?.filter,
    shouldSetDefaultActiveFilters: location.state?.shouldSetDefaultActiveFilters
  });

  const sizingAvailability = useSizingAvailability(bodySizingEnabled && !isOffline);

  const handleLinkClick = (_event: React.MouseEvent<HTMLElement>, href: string) => {
    onPush(href);
  };

  const handleTabClick = (_event: React.MouseEvent<HTMLElement>, href: string) => {
    const locationState: GlobalLocationState = location?.state?.goBackToDetails
      ? {
          goBackToDetails: location.state.goBackToDetails
        }
      : {};

    onReplace(href, locationState);
  };

  const [sortedRoutes, initialProductFinderOverviewRoute] = React.useMemo(
    () => [
      compact([
        productFinderOverviewRoutes.find((route: RouteDefinition) => route.value === productFinderOverviewInitialTab),
        ...productFinderOverviewRoutes.filter(
          (route: RouteDefinition) => route.value !== productFinderOverviewInitialTab
        )
      ]),
      productFinderOverviewRoutes.find(route => route.value === productFinderOverviewInitialTab)?.path ??
        ROUTES.PRODUCT_FINDER_OVERVIEW.BRANDS
    ],
    [productFinderOverviewInitialTab, productFinderOverviewRoutes]
  );

  const renderUnconfigureBrandModelYearNotification = ({ close }: HandleProps) => (
    <FlexLayout direction="column" gap="xs">
      <strong>
        {customization.modelYearDisplay.term === "modelYear"
          ? t("commons:productFinderOverviewPartialProductFinderOverview.newModelYearsAvailableTitle")
          : t("commons:productFinderOverviewPartialProductFinderOverview.newReleaseYearsAvailableTitle")}
      </strong>

      {envelope.isMobileBuild ? (
        <span>{t("commons:productFinderOverviewPartialProductFinderOverview.updateBrandsDevice")}</span>
      ) : (
        <Button
          size="s"
          variant="standout"
          onClick={() => {
            close();
            openAuthModal({ onSuccess: () => openBrandsModelYearActivationModal({}) });
          }}
        >
          {t("commons:productFinderOverviewPartialProductFinderOverview.updateButton")}
        </Button>
      )}
    </FlexLayout>
  );

  const renderTabLinkWithBrandActivationModal = (
    route: RouteDefinition,
    brandData: Brand[]
  ): React.ReactElement<React.ComponentProps<typeof TabLink>> => (
    <Route
      exact
      key={route.path}
      path={route.path}
      children={({ match }: RouteChildrenProps) => (
        <TabLink
          active={match !== null}
          label={route.title}
          href={route.path}
          onClick={handleTabClick}
          notification={
            tabHasBrandNotification(route) && areNewAndUnconfiguredModelYearsInBrands(brandData) ? (
              <Tooltip
                content={renderUnconfigureBrandModelYearNotification}
                isCloseable
                openOnMount
                openOnMountDelay={1000}
                shouldCloseAutomatically={false}
                preferredPosition={TooltipPosition.Bottom}
                closeOnOutsideClick={false}
                variant="accent"
                extraHorizontalPadding
              >
                <Dot pulse />
              </Tooltip>
            ) : tabHasActiveFilter(specConfig.filterGroups, route, productFilter.activeFilters) && !match ? (
              <Dot />
            ) : undefined
          }
        />
      )}
    />
  );

  const renderTabLink = (route: RouteDefinition): React.ReactElement<React.ComponentProps<typeof TabLink>> => {
    return (
      <Route
        exact
        key={route.path}
        path={route.path}
        children={({ match }: RouteChildrenProps) => (
          <TabLink
            active={match !== null}
            label={route.title}
            href={route.path}
            onClick={handleTabClick}
            notification={
              tabHasActiveFilter(specConfig.filterGroups, route, productFilter.activeFilters) && !match ? (
                <Dot />
              ) : undefined
            }
          />
        )}
      />
    );
  };

  const renderTabNavigationLinks = (
    sortedRoutes: RouteDefinition[],
    brands: Brand[]
  ): Array<React.ReactElement<React.ComponentProps<typeof TabLink>>> => {
    return sortedRoutes.map((route: RouteDefinition) => {
      if (tabHasBrandNotification(route)) {
        return renderTabLinkWithBrandActivationModal(route, brands);
      } else {
        return renderTabLink(route);
      }
    });
  };

  return (
    <MainContentLayout
      ref={pageRef}
      headerLeft={backButton}
      headerRight={
        <>
          <CurrentPageBreadcrumb>
            {t("commons:productFinderOverviewPartialProductFinderOverview.findBikes")}
          </CurrentPageBreadcrumb>
          {currentRoutePath === ROUTES.PRODUCT_FINDER_OVERVIEW.BRANDS && !envelope.isMobileBuild && (
            <IconLink
              kind="support"
              href={ROUTES.SETTINGS.BRAND_ACTIVATION}
              onClick={handleLinkClick}
              icon={<Icon source={icons.IconSmallSettings} />}
            >
              {t("commons:productFinderOverviewPartialProductFinderOverview.configureBrandsButton")}
            </IconLink>
          )}
        </>
      }
      noPaddingBottom={
        !breakpoints.xl &&
        (currentRoutePath === ROUTES.PRODUCT_FINDER_OVERVIEW.CATEGORIES ||
          currentRoutePath === ROUTES.PRODUCT_FINDER_OVERVIEW.DETAILS)
      }
    >
      <TabContent tabLinksAlignment="center" tabLinks={renderTabNavigationLinks(sortedRoutes, activeBrands)}>
        <Switch>
          <Redirect exact from={ROUTES.PRODUCT_FINDER_OVERVIEW.INDEX} to={initialProductFinderOverviewRoute} />
          {productFinderOverviewRoutes.map(route => (
            <Route
              exact
              key={route.path}
              path={route.path}
              render={props =>
                React.createElement(route.component, {
                  ...props,
                  ...(route.props ?? {}),
                  pageRef,
                  sizingAvailability,
                  productFilter
                })
              }
            />
          ))}
        </Switch>
      </TabContent>
    </MainContentLayout>
  );
};

export default connector(ProductFinderOverviewPartial);
