import React from "react";
import { ConnectedProps } from "react-redux";

import { LogoType } from "../../../../commons/types/logo";
import actions from "../../actions";
import { State } from "../../reducers";
import { connect } from "../utils/loop";

import LogoPartial from "./LogoPartial";

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  openAboutModal: actions.modal.openAboutModal
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OuterProps = {
  invert?: boolean;
};
type Props = ConnectedProps<typeof connector> & OuterProps;

const AppLogoPartial = ({ invert = false, openAboutModal }: Props) => (
  <LogoPartial type={invert ? LogoType.BcIconInvert : LogoType.BcIcon} onClick={openAboutModal} />
);

export default connector(AppLogoPartial);
