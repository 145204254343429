import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallCheck } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  bg: string;
  fg: string;
  checked?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const ColorCheckCircle = ({ classNames = [], bg, fg, checked = false, onClick = noop }: Props) => {
  const borderColor = darkenColor(bg, 15);

  return (
    <button
      className={cn("ColorCheckCircle", [{ checked }], classNames)}
      onClick={onClick}
      style={{ backgroundColor: bg, color: fg, borderColor }}
    >
      {checked && (
        <span className="ColorCheckCircle__icon">
          <Icon source={IconSmallCheck} />
        </span>
      )}
    </button>
  );
};

function darkenColor(hexColor: string, percentage: number): string {
  // Remove the hash if it exists
  const hex = hexColor.replace(/^#/, "");

  // Parse the hex string to RGB values
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Calculate the darkening factor
  const darkenFactor = 1 - percentage / 100;

  // Darken each color component
  const darkenedR = Math.max(0, Math.floor(r * darkenFactor));
  const darkenedG = Math.max(0, Math.floor(g * darkenFactor));
  const darkenedB = Math.max(0, Math.floor(b * darkenFactor));

  // Convert the darkened RGB values back to hex
  const darkenedHex = `#${darkenedR.toString(16).padStart(2, "0")}${darkenedG.toString(16).padStart(2, "0")}${darkenedB.toString(16).padStart(2, "0")}`;

  return darkenedHex;
}

export default ColorCheckCircle;
