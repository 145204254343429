import React from "react";

import { Product, ProductId } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import AvailabilityOverlayBase from "../../../commons/container/ProductDetails/AvailabilityOverlay";

interface OuterProps {
  variantId?: ProductId;
  product: Product;
  brand: Brand;
}

const AvailabilityOverlay = ({ product, brand, variantId }: OuterProps) => (
  <AvailabilityOverlayBase product={product} brand={brand} variantId={variantId} hasInternetConnectivity />
);

export default AvailabilityOverlay;
