import React from "react";
import { flatMap, values } from "lodash";
import { matchPath, useLocation } from "react-router-dom";

import { useTrackingContext } from "../../container/utils/tracking-context";
import { ROUTES } from "../../routes";

import usePrevious from "./use-previous";

const useTrackPageView = () => {
  const { mixpanel } = useTrackingContext();

  const location = useLocation();
  const prevLocation = usePrevious(location);

  React.useEffect(() => {
    if (!prevLocation || location.pathname !== prevLocation.pathname) {
      flatMap(ROUTES, values).every(path => {
        const match = matchPath<{ [params: string]: string }>(location.pathname, { path, exact: true });
        if (match) {
          mixpanel?.trackPageViewed(location.pathname, match.path, match.params);
          return false; // break
        }

        return true; // continue
      });
    }
  }, [location.pathname, mixpanel, prevLocation]);
};

export default useTrackPageView;
