import { ImporterStats, ImporterStatsFailedReason, ProductAvailability, WawiType } from "../libs/externals/wawi-proxy";
import { ProductId, ProductSpecKey, StandaloneProductVariant } from "../specs/product";

export { WawiType } from "../libs/externals/wawi-proxy";

// FtpWawiType are used to display the manufacturer contact infos of the ERP system in the ERP ftp settings
// Note: add new manually configurable ftp based wawis here as well as in WawiTypes
export type FtpWawiType =
  | WawiType.ADVARICS
  | WawiType.ASCEND
  | WawiType.BIDEX
  | WawiType.BIKEARENA_OLTMANNS
  | WawiType.CSB
  | WawiType.DOIT7
  | WawiType.FAMOWA
  | WawiType.HIBIKE
  | WawiType.HIW
  | WawiType.INTERSPORT_AUSTRIA_COMBINED
  | WawiType.INTERSPORT_SAUER
  | WawiType.RADFAK
  | WawiType.SERVI_BIKES
  | WawiType.VELODATA
  | WawiType.VELOPORT
  | WawiType.ZEGWW3
  | WawiType.FAHRRAD_XXL_FRANZ;

export interface WawiSettings {
  apiKey?: string;
  type?: WawiType;
}

export interface TridataWawiCredentials {
  dealerId: string;
}

export enum WawiErrorType {
  BikecenterHasNoConnection = "BikecenterHasNoConnection",
  InvalidCredentials = "InvalidCredentials",
  Other = "Other",
  TypeNotImplemented = "TypeNotImplemented",
  Unauthorized = "Unauthorized",
  WaWiCredentialsInWrongFormat = "WaWiCredentialsInWrongFormat",
  InvalidProductIdentification = "InvalidProductIdentification",
  FtphostNoOfficialInstance = "FtphostNoOfficialInstance"
}

export enum FtpWawiHosts {
  Default = "ftp.bike.center"
}

export interface FtpWawiCredentials {
  ftphost: string;
  username: string;
  password: string;
}

export enum WawiAvailabilityCode {
  Available = "available",
  Ordered = "ordered",
  NotAvailable = "not_available",
  Unknown = "unknown"
}

export enum SupplierAvailabilityCode {
  Available = "available",
  Orderable = "orderable",
  NotAvailable = "not_available"
}

export enum WawiImporterStatus {
  ImportsEmpty = "ImportsEmpty",
  ImportFailed = "ImportFailed",
  Success = "Success",
  ImportStarted = "ImportStarted",
  ImportRunning = "ImportRunning"
}

export type WawiCredentials = TridataWawiCredentials | FtpWawiCredentials;

export interface WawiAvailability {
  location: string;
  currentLocation: boolean;
  productCount: number;
  orderedCount?: number;
  orderedForDate?: Date;
  code: WawiAvailabilityCode;
}

export interface WawiProductAvailabilityVariant {
  variantId: ProductId | null;

  itemUnknown?: boolean;
  itemAmbiguous?: boolean;

  supplier?: {
    code: SupplierAvailabilityCode;
    expectedDeliveryDate?: Date;
  };

  availabilities: WawiAvailability[];
}

export interface GetWawiProductAvailabiltyResponse {
  productId: ProductId;
  variants: WawiProductAvailabilityVariant[];
}

export type DebugConnection = {
  value: string | number | null;
  itemAmbiguous?: boolean;
  itemUnknown?: boolean;
};

export interface DebugAvailability {
  brandNameOrId: string;
  modelName: string;
  gtin: string;
  articleNumber: string;
  connectionStatus: DebugConnection;
}

export interface GetWawiImporterStatsResponse {
  status: WawiImporterStatus;
  failedReason: ImporterStatsFailedReason | null;
  lastImport: ImporterStats | null;
  lastSuccessfulImport: ImporterStats | null;
}

export interface WawiImporterDetails {
  id?: string;
}

export interface StartWawiImporterResponse {
  status: WawiImporterStatus;
  details: WawiImporterDetails;
}

export interface WaWiResult {
  productId: ProductId;
  variantId: ProductId | null;
  results: ProductAvailability[];
  variant: {
    [ProductSpecKey.BrandName]?: StandaloneProductVariant[ProductSpecKey.BrandName];
    [ProductSpecKey.BrandKey]?: StandaloneProductVariant[ProductSpecKey.BrandKey];
    [ProductSpecKey.ModelName]?: StandaloneProductVariant[ProductSpecKey.ModelName];
    [ProductSpecKey.Gtin]?: StandaloneProductVariant[ProductSpecKey.Gtin];
    [ProductSpecKey.Upc]?: StandaloneProductVariant[ProductSpecKey.Upc];
    [ProductSpecKey.ArticleNumber]?: StandaloneProductVariant[ProductSpecKey.ArticleNumber];
  };
}
