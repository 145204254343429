import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import { IconSmallCheck } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

interface Props extends Component {
  label: string;
  active?: boolean;
  outline?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const CheckboxListItem = ({ classNames = [], label, active = false, outline = false, onClick = noop }: Props) => (
  <button type="button" onClick={onClick} className={cn("CheckboxListItem", [{ active, outline }], classNames)}>
    <span className="CheckboxListItem__label">{label}</span>
    <div className="CheckboxListItem__icon">
      <Icon source={IconSmallCheck} />
    </div>
  </button>
);

export default CheckboxListItem;
