import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const MonospaceParagraph = ({ classNames = [], children }: Props) => (
  <pre className={cn("MonospaceParagraph", [], classNames)}>{children}</pre>
);

export default MonospaceParagraph;
