import { Settings } from "../types/settings";

import { deleteFromService, getFromService, postToService } from "./client-utils";

const settingsService = "settings";

// Settings object is navigated like a tree.
// pathToValue is a list of key strings identifying the needed value

export function getSettings(): Promise<Settings> {
  return getFromService(settingsService, "/");
}

export function storeSetting(pathToValue: string[], value: any): Promise<Settings> {
  return postToService(settingsService, pathToValue.join("/"), { value });
}

export function deleteSetting(pathToValue: string[]): Promise<Settings> {
  return deleteFromService(settingsService, pathToValue.join("/"));
}
