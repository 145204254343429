import { strict as assert } from "assert";
import { Action, AnyAction, Reducer } from "redux";
import { getCmd, getModel, isLoop, Loop, loop } from "redux-loop";

export type InnerReducer<OuterState, InnerState, CurrentAction extends Action> = (
  state: OuterState,
  action: CurrentAction
) => InnerState | Loop<InnerState>;

export const mapReducerStateWithKey =
  <State, Key extends keyof State, CurrentAction extends Action = AnyAction>(
    reducer: InnerReducer<State, State[Key], CurrentAction>,
    key: Key
  ) =>
  (state: State | undefined, action: CurrentAction) => {
    assert(state);
    const returnValue = reducer(state, action);

    if (isLoop(returnValue)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Cmd is non-null when isLoop is true
      const cmd = getCmd(returnValue)!; //
      return loop({ ...state, [key]: getModel(returnValue) }, cmd);
    } else {
      return { ...state, [key]: returnValue };
    }
  };

export const mapReducerWithKey =
  <OuterState, Key extends keyof OuterState, CurrentAction extends Action = AnyAction>(
    reducer: Reducer<OuterState[Key], CurrentAction>,
    key: Key
  ) =>
  (state: OuterState | undefined, action: CurrentAction) => {
    assert(state);
    return { ...state, [key]: reducer(state[key], action) };
  };
