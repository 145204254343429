// config-CONFIG_TARGET exports both, kiosk and mobile
// this helps for linting and development.
// CONFIG_TARGET gets replaced with MOBILE or KIOSK in webpack
// during the build.
import config from "./config-CONFIG_TARGET";
import sharedConfig from "./shared";

export default {
  ...sharedConfig,
  ...config
};
