import React, { useMemo } from "react";
import { goBack, replace } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { Route, useLocation, useParams } from "react-router-dom";

import { getProduct } from "../../../../commons/libs/content-service";
import { byDefined } from "../../../../commons/libs/filter-guards";
import { getSpecFormatter } from "../../../../commons/libs/formatters";
import { getBrandLogoUrl } from "../../../../commons/libs/resource-paths";
import { getFirstVariantId, getSingleSpecValue, getStandaloneProductVariant } from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { Product, ProductId, ProductKey, ProductSpecKey } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import { GlobalLocationState } from "../../../../commons/types/location";
import * as icons from "../../../../resources/icons";
import { IconSmallCross } from "../../../../resources/icons";
import { useTrackingContext } from "../../../commons/container/utils/tracking-context";
import { buildProductPath } from "../../../commons/libs/path";
import { getNeighbouringProducts } from "../../../commons/libs/products";
import actions from "../../actions";
import BrandImage from "../../components/BrandImage/BrandImage";
import Button from "../../components/Button/Button";
import CenteredPageLayout from "../../components/CenteredPageLayout/CenteredPageLayout";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Icon from "../../components/Icon/Icon";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import MainContentLayout from "../../components/MainContentLayout/MainContentLayout";
import ProductDetailsContentLayout from "../../components/ProductDetailsContentLayout/ProductDetailsContentLayout";
import SwipeableContent, { TransitionDirection } from "../../components/SwipeableContent/SwipeableContent";
import TabBarPageLayout from "../../components/TabBarPageLayout/TabBarPageLayout";
import useFetchData from "../../libs/hooks/use-fetch-data";
import useVeloconnectEndpoint, { UseVeloconnectEndpointReturn } from "../../libs/hooks/use-veloconnect-endpoint";
import { useActiveBrands } from "../../libs/queries/use-active-brands";
import { selectAssortmentPriceSettings, selectInitializedSettings } from "../../libs/selectors";
import { State } from "../../reducers";
import { ROUTES } from "../../routes";
import ProductTitlePartial from "../partials/ProductTitlePartial";
import { connect } from "../utils/loop";
import { VeloconnectEndpointProvider } from "../utils/veloconnect-endpoint-context";

import ProductDetailsImagePartial from "./ProductDetailsImagePartial";

const mapStateToProps = (state: State) => ({
  settings: selectInitializedSettings(state),
  assortmentPriceSettings: selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = {
  onReplace: replace,
  onGoBack: goBack,
  onError: actions.error.set
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface ProductDetailsPartialRenderProps {
  productId: ProductId;
  variantId: ProductId | undefined;
  product: Product | undefined;
  productContent?: {
    key: string;
    link: string;
  };
  productInitializingFirstTime: boolean;
  brand: Brand | undefined;
  veloconnectEndpoint: UseVeloconnectEndpointReturn;
  isAsideAnimationRunning: boolean;
}

interface OuterProps {
  tabBar: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  tabBarOverlay: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  advertisement?: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  productContentAside?: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  productContentCta?: (props: ProductDetailsPartialRenderProps) => React.ReactNode;
  backButton: () => React.ReactNode;
  onProductLoad?: (product: Product) => void;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const ProductDetailsPartial = ({
  onReplace,
  onError,
  onGoBack,
  settings,
  tabBar,
  backButton,
  tabBarOverlay,
  advertisement,
  productContentAside,
  productContentCta,
  onProductLoad,
  assortmentPriceSettings
}: Props) => {
  const { t, i18n } = useTranslation(["commons"]);

  const { getActiveBrand } = useActiveBrands();

  const location = useLocation<GlobalLocationState>();
  const { mixpanel } = useTrackingContext();
  const productListContext = (location.state || {}).productListContext || [];
  const variantId = location.state?.variantId;
  const { id: productId } = useParams<{ id: ProductId }>();

  const [transitionDirection, setTransitionDirection] = React.useState<TransitionDirection>(TransitionDirection.Next);
  const [isAsideAnimationRunning, setIsAsideAnimationRunning] = React.useState(false);

  const { isLoading: isLoading, data: { product, productVariant, specFormatter } = {} } = useFetchData(
    async () => {
      const product = await getProduct(productId, settings.currency, assortmentPriceSettings);

      const actualVariantId = variantId ?? getFirstVariantId(product);
      const productVariant = getStandaloneProductVariant(
        product,
        product[ProductKey.Variants].find(variant => variant[ProductKey.VariantId] === actualVariantId)
      );

      const productType = product[ProductKey.ProductType];
      const specConfig = getSpecConfig(productType);

      const specFormatter = getSpecFormatter(productType, specConfig.specDefinitions, i18n, settings.customization);

      return { product, productVariant, specFormatter };
    },
    [productId, variantId, settings.currency],
    {
      handleError: error => onError(error),
      onDone: ({ product }) => {
        onProductLoad?.(product);
      }
    }
  );

  const tabBarRoutes = useMemo(
    () =>
      [
        ROUTES.PRODUCT_DETAILS.SPECIFICATIONS,
        ROUTES.PRODUCT_DETAILS.BOOKMARK,
        ROUTES.PRODUCT_DETAILS.BOOKMARK_COMPARE,
        ROUTES.PRODUCT_DETAILS.AVAILABILITY
      ].map(route => buildProductPath(route, { productId })),
    [productId]
  );

  const isOnTabBarRoute = location.pathname ? tabBarRoutes.includes(location.pathname) : false;

  const productContentKey = product ? getSingleSpecValue(ProductSpecKey.ProductContentKey, product) : undefined;
  const productContentLink = productContentKey
    ? buildProductPath(ROUTES.PRODUCT_DETAILS.PRODUCT_CONTENT, {
        productId,
        productContentKey
      })
    : undefined;

  const isOnProductContentRoute = location.pathname === productContentLink;

  const productInitializingFirstTime = !product && isLoading;

  const brandKey = product && getSingleSpecValue(ProductSpecKey.BrandKey, product);
  const brand = brandKey ? getActiveBrand(brandKey) : undefined;
  const neighbours = getNeighbouringProducts(productListContext, productId);

  const veloconnectEndpoint = useVeloconnectEndpoint(brandKey);

  const renderProps: ProductDetailsPartialRenderProps = {
    productId,
    variantId,
    product,
    brand,
    productInitializingFirstTime,
    veloconnectEndpoint,
    isAsideAnimationRunning,
    ...(productContentKey && productContentLink
      ? { productContent: { key: productContentKey, link: productContentLink } }
      : undefined)
  };

  React.useEffect(() => {
    if (product) {
      mixpanel?.trackProductViewed(product);
    }
    // Track only when product id changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.[ProductKey.ProductId]]);

  const handleNextProduct = (interaction: "click" | "swipe") => {
    if (!isLoading && brand && product && neighbours.next != null) {
      mixpanel?.trackProductDetailsNavigated(
        interaction,
        "next",
        { productId, brandKey: brand.key, modelName: getSingleSpecValue(ProductSpecKey.ModelName, product) },
        neighbours.next.productId
      );

      setTransitionDirection(TransitionDirection.Next);
      onReplace(buildProductPath(ROUTES.PRODUCT_DETAILS.INDEX, { productId: String(neighbours.next.productId) }), {
        ...location.state,
        variantId: neighbours.next.variantId
      });
    }
  };

  const handlePrevProduct = (interaction: "click" | "swipe") => {
    if (!isLoading && brand && product && neighbours.prev != null) {
      mixpanel?.trackProductDetailsNavigated(
        interaction,
        "prev",
        { productId, brandKey: brand.key, modelName: getSingleSpecValue(ProductSpecKey.ModelName, product) },
        neighbours.prev.productId
      );

      setTransitionDirection(TransitionDirection.Prev);
      onReplace(buildProductPath(ROUTES.PRODUCT_DETAILS.INDEX, { productId: String(neighbours.prev.productId) }), {
        ...location.state,
        variantId: neighbours.prev.variantId
      });
    }
  };

  return (
    <Route
      exact
      path={ROUTES.PRODUCT_DETAILS.INDEX}
      children={() => (
        <VeloconnectEndpointProvider endpoint={veloconnectEndpoint}>
          <TabBarPageLayout
            isTabBarOverlayOpen={!productInitializingFirstTime && isOnTabBarRoute}
            tabBar={tabBar(renderProps)}
            tabBarOverlay={tabBarOverlay(renderProps)}
            onCloseTabBarOverlay={() => {
              onReplace(buildProductPath(ROUTES.PRODUCT_DETAILS.INDEX, { productId }), location.state);
            }}
            isAsideOpen={isOnProductContentRoute}
            onAsideAnimationStarted={() => setIsAsideAnimationRunning(true)}
            onAsideAnimationCompleted={() => setIsAsideAnimationRunning(false)}
            aside={productContentAside?.(renderProps)}
            asideCloseButton={
              <Button onClick={() => onGoBack()} variant="accent" kind="solid" icon={<Icon source={IconSmallCross} />}>
                {t("productDetailsPartialProductDetails.closeAsideButtonLabel")}
              </Button>
            }
            asideProductTitle={<ProductTitlePartial product={product} wrap align="left" />}
          >
            <ProductDetailsContentLayout
              itemsRight={[productContentCta?.(renderProps), advertisement?.(renderProps)].filter(byDefined)}
              alignment={!!advertisement?.(renderProps) ? "right-bottom" : "right-center"}
              isAsideOpen={isOnProductContentRoute}
            >
              {!product && (
                <CenteredPageLayout>
                  <LoadingIndicator size="s" />
                </CenteredPageLayout>
              )}
              {product && productVariant && specFormatter && (
                <MainContentLayout
                  layout="full"
                  headerLeft={backButton()}
                  // TODO: Refactor with header component. Adding a simple margin works fine but just in this special case. It breaks out of the header
                  headerRight={
                    brand && (
                      <FlexLayout alignItems="center" gap="sl" classNames={["u-space-top-l"]}>
                        {brand?.highlighted ? <Icon source={icons.IconMediumHighlight} size="l" /> : undefined}
                        <BrandImage position="right" src={getBrandLogoUrl(brand.key)} />
                      </FlexLayout>
                    )
                  }
                  backgroundImage={null}
                  hideHeaderBackground
                >
                  <SwipeableContent
                    transitionDirection={transitionDirection}
                    classNames={["u-space-top-s", "u-space-xl"]}
                    // prevButton={
                    //   neighbours.prev !== null ? (
                    //     <NextPrevNavigationButton onClick={() => handlePrevProduct("click")} />
                    //   ) : undefined
                    // }
                    // nextButton={
                    //   neighbours.next !== null ? (
                    //     <NextPrevNavigationButton next onClick={() => handleNextProduct("click")} />
                    //   ) : undefined
                    // }
                    onPrev={() => handlePrevProduct("swipe")}
                    onNext={() => handleNextProduct("swipe")}
                  >
                    <ProductDetailsImagePartial
                      // It is necessary to create a new key with every new product to allow SwipeableContent to detect when it has to animate!
                      isProductContentAsideOpen={isOnProductContentRoute}
                      key={product[ProductKey.ProductId]}
                      product={product}
                      productVariant={productVariant}
                    />
                  </SwipeableContent>
                </MainContentLayout>
              )}
            </ProductDetailsContentLayout>
          </TabBarPageLayout>
        </VeloconnectEndpointProvider>
      )}
    />
  );
};

export default connector(ProductDetailsPartial);
