import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  aside?: React.ReactNode;
  footer?: React.ReactNode;
}

const ErrorPageLayout = ({ classNames = [], children, aside = null, footer = null }: Props) => (
  <div className={cn("ErrorPageLayout", [{ aside }], classNames)}>
    <div className="ErrorPageLayout__content">
      <div className="ErrorPageLayout__left">
        <div className="ErrorPageLayout__main">{children}</div>
        {footer && <div className="ErrorPageLayout__footer">{footer}</div>}
      </div>
      {aside && <div className="ErrorPageLayout__right">{aside}</div>}
    </div>
  </div>
);

export default ErrorPageLayout;
