import { ProductId } from "../specs/product";

export enum Gender {
  Female = "female",
  Male = "male",
  Unisex = "unisex"
}

export enum SizingResult {
  Fit = "Fit",
  NoFit = "NoFit",
  NotFound = "NotFound"
}

interface AverageValue {
  range: [number, number];
  averageRange: [number, number];
  average: number;
}

export interface SizingBodyAverageValues {
  armLength: AverageValue;
  inseam: AverageValue;
}

export interface SizingBodyValues {
  inseam: number;
  armLength: number;
  bodyHeight: number;
}

export interface SizeBicycleVariantResult {
  variantId: ProductId;
  result: SizingResult;
}

export interface SizeBicycleProductResult {
  bikeId: ProductId;
  variants: SizeBicycleVariantResult[];
  result: SizingResult;
}

export interface SizeBicycleProductTarget {
  bikeId: ProductId;
  variants: {
    variantId: ProductId;
    code: string | null;
  }[];
}

export type SizeBicycleProductResponse = SizeBicycleProductResult[];

export interface SmartfitAuth {
  apiKey: string;
  origin: string;
}

export interface SizingStatusBikeResult {
  bikeId: ProductId;
  isSizable: boolean;
  variants: {
    variantId: ProductId;
    code: string | null;
    isSizable: boolean;
  }[];
}

export type IsSizingDataAvailableForBicycleProductsResponse = SizingStatusBikeResult[];
