import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Paragraph from "../Paragraph/Paragraph";

interface Props extends Component {
  headline: string;
  children: React.ReactNode;
  footer: React.ReactNode;
}

const InstructionCard = ({ headline, children, footer, classNames = [] }: Props) => (
  <div className={cn("InstructionCard", [], classNames)}>
    <div className="InstructionCard__headline">
      <Paragraph size="s">
        <strong>{headline}</strong>
      </Paragraph>
    </div>

    <div className="InstructionCard__instruction">{children}</div>

    <div className="InstructionCard__footer">{footer}</div>
  </div>
);

export default InstructionCard;
