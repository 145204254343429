import React from "react";
import { noop } from "lodash";
import Slider from "rc-slider";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Balloon from "../Balloon/Balloon";

type LabelPosition = "inside" | "outside";
type Tooltip = "auto" | "on";
type Value = number[] | number;

interface Props extends Component {
  // Name is needed for formik fields
  // eslint-disable-next-line react-redux/no-unused-prop-types
  name?: string;
  value?: Value;
  min: number;
  max: number;
  tooltip?: Tooltip;
  labelPosition?: LabelPosition;
  minMaxLabels?: boolean;
  unit?: string;
  disabled?: boolean;
  onChange?: (value: Value) => void;
  onBlur?: () => void;
}

const RangeField = ({
  classNames = [],
  value,
  min,
  max,
  tooltip = "on",
  labelPosition = "inside",
  minMaxLabels = false,
  unit = "",
  disabled = false,
  onChange = noop,
  onBlur = noop
}: Props) => {
  const isRange = Array.isArray(value);
  const valueWithUnit = (val: number) => `${val}${unit ? ` ${unit}` : ""}`;

  const renderCustomHandle = (
    Handle: React.ReactElement,
    // Equivalent to unexported "RenderProps" in rc-slider/lib/Handles/index.d.ts
    {
      index,
      value,
      dragging
    }: {
      index: number;
      value: number;
      dragging: boolean;
    }
  ) =>
    React.cloneElement(Handle, {
      key: index,
      value,
      dragging: dragging.toString(),
      children: (
        <Balloon size="l" classNames={["RangeField__balloon"]} arrowPosition="bottom">
          {valueWithUnit(value)}
        </Balloon>
      )
    });

  return (
    <div
      className={cn(
        "RangeField",
        [
          {
            range: isRange,
            disabled,
            [`label-position-${labelPosition}`]: labelPosition,
            [`tooltip-${tooltip}`]: tooltip
          }
        ],
        classNames
      )}
    >
      <div className="RangeField__slider">
        <Slider
          range={isRange}
          min={Math.floor(min)}
          max={Math.ceil(max)}
          value={value}
          handleRender={!disabled ? renderCustomHandle : undefined}
          onChange={(value: number | number[]) => {
            onChange(value);
          }}
          onBlur={onBlur}
        />
      </div>

      <label className="RangeField__minLabel">
        {minMaxLabels && "min. "}
        {valueWithUnit(min)}
      </label>
      <label className="RangeField__maxLabel">
        {minMaxLabels && "max. "}
        {valueWithUnit(max)}
      </label>
    </div>
  );
};

export default RangeField;
