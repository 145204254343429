import { useMemo } from "react";

import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import { getEnv } from "../../../../commons/libs/env-service";
import { Env } from "../../../../commons/types/env";
import actions from "../../actions";
import useDispatch from "../hooks/use-dispatch";
import useQuery, { UseQueryOptions } from "../hooks/use-query";

const queryKey = ["env"] as const;

type Options = Omit<Partial<UseQueryOptions<Env>>, "queryKey" | "queryFn">;

const useEnv = (options: Options = {}) => {
  const dispatch = useDispatch();

  const query = useQuery({
    ...options,
    queryKey,
    queryFn: getEnv,
    onSuccess: env => {
      // TODO: BCD-7671 Replace `state.core.env` with `useEnv`
      dispatch(actions.core.envReceived(env));
      options.onSuccess?.(env);
    }
  });

  const env = query.data;

  const customerGroupConfig = useMemo(() => (env ? getCustomerGroupConfig(env.customerGroup) : undefined), [env]);

  return {
    ...query,
    customerGroupConfig
  };
};

export default useEnv;
