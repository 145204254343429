import { useCallback, useMemo } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { keyBy } from "lodash";

import { addBrand, addBrands, getActiveBrands, removeBrand } from "../../../../commons/libs/content-service";
import { Brand } from "../../../../commons/types/brand";
import useQuery, { UseQueryOptions } from "../hooks/use-query";

const activeBrandsQueryKey = ["activeBrands"] as const;
const addActiveBrandMutationKey = ["addActiveBrand"] as const;
const removeActiveBrandMutationKey = ["removeActiveBrand"] as const;
const addActiveBrandsMutationKey = ["addActiveBrands"] as const;

type BrandsQueryOptions = Omit<Partial<UseQueryOptions<Brand[]>>, "queryKey" | "queryFn">;
type BrandMutationOption = Omit<Partial<UseMutationOptions<Brand[], Error, Brand>>, "mutationFn">;
type BrandsMutationOption = Omit<Partial<UseMutationOptions<Brand[], Error, Brand[]>>, "mutationFn">;

export const useActiveBrands = (options: BrandsQueryOptions = {}) => {
  const query = useQuery({
    ...options,
    queryKey: activeBrandsQueryKey,
    queryFn: getActiveBrands,
    // Active Brands do not change during runtime (device restarts on changes),
    // but the cache can still be invalidated by mutations. Setting staleTime
    // to Infinity prevents unnecessary refetches.
    staleTime: Infinity
  });

  const data = useMemo(() => query.data ?? [], [query.data]);

  const activeBrandsMap = useMemo(() => keyBy(data, brand => brand.key), [data]);

  const activeBrandKeys = useMemo(() => data.map(brand => brand.key), [data]);

  const highlightedBrandKeys = useMemo(() => data.filter(brand => brand.highlighted).map(brand => brand.key), [data]);

  const getActiveBrand = useCallback(
    (brandKey: string) => data.find(activeBrand => activeBrand.key === brandKey),
    [data]
  );

  return {
    ...query,
    data,
    activeBrandsMap,
    activeBrandKeys,
    highlightedBrandKeys,
    getActiveBrand
  };
};

export const useAddActiveBrand = (options: BrandMutationOption = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...options,
    mutationKey: addActiveBrandMutationKey,
    mutationFn: (brand: Brand) => addBrand(brand),
    onSuccess: (activeBrands, brand, context) => {
      queryClient.invalidateQueries({ queryKey: activeBrandsQueryKey });
      options.onSuccess?.(activeBrands, brand, context);
    }
  });

  return mutation;
};

export const useRemoveActiveBrand = (options: BrandMutationOption = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...options,
    mutationKey: removeActiveBrandMutationKey,
    mutationFn: (brand: Brand) => removeBrand(brand.key),
    onSuccess: (activeBrands, brand, context) => {
      queryClient.invalidateQueries({ queryKey: activeBrandsQueryKey });
      options.onSuccess?.(activeBrands, brand, context);
    }
  });

  return mutation;
};

export const useAddActiveBrands = (options: BrandsMutationOption = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...options,
    mutationKey: addActiveBrandsMutationKey,
    mutationFn: (brands: Brand[]) => addBrands(brands),
    onSuccess: (activeBrands, brands, context) => {
      queryClient.invalidateQueries({ queryKey: activeBrandsQueryKey });
      options.onSuccess?.(activeBrands, brands, context);
    }
  });

  return mutation;
};
