import { changeLanguage as i18nChangeLanguage, use as i18nextUse, Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import { getLanguageFromLocale } from "../../../../commons/libs/locale";
import { getLocalizationWithFallback } from "../../../../commons/libs/localization";
import type { Locale } from "../../../../commons/types/localization";
import type { SharedConfig } from "../../config/config-types";

import type { Sentry } from "./sentry";

type I18nSharedConfig = SharedConfig["shared"]["i18n"];

interface I18nInitOptions extends I18nSharedConfig {
  debug?: boolean;
}

export const initI18n = (
  resources: Resource,
  { commonNamespace, defaultLocale, languages, debug }: I18nInitOptions,
  sentry?: Sentry
) =>
  i18nextUse(initReactI18next).init({
    resources,
    // Language config
    lng: defaultLocale,
    supportedLngs: languages,
    fallbackLng: getLanguageFromLocale(defaultLocale),
    // Namespace config
    ns: Object.keys(getLocalizationWithFallback(resources, defaultLocale) ?? {}),
    defaultNS: commonNamespace,
    fallbackNS: commonNamespace,
    // Misc config
    debug,
    interpolation: {
      escapeValue: false // React already prevents xss
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key) => {
      if (sentry) {
        sentry.captureException(new Error(`Missing translation in languages ${lngs.join(", ")}: ${ns}:${key}`));
      }
    }
  });

export const changeLanguage = (locale: Locale) => {
  i18nChangeLanguage(locale);
};
