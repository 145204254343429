import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  noCard?: boolean;
}

const DescriptionListCard = ({ classNames = [], children, noCard = false }: Props) => (
  <dl className={cn("DescriptionListCard", [{ noCard }], classNames)}>{children}</dl>
);

export default DescriptionListCard;
