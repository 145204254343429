import { useEffect, useState } from "react";

export enum Orientation {
  Portrait = "portrait",
  Landscape = "landscape"
}

const useOrientation = () => {
  const getOrientation = () =>
    Object.values(Orientation).find(orientation => window.matchMedia(`(orientation: ${orientation})`).matches);

  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    const updateOrientation = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener("resize", updateOrientation, false);
    window.addEventListener("orientationchange", updateOrientation, false);

    return () => {
      window.removeEventListener("resize", updateOrientation, false);
      window.removeEventListener("orientationchange", updateOrientation, false);
    };
  }, []);

  return orientation;
};

export default useOrientation;
