import { useEffect } from "react";
import { noop } from "lodash";

import {
  getManualAssortment as getManualAssortmentLib,
  getPackages as getPackagesLib
} from "../../../../commons/libs/content-service";
import actions from "../../actions";
import { useActiveBrands } from "../queries/use-active-brands";
import useAvailableBrands from "../queries/use-available-brands";
import useEnv from "../queries/use-env";
import useServiceAvailability from "../queries/use-service-availability";
import { useSettings } from "../queries/use-settings";

import useDispatch from "./use-dispatch";
import useQuery from "./use-query";

interface Arguments {
  onError?: (error: Error) => void;
}

const useInitCore = ({ onError = noop }: Arguments = {}) => {
  const dispatch = useDispatch();

  const serviceAvailability = useServiceAvailability({
    onError
  });

  const env = useEnv({
    enabled: serviceAvailability.isSuccess,
    onError
  });

  const settings = useSettings({
    enabled: env.query.isSuccess,
    onError
  });

  // BCD-7697 Replace `state.core.contentPackages` with `useContentPackages`
  const contentPackages = useQuery({
    queryKey: ["contentPackages"],
    queryFn: getPackagesLib,
    onSuccess: packages => {
      dispatch(actions.core.contentPackagesReceived(packages));
    },
    onError,
    enabled: env.query.isSuccess && settings.query.isSuccess
  });

  // BCD-7698 Replace `state.core.manualAssortment` with `useManualAssortment`
  const manualAssortment = useQuery({
    queryKey: ["manualAssortment"],
    queryFn: getManualAssortmentLib,
    onSuccess: manualAssortment => {
      dispatch(actions.core.manualAssortmentReceived(manualAssortment));
    },
    onError,
    enabled: env.query.isSuccess && settings.query.isSuccess
  });

  const availableBrands = useAvailableBrands({
    onError,
    enabled: env.query.isSuccess && settings.query.isSuccess
  });

  const activeBrands = useActiveBrands({
    onError,
    enabled: env.query.isSuccess && settings.query.isSuccess
  });

  const isInitCoreDone =
    env.query.isSuccess &&
    settings.query.isSuccess &&
    contentPackages.isSuccess &&
    manualAssortment.isSuccess &&
    availableBrands.query.isSuccess &&
    activeBrands.query.isSuccess;

  useEffect(() => {
    if (isInitCoreDone) {
      dispatch(actions.core.initCoreDone());
    }
  }, [isInitCoreDone, dispatch]);
};

export default useInitCore;
