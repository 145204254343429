/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Veloconnect-Proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

//#region Runtime
import FormData from "form-data";
import fetch, { Blob, RequestCredentials, RequestInit, Response } from "node-fetch";

export type FetchAPI = (url: string, init: RequestInit) => any;
/**
 * ISO Date string
 * @example 2023-02-28
 */
export type date = string;
/**
 * ISO Datetime string
 * @example 2023-02-29T12:00:00.000Z
 */
export type datetime = string;

export const BASE_PATH = "https://veloconnect.bike.center/api/v1".replace(/\/+$/, "");

export interface ConfigurationParameters {
  basePath?: string; // override base path
  fetchApi?: FetchAPI; // override for fetch implementation
  middleware?: Middleware[]; // middleware to apply before/after fetch requests
  queryParamsStringify?: (params: HTTPQuery) => string; // stringify function for query strings
  username?: string; // parameter for basic security
  password?: string; // parameter for basic security
  apiKey?: string | ((name: string) => string | Promise<string>); // parameter for apiKey security
  accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string | Promise<string>); // parameter for oauth2 security
  headers?: HTTPHeaders; //header params we want to use on every request
  credentials?: RequestCredentials; //value for the credentials param we want to use on each request
}

export class Configuration {
  constructor(private configuration: ConfigurationParameters = {}) {}

  set config(configuration: Configuration) {
    this.configuration = configuration;
  }

  get basePath(): string {
    return this.configuration.basePath != null ? this.configuration.basePath : BASE_PATH;
  }

  get fetchApi(): FetchAPI | undefined {
    return this.configuration.fetchApi;
  }

  get middleware(): Middleware[] {
    return this.configuration.middleware || [];
  }

  get queryParamsStringify(): (params: HTTPQuery) => string {
    return this.configuration.queryParamsStringify || querystring;
  }

  get username(): string | undefined {
    return this.configuration.username;
  }

  get password(): string | undefined {
    return this.configuration.password;
  }

  get apiKey(): ((name: string) => string | Promise<string>) | undefined {
    const apiKey = this.configuration.apiKey;
    if (apiKey) {
      return typeof apiKey === "function" ? apiKey : () => apiKey;
    }
    return undefined;
  }

  get accessToken(): ((name?: string, scopes?: string[]) => string | Promise<string>) | undefined {
    const accessToken = this.configuration.accessToken;
    if (accessToken) {
      return typeof accessToken === "function" ? accessToken : async () => accessToken;
    }
    return undefined;
  }

  get headers(): HTTPHeaders | undefined {
    return this.configuration.headers;
  }

  get credentials(): RequestCredentials | undefined {
    return this.configuration.credentials;
  }
}

export const DefaultConfig = new Configuration();

/**
 * This is the base class for all generated API classes.
 */
export class BaseAPI {
  private static readonly jsonRegex = /^(:?application\/json|[^;/ \t]+\/+\/[^;/ \t]+[+]json)[ \t]*(:?;.*)?$/i;
  private middleware: Middleware[];

  constructor(protected configuration = DefaultConfig) {
    this.middleware = configuration.middleware;
  }

  withMiddleware<T extends BaseAPI>(this: T, ...middlewares: Middleware[]) {
    const next = this.clone<T>();
    next.middleware = next.middleware.concat(...middlewares);
    return next;
  }

  withPreMiddleware<T extends BaseAPI>(this: T, ...preMiddlewares: Array<Middleware["pre"]>) {
    const middlewares = preMiddlewares.map(pre => ({ pre }));
    return this.withMiddleware<T>(...middlewares);
  }

  withPostMiddleware<T extends BaseAPI>(this: T, ...postMiddlewares: Array<Middleware["post"]>) {
    const middlewares = postMiddlewares.map(post => ({ post }));
    return this.withMiddleware<T>(...middlewares);
  }

  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  protected isJsonMime(mime: string | null | undefined): boolean {
    if (!mime) {
      return false;
    }
    return BaseAPI.jsonRegex.test(mime);
  }

  protected async request(context: RequestOpts, initOverrides?: RequestInit | InitOverrideFunction): Promise<Response> {
    const { url, init } = await this.createFetchParams(context, initOverrides);
    const response = await this.fetchApi(url, init);
    if (response && response.status >= 200 && response.status < 300) {
      return response;
    }
    throw new ResponseError(response, "Response returned an error code");
  }

  private async createFetchParams(context: RequestOpts, initOverrides?: RequestInit | InitOverrideFunction) {
    let url = this.configuration.basePath + context.path;
    if (context.query !== undefined && Object.keys(context.query).length !== 0) {
      // only add the querystring to the URL if there are query parameters.
      // this is done to avoid urls ending with a "?" character which buggy webservers
      // do not handle correctly sometimes.
      url += "?" + this.configuration.queryParamsStringify(context.query);
    }

    const headers = Object.assign({}, this.configuration.headers, context.headers);
    Object.keys(headers).forEach(key => (headers[key] === undefined ? delete headers[key] : {}));

    const initOverrideFn = typeof initOverrides === "function" ? initOverrides : async () => initOverrides;

    const initParams = {
      method: context.method,
      headers,
      body: context.body,
      credentials: this.configuration.credentials
    };

    const overriddenInit: RequestInit = {
      ...initParams,
      ...(await initOverrideFn({
        init: initParams,
        context
      }))
    };

    let body: any;
    if (
      isFormData(overriddenInit.body) ||
      overriddenInit.body instanceof URLSearchParams ||
      isBlob(overriddenInit.body)
    ) {
      body = overriddenInit.body;
    } else if (this.isJsonMime(headers["Content-Type"])) {
      body = JSON.stringify(overriddenInit.body);
    } else {
      body = overriddenInit.body;
    }

    const init: RequestInit = {
      ...overriddenInit,
      body
    };

    return { url, init };
  }

  private fetchApi = async (url: string, init: RequestInit) => {
    let fetchParams = { url, init };
    for (const middleware of this.middleware) {
      if (middleware.pre) {
        fetchParams =
          (await middleware.pre({
            fetch: this.fetchApi,
            ...fetchParams
          })) || fetchParams;
      }
    }
    let response: Response | undefined = undefined;
    try {
      response = await (this.configuration.fetchApi || fetch)(fetchParams.url, fetchParams.init);
    } catch (e) {
      for (const middleware of this.middleware) {
        if (middleware.onError) {
          response =
            (await middleware.onError({
              fetch: this.fetchApi,
              url: fetchParams.url,
              init: fetchParams.init,
              error: e,
              response: response ? response.clone() : undefined
            })) || response;
        }
      }
      if (response === undefined) {
        if (e instanceof Error) {
          throw new FetchError(e, "The request failed and the interceptors did not return an alternative response");
        } else {
          throw e;
        }
      }
    }
    for (const middleware of this.middleware) {
      if (middleware.post) {
        response =
          (await middleware.post({
            fetch: this.fetchApi,
            url: fetchParams.url,
            init: fetchParams.init,
            response: response?.clone()
          })) || response;
      }
    }
    return response;
  };

  /**
   * Create a shallow clone of `this` by constructing a new instance
   * and then shallow cloning data members.
   */
  private clone<T extends BaseAPI>(this: T): T {
    const constructor = this.constructor as any;
    const next = new constructor(this.configuration);
    next.middleware = this.middleware.slice();
    return next;
  }
}

function isBlob(value: any): value is Blob {
  return typeof Blob !== "undefined" && value instanceof Blob;
}

function isFormData(value: any): value is FormData {
  return typeof FormData !== "undefined" && value instanceof FormData;
}

export class ResponseError extends Error {
  override name = "ResponseError" as const;
  constructor(
    public response?: Response,
    msg?: string
  ) {
    super(msg);
  }
}

export class FetchError extends Error {
  override name = "FetchError" as const;
  constructor(
    public cause: Error,
    msg?: string
  ) {
    super(msg);
  }
}

export class RequiredError extends Error {
  override name = "RequiredError" as const;
  constructor(
    public field: string,
    msg?: string
  ) {
    super(msg);
  }
}

export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};

export type Json = any;
export type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "OPTIONS" | "HEAD";
export type HTTPHeaders = { [key: string]: string };
export type HTTPQuery = {
  [key: string]:
    | string
    | number
    | null
    | boolean
    | Array<string | number | null | boolean>
    | Set<string | number | null | boolean>
    | HTTPQuery;
};
export type HTTPBody = Json | FormData | URLSearchParams;
export type HTTPRequestInit = {
  headers?: HTTPHeaders;
  method: HTTPMethod;
  credentials?: RequestCredentials;
  body?: HTTPBody;
};
export type ModelPropertyNaming = "camelCase" | "snake_case" | "PascalCase" | "original";

export type InitOverrideFunction = (requestContext: {
  init: HTTPRequestInit;
  context: RequestOpts;
}) => Promise<RequestInit>;

export interface FetchParams {
  url: string;
  init: RequestInit;
}

export interface RequestOpts {
  path: string;
  method: HTTPMethod;
  headers: HTTPHeaders;
  query?: HTTPQuery;
  body?: HTTPBody;
}

export function exists(json: any, key: string) {
  const value = json[key];
  return value !== null && value !== undefined;
}

export function querystring(params: HTTPQuery, prefix: string = ""): string {
  return Object.keys(params)
    .map(key => querystringSingleKey(key, params[key], prefix))
    .filter(part => part.length > 0)
    .join("&");
}

function querystringSingleKey(
  key: string,
  value:
    | string
    | number
    | null
    | undefined
    | boolean
    | Array<string | number | null | boolean>
    | Set<string | number | null | boolean>
    | HTTPQuery,
  keyPrefix: string = ""
): string {
  const fullKey = keyPrefix + (keyPrefix.length ? `[${key}]` : key);
  if (value instanceof Array) {
    const multiValue = value
      .map(singleValue => encodeURIComponent(String(singleValue)))
      .join(`&${encodeURIComponent(fullKey)}=`);
    return `${encodeURIComponent(fullKey)}=${multiValue}`;
  }
  if (value instanceof Set) {
    const valueAsArray = Array.from(value);
    return querystringSingleKey(key, valueAsArray, keyPrefix);
  }
  if (value instanceof Date) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value.toISOString())}`;
  }
  if (value instanceof Object) {
    return querystring(value as HTTPQuery, fullKey);
  }
  return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
}

export function mapValues(data: any, fn: (item: any) => any) {
  return Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: fn(data[key]) }), {});
}

export function canConsumeForm(consumes: Consume[]): boolean {
  for (const consume of consumes) {
    if ("multipart/form-data" === consume.contentType) {
      return true;
    }
  }
  return false;
}

export interface Consume {
  contentType: string;
}

export interface RequestContext {
  fetch: FetchAPI;
  url: string;
  init: RequestInit;
}

export interface ResponseContext {
  fetch: FetchAPI;
  url: string;
  init: RequestInit;
  response: Response | undefined;
}

export interface ErrorContext {
  fetch: FetchAPI;
  url: string;
  init: RequestInit;
  error: unknown;
  response?: Response;
}

export interface Middleware {
  pre?(context: RequestContext): Promise<FetchParams | void>;
  post?(context: ResponseContext): Promise<Response | void>;
  onError?(context: ErrorContext): Promise<Response | void>;
}

export interface ApiResponse<T> {
  raw: Response;
  value(): Promise<T>;
}

export interface ResponseTransformer<T> {
  (json: any): T;
}

export class JSONApiResponse<T> {
  constructor(
    public raw: Response,
    private transformer: ResponseTransformer<T> = (jsonValue: any) => jsonValue
  ) {}

  async value(): Promise<T> {
    return this.transformer(await this.raw.json());
  }
}

export class VoidApiResponse {
  constructor(public raw: Response) {}

  async value(): Promise<void> {
    return undefined;
  }
}

export class BlobApiResponse {
  constructor(public raw: Response) {}

  async value(): Promise<Blob> {
    return await this.raw.blob();
  }
}

export class TextApiResponse {
  constructor(public raw: Response) {}

  async value(): Promise<string> {
    return await this.raw.text();
  }
}

//#endregion

//#region Models
//#region AdapterInformation
/**
 *
 * @export
 * @interface AdapterInformation
 */
export interface AdapterInformation {
  adapterKey?: string;
  lastJobId?: string;
  lastSuccessfulJobId?: string;
  lastImport?: datetime;
  lastSuccessfulImport?: datetime;
  /**
   * returns true when credentials are rejected on last import
   */
  areCredentialsInvalid?: boolean;
  isImportRunning?: boolean;
  /**
   * returns true when a import is queued for import and isImportRunning = false
   */
  isImportExpected?: boolean;
  nextImportExpected?: datetime;
  lastSuccessfulImportItemCountSuccessful?: number;
  lastSuccessfulImportItemCountExpected?: number;
  lastSuccessfulImportDurationMs?: number;
  /**
   * returns true if last successfully import is older than accepted tolerance
   */
  isImportOutdated?: boolean;
}

/**
 * Check if a given object implements the AdapterInformation interface.
 */
export function instanceOfAdapterInformation(value: object): boolean {
  return true;
}

export function AdapterInformationFromJSON(json: any): AdapterInformation {
  return AdapterInformationFromJSONTyped(json, false);
}

export function AdapterInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdapterInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    adapterKey: !exists(json, "adapterKey") ? undefined : json["adapterKey"],
    lastJobId: !exists(json, "lastJobId") ? undefined : json["lastJobId"],
    lastSuccessfulJobId: !exists(json, "lastSuccessfulJobId") ? undefined : json["lastSuccessfulJobId"],
    lastImport: !exists(json, "lastImport") ? undefined : json["lastImport"],
    lastSuccessfulImport: !exists(json, "lastSuccessfulImport") ? undefined : json["lastSuccessfulImport"],
    areCredentialsInvalid: !exists(json, "areCredentialsInvalid") ? undefined : json["areCredentialsInvalid"],
    isImportRunning: !exists(json, "isImportRunning") ? undefined : json["isImportRunning"],
    isImportExpected: !exists(json, "isImportExpected") ? undefined : json["isImportExpected"],
    nextImportExpected: !exists(json, "nextImportExpected") ? undefined : json["nextImportExpected"],
    lastSuccessfulImportItemCountSuccessful: !exists(json, "lastSuccessfulImportItemCountSuccessful")
      ? undefined
      : json["lastSuccessfulImportItemCountSuccessful"],
    lastSuccessfulImportItemCountExpected: !exists(json, "lastSuccessfulImportItemCountExpected")
      ? undefined
      : json["lastSuccessfulImportItemCountExpected"],
    lastSuccessfulImportDurationMs: !exists(json, "lastSuccessfulImportDurationMs")
      ? undefined
      : json["lastSuccessfulImportDurationMs"],
    isImportOutdated: !exists(json, "isImportOutdated") ? undefined : json["isImportOutdated"]
  };
}

export function AdapterInformationToJSON(value?: AdapterInformation | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    adapterKey: value.adapterKey,
    lastJobId: value.lastJobId,
    lastSuccessfulJobId: value.lastSuccessfulJobId,
    lastImport: value.lastImport === undefined ? undefined : value.lastImport,
    lastSuccessfulImport: value.lastSuccessfulImport === undefined ? undefined : value.lastSuccessfulImport,
    areCredentialsInvalid: value.areCredentialsInvalid,
    isImportRunning: value.isImportRunning,
    isImportExpected: value.isImportExpected,
    nextImportExpected: value.nextImportExpected === undefined ? undefined : value.nextImportExpected,
    lastSuccessfulImportItemCountSuccessful: value.lastSuccessfulImportItemCountSuccessful,
    lastSuccessfulImportItemCountExpected: value.lastSuccessfulImportItemCountExpected,
    lastSuccessfulImportDurationMs: value.lastSuccessfulImportDurationMs,
    isImportOutdated: value.isImportOutdated
  };
}

//#endregion
//#region AvailabilityResponse
/**
 *
 * @export
 * @interface AvailabilityResponse
 */
export interface AvailabilityResponse {
  data: Array<ProductAvailability>;
  count: number;
}

/**
 * Check if a given object implements the AvailabilityResponse interface.
 */
export function instanceOfAvailabilityResponse(value: object): boolean {
  return "data" in value && "count" in value;
}

export function AvailabilityResponseFromJSON(json: any): AvailabilityResponse {
  return AvailabilityResponseFromJSONTyped(json, false);
}

export function AvailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailabilityResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(ProductAvailabilityFromJSON),
    count: json["count"]
  };
}

export function AvailabilityResponseToJSON(value?: AvailabilityResponse | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    data: (value.data as Array<any>).map(ProductAvailabilityToJSON),
    count: value.count
  };
}

//#endregion
//#region AvailabilityStatus
/**
 *
 * @export
 * @enum {string}
 */
export enum AvailabilityStatus {
  AVAILABLE = "available",
  PARTIALLY_AVAILABLE = "partially_available",
  EXPECTING_DELIVERY = "expecting_delivery",
  NOT_AVAILABLE = "not_available"
}

export function instanceOfAvailabilityStatus(value: any): boolean {
  for (const key in AvailabilityStatus) {
    if (Object.prototype.hasOwnProperty.call(AvailabilityStatus, key)) {
      if (AvailabilityStatus[key as keyof typeof AvailabilityStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function AvailabilityStatusFromJSON(json: any): AvailabilityStatus {
  return AvailabilityStatusFromJSONTyped(json, false);
}

export function AvailabilityStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailabilityStatus {
  return json as AvailabilityStatus;
}

export function AvailabilityStatusToJSON(value?: AvailabilityStatus | null): any {
  return value as any;
}

//#endregion
//#region BrandStatus
/**
 *
 * @export
 * @interface BrandStatus
 */
export interface BrandStatus {
  brandKey: string;
  isPublic: boolean;
  isRestricted: boolean;
}

/**
 * Check if a given object implements the BrandStatus interface.
 */
export function instanceOfBrandStatus(value: object): boolean {
  return "brandKey" in value && "isPublic" in value && "isRestricted" in value;
}

export function BrandStatusFromJSON(json: any): BrandStatus {
  return BrandStatusFromJSONTyped(json, false);
}

export function BrandStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    brandKey: json["brandKey"],
    isPublic: json["isPublic"],
    isRestricted: json["isRestricted"]
  };
}

export function BrandStatusToJSON(value?: BrandStatus | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    brandKey: value.brandKey,
    isPublic: value.isPublic,
    isRestricted: value.isRestricted
  };
}

//#endregion
//#region BrandStatusList
/**
 *
 * @export
 * @interface BrandStatusList
 */
export interface BrandStatusList {
  data: Array<BrandStatus>;
}

/**
 * Check if a given object implements the BrandStatusList interface.
 */
export function instanceOfBrandStatusList(value: object): boolean {
  return "data" in value;
}

export function BrandStatusListFromJSON(json: any): BrandStatusList {
  return BrandStatusListFromJSONTyped(json, false);
}

export function BrandStatusListFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandStatusList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(BrandStatusFromJSON)
  };
}

export function BrandStatusListToJSON(value?: BrandStatusList | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    data: (value.data as Array<any>).map(BrandStatusToJSON)
  };
}

//#endregion
//#region Client
/**
 * entity for bike center. describes unique bike center with service tag. holds device configuration
 * @export
 * @interface Client
 */
export interface Client {
  serviceTag: string;
  apiKey?: string;
  creationTime?: datetime;
  lastInteractionTime?: datetime;
  brandKeys: Array<string>;
  enabledPrivateEndpoints: Array<string>;
  allowReregistration: boolean;
}

/**
 * Check if a given object implements the Client interface.
 */
export function instanceOfClient(value: object): boolean {
  return (
    "serviceTag" in value &&
    "brandKeys" in value &&
    "enabledPrivateEndpoints" in value &&
    "allowReregistration" in value
  );
}

export function ClientFromJSON(json: any): Client {
  return ClientFromJSONTyped(json, false);
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    serviceTag: json["serviceTag"],
    apiKey: !exists(json, "apiKey") ? undefined : json["apiKey"],
    creationTime: !exists(json, "creationTime") ? undefined : json["creationTime"],
    lastInteractionTime: !exists(json, "lastInteractionTime") ? undefined : json["lastInteractionTime"],
    brandKeys: json["brandKeys"],
    enabledPrivateEndpoints: json["enabledPrivateEndpoints"],
    allowReregistration: json["allowReregistration"]
  };
}

export function ClientToJSON(value?: Client | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    serviceTag: value.serviceTag,
    apiKey: value.apiKey,
    creationTime: value.creationTime === undefined ? undefined : value.creationTime,
    lastInteractionTime: value.lastInteractionTime === undefined ? undefined : value.lastInteractionTime,
    brandKeys: value.brandKeys,
    enabledPrivateEndpoints: value.enabledPrivateEndpoints,
    allowReregistration: value.allowReregistration
  };
}

//#endregion
//#region ClientConfig
/**
 *
 * @export
 * @interface ClientConfig
 */
export interface ClientConfig {
  endpointKey: string;
  adapterKeys: Array<string>;
  brandKeys: Array<string>;
  isConfigured: boolean;
  name: string;
  description: string;
  isPublic: boolean;
  isRestricted: boolean;
  isConfigurable: boolean;
  loginDetails?: string;
  credentials?: ConnectionCredentials;
  /**
   * @deprecated
   */
  lastJobId?: string;
  /**
   * @deprecated
   */
  lastSuccessfulJobId?: string;
  /**
   * @deprecated
   */
  lastImport?: datetime;
  /**
   * @deprecated
   */
  lastSuccessfulImport?: datetime;
  /**
   * returns true when credentials are rejected on any of the last imports
   */
  areCredentialsInvalid?: boolean;
  /**
   * returns true when any of the adapters run an import
   */
  isImportRunning?: boolean;
  /**
   * returns true when any import is queued for import and isImportRunning = false
   */
  isImportExpected?: boolean;
  nextImportExpected?: datetime;
  /**
   * @deprecated
   */
  lastSuccessfulImportItemCountSuccessful?: number;
  /**
   * @deprecated
   */
  lastSuccessfulImportItemCountExpected?: number;
  /**
   * @deprecated
   */
  lastSuccessfulImportDurationMs?: number;
  adapters?: Array<AdapterInformation>;
}

/**
 * Check if a given object implements the ClientConfig interface.
 */
export function instanceOfClientConfig(value: object): boolean {
  return (
    "endpointKey" in value &&
    "adapterKeys" in value &&
    "brandKeys" in value &&
    "isConfigured" in value &&
    "name" in value &&
    "description" in value &&
    "isPublic" in value &&
    "isRestricted" in value &&
    "isConfigurable" in value
  );
}

export function ClientConfigFromJSON(json: any): ClientConfig {
  return ClientConfigFromJSONTyped(json, false);
}

export function ClientConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientConfig {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    endpointKey: json["endpointKey"],
    adapterKeys: json["adapterKeys"],
    brandKeys: json["brandKeys"],
    isConfigured: json["isConfigured"],
    name: json["name"],
    description: json["description"],
    isPublic: json["isPublic"],
    isRestricted: json["isRestricted"],
    isConfigurable: json["isConfigurable"],
    loginDetails: !exists(json, "loginDetails") ? undefined : json["loginDetails"],
    credentials: !exists(json, "credentials") ? undefined : ConnectionCredentialsFromJSON(json["credentials"]),
    lastJobId: !exists(json, "lastJobId") ? undefined : json["lastJobId"],
    lastSuccessfulJobId: !exists(json, "lastSuccessfulJobId") ? undefined : json["lastSuccessfulJobId"],
    lastImport: !exists(json, "lastImport") ? undefined : json["lastImport"],
    lastSuccessfulImport: !exists(json, "lastSuccessfulImport") ? undefined : json["lastSuccessfulImport"],
    areCredentialsInvalid: !exists(json, "areCredentialsInvalid") ? undefined : json["areCredentialsInvalid"],
    isImportRunning: !exists(json, "isImportRunning") ? undefined : json["isImportRunning"],
    isImportExpected: !exists(json, "isImportExpected") ? undefined : json["isImportExpected"],
    nextImportExpected: !exists(json, "nextImportExpected") ? undefined : json["nextImportExpected"],
    lastSuccessfulImportItemCountSuccessful: !exists(json, "lastSuccessfulImportItemCountSuccessful")
      ? undefined
      : json["lastSuccessfulImportItemCountSuccessful"],
    lastSuccessfulImportItemCountExpected: !exists(json, "lastSuccessfulImportItemCountExpected")
      ? undefined
      : json["lastSuccessfulImportItemCountExpected"],
    lastSuccessfulImportDurationMs: !exists(json, "lastSuccessfulImportDurationMs")
      ? undefined
      : json["lastSuccessfulImportDurationMs"],
    adapters: !exists(json, "adapters") ? undefined : (json["adapters"] as Array<any>).map(AdapterInformationFromJSON)
  };
}

export function ClientConfigToJSON(value?: ClientConfig | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    endpointKey: value.endpointKey,
    adapterKeys: value.adapterKeys,
    brandKeys: value.brandKeys,
    isConfigured: value.isConfigured,
    name: value.name,
    description: value.description,
    isPublic: value.isPublic,
    isRestricted: value.isRestricted,
    isConfigurable: value.isConfigurable,
    loginDetails: value.loginDetails,
    credentials: ConnectionCredentialsToJSON(value.credentials),
    lastJobId: value.lastJobId,
    lastSuccessfulJobId: value.lastSuccessfulJobId,
    lastImport: value.lastImport === undefined ? undefined : value.lastImport,
    lastSuccessfulImport: value.lastSuccessfulImport === undefined ? undefined : value.lastSuccessfulImport,
    areCredentialsInvalid: value.areCredentialsInvalid,
    isImportRunning: value.isImportRunning,
    isImportExpected: value.isImportExpected,
    nextImportExpected: value.nextImportExpected === undefined ? undefined : value.nextImportExpected,
    lastSuccessfulImportItemCountSuccessful: value.lastSuccessfulImportItemCountSuccessful,
    lastSuccessfulImportItemCountExpected: value.lastSuccessfulImportItemCountExpected,
    lastSuccessfulImportDurationMs: value.lastSuccessfulImportDurationMs,
    adapters: value.adapters === undefined ? undefined : (value.adapters as Array<any>).map(AdapterInformationToJSON)
  };
}

//#endregion
//#region ClientList
/**
 *
 * @export
 * @interface ClientList
 */
export interface ClientList {
  data: Array<Client>;
  meta: ListMeta;
}

/**
 * Check if a given object implements the ClientList interface.
 */
export function instanceOfClientList(value: object): boolean {
  return "data" in value && "meta" in value;
}

export function ClientListFromJSON(json: any): ClientList {
  return ClientListFromJSONTyped(json, false);
}

export function ClientListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(ClientFromJSON),
    meta: ListMetaFromJSON(json["meta"])
  };
}

export function ClientListToJSON(value?: ClientList | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    data: (value.data as Array<any>).map(ClientToJSON),
    meta: ListMetaToJSON(value.meta)
  };
}

//#endregion
//#region ConnectionCredentials
/**
 *
 * @export
 * @interface ConnectionCredentials
 */
export interface ConnectionCredentials {
  buyersId: string;
  password: string;
}

/**
 * Check if a given object implements the ConnectionCredentials interface.
 */
export function instanceOfConnectionCredentials(value: object): boolean {
  return "buyersId" in value && "password" in value;
}

export function ConnectionCredentialsFromJSON(json: any): ConnectionCredentials {
  return ConnectionCredentialsFromJSONTyped(json, false);
}

export function ConnectionCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionCredentials {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    buyersId: json["buyersId"],
    password: json["password"]
  };
}

export function ConnectionCredentialsToJSON(value?: ConnectionCredentials | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    buyersId: value.buyersId,
    password: value.password
  };
}

//#endregion
//#region ContentProduct
/**
 *
 * @export
 * @interface ContentProduct
 */
export interface ContentProduct {
  id: string;
  productId: string;
  variantId: string;
  productNumber?: string;
  gtin?: string;
  brandKey: string;
  updatedAt: datetime;
  modelYear: number;
}

/**
 * Check if a given object implements the ContentProduct interface.
 */
export function instanceOfContentProduct(value: object): boolean {
  return (
    "id" in value &&
    "productId" in value &&
    "variantId" in value &&
    "brandKey" in value &&
    "updatedAt" in value &&
    "modelYear" in value
  );
}

export function ContentProductFromJSON(json: any): ContentProduct {
  return ContentProductFromJSONTyped(json, false);
}

export function ContentProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentProduct {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    productId: json["productId"],
    variantId: json["variantId"],
    productNumber: !exists(json, "productNumber") ? undefined : json["productNumber"],
    gtin: !exists(json, "gtin") ? undefined : json["gtin"],
    brandKey: json["brandKey"],
    updatedAt: json["updatedAt"],
    modelYear: json["modelYear"]
  };
}

export function ContentProductToJSON(value?: ContentProduct | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    id: value.id,
    productId: value.productId,
    variantId: value.variantId,
    productNumber: value.productNumber,
    gtin: value.gtin,
    brandKey: value.brandKey,
    updatedAt: value.updatedAt,
    modelYear: value.modelYear
  };
}

//#endregion
//#region ContentProductIdentity
/**
 *
 * @export
 * @interface ContentProductIdentity
 */
export interface ContentProductIdentity {
  productId: string;
  variantId: string;
}

/**
 * Check if a given object implements the ContentProductIdentity interface.
 */
export function instanceOfContentProductIdentity(value: object): boolean {
  return "productId" in value && "variantId" in value;
}

export function ContentProductIdentityFromJSON(json: any): ContentProductIdentity {
  return ContentProductIdentityFromJSONTyped(json, false);
}

export function ContentProductIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentProductIdentity {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productId: json["productId"],
    variantId: json["variantId"]
  };
}

export function ContentProductIdentityToJSON(value?: ContentProductIdentity | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    productId: value.productId,
    variantId: value.variantId
  };
}

//#endregion
//#region ContentProductList
/**
 *
 * @export
 * @interface ContentProductList
 */
export interface ContentProductList {
  data: Array<ContentProduct>;
  meta: ListMeta;
}

/**
 * Check if a given object implements the ContentProductList interface.
 */
export function instanceOfContentProductList(value: object): boolean {
  return "data" in value && "meta" in value;
}

export function ContentProductListFromJSON(json: any): ContentProductList {
  return ContentProductListFromJSONTyped(json, false);
}

export function ContentProductListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentProductList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(ContentProductFromJSON),
    meta: ListMetaFromJSON(json["meta"])
  };
}

export function ContentProductListToJSON(value?: ContentProductList | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    data: (value.data as Array<any>).map(ContentProductToJSON),
    meta: ListMetaToJSON(value.meta)
  };
}

//#endregion
//#region CreateClient
/**
 *
 * @export
 * @interface CreateClient
 */
export interface CreateClient {
  serviceTag: string;
}

/**
 * Check if a given object implements the CreateClient interface.
 */
export function instanceOfCreateClient(value: object): boolean {
  return "serviceTag" in value;
}

export function CreateClientFromJSON(json: any): CreateClient {
  return CreateClientFromJSONTyped(json, false);
}

export function CreateClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    serviceTag: json["serviceTag"]
  };
}

export function CreateClientToJSON(value?: CreateClient | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    serviceTag: value.serviceTag
  };
}

//#endregion
//#region CreateUpstreamConnection
/**
 * credentials for veloconnect upstream server. a set of credentials can be used by multiple clients in the same location
 * @export
 * @interface CreateUpstreamConnection
 */
export interface CreateUpstreamConnection {
  /**
   * identifier for target veloconnect server
   */
  endpointKey: string;
  /**
   * user identification / username for veloconnect server
   */
  buyersId: string;
  /**
   * password for veloconnect server
   */
  password: string;
  /**
   * when this is set to true we do not verify if the provided credentials are correct
   */
  doNotValidateCredentials?: boolean;
}

/**
 * Check if a given object implements the CreateUpstreamConnection interface.
 */
export function instanceOfCreateUpstreamConnection(value: object): boolean {
  return "endpointKey" in value && "buyersId" in value && "password" in value;
}

export function CreateUpstreamConnectionFromJSON(json: any): CreateUpstreamConnection {
  return CreateUpstreamConnectionFromJSONTyped(json, false);
}

export function CreateUpstreamConnectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateUpstreamConnection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    endpointKey: json["endpointKey"],
    buyersId: json["buyersId"],
    password: json["password"],
    doNotValidateCredentials: !exists(json, "doNotValidateCredentials") ? undefined : json["doNotValidateCredentials"]
  };
}

export function CreateUpstreamConnectionToJSON(value?: CreateUpstreamConnection | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    endpointKey: value.endpointKey,
    buyersId: value.buyersId,
    password: value.password,
    doNotValidateCredentials: value.doNotValidateCredentials
  };
}

//#endregion
//#region DebugResponse
/**
 *
 * @export
 * @interface DebugResponse
 */
export interface DebugResponse {
  code: number;
  message: string;
  event?: string;
  details?: { [key: string]: string };
}

/**
 * Check if a given object implements the DebugResponse interface.
 */
export function instanceOfDebugResponse(value: object): boolean {
  return "code" in value && "message" in value;
}

export function DebugResponseFromJSON(json: any): DebugResponse {
  return DebugResponseFromJSONTyped(json, false);
}

export function DebugResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DebugResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    message: json["message"],
    event: !exists(json, "event") ? undefined : json["event"],
    details: !exists(json, "details") ? undefined : json["details"]
  };
}

export function DebugResponseToJSON(value?: DebugResponse | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    code: value.code,
    message: value.message,
    event: value.event,
    details: value.details
  };
}

//#endregion
//#region Job
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  id: string;
  connectionId: string;
  endpointKey: string;
  adapterKey: string;
  status: JobStatus;
  retries: number;
  errorLog?: string;
  pauseUntil: datetime;
  createdAt: datetime;
  startedAt?: datetime;
  finishedAt?: datetime;
  /**
   * amount of successfully imported items
   */
  itemCountSuccessful?: number;
  /**
   * amount of expected items
   */
  itemCountExpected?: number;
}

/**
 * Check if a given object implements the Job interface.
 */
export function instanceOfJob(value: object): boolean {
  return (
    "id" in value &&
    "connectionId" in value &&
    "endpointKey" in value &&
    "adapterKey" in value &&
    "status" in value &&
    "retries" in value &&
    "pauseUntil" in value &&
    "createdAt" in value
  );
}

export function JobFromJSON(json: any): Job {
  return JobFromJSONTyped(json, false);
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    connectionId: json["connectionId"],
    endpointKey: json["endpointKey"],
    adapterKey: json["adapterKey"],
    status: JobStatusFromJSON(json["status"]),
    retries: json["retries"],
    errorLog: !exists(json, "errorLog") ? undefined : json["errorLog"],
    pauseUntil: json["pauseUntil"],
    createdAt: json["createdAt"],
    startedAt: !exists(json, "startedAt") ? undefined : json["startedAt"],
    finishedAt: !exists(json, "finishedAt") ? undefined : json["finishedAt"],
    itemCountSuccessful: !exists(json, "itemCountSuccessful") ? undefined : json["itemCountSuccessful"],
    itemCountExpected: !exists(json, "itemCountExpected") ? undefined : json["itemCountExpected"]
  };
}

export function JobToJSON(value?: Job | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    id: value.id,
    connectionId: value.connectionId,
    endpointKey: value.endpointKey,
    adapterKey: value.adapterKey,
    status: JobStatusToJSON(value.status),
    retries: value.retries,
    errorLog: value.errorLog,
    pauseUntil: value.pauseUntil,
    createdAt: value.createdAt,
    startedAt: value.startedAt === undefined ? undefined : value.startedAt,
    finishedAt: value.finishedAt === undefined ? undefined : value.finishedAt,
    itemCountSuccessful: value.itemCountSuccessful,
    itemCountExpected: value.itemCountExpected
  };
}

//#endregion
//#region JobList
/**
 *
 * @export
 * @interface JobList
 */
export interface JobList {
  data: Array<Job>;
  meta: ListMeta;
}

/**
 * Check if a given object implements the JobList interface.
 */
export function instanceOfJobList(value: object): boolean {
  return "data" in value && "meta" in value;
}

export function JobListFromJSON(json: any): JobList {
  return JobListFromJSONTyped(json, false);
}

export function JobListFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(JobFromJSON),
    meta: ListMetaFromJSON(json["meta"])
  };
}

export function JobListToJSON(value?: JobList | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    data: (value.data as Array<any>).map(JobToJSON),
    meta: ListMetaToJSON(value.meta)
  };
}

//#endregion
//#region JobStatus
/**
 *
 * @export
 * @enum {string}
 */
export enum JobStatus {
  PENDING = "pending",
  WORKING = "working",
  DONE = "done",
  FAILED = "failed",
  INVALID_CREDENTIALS = "invalid_credentials"
}

export function instanceOfJobStatus(value: any): boolean {
  for (const key in JobStatus) {
    if (Object.prototype.hasOwnProperty.call(JobStatus, key)) {
      if (JobStatus[key as keyof typeof JobStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function JobStatusFromJSON(json: any): JobStatus {
  return JobStatusFromJSONTyped(json, false);
}

export function JobStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatus {
  return json as JobStatus;
}

export function JobStatusToJSON(value?: JobStatus | null): any {
  return value as any;
}

//#endregion
//#region ListMeta
/**
 *
 * @export
 * @interface ListMeta
 */
export interface ListMeta {
  /**
   * total amount of items matching the request
   */
  count?: number;
  /**
   * requested page number. starts at 0
   */
  page?: number;
  /**
   * page limit
   */
  pageSize?: number;
}

/**
 * Check if a given object implements the ListMeta interface.
 */
export function instanceOfListMeta(value: object): boolean {
  return true;
}

export function ListMetaFromJSON(json: any): ListMeta {
  return ListMetaFromJSONTyped(json, false);
}

export function ListMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    count: !exists(json, "count") ? undefined : json["count"],
    page: !exists(json, "page") ? undefined : json["page"],
    pageSize: !exists(json, "pageSize") ? undefined : json["pageSize"]
  };
}

export function ListMetaToJSON(value?: ListMeta | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    count: value.count,
    page: value.page,
    pageSize: value.pageSize
  };
}

//#endregion
//#region ProductAvailability
/**
 *
 * @export
 * @interface ProductAvailability
 */
export interface ProductAvailability {
  productId: string;
  variantId: string;
  productFound?: boolean;
  availabilities: Array<ProductEndpointAvailability>;
}

/**
 * Check if a given object implements the ProductAvailability interface.
 */
export function instanceOfProductAvailability(value: object): boolean {
  return "productId" in value && "variantId" in value && "availabilities" in value;
}

export function ProductAvailabilityFromJSON(json: any): ProductAvailability {
  return ProductAvailabilityFromJSONTyped(json, false);
}

export function ProductAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductAvailability {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productId: json["productId"],
    variantId: json["variantId"],
    productFound: !exists(json, "productFound") ? undefined : json["productFound"],
    availabilities: (json["availabilities"] as Array<any>).map(ProductEndpointAvailabilityFromJSON)
  };
}

export function ProductAvailabilityToJSON(value?: ProductAvailability | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    productId: value.productId,
    variantId: value.variantId,
    productFound: value.productFound,
    availabilities: (value.availabilities as Array<any>).map(ProductEndpointAvailabilityToJSON)
  };
}

//#endregion
//#region ProductEndpointAvailability
/**
 *
 * @export
 * @interface ProductEndpointAvailability
 */
export interface ProductEndpointAvailability {
  status: UpstreamItemStatus;
  description?: string;
  /**
   * amount in lowest unit of currency. eg cent
   */
  recommendedRetailPriceAmount?: number;
  recommendedRetailPriceCurrency?: string;
  productImageUrl?: string;
  /**
   * adapter with the highest value is the most important adapter. response is not sorted
   */
  priority: number;
  updateTime: datetime;
  endpointKey: string;
  adapterKey: string;
  expectedDeliveryDate?: date;
  itemInformation?: UpstreamItemInformation;
}

/**
 * Check if a given object implements the ProductEndpointAvailability interface.
 */
export function instanceOfProductEndpointAvailability(value: object): boolean {
  return (
    "status" in value && "priority" in value && "updateTime" in value && "endpointKey" in value && "adapterKey" in value
  );
}

export function ProductEndpointAvailabilityFromJSON(json: any): ProductEndpointAvailability {
  return ProductEndpointAvailabilityFromJSONTyped(json, false);
}

export function ProductEndpointAvailabilityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductEndpointAvailability {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: UpstreamItemStatusFromJSON(json["status"]),
    description: !exists(json, "description") ? undefined : json["description"],
    recommendedRetailPriceAmount: !exists(json, "recommendedRetailPriceAmount")
      ? undefined
      : json["recommendedRetailPriceAmount"],
    recommendedRetailPriceCurrency: !exists(json, "recommendedRetailPriceCurrency")
      ? undefined
      : json["recommendedRetailPriceCurrency"],
    productImageUrl: !exists(json, "productImageUrl") ? undefined : json["productImageUrl"],
    priority: json["priority"],
    updateTime: json["updateTime"],
    endpointKey: json["endpointKey"],
    adapterKey: json["adapterKey"],
    expectedDeliveryDate: !exists(json, "expectedDeliveryDate") ? undefined : json["expectedDeliveryDate"],
    itemInformation: !exists(json, "itemInformation")
      ? undefined
      : UpstreamItemInformationFromJSON(json["itemInformation"])
  };
}

export function ProductEndpointAvailabilityToJSON(value?: ProductEndpointAvailability | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    status: UpstreamItemStatusToJSON(value.status),
    description: value.description,
    recommendedRetailPriceAmount: value.recommendedRetailPriceAmount,
    recommendedRetailPriceCurrency: value.recommendedRetailPriceCurrency,
    productImageUrl: value.productImageUrl,
    priority: value.priority,
    updateTime: value.updateTime,
    endpointKey: value.endpointKey,
    adapterKey: value.adapterKey,
    expectedDeliveryDate: value.expectedDeliveryDate === undefined ? undefined : value.expectedDeliveryDate,
    itemInformation: UpstreamItemInformationToJSON(value.itemInformation)
  };
}

//#endregion
//#region Status
/**
 *
 * @export
 * @interface Status
 */
export interface Status {
  code: number;
  message: string;
  event?: string;
  details?: { [key: string]: string };
}

/**
 * Check if a given object implements the Status interface.
 */
export function instanceOfStatus(value: object): boolean {
  return "code" in value && "message" in value;
}

export function StatusFromJSON(json: any): Status {
  return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    message: json["message"],
    event: !exists(json, "event") ? undefined : json["event"],
    details: !exists(json, "details") ? undefined : json["details"]
  };
}

export function StatusToJSON(value?: Status | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    code: value.code,
    message: value.message,
    event: value.event,
    details: value.details
  };
}

//#endregion
//#region UpdateClient
/**
 *
 * @export
 * @interface UpdateClient
 */
export interface UpdateClient {
  enabledPrivateEndpoints?: Array<string>;
  allowReregistration?: boolean;
}

/**
 * Check if a given object implements the UpdateClient interface.
 */
export function instanceOfUpdateClient(value: object): boolean {
  return true;
}

export function UpdateClientFromJSON(json: any): UpdateClient {
  return UpdateClientFromJSONTyped(json, false);
}

export function UpdateClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    enabledPrivateEndpoints: !exists(json, "enabledPrivateEndpoints") ? undefined : json["enabledPrivateEndpoints"],
    allowReregistration: !exists(json, "allowReregistration") ? undefined : json["allowReregistration"]
  };
}

export function UpdateClientToJSON(value?: UpdateClient | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    enabledPrivateEndpoints: value.enabledPrivateEndpoints,
    allowReregistration: value.allowReregistration
  };
}

//#endregion
//#region UpdateUpstreamConnection
/**
 * credentials for veloconnect upstream server. a set of credentials can be used by multiple clients in the same location
 * @export
 * @interface UpdateUpstreamConnection
 */
export interface UpdateUpstreamConnection {
  buyersId: string;
  password: string;
}

/**
 * Check if a given object implements the UpdateUpstreamConnection interface.
 */
export function instanceOfUpdateUpstreamConnection(value: object): boolean {
  return "buyersId" in value && "password" in value;
}

export function UpdateUpstreamConnectionFromJSON(json: any): UpdateUpstreamConnection {
  return UpdateUpstreamConnectionFromJSONTyped(json, false);
}

export function UpdateUpstreamConnectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateUpstreamConnection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    buyersId: json["buyersId"],
    password: json["password"]
  };
}

export function UpdateUpstreamConnectionToJSON(value?: UpdateUpstreamConnection | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    buyersId: value.buyersId,
    password: value.password
  };
}

//#endregion
//#region UpstreamItem
/**
 *
 * @export
 * @interface UpstreamItem
 */
export interface UpstreamItem {
  adapterKey: string;
  sellersItemIdentification: string;
  manufacturersItemIdentification?: string;
  manufacturersItemIdentificationIssuerParty?: string;
  upstreamConnectionId: string;
  contentProductId?: string;
  availabilityStatus: UpstreamItemStatus;
  updatedAt: datetime;
  partyName?: string;
  description?: string;
  standardItemIdentification?: string;
  identificationSchemeId?: string;
  basePriceAmount?: number;
  basePriceCurrency?: string;
  recommendedRetailPriceAmount?: number;
  recommendedRetailPriceCurrency?: string;
  itemInformation?: UpstreamItemInformation;
  classificationGroupMember?: { [key: string]: any };
  productImageUrl?: string;
  expectedDeliveryDate?: date;
}

/**
 * Check if a given object implements the UpstreamItem interface.
 */
export function instanceOfUpstreamItem(value: object): boolean {
  return (
    "adapterKey" in value &&
    "sellersItemIdentification" in value &&
    "upstreamConnectionId" in value &&
    "availabilityStatus" in value &&
    "updatedAt" in value
  );
}

export function UpstreamItemFromJSON(json: any): UpstreamItem {
  return UpstreamItemFromJSONTyped(json, false);
}

export function UpstreamItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpstreamItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    adapterKey: json["adapterKey"],
    sellersItemIdentification: json["sellersItemIdentification"],
    manufacturersItemIdentification: !exists(json, "manufacturersItemIdentification")
      ? undefined
      : json["manufacturersItemIdentification"],
    manufacturersItemIdentificationIssuerParty: !exists(json, "manufacturersItemIdentificationIssuerParty")
      ? undefined
      : json["manufacturersItemIdentificationIssuerParty"],
    upstreamConnectionId: json["upstreamConnectionId"],
    contentProductId: !exists(json, "contentProductId") ? undefined : json["contentProductId"],
    availabilityStatus: UpstreamItemStatusFromJSON(json["availabilityStatus"]),
    updatedAt: json["updatedAt"],
    partyName: !exists(json, "partyName") ? undefined : json["partyName"],
    description: !exists(json, "description") ? undefined : json["description"],
    standardItemIdentification: !exists(json, "standardItemIdentification")
      ? undefined
      : json["standardItemIdentification"],
    identificationSchemeId: !exists(json, "identificationSchemeId") ? undefined : json["identificationSchemeId"],
    basePriceAmount: !exists(json, "basePriceAmount") ? undefined : json["basePriceAmount"],
    basePriceCurrency: !exists(json, "basePriceCurrency") ? undefined : json["basePriceCurrency"],
    recommendedRetailPriceAmount: !exists(json, "recommendedRetailPriceAmount")
      ? undefined
      : json["recommendedRetailPriceAmount"],
    recommendedRetailPriceCurrency: !exists(json, "recommendedRetailPriceCurrency")
      ? undefined
      : json["recommendedRetailPriceCurrency"],
    itemInformation: !exists(json, "itemInformation")
      ? undefined
      : UpstreamItemInformationFromJSON(json["itemInformation"]),
    classificationGroupMember: !exists(json, "classificationGroupMember")
      ? undefined
      : json["classificationGroupMember"],
    productImageUrl: !exists(json, "productImageUrl") ? undefined : json["productImageUrl"],
    expectedDeliveryDate: !exists(json, "expectedDeliveryDate") ? undefined : json["expectedDeliveryDate"]
  };
}

export function UpstreamItemToJSON(value?: UpstreamItem | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    adapterKey: value.adapterKey,
    sellersItemIdentification: value.sellersItemIdentification,
    manufacturersItemIdentification: value.manufacturersItemIdentification,
    manufacturersItemIdentificationIssuerParty: value.manufacturersItemIdentificationIssuerParty,
    upstreamConnectionId: value.upstreamConnectionId,
    contentProductId: value.contentProductId,
    availabilityStatus: UpstreamItemStatusToJSON(value.availabilityStatus),
    updatedAt: value.updatedAt,
    partyName: value.partyName,
    description: value.description,
    standardItemIdentification: value.standardItemIdentification,
    identificationSchemeId: value.identificationSchemeId,
    basePriceAmount: value.basePriceAmount,
    basePriceCurrency: value.basePriceCurrency,
    recommendedRetailPriceAmount: value.recommendedRetailPriceAmount,
    recommendedRetailPriceCurrency: value.recommendedRetailPriceCurrency,
    itemInformation: UpstreamItemInformationToJSON(value.itemInformation),
    classificationGroupMember: value.classificationGroupMember,
    productImageUrl: value.productImageUrl,
    expectedDeliveryDate: value.expectedDeliveryDate === undefined ? undefined : value.expectedDeliveryDate
  };
}

//#endregion
//#region UpstreamItemInformation
/**
 *
 * @export
 * @interface UpstreamItemInformation
 */
export interface UpstreamItemInformation {
  [key: string]: any | any;
  /**
   * Amount of items ordered
   */
  orderedCount?: number;
  /**
   * Personalization Deep Link for bikecenter variant view
   */
  bikeCenterDeepLink?: string;
  orbeaConnectOrder?: { [key: string]: any };
}

/**
 * Check if a given object implements the UpstreamItemInformation interface.
 */
export function instanceOfUpstreamItemInformation(value: object): boolean {
  return true;
}

export function UpstreamItemInformationFromJSON(json: any): UpstreamItemInformation {
  return UpstreamItemInformationFromJSONTyped(json, false);
}

export function UpstreamItemInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpstreamItemInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...json,
    orderedCount: !exists(json, "orderedCount") ? undefined : json["orderedCount"],
    bikeCenterDeepLink: !exists(json, "bikeCenterDeepLink") ? undefined : json["bikeCenterDeepLink"],
    orbeaConnectOrder: !exists(json, "orbeaConnectOrder") ? undefined : json["orbeaConnectOrder"]
  };
}

export function UpstreamItemInformationToJSON(value?: UpstreamItemInformation | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    ...value,
    orderedCount: value.orderedCount,
    bikeCenterDeepLink: value.bikeCenterDeepLink,
    orbeaConnectOrder: value.orbeaConnectOrder
  };
}

//#endregion
//#region UpstreamItemList
/**
 *
 * @export
 * @interface UpstreamItemList
 */
export interface UpstreamItemList {
  data: Array<UpstreamItem>;
}

/**
 * Check if a given object implements the UpstreamItemList interface.
 */
export function instanceOfUpstreamItemList(value: object): boolean {
  return "data" in value;
}

export function UpstreamItemListFromJSON(json: any): UpstreamItemList {
  return UpstreamItemListFromJSONTyped(json, false);
}

export function UpstreamItemListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpstreamItemList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(UpstreamItemFromJSON)
  };
}

export function UpstreamItemListToJSON(value?: UpstreamItemList | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    data: (value.data as Array<any>).map(UpstreamItemToJSON)
  };
}

//#endregion
//#region UpstreamItemStatus
/**
 * available, partially_available, expecting_delivery, not_available are used according to the veloconnect specification
 * not_found will be used if the upstream server has returned that it can not find the provided sellersItemIdentification
 *
 * @export
 * @enum {string}
 */
export enum UpstreamItemStatus {
  AVAILABLE = "available",
  PARTIALLY_AVAILABLE = "partially_available",
  EXPECTING_DELIVERY = "expecting_delivery",
  NOT_AVAILABLE = "not_available",
  NOT_FOUND = "not_found"
}

export function instanceOfUpstreamItemStatus(value: any): boolean {
  for (const key in UpstreamItemStatus) {
    if (Object.prototype.hasOwnProperty.call(UpstreamItemStatus, key)) {
      if (UpstreamItemStatus[key as keyof typeof UpstreamItemStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function UpstreamItemStatusFromJSON(json: any): UpstreamItemStatus {
  return UpstreamItemStatusFromJSONTyped(json, false);
}

export function UpstreamItemStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpstreamItemStatus {
  return json as UpstreamItemStatus;
}

export function UpstreamItemStatusToJSON(value?: UpstreamItemStatus | null): any {
  return value as any;
}

//#endregion
//#endregion

//#region AuthenticationApi

export interface CreateClientRequest {
  CreateClient: CreateClient;
}

export interface DeleteClientRequest {
  serviceTag: string;
  resetApiKey?: boolean;
}

export interface GetClientRequest {
  serviceTag: string;
}

export interface GetClientsRequest {
  page: number;
  pageSize: number;
}

export interface UpdateClientRequest {
  serviceTag: string;
  UpdateClient: UpdateClient;
}

/**
 *
 */
export class AuthenticationApi extends BaseAPI {
  /**
   * Creates a new client that will be associated with the service tag. The client is used to authenticate against the veloconnect proxy.
   * Create new client
   */
  async createClientRaw(
    requestParameters: CreateClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Client>> {
    if (requestParameters.CreateClient === null || requestParameters.CreateClient === undefined) {
      throw new RequiredError(
        "CreateClient",
        "Required parameter requestParameters.CreateClient was null or undefined when calling createClient."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("RegisterAuthorization"); // RegisterAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateClientToJSON(requestParameters.CreateClient)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ClientFromJSON(jsonValue));
  }

  /**
   * Creates a new client that will be associated with the service tag. The client is used to authenticate against the veloconnect proxy.
   * Create new client
   */
  async createClient(
    requestParameters: CreateClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Client> {
    const response = await this.createClientRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Deletes the client associated with the given service tag.
   * Delete client
   */
  async deleteClientRaw(
    requestParameters: DeleteClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Status>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling deleteClient."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.resetApiKey !== undefined) {
      queryParameters["resetApiKey"] = requestParameters.resetApiKey;
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Deletes the client associated with the given service tag.
   * Delete client
   */
  async deleteClient(
    requestParameters: DeleteClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Status> {
    const response = await this.deleteClientRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the client associated with the given service tag.
   * Get client
   */
  async getClientRaw(
    requestParameters: GetClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Client>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getClient."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ClientFromJSON(jsonValue));
  }

  /**
   * Returns the client associated with the given service tag.
   * Get client
   */
  async getClient(
    requestParameters: GetClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Client> {
    const response = await this.getClientRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all clients.
   * Get clients
   */
  async getClientsRaw(
    requestParameters: GetClientsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ClientList>> {
    if (requestParameters.page === null || requestParameters.page === undefined) {
      throw new RequiredError(
        "page",
        "Required parameter requestParameters.page was null or undefined when calling getClients."
      );
    }

    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
      throw new RequiredError(
        "pageSize",
        "Required parameter requestParameters.pageSize was null or undefined when calling getClients."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ClientListFromJSON(jsonValue));
  }

  /**
   * Returns all clients.
   * Get clients
   */
  async getClients(
    requestParameters: GetClientsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ClientList> {
    const response = await this.getClientsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the updated client associated with the given service tag.
   * Update client
   */
  async updateClientRaw(
    requestParameters: UpdateClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Client>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling updateClient."
      );
    }

    if (requestParameters.UpdateClient === null || requestParameters.UpdateClient === undefined) {
      throw new RequiredError(
        "UpdateClient",
        "Required parameter requestParameters.UpdateClient was null or undefined when calling updateClient."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateClientToJSON(requestParameters.UpdateClient)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ClientFromJSON(jsonValue));
  }

  /**
   * Returns the updated client associated with the given service tag.
   * Update client
   */
  async updateClient(
    requestParameters: UpdateClientRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Client> {
    const response = await this.updateClientRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region AvailabilityApi

export interface GetContentProductAvailabilitiesRequest {
  serviceTag: string;
  page: number;
  pageSize: number;
  brandKeys?: Array<string>;
}

export interface GetContentProductAvailabilitiesByProductIdentityRequest {
  serviceTag: string;
  ContentProductIdentity: Array<ContentProductIdentity>;
}

/**
 *
 */
export class AvailabilityApi extends BaseAPI {
  /**
   * Returns cached content product availabilities and details. As only the cache returned, it is possible that this endpoint returns insufficient data, e.g. if the cache is not yet filled. If the client has multiple connections configured serving the same requested content product brand the response can contain multiple responses for the same product.
   * Get cached content product availabilities and details
   */
  async getContentProductAvailabilitiesRaw(
    requestParameters: GetContentProductAvailabilitiesRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<AvailabilityResponse>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getContentProductAvailabilities."
      );
    }

    if (requestParameters.page === null || requestParameters.page === undefined) {
      throw new RequiredError(
        "page",
        "Required parameter requestParameters.page was null or undefined when calling getContentProductAvailabilities."
      );
    }

    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
      throw new RequiredError(
        "pageSize",
        "Required parameter requestParameters.pageSize was null or undefined when calling getContentProductAvailabilities."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    if (requestParameters.brandKeys) {
      queryParameters["brandKeys"] = requestParameters.brandKeys.join(COLLECTION_FORMATS["csv"]);
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/content-product-availabilities`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => AvailabilityResponseFromJSON(jsonValue));
  }

  /**
   * Returns cached content product availabilities and details. As only the cache returned, it is possible that this endpoint returns insufficient data, e.g. if the cache is not yet filled. If the client has multiple connections configured serving the same requested content product brand the response can contain multiple responses for the same product.
   * Get cached content product availabilities and details
   */
  async getContentProductAvailabilities(
    requestParameters: GetContentProductAvailabilitiesRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<AvailabilityResponse> {
    const response = await this.getContentProductAvailabilitiesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns cached content product availabilities and details for the given product ids and variant ids. As only the cache returned, it is possible that this endpoint returns insufficient data, e.g. if the cache is not yet filled. If a requested product is not found in our product cache or we do not have a cached availability state for the given client it will be omitted in the response. If the client has multiple connections configured serving the same requested content product brand the response can contain multiple responses for the same product. The variantId field is required. If your requested product does not have a variantId use the productId as variantId.
   * Get cached content product availabilities and details by product identity
   */
  async getContentProductAvailabilitiesByProductIdentityRaw(
    requestParameters: GetContentProductAvailabilitiesByProductIdentityRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<AvailabilityResponse>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getContentProductAvailabilitiesByProductIdentity."
      );
    }

    if (requestParameters.ContentProductIdentity === null || requestParameters.ContentProductIdentity === undefined) {
      throw new RequiredError(
        "ContentProductIdentity",
        "Required parameter requestParameters.ContentProductIdentity was null or undefined when calling getContentProductAvailabilitiesByProductIdentity."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/content-product-availabilities`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.ContentProductIdentity.map(ContentProductIdentityToJSON)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => AvailabilityResponseFromJSON(jsonValue));
  }

  /**
   * Returns cached content product availabilities and details for the given product ids and variant ids. As only the cache returned, it is possible that this endpoint returns insufficient data, e.g. if the cache is not yet filled. If a requested product is not found in our product cache or we do not have a cached availability state for the given client it will be omitted in the response. If the client has multiple connections configured serving the same requested content product brand the response can contain multiple responses for the same product. The variantId field is required. If your requested product does not have a variantId use the productId as variantId.
   * Get cached content product availabilities and details by product identity
   */
  async getContentProductAvailabilitiesByProductIdentity(
    requestParameters: GetContentProductAvailabilitiesByProductIdentityRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<AvailabilityResponse> {
    const response = await this.getContentProductAvailabilitiesByProductIdentityRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region ConfigApi

export interface CreateUpstreamConnectionRequest {
  serviceTag: string;
  CreateUpstreamConnection: CreateUpstreamConnection;
}

export interface DeleteUpstreamConnectionRequest {
  serviceTag: string;
  endpointKey: string;
}

export interface ForceUpstreamConnectionImportRequest {
  serviceTag: string;
  endpointKey: string;
}

export interface GetUpstreamConnectionsRequest {
  serviceTag: string;
}

export interface UpdateBrandsRequest {
  serviceTag: string;
  request_body: Array<string>;
}

export interface UpdateUpstreamConnectionRequest {
  serviceTag: string;
  endpointKey: string;
  UpdateUpstreamConnection: UpdateUpstreamConnection;
}

/**
 *
 */
export class ConfigApi extends BaseAPI {
  /**
   * Creates new upstream connection that will be associated with the service tag. The upstream connection are used to authenticate against a given upstream API. This endpoint will return a 409, if the upstream connection are invalid.
   * Create new upstream connection
   */
  async createUpstreamConnectionRaw(
    requestParameters: CreateUpstreamConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ClientConfig>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling createUpstreamConnection."
      );
    }

    if (
      requestParameters.CreateUpstreamConnection === null ||
      requestParameters.CreateUpstreamConnection === undefined
    ) {
      throw new RequiredError(
        "CreateUpstreamConnection",
        "Required parameter requestParameters.CreateUpstreamConnection was null or undefined when calling createUpstreamConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateUpstreamConnectionToJSON(requestParameters.CreateUpstreamConnection)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ClientConfigFromJSON(jsonValue));
  }

  /**
   * Creates new upstream connection that will be associated with the service tag. The upstream connection are used to authenticate against a given upstream API. This endpoint will return a 409, if the upstream connection are invalid.
   * Create new upstream connection
   */
  async createUpstreamConnection(
    requestParameters: CreateUpstreamConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ClientConfig> {
    const response = await this.createUpstreamConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Deletes the upstream connection associated with the given endpoint key and service tag.
   * Delete upstream connection
   */
  async deleteUpstreamConnectionRaw(
    requestParameters: DeleteUpstreamConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Status>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling deleteUpstreamConnection."
      );
    }

    if (requestParameters.endpointKey === null || requestParameters.endpointKey === undefined) {
      throw new RequiredError(
        "endpointKey",
        "Required parameter requestParameters.endpointKey was null or undefined when calling deleteUpstreamConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections/{endpointKey}`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"endpointKey"}}`, encodeURIComponent(String(requestParameters.endpointKey))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Deletes the upstream connection associated with the given endpoint key and service tag.
   * Delete upstream connection
   */
  async deleteUpstreamConnection(
    requestParameters: DeleteUpstreamConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Status> {
    const response = await this.deleteUpstreamConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * if this endpoint is called the upstream connection is scheduled for a immediate import. the import should start in a couple of seconds after calling this endpoint
   * Force Import
   */
  async forceUpstreamConnectionImportRaw(
    requestParameters: ForceUpstreamConnectionImportRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Status>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling forceUpstreamConnectionImport."
      );
    }

    if (requestParameters.endpointKey === null || requestParameters.endpointKey === undefined) {
      throw new RequiredError(
        "endpointKey",
        "Required parameter requestParameters.endpointKey was null or undefined when calling forceUpstreamConnectionImport."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections/{endpointKey}`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"endpointKey"}}`, encodeURIComponent(String(requestParameters.endpointKey))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * if this endpoint is called the upstream connection is scheduled for a immediate import. the import should start in a couple of seconds after calling this endpoint
   * Force Import
   */
  async forceUpstreamConnectionImport(
    requestParameters: ForceUpstreamConnectionImportRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Status> {
    const response = await this.forceUpstreamConnectionImportRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * returns a list of all compatible brands and features
   * Ping
   */
  async getBrandStatusRaw(initOverrides?: RequestInit | InitOverrideFunction): Promise<ApiResponse<BrandStatusList>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/brands`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => BrandStatusListFromJSON(jsonValue));
  }

  /**
   * returns a list of all compatible brands and features
   * Ping
   */
  async getBrandStatus(initOverrides?: RequestInit | InitOverrideFunction): Promise<BrandStatusList> {
    const response = await this.getBrandStatusRaw(initOverrides);
    return await response.value();
  }

  /**
   * Returns the available client configs for the given service tag. The client configs contain information about existing upstream connections, and upstream endpoint information.
   * Get upstream connections
   */
  async getUpstreamConnectionsRaw(
    requestParameters: GetUpstreamConnectionsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Array<ClientConfig>>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getUpstreamConnections."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => jsonValue.map(ClientConfigFromJSON));
  }

  /**
   * Returns the available client configs for the given service tag. The client configs contain information about existing upstream connections, and upstream endpoint information.
   * Get upstream connections
   */
  async getUpstreamConnections(
    requestParameters: GetUpstreamConnectionsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Array<ClientConfig>> {
    const response = await this.getUpstreamConnectionsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Updates the brands associated with the given service tag. The brands determine for which brands the proxy will fetch data. After a new brand is added this way, a worker will build up the cache for the new brand.
   * Update brands
   */
  async updateBrandsRaw(
    requestParameters: UpdateBrandsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Array<string>>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling updateBrands."
      );
    }

    if (requestParameters.request_body === null || requestParameters.request_body === undefined) {
      throw new RequiredError(
        "request_body",
        "Required parameter requestParameters.request_body was null or undefined when calling updateBrands."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/brands`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.request_body
      },
      initOverrides
    );

    return new JSONApiResponse<any>(response);
  }

  /**
   * Updates the brands associated with the given service tag. The brands determine for which brands the proxy will fetch data. After a new brand is added this way, a worker will build up the cache for the new brand.
   * Update brands
   */
  async updateBrands(
    requestParameters: UpdateBrandsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Array<string>> {
    const response = await this.updateBrandsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Updates the upstream connection associated with the given endpoint key and service tag. The upstream connection are used to authenticate against a given upstream API. This endpoint will return a 409, if the upstream connection are invalid.
   * Update upstream connection
   */
  async updateUpstreamConnectionRaw(
    requestParameters: UpdateUpstreamConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ClientConfig>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling updateUpstreamConnection."
      );
    }

    if (requestParameters.endpointKey === null || requestParameters.endpointKey === undefined) {
      throw new RequiredError(
        "endpointKey",
        "Required parameter requestParameters.endpointKey was null or undefined when calling updateUpstreamConnection."
      );
    }

    if (
      requestParameters.UpdateUpstreamConnection === null ||
      requestParameters.UpdateUpstreamConnection === undefined
    ) {
      throw new RequiredError(
        "UpdateUpstreamConnection",
        "Required parameter requestParameters.UpdateUpstreamConnection was null or undefined when calling updateUpstreamConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections/{endpointKey}`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"endpointKey"}}`, encodeURIComponent(String(requestParameters.endpointKey))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUpstreamConnectionToJSON(requestParameters.UpdateUpstreamConnection)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ClientConfigFromJSON(jsonValue));
  }

  /**
   * Updates the upstream connection associated with the given endpoint key and service tag. The upstream connection are used to authenticate against a given upstream API. This endpoint will return a 409, if the upstream connection are invalid.
   * Update upstream connection
   */
  async updateUpstreamConnection(
    requestParameters: UpdateUpstreamConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ClientConfig> {
    const response = await this.updateUpstreamConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region DatabaseApi

export interface GetContentProductsRequest {
  page: number;
  pageSize: number;
  productId?: string;
  variantId?: string;
  brandKey?: string;
  gtin?: string;
  productNumber?: string;
  modelYear?: number;
}

export interface GetJobsRequest {
  page: number;
  pageSize: number;
  jobId?: string;
  endpointKey?: string;
  serviceTag?: string;
  status?: JobStatus;
  adapterKey?: string;
}

export interface GetUpstreamConnectionUpstreamItemsRequest {
  serviceTag: string;
  endpointKey: string;
}

/**
 *
 */
export class DatabaseApi extends BaseAPI {
  /**
   * Returns all or all matching content products from the database.
   * Get content products from database
   */
  async getContentProductsRaw(
    requestParameters: GetContentProductsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ContentProductList>> {
    if (requestParameters.page === null || requestParameters.page === undefined) {
      throw new RequiredError(
        "page",
        "Required parameter requestParameters.page was null or undefined when calling getContentProducts."
      );
    }

    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
      throw new RequiredError(
        "pageSize",
        "Required parameter requestParameters.pageSize was null or undefined when calling getContentProducts."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.productId !== undefined) {
      queryParameters["productId"] = requestParameters.productId;
    }

    if (requestParameters.variantId !== undefined) {
      queryParameters["variantId"] = requestParameters.variantId;
    }

    if (requestParameters.brandKey !== undefined) {
      queryParameters["brandKey"] = requestParameters.brandKey;
    }

    if (requestParameters.gtin !== undefined) {
      queryParameters["gtin"] = requestParameters.gtin;
    }

    if (requestParameters.productNumber !== undefined) {
      queryParameters["productNumber"] = requestParameters.productNumber;
    }

    if (requestParameters.modelYear !== undefined) {
      queryParameters["modelYear"] = requestParameters.modelYear;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/content-products`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ContentProductListFromJSON(jsonValue));
  }

  /**
   * Returns all or all matching content products from the database.
   * Get content products from database
   */
  async getContentProducts(
    requestParameters: GetContentProductsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ContentProductList> {
    const response = await this.getContentProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all or all matching jobs from the database.
   * Get jobs from database
   */
  async getJobsRaw(
    requestParameters: GetJobsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<JobList>> {
    if (requestParameters.page === null || requestParameters.page === undefined) {
      throw new RequiredError(
        "page",
        "Required parameter requestParameters.page was null or undefined when calling getJobs."
      );
    }

    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
      throw new RequiredError(
        "pageSize",
        "Required parameter requestParameters.pageSize was null or undefined when calling getJobs."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.jobId !== undefined) {
      queryParameters["jobId"] = requestParameters.jobId;
    }

    if (requestParameters.endpointKey !== undefined) {
      queryParameters["endpointKey"] = requestParameters.endpointKey;
    }

    if (requestParameters.serviceTag !== undefined) {
      queryParameters["serviceTag"] = requestParameters.serviceTag;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.adapterKey !== undefined) {
      queryParameters["adapterKey"] = requestParameters.adapterKey;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("QueryUserAuthorization"); // QueryUserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/jobs`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => JobListFromJSON(jsonValue));
  }

  /**
   * Returns all or all matching jobs from the database.
   * Get jobs from database
   */
  async getJobs(
    requestParameters: GetJobsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<JobList> {
    const response = await this.getJobsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all or all matching upstream items from the database.
   * Get upstream items from database
   */
  async getUpstreamConnectionUpstreamItemsRaw(
    requestParameters: GetUpstreamConnectionUpstreamItemsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<UpstreamItemList>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getUpstreamConnectionUpstreamItems."
      );
    }

    if (requestParameters.endpointKey === null || requestParameters.endpointKey === undefined) {
      throw new RequiredError(
        "endpointKey",
        "Required parameter requestParameters.endpointKey was null or undefined when calling getUpstreamConnectionUpstreamItems."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections/{endpointKey}/upstream-items`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"endpointKey"}}`, encodeURIComponent(String(requestParameters.endpointKey))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => UpstreamItemListFromJSON(jsonValue));
  }

  /**
   * Returns all or all matching upstream items from the database.
   * Get upstream items from database
   */
  async getUpstreamConnectionUpstreamItems(
    requestParameters: GetUpstreamConnectionUpstreamItemsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<UpstreamItemList> {
    const response = await this.getUpstreamConnectionUpstreamItemsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region MetricsApi

/**
 *
 */
export class MetricsApi extends BaseAPI {
  /**
   * Returns metrics
   * Metrics
   */
  async getMetricsRaw(initOverrides?: RequestInit | InitOverrideFunction): Promise<ApiResponse<string>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters["Authorization"] =
        "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
    }
    const response = await this.request(
      {
        path: `/_metrics`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new JSONApiResponse<string>(response);
    } else {
      return new TextApiResponse(response) as any;
    }
  }

  /**
   * Returns metrics
   * Metrics
   */
  async getMetrics(initOverrides?: RequestInit | InitOverrideFunction): Promise<string> {
    const response = await this.getMetricsRaw(initOverrides);
    return await response.value();
  }
}

//#endregion
//#region PingApi

/**
 *
 */
export class PingApi extends BaseAPI {
  /**
   * Returns a success status code if the proxy is running.
   * Ping
   */
  async pingRaw(initOverrides?: RequestInit | InitOverrideFunction): Promise<ApiResponse<Status>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/ping`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Returns a success status code if the proxy is running.
   * Ping
   */
  async ping(initOverrides?: RequestInit | InitOverrideFunction): Promise<Status> {
    const response = await this.pingRaw(initOverrides);
    return await response.value();
  }
}

//#endregion
//#region UpstreamDebugApi

export interface DebugUpstreamConnectionGetRequest {
  serviceTag: string;
  endpointKey: string;
  adapterKey: string;
}

export interface DebugUpstreamConnectionPostRequest {
  serviceTag: string;
  endpointKey: string;
  adapterKey: string;
  body?: string;
}

/**
 *
 */
export class UpstreamDebugApi extends BaseAPI {
  /**
   * Request will be forwarded to upstream server. The VCProxy will add BuyersId and Password parameters to request
   * debug proxy upstream server get
   */
  async debugUpstreamConnectionGetRaw(
    requestParameters: DebugUpstreamConnectionGetRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<DebugResponse>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling debugUpstreamConnectionGet."
      );
    }

    if (requestParameters.endpointKey === null || requestParameters.endpointKey === undefined) {
      throw new RequiredError(
        "endpointKey",
        "Required parameter requestParameters.endpointKey was null or undefined when calling debugUpstreamConnectionGet."
      );
    }

    if (requestParameters.adapterKey === null || requestParameters.adapterKey === undefined) {
      throw new RequiredError(
        "adapterKey",
        "Required parameter requestParameters.adapterKey was null or undefined when calling debugUpstreamConnectionGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections/{endpointKey}/proxy/{adapterKey}`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"endpointKey"}}`, encodeURIComponent(String(requestParameters.endpointKey)))
          .replace(`{${"adapterKey"}}`, encodeURIComponent(String(requestParameters.adapterKey))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => DebugResponseFromJSON(jsonValue));
  }

  /**
   * Request will be forwarded to upstream server. The VCProxy will add BuyersId and Password parameters to request
   * debug proxy upstream server get
   */
  async debugUpstreamConnectionGet(
    requestParameters: DebugUpstreamConnectionGetRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<DebugResponse> {
    const response = await this.debugUpstreamConnectionGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Request will be forwarded to upstream server. The VCProxy will add BuyersId and Password parameters to request if $$BUYERSID$$ is present in the body it will be replaced by connection BuyersId if $$PASSWORD$$ is present in the body it will be replaced by connection password
   * debug proxy upstream server post
   */
  async debugUpstreamConnectionPostRaw(
    requestParameters: DebugUpstreamConnectionPostRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<DebugResponse>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling debugUpstreamConnectionPost."
      );
    }

    if (requestParameters.endpointKey === null || requestParameters.endpointKey === undefined) {
      throw new RequiredError(
        "endpointKey",
        "Required parameter requestParameters.endpointKey was null or undefined when calling debugUpstreamConnectionPost."
      );
    }

    if (requestParameters.adapterKey === null || requestParameters.adapterKey === undefined) {
      throw new RequiredError(
        "adapterKey",
        "Required parameter requestParameters.adapterKey was null or undefined when calling debugUpstreamConnectionPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "text/xml";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("UserAuthorization"); // UserAuthorization authentication
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/clients/{serviceTag}/upstream-connections/{endpointKey}/proxy/{adapterKey}`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"endpointKey"}}`, encodeURIComponent(String(requestParameters.endpointKey)))
          .replace(`{${"adapterKey"}}`, encodeURIComponent(String(requestParameters.adapterKey))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => DebugResponseFromJSON(jsonValue));
  }

  /**
   * Request will be forwarded to upstream server. The VCProxy will add BuyersId and Password parameters to request if $$BUYERSID$$ is present in the body it will be replaced by connection BuyersId if $$PASSWORD$$ is present in the body it will be replaced by connection password
   * debug proxy upstream server post
   */
  async debugUpstreamConnectionPost(
    requestParameters: DebugUpstreamConnectionPostRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<DebugResponse> {
    const response = await this.debugUpstreamConnectionPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region WebhookApi

/**
 *
 */
export class WebhookApi extends BaseAPI {
  /**
   * Return sync result
   * Webhook
   */
  async syncProductsRaw(initOverrides?: RequestInit | InitOverrideFunction): Promise<ApiResponse<Status>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("AdminAuthorization"); // AdminAuthorization authentication
    }

    const response = await this.request(
      {
        path: `/webhook/sync-products`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Return sync result
   * Webhook
   */
  async syncProducts(initOverrides?: RequestInit | InitOverrideFunction): Promise<Status> {
    const response = await this.syncProductsRaw(initOverrides);
    return await response.value();
  }
}

//#endregion
