import React from "react";
import { useTranslation } from "react-i18next";

import Card from "../../../commons/components/Card/Card";
import CenteredContent from "../../../commons/components/CenteredContent/CenteredContent";
import MainContentLayout from "../../../commons/components/MainContentLayout/MainContentLayout";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";

const CredentialsNotice = () => {
  const { t } = useTranslation(["mobile"]);

  return (
    <MainContentLayout>
      <CenteredContent>
        <Card headline={t("mobile:credentialsNotice.noCredentialsHeadline")}>
          <Paragraph>{t("mobile:credentialsNotice.noCredentialsText")}</Paragraph>
        </Card>
      </CenteredContent>
    </MainContentLayout>
  );
};

export default CredentialsNotice;
