import React from "react";

import { Component } from "../../../../commons/types/component";
import { HashMap } from "../../../../commons/types/hashmap";
import cn from "../../libs/class-name";

interface Props extends Component {
  data: HashMap<string>;
  variant?: "primary" | "standout";
}

const InformationList = ({ classNames = [], variant = "primary", data }: Props) => (
  <dl
    className={cn(
      "InformationList",
      [
        {
          [`variant-${variant}`]: variant
        }
      ],
      classNames
    )}
  >
    {Object.keys(data).map(key => (
      <React.Fragment key={key}>
        <dt className="InformationList__key">{key}</dt>
        <dd className="InformationList__value">{data[key]}</dd>
      </React.Fragment>
    ))}
  </dl>
);

export default InformationList;
