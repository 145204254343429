import { useCallback, useMemo, useState } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { FormikErrors, FormikHelpers, useFormik } from "formik";

import {
  getSettings as getSettingsLib,
  storeSetting as storeSettingLib
} from "../../../../commons/libs/settings-service";
import { AssortmentType } from "../../../../commons/types/assortment";
import { AssortmentPriceSettings, Settings, ToolSetting } from "../../../../commons/types/settings";
import { ExternalToolKey } from "../../../../commons/types/tool";
import actions from "../../actions";
import sharedConfig from "../../config/shared";
import { hash } from "../externals/hash";
import useDispatch from "../hooks/use-dispatch";
import useOnMount from "../hooks/use-on-mount";
import useQuery, { UseQueryOptions } from "../hooks/use-query";
import useThrottle from "../hooks/use-throttle";

const settingsQueryKey = ["settings"] as const;
const setSettingsMutationKey = ["setSettings"] as const;

type SettingsQueryOptions = Omit<Partial<UseQueryOptions<Settings>>, "queryKey" | "queryFn">;

type SetSettingsMutationVariables = {
  path: Parameters<typeof storeSettingLib>[0];
  value: Parameters<typeof storeSettingLib>[1];
};

type SetSettingsMutationOptions = Omit<
  Partial<UseMutationOptions<Settings, Error, SetSettingsMutationVariables>>,
  "mutationKey" | "mutationFn"
>;

export const useSettings = (options: SettingsQueryOptions = {}) => {
  const dispatch = useDispatch();

  const query = useQuery({
    ...options,
    queryKey: settingsQueryKey,
    queryFn: getSettingsLib,
    staleTime: Infinity, // Keep the cache data, until we mutate the settings via `useSetSettings`.
    onSuccess: settings => {
      // TODO: BCD-7672 Replace `state.settings.settings` with `useSettings`
      dispatch(actions.settings.received(settings));
      options.onSuccess?.(settings);
    },
    onError: error => {
      dispatch(actions.error.set(error));
    }
  });

  const settings = query.data;

  const assortmentPriceSettings = useMemo(() => {
    const fallbackAssortmentPriceSettings: AssortmentPriceSettings = {
      showAssortmentPrices: false,
      showFallbackPrices: false,
      useRrpAsOriginalPrice: false,
      showOriginalPrices: false,
      showSaleBadge: null
    };

    if (settings) {
      const hasAutomaticAssortmentType = settings.assortment.type === AssortmentType.Automatic;

      return hasAutomaticAssortmentType
        ? (settings.assortment.priceSettings ?? fallbackAssortmentPriceSettings)
        : fallbackAssortmentPriceSettings;
    } else {
      return undefined;
    }
  }, [settings]);

  return {
    ...query,
    assortmentPriceSettings
  };
};

export const useSetSettings = (options: SetSettingsMutationOptions = {}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    mutate,
    // We destructure the mutation functions to make them usable inside the `useSetSettings` hook,
    // but don't export them to the outside. Setting settings should only be done via specific exported typed functions.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutateAsync,
    ...setSettingsMutation
  } = useMutation({
    ...options,
    mutationKey: setSettingsMutationKey,
    mutationFn: ({ path, value }: SetSettingsMutationVariables) => storeSettingLib(path, value),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: settingsQueryKey });
      options.onSuccess?.(data, variables, context);
    },
    onError: error => {
      dispatch(actions.error.set(error));
    }
  });

  const setPersonalCode = useCallback(
    (personalCode: string) => {
      return mutate({ path: ["personalCode"], value: hash(personalCode) });
    },
    [mutate]
  );

  const setToolSettings = useCallback(
    <T extends ToolSetting>(toolKey: ExternalToolKey) =>
      (toolSettings: T) => {
        return mutate({ path: ["toolSettings", toolKey], value: toolSettings });
      },
    [mutate]
  );

  return {
    ...setSettingsMutation,
    setPersonalCode,
    setToolSettings
  };
};

interface UseToolSettingsOptions<TS> {
  formik?: {
    initialValues?: (toolSettings: TS) => TS;
    onSubmit?: ({
      values,
      helpers,
      setToolSettings
    }: {
      /** The current formik values. */
      values: TS;
      /** The original formik helpers */
      helpers: FormikHelpers<TS>;
      /** The function to set the tool settings with typed tool settings. */
      setToolSettings: (toolSettings: TS) => void;
    }) => Promise<void> | void;
    validate?: ({
      values,
      errors
    }: {
      /** The current formik values. */
      values: TS;
      /** Initial empty, but typed error object to which the errors need to be appended to. */
      errors: FormikErrors<TS>;
    }) => FormikErrors<TS>;
  };
  query?: {
    settings?: SettingsQueryOptions;
    setSettings?: SetSettingsMutationOptions;
  };
}

/** Time to throttle quickly changing loading states to avoid flickering for elements that depend on the loading states. */
const LOADING_STATE_THROTTLE_TIME = sharedConfig.shared.minLoadingTimeout;

// BCD-7381 Improve Tool Config Type Association
export const useToolSettings = <TS extends ToolSetting = ToolSetting>(
  toolKey: ExternalToolKey,
  options: UseToolSettingsOptions<TS> = {}
) => {
  const settings = useSettings(options.query?.settings);
  const setSettings = useSetSettings(options.query?.setSettings);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const fallbackToolSettings = { meta: {} };
  const currentToolSettings = settings.data?.toolSettings[toolKey] as TS;
  const toolSettings = currentToolSettings ?? fallbackToolSettings;
  const setToolSettings = setSettings.setToolSettings<TS>(toolKey);

  const formik = useFormik<TS>({
    enableReinitialize: true,
    initialValues: options.formik?.initialValues?.(toolSettings) ?? toolSettings,
    validate: (values: TS) => {
      if (options.formik?.validate) {
        const errors: FormikErrors<TS> = {};
        return options.formik.validate({ values, errors });
      } else {
        return {};
      }
    },
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);

      if (options.formik?.onSubmit) {
        await options.formik.onSubmit({ values, helpers, setToolSettings });
      } else {
        setToolSettings({
          ...values,
          meta: {
            ...values.meta,
            touchedSettings: true
          }
        });
      }

      helpers.setSubmitting(false);
      setHasSubmitted(true);
    }
  });

  const isFetching = useThrottle(
    settings.isFetching || setSettings.isPending || formik.isSubmitting,
    LOADING_STATE_THROTTLE_TIME
  );

  const isSuccess = useThrottle(
    setSettings.isSuccess || settings.isSuccess || hasSubmitted,
    LOADING_STATE_THROTTLE_TIME
  );

  // Mark the tool as viewed if we visit the page.
  useOnMount(() => {
    if (!toolSettings.meta.viewedSettings) {
      setToolSettings({
        ...toolSettings,
        meta: {
          ...toolSettings.meta,
          viewedSettings: true
        }
      });
    }
  });

  return {
    /** The raw query return object of `useSettings`. */
    settingsQuery: settings,
    /** The raw mutation return object of `useSetSettings`. */
    setSettingsMutation: setSettings,
    /** The current tool settings for the provided tool key. */
    toolSettings,
    /** The mutation function for setting the tool settings. */
    setToolSettings,
    /** The formik object for the tool settings form. */
    formik,
    /** Has the user tried to submit the form? */
    hasSubmitted,
    /** Convience property for checking whether the settings, the mutation is currently fetching or formik is submitting. */
    isFetching,
    /** Convience property for checking whether the settings or the mutation was successful. */
    isSuccess
  };
};
