import { useState } from "react";
import { noop } from "lodash";

import { ClientConfig } from "../../../../commons/libs/externals/veloconnect-proxy";
import {
  getVeloconnectEndpointByBrandKey,
  isVeloconnectImportReady,
  VeloconnectError
} from "../../../../commons/libs/veloconnect-service";
import { VeloconnectErrorType } from "../../../../commons/types/veloconnect";
import { selectInitializedSettings } from "../selectors";

import useFetchData from "./use-fetch-data";
import useSelector from "./use-selector";

export interface UseVeloconnectEndpointOptions {
  refetchDependencies?: React.DependencyList;
  onFetchSuccess?: (endpoint: ClientConfig | undefined) => void;
  onFetchError?: (error: Error) => void;
}

export interface UseVeloconnectEndpointReturn {
  isReachable: boolean;
  isLoading: boolean;
  /** If endpoint returns `undefined`, it means that for this brand there is no Veloconnect available. */
  endpoint: ClientConfig | undefined;
  refresh: () => void;
  error: VeloconnectErrorType | undefined;
  isImportReady: boolean;
  isImportOutdated: boolean;
  shouldShowNotification: boolean;
}

/** A hook that fetches a Veloconnect Endpoint for a given brandKey and provides utility functions to interact with it. */
const useVeloconnectEndpoint = (
  // When used from `ProductDetailsPartial` the `brandKey` is `undefined`, until the `product` is loaded.
  brandKey: string | undefined,
  { refetchDependencies = [], onFetchSuccess = noop, onFetchError = noop }: UseVeloconnectEndpointOptions = {}
): UseVeloconnectEndpointReturn => {
  const veloconnectSettings = useSelector(state => selectInitializedSettings(state).veloconnect);

  const [isReachable, setIsReachable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    data: endpoint,
    error: endpointError,
    refresh
  } = useFetchData(
    async () => {
      setIsLoading(true);
      if (brandKey) {
        const endpoint = await getVeloconnectEndpointByBrandKey(brandKey);
        // If no endpoint is found for the brand, the server will return an empty object.
        // If this is the case, explicitly return `undefined`,
        // to indicate that there is no Veloconnect available for this brand.
        const doesEndpointExistForBrand = endpoint && Object.keys(endpoint).length > 0;
        return doesEndpointExistForBrand ? endpoint : undefined;
      } else {
        return undefined;
      }
    },
    [...refetchDependencies, brandKey],
    {
      onDone: endpoint => {
        setIsReachable(true);
        setIsLoading(false);
        onFetchSuccess?.(endpoint);
      },
      handleError: error => {
        setIsReachable(false);
        setIsLoading(false);
        onFetchError?.(error);
        console.error("Error fetching Veloconnect endpoint", error);
      },
      isEnabled: !!brandKey
    }
  );

  // TODO: BCD-7002 Generic Error Type Argument für `useFetchData`
  const evalError = (error: Error): VeloconnectErrorType => {
    if (error instanceof VeloconnectError) {
      return error.veloconnectError;
    } else {
      return VeloconnectErrorType.Other;
    }
  };

  const error = endpointError ? evalError(endpointError) : undefined;

  // Show the notification if the Veloconnect is not configured but available for configuration, or if it is restricted.
  const shouldShowNotification = Boolean(
    !!endpoint && !endpoint.isConfigured && !veloconnectSettings[endpoint?.endpointKey]?.modalDismissed
  );

  return {
    isReachable,
    isLoading,
    endpoint: brandKey ? endpoint : undefined,
    refresh,
    error,
    isImportReady: isVeloconnectImportReady(endpoint),
    isImportOutdated: endpoint?.adapters?.some(adapter => adapter.isImportOutdated) || false,
    shouldShowNotification
  };
};

export default useVeloconnectEndpoint;
