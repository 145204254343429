import React from "react";

import initMixpanel from "../../../commons/libs/externals/mixpanel";
import initSentry from "../../../commons/libs/externals/sentry";

interface TrackingContextState {
  sentry: ReturnType<typeof initSentry>;
  mixpanel: Omit<ReturnType<typeof initMixpanel>, "trackingMiddleware">;
}

interface Props extends TrackingContextState {
  children: React.ReactNode;
}

const TrackingContext = React.createContext<TrackingContextState | null>(null);

export const TrackingProvider = ({ children, sentry, mixpanel }: Props) => {
  return <TrackingContext.Provider value={{ sentry, mixpanel }}>{children}</TrackingContext.Provider>;
};

export const useTrackingContext = (): TrackingContextState => {
  const context = React.useContext(TrackingContext);

  if (context === null) {
    throw new Error("useTrackingContext must be used within a TrackingProvider");
  }

  return context;
};
