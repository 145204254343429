import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  major?: boolean;
}

const DescriptionList = ({ classNames = [], children, major = false }: Props) => (
  <dl className={cn("DescriptionList", [{ major }], classNames)}>{children}</dl>
);

export default DescriptionList;
