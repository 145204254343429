import React from "react";
import { ConnectedProps } from "react-redux";

import { ModalProps, PdfViewerModalContentProps } from "../../../../commons/types/modal";
import * as icons from "../../../../resources/icons";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import Modal from "../../components/Modal/Modal";
import { connect } from "../../container/utils/loop";
import { State } from "../../reducers";

import PdfViewerPartial from "./PdfViewerPartial";

const mapStateToProps = (state: State) => ({
  bodyValues: state.session.bodyValues
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & ModalProps<PdfViewerModalContentProps>;

const PdfViewerModalParial = ({
  close,
  url,
  isOffline,
  isOfflineAndMailToPrintIsEnabled,
  hidePrint = false,
  hideSend = false
}: Props) => {
  return (
    <Modal
      width="xl"
      layout="noPadding"
      closeButton={<IconButton onClick={() => close()} icon={<Icon source={icons.IconSmallCross} />} />}
    >
      <PdfViewerPartial
        isOffline={isOffline}
        url={url}
        isOfflineAndMailToPrintIsEnabled={isOfflineAndMailToPrintIsEnabled}
        hidePrint={hidePrint}
        hideSend={hideSend}
        onClose={close}
      />
    </Modal>
  );
};

export default connector(PdfViewerModalParial);
