import { strict as assert } from "assert";
import { i18n } from "i18next";
import { isNil, snakeCase, uniq } from "lodash";
import moment from "moment";

import {
  Product,
  ProductSpecKey,
  ProductType,
  SpecDefinitions,
  SpecMap,
  StandaloneProductVariant
} from "../specs/product";
import { Currency } from "../types/currency";
import { UrlPrintDataItem } from "../types/print-mail";
import { ModelYearDisplay } from "../types/settings";

import {
  filterVisibleSpecValues,
  getAllSpecValues,
  getStandaloneProductVariants,
  isProduct,
  isSpecValueVisible
} from "./specs";

// TODO: this function is missing a verb and its simply not a formatter (because it doesn't just prepare data as string for output)
export const wifiSignalStrength = (signal: number) => {
  if (signal < 1) {
    return 1;
  }
  if (signal > 99) {
    return 5;
  }

  return Math.ceil((signal / 100) * 5);
};

export const formatTimeWithLeadingZero = (hourOrMinute: number, leadingZero: boolean): string => {
  return leadingZero ? `0${hourOrMinute}` : `${hourOrMinute}`;
};

export const formatFloatToTime = (time: number): string => {
  const minutePercentage = parseFloat((time % 1).toFixed(2));

  const hourDisplay = Math.trunc(time);
  const minuteDisplay = parseInt((60 * minutePercentage).toFixed(0), 10);

  const leadingZeroHour = hourDisplay < 10;
  const leadingZeroMinute = minuteDisplay < 10;

  return `${formatTimeWithLeadingZero(hourDisplay, leadingZeroHour)}:${formatTimeWithLeadingZero(
    minuteDisplay,
    leadingZeroMinute
  )}`;
};

export const formatPercentage = (num: number) => `${Math.min(Math.max(Math.round(num * 100), 0), 100)}%`;

export const formatPrice = (price: number, currency: Currency, locale: string) =>
  Intl.NumberFormat(locale, {
    currency,
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(price);

export const formatEmptyPrice = (currency: Currency, locale: string) => {
  const parts = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).formatToParts(0);

  const currencyPart = (part: Intl.NumberFormatPart) => part.type === "currency";
  const symbol = parts.find(currencyPart)?.value || "";
  const isSymbolPrefix = parts.findIndex(currencyPart) === 0;

  return isSymbolPrefix ? `${symbol} —` : `— ${symbol}`;
};

export const formatDecimal = (
  value: number,
  locale: string,
  options?: { maximumFractionDigits?: number; useGrouping?: false }
) => Intl.NumberFormat(locale, { maximumFractionDigits: 2, ...options }).format(value);

// List of accepted units: https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers
// Please add more strings to this union type as needed.
type Unit = "millimeter" | "centimeter" | "meter" | "kilometer" | "kilogram";
export const formatUnit = (
  value: number,
  locale: string,
  options: { unit: Unit; maximumFractionDigits?: number; useGrouping?: false }
) => Intl.NumberFormat(locale, { style: "unit", maximumFractionDigits: 2, ...options }).format(value);

export const formatDateTime = (value: Date, locale: string) =>
  Intl.DateTimeFormat(locale, { dateStyle: "short", timeStyle: "medium" }).format(value);

export const formatDate = (value: Date, locale: string) => Intl.DateTimeFormat(locale).format(value);

export const formatShareProductDataSheetFilename = (product: Product) =>
  `${snakeCase(`${product[ProductSpecKey.BrandName]}-${product[ProductSpecKey.ModelName]}-${product[ProductSpecKey.ModelYear]}`)}.pdf`;

export const formatShareDefaultFilename = (printData: UrlPrintDataItem) =>
  `${snakeCase(`${printData.title}_${moment(printData.date).unix()}`)}.pdf`;

export interface SpecFormatter<Type extends ProductType> {
  formatLabel(specKey: keyof SpecMap[Type]): string | undefined;
  formatVariantValue(specKey: keyof SpecMap[Type], variant: StandaloneProductVariant<Type>): string | undefined;
  formatProductValue(specKey: keyof SpecMap[Type], product: Product<Type>): string | undefined;
  formatProductOrVariantValue(
    specKey: keyof SpecMap[Type],
    product: Product<Type> | StandaloneProductVariant<Type>
  ): string | undefined;
}

export interface SpecFormatterOptions {
  modelYearDisplay: ModelYearDisplay;
}

export const getSpecFormatter = <Type extends ProductType>(
  productType: Type,
  specDefinitions: SpecDefinitions<Type>,
  i18n: i18n,
  options: SpecFormatterOptions
): SpecFormatter<Type> => {
  type Spec = SpecMap[Type];

  // const specDefinitions = specConfig.specDefinitions;
  const getI18nSpecKey = (specKey: keyof Spec) => `specs.${productType}.specDefinitions.${String(specKey)}` as const;

  const formatLabel = (specKey: keyof Spec): string | undefined => {
    const specDefinition = specDefinitions[specKey];

    if (specDefinition && specDefinition.formatLabel) {
      return specDefinition.formatLabel(
        {
          i18n,
          i18nSpecKey: getI18nSpecKey(specKey) as Parameters<typeof specDefinition.formatLabel>[0]["i18nSpecKey"]
        },
        options
      );
    }

    // Translations for labels are optional, in parallel to visibility check.
    const labelKey = `${getI18nSpecKey(specKey)}.label`;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return i18n.exists(labelKey) ? i18n.t(labelKey) : undefined;
  };

  const formatVariantValue = (specKey: keyof Spec, variant: StandaloneProductVariant<Type>): string | undefined => {
    const specDefinition = specDefinitions[specKey];
    assert(
      specDefinition,
      new Error(`Spec value cannot be formatted because spec definition not found for spec key "${String(specKey)}".`)
    );

    const value = variant[specKey];

    if (isSpecValueVisible(value, specDefinition)) {
      if (specDefinition.formatValue) {
        return specDefinition.formatValue(
          value,
          variant,
          {
            i18n,
            // Impossible to map types in a clean way here.
            // But that is ok because the benefit of having strict types within the specs definition is more important.
            i18nSpecKey: getI18nSpecKey(specKey) as Parameters<typeof specDefinition.formatValue>[2]["i18nSpecKey"]
          },
          options
        );
      } else {
        return String(value);
      }
    } else {
      return undefined;
    }
  };

  const formatProductValue = (specKey: keyof Spec, product: Product<Type>): string | undefined => {
    const specDefinition = specDefinitions[specKey];
    assert(
      specDefinition,
      new Error(`Spec value cannot be formatted because spec definition not found for spec key "${String(specKey)}".`)
    );

    const join = (values: string[] | string | undefined) => {
      if (values === undefined) {
        return undefined;
      } else if (Array.isArray(values)) {
        if (values.length === 0) {
          return undefined;
        } else {
          return values.join(", ");
        }
      } else {
        return values;
      }
    };

    if (specDefinition.formatValues) {
      const values = filterVisibleSpecValues(getAllSpecValues(specKey, product), specDefinition);

      return values.length > 0
        ? join(
            specDefinition.formatValues(
              values,
              product,
              {
                i18n,
                // Impossible to map types in a clean way here.
                // But that is ok because the benefit of having strict types within the specs definition is more important.
                i18nSpecKey: getI18nSpecKey(specKey) as Parameters<typeof specDefinition.formatValues>[2]["i18nSpecKey"]
              },
              options
            )
          )
        : undefined;
    } else {
      return join(
        uniq(
          getStandaloneProductVariants(product)
            .map(variant => formatVariantValue(specKey, variant))
            .filter((str): str is string => !isNil(str))
        )
      );
    }
  };

  const formatProductOrVariantValue = (
    specKey: keyof Spec,
    productOrVariant: Product<Type> | StandaloneProductVariant<Type>
  ): string | undefined => {
    if (!productOrVariant) {
      return undefined;
    } else if (isProduct(productOrVariant)) {
      return formatProductValue(specKey, productOrVariant);
    } else {
      return formatVariantValue(specKey, productOrVariant);
    }
  };

  return {
    formatLabel,
    formatVariantValue,
    formatProductValue,
    formatProductOrVariantValue
  };
};

export const formatConcealedModelYear = (modelYear: number, modelYearTerm: ModelYearDisplay["term"]): string => {
  if (!Number.isInteger(modelYear)) {
    throw new Error(`Model year must be an integer, but was ${modelYear}`);
  }

  if (modelYear < 1000 || modelYear > 9999) {
    throw new Error("Model year must be a positive 4-digit number");
  }

  const prefix = modelYearTerm === "modelYear" ? "M" : "E";
  const lastDigit = modelYear.toString().slice(-1);

  return `${prefix}${lastDigit}`;
};
