import i18nConfig from "../i18n-config";
import { Locale, TranslationKey } from "../types/localization";

import { getLanguageFromLocale } from "./locale";

export const toLocalizedDate = (date: Date, locale: Locale) => Intl.DateTimeFormat(locale).format(date);

export const toLocalizedTime = (date: Date, locale: Locale) =>
  Intl.DateTimeFormat(locale, { hour: "numeric", minute: "numeric" }).format(date);

export const getLocalizationWithFallback = <T = unknown>(
  translationMap: Partial<Record<TranslationKey, T>>,
  locale: Locale
): T | undefined => {
  if (locale in translationMap) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- if locale is key of translationMap result can´t be undefined
    return translationMap[locale]!;
  } else {
    // Locale falls back to language
    const language = getLanguageFromLocale(locale) ?? i18nConfig.defaultLanguage;

    if (language in translationMap) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- if language is key of translationMap result can´t be undefined
      return translationMap[language]!;
    } else {
      return undefined;
    }
  }
};
