import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import { mapChildrenWithFragments } from "../../libs/react-utils";

interface Props extends Component {
  children: React.ReactNode;
  label?: string;
  error?: string;
  hideErrorText?: boolean;
  /*
  autoWidth allows an intuitive click area. Otherwise the label is spread across the complete screen which feels
  extremly odd clicking in a blank space and selecting the input.
  */
  autoWidth?: boolean;
}

const LabeledField = ({
  classNames = [],
  children,
  label = "",
  error = "",
  hideErrorText = false,
  autoWidth = false
}: Props) => (
  <label className={cn("LabeledField", [{ error, autoWidth }], classNames)}>
    {!!label && <div className="LabeledField__label">{label}</div>}
    {mapChildrenWithFragments(children, child =>
      React.cloneElement(child, {
        error: Boolean(error)
      })
    )}
    {!!error && !hideErrorText && <div className="LabeledField__error">{error}</div>}
  </label>
);

export default LabeledField;
