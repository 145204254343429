import React from "react";
import { ConnectedProps } from "react-redux";

import { Modals } from "../../../../commons/types/modal";
import { connect } from "../../../commons/container/utils/loop";
import actions from "../../actions";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import usePrevious from "../../libs/hooks/use-previous";
import { State } from "../../reducers";
import { useKeyboardContext } from "../utils/keyboard-context";

const mapStateToProps = (state: State) => ({
  isOpen: state.modal?.isOpen,
  contentType: state.modal?.modalConfig?.contentType,
  contentProps: state.modal?.modalConfig?.contentProps
});

const mapDispatchToProps = {
  close: actions.modal.close
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OuterProps = {
  modals: Modals;
  /**
   * The animation duration in seconds
   */
  animationDuration?: React.ComponentProps<typeof ModalContainer>["animationDuration"];
};

type Props = ConnectedProps<typeof connector> & OuterProps;

const ModalPartial = ({ animationDuration, modals, isOpen, contentType, contentProps, close }: Props) => {
  const { keyboardOpen } = useKeyboardContext();

  const modal = !!contentType && modals[contentType];

  if (!modal && !!contentType) {
    throw new Error(`Unknown modal contentType "${contentType}"`);
  }

  const previousIsOpen = usePrevious(isOpen);
  const previousContentProps = usePrevious(contentProps);
  React.useEffect(() => {
    if (!isOpen && previousIsOpen && previousContentProps?.onClose) {
      previousContentProps?.onClose();
    }
  }, [isOpen, contentType, previousContentProps, previousIsOpen]);

  // We need to return always a Modal continer because the closing animation is handled inside the container. Therefore it should close instead of return no component or a fragment.
  return (
    <ModalContainer
      animationDuration={animationDuration}
      isOpen={isOpen && contentType !== undefined}
      onClose={close}
      keyboardOpen={keyboardOpen}
    >
      {modal &&
        React.createElement(modal, {
          ...(contentProps || {}),
          close
        })}
    </ModalContainer>
  );
};

export default connector(ModalPartial);
