import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  label?: React.ReactNode;
  id?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onClick?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const RadioField = ({
  classNames = [],
  label = null,
  name = "",
  id = "",
  value = "",
  checked = false,
  disabled = false,
  onClick = noop,
  onBlur = noop
}: Props) => {
  return (
    <label className={cn("RadioField", [{ checked }], classNames)} aria-checked={checked} aria-disabled={disabled}>
      <input
        className="RadioField__input"
        type="radio"
        name={name}
        value={value}
        onChange={onClick}
        checked={checked}
        disabled={disabled}
        onBlur={onBlur}
        id={id}
      />
      {label && <div className="RadioField__label">{label}</div>}
    </label>
  );
};

export default RadioField;
