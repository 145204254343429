import React from "react";

import config from "../../../commons/config";
import useOrientation, { Orientation } from "../../../commons/libs/hooks/use-orientation";

import ClientWarningPartial from "./ClientWarningPartial";
import OrientationWarningPartial from "./OrientationWarningPartial";

const WarningPartial = () => {
  const orientation = useOrientation();
  const hasInvalidOrientation = !config.mobile.allowPortraitMode && orientation === Orientation.Portrait;

  return hasInvalidOrientation ? <OrientationWarningPartial /> : <ClientWarningPartial />;
};

export default WarningPartial;
