import React, { createContext, ReactNode, useContext } from "react";

import { UseVeloconnectEndpointsReturn } from "../../libs/hooks/use-veloconnect-endpoints";

interface VeloconnectEndpointsContext {
  endpoints: UseVeloconnectEndpointsReturn;
}

interface Props extends VeloconnectEndpointsContext {
  children: ReactNode;
}

const VeloconnectEndpointsContext = createContext<UseVeloconnectEndpointsReturn | null>(null);

/** A provider which receives the Veloconnect Endpoints data and provides it to its children. */
export const VeloconnectEndpointsProvider = ({ endpoints, children }: Props) => {
  return <VeloconnectEndpointsContext.Provider value={endpoints}>{children}</VeloconnectEndpointsContext.Provider>;
};

/** Custom hook to use the context directly */
export const useVeloconnectEndpointsContext = (): UseVeloconnectEndpointsReturn => {
  const context = useContext(VeloconnectEndpointsContext);

  if (context === null) {
    throw new Error("useVeloconnectEndpointsContext must be used within a VeloconnectEndpointsProvider");
  }

  return context;
};
