import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useDebouncedCallback from "../../libs/hooks/use-debounced-callback";
import useTouchState from "../../libs/hooks/use-touch-state";
import Icon from "../Icon/Icon";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

type Variant = "primary" | "tertiary" | "standout" | "accent" | "negative";

type Kind = "outlined" | "solid";

type Size = "default" | "s";

type ButtonType = "button" | "submit" | "reset";

interface Props extends Component {
  children?: React.ReactNode;
  icon?:
    | React.ReactElement<React.ComponentProps<typeof Icon>>
    | React.ReactElement<React.ComponentProps<typeof LoadingIndicator>>;
  block?: boolean;
  disabled?: boolean;
  iconRight?: boolean;
  variant?: Variant;
  kind?: Kind;
  rectangular?: boolean;
  size?: Size;
  singleLine?: boolean;
  animationDuration?: string; // used for ProgressButton
  type?: ButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onDisabledClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  onTouchStart?: React.TouchEventHandler<HTMLButtonElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLButtonElement>;
}

const Button = ({
  classNames = [],
  children,
  icon,
  block = false,
  disabled = false,
  iconRight = false,
  variant = "primary",
  kind = "outlined",
  rectangular = false,
  size = "default",
  singleLine = false,
  animationDuration = "",
  type = "button",
  onClick = noop,
  //onDisabled is set explicitly to undefined b/c the default of onDisabledClick is defined in the component
  onDisabledClick = undefined,
  onTouchStart: onTouchStartOuter,
  onTouchEnd
}: Props) => {
  const { onTouchStart, touched } = useTouchState(onTouchStartOuter);

  const [buzzing, setBuzzing] = React.useState(false);
  const debounceSetBuzzing = useDebouncedCallback(setBuzzing, 500);

  const handleClick = disabled
    ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onDisabledClick) {
          onDisabledClick(event);
        } else {
          // default of onDisabledClick
          setBuzzing(true);
          debounceSetBuzzing(false);
        }
      }
    : onClick;

  return (
    <button
      onClick={handleClick}
      type={type}
      aria-disabled={disabled}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      className={cn(
        "Button",
        [
          {
            block,
            buzzing,
            disabled,
            icon,
            iconRight,
            rectangular,
            [`size-${size}`]: size,
            singleLine,
            touched,
            [`variant-${variant}`]: variant,
            [`kind-${kind}`]: kind
          }
        ],
        classNames
      )}
      style={{ animationDuration }}
    >
      {icon}
      <div className="Button__label">{children}</div>
    </button>
  );
};

export default Button;
