import React from "react";

import { Component } from "../../../../commons/types/component";
import { IconMediumFrameSize } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";
import Tooltip, { TooltipPosition } from "../Tooltip/Tooltip";

interface Props extends Component {
  status?: "fit" | "error";
  errorTooltipContent?: React.ReactNode;
}

const SizingStatusIcon = ({ status = "fit", errorTooltipContent, classNames = [] }: Props) => (
  <span className={cn("SizingStatusIcon", [{ [`status-${status}`]: status }], classNames)}>
    {status === "fit" || !errorTooltipContent ? (
      <Icon source={IconMediumFrameSize} />
    ) : (
      <Tooltip closeOnOutsideClick preferredPosition={TooltipPosition.Bottom} content={errorTooltipContent}>
        {({ open }) => (
          <span onClick={open}>
            <Icon source={IconMediumFrameSize} />
          </span>
        )}
      </Tooltip>
    )}
  </span>
);

export default SizingStatusIcon;
