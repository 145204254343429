import React from "react";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { LogoType } from "../../../../commons/types/logo";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import Button from "../../../commons/components/Button/Button";
import FlexLayout from "../../../commons/components/FlexLayout/FlexLayout";
import Headline from "../../../commons/components/Headline/Headline";
import Icon from "../../../commons/components/Icon/Icon";
import IconButton from "../../../commons/components/IconButton/IconButton";
import Modal from "../../../commons/components/Modal/Modal";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";
import Logo from "../../../commons/container/App/LogoPartial";
import { connect } from "../../../commons/container/utils/loop";
import { ROUTES } from "../../../commons/routes";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onPush: push,
  close: actions.modal.close,
  openLegalInformationModal: actions.modal.openLegalInformationModal
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OuterProps = {
  nodeRef?: React.Ref<HTMLElement>;
};
type Props = ConnectedProps<typeof connector> & OuterProps;

const AboutModalPartial = ({ close, onPush, openLegalInformationModal, nodeRef }: Props) => {
  const { t } = useTranslation(["mobile"]);

  const year = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <Modal
      nodeRef={nodeRef}
      closeButton={<IconButton onClick={close} icon={<Icon source={icons.IconSmallCross} />} />}
      footer={
        <FlexLayout gap="l" justifyContent="center">
          <Button
            onClick={() => {
              openLegalInformationModal();
            }}
          >
            {t("mobile:aboutModal.legalInformation")}
          </Button>
          <Button
            onClick={() => {
              close();
              onPush(ROUTES.DEVICE_INFORMATION_AND_SUPPORT.INDEX);
            }}
          >
            {t("mobile:aboutModal.support")}
          </Button>
        </FlexLayout>
      }
    >
      <Headline kind="sub">{t("mobile:aboutModal.headline")}</Headline>
      <Logo type={LogoType.ClaimSmall} />
      <Headline>{t("mobile:defaults.appName")}</Headline>
      <Paragraph kind="support" classNames={["u-space-top-base"]}>
        {t("mobile:aboutModal.copyright")} {year} {t("mobile:defaults.companyName")}
        <br />
        {t("mobile:aboutModal.allRightsReserved")}
      </Paragraph>
    </Modal>
  );
};

export default connector(AboutModalPartial);
