import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useTouchState from "../../libs/hooks/use-touch-state";
import Badge from "../Badge/Badge";
import Icon from "../Icon/Icon";
import Image from "../Image/Image";
import NotificationBubble from "../NotificationBubble/NotificationBubble";
import TileIconButton from "../TileIconButton/TileIconButton";

interface Props extends Component {
  icon?: React.ReactElement<React.ComponentProps<typeof Icon>> | React.ReactElement<React.ComponentProps<typeof Image>>;
  badge?: React.ReactElement<React.ComponentProps<typeof Badge>>;
  tileButton?: React.ReactElement<React.ComponentProps<typeof TileIconButton>>;
  notification?: React.ReactNode;
  notificationBubble?: React.ReactElement<React.ComponentProps<typeof NotificationBubble>>;
  label?: string;
  href?: string;
  height?: "default" | "xl";
  brandLogo?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>, href: string) => void;
  onTouchStart?: React.TouchEventHandler<HTMLElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLElement>;
  transparent?: boolean;
}

const MainNavigationTile = ({
  classNames = [],
  icon,
  badge,
  tileButton,
  notification = null,
  notificationBubble,
  label = "",
  href = "#",
  height = "default",
  brandLogo = false,
  disabled = false,
  inactive = false,
  transparent = false,
  onClick = noop,
  onTouchStart: onTouchStartOuter,
  onTouchEnd
}: Props) => {
  const { onTouchStart, touched } = useTouchState(onTouchStartOuter);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!disabled) {
      onClick(event, href);
    }
  };

  return (
    <span
      className={cn(
        "MainNavigationTile",
        [
          {
            touched,
            brandLogo,
            disabled,
            inactive,
            transparent,
            [`height-${height}`]: height
          }
        ],
        classNames
      )}
    >
      <a
        className="MainNavigationTile__link"
        href={href}
        onClick={handleClick}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        {icon && <div className="MainNavigationTile__icon">{React.cloneElement(icon, { size: "xl" })}</div>}
        {label && <div className="MainNavigationTile__label">{label}</div>}
        {notificationBubble}
        {badge && <div className="MainNavigationTile__badge">{badge}</div>}
      </a>
      {tileButton && <div className="MainNavigationTile__tileButton">{tileButton}</div>}
      {notification && <div className="MainNavigationTile__notification">{notification}</div>}
    </span>
  );
};

export default MainNavigationTile;
