import React from "react";

import { Component } from "../../../../commons/types/component";
import { IconSmallCross } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

export type ArrowPosition = "left" | "right" | "top" | "bottom";

export type MaxWidth = "default" | "s";

export type Variant = "primary" | "accent";

export type Size = "default" | "l";

interface Props extends Component {
  children: React.ReactNode;
  arrowPosition?: ArrowPosition;
  variant?: Variant;
  size?: Size;
  maxWidth?: MaxWidth;
  extraHorizontalPadding?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement> | null;
}

const Balloon = ({
  classNames = [],
  children,
  arrowPosition = "top",
  variant = "primary",
  size = "default",
  maxWidth = "default",
  extraHorizontalPadding = false,
  onClose
}: Props) => (
  <div
    className={cn(
      "Balloon",
      [
        {
          [`arrow-position-${arrowPosition}`]: !!arrowPosition,
          [`variant-${variant}`]: variant,
          [`size-${size}`]: !!size,
          closeable: !!onClose,
          extraHorizontalPadding
        }
      ],
      classNames
    )}
  >
    <div className="Balloon__wrapper">
      <div className={cn("Balloon__children", [{ [`max-width-${maxWidth}`]: maxWidth }])}>{children}</div>
      {!!onClose && (
        <button type="button" className="Balloon__close" onClick={onClose}>
          <Icon source={IconSmallCross} />
        </button>
      )}
    </div>
  </div>
);

export default Balloon;
