/**
 * Rounds a given decimal number up to the nearest .5 or full value
 * @param number to be round
 * @returns rounded number
 * @example
 * roundUpToNearestHalfOrFull(42.2) => 42.5
 * @example
 * roundUpToNearestHalfOrFull(42.8) => 43
 * @example
 * roundUpToNearestHalfOrFull(42.5) => 42.5
 * @example
 * roundUpToNearestHalfOrFull(42) => 42
 */
export const roundUpToNearestHalfOrFull = (num: number) => {
  const rest = num % 1;

  if (rest === 0 || rest === 0.5) {
    return num;
  } else if (rest < 0.5) {
    return Math.floor(num) + 0.5;
  } else {
    return Math.ceil(num);
  }
};

/**
 * Rounds a given decimal number down to the nearest .5 or full value
 * @param number to be round
 * @returns rounded number
 * @example
 * roundDownToNearestHalfOrFull(42.7) => 42.5
 * @example
 * roundDownToNearestHalfOrFull(42.2) => 42
 * @example
 * roundDownToNearestHalfOrFull(42.5) => 42.5
 * @example
 * roundDownToNearestHalfOrFull(42) => 42
 */
export const roundDownToNearestHalfOrFull = (num: number) => {
  const rest = num % 1;

  if (rest === 0 || rest === 0.5) {
    return num;
  } else if (rest > 0.5) {
    return Math.floor(num) + 0.5;
  } else {
    return Math.floor(num);
  }
};
