import { useState } from "react";
import { noop } from "lodash";

import { ProductAvailability } from "../../../../commons/libs/externals/veloconnect-proxy";
import { isProductOfType } from "../../../../commons/libs/products";
import { getVeloconnectProductDetails, VeloconnectError } from "../../../../commons/libs/veloconnect-service";
import { Product, ProductId, ProductType } from "../../../../commons/specs/product";
import { VeloconnectErrorType } from "../../../../commons/types/veloconnect";

import useFetchData from "./use-fetch-data";

interface UseVeloconnectProductAvailabilitiesOptions {
  refetchDependencies?: React.DependencyList;
  onFetchSuccess?: (availabilities: ProductAvailability[] | undefined) => void;
  onFetchError?: (error: Error) => void;
}

export interface UseVeloconnectProductAvailabilitiesReturn {
  isReachable: boolean;
  isLoading: boolean;
  availabilities: ProductAvailability[] | undefined;
  refresh: () => void;
  error: VeloconnectErrorType | undefined;
  getAvailabilityByVariant: (variantId: ProductId) => ProductAvailability | undefined;
}

/** A hook that fetches the Veloconnect Product Availabilities for a given product and provides utility functions to interact with it. */
const useVeloconnectProductAvailabilities = (
  product: Product,
  {
    refetchDependencies = [],
    onFetchSuccess = noop,
    onFetchError = noop
  }: UseVeloconnectProductAvailabilitiesOptions = {}
): UseVeloconnectProductAvailabilitiesReturn => {
  // TODO: BCD-7063 Veloconnect mit CustomerGroup o. ProductType assoziieren
  const isBicycle = isProductOfType(product, ProductType.Bicycle);

  const [isReachable, setIsReachable] = useState<boolean>(false);

  const {
    data: availabilities,
    error: availabilitiesError,
    isLoading,
    refresh
  } = useFetchData(
    async () => {
      if (isBicycle) {
        return await getVeloconnectProductDetails(product);
      } else {
        return Promise.resolve([]);
      }
    },
    [...refetchDependencies, product],
    {
      onDone: productAvailabilities => {
        setIsReachable(true);
        onFetchSuccess?.(productAvailabilities);
      },
      handleError: error => {
        setIsReachable(false);
        onFetchError?.(error);
        console.error("Error fetching Veloconnect product availabilities", error);
      },
      isEnabled: isBicycle
    }
  );

  // TODO: BCD-7002 Generic Error Type Argument für `useFetchData`
  const evalError = (error: Error): VeloconnectErrorType => {
    if (error instanceof VeloconnectError) {
      return error.veloconnectError;
    } else {
      return VeloconnectErrorType.Other;
    }
  };

  const error = availabilitiesError ? evalError(availabilitiesError) : undefined;

  const getAvailabilityByVariant = (variantId: ProductId): ProductAvailability | undefined =>
    (availabilities || []).find(variant => variant.variantId === variantId);

  return {
    isReachable,
    isLoading,
    availabilities,
    refresh,
    error,
    getAvailabilityByVariant
  };
};

export default useVeloconnectProductAvailabilities;
