import { useMemo } from "react";
import { QueryKey } from "@tanstack/react-query";
import { isNumber } from "lodash";

import { getProducts, ProductsResponse } from "../../../../commons/libs/content-service";
import { getProductVariants } from "../../../../commons/libs/specs";
import { getSpecConfig } from "../../../../commons/specs";
import { ActiveFilters, ProductFilter } from "../../../../commons/specs/filters";
import { ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import { Currency } from "../../../../commons/types/currency";
import { AssortmentPriceSettings } from "../../../../commons/types/settings";
import { getProductFiltersFromActiveFilters } from "../filters";
import useQuery, { UseQueryOptions } from "../hooks/use-query";

type QueryOptions<Type extends ProductType> = Omit<UseQueryOptions<ProductsResponse<Type>>, "queryKey" | "queryFn">;

interface Arguments<Type extends ProductType> {
  productType: Type;
  currency: Currency;
  assortmentPriceSettings: AssortmentPriceSettings;
  activeFilters: ActiveFilters<Type>;
  searchTerm?: string;
  brandKey?: string;
  limit?: number;
  offset?: number;
  options?: QueryOptions<Type>;
}

const useProducts = <Type extends ProductType>({
  productType,
  currency,
  assortmentPriceSettings,
  activeFilters,
  searchTerm = "",
  brandKey = "",
  limit = undefined,
  offset = undefined,
  options = {}
}: Arguments<Type>) => {
  const specConfig = getSpecConfig(productType);
  const productFilters = getProductFiltersFromActiveFilters(activeFilters, specConfig.filterConfig);
  const evaluatedProductFilters = brandKey
    ? [...productFilters, { key: ProductSpecKey.BrandKey, value: brandKey }]
    : productFilters;

  const query = useQuery({
    ...options,
    gcTime: 0, // Don't use the frontend query cache for these requests.
    queryKey: queryKey({
      brandKey,
      currency,
      productFilters: evaluatedProductFilters,
      searchTerm,
      assortmentPriceSettings,
      limit,
      offset
    }),
    queryFn: () => {
      return getProducts<Type>(currency, assortmentPriceSettings, evaluatedProductFilters, searchTerm, limit, offset);
    }
  });

  const data = useMemo(() => query.data ?? FALLBACK_DATA, [query.data]);

  const productsLength = useMemo(() => data.results.length, [data]);
  const variantsLength = useMemo(() => data.results.flatMap(getProductVariants).length, [data]);

  return {
    ...query,
    data,
    productsLength,
    variantsLength,
    usedSearchTerm: searchTerm
  };
};

const FALLBACK_DATA: ProductsResponse = {
  results: [],
  totalFiltered: 0,
  total: 0,
  offset: null,
  limit: null
};

const queryKey = ({
  brandKey,
  currency,
  productFilters,
  searchTerm,
  assortmentPriceSettings,
  limit,
  offset
}: {
  currency: Currency;
  productFilters: ProductFilter[];
  assortmentPriceSettings: AssortmentPriceSettings;
  searchTerm?: string;
  brandKey?: string;
  limit?: number;
  offset?: number;
}): QueryKey => [
  "products",
  ...(!!brandKey ? [brandKey] : []),
  ...(!!searchTerm ? [searchTerm] : []),
  productFilters,
  currency,
  assortmentPriceSettings,
  ...(isNumber(limit) ? [`limit:${limit}`] : []),
  ...(isNumber(offset) ? [`offset:${offset}`] : [])
];

export default useProducts;
