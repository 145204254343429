import moment from "moment";
import * as R from "ramda";

import envelope from "../../commons/libs/externals/envelope";

import { ensureError } from "./error";

export type PromiseFn<T1, T2> = (arg: T1) => Promise<T2>;

export const collectPromises = <T>(promises: Array<Promise<T>>): Promise<T[]> => Promise.all(promises);

export const awaitOr =
  <T1, T2>(or: T2, promise: (arg: T1) => Promise<T2>) =>
  async (arg: T1): Promise<T2> => {
    try {
      return await promise(arg);
    } catch {
      return or;
    }
  };

export const rethrowIfNot = R.curryN(2)((isHarmlessError: (x: Error) => boolean, err: Error) => {
  if (!isHarmlessError(err)) {
    throw err;
  }
});

export const allowErrorP =
  <T>(isHarmlessError: (x: Error) => boolean, promiseFn: (...args: any[]) => Promise<T>) =>
  (...args: any[]) =>
    promiseFn(...args).catch(rethrowIfNot(isHarmlessError));

export const allowError =
  <T>(isHarmlessError: (x: Error) => boolean, fn: (...args: any[]) => T) =>
  (...args: any[]) => {
    try {
      return fn(...args);
    } catch (error) {
      rethrowIfNot(isHarmlessError, ensureError(error));
      return null;
    }
  };

export const mapPromise =
  <T1, T2>(promiseFn: PromiseFn<T1, T2>) =>
  (collection: T1[]): Promise<T2[]> =>
    Promise.all(collection.map(promiseFn));

// Map a promise generator over all values, resolve them serially one at a time and return an ordered array of results
export const mapChained =
  <T1, T2>(promiseFn: PromiseFn<T1, T2>) =>
  (collection: T1[]): Promise<T2[]> => {
    if (R.isEmpty(collection)) {
      return Promise.resolve([]);
    }
    return R.reduce(
      async (accum: Promise<T2[]>, nextArg: T1) => [...(await accum), await promiseFn(nextArg)],
      Promise.resolve([]),
      collection
    );
  };

export const filterByPromise =
  <T1>(filterGenerator: PromiseFn<T1, boolean>) =>
  async (collection: T1[]): Promise<T1[]> => {
    return R.reduce(
      async (accum: Promise<T1[]>, nextArg: T1) =>
        (await filterGenerator(nextArg).catch(R.F)) ? [...(await accum), nextArg] : accum,
      Promise.resolve([] as T1[]),
      collection
    );
  };

// Map a promise generator over all values, run no more than <concurrent> at a time, and resolve an ordered array of
// results.
export const mapChainedN =
  <T1, T2>(concurrent: number, promiseFn: PromiseFn<T1, T2>) =>
  async (inputCollection: T1[]): Promise<T2[]> => {
    const collection: Array<T1 | undefined> = Array.from(inputCollection);
    const results = new Array(collection.length);
    const nThreads = Math.min(concurrent, collection.length);

    const start = (element: number, arg: T1): Promise<T2> => {
      collection[element] = undefined;
      return promiseFn(arg)
        .then((result: T2) => done(element, result))
        .catch(() => done(element, undefined));
    };

    const done = async (element: number, result?: T2): Promise<T2> => {
      results[element] = result;
      const n = R.findIndex(x => !R.isNil(x), collection);

      if (n >= 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know for sure that this element exists.
        return start(n, collection[n]!);
      } else {
        return Promise.resolve(results[element]); // result is ignored, just to satisfy TypeScript
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know for sure that this element exists.
    await Promise.all(R.range(0, nThreads).map(n => start(n, collection[n]!)));
    return results;
  };

export const awaitCatch =
  <T1, T2>(promise: PromiseFn<T1, T2>, elsePromise: PromiseFn<T1, T2>) =>
  async (arg: T1): Promise<T2> => {
    try {
      return await promise(arg);
    } catch {
      return elsePromise(arg);
    }
  };

// From ramda cookbook
// https://github.com/ramda/ramda/wiki/Cookbook#flatten-a-nested-object-into-dot-separated-key--value-pairs
export const flattenObject = (obj: any): any => {
  const go = (innerObj: any): Array<[string, any]> => {
    return R.chain(([k, v]) => {
      if (R.type(v) === "Object" || R.type(v) === "Array") {
        return R.map(([k2, v2]) => [`${k.toString()}.${k2}`, v2], go(v));
      } else {
        return [[k, v]];
      }
    }, R.toPairs(innerObj));
  };

  return R.fromPairs(go(obj));
};

export function isFalse(value: boolean) {
  return value === false;
}

export function isTrue(value: boolean) {
  return value === true;
}

/**
 * Measure execution time of applying unary function fn to arg
 */
export const measureTime = <T1, T2>(msg: string, fn: (x: T1) => T2): ((x: T1) => T2) =>
  envelope.isProductionBuild
    ? fn
    : (arg: T1) => {
        const start = moment.now();
        const result = fn(arg);
        console.log(msg, "took", moment.now() - start, "ms");
        return result;
      };

export function isEnumValue<T extends string | number, Enum extends string | number>(enumVariable: {
  [key in T]: Enum;
}) {
  const enumValues = Object.values(enumVariable);
  return (value: unknown): value is Enum => enumValues.includes(value);
}
