import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import IconButton from "../IconButton/IconButton";
import InputField from "../InputField/InputField";
import RangeField from "../RangeField/RangeField";

interface Props extends Component {
  range: React.ReactElement<React.ComponentProps<typeof RangeField>>;
  from: React.ReactElement<React.ComponentProps<typeof InputField>>;
  to: React.ReactElement<React.ComponentProps<typeof InputField>>;
  clear?: React.ReactElement<React.ComponentProps<typeof IconButton>>;
}

const PriceFilterLayout = ({ from, to, range, clear, classNames = [] }: Props) => (
  <div className={cn("PriceFilterLayout", [], classNames)}>
    <div className="PriceFilterLayout__from">{from}</div>
    <div className="PriceFilterLayout__divider">
      <hr />
    </div>
    <div className="PriceFilterLayout__to">{to}</div>
    <div className="PriceFilterLayout__range">{range}</div>
    <div className="PriceFilterLayout__clear">{clear}</div>
  </div>
);

export default PriceFilterLayout;
