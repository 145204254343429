import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Button from "../Button/Button";

interface Props extends Component {
  children: React.ReactElement<React.ComponentProps<typeof Button>>;
  annotation: string;
  inline?: boolean;
}

const AnnotatedButton = ({ classNames = [], children, annotation, inline = false }: Props) => (
  <span className={cn("AnnotatedButton", [{ inline }], classNames)}>
    <div className="AnnotatedButton__annotation">{annotation}</div>
    {children}
  </span>
);

export default AnnotatedButton;
