import React from "react";
import { noop } from "lodash";
import { PortalWithState } from "react-portal";

import { Component } from "../../../../commons/types/component";
import { IconSmallAngleDown, IconSmallInputClear } from "../../../../resources/icons";
import cn from "../../libs/class-name";
import Icon from "../Icon/Icon";

import SelectCtaButton from "./SelectCtaButton";
import SelectMenuItem, { ClickHandler } from "./SelectMenuItem";

interface Props extends Component {
  children:
    | React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>
    | Array<React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>>;
  value?: any;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  fixed?: boolean;
  icon?: React.ReactNode;
  ctaButtons?: (closePortal: () => void) => React.ReactElement<React.ComponentProps<typeof SelectCtaButton>>;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onChange?: ClickHandler;
  onResetSelection?: ClickHandler;
  onClick?: ClickHandler;
  withResetSelectionButton?: boolean;
  showName?: boolean;
  minContentWidthForMenuItems?: boolean;
}

const SelectField = ({
  children,
  classNames = [],
  value = "",
  name = "",
  placeholder = "",
  disabled = false,
  error = false,
  fixed = false,
  icon = null,
  ctaButtons = undefined,
  onBlur = noop,
  onResetSelection = noop,
  onClick = noop,
  withResetSelectionButton = false,
  showName = false,
  minContentWidthForMenuItems = false,
  onChange
}: Props) => {
  const selectFieldRef = React.useRef<HTMLDivElement>(null);
  const arrowButtonRef = React.useRef<HTMLButtonElement>(null);
  const resetButtonRef = React.useRef<HTMLButtonElement>(null);
  const flyoutRef = React.useRef<HTMLDivElement>(null);

  const { flyoutStyle, isOpenAbove, calculateFlyoutStyle, resetFlyoutStyle } = useFlyoutStyles({
    selectFieldRef,
    flyoutRef,
    resetButtonRef,
    withResetSelectionButton,
    fixed,
    minContentWidthForMenuItems
  });

  const onFlyoutOpen = React.useCallback(() => {
    calculateFlyoutStyle();
  }, [calculateFlyoutStyle]);

  const onFlyoutClose = React.useCallback(() => {
    resetFlyoutStyle();
  }, [resetFlyoutStyle]);

  const handleFlyoutItemClick = React.useCallback(
    (value: any, closeFlyout: () => void) => {
      onChange?.(value);
      closeFlyout();
    },
    [onChange]
  );

  const label = React.useMemo(() => {
    let foundLabel: React.ReactNode = null;

    React.Children.forEach(children, (item: React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>) => {
      if (item.props.value === value) {
        foundLabel = item.props.label;
      }
    });

    return foundLabel;
  }, [children, value]);

  return (
    <PortalWithState closeOnEsc closeOnOutsideClick onOpen={onFlyoutOpen} onClose={onFlyoutClose}>
      {({ openPortal: openFlyout, closePortal: closeFlyout, isOpen: isFlyoutOpen, portal }) => [
        <div
          key={name}
          ref={selectFieldRef}
          className={cn(
            "SelectField",
            [
              {
                selected: !!value,
                disabled,
                error,
                isOpen: isFlyoutOpen,
                isOpenAbove,
                withResetSelectionButton,
                withIcon: !!icon,
                showName
              }
            ],
            classNames
          )}
          onClick={onClick}
        >
          <button
            key="button"
            name={name}
            ref={arrowButtonRef}
            type="button"
            className="SelectField__button"
            onClick={event => (isFlyoutOpen ? closeFlyout() : openFlyout(event))}
            onBlur={onBlur}
            disabled={disabled}
          >
            {icon && <span className="SelectField__icon">{icon}</span>}
            {value ? label : <span className="SelectField__placeholder">{placeholder}</span>}
            <span className="SelectField__arrow">
              <Icon source={IconSmallAngleDown} />
            </span>
          </button>

          {showName && <div className="SelectField__name">{name}</div>}

          {withResetSelectionButton && (
            <button ref={resetButtonRef} onClick={onResetSelection} className="SelectField__reset-button" type="button">
              <Icon source={IconSmallInputClear} />
            </button>
          )}
        </div>,
        portal(
          <div
            key="flyout"
            ref={flyoutRef}
            style={flyoutStyle}
            className={cn("SelectField__flyout", [{ fixed, isOpenAbove }])}
          >
            {ctaButtons && <div className="SelectField__ctaButtons">{ctaButtons(closeFlyout)}</div>}
            <ul className="SelectField__menu">
              {React.Children.map(
                children,
                (item: React.ReactElement<React.ComponentProps<typeof SelectMenuItem>>, index) => (
                  <li key={index}>
                    {React.cloneElement(item, {
                      onClick: (v: any) => handleFlyoutItemClick(v, closeFlyout),
                      selected: value && item.props.value === value
                    })}
                  </li>
                )
              )}
            </ul>
          </div>
        )
      ]}
    </PortalWithState>
  );
};

const useFlyoutStyles = ({
  selectFieldRef,
  flyoutRef,
  resetButtonRef,
  withResetSelectionButton,
  minContentWidthForMenuItems,
  fixed
}: {
  selectFieldRef: React.RefObject<HTMLDivElement>;
  flyoutRef: React.RefObject<HTMLDivElement>;
  resetButtonRef: React.RefObject<HTMLButtonElement>;
  fixed: boolean;
  withResetSelectionButton: boolean;
  minContentWidthForMenuItems: boolean;
}) => {
  const [flyoutStyle, setFlyoutStyle] = React.useState<React.CSSProperties | undefined>(undefined);
  const [isOpenAbove, setIsOpenAbove] = React.useState<boolean>(false);

  const calculateFlyoutStyle = React.useCallback(() => {
    if (selectFieldRef.current && flyoutRef.current) {
      const flyoutSize = flyoutRef.current.getBoundingClientRect();
      const selectSize = selectFieldRef.current.getBoundingClientRect();
      const resetButtonSize = resetButtonRef.current?.getBoundingClientRect();

      /**
       * Avoid issues for calculating the position, by keeping the responsibility for sizes & spacings for the flyout menu in here.
       * We define a similar spacing value here ($space-xxs) and use it to calculate the flyout position as a whole.
       * */
      const spaceXXS = 10;

      const width =
        withResetSelectionButton && resetButtonSize ? selectSize.width - resetButtonSize.width : selectSize.width;

      const shouldOpenAbove = selectSize.top + selectSize.height + flyoutSize.height > window.innerHeight;

      setIsOpenAbove(shouldOpenAbove);

      setFlyoutStyle({
        top: shouldOpenAbove
          ? selectSize.top - (flyoutSize.height + spaceXXS) + (fixed ? 0 : window.scrollY)
          : selectSize.top + (selectSize.height + spaceXXS) + (fixed ? 0 : window.scrollY),
        left: selectSize.left + (fixed ? 0 : window.scrollX),
        ...(minContentWidthForMenuItems ? { minWidth: width } : { width })
      });
    }
  }, [fixed, minContentWidthForMenuItems, withResetSelectionButton, selectFieldRef, flyoutRef, resetButtonRef]);

  const resetFlyoutStyle = React.useCallback(() => {
    setFlyoutStyle(undefined);
    setIsOpenAbove(false);
  }, []);

  return { flyoutStyle, isOpenAbove, calculateFlyoutStyle, resetFlyoutStyle };
};

export default SelectField;
