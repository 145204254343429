import { Product, ProductKey, ProductType } from "../specs/product";

export const isProductOfType = <T1 extends ProductType, T2 extends T1>(
  product: Product<T1>,
  productType: T2
): product is Product<T2> => {
  return product[ProductKey.ProductType] === productType;
};

export const allProductsOfSameType = (products: Product[]): boolean => {
  const firstType = products[0][ProductKey.ProductType];
  return products.every((product: Product) => product[ProductKey.ProductType] === firstType);
};

export const allProductsOfType = <T1 extends ProductType, T2 extends T1>(
  products: Product<T1>[],
  productType: T2
): products is Product<T2>[] => products.every((product: Product<T1>) => isProductOfType(product, productType));
