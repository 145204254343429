import { ManualAssortmentItem } from "../../../commons/types/assortment";
import { PackageInfo } from "../../../commons/types/content";
import { Env } from "../../../commons/types/env";
import { Action } from "../../../commons/types/redux";

export type InitCoreDoneAction = Action<"CORE.INIT_CORE_DONE">;
export type InitAppDoneAction = Action<"CORE.INIT_APP_DONE">;
export type SetupDoneAction = Action<"CORE.SETUP_DONE">;
export type ContentPackagesReceivedAction = Action<"CORE.CONTENT_PACKAGES_RECEIVED", PackageInfo[]>;

export type EnvReceivedAction = Action<"CORE.ENV_RECEIVED", Env>;
export type InteractAction = Action<"CORE.INTERACT">;
export type AddManualAssortmentAction = Action<"CORE.MANUAL_ASSORTMENT_ADD", ManualAssortmentItem[]>;
export type DeleteManualAssortmentAction = Action<"CORE.MANUAL_ASSORTMENT_DELETE", ManualAssortmentItem[]>;
export type ManualAssortmentReceivedAction = Action<"CORE.MANUAL_ASSORTMENT_RECEIVED", ManualAssortmentItem[]>;

export type CoreActions =
  | InitCoreDoneAction
  | InitAppDoneAction
  | ContentPackagesReceivedAction
  | EnvReceivedAction
  | InteractAction
  | AddManualAssortmentAction
  | DeleteManualAssortmentAction
  | ManualAssortmentReceivedAction;

export const initCoreDone = (): InitCoreDoneAction => ({
  type: "CORE.INIT_CORE_DONE"
});

export const initAppDone = (): InitAppDoneAction => ({
  type: "CORE.INIT_APP_DONE"
});

export const contentPackagesReceived = (payload: PackageInfo[]): ContentPackagesReceivedAction => ({
  type: "CORE.CONTENT_PACKAGES_RECEIVED",
  payload
});

export const envReceived = (env: Env): EnvReceivedAction => ({
  type: "CORE.ENV_RECEIVED",
  payload: env
});

export const addManualAssortment = (payload: ManualAssortmentItem[]): AddManualAssortmentAction => ({
  type: "CORE.MANUAL_ASSORTMENT_ADD",
  payload
});

export const deleteManualAssortment = (payload: ManualAssortmentItem[]): DeleteManualAssortmentAction => ({
  type: "CORE.MANUAL_ASSORTMENT_DELETE",
  payload
});

export const manualAssortmentReceived = (payload: ManualAssortmentItem[]): ManualAssortmentReceivedAction => ({
  type: "CORE.MANUAL_ASSORTMENT_RECEIVED",
  payload
});

export const interact = (): InteractAction => ({
  type: "CORE.INTERACT"
});
