import envelope from "../../../commons/libs/externals/envelope";

import { AuthenticationCredentials } from "./credentials";

const setCookie = (key: string, value: string) => {
  const secureFlag = envelope.isMobileServerSecure ? "Secure;" : "";
  document.cookie = `${key}=${value};SameSite=Strict;${secureFlag}`;
};

export const setLicenseCookies = ({ licenseId, licenseToken }: AuthenticationCredentials) => {
  setCookie("licenseId", licenseId);
  setCookie("licenseToken", licenseToken);
};

export const setRegistrationCookie = (registrationToken: string) => {
  setCookie("registrationToken", registrationToken);
};
