import React from "react";
import { ceil, floor, isEqual, partition } from "lodash";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { getBrandLogoUrl } from "../../../../commons/libs/resource-paths";
import { getSpecConfig } from "../../../../commons/specs";
import { BicycleSpecKey, CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import {
  ActiveFilters,
  FilterConfigConstKeys,
  FilterConfigItem,
  FilterKey,
  FilterValue,
  ProductFilterValues,
  RangeFilterValue
} from "../../../../commons/specs/filters";
import { MotorcycleSpecKey } from "../../../../commons/specs/motorcycle";
import {
  CategoryGroupDefinition,
  CategoryGroupKeyMap,
  CategoryMap,
  ProductSpecKey,
  ProductType
} from "../../../../commons/specs/product";
import * as icons from "../../../../resources/icons";
import smartfitLogoImage from "../../../../resources/images/body-sizing/smartfit-logo.svg";
import Badge from "../../components/Badge/Badge";
import CheckboxListItem from "../../components/CheckboxListItem/CheckboxListItem";
import CheckboxListItemWithIcon from "../../components/CheckboxListItemWithIcon/CheckboxListItemWithIcon";
import CheckboxListItemWithImage from "../../components/CheckboxListItemWithImage/CheckboxListItemWithImage";
import FilterModal from "../../components/FilterModal/FilterModal";
import FilterModalCustomContent from "../../components/FilterModal/FilterModalCustomContent";
import FilterModalModelYearValues from "../../components/FilterModal/FilterModalModelYearValues";
import FilterModalValues from "../../components/FilterModal/FilterModalValues";
import Icon from "../../components/Icon/Icon";
import Image from "../../components/Image/Image";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import RadioListItem from "../../components/RadioListItem/RadioListItem";
import TileIconButton from "../../components/TileIconButton/TileIconButton";
import config from "../../config";
import {
  addActiveFilterValue,
  clearActiveFilter,
  getActiveFilterValues,
  isActiveFilter,
  isFilterItemActive,
  removeActiveFilterValue,
  setActiveFilter
} from "../../libs/filters";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import { connect } from "../utils/loop";

import AvailabilityFilterModalPartial from "./AvailabilityFilterModalPartial";
import BodySizingFilterModalPartial from "./BodySizingFilterModalPartial";
import ForkSuspensionTravelFilterModalPartial from "./ForkSuspensionTravelFilterModalPartial";
import PriceFilterModalPartial from "./PriceFilterModalPartial";
import RangeFilterModalPartial from "./RangeFilterModalPartial";

const mapStateToProps = (state: State) => ({
  bodySizingEnabled: selectors.selectIsBodySizingEnabled(state),
  bodySizingBodyValues: state.session.bodyValues,
  currency: selectors.selectInitializedSettings(state).currency
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OuterProps<Type extends ProductType, K extends FilterKey<Type>> = {
  productType: Type;
  isProductLoading: boolean;
  isOpen: boolean;
  activeFilters: ActiveFilters<Type>;
  onFilterChange: (activeFilters: ActiveFilters<Type>) => void;
  clearFilter?: (filterKey: FilterKey<ProductType>) => void;
  filterValues: ProductFilterValues<Type, K>;
  filterKey: K;
  filterConfig: FilterConfigItem<Type, K>;
  onClose: () => void;
  alignRight?: boolean;
  variantsLength: number;
  onOpenChange: (isOpen: boolean) => void;
  highlightedBrandKeys: string[];
};

type Props<Type extends ProductType, K extends FilterKey<Type>> = ConnectedProps<typeof connector> &
  OuterProps<Type, K>;

const FilterModalPartial = <Type extends ProductType, K extends FilterKey<Type>>({
  isProductLoading,
  productType,
  isOpen = false,
  onOpenChange,
  activeFilters,
  onFilterChange,
  clearFilter,
  filterKey,
  filterValues: productFilterValues,
  filterConfig,
  onClose,
  alignRight = false,
  bodySizingBodyValues,
  bodySizingEnabled,
  variantsLength,
  highlightedBrandKeys,
  currency
}: Props<Type, K>) => {
  const { t, i18n } = useTranslation(["commons"]);

  React.useEffect(() => {
    onOpenChange(isOpen);
  }, [isOpen, onOpenChange]);

  const filterKeyString = filterKey.toString();
  const filterValues = productFilterValues[filterKey] ?? [];

  const titleKey = `commons:productFilterConfig.${productType}.productFilter.${filterKeyString}.title` as keyof {
    [T in ProductType as `commons:productFilterConfig.${T}.productFilter.${FilterConfigConstKeys<T>}.title`]: null;
  };

  const descriptionKey =
    `commons:productFilterConfig.${productType}.productFilter.${filterKeyString}.description` as keyof {
      [T in ProductType as `commons:productFilterConfig.${T}.productFilter.${FilterConfigConstKeys<T>}.description`]: null;
    };

  const title = t(titleKey);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Description is optional
  // @ts-ignore
  const description: string = i18n.exists(descriptionKey) ? t(descriptionKey) : "";

  switch (filterConfig.type) {
    case "default":
      switch (filterKey) {
        case ProductSpecKey.BrandKey: {
          const [highlightedBrands, nonHighlightedBrands] = partition(filterValues, filterValue => {
            return highlightedBrandKeys.includes(filterValue.value);
          });

          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalValues justifyContent="start">
                {[...highlightedBrands, ...nonHighlightedBrands].map(({ value: brandKey }) => {
                  const isActive = isActiveFilter(activeFilters, filterKey, brandKey);

                  const handleClick = () =>
                    onFilterChange(
                      isActive
                        ? removeActiveFilterValue(activeFilters, filterKey, brandKey)
                        : addActiveFilterValue(activeFilters, filterKey, brandKey)
                    );

                  return (
                    <CheckboxListItemWithImage
                      key={`${filterKeyString}-${brandKey}`}
                      imageUrl={getBrandLogoUrl(brandKey)}
                      imageWidth="m"
                      imageAspectRatio={21 / 9}
                      outline
                      active={isActive}
                      badge={
                        highlightedBrandKeys.includes(brandKey) ? <Icon source={icons.IconSmallHighlight} /> : undefined
                      }
                      onClick={handleClick}
                    />
                  );
                })}
              </FilterModalValues>
            </FilterModal>
          );
        }

        case ProductSpecKey.ModelYear:
          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalModelYearValues
                defaultValue={
                  <RadioListItem
                    outline
                    label={t("commons:filterModalPartialProductFinder.modelYear.latestLabel")}
                    active={R.either(R.isNil, R.isEmpty)(getActiveFilterValues(activeFilters, filterKey))}
                    onClick={() => onFilterChange(clearActiveFilter(activeFilters, filterKey))}
                  />
                }
              >
                {filterValues.map(filterValue => {
                  const active = isActiveFilter(activeFilters, filterKey, filterValue.value);
                  return (
                    <CheckboxListItem
                      key={`${filterKeyString}-${filterValue.value}`}
                      outline
                      label={filterValue.label}
                      active={active}
                      onClick={() =>
                        onFilterChange(
                          active
                            ? removeActiveFilterValue(activeFilters, filterKey, filterValue.value)
                            : addActiveFilterValue(activeFilters, filterKey, filterValue.value)
                        )
                      }
                    />
                  );
                })}
              </FilterModalModelYearValues>
            </FilterModal>
          );

        case ProductSpecKey.CategoryKey: {
          const specConfig = getSpecConfig(productType);

          const categoryGroupsWithFilterModalValues: CategoryGroupDefinition[] = specConfig.categoryGroups
            .map(({ categories, ...categoryGroupRest }) => ({
              ...categoryGroupRest,
              categories: categories.filter(category => filterValues.find(({ value }) => value === category))
            }))
            .filter(({ categories }) => categories.length > 0);

          const onClick = (isActive: boolean, value: FilterValue<Type, ProductSpecKey.CategoryKey>) => {
            const nextFilters = isActive
              ? removeActiveFilterValue(activeFilters, ProductSpecKey.CategoryKey, value)
              : addActiveFilterValue(activeFilters, ProductSpecKey.CategoryKey, value);

            onFilterChange(nextFilters as ActiveFilters<Type>);
          };

          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              {categoryGroupsWithFilterModalValues.map(({ key, categories, productType: groupProductType }) => {
                return (
                  <FilterModalValues
                    key={key}
                    title={t(
                      `commons:specs.${groupProductType}.categoryGroup.${key}` as keyof {
                        [T in ProductType as `commons:specs.${T}.categoryGroup.${CategoryGroupKeyMap[T]}`]: null;
                      }
                    )}
                    justifyContent="start"
                  >
                    {(categories as FilterValue<Type, ProductSpecKey.CategoryKey>[]).map(category => {
                      const isActive = isActiveFilter(activeFilters, ProductSpecKey.CategoryKey, category);

                      return (
                        <CheckboxListItem
                          key={`${filterKeyString}-${category}`}
                          outline
                          label={t(
                            `commons:specs.${groupProductType}.categories.${category}.default` as keyof {
                              [T in ProductType as `commons:specs.${T}.categories.${CategoryMap[T]}.default`]: null;
                            }
                          )}
                          active={isActive}
                          onClick={() => onClick(isActive, category)}
                        />
                      );
                    })}
                  </FilterModalValues>
                );
              })}
            </FilterModal>
          );
        }

        case ProductSpecKey.AutomaticAssortment: {
          const veloconnectAssortmentFilterValues =
            productFilterValues[ProductSpecKey.VeloconnectAssortment as K] ?? [];

          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              loadingIndicator={isProductLoading ? <LoadingIndicator size="s" /> : undefined}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <AvailabilityFilterModalPartial
                activeFilters={activeFilters}
                automaticAssortmentFilterValues={filterValues}
                veloconnectAssortmentFilterValues={veloconnectAssortmentFilterValues}
                onFilterChange={onFilterChange}
                clearFilter={clearFilter}
              />
            </FilterModal>
          );
        }

        case BicycleSpecKey.FrameTypeKey: {
          const frameTypeFilters = [
            {
              key: "diamond",
              icon: icons.IconFilterFrameTypeDiamond,
              value: "DIAMANT"
            },
            {
              key: "trapeze",
              icon: icons.IconFilterFrameTypeTrapezoid,
              value: "TRAPEZ"
            },
            {
              key: "wave",
              icon: icons.IconFilterFrameTypeWave,
              value: "WAVE"
            }
          ];

          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalValues>
                {frameTypeFilters.map(({ key, icon, value }) => {
                  const filterValueKey = `commons:productFilterConfig.${productType}.productFilter.${filterKeyString}.filter.${key}.title`;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const filterValueLabel: string = i18n.exists(filterValueKey) ? t(filterValueKey) : "";

                  // TODO: This partial should be rebuilt to be more generic
                  // `as` is used to cast the type to ProductType.Bicycle, because the current implementation is only used for bicycles
                  const active = isActiveFilter<ProductType.Bicycle, BicycleSpecKey.FrameTypeKey>(
                    activeFilters as ActiveFilters<ProductType.Bicycle>,
                    filterKey,
                    value
                  );

                  return (
                    <CheckboxListItemWithIcon
                      key={`${filterKeyString}-${key}`}
                      icon={icon}
                      outline
                      label={filterValueLabel}
                      active={active}
                      onClick={() =>
                        onFilterChange(
                          (active
                            ? removeActiveFilterValue<ProductType.Bicycle, BicycleSpecKey.FrameTypeKey>(
                                activeFilters as ActiveFilters<ProductType.Bicycle>,
                                filterKey,
                                value
                              )
                            : addActiveFilterValue<ProductType.Bicycle, BicycleSpecKey.FrameTypeKey>(
                                activeFilters as ActiveFilters<ProductType.Bicycle>,
                                filterKey,
                                value
                              )) as ActiveFilters<Type>
                        )
                      }
                    />
                  );
                })}
              </FilterModalValues>
            </FilterModal>
          );
        }

        // TODO Future: This should be a default filter with boolean-like labeled values from labeledValues content service api
        case ProductSpecKey.ConfiguratorUrl:
        case MotorcycleSpecKey.Abs: {
          const value = "true" as FilterValue<typeof productType, typeof filterKey>;
          const filterValueKey = `commons:productFilterConfig.${productType}.productFilter.${filterKeyString}.filter.${value}.title`;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const filterValueLabel: string = i18n.exists(filterValueKey) ? t(filterValueKey) : "";
          const active = isActiveFilter(activeFilters, filterKey, value);

          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalValues>
                <CheckboxListItem
                  key={`${filterKeyString}-${value}`}
                  outline
                  label={filterValueLabel}
                  active={active}
                  onClick={() =>
                    onFilterChange(
                      active
                        ? removeActiveFilterValue(activeFilters, filterKey, value)
                        : addActiveFilterValue(activeFilters, filterKey, value)
                    )
                  }
                />
              </FilterModalValues>
            </FilterModal>
          );
        }

        default:
          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalValues>
                {filterValues.map(filterValue => {
                  const active = isActiveFilter(activeFilters, filterKey, filterValue.value);

                  return (
                    <CheckboxListItem
                      key={`${filterKeyString}-${filterValue.value}`}
                      outline
                      label={filterValue.label}
                      active={active}
                      onClick={() =>
                        onFilterChange(
                          active
                            ? removeActiveFilterValue(activeFilters, filterKey, filterValue.value)
                            : addActiveFilterValue(activeFilters, filterKey, filterValue.value)
                        )
                      }
                    />
                  );
                })}
              </FilterModalValues>
            </FilterModal>
          );
      }

    case "range": {
      type RangeValue = FilterValue<Type, typeof filterKey>[] & RangeFilterValue;
      const rangeValue = getActiveFilterValues(activeFilters, filterKey) as RangeValue;

      switch (filterKey) {
        case ProductSpecKey.Price: {
          const roundedFromLimit = floor(filterValues[0].value, -1);
          const roundedToLimit = ceil(filterValues[filterValues.length - 1].value, -1);

          const handleOnChange = (priceRange: RangeFilterValue): void => {
            const initialPriceRange: RangeFilterValue = [roundedFromLimit, roundedToLimit];

            const priceRangeIsInitialValue: boolean = isEqual(priceRange, initialPriceRange);

            if (priceRangeIsInitialValue) {
              // to remove the active badge on the price filter FilterListItem
              onFilterChange(clearActiveFilter(activeFilters, filterKey));
            } else {
              onFilterChange(setActiveFilter(activeFilters, filterKey, priceRange as RangeValue));
            }
          };

          const filterValuesToRender: RangeFilterValue =
            rangeValue?.length === 2 ? rangeValue : [roundedFromLimit, roundedToLimit];

          return (
            <FilterModal
              alignRight={alignRight}
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalValues>
                <PriceFilterModalPartial
                  rangeLimitFrom={roundedFromLimit}
                  rangeLimitTo={roundedToLimit}
                  priceActiveFilters={filterValuesToRender}
                  onChange={handleOnChange}
                  currency={currency}
                />
              </FilterModalValues>
            </FilterModal>
          );
        }

        case BicycleSpecKey.ForkSuspensionTravel: {
          {
            const rangeFrom: number = 1;
            const rangeTo: number = 10_000;

            const forkSuspensionRangeValue: RangeFilterValue =
              rangeValue?.length === 2 ? (rangeValue as RangeFilterValue) : [rangeFrom, rangeTo];

            const handleRangeChange = (range: RangeFilterValue): void => {
              if (isEqual(range, [rangeFrom, rangeTo])) {
                onFilterChange(clearActiveFilter(activeFilters, filterKey));
              } else {
                onFilterChange(setActiveFilter(activeFilters, filterKey, range as RangeValue));
              }
            };

            if (productType === ProductType.Bicycle) {
              return (
                <FilterModal
                  alignRight={alignRight}
                  title={title}
                  description={description}
                  closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
                >
                  <ForkSuspensionTravelFilterModalPartial
                    rangeFrom={rangeFrom}
                    rangeTo={rangeTo}
                    forkSuspensionRangeValue={forkSuspensionRangeValue}
                    onChange={handleRangeChange}
                  />
                </FilterModal>
              );
            }
          }
        }

        // eslint-disable-next-line no-fallthrough -- Allow fallthrough of ForkSuspensionTravel if not a bicycle
        default: {
          let rangeMin = Math.floor(filterValues[0].value);
          let rangeMax = Math.ceil(filterValues[filterValues.length - 1].value);

          // If rangeMin and rangeMax is the same for now lets just add a bit of range on both sides to keep the RangeField interactive.
          if (rangeMin === rangeMax) {
            rangeMin = Math.floor(0.9 * rangeMin);
            rangeMax = Math.ceil(1.1 * rangeMax);
          }

          const value: number[] = rangeValue?.length === 2 ? (rangeValue as number[]) : [rangeMin, rangeMax];

          const disableRangeValue = [0, 0];
          const isDisableRangeValue = !!filterConfig.canDisable && isEqual(value, disableRangeValue);

          return (
            <FilterModal
              alignRight={alignRight}
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalValues>
                <RangeFilterModalPartial
                  disabled={isDisableRangeValue}
                  min={isDisableRangeValue ? 0 : rangeMin}
                  max={rangeMax}
                  values={[value[0], value[1]]}
                  onChange={values => onFilterChange(setActiveFilter(activeFilters, filterKey, values as RangeValue))}
                />
              </FilterModalValues>
              {filterConfig.canDisable && (
                <CheckboxListItem
                  outline
                  label={t("commons:filterModalPartialProductFinder.range.withoutFilterButton", {
                    filterName: title
                  })}
                  active={isDisableRangeValue}
                  onClick={
                    isDisableRangeValue
                      ? () =>
                          onFilterChange(setActiveFilter(activeFilters, filterKey, [rangeMin, rangeMax] as RangeValue))
                      : () => onFilterChange(setActiveFilter(activeFilters, filterKey, disableRangeValue as RangeValue))
                  }
                />
              )}
            </FilterModal>
          );
        }
      }
    }

    case "custom": {
      switch (filterKey) {
        case CustomBicycleFilterKey.Carrier: {
          const carrierFilters = [
            {
              key: BicycleSpecKey.FrontCarrier,
              filters: [
                {
                  key: "yes",
                  value: "true"
                },
                {
                  key: "no",
                  value: "false"
                }
              ]
            },
            {
              key: BicycleSpecKey.Carrier,
              filters: [
                {
                  key: "yes",
                  value: "true"
                },
                {
                  key: "no",
                  value: "false"
                }
              ]
            }
          ] as const;

          return (
            <FilterModal
              alignRight={alignRight}
              limitHeight
              title={title}
              description={description}
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              {carrierFilters.map(({ filters, key }) => {
                // TODO: This partial should be rebuilt to be more generic
                // `as` is used to cast the type to ProductType.Bicycle, because the current implementation is only used for bicycles
                const [[activeFilterValue], otherFilterValues] = partition(
                  getActiveFilterValues<ProductType.Bicycle, CustomBicycleFilterKey.Carrier>(
                    activeFilters as ActiveFilters<ProductType.Bicycle>,
                    filterKey
                  ),
                  value => value.startsWith(key)
                );

                const unsetValue = !activeFilterValue;

                const filterGroupTitle = t(
                  `commons:productFilterConfig.${ProductType.Bicycle}.productFilter.${CustomBicycleFilterKey.Carrier}.filter.${key}.title`
                );

                return (
                  <FilterModalValues key={key} title={filterGroupTitle}>
                    <RadioListItem
                      key={`${key}-item`}
                      outline
                      label={t("commons:filterModalPartialProductFinder.carrier.noMatterLabel")}
                      active={unsetValue}
                      onClick={() =>
                        onFilterChange(
                          removeActiveFilterValue<ProductType.Bicycle, CustomBicycleFilterKey.Carrier>(
                            activeFilters as ActiveFilters<ProductType.Bicycle>,
                            filterKey,
                            activeFilterValue
                          ) as ActiveFilters<Type>
                        )
                      }
                    />
                    {filters.map(({ key: filterItemKey, value: filterItemValue }) => {
                      const value = `${key}-${filterItemValue}`;

                      const active = isActiveFilter<ProductType.Bicycle, CustomBicycleFilterKey.Carrier>(
                        activeFilters as ActiveFilters<ProductType.Bicycle>,
                        filterKey,
                        value
                      );

                      const filterValueLabel = t(
                        `commons:productFilterConfig.${ProductType.Bicycle}.productFilter.${CustomBicycleFilterKey.Carrier}.filter.${key}.filter.${filterItemKey}`
                      );

                      return (
                        <RadioListItem
                          key={`${key}-${filterItemKey}`}
                          outline
                          label={filterValueLabel}
                          active={active}
                          onClick={() =>
                            onFilterChange(
                              setActiveFilter<ProductType.Bicycle, CustomBicycleFilterKey.Carrier>(
                                activeFilters as ActiveFilters<ProductType.Bicycle>,
                                filterKey,
                                [value, ...otherFilterValues]
                              ) as ActiveFilters<Type>
                            )
                          }
                        />
                      );
                    })}
                  </FilterModalValues>
                );
              })}
            </FilterModal>
          );
        }

        case CustomBicycleFilterKey.Sizing: {
          // TODO: This partial should be rebuilt to be more generic
          // `as` is used to cast the type to ProductType.Bicycle, because the current implementation is only used for bicycles
          const isFilterActive = !!isFilterItemActive<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
            CustomBicycleFilterKey.Sizing,
            filterConfig as FilterConfigItem<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>,
            activeFilters as ActiveFilters<ProductType.Bicycle>
          );
          const isLimitReached = variantsLength > config.shared.filter.sizingLimit;

          const showSizingWarning = isFilterActive && isLimitReached;
          const showSetFilterButton =
            !!bodySizingBodyValues &&
            !isActiveFilter<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
              activeFilters as ActiveFilters<ProductType.Bicycle>,
              CustomBicycleFilterKey.Sizing
            );

          return (
            <FilterModal
              alignRight={alignRight}
              title={title}
              badge={
                !bodySizingEnabled ? <Badge size="s" variant="accent" label={t("commons:defaults.addOn")} /> : undefined
              }
              description={
                <>
                  <Image originalSize src={smartfitLogoImage} classNames={["u-space-top-s"]} />
                  {description && (
                    <>
                      <br />
                      {description}
                    </>
                  )}
                </>
              }
              closeButton={<TileIconButton icon={<Icon source={icons.IconSmallCross} />} onClick={onClose} />}
            >
              <FilterModalCustomContent>
                <BodySizingFilterModalPartial
                  onClose={onClose}
                  activeFilters={activeFilters as ActiveFilters<ProductType.Bicycle>}
                  showSizingWarning={showSizingWarning}
                  showSetFilterButton={showSetFilterButton}
                />
              </FilterModalCustomContent>
            </FilterModal>
          );
        }

        default:
          return null;
      }
    }

    default:
      return null;
  }
};

export default connector(FilterModalPartial);
