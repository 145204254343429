import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type TextAlign = "inherit" | "left";

type Size = "base" | "xs" | "s" | "l";

interface Props extends Component {
  children: React.ReactNode;
  kind?: "inherit" | "support";
  textAlign?: TextAlign;
  size?: Size;
}

const Paragraph = ({ classNames = [], children, kind = "inherit", size = "base", textAlign = "inherit" }: Props) => (
  <p
    className={cn(
      "Paragraph",
      [
        {
          [`kind-${kind}`]: kind,
          [`size-${size}`]: size,
          [`text-align-${textAlign}`]: textAlign
        }
      ],
      classNames
    )}
  >
    {children}
  </p>
);

export default Paragraph;
