import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  num: number;
}

const NumberIndicator = ({ classNames = [], num }: Props) => (
  <span className={cn("NumberIndicator", [], classNames)}>{num}</span>
);

export default NumberIndicator;
