import React from "react";

import { AvailabilityType } from "../../../../commons/types/availability";
import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  kind?: AvailabilityType;
  label?: string;
  block?: boolean;
}

const Availability = ({ classNames = [], kind = AvailabilityType.Full, label = "", block = false }: Props) => (
  <span className={cn("Availability", [{ [`kind-${kind}`]: kind, block }], classNames)}>
    {!!label && <span className="Availability__label">{label}</span>}
  </span>
);

export default Availability;
