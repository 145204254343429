import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

interface Props extends Component {
  children: React.ReactNode;
  loaded?: boolean;
}

const PrintPreview = ({ classNames = [], children, loaded = false }: Props) => {
  return (
    <div className={cn("PrintPreview", [{ loaded }], classNames)}>
      {children}
      {!loaded && <LoadingIndicator size="s" />}
    </div>
  );
};

export default PrintPreview;
