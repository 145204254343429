import { useMemo, useRef } from "react";
import { debounce, DebouncedFunc } from "lodash";

const useDebouncedCallback = <T>(
  callback: (args: T) => void,
  delay: number,
  debounceOptions: Parameters<typeof debounce>[2] = {}
): DebouncedFunc<(args: T) => void> => {
  // Store the callback and debounceOptions in a ref, so we dont create a new debounced function on every renders
  const callbackRef = useRef(callback);
  // Update the callbackRef.current value on every render (in case the callback changes)
  callbackRef.current = callback;

  // Store the debounceOptions in a ref, so we dont create a new debounced function on every renders
  const debounceOptionsRef = useRef(debounceOptions);

  // useMemo to avoid re-creating the debounced function on every render
  const debouncedCallback = useMemo(() => {
    const callbackHandler: typeof callback = (...args) => callbackRef?.current(...args);
    return debounce(callbackHandler, delay, debounceOptionsRef?.current);
  }, [delay]);

  return debouncedCallback;
};

export default useDebouncedCallback;
