import React from "react";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { NavigationConfigId } from "../../../../commons/config/customer-groups";
import { getCustomerGroupConfig } from "../../../../commons/libs/config";
import * as icons from "../../../../resources/icons";
import CenteredContent from "../../../commons/components/CenteredContent/CenteredContent";
import Headline from "../../../commons/components/Headline/Headline";
import Icon from "../../../commons/components/Icon/Icon";
import MainContentLayout from "../../../commons/components/MainContentLayout/MainContentLayout";
import MainNavigationTile from "../../../commons/components/MainNavigationTile/MainNavigationTile";
import TileGrid from "../../../commons/components/TileGrid/TileGrid";
import AppLogoPartial from "../../../commons/container/App/AppLogoPartial";
import { connect } from "../../../commons/container/utils/loop";
import getBikeCenterName from "../../../commons/libs/bike-center-name";
import { getWallpaperConfig } from "../../../commons/libs/config";
import { ROUTES } from "../../../commons/routes";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";

const productOverviewIconMap = {
  [NavigationConfigId.Bicycle]: icons.IconLargeBike,
  [NavigationConfigId.Motorcycle]: icons.IconLargeMotorbike
};

const mapStateToProps = (state: State) => {
  const customerGroup = getCustomerGroupConfig(selectors.selectInitializedEnv(state).customerGroup);

  return {
    customization: selectors.selectInitializedSettings(state).customization,
    wallpaper: getWallpaperConfig(selectors.selectInitializedSettings(state).customization.wallpaper, customerGroup),
    customerGroup
  };
};

const mapDispatchToProps = {
  onPush: push
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const MainNavigationPage = ({ customization, wallpaper, customerGroup, onPush }: Props) => {
  const { t } = useTranslation(["mobile"]);

  const handleLinkClick = (event: React.MouseEvent<HTMLElement>, href: string) => {
    onPush(href);
  };

  return (
    <MainContentLayout
      layout="full"
      headerRight={<AppLogoPartial />}
      backgroundImage={wallpaper.file}
      hideHeaderBackground={wallpaper.shouldHideMainNavigationHeaderBackground}
    >
      <CenteredContent>
        <TileGrid
          columns={1}
          shadow
          hideShadow={wallpaper.shouldHideTileGridShadow}
          slideIn
          header={
            <Headline variant={wallpaper.associatedVariant} kind="hero" fitText>
              {getBikeCenterName(customization)}
            </Headline>
          }
        >
          <MainNavigationTile
            href={ROUTES.PRODUCT_FINDER_OVERVIEW.INDEX}
            icon={<Icon source={productOverviewIconMap[customerGroup.navigation]} />}
            label={t("mobile:mainNavigationPageNavigation.navigationItems.findBikes")}
            onClick={handleLinkClick}
            transparent={wallpaper.shouldMainNavigationTilesBeTransparent}
          />
        </TileGrid>
      </CenteredContent>
    </MainContentLayout>
  );
};

export default connector(MainNavigationPage);
