import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useTouchState from "../../libs/hooks/use-touch-state";

interface Props extends Component {
  label: string;
  href?: string;
  active?: boolean;
  notification?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>, href: string) => void;
  onTouchStart?: React.TouchEventHandler<HTMLElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLElement>;
}

const TabLink = ({
  classNames = [],
  label,
  href = "#",
  active = false,
  notification = null,
  onClick = noop,
  onTouchStart: onTouchStartOuter,
  onTouchEnd
}: Props) => {
  const { onTouchStart, touched } = useTouchState(onTouchStartOuter);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick(event, href);
  };

  return (
    <a
      className={cn("TabLink", [{ touched, active }], classNames)}
      href={href}
      onClick={handleClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <div className="TabLink__content">
        <span className="TabLink__label" data-label={label}>
          {label}
        </span>
        {notification && <div className="TabLink__notification">{notification}</div>}
      </div>
      <span className="TabLink__border" />
    </a>
  );
};

export default TabLink;
