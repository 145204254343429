import { Action } from "../../../commons/types/redux";

export type InitAction = Action<"MOBILE_APP.INIT">;

export type HasEnteredThroughDeepLinkAction = Action<
  "MOBILE_APP.SET_HAS_ENTERED_THROUGH_DEEP_LINK",
  { hasEnteredThroughDeepLink: boolean }
>;

export type MobileAppActions = InitAction | HasEnteredThroughDeepLinkAction;

export const init = (): InitAction => ({
  type: "MOBILE_APP.INIT"
});

export const setHasEnteredThroughDeepLink = (
  hasEnteredThroughDeepLink: boolean = false
): HasEnteredThroughDeepLinkAction => ({
  type: "MOBILE_APP.SET_HAS_ENTERED_THROUGH_DEEP_LINK",
  payload: {
    hasEnteredThroughDeepLink
  }
});
