import React from "react";
import { detect } from "detect-browser";
import { useTranslation } from "react-i18next";

import { IconLargeWarning } from "../../../../resources/icons";
import Headline from "../../../commons/components/Headline/Headline";
import Icon from "../../../commons/components/Icon/Icon";
import Paragraph from "../../../commons/components/Paragraph/Paragraph";
import Text from "../../../commons/components/Text/Text";
import WarningOverlayLayout from "../../../commons/components/WarningOverlayLayout/WarningOverlayLayout";
import config from "../../../commons/config";
import LogoPartial from "../../../commons/container/App/LogoPartial";
import { isBrowserInfo, isBrowserValid } from "../../libs/browser";

enum WarningType {
  Browser,
  ScreenSize
}

const ClientWarningPartial = () => {
  const { t } = useTranslation(["mobile"]);
  const [warningType, setWarningType] = React.useState<WarningType | undefined>();

  const headlineMap: { [key in WarningType]: string } = {
    [WarningType.Browser]: t("mobile:clientWarningOverlayApp.incompatibleBrowserHeadline"),
    [WarningType.ScreenSize]: t("mobile:clientWarningOverlayApp.incompatibleScreenSizeHeadline")
  };

  React.useEffect(() => {
    const browserInfo = detect(navigator.userAgent);

    const checkScreenSize = () => {
      const isScreenHeightTooLow = (window.innerHeight || screen.height) < config.mobile.minHeight;
      const isScreenWidthTooLow = (window.innerWidth || screen.width) < config.mobile.minWidth;
      const isScreenTooSmall = isScreenHeightTooLow || isScreenWidthTooLow;

      if (!isScreenTooSmall) {
        window.removeEventListener("resize", checkScreenSize);
      }

      setWarningType(isScreenTooSmall ? WarningType.ScreenSize : undefined);
    };

    if (!isBrowserInfo(browserInfo) || !isBrowserValid(browserInfo, navigator.userAgent)) {
      setWarningType(WarningType.Browser);
    } else {
      window.addEventListener("resize", checkScreenSize);

      checkScreenSize();

      return () => {
        window.removeEventListener("resize", checkScreenSize);
      };
    }
  }, []);

  return warningType !== undefined && Object.values(WarningType).includes(warningType) ? (
    <WarningOverlayLayout
      logo={<LogoPartial />}
      headline={<Headline kind="m">{headlineMap[warningType]}</Headline>}
      icon={<Icon source={IconLargeWarning} />}
      description={
        <Text>
          <Paragraph size="l">
            <strong>{headlineMap[warningType]}</strong>
            <br />
            {t("mobile:clientWarningOverlayApp.generalDescription")}
          </Paragraph>
          {warningType === WarningType.ScreenSize && (
            <>
              <Paragraph textAlign="left" size="l">
                <br />
                <strong>{t("mobile:clientWarningOverlayApp.screenSizeWarningHeadline")}</strong>
              </Paragraph>
              <ul>
                <li>
                  <Paragraph textAlign="left" size="l">
                    {t("mobile:clientWarningOverlayApp.screenSizeWarningSidebarSolution")}
                  </Paragraph>
                </li>
                <li>
                  <Paragraph textAlign="left" size="l">
                    {t("mobile:clientWarningOverlayApp.screenSizeWarningSplitScreenSolution")}
                  </Paragraph>
                </li>
              </ul>
            </>
          )}
        </Text>
      }
    />
  ) : null;
};

export default ClientWarningPartial;
