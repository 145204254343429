import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Image from "../Image/Image";

interface Props extends Component {
  description: string;
  brands: { key: string; logoSrc: string }[];
}

const FilterModalBrandsGrid = ({ classNames = [], description, brands }: Props) => {
  return (
    <div className={cn("FilterModalBrandsGrid", [], classNames)}>
      <div className="FilterModalBrandsGrid__description">{description}</div>
      <div className="FilterModalBrandsGrid__brands">
        {brands.map(brand => (
          <Image key={brand.key} src={brand.logoSrc} />
        ))}
      </div>
    </div>
  );
};

export default FilterModalBrandsGrid;
