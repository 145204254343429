import { isLocaleInAssociatedLocales } from "../../../commons/libs/locale";
import { Brand } from "../../../commons/types/brand";
import { BrandWorldPackageInfo, PackageInfo, PackageType } from "../../../commons/types/content";
import { Locale } from "../../../commons/types/localization";

import { getLatestAvailableModelYear } from "./brand";

export const isBrandWorldPackage = (pkg: PackageInfo): pkg is BrandWorldPackageInfo =>
  pkg.type === PackageType.BrandWorld;

export const filterBrandWorldPackages = (contentPackages: PackageInfo[]) => contentPackages.filter(isBrandWorldPackage);

export const getBrandWorldPackageInfo = (
  brandKey: string,
  brandWorldPackages: BrandWorldPackageInfo[]
): BrandWorldPackageInfo | null => {
  const possibleBrandWorlds = brandWorldPackages.filter(pkg => pkg.associated.brands.includes(brandKey));

  if (possibleBrandWorlds && possibleBrandWorlds.length > 0) {
    // By convention brandkey-brand-world is considered to be the most explicit
    const explicitBrandWorldByName = possibleBrandWorlds.find(world => world.name.startsWith(brandKey));
    // A brand world with just a single packageGroup is considered to be explicit
    const explicitBrandWorld = possibleBrandWorlds.find(
      world => world.associated.brands.length === 1 && world.associated.brands.includes(brandKey)
    );
    // Example would be: pexco-brand-world with packageGroups ["husqvarna-bicycles", "raymon"]
    const fallbackBrandWorld = possibleBrandWorlds.find(world => world.associated.brands.includes(brandKey));

    const mostReasonableBrandWorld = explicitBrandWorldByName || explicitBrandWorld || fallbackBrandWorld;
    return mostReasonableBrandWorld ?? null;
  }

  return null;
};

export const hasBrandWorld = (
  brand: Brand,
  modelYear: number,
  brandWorldPackages: BrandWorldPackageInfo[],
  locale: Locale
): boolean => {
  const brandWorld = getBrandWorldPackageInfo(brand.key, brandWorldPackages);
  // Only show the brand world if most recent model year is selected
  const isModelYearCurrentYear = modelYear === getLatestAvailableModelYear(brand);
  // Only show the brand world if it is visible for the current locale
  const isVisibleForLocale = isLocaleInAssociatedLocales(brandWorld?.associated?.locales, locale);

  return !!brandWorld?.name && isModelYearCurrentYear && isVisibleForLocale;
};
