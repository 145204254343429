import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const Text = ({ classNames = [], children }: Props) => (
  <section className={cn("Text", [], classNames)}>{children}</section>
);

export default Text;
