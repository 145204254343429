import {
  deleteFromService,
  getFromService,
  postFormToService,
  postToService
} from "../../../../../commons/libs/client-utils";
import { PdfMetaData } from "../../../../../commons/types/pdf";

export const getPdf = (id: string): Promise<PdfMetaData> => getFromService("pdf", id);

export const deletePdf = (id: string) => deleteFromService("pdf", id);

export const reset = () => deleteFromService("pdf", "");

export const getPdfMetadataFromUrl = (url: string): Promise<PdfMetaData> => postToService("pdf", "/", { url });

export const getPdfMetadataFromBuffer = (file: Buffer): Promise<PdfMetaData> => {
  const formData = new FormData();
  formData.append("file", new Blob([file], { type: "application/pdf" }), "original.pdf");

  return postFormToService("pdf", "/", formData);
};
