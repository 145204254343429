import React from "react";
import { sum } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

import TableHeaderCell from "./TableHeaderCell";
import TableRow from "./TableRow";

interface Props extends Component {
  children:
    | React.ReactElement<React.ComponentProps<typeof TableRow>>
    | Array<React.ReactElement<React.ComponentProps<typeof TableRow>>>;
  header:
    | React.ReactElement<React.ComponentProps<typeof TableHeaderCell>>
    | Array<React.ReactElement<React.ComponentProps<typeof TableHeaderCell>>>;
  dynamicSize?: boolean;
  inContent?: boolean;
  noStickyHeader?: boolean;
}

const Table = ({
  classNames = [],
  children,
  header,
  dynamicSize = false,
  inContent = false,
  noStickyHeader = false
}: Props) => {
  const widths = React.Children.map(
    header,
    ({ props: { width = 1, fixed } }: React.ReactElement<React.ComponentProps<typeof TableHeaderCell>>) =>
      fixed ? 0 : width
  );
  const fixedWidths = React.Children.map(
    header,
    ({ props: { width = 1, fixed } }: React.ReactElement<React.ComponentProps<typeof TableHeaderCell>>) =>
      fixed ? width : 0
  );
  const totalWidths = sum(widths);
  const totalFixedWidths = sum(fixedWidths);

  return (
    <div className={cn("Table", [{ dynamicSize, inContent, noStickyHeader }], classNames)}>
      <table className="Table__table">
        <thead>
          <tr className="Table__headerRow">
            {React.Children.map(header, (headCell: React.ReactElement<React.ComponentProps<typeof TableHeaderCell>>) =>
              React.cloneElement(headCell, {
                inContent,
                noStickyHeader,
                computedWidth: headCell.props.fixed
                  ? `${headCell.props.width}px`
                  : `calc(${(headCell.props.width ?? 0 / totalWidths) * 100}%${
                      totalFixedWidths ? ` - ${totalFixedWidths}px` : ""
                    })`
              })
            )}
          </tr>
        </thead>
        <tbody className="Table__body">{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
