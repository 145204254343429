import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { ConnectedProps } from "react-redux";

import { getProductImageUrl } from "../../../../commons/libs/resource-paths";
import { Product, StandaloneProductVariant } from "../../../../commons/specs/product";
import { IconSmallZoomIn } from "../../../../resources/icons";
import zoomAnimationData from "../../../../resources/images/lottie/zoom-animation.json";
import actions from "../../actions";
import CircularButton from "../../components/CircularButton/CircularButton";
import Icon from "../../components/Icon/Icon";
import ProductDetailsImage from "../../components/ProductDetailsImage/ProductDetailsImage";
import Tooltip from "../../components/Tooltip/Tooltip";
import ZoomImageModal from "../../components/ZoomImageModal/ZoomImageModal";
import { State } from "../../reducers";
import ModalContainerWithStatePartial from "../Modal/ModalContainerWithStatePartial";
import ProductTitlePartial from "../partials/ProductTitlePartial";
import { connect } from "../utils/loop";
import { useTrackingContext } from "../utils/tracking-context";

const mapStateToProps = (state: State) => ({
  showProductDetailsZoomNotice: state.session.showProductDetailsZoomNotice
});

const mapDispatchToProps = {
  hideProductDetailsZoomNotice: actions.session.hideProductDetailsZoomNotice
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps {
  product: Product;
  productVariant: StandaloneProductVariant;
  isProductContentAsideOpen?: boolean;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const ProductDetailsImagePartial = ({
  product,
  productVariant,
  showProductDetailsZoomNotice,
  hideProductDetailsZoomNotice,
  isProductContentAsideOpen = false
}: Props) => {
  const openButtonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(["commons"]);
  const { mixpanel } = useTrackingContext();

  return (
    <ModalContainerWithStatePartial
      onOpenChange={isOpen => {
        if (isOpen) {
          mixpanel?.trackProductDetailsImageZoomOpened(product);
        } else {
          mixpanel?.trackProductDetailsImageZoomClosed(product);
        }
      }}
      modal={(_isOpen, close) => (
        <ZoomImageModal
          openButton={openButtonRef.current}
          imageUrl={getProductImageUrl(productVariant)}
          notice={
            <Tooltip
              variant="accent"
              maxWidth="s"
              content={t("productDetailsImagePartialProductDetails.zoomNotice")}
              openOnMount
              isCloseable
              openOnMountDelay={300}
              shouldCloseAutomatically={false}
              onClose={hideProductDetailsZoomNotice}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: zoomAnimationData
                }}
                height={240}
                width={240}
              />
            </Tooltip>
          }
          onClose={close}
          showNotice={showProductDetailsZoomNotice}
          onHideNotice={hideProductDetailsZoomNotice}
        />
      )}
    >
      {(isOpen, open) => (
        <ProductDetailsImage
          isProductContentAsideOpen={isProductContentAsideOpen}
          imageUrl={getProductImageUrl(productVariant)}
          onClick={open}
          action={
            <CircularButton ref={openButtonRef} variant={isOpen ? "accent" : "primary"} elevated onClick={open}>
              <Icon source={IconSmallZoomIn} />
            </CircularButton>
          }
          productTitle={<ProductTitlePartial product={product} align={isProductContentAsideOpen ? "left" : "center"} />}
        />
      )}
    </ModalContainerWithStatePartial>
  );
};

export default connector(ProductDetailsImagePartial);
