import { useCallback, useRef, useState } from "react";

import useOnMount from "./use-on-mount";

const useTouchState = <T = HTMLElement>(
  onTouchStart?: React.TouchEventHandler<T>
): {
  touched: boolean;
  onTouchStart: React.TouchEventHandler<T>;
} => {
  const [touched, setTouched] = useState(false);

  const onTouchStartRef = useRef(onTouchStart);
  onTouchStartRef.current = onTouchStart;

  useOnMount(() => {
    const handler = () => {
      setTouched(false);
    };

    window.addEventListener("touchend", handler);

    return () => {
      window.removeEventListener("touchend", handler);
    };
  });

  const onTouchStartHandler = useCallback((event: React.TouchEvent<T>) => {
    setTouched(true);
    if (onTouchStartRef.current) {
      onTouchStartRef.current(event);
    }
  }, []);

  return {
    touched,
    onTouchStart: onTouchStartHandler
  };
};

export default useTouchState;
