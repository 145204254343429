import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement> | null;
}

const TableRow = React.forwardRef<HTMLTableRowElement, Props>(
  ({ classNames = [], children, selected = false, disabled = false, onClick = noop }, ref) => (
    <tr
      className={cn("TableRow", [{ clickable: onClick, selected, disabled }], classNames)}
      onClick={onClick ?? undefined}
      ref={ref}
    >
      {children}
    </tr>
  )
);

TableRow.displayName = "TableRow";

export default TableRow;
