import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

import TabLink from "./TabLink";

type TabLinksAlignment = "left" | "center";

interface Props extends Component {
  children: React.ReactNode;
  tabLinks: Array<React.ReactElement<React.ComponentProps<typeof TabLink>>>;
  tabLinksAlignment?: TabLinksAlignment;
}

const TabContent = ({ classNames = [], children, tabLinks, tabLinksAlignment = "left" }: Props) => (
  <section
    className={cn("TabContent", [{ [`tabLinksAlignment-${tabLinksAlignment}`]: tabLinksAlignment }], classNames)}
  >
    <header className="TabContent__header">{tabLinks}</header>
    <div className="TabContent__content">{children}</div>
  </section>
);

export default TabContent;
