import { intersection, mapValues } from "lodash";
import mixpanel from "mixpanel-browser";
import { Dispatch, Middleware } from "redux";

import { getActiveBrands } from "../../../../commons/libs/content-service";
import {
  getConfiguredVeloconnectEndpointBrandKeys,
  getVeloconnectEndpointBrandKeys,
  getVeloconnectEndpoints
} from "../../../../commons/libs/veloconnect-service";
import { Currency } from "../../../../commons/types/currency";
import { Env } from "../../../../commons/types/env";
import { AssortmentSettings, CustomizationSettings } from "../../../../commons/types/settings";
import { Actions } from "../../../commons/actions";
import initCommonMixpanel, { MixpanelConfig } from "../../../commons/libs/externals/mixpanel";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import { getStoredAuthenticationCredentials } from "../credentials";

interface UserProfile
  extends Required<Omit<CustomizationSettings, "dealerWebsite" | "noPinRequiredForDealerWebsite" | "wallpaper">>,
    Pick<Env, "customerGroup" | "serviceTag" | "locale"> {
  $name: Env["serviceTag"]; // Reserved Property for Mixpanel People/User profile
  licence: Env["license"];
  appVersion: string;
  currency: Currency;
  numBookmarks: number;
  printingActive: boolean;
  mailActive: boolean;
  features: string[];
  brands: string[];
  numBrands: number;
  isWawiConfigured: boolean;
  availableVeloconnectBrands?: string[];
  numAvailableVeloconnectBrands?: number;
  configuredVeloconnectBrands?: string[];
  numConfiguredVeloconnectBrands?: number;
  assortmentType: AssortmentSettings["type"];
}

const OUTDATED_USER_PROFILE_PROPS = [
  "availableVeloConnectBrands",
  "numAvailableVeloConnectBrands",
  "configuredVeloConnectBrands",
  "numConfiguredVeloConnectBrands"
];

const initMixpanel = ({ appVersion, sentry, config }: MixpanelConfig) => {
  const commonMixpanel = initCommonMixpanel({ appVersion, sentry, config });

  const trackUserProfile = async (state: State) => {
    const env = selectors.selectInitializedEnv(state);
    const settings = selectors.selectInitializedSettings(state);

    const isWawiConfigured = selectors.selectIsWawiConfigured(state);

    const serviceTag = env.serviceTag;
    const activeBrands = await getActiveBrands();
    const brands = activeBrands.map(brand => brand.key);
    const features = (Object.keys(settings.features) as (keyof typeof settings.features)[]).filter(
      key => !!settings.features[key]
    );
    const authenticationCredentials = getStoredAuthenticationCredentials();
    const {
      dealerWebsite: _dealerWebsite,
      noPinRequiredForDealerWebsite: _noPinRequiredForDealerWebsite,
      wallpaper: _wallpaper,
      ...sanitizedCustomizationSettings
    } = settings.customization;

    let configuredVeloconnectBrands: string[] | undefined = undefined;
    let availableVeloconnectBrands: string[] | undefined = undefined;
    try {
      const endpoints = await getVeloconnectEndpoints();
      configuredVeloconnectBrands = getConfiguredVeloconnectEndpointBrandKeys(endpoints);
      availableVeloconnectBrands = getVeloconnectEndpointBrandKeys(endpoints);
    } catch (error) {
      console.error("Couldn't fetch Veloconnect endpoints during trackUserProfile.", error);
      sentry.captureException(new Error("Couldn't fetch Veloconnect endpoints during trackUserProfile."));
    }

    const userProfile: UserProfile = {
      ...sanitizedCustomizationSettings,
      bikeCenterName: sanitizedCustomizationSettings.bikeCenterName ?? "",

      $name: serviceTag, // Reserved Property for Mixpanel People/User profile
      serviceTag,
      customerGroup: env.customerGroup,
      licence: env.license,
      locale: env.locale,
      appVersion,
      numBookmarks: settings.bookmarks.persistent.length,
      currency: settings.currency,
      printingActive: settings.printing.active,
      mailActive: settings.mail.active,
      features,
      brands,
      numBrands: brands.length,
      isWawiConfigured,
      assortmentType: settings.assortment.type,

      /*
       * Following properties should be set only if they are available.
       * This is to avoid setting them to null in mixpanel and overwriting the existing values.
       * These properties depend on the availability of our Veloconnect Proxy, which could be down at any time.
       */
      ...(availableVeloconnectBrands
        ? { availableVeloconnectBrands: intersection(availableVeloconnectBrands, brands) }
        : {}),
      ...(availableVeloconnectBrands
        ? { numAvailableVeloconnectBrands: intersection(availableVeloconnectBrands, brands).length }
        : {}),
      ...(configuredVeloconnectBrands ? { configuredVeloconnectBrands } : {}),
      ...(configuredVeloconnectBrands ? { numConfiguredVeloconnectBrands: configuredVeloconnectBrands.length } : {})
    };

    mixpanel.identify(env.serviceTag);
    mixpanel.alias(`${env.serviceTag}-${authenticationCredentials.licenseId}`, env.serviceTag);

    // Replace undefined values with null, to keep unset values explicitly in mixpanel
    mixpanel.people.set(mapValues(userProfile, value => (value === undefined ? null : value)));

    mixpanel.people.set_once("firstSeen", new Date());

    // Remove outdated user profile properties that have ever been set
    mixpanel.people.unset(OUTDATED_USER_PROFILE_PROPS);
  };

  const trackingMiddleware: Middleware<EmptyObject, State, Dispatch<Actions>> = store => next => (action: Actions) => {
    // Super call to common mixpanel middleware
    commonMixpanel.trackingMiddleware(store)(next)(action);

    const state = store.getState();

    switch (action.type) {
      // Init
      case "CORE.INIT_APP_DONE":
        trackUserProfile(state);
        break;

      default:
        break;
    }
  };

  return {
    ...commonMixpanel,
    trackUserProfile,
    trackingMiddleware
  };
};

export default initMixpanel;
