import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

import { Props as NumberedStepProps } from "./NumberedStep";

interface Props extends Component {
  children: React.ReactElement<NumberedStepProps>[];
  activeStep: number;
}

const NumberedSteps = ({ classNames = [], children, activeStep }: Props) => {
  const stepsCount = React.Children.count(children);
  const sanitizedActiveStep = Math.min(Math.max(activeStep, 1), stepsCount);

  const renderLine = (variant: NumberedStepProps["kind"]) => {
    switch (variant) {
      case "active":
        return <hr className="NumberedSteps__activeLine" />;

      case "complete":
        return <hr className="NumberedSteps__completeLine" />;

      default:
        return <hr className="NumberedSteps__line" />;
    }
  };

  return (
    <div className={cn("NumberedSteps", [{}], classNames)}>
      {React.Children.map(children, (child, index) => {
        const step = index + 1;
        const kind: NumberedStepProps["kind"] =
          step === sanitizedActiveStep ? "active" : step < sanitizedActiveStep ? "complete" : "default";

        return (
          <>
            {step !== 1 && renderLine(kind)}
            {React.cloneElement(child, {
              kind
            })}
          </>
        );
      })}
    </div>
  );
};

export default NumberedSteps;
