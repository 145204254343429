import { LocationChangeAction } from "connected-react-router";
import { Action } from "redux";

import { ActiveFilters } from "../../../commons/specs/filters";
import { ProductType } from "../../../commons/specs/product";
import { AvailabilityFilterLocation } from "../../../commons/types/availability";
import { GlobalLocationState } from "../../../commons/types/location";
import { SizingBodyValues } from "../../../commons/types/sizing";
import * as settingsActions from "../../commons/actions/settings";
import { Actions } from "../actions";
import * as sessionActions from "../actions/session";
import { InnerReducer, mapReducerStateWithKey } from "../libs/reducer";
import { selectInitializedSettings } from "../libs/selectors";

import { State } from ".";

const NAME = "session";

export type SessionState<Type extends ProductType = ProductType> = {
  [NAME]: {
    bodyValues: SizingBodyValues | null;
    activeFilters: ActiveFilters<Type>;
    showProductDetailsZoomNotice: boolean;
    availabilityFilter: {
      isSupplierFilterEnabled: boolean;
      availabilityLocation: AvailabilityFilterLocation;
    };
  };
};

export const initialState: SessionState = {
  [NAME]: {
    bodyValues: null,
    activeFilters: null,
    showProductDetailsZoomNotice: true,
    availabilityFilter: {
      isSupplierFilterEnabled: false,
      availabilityLocation: "dealer"
    }
  }
};

type SessionInnerReducer<A extends Action = Actions> = InnerReducer<State, SessionState[typeof NAME], A>;

const handleReset: SessionInnerReducer<sessionActions.Reset> = (state, _action) => {
  const enableSupplierAvailabilitiesFilterAutomatically =
    selectInitializedSettings(state).assortment.productFinderFilterSettings
      .enableSupplierAvailabilitiesFilterAutomatically;

  return {
    ...initialState[NAME],
    availabilityFilter: {
      ...initialState[NAME].availabilityFilter,
      isSupplierFilterEnabled: enableSupplierAvailabilitiesFilterAutomatically,
      // NOTE: If `enableSupplierAvailabilitiesFilterAutomatically` is true, we want to preselect "everywhere"
      availabilityLocation: enableSupplierAvailabilitiesFilterAutomatically ? "everywhere" : "dealer"
    }
  };
};

const handleSetActiveFilters: SessionInnerReducer<sessionActions.SetActiveFilters> = (state, action) => ({
  ...state[NAME],
  activeFilters: action.payload.activeFilters
});

const handleResetActiveFilters: SessionInnerReducer<sessionActions.ResetActiveFilters> = state => ({
  ...state[NAME],
  activeFilters: null
});

const handleSetBodyValues: SessionInnerReducer<sessionActions.SetBodyValues> = (state, action) => ({
  ...state[NAME],
  bodyValues: action.payload
});

const handleHideProductDetailsZoomNotice: SessionInnerReducer<sessionActions.HideProductDetailsZoomNotice> = state => ({
  ...state[NAME],
  showProductDetailsZoomNotice: false
});

const handleLocationChange: SessionInnerReducer<LocationChangeAction<GlobalLocationState>> = (state, action) => {
  if (
    action.payload.location.state?.activeFilters &&
    Object.keys(action.payload.location.state.activeFilters).length > 0
  ) {
    return {
      ...state[NAME],
      activeFilters: action.payload.location.state.activeFilters
    };
  }

  return state[NAME];
};

const handleSetIsSupplierFilterEnabled: SessionInnerReducer<sessionActions.SetIsSupplierFilterEnabled> = (
  state,
  action
) => ({
  ...state[NAME],
  availabilityFilter: {
    ...state[NAME].availabilityFilter,
    isSupplierFilterEnabled: action.payload
  }
});

const handleSetAvailabilityFilterLocation: SessionInnerReducer<sessionActions.SetAvailabilityFilterLocation> = (
  state,
  action
) => ({
  ...state[NAME],
  availabilityFilter: {
    ...state[NAME].availabilityFilter,
    availabilityLocation: action.payload
  }
});

const handleReceivedSettings: SessionInnerReducer<settingsActions.ReceivedAction> = (state, _action) => {
  const enableSupplierAvailabilitiesFilterAutomatically =
    selectInitializedSettings(state).assortment.productFinderFilterSettings
      .enableSupplierAvailabilitiesFilterAutomatically;

  return {
    ...initialState[NAME],
    availabilityFilter: {
      ...state[NAME].availabilityFilter,
      isSupplierFilterEnabled: enableSupplierAvailabilitiesFilterAutomatically,
      availabilityLocation: enableSupplierAvailabilitiesFilterAutomatically ? "everywhere" : "dealer"
    }
  };
};

const reducer: InnerReducer<State, SessionState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "SESSION.RESET":
      return handleReset(state, action);
    case "SESSION.SET_ACTIVE_FILTERS":
      return handleSetActiveFilters(state, action);
    case "SESSION.RESET_ACTIVE_FILTERS":
      return handleResetActiveFilters(state, action);
    case "SESSION.SET_BODY_VALUES":
      return handleSetBodyValues(state, action);
    case "SESSION.HIDE_PRODUCT_DETAILS_ZOOM_NOTICE":
      return handleHideProductDetailsZoomNotice(state, action);
    case "SESSION.SET_IS_SUPPLIER_FILTER_ENABLED":
      return handleSetIsSupplierFilterEnabled(state, action);
    case "SESSION.SET_AVAILABILITY_FILTER_LOCATION":
      return handleSetAvailabilityFilterLocation(state, action);
    case "SETTINGS.RECEIVED":
      return handleReceivedSettings(state, action);
    case "@@router/LOCATION_CHANGE":
      return handleLocationChange(state, action as LocationChangeAction<GlobalLocationState>);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
