import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import ForkSuspension from "../ForkSuspension/ForkSuspension";
import RadioListItem from "../RadioListItem/RadioListItem";

export type ForkSuspensionFilterElement = React.ReactElement<React.ComponentProps<typeof RadioListItem>>;

interface Props extends Component {
  filters: ForkSuspensionFilterElement[];
  allFilter: ForkSuspensionFilterElement;
  forkSuspension: React.ReactElement<React.ComponentProps<typeof ForkSuspension>>;
}

const ForkSuspensionFilterLayout = ({ filters, allFilter, forkSuspension, classNames = [] }: Props) => (
  <div className={cn("ForkSuspensionFilterLayout", [], classNames)}>
    <div className="ForkSuspensionFilterLayout__filters">{filters}</div>

    <div className="ForkSuspensionFilterLayout__filter-all">{allFilter}</div>

    <hr className="ForkSuspensionFilterLayout__divider" />

    <div className="ForkSuspensionFilterLayout__illustration">{forkSuspension}</div>
  </div>
);

export default ForkSuspensionFilterLayout;
