import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  footer: React.ReactNode;
}

const FlexFooterLayout = ({ classNames = [], children, footer }: Props) => (
  <div className={cn("FlexFooterLayout", [], classNames)}>
    <div className="FlexFooterLayout__children">{children}</div>
    <div className="FlexFooterLayout__footer">{footer}</div>
  </div>
);

export default FlexFooterLayout;
