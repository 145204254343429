import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

type ChildrenWidth = "auto" | "full";

interface Props extends Component {
  children: React.ReactNode;
  footerLeft?: React.ReactNode;
  footerRight?: React.ReactNode;
  childrenWidth?: ChildrenWidth;
}

const CenteredContent = ({
  classNames = [],
  children,
  footerLeft = null,
  footerRight = null,
  childrenWidth = "auto"
}: Props) => (
  <div className={cn("CenteredContent", [{ [`children-width-${childrenWidth}`]: childrenWidth }], classNames)}>
    <div className="CenteredContent__children">{children}</div>
    {footerLeft && <div className="CenteredContent__footerLeft">{footerLeft}</div>}
    {footerRight && <div className="CenteredContent__footerRight">{footerRight}</div>}
  </div>
);

export default CenteredContent;
