import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const ContentTile = ({ classNames = [], children }: Props) => (
  <div className={cn("ContentTile", [], classNames)}>{children}</div>
);

export default ContentTile;
