import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
}

const TabBar = ({ classNames = [], children }: Props) => (
  <ul className={cn("TabBar", [], classNames)}>
    {React.Children.map(
      children,
      (child, index) =>
        child && (
          <li className="TabBar__item" key={index}>
            {child}
          </li>
        )
    )}
  </ul>
);

export default TabBar;
