import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import useTouchState from "../../libs/hooks/use-touch-state";
import Icon from "../Icon/Icon";

type IconLinkSize = "base" | "s" | "l";

type IconPosition = "left" | "right";

type FontWeight = "regular" | "medium";

interface Props extends Component {
  children?: React.ReactNode;
  icon: React.ReactElement<React.ComponentProps<typeof Icon>>;
  iconPosition?: IconPosition;
  size?: IconLinkSize;
  fontWeight?: FontWeight;
  kind?: "default" | "support";
  variant?: "primary" | "standout";
  multiline?: boolean;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>, href: string) => void;
  onTouchStart?: React.TouchEventHandler<HTMLElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLElement>;
}

const IconLink = ({
  classNames = [],
  children = null,
  icon,
  iconPosition = "left",
  size = "base",
  fontWeight = "regular",
  kind = "default",
  variant = "primary",
  multiline = false,
  href = "#",
  onClick = noop,
  onTouchStart: onTouchStartOuter,
  onTouchEnd
}: Props) => {
  const { onTouchStart, touched } = useTouchState(onTouchStartOuter);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick(event, href);
  };

  return (
    <a
      href={href}
      onClick={handleClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      className={cn(
        "IconLink",
        [
          {
            [`size-${size}`]: size,
            [`variant-${variant}`]: variant,
            [`kind-${kind}`]: kind,
            [`icon-position-${iconPosition}`]: iconPosition,
            [`font-weight-${fontWeight}`]: fontWeight,
            touched,
            multiline
          }
        ],
        classNames
      )}
    >
      {React.cloneElement(icon, { ...(multiline ? { size: "l" } : {}) })}
      <span className="IconLink__label">{children}</span>
    </a>
  );
};

export default IconLink;
