import React from "react";
import { push } from "connected-react-router";
import { partition } from "lodash";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { getBrandLogoUrl } from "../../../../commons/libs/resource-paths";
import { ProductType } from "../../../../commons/specs/product";
import { Brand } from "../../../../commons/types/brand";
import { GlobalLocationState } from "../../../../commons/types/location";
import * as icons from "../../../../resources/icons";
import actions from "../../../commons/actions";
import BrandTile from "../../../commons/components/BrandTile/BrandTile";
import CenteredContent from "../../../commons/components/CenteredContent/CenteredContent";
import FlexLayout from "../../../commons/components/FlexLayout/FlexLayout";
import FlippableTile from "../../../commons/components/FlippableTile/FlippableTile";
import Icon from "../../../commons/components/Icon/Icon";
import Image from "../../../commons/components/Image/Image";
import TileGrid from "../../../commons/components/TileGrid/TileGrid";
import { connect } from "../../../commons/container/utils/loop";
import {
  generateBrandsGridConfig,
  getActiveModelYears,
  getLatestActiveModelYear,
  getPreviousActiveModelYear,
  shouldBeFlippableTile
} from "../../../commons/libs/brand";
import { shouldShowAllModelYears } from "../../../commons/libs/filters";
import { buildPath } from "../../../commons/libs/path";
import { useActiveBrands } from "../../../commons/libs/queries/use-active-brands";
import useProductFilter from "../../../commons/libs/queries/use-product-filter";
import { ROUTES } from "../../../commons/routes";
import { selectAssortmentFilterSettings } from "../../libs/selectors";
import { State } from "../../reducers";

const mapStateToProps = (state: State) => ({
  assortmentFilterSettings: selectAssortmentFilterSettings(state)
});

const mapDispatchToProps = {
  onError: actions.error.set,
  onPush: push
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps extends RouteComponentProps<EmptyObject, EmptyObject, GlobalLocationState> {
  productFilter: ReturnType<typeof useProductFilter>;
}

type Props = ConnectedProps<typeof connector> & OuterProps;

const BrandsContent = ({ onPush, assortmentFilterSettings, productFilter }: Props) => {
  const { t } = useTranslation(["mobile"]);

  const activeBrands = useActiveBrands();

  const showAllModelYears = shouldShowAllModelYears(
    assortmentFilterSettings.showAllModelYears,
    assortmentFilterSettings.selectAssortmentAutomatically
  );

  const goToProductFinder = async (brand: Brand, hrefToProducts: string, modelYear: number) => {
    const activeModelYearsForBrand = getActiveModelYears(brand);

    if (!showAllModelYears) {
      const isModelYearCurrentActiveYear = modelYear === getLatestActiveModelYear(brand);
      productFilter.setDefaultActiveFiltersForBrand(brand.key, {
        modelYear: isModelYearCurrentActiveYear ? [] : [modelYear]
      });
    } else {
      productFilter.setDefaultActiveFiltersForBrand(brand.key, {
        modelYear: activeModelYearsForBrand
      });
    }

    onPush(hrefToProducts);
  };

  const handleClick = (brand: Brand, hrefToProducts: string) => {
    goToProductFinder(brand, hrefToProducts, getLatestActiveModelYear(brand));
  };

  const [highlightedBrands, nonHighlightedBrands] = partition(activeBrands.query.data, "highlighted");

  const brandsGridConfig = generateBrandsGridConfig({
    brandCount: activeBrands.brandsCount,
    shouldReduceColumnCount: true
  });

  const highlightedBrandsGridConfig = generateBrandsGridConfig({
    brandCount: activeBrands.highlightedBrandsCount,
    brandsKind: "highlighted",
    shouldReduceColumnCount: true
  });

  const nonHighlightedBrandsGridConfig = generateBrandsGridConfig({
    brandCount: nonHighlightedBrands.length,
    brandsKind: "nonHighlighted",
    shouldReduceColumnCount: true
  });

  const isLargeBrandTile: boolean = activeBrands.brandsCount <= 2;

  const handleClickWithCurrentModelYear = (brand: Brand, hrefToProducts: string) => handleClick(brand, hrefToProducts);
  const handleClickWithPreviousModelYear = (brand: Brand, hrefToProducts: string) =>
    goToProductFinder(brand, hrefToProducts, getPreviousActiveModelYear(brand));

  const getHighlightedBadge = (isHighlighted?: boolean) =>
    isHighlighted ? <Icon source={icons.IconMediumHighlight} size="l" /> : undefined;

  const renderBrands = (brands: Brand<ProductType>[], brandTileHeight: "default" | "l", isHighlighted?: boolean) =>
    brands.map(brand =>
      shouldBeFlippableTile(brand, showAllModelYears) ? (
        <FlippableTile
          key={brand.key}
          height={brandTileHeight}
          frontSide={flip => (
            <BrandTile
              height={brandTileHeight}
              badge={getHighlightedBadge(isHighlighted)}
              year={getLatestActiveModelYear(brand).toString()}
              href={buildPath(ROUTES.PRODUCT_FINDER.BRAND, { brand: brand.key })}
              onClick={(_event: React.MouseEvent<HTMLElement>, hrefToProducts: string) =>
                handleClickWithCurrentModelYear(brand, hrefToProducts)
              }
              onDoubleClick={(_event: React.MouseEvent<HTMLElement>, hrefToProducts: string) =>
                handleClickWithCurrentModelYear(brand, hrefToProducts)
              }
              image={<Image multiplyBlendMode src={getBrandLogoUrl(brand.key)} />}
              isFlippable
              onFlip={flip}
            />
          )}
          backSide={flip => (
            <BrandTile
              height={brandTileHeight}
              badge={getHighlightedBadge(isHighlighted)}
              year={getPreviousActiveModelYear(brand).toString()}
              href={buildPath(ROUTES.PRODUCT_FINDER.BRAND, { brand: brand.key })}
              onClick={(_event: React.MouseEvent<HTMLElement>, hrefToProducts: string) => {
                handleClickWithPreviousModelYear(brand, hrefToProducts);
              }}
              onDoubleClick={(_event: React.MouseEvent<HTMLElement>, hrefToProducts: string) => {
                handleClickWithPreviousModelYear(brand, hrefToProducts);
              }}
              image={<Image multiplyBlendMode src={getBrandLogoUrl(brand.key)} />}
              isBackside
              isCloseable
              onClose={flip}
            />
          )}
        />
      ) : (
        <BrandTile
          height={brandTileHeight}
          badge={getHighlightedBadge(isHighlighted)}
          key={brand.key}
          year={!showAllModelYears ? getLatestActiveModelYear(brand).toString() : undefined}
          href={buildPath(ROUTES.PRODUCT_FINDER.BRAND, { brand: brand.key })}
          onClick={(_event: React.MouseEvent<HTMLElement>, hrefToProducts: string) =>
            handleClickWithCurrentModelYear(brand, hrefToProducts)
          }
          onDoubleClick={(_event: React.MouseEvent<HTMLElement>, hrefToProducts: string) =>
            handleClickWithCurrentModelYear(brand, hrefToProducts)
          }
          image={<Image multiplyBlendMode src={getBrandLogoUrl(brand.key)} />}
        />
      )
    );

  const renderTileGrids = () => {
    if (highlightedBrands.length > 0) {
      return (
        <FlexLayout direction="column" alignItems="center" gap="xxxl">
          <TileGrid size="l" columns={highlightedBrandsGridConfig.columnCount}>
            {renderBrands(highlightedBrands, "l", true)}
          </TileGrid>
          <TileGrid size="s" columns={nonHighlightedBrandsGridConfig.columnCount}>
            {renderBrands(nonHighlightedBrands, "default")}
          </TileGrid>
        </FlexLayout>
      );
    } else {
      return (
        <TileGrid columns={brandsGridConfig.columnCount} shadow={brandsGridConfig.withShadow}>
          {renderBrands(activeBrands.query.data ?? [], isLargeBrandTile ? "l" : "default")}
        </TileGrid>
      );
    }
  };

  return (
    <CenteredContent>
      {activeBrands.brandsCount > 0 ? (
        renderTileGrids()
      ) : (
        <>{t("mobile:brandsContentProductFinderOverview.noBrandsActivated")}</>
      )}
    </CenteredContent>
  );
};

export default connector(BrandsContent);
