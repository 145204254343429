import type { Browser, BrowserInfo, detect, OperatingSystem } from "detect-browser";

import config from "../../commons/config";
import { SupportedBrowser } from "../../commons/config/config-types";

const MINIMUM_BROWSER_VERSION_MAPPING: Partial<Record<Browser, number>> = {
  chrome: 103,
  firefox: 102,
  safari: 12
};

const SUPPORTED_OPERATING_SYSTEM_MAPPING: Record<SupportedBrowser, OperatingSystem> = {
  [SupportedBrowser.Android]: "Android OS",
  [SupportedBrowser.Ios]: "iOS"
};

const MINIMUM_IOS_MAJOR_VERSION = 12;
const MINIMUM_IOS_MINOR_VERSION = 1;

export const supportedOperatingSystems = config.mobile.supportedOperatingSystems.map(
  supportedOperatingSystem => SUPPORTED_OPERATING_SYSTEM_MAPPING[supportedOperatingSystem]
);

export const isIosVersionSufficient = (browserInfo: BrowserInfo, userAgent: string): boolean => {
  if (browserInfo.os !== "iOS") {
    return false;
  } else {
    const iosVersionRegex =
      /(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(?<majorIosVersion>[\d]{2,})_(?<minorIosVersion>[\d]{1,})/;
    const regExpMatchArray = userAgent.match(iosVersionRegex);

    const isSufficientByMajorAndMinor = ({ majorIosVersion, minorIosVersion }: Record<string, string>) => {
      if (parseInt(majorIosVersion) > MINIMUM_IOS_MAJOR_VERSION) {
        return true;
      } else if (parseInt(majorIosVersion) === MINIMUM_IOS_MAJOR_VERSION) {
        return parseInt(minorIosVersion) >= MINIMUM_IOS_MINOR_VERSION;
      } else {
        return false;
      }
    };

    return regExpMatchArray === null || !regExpMatchArray.groups
      ? false
      : isSufficientByMajorAndMinor(regExpMatchArray.groups);
  }
};

export const parseBrowserMajorVersion = (version: string | null): number => parseInt(String(version).split(".")[0]);

export const isBrowserInfo = (info: ReturnType<typeof detect> | null): info is BrowserInfo =>
  info?.type === "browser" ? true : false;

/**
 * Accepts any browser on a device running iOS 11 or later.
 * Otherwise it only accepts the browsers listed in the `getMinimumBrowserVersion`
 * config function
 */
export const isBrowserValid = (browserInfo: BrowserInfo, userAgent: string): boolean => {
  const minimumBrowserVersion = MINIMUM_BROWSER_VERSION_MAPPING[browserInfo.name];
  const isBrowserVersionInsufficient =
    !minimumBrowserVersion || parseBrowserMajorVersion(browserInfo.version) < minimumBrowserVersion;

  if (isBrowserVersionInsufficient && !isIosVersionSufficient(browserInfo, userAgent)) {
    return false;
  } else {
    return true;
  }
};
