import { Action } from "redux";
import { Cmd, loop } from "redux-loop";

import { getWawiConnectionInfo } from "../../../commons/libs/wawi-service";
import { WawiSettings } from "../../../commons/types/wawi";
import { Actions } from "../../commons/actions";
import * as errorActions from "../../commons/actions/error";
import * as wawiActions from "../../commons/actions/wawi";
import { InnerReducer, mapReducerStateWithKey } from "../../commons/libs/reducer";

import { State } from "./";

const NAME = "wawi";

export interface WawiState {
  [NAME]: {
    connectionInfo?: Partial<WawiSettings>;
  };
}

export const initialState: WawiState = {
  [NAME]: {
    connectionInfo: undefined
  }
};

type WawiInnerReducer<A extends Action = Actions> = InnerReducer<State, WawiState[typeof NAME], A>;

const handleFetchConnectionInfoSettings: WawiInnerReducer = (state: WawiState) =>
  loop(
    state[NAME],
    Cmd.run(getWawiConnectionInfo, {
      successActionCreator: wawiActions.receivedConnectionInfo,
      failActionCreator: errorActions.set
    })
  );

const handleReceivedConnectionInfoSettings: WawiInnerReducer<wawiActions.ReceivedConnectionInfoAction> = (
  state,
  action
) => ({
  ...state[NAME],
  connectionInfo: action.payload
});

const reducer: InnerReducer<State, WawiState[typeof NAME], Actions> = (state, action) => {
  switch (action.type) {
    case "WAWI.FETCH_CONNECTION_INFO":
      return handleFetchConnectionInfoSettings(state, action);
    case "WAWI.RECEIVED_CONNECTION_INFO":
      return handleReceivedConnectionInfoSettings(state, action);
    default:
      return state[NAME];
  }
};

export default mapReducerStateWithKey(reducer, NAME);
