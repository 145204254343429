import React from "react";
import { noop } from "lodash";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

export type Position = "left" | "center" | "right";

interface Props extends Component {
  src: string;
  fallbackSrc?: string;
  backgroundColor?: string;
  ratio?: number;
  fill?: boolean;
  lazy?: boolean;
  position?: Position;
  cover?: boolean;
  originalSize?: boolean;
  multiplyBlendMode?: boolean;
  timeout?: number;
  onClick?: () => void;
}

const Image = ({
  classNames = [],
  src,
  fallbackSrc = "",
  backgroundColor = "",
  ratio = 16 / 9,
  fill = false,
  lazy = false,
  position = "left",
  cover = false,
  originalSize = false,
  multiplyBlendMode = false,
  timeout = 4000,
  onClick = noop
}: Props) => {
  const imageRef = React.useRef<HTMLImageElement>(null);

  const [error, setError] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const handleError = () => {
    const shouldUseFallbackSrc = !error && imageRef.current && fallbackSrc && imageRef.current.src !== fallbackSrc;
    if (shouldUseFallbackSrc) {
      imageRef.current.src = fallbackSrc;
      setLoaded(false);
    } else {
      setError(true);
    }
  };

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!loaded) {
      timer = setTimeout(() => {
        setError(true);
      }, timeout);
    }

    return () => {
      clearTimeout(timer);
    };
    // The timeout should not be restartet or aborted by changing it.
    // there is no point in changing the timeout after mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const loadingStrategy = lazy ? "lazy" : "eager";

  return (
    <div
      className={cn(
        "Image",
        [
          {
            [`position-${position}`]: !!position,
            cover,
            fill,
            originalSize,
            multiplyBlendMode,
            loaded,
            error
          }
        ],
        classNames
      )}
      onClick={onClick}
      style={{
        aspectRatio: (!fill && !originalSize && `${ratio}`) || undefined,
        backgroundColor
      }}
    >
      <img
        className="Image__img"
        ref={imageRef}
        src={src}
        loading={loadingStrategy}
        onLoad={() => setLoaded(true)}
        onError={handleError}
      />
    </div>
  );
};

export default Image;
