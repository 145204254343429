import React from "react";
import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { postToService } from "../../../../commons/libs/client-utils";
import { formatUnit } from "../../../../commons/libs/formatters";
import { CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import { ProductType } from "../../../../commons/specs/product";
import { KeyboardType } from "../../../../commons/types/keyboard";
import { BodySizingModalContentProps, ModalProps } from "../../../../commons/types/modal";
import { SizingBodyAverageValues, SizingBodyValues } from "../../../../commons/types/sizing";
import * as icons from "../../../../resources/icons";
import armLengthImage from "../../../../resources/images/body-sizing/arm-length.svg";
import bodyHeightImage from "../../../../resources/images/body-sizing/body-height.svg";
import inseamImage from "../../../../resources/images/body-sizing/inseam.svg";
import gridResultsImage from "../../../../resources/images/body-sizing/results-grid.svg";
import listResultsImage from "../../../../resources/images/body-sizing/results-list.svg";
import smartfitLogo from "../../../../resources/images/body-sizing/smartfit-logo.svg";
import actions from "../../actions";
import BodySizingModalLayout from "../../components/BodySizingModalLayout/BodySizingModalLayout";
import Button from "../../components/Button/Button";
import DescriptionListCard from "../../components/DescriptionListCard/DescriptionListCard";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Headline from "../../components/Headline/Headline";
import Icon from "../../components/Icon/Icon";
import IconButton from "../../components/IconButton/IconButton";
import Image from "../../components/Image/Image";
import InputField from "../../components/InputField/InputField";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Modal from "../../components/Modal/Modal";
import NumberedStep from "../../components/NumberedSteps/NumberedStep";
import NumberedSteps from "../../components/NumberedSteps/NumberedSteps";
import Paragraph from "../../components/Paragraph/Paragraph";
import RangeField2 from "../../components/RangeField2/RangeField2";
import config from "../../config";
import { connect } from "../../container/utils/loop";
import { setActiveFilter } from "../../libs/filters";
import useDebounce from "../../libs/hooks/use-debounce";
import useFetchData from "../../libs/hooks/use-fetch-data";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import { roundDownToNearestHalfOrFull, roundUpToNearestHalfOrFull } from "../../libs/math-utils";
import { State } from "../../reducers";

const mapStateToProps = (state: State) => ({
  bodyValues: state.session.bodyValues,
  activeFilters: state.session.activeFilters
});

const mapDispatchToProps = {
  setBodyValues: actions.session.setBodyValues,
  setActiveFilters: actions.session.setActiveFilters,
  showToast: actions.toasts.showToast
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & ModalProps<BodySizingModalContentProps>;

type Steps = {
  [key: string]: {
    renderChildren: () => React.ReactNode;
    renderAside: () => React.ReactNode;
    validate?: () => boolean;
  };
};

const INITIAL_STEP = 1;

const MIN_BODY_HEIGHT = 140;
const MAX_BODY_HEIGHT = 210;
const RANGE_STEP = 0.5;
const CM = "cm";

const roundedAverages = (averages: [number, number]): [number, number] => {
  return [roundUpToNearestHalfOrFull(averages[0]), roundDownToNearestHalfOrFull(averages[1])];
};

const BodySizingModalPartial = ({
  close,
  bodyValues,
  setBodyValues,
  variant,
  showToast,
  activeFilters,
  setActiveFilters
}: Props) => {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation(["commons"]);
  const breakpoints = useMediaQuery();

  const [currentStep, setCurrentStep] = React.useState(INITIAL_STEP);
  const currentStepIndex = currentStep - 1;

  const formik = useFormik<SizingBodyValues>({
    enableReinitialize: true,
    initialValues: {
      bodyHeight: 175,
      inseam: 0,
      armLength: 0,
      ...bodyValues
    },
    onSubmit: async (values, { setSubmitting, setStatus, setTouched }) => {
      setBodyValues(values);
      setActiveFilters(
        setActiveFilter<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
          activeFilters,
          CustomBicycleFilterKey.Sizing,
          [values]
        )
      );

      setSubmitting(false);
      setTouched({});
      setStatus({ isSubmitted: true });
      close();
    }
  });

  const [focusedInput, setFocusedInput] = React.useState<keyof SizingBodyValues>();
  const debouncedBodyHeight = useDebounce(formik.values.bodyHeight, config.shared.filter.filterFetchDelay);

  const { data: bodyAverageData, refresh: refreshBodyAverageData } = useFetchData<SizingBodyAverageValues>(
    () =>
      postToService("sizing", "bodyAverageValues", {
        bodyHeight: debouncedBodyHeight
      }),
    [debouncedBodyHeight],
    {
      onDone: ({ inseam, armLength }) => {
        formik.setFieldValue("inseam", Math.round(inseam.average));
        formik.setFieldValue("armLength", Math.round(armLength.average));
      },
      handleError: () => {
        close();

        showToast({
          title: t("commons:bodySizingModalPartialApp.serviceErrorToast.title"),
          description: t("commons:bodySizingModalPartialApp.serviceErrorToast.description"),
          kind: "negative",
          icon: icons.IconSmallAttention
        });
      }
    }
  );

  const handleStepInputFieldChange = (fieldName: keyof SizingBodyValues) => (value: string) => {
    const parsedValue = parseFloat(value);

    if (Number.isNaN(parsedValue)) {
      formik.setFieldValue(fieldName, "");
    } else {
      formik.setFieldValue(fieldName, parsedValue);
    }
  };

  const handleStepInputFieldBlur = (fieldName: keyof SizingBodyValues, min: number, max: number) => {
    if (formik.values[fieldName] > max) {
      formik.setFieldValue(fieldName, max);
    }
    if (formik.values[fieldName] < min) {
      formik.setFieldValue(fieldName, min);
    }
  };

  const steps: Steps = {
    bodyHeight: {
      renderChildren: () => (
        <>
          <Headline kind={breakpoints.m ? "m" : "base"} classNames={["u-space-base"]}>
            {t("commons:bodySizingModalPartialApp.bodyHeight.headline")}
          </Headline>
          <Paragraph size={breakpoints.xl ? "base" : "s"} classNames={["u-space-l"]}>
            <Trans
              t={t}
              i18nKey="commons:bodySizingModalPartialApp.bodyHeight.description"
              components={{ bold: <strong /> }}
            />
          </Paragraph>
          <FlexLayout gap="s" alignItems="center" classNames={["u-space-base"]}>
            <InputField
              classNames={["u-width-20"]}
              maxLength={3}
              keyboardType={KeyboardType.Numpad}
              withoutClearButton
              value={`${formik.values.bodyHeight}`}
              onFocus={() => setFocusedInput("bodyHeight")}
              onChange={handleStepInputFieldChange("bodyHeight")}
              onBlur={() => {
                setFocusedInput(undefined);
                handleStepInputFieldBlur("bodyHeight", MIN_BODY_HEIGHT, MAX_BODY_HEIGHT);
              }}
            />
            {CM}
          </FlexLayout>
          <RangeField2
            min={MIN_BODY_HEIGHT}
            max={MAX_BODY_HEIGHT}
            step={RANGE_STEP}
            unit={CM}
            value={formik.values.bodyHeight}
            onChange={value => {
              // Don't let RangeField interfere with value while InputField is focused
              if (!focusedInput) {
                formik.setFieldValue("bodyHeight", value);
              }
            }}
          />
        </>
      ),
      renderAside: () => breakpoints.m && <Image fill position="center" src={bodyHeightImage} />,
      validate: () => formik.values.bodyHeight >= MIN_BODY_HEIGHT && formik.values.bodyHeight <= MAX_BODY_HEIGHT
    },
    inseam: {
      renderChildren: () => (
        <>
          <Headline kind={breakpoints.m ? "m" : "base"} classNames={[breakpoints.m ? "u-space-base" : "u-space-xs"]}>
            {t("commons:bodySizingModalPartialApp.inseam.headline")}
          </Headline>
          <Paragraph size={breakpoints.xl ? "base" : "s"} classNames={["u-space-l"]}>
            <Trans
              t={t}
              i18nKey="commons:bodySizingModalPartialApp.inseam.description"
              components={{ bold: <strong /> }}
            />
          </Paragraph>
          {!!bodyAverageData ? (
            <>
              <FlexLayout gap="s" alignItems="center" classNames={["u-space-base"]}>
                <InputField
                  classNames={["u-width-20"]}
                  maxLength={3}
                  keyboardType={KeyboardType.Numpad}
                  withoutClearButton
                  value={`${formik.values.inseam}`}
                  onFocus={() => setFocusedInput("inseam")}
                  onChange={handleStepInputFieldChange("inseam")}
                  onBlur={() => {
                    setFocusedInput(undefined);
                    handleStepInputFieldBlur(
                      "inseam",
                      roundUpToNearestHalfOrFull(bodyAverageData.inseam.range[0]),
                      roundDownToNearestHalfOrFull(bodyAverageData.inseam.range[1])
                    );
                  }}
                />
                {CM}
              </FlexLayout>
              <RangeField2
                min={roundUpToNearestHalfOrFull(bodyAverageData.inseam.range[0])}
                max={roundDownToNearestHalfOrFull(bodyAverageData.inseam.range[1])}
                step={RANGE_STEP}
                unit={CM}
                value={formik.values.inseam}
                average={roundedAverages(bodyAverageData.inseam.averageRange)}
                averageLabel={t("commons:bodySizingModalPartialApp.inseam.averageLabel", {
                  value: formatUnit(bodyAverageData.inseam.average, locale, { unit: "centimeter" })
                })}
                onChange={value => {
                  // Don't let RangeField interfere with value while InputField is focused
                  if (!focusedInput) {
                    formik.setFieldValue("inseam", value);
                  }
                }}
              />
            </>
          ) : (
            <LoadingIndicator size="s" />
          )}
        </>
      ),
      renderAside: () => breakpoints.m && <Image fill position="center" src={inseamImage} />,
      validate: () =>
        !!bodyAverageData &&
        formik.values.inseam >= bodyAverageData.inseam.range[0] &&
        formik.values.inseam <= bodyAverageData.inseam.range[1]
    },
    armLength: {
      renderChildren: () => (
        <>
          <Headline kind={breakpoints.m ? "m" : "base"} classNames={[breakpoints.m ? "u-space-base" : "u-space-xs"]}>
            {t("commons:bodySizingModalPartialApp.armLength.headline")}
          </Headline>
          <Paragraph size={breakpoints.xl ? "base" : "s"} classNames={["u-space-l"]}>
            <Trans
              t={t}
              i18nKey="commons:bodySizingModalPartialApp.armLength.description"
              components={{ bold: <strong />, linebreak: <br /> }}
            />
          </Paragraph>
          {!!bodyAverageData ? (
            <>
              <FlexLayout gap="s" alignItems="center" classNames={["u-space-base"]}>
                <InputField
                  classNames={["u-width-20"]}
                  maxLength={3}
                  keyboardType={KeyboardType.Numpad}
                  withoutClearButton
                  value={`${formik.values.armLength}`}
                  onFocus={() => setFocusedInput("armLength")}
                  onChange={handleStepInputFieldChange("armLength")}
                  onBlur={() => {
                    setFocusedInput(undefined);
                    handleStepInputFieldBlur(
                      "armLength",
                      roundUpToNearestHalfOrFull(bodyAverageData.armLength.range[0]),
                      roundDownToNearestHalfOrFull(bodyAverageData.armLength.range[1])
                    );
                  }}
                />
                {CM}
              </FlexLayout>
              <RangeField2
                min={roundUpToNearestHalfOrFull(bodyAverageData.armLength.range[0])}
                max={roundDownToNearestHalfOrFull(bodyAverageData.armLength.range[1])}
                step={RANGE_STEP}
                unit={CM}
                value={formik.values.armLength}
                average={roundedAverages(bodyAverageData.armLength.averageRange)}
                averageLabel={t("commons:bodySizingModalPartialApp.armLength.averageLabel", {
                  value: formatUnit(bodyAverageData.armLength.average, locale, { unit: "centimeter" })
                })}
                onChange={value => {
                  // Don't let RangeField interfere with value while InputField is focused
                  if (!focusedInput) {
                    formik.setFieldValue("armLength", value);
                  }
                }}
              />
            </>
          ) : (
            <LoadingIndicator size="s" />
          )}
        </>
      ),
      renderAside: () => breakpoints.m && <Image fill position="center" src={armLengthImage} />,
      validate: () =>
        !!bodyAverageData &&
        formik.values.armLength >= bodyAverageData.armLength.range[0] &&
        formik.values.armLength <= bodyAverageData.armLength.range[1]
    },
    results: {
      renderChildren: () => {
        const resultsImage = {
          productDetail: listResultsImage,
          productFinder: gridResultsImage
        }[variant];

        return (
          <>
            <Headline kind={breakpoints.m ? "m" : "base"} classNames={[breakpoints.m ? "u-space-base" : "u-space-xs"]}>
              {t(`commons:bodySizingModalPartialApp.results.headline.${variant}`)}
            </Headline>
            <Headline kind={breakpoints.m ? "base" : "xs"} classNames={[breakpoints.m ? "u-space-s" : "u-space-l"]}>
              {t(`commons:bodySizingModalPartialApp.results.description.${variant}`)}
            </Headline>
            <Image src={resultsImage} />
          </>
        );
      },
      renderAside: () => (
        <>
          <Headline classNames={["u-space-base"]}>
            {t("commons:bodySizingModalPartialApp.results.asideHeadline")}
          </Headline>
          <DescriptionListCard classNames={["u-space-xl"]}>
            <dt>{t("commons:bodySizingModalPartialApp.labels.bodyHeight")}:</dt>
            <dd>{formatUnit(formik.values.bodyHeight, locale, { unit: "centimeter" })}</dd>
            <dt>{t("commons:bodySizingModalPartialApp.labels.inseam")}:</dt>
            <dd>{formatUnit(formik.values.inseam, locale, { unit: "centimeter" })}</dd>
            <dt>{t("commons:bodySizingModalPartialApp.labels.armLength")}</dt>
            <dd>{formatUnit(formik.values.armLength, locale, { unit: "centimeter" })}</dd>
          </DescriptionListCard>
          <Image originalSize src={smartfitLogo} />
        </>
      )
    }
  };

  const activeSteps = Object.keys(steps) as (keyof typeof steps)[];
  const activeStep = steps[activeSteps[currentStepIndex]];
  const isLastStep = currentStep === activeSteps.length;

  return (
    <Modal
      width="xl"
      layout="noPadding"
      closeButton={<IconButton onClick={() => close()} icon={<Icon source={icons.IconSmallCross} />} />}
    >
      <BodySizingModalLayout
        actionLeft={
          !isLastStep ? (
            <Button
              onClick={() => setCurrentStep(currentStep - 1)}
              disabled={currentStep === 1}
              icon={<Icon source={icons.IconSmallArrowLeft} />}
            >
              {t("commons:bodySizingModalPartialApp.backButton")}
            </Button>
          ) : (
            <Button
              onClick={() => {
                formik.resetForm();
                refreshBodyAverageData();
                setCurrentStep(INITIAL_STEP);
              }}
              disabled={currentStep === INITIAL_STEP}
              icon={<Icon source={icons.IconSmallRefresh} />}
              iconRight
            >
              {t("commons:bodySizingModalPartialApp.resetButton")}
            </Button>
          )
        }
        actionRight={
          <Button
            variant="accent"
            kind="solid"
            iconRight
            onClick={() => (!isLastStep ? setCurrentStep(currentStep + 1) : formik.handleSubmit())}
            disabled={activeStep.validate ? !activeStep.validate() : false}
            icon={<Icon source={icons.IconSmallArrowRight} />}
          >
            {t("commons:bodySizingModalPartialApp.continueButton")}
          </Button>
        }
        progress={
          <NumberedSteps activeStep={currentStep}>
            {activeSteps.map((_, index) => {
              const step = index + 1;
              return <NumberedStep key={step} step={step} onClick={() => setCurrentStep(step)} />;
            })}
          </NumberedSteps>
        }
        aside={activeStep.renderAside()}
      >
        <div className="u-space-l">{activeStep.renderChildren()}</div>
        {!isLastStep && <Image originalSize classNames={["u-space-top-auto"]} src={smartfitLogo} />}
      </BodySizingModalLayout>
    </Modal>
  );
};

export default connector(BodySizingModalPartial);
